import React, { useRef } from 'react'
import { getClass } from '../../../../helpers/stringHelper'
import TextareaStyled from './Textarea.styled'
import pictoWarning from '../../../../../assets/images/attention.svg'

export default function Textarea({
  className, name, title, value, maxLength, error, onChange, ref,
}) {
  const debouncer = useRef(null)

  const debounceChange = (valueChanged, time) => new Promise((resolve) => {
    if (debouncer.current !== null) {
      clearTimeout(debouncer.current)
    }

    debouncer.current = setTimeout(() => {
      resolve(valueChanged)
      onChange(valueChanged)
    },
    time)
  })

  return (
    <TextareaStyled className={getClass(className, error && 'error')} pictoWarning={pictoWarning}>
      {
        title && <div className="title">{title}</div>
      }
      <div className="area">
        <textarea
          ref={ref}
          style={{ boxShadow: 'initial' }}
          aria-label={`${name} textarea`}
          value={value ? value.toString() : ''}
          onChange={({ target }) => debounceChange(target.value, 0)}
          maxLength={maxLength || null}
        />
        {
          error && (
            <div className="error">
              <span className="error-icon" />
              <span className="error-text">{error}</span>
            </div>
          )
        }
      </div>
    </TextareaStyled>
  )
}
