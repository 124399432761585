import React, { useState, useEffect, useCallback } from 'react'
import { InputSearch, InputNumber } from '@produits-internes-oss/design-system-components'
import { useTranslation } from 'react-i18next'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectAddPresalesStyled } from './ProjectAddPresales.styled'
import { getClass } from '../../helpers/stringHelper'
import { useSearch } from '../../hooks/useSearch/useSearch'
import { useDebouncer } from '../../hooks/useDebouncer/useDebouncer'
import Button from '../input/_ExportDS/ButtonWithStyle/Button'
import pictoDestroy from '../../../assets/images/destroy.svg'

export const ProjectAddPresales = () => {
  const { project, fetchPutAssignPerson, getPersonName, fetchPutUnAssignPerson, fetchUpdateFields, projectSnapshot, isProjectPresales } = useProjectSalesContext()
  const { fetchSearch } = useSearch()
  const { debounce } = useDebouncer()
  const [groupPresales, setGroupPresales] = useState([])
  const { t } = useTranslation()

  const isEditable = !projectSnapshot || isProjectPresales()

  const groupPerson = () => {
    const rowSize = 4
    const peopleByOrder = project.activity_presales.people

    peopleByOrder.sort((a, b) => {
      if (a.first_name < b.first_name) {
        return -1
      }
      if (a.first_name > b.first_name) {
        return 1
      }
      return 0
    })
    let personInRow = []

    for (let i = 0; i < peopleByOrder.length; i += rowSize) {
      const chunk = peopleByOrder.slice(i, i + rowSize)
      personInRow = [...personInRow, chunk]
    }

    setGroupPresales([...personInRow])
  }

  const rowsOfPeopleAssignedToPresales = useCallback(() => {
    let typeRow = ''
    let keyRow = 0

    return (
      groupPresales.map((groupsPerson) => {
        keyRow += 1
        if (typeRow === 'even') {
          typeRow = 'odd'
        } else {
          typeRow = 'even'
        }
        return (
          <span className={getClass('row-person-presale', typeRow)} key={`row ${keyRow}  `}>
            {groupsPerson.map((person) => (
              <li key={`person ${person.id}`} className="cell-person-presale">
                <Button
                  className="destroy"
                  onClick={() => fetchPutUnAssignPerson({
                    person_id: person.id,
                    activity_id: project.activity_presales.id,
                  })}
                  picto={pictoDestroy}
                />
                <div className="name">
                  <a
                    href={`/timesheet/${person.nickname}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`${person.first_name} ${person.last_name} / ${person.nickname}`}
                  </a>
                </div>
              </li>
            ))}
          </span>
        )
      })
    )
  }, [groupPresales])

  const SearchPerson = useCallback(() => (
    <InputSearch
      className="form-control"
      skin="light"
      name="person search"
      title={t('project.presales.affectation')}
      required={false}
      onSearch={(search) => debounce(() => search.length >= 3 && fetchSearch(search, 'Person', null, getPersonName), 500)}
      disabled={!isEditable}
      onChange={({ key }) => {
        if (key) {
          fetchPutAssignPerson({
            person_id: key,
            activity_id: project.activity_presales.id,
          })
        }
      }}
    />
  ), [project?.activity_presales])

  useEffect(() => {
    if (project) groupPerson()
  }, [project])

  if (!project) return <div>...</div>

  return (
    <ProjectAddPresalesStyled>
      <div className="form-search">
        <SearchPerson />
        <InputNumber
          skin="light"
          name="chargeable"
          title={t('project.presales.totalCharge')}
          localization={{
            invalid: { text: t('expenses.errors.mustBeANumber'), arialabel: t('expenses.errors.mustBeANumber') },
          }}
          valueInitial={project.activity_presales.nb_days}
          disabled={!isEditable}
          onChange={(value) => debounce(() => fetchUpdateFields(value.toString(), project.activity_presales.id, 'nb_days'), 1000)}
          onCheck={(value) => value < 0 && `${t('expenses.errors.greaterThanOrEqualToZero')}`}
        />
      </div>
      <ul>
        {
          rowsOfPeopleAssignedToPresales()
        }
      </ul>
      <div className="footer">
        <div className="total">
          <span>
            {' '}
            {t('project.presales.total')}
            {' '}
            :
          </span>
          <em>
            <span>
              {' '}
              {project.activity_presales.people.length}

            </span>
            <span>
              {' '}

              {project.activity_presales.people.length > 1 ? t('project.presales.people') : t('project.presales.person')}
            </span>
          </em>
        </div>
        <div className="time-in-days">
          {t('project.presales.time_in_days')}
          {' '}
          :
          <em className="total-days">
            <span>
              {project.activity_presales.total_time_in_days}
            </span>
            <span>
              {project.activity_presales.total_time_in_days > 1 ? t('project.presales.days') : t('project.presales.day')}
            </span>
          </em>
        </div>
      </div>
    </ProjectAddPresalesStyled>
  )
}
