import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectErrorsSummaryStyled } from './ProjectErrorsSummary.styled'
import { ProjectErrors } from '../ProjectErrors/ProjectErrors'
import { useProjectSummaryContext } from '../../context/ProjectSummaryContext/ProjectSummaryContext'
import pictoInfo from '../../../assets/images/infosCircle.svg'
import pictoWarning from '../../../assets/images/warningCircle.svg'
import { parseFloatWithAnySeparator, getNumberWithUnit } from '../../helpers/stringHelper'

export const ProjectErrorsSummary = () => {
  const { t, i18n } = useTranslation()
  const { project, projectPricingWarning, projectErrors } = useProjectSummaryContext()
  const filteredErrors = project && project.end_date ? Object.keys(projectErrors).filter((item) => item !== 'end_date') : Object.keys(projectErrors)

  if (!project) return <div>...</div>

  const translateProjectErrors = (errorKey, errorValue) => {
    const translatedMessages = []
    const errorMessageKey = i18n.exists(`project.form.${errorKey}`) ? t(`project.form.${errorKey}`) : errorKey

    if (errorKey === 'amount' && project.amount === null) return t('project.errorMessages.missingAmount')
    if (
      errorKey === 'amount' && (parseFloat(project.amount) < 0 || (parseFloat(project.amount) === 0 && project.status !== 'framework_agreement'))
    ) return t('project.errorMessages.negativeAmount')
    if (errorKey === 'activities') return t('project.errorMessages.noActivities')
    if (errorKey === 'qa_director') return t('project.errorMessages.missingQaDirector')
    if (errorKey === 'customer') return t('project.errorMessages.customerMustBeActive')
    if (errorKey === 'payment_schedule') return t('project.errorMessages.paymentScheduleNotFilled')
    if (errorKey === 'prevention_plan_status') return t('project.errorMessages.missingPreventionPlanStatus')
    if (errorKey === 'invoiceable_fees') return t('project.errorMessages.missingInvoiceableFees')
    if (errorKey === 'status' && project.needs_turnover_breakdown_validation) return t('project.errorMessages.needsTurnoverBreakdownValidation')
    if (errorKey === 'status') return t('project.errorMessages.timeInputsOnCurrentMonth')

    if (errorValue.length > 1) {
      errorValue.map((message) => (i18n.exists(`project.errorMessages.${message}`)
        ? translatedMessages.push(t(`project.errorMessages.${message}`))
        : translatedMessages.push(message)))

      return `${errorMessageKey}: ${translatedMessages}`
    }
    const errorMessageValue = i18n.exists(`project.errorMessages.${errorValue}`) ? t(`project.errorMessages.${errorValue}`) : errorValue

    return `${errorMessageKey}: ${errorMessageValue}`
  }

  const warningDate = () => {
    if (!projectPricingWarning) return null
    if (projectPricingWarning.priceWarningEndDate === false && projectPricingWarning.priceWarningStartDate === false) return null
    return (
      <>
        {projectPricingWarning.priceWarningEndDate ? (
          <div className="errors-date">
            {t('project.warning_for_expired_end_date_ongoing_project.0')}
            {' '}
            <strong>{t('project.warning_for_expired_end_date_ongoing_project.1')}</strong>
            {' '}
            {t('project.warning_for_expired_end_date_ongoing_project.2')}
            {' '}
            <strong>
              {' '}
              {t('project.warning_for_expired_end_date_ongoing_project.3')}
            </strong>
          </div>
        ) : ''}
        {projectPricingWarning.priceWarningStartDate ? (
          <div className="errors-date">
            {t('project.warning_for_expired_start_date_presale_project.0')}
            {' '}
            <strong>{t('project.warning_for_expired_start_date_presale_project.1')}</strong>
            {' '}
            {t('project.warning_for_expired_start_date_presale_project.2')}
            {' '}
            <strong>
              {' '}
              {t('project.warning_for_expired_start_date_presale_project.3')}
            </strong>
          </div>
        ) : ''}
      </>
    )
  }

  const warningPrice = () => (
    <div className="warning-price">
      <div className="warning-text">
        {`${t('project.warning_price.0')} (${getNumberWithUnit(parseFloatWithAnySeparator(project.amount), '€')}) ${t('project.warning_price.1')} `}
        <strong>{`(${getNumberWithUnit(parseFloatWithAnySeparator(project.total_amount_sold), '€')})`}</strong>
        {' .'}
      </div>
      <ul>
        <li>
          {`${t('project.warning_price.2')} = ${getNumberWithUnit(parseFloatWithAnySeparator(project.total_amount_sold_of_activities), '€')}`}
        </li>
        <li>
          {`${t('project.warning_price.3')} = ${getNumberWithUnit(parseFloatWithAnySeparator(project.expense_cost_subcontracting), '€')}`}
        </li>
        <li>
          {`${t('project.warning_price.4')} = ${getNumberWithUnit(parseFloatWithAnySeparator(project.expense_cost_misc), '€')}`}
        </li>
      </ul>
      <div className="warning-text">
        {t('project.warning_price.5')}
        {' '}
        <strong>
          {getNumberWithUnit(parseFloatWithAnySeparator(project.total_amount_sold - project.amount), '€')}
          .
          {' '}
          {t('project.warning_price.6')}
        </strong>
        {' '}
      </div>
    </div>
  )

  return (
    <ProjectErrorsSummaryStyled>
      <div className="errors-summary">
        {warningDate()
          ? (
            <ProjectErrors errorType="danger" picto={pictoWarning} errorsColumn>
              {warningDate()}
            </ProjectErrors>
          )
          : null}
        {
          filteredErrors.length > 0 && (
            <ProjectErrors errorType="danger" picto={pictoWarning} errorsColumn>
              <ul>
                {filteredErrors.map((key) => <li key={key} className="list-error">{translateProjectErrors(key, projectErrors[key])}</li>)}
              </ul>
            </ProjectErrors>
          )
        }
        {project.pricing_warning
          ? (
            <ProjectErrors errorType="warning" picto={pictoInfo} errorsColumn>
              {warningPrice()}
            </ProjectErrors>
          )
          : null}
      </div>
    </ProjectErrorsSummaryStyled>

  )
}
