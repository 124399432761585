/* eslint camelcase:0 */

import React, { useState, useContext, useEffect, useMemo, createContext } from 'react'
import { useFetch } from '../../fetchOverviewApi'

const StaffingContext = createContext()

export const StaffingContextProvider = ({ children }) => {
  const AVAIBILILITY_RATE_TO_SELECT_FROM = 75
  const [opportunities, setOpportunities] = useState(null)
  const [errors, setErrors] = useState([])
  const [selectedBizDevs, setSelectedBizDevs] = useState([])
  const [selectedMainRoles, setSelectedMainRoles] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])
  const [availabilitiesResult, setAvailabilitiesResult] = useState(null)
  const [availabilities, setAvailabilities] = useState(null)
  const [peopleMatchingLoaded, setPeopleMatchingLoaded] = useState(false)
  const [avaibilitiesMatchingLoaded, setAvaibilitiesMatchingLoaded] = useState(false)
  const [opportunitiesResult, setOpportunitiesResult] = useState(null)
  const [view, setView] = useState('opportunities')

  const dateRanges = useMemo(() => {
    const today = new Date()

    const getStartOfMonth = (year, month) => new Date(year, month, 1)
    const getEndOfMonth = (year, month) => new Date(year, month + 1, 0)

    const currentMonthEnd = getEndOfMonth(today.getFullYear(), today.getMonth())

    const secondMonthStart = getStartOfMonth(today.getFullYear(), today.getMonth() + 1)
    const secondMonthEnd = getEndOfMonth(today.getFullYear(), today.getMonth() + 1)

    const thirdMonthStart = getStartOfMonth(today.getFullYear(), today.getMonth() + 2)
    const thirdMonthEnd = getEndOfMonth(today.getFullYear(), today.getMonth() + 2)

    const currentMonthName = today.toLocaleString('en', { month: 'long' })
    const secondMonthName = secondMonthStart.toLocaleString('en', { month: 'long' })
    const thirdMonthName = thirdMonthStart.toLocaleString('en', { month: 'long' })

    return {
      currentMonth: { name: currentMonthName, startDate: today, endDate: currentMonthEnd },
      secondMonth: { name: secondMonthName, startDate: secondMonthStart, endDate: secondMonthEnd },
      thirdMonth: { name: thirdMonthName, startDate: thirdMonthStart, endDate: thirdMonthEnd },
    }
  }, [])

  const fetchGetOpportunities = async () => {
    setOpportunities(null)
    setPeopleMatchingLoaded(false)

    const response = await useFetch('GET', '/activities/opportunities')

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setOpportunities(response.opportunities)
    }
  }

  const fetchGetAvailabilities = async () => {
    setAvailabilities(null)
    setAvaibilitiesMatchingLoaded(false)

    let resultAllFetches = []

    const promises = []
    Object.values(dateRanges).forEach((dateRange) => {
      promises.push(useFetch('GET', `/people/availabilities?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`))
    })

    await Promise.all(promises)
    await promises.forEach((promise) => {
      promise.then((response) => {
        if (response.errors) {
          setErrors([...errors, ...response.errors])
        } else {
          resultAllFetches = resultAllFetches.concat(response.people_availabilities)
        }
      })
    })
    const result = []
    const personMap = {}

    let peopleWithEnoughAvailabilities = []

    resultAllFetches.forEach((entry) => {
      const { person, start_date, availability_rate, ...otherKeys } = entry
      const { nickname } = person
      const month = new Date(start_date).toLocaleString('en', { month: 'long' })

      if (!personMap[nickname]) {
        personMap[nickname] = {
          ...otherKeys,
          person,
          start_date,
          availability_rate: { [month]: 0 },
        }
        result.push(personMap[nickname])
      }

      personMap[nickname].availability_rate[month] = entry.availability_rate
    })

    peopleWithEnoughAvailabilities = result.filter((availability) => {
      let available = false
      Object.values(availability.availability_rate).forEach((availabilityRate) => {
        available = available || availabilityRate >= AVAIBILILITY_RATE_TO_SELECT_FROM
      })

      return available
    })

    setAvailabilities(peopleWithEnoughAvailabilities)
  }

  const fetchMatchingForOpportunities = async () => {
    const promises = []
    const opportunitiesTemp = opportunities

    opportunities.forEach((opportunity) => {
      promises.push(useFetch('GET', `/activities/people_available_for_activity/${opportunity.activity_id}`).then((response) => {
        opportunitiesTemp[opportunitiesTemp.findIndex((opportunityTemp) => (opportunityTemp.activity_id === opportunity.activity_id))].people_matching = response.people
      }))
    })

    await Promise.all(promises)
    setPeopleMatchingLoaded(true)
    setOpportunities(opportunitiesTemp)
  }

  const fetchMatchingForAvailabilities = async () => {
    const promises = []
    const avaibilitiesTemp = availabilities

    const peopleIds = avaibilitiesTemp.map((availability) => availability.person.id)

    const limit = 50
    const numberOfFetchesToDo = Math.ceil(peopleIds.length / limit)

    for (let numberOfFetch = 1; numberOfFetch <= numberOfFetchesToDo; numberOfFetch += 1) {
      promises.push(useFetch('GET', `/activities/opportunities_for_people?people_ids=${peopleIds.splice(0, limit)}`).then((response) => response.people_opportunities
        .forEach((person_opportunity) => {
          avaibilitiesTemp[
            avaibilitiesTemp.findIndex((avaibilityTemp) => avaibilityTemp.person.id === person_opportunity.person_id)
          ].opportunities_matching = person_opportunity.opportunities
        })))
    }

    await Promise.all(promises)

    setAvaibilitiesMatchingLoaded(true)
    setAvailabilities(avaibilitiesTemp)
  }

  const filterAvailabilities = () => {
    if (!availabilities) return {}
    let selectedAvaibilities = availabilities

    if (selectedMainRoles.length > 0) {
      selectedAvaibilities = selectedAvaibilities.filter((availability) => selectedMainRoles.includes(availability.person.main_role?.name || '-'))
    }

    return Object.fromEntries(selectedAvaibilities.map((availability, index) => [index, availability]))
  }

  const filteredOpportunities = () => {
    if (!opportunities) return {}
    let selectedOpportunities = opportunities

    if (selectedBizDevs.length > 0) {
      selectedOpportunities = selectedOpportunities.filter((opportunity) => selectedBizDevs.includes(opportunity.project_business_contact_nickname))
    }

    if (selectedRoles.length > 0) {
      selectedOpportunities = selectedOpportunities.filter((opportunity) => selectedRoles.includes(opportunity.role_name))
    }

    return Object.fromEntries(selectedOpportunities.map((opportunity, index) => [index, opportunity]))
  }

  const availabilitiesMainRoles = () => {
    if (!availabilities) return []

    return availabilities
      .map((availability) => availability.person.main_role?.name)
      .sort()
      .reduce((accumulator, mainRoleName) => {
        if (mainRoleName) {
          accumulator[mainRoleName] = mainRoleName
        } else {
          accumulator['-'] = '-'
        }

        return accumulator
      }, {})
  }

  const opportunitiesBizDevs = () => {
    if (!opportunities) return []

    return opportunities
      .map((opportunity) => opportunity.project_business_contact_nickname)
      .sort()
      .reduce((accumulator, trigram) => {
        accumulator[trigram] = trigram
        return accumulator
      }, {})
  }

  const opportunitiesRoles = () => {
    if (!opportunities) return []

    return opportunities
      .map((opportunity) => opportunity.role_name)
      .sort()
      .reduce((accumulator, roleName) => {
        accumulator[roleName] = roleName
        return accumulator
      }, {})
  }

  useEffect(() => {
    fetchGetOpportunities()
  }, [])

  useEffect(() => {
    if (view === 'availabilities') {
      if (availabilities) {
        setAvailabilitiesResult(filterAvailabilities())
      }

      if (availabilities && availabilities.length > 0 && !avaibilitiesMatchingLoaded) {
        fetchMatchingForAvailabilities()
      }
    }

    if (view === 'opportunities') {
      if (opportunities) {
        setOpportunitiesResult(filteredOpportunities())
      }

      if (opportunities && opportunities.length > 0 && !peopleMatchingLoaded) {
        fetchMatchingForOpportunities()
      }
    }
  }, [availabilities, opportunities, selectedBizDevs, selectedRoles, selectedMainRoles])

  return (
    <StaffingContext.Provider
      value={{
        view,
        setView,
        errors,
        opportunities,
        fetchGetOpportunities,
        opportunitiesBizDevs,
        opportunitiesRoles,
        setOpportunities,
        opportunitiesResult,
        peopleMatchingLoaded,
        setSelectedBizDevs,
        setSelectedMainRoles,
        filteredOpportunities,
        setSelectedRoles,
        dateRanges,
        availabilitiesMainRoles,
        availabilities,
        fetchGetAvailabilities,
        availabilitiesResult,
        setAvailabilitiesResult,
        setErrors,
      }}
    >
      {children}
    </StaffingContext.Provider>
  )
}

export const useStaffingContext = () => useContext(StaffingContext)
