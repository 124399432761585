import styled from 'styled-components'

export default styled.footer`
  font-size: 1.4rem;

  .description {
    margin: 1.6rem 0 0.8rem;
    color: ${({ theme }) => theme.colors.betterway.primary[100]};
  }

  button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 3.2rem;
    padding: 0.8rem 1.6rem;
    border-radius: 1.6rem;
    background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
    color: ${({ theme }) => theme.colors.betterway.white};
    transition: background-color 0.1s ease-in-out;

    &:hover {
      background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
    }
  }
`
