import styled from 'styled-components'

export const ProjectTurnoverBreakdownInputStyled = styled.div`
  .octo.hard > .control {
    min-width: 11rem;
    text-align: end;
  }

  .octo.hard.changed {
    text-decoration: underline;
  }
`
