import styled from 'styled-components'

export const StyledTimeRecap = styled.div`
  margin-bottom: 5rem;

  .period-errors {
    height: auto;
    margin: 2rem 0;
    font-size: 1.2rem;
  }

  h5 {
    margin-top: 1.5rem;
    margin-bottom: 0.8rem;
    padding-left: 1rem;
    color: ${({ theme }) => theme.colors.primaryGrey};
    font-size: 1.2rem;
    font-family: ${({ theme }) => theme.fonts.family.light};
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  h3 {
    margin-bottom: 1rem;
    font-size: 2rem;
  }

  h6 {
    margin-bottom: 2rem;
    color: inherit;
    font-size: inherit;
    font-family: ${({ theme }) => theme.fonts.family.bold};
    text-transform: none;
  }

  #comment-title {
    margin-bottom: 0.5rem;
  }

  .gdpr-info {
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: ${({ hascomment }) => (hascomment ? '0' : '2rem')};
  }
`
