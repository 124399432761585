import React, {
  useState,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'

import './Profile.scss'
import PersonPicker from '../../components/input/PersonPicker/PersonPicker'
import RolesContainer from '../../components/RolesContainer/RolesContainer'
import StaffingInfoContainer from '../../components/StaffingInfoContainer/StaffingInfoContainer'
import SkillMatrix from '../../components/SkillMatrix/SkillMatrix'
import Loader from '../../components/UI/Loader/Loader'
import { request } from '../../javascripts/HttpService'

const ProfileScreen = ({ person }) => {
  const [selectedPerson, setSelectedPerson] = useState(person)
  const [profile, setProfile] = useState()
  const { t } = useTranslation()

  useEffect(() => {
    setProfile()
    getProfile()
  }, [selectedPerson])

  const updateRoleBlock = () => {
    const { updated_at, updated_by } = profile.role
    const updated_at_date = new Date(updated_at).toLocaleDateString('fr-FR')
    const updated_at_time = new Date(updated_at).toLocaleTimeString('fr-FR')

    if (updated_at && updated_by) {
      return (
        <div className="updated-at">
          <p>{t('profile.updated_on', { date: updated_at_date, person: updated_by, time: updated_at_time }) }</p>
        </div>
      )
    }
  }

  const getProfile = () => {
    const url = `/people/${selectedPerson.id}/profile`

    request(url, { method: 'GET' })
      .then((data_json) => (
        setProfile(data_json)
      ))
      .catch((error) => console.error(error))
  }

  const changePerson = (selectedOption) => {
    const newPerson = {
      id: selectedOption.selectedValue,
      label: selectedOption.label,
    }

    setSelectedPerson(newPerson)
  }

  return (
    <div className="profile">
      <div className="profile-head">
        <h1 className="profile-title">{t('profile.title')}</h1>
        <div className="profile-person-picker">
          { console.log(selectedPerson) }
          <PersonPicker value={selectedPerson} onChange={changePerson} />
        </div>
      </div>
      <div className="profile-body">
        <div id="roles" className="profile-section">
          <div className="profile-section-title">
            <h3>{t('roles.title')}</h3>
          </div>
          <h4 className="profile-section-message">{t('roles.message')}</h4>
          {profile && updateRoleBlock()}
          <div className="role-item-list">
            {profile
              ? (
                <RolesContainer
                  person={selectedPerson}
                  roles={profile.role.roles}
                  getProfile={getProfile}
                />
              )
              : <Loader />}
          </div>
        </div>
        <div className="profile-section">
          <div className="profile-section-title">
            <h3>{t('skillMatrix.title')}</h3>
          </div>
          <div className="skill-matrix">
            {profile && <SkillMatrix nickname={profile.nickname} />}
          </div>
        </div>
        <div className="profile-section">
          <div className="profile-section-title">
            <h3>{t('staffingInfo.title')}</h3>
          </div>
          <div className="staffing-info">
            {profile
							&& (
<StaffingInfoContainer
  person={selectedPerson}
  staffingInfo={profile.staffing_info}
  getProfile={getProfile}
/>
							)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileScreen
