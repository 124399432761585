import styled from 'styled-components'

export const ManagePeriodsHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-bottom: 2rem;

  h1 {
    font-size: 2.4rem;
  }

  .in-progress-alert {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    padding: 1.2rem;
    border: 0.1rem solid ${({ theme }) => theme.colors.betterway.secondary[100]};
    border-radius: 1.5rem;
    background-color: ${({ theme }) => theme.colors.betterway.secondary[5]};

    .img-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.48rem;
      height: 3.48rem;
      margin-right: 1.8rem;
      border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[100]};
      background-color: ${({ theme }) => theme.colors.betterway.white};
    }

    p {
      margin: 0;
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      font-family: ${({ theme }) => theme.fonts.family.bold};
    }
  }

  button {
    &.chevron {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: auto;
      height: 2.4rem;
      margin-top: 2.45rem;
      padding: 0;
      background-color: ${({ theme }) => theme.colors.betterway.primary[5]};
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      font-size: 1.6rem;

      img {
        margin-left: 0.5rem;
      }
    }
  }

  ul {
    width: 395px;
    margin-top: 0.5rem;
    margin-left: 2.3rem;
    transition: all 0.5s ease-out;

    li {
      padding: 0.5rem;
    }
  }

  .active-accordion {
    display: block;
  }

  .inactive-according {
    display: none;
  }

  .header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    margin-top: 1rem;
    padding: 3.2rem;
    border: 1px solid ${({ theme }) => theme.colors.betterway.primary[50]};
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.betterway.primary[5]};

    .header-content-left {
      display: flex;
      flex-direction: column;

      h2 {
        font-weight: normal;
        font-size: 1.6rem;
      }
    }

    .header-content-right {
      display: flex;
      flex-direction: column;

      h2 {
        font-weight: normal;
        font-size: 1.6rem;
      }
    }
  }
`
