import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from '../../Link/Link'
import { useManagerPeriodsContext } from '../../../context/ManagePeriods/ManagePeriodsContext'
import PeopleListingTableStyled from '../../PeopleListing/PeopleListingTable/PeopleListingTable.styled'
import Loader from '../../input/_ExportDS/Loader/Loader'

export default function ManagePeriodsTable() {
  const { t } = useTranslation()
  const {
    timesheetUrl,
    peopleFilteredBySearch,
    loading,
    date,
  } = useManagerPeriodsContext()

  const columns = [
    {
      key: 'last_name', label: t('people.listing.columns.name'), width: '10%', isSortable: true, borderTopRadius: '0',
    },
    {
      key: 'lob', label: t('managePeriods.columns.lob'), width: '10%', isSortable: true,
    },
    {
      key: 'league', label: t('people.listing.columns.league'), width: '10%', isSortable: true,
    },
  ]

  const getCurrentDate = () => ({
    month: date.month,
    year: date.year,
  })

  const displayLoader = () => (
    <tbody>
      <tr className="loader-row">
        <td colSpan={3}>
          <Loader />
        </td>
      </tr>
    </tbody>
  )

  return (
    <PeopleListingTableStyled>
      <thead>
        <tr>
          {
            columns.map((column) => (
              <th
                key={column.key}
                style={{ width: column.width, borderTopLeftRadius: column.borderTopRadius }}
              >
                <div className="column-content">
                  <span className="column-content-title">{column.label}</span>
                  {
                      column.isSortable && (
                      <button
                        className="column-content-sort"
                        aria-label={`sort ${column.key} column`}
                        type="button"
                      >
                        <i className="fa fa-sort" />
                      </button>
                      )
                    }
                </div>
              </th>
            ))
          }
        </tr>
      </thead>
      { loading
        ? displayLoader()
        : (
          <>
            <tbody>
              {
                peopleFilteredBySearch.map((person, personIndex) => (
                  (
                    <tr key={person.id} className={personIndex % 2 === 0 ? 'even' : 'odd'}>
                      <td>
                        <Link
                          className="name-manage-periods"
                          name={`person ${person.id} CRA profile`}
                          href={`${timesheetUrl}/${person.nickname}/${getCurrentDate().year}/${getCurrentDate().month}`}
                        >
                          {`${person.first_name} ${person.last_name} ${person.nickname}`}
                        </Link>
                      </td>
                      <td>{person.lob}</td>

                      <td>
                        {person.league}
                      </td>
                    </tr>
                  )
                ))
              }
            </tbody>
            <tfoot className="manage">
              <tr>
                <td colSpan={3} />
              </tr>
            </tfoot>
          </>
        )}
    </PeopleListingTableStyled>
  )
}
