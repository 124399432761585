import React, { useRef, useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import { useTranslation } from 'react-i18next'
import Backdrop from '../../../../UI/Backdrop/Backdrop'
import StyledHappinessIndicatorMondal from './HappinessIndicatorModal.styled'
import { useFetchData } from '../../../../../fetchOverviewApi'
import { useTimesheetStateValue } from '../../../../../context/Timesheet/TimesheetStateContext'

import EmojiBad from '../../../../../../assets/images/Emoji-1.svg'
import EmojiAverage from '../../../../../../assets/images/Emoji-2.svg'
import EmojiGood from '../../../../../../assets/images/Emoji-3.svg'
import EmojiGreat from '../../../../../../assets/images/Emoji-4.svg'
import EmojiNoAnswer from '../../../../../../assets/images/Emoji-0.svg'

const emotions = [
  { emoji: EmojiNoAnswer, feeling: 'noAnswer' },
  { emoji: EmojiBad, feeling: 'bad' },
  { emoji: EmojiAverage, feeling: 'average' },
  { emoji: EmojiGood, feeling: 'good' },
  { emoji: EmojiGreat, feeling: 'great' },
]

const HappinessIndicatorModal = ({
  open,
  close,
}) => {
  const { t } = useTranslation()

  const [happinessReport, setHappinessReport] = useState([])
  const { timesheet } = useTimesheetStateValue()
  const [loading, hasError, data, handleFetch] = useFetchData(true)
  const isMounted = useRef(true)

  useEffect(() => {
    if (isMounted && open) {
      const url = `/happiness_indicators/${timesheet.id}`
      handleFetch(isMounted, url)
    }
  }, [isMounted, open])

  useEffect(() => {
    if (!loading) {
      if (hasError) {
        setHappinessReport([])
      } else {
        setHappinessReport(data.happinessIndicators)
      }
    }
  }, [hasError, loading, data])

  return (
    <>
      <Backdrop open={open} close={close} />
      <CSSTransition
        in={open}
        mountOnEnter
        unmountOnExit
        timeout={2000}
      >
        <StyledHappinessIndicatorMondal aria-label="happiness-indicator-modal">
          <h3>{t('happinessIndicator.title')}</h3>
          <ul>
            {
              happinessReport && happinessReport.length > 0
                ? happinessReport.map((project) => (
                  <HappinessLine
                    key={project.billable_project_id || project.activity_id}
                    projectName={project.label}
                    emotion={project.value ? emotions[project.value] : emotions[0]}
                  />
                )) : t('happinessIndicator.noneForThisMonth')
            }
          </ul>
        </StyledHappinessIndicatorMondal>
      </CSSTransition>
    </>
  )
}

const HappinessLine = ({ projectName, emotion }) => {
  const { t } = useTranslation()
  return (
    <li>
      <p className="project-name">{projectName}</p>
      <div>
        <img src={emotion.emoji} alt="emoji" />
        <p>{t(`happinessIndicator.legends.${emotion.feeling}`)}</p>
      </div>
    </li>
  )
}

export default HappinessIndicatorModal
