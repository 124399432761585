import React from 'react'
import { useTranslation } from 'react-i18next'
import { useExpensesContext } from '../../context/Expenses/ExpensesContext'
import { monthList } from '../../helpers/datePickerHelper'
import Button from '../input/_ExportDS/Button/Button'
import Popup from '../input/_ExportDS/Popup/Popup'
import Options from '../input/_ExportDS/Options/Options'
import ExpensesDetailsStyled from './ExpensesDetails.styled'
import pictoThreeDots from '../../../assets/images/autreHorizontal.svg'
import pictoDoubleArrow from '../../../assets/images/doubleArrow.svg'

export default function ExpensesDetails({ className, expense }) {
  const { t } = useTranslation()
  const { options, fetchEditExpense, fetchDestroyExpense, fetchUpdateExpenseStatus, canEditAlreadySentExpenses } = useExpensesContext()

  const id = expense.getValue('id')
  const status = expense.getValue('status')
  const date = new Date(expense.getValue('day'))
  const toSend = expense.getValue('to_send')
  const type = expense.getValue('type')
  const currency = options.countries.find((country) => country.name === expense.getValue('country_id'))?.currency

  const editButton = () => (
    <Popup
      className="header-controls"
      name={`expense popup ${id}`}
      trigger={({ open }) => (
        <Button
          className="header-controls-trigger"
          name={`expense popup button ${id}`}
          picto={pictoThreeDots}
          onClick={() => open()}
        />
      )}
      content={({ close }) => (
        <Options
          name={`expense popup ${id} options`}
          items={toSend
            ? [{ name: 'edit', title: t('expenses.edit') }, { name: 'destroy', title: t('expenses.delete') }]
            : [{ name: 'edit', title: t('expenses.edit') }]}
          onChange={(option) => {
            if (option.name === 'edit') {
              fetchEditExpense(id)
            } else if (option.name === 'destroy') {
              fetchDestroyExpense(id)
            }
            close()
          }}
        />
      )}
    />
  )

  const updateStatusButton = () => (
    <div className="info">
      <span className="update-status-text">{t('expenses.update_status')}</span>
      <Button
        className="update-status-button"
        name={`update expense ${id} status`}
        picto={pictoDoubleArrow}
        onClick={() => fetchUpdateExpenseStatus(id)}
      />
    </div>
  )

  return (
    <ExpensesDetailsStyled className={className}>
      <div className="info">
        <div className="header">
          <div className="header-status">
            <div className={['header-status-icon', status].join(' ')} />
            <div className="header-status-title">{t(`expenses.status.${status}`)}</div>
          </div>
          {
            ((toSend || canEditAlreadySentExpenses) && type !== 'TeleworkExpense') ? (
              editButton()
            ) : ''
          }
        </div>
      </div>
      <div className="info">
        <div className="type">{t(`expenses.categories.type.${expense.getValue('type')}`)}</div>
        <div className="value">
          {
            parseFloat(expense.getValue('value')).toFixed(3).replace('.', currency)
          }
        </div>
      </div>
      <div className="info">{`${date.getDate()} ${t(`months.${monthList[date.getMonth()]}.full`)} ${date.getFullYear()}`}</div>
      <div className="info">{options.projects.find((project) => project.name === expense.getValue('project_id'))?.title}</div>
      {
        (canEditAlreadySentExpenses && ['processing', 'processed'].includes(status) && type !== 'TeleworkExpense') ? (
          updateStatusButton()
        ) : ''
      }
      <div className="description">
        <div className="description-title">{t('expenses.description')}</div>
        <div className="description-content">{expense.getValue('label')}</div>
      </div>
    </ExpensesDetailsStyled>
  )
}
