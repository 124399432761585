import React from 'react'
import { ThemeProvider } from 'styled-components/macro'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import { SubcontractingProvider } from '../../context/Subcontracting/SubcontractingContext'
import SubcontractorsOverviewTop from '../../components/SubcontractorsOverview/SubcontractorsOverviewTop/SubcontractorsOverviewTop'
import SubcontractorsOverviewTable from '../../components/SubcontractorsOverview/SubcontractorsOverviewTable/SubcontractorsOverviewTable'

export default function SubcontractorsOverviewScreen() {
  return (
    <ThemeProvider theme={theme}>
      <SubcontractingProvider>
        <GlobalStyle />
        <SubcontractorsOverviewTop />
        <SubcontractorsOverviewTable />
      </SubcontractingProvider>
    </ThemeProvider>
  )
}
