import styled from 'styled-components'
import { Section } from '@produits-internes-oss/design-system-components'
import pictoInfo from '../../../assets/images/info.svg'
import pictoEdit from '../../../assets/images/editBlue.svg'
import pictoDestroy from '../../../assets/images/destroy.svg'
import pictoWarningFullImportant from '../../../assets/images/warningFullImportant.svg'
import arrowRightBlue from '../../../assets/images/arrowRightBlue.svg'
import arrowBottomBlue from '../../../assets/images/arrowBottomBlue.svg'

export const ProjectSalesExpensesListStyled = styled(Section)`
  > section {
    > header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;

      .count {
        display: flex;
        flex-flow: row nowrap;
        gap: 1.6rem;
        align-items: center;
        justify-content: flex-start;

        > em {
          font-weight: 600;

          &.big {
            font-size: 2.4rem;
          }
        }
      }

      .help {
        margin-top: 0.4rem;

        > em {
          font-weight: 600;

          ::after {
            content: ' ';
          }
        }

        >.error {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          margin-top: 1.2rem;
          padding: 0.7rem 1.6rem 0.8rem;
          border: 0.1rem solid ${({ theme }) => theme.colors.betterway.red[100]};
          border-radius: 0.3rem;
          background-color: ${({ theme }) => theme.colors.betterway.red[5]};

          >.error-icon {
            content: '';
            display: inline-block;
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 0.8rem;
            background-image: url(${pictoWarningFullImportant});
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }

          >.error-text {
            > em {
              font-weight: 600;

              ::before {
                content: ' ';
              }

              ::after {
                content: ' ';
              }
            }
          }
        }
      }

      .example {
        margin-top: 1.6rem;

        > em {
          text-decoration: underline;
        }

        > span {
          ::before {
            content: ' ';
          }
        }
      }
    }

    >.list {
      >.list-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: space-between;
        height: fit-content;
        padding: 1.6rem;
        background-color: ${({ theme }) => theme.colors.betterway.white};

        &.odd {
          background-color: ${({ theme }) => theme.colors.betterway.primary[5]};
        }

        >.infos-item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-self: center;
          justify-content: start;
          min-width: 26rem;
          margin-right: 2rem;

          >.name-subcontractor {
            display: flex;
            height: 100%;
            margin-top: 0.8rem;

            >em {
              margin-left: 0.5rem;
              font-weight: 600;

              &::before {
                content: ' ';
              }
            }
          }

          >.list-item-name {
            display: flex;
            flex-direction: column;
            justify-content: end;
            height: 100%;

            >.tag-expense {
              width: fit-content;
              padding: 0.8rem 1.6rem;
              border-radius: 0.3rem;
              font-weight: 600;

              &.data-driver {
                background-color: #4cc1b5;
              }

              &.travel {
                background-color: #ffe300;
              }

              &.subcontractor-expenses {
                background-color: #b8a0f4;
              }

              &.misc {
                background-color: #ff914c;
              }
            }

            >.list-item-name-id {
              font-weight: 600;
            }

            >em {
              font-weight: 600;

              &::before {
                content: ' ';
              }
            }
          }
        }

        >div {
          &.quantity,
          &.unit-price,
          &.total-amount {
            margin-right: 1rem;

            >div {
              margin-bottom: 1rem;
            }

            >em {
              font-weight: 600;

              &::before {
                content: ' ';
              }
            }
          }
        }

        >.list-item-description {
          flex: 5;
        }

        >.list-item-actions {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: stretch;
          margin-left: 2rem;

          .trigger {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-end;
            padding: 0 1rem 1rem;
            text-decoration: underline;
            cursor: pointer;

            ::after {
              content: '';
              width: 2rem;
              height: 2rem;
              margin-left: 0.6rem;
              background-position: center;
              background-repeat: no-repeat;
            }

            &.comment {
              ::after {
                background-image: url(${pictoInfo});
              }
            }

            &.update {
              ::after {
                background-image: url(${pictoEdit});
              }
            }

            &.destroy {
              ::after {
                background-image: url(${pictoDestroy});
              }
            }
          }
        }
      }

      .full-list-button {
        display: flex;
        justify-content: end;
        width: 100%;
        margin-top: 2.4rem;
        text-decoration: underline;
        cursor: pointer;

        button {
          display: flex;

          .btn-expand-list {
            display: flex;
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 0.8rem;
            background-image: url(${arrowRightBlue});
          }

          .btn-close-list {
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 0.8rem;
            background-image: url(${arrowBottomBlue});
            font-display: flex;
          }
        }
      }
    }
  }

  &.notitle {
    >.title {
      display: none;
    }

    > section:not(:first-child) {
      margin-top: 0;
    }
  }
`
