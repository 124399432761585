import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetch } from '../../fetchOverviewApi'
import { useJQuery } from '../../hooks/useJQuery/useJQuery'
import { useTooltip } from '../../context/Tooltip/Tooltip'
import { clean, getClass, getNumberWithUnit, getNumberAsPercentage } from '../../helpers/stringHelper'
import { ProjectTurnoverBreakdown } from '../../structures/ProjectTurnoverBreakdown/ProjectTurnoverBreakdown'
import { ProjectTurnoverBreakdownTableStyled } from './ProjectTurnoverBreakdownTable.styled'
import Popup from '../input/_ExportDS/Popup/Popup'
import Button from '../input/_ExportDS/Button/Button'
import Loader from '../input/_ExportDS/Loader/Loader'
import pictoCheck from '../../../assets/images/check.svg'
import pictoWarning from '../../../assets/images/warning.svg'
import pictoCross from '../../../assets/images/Icons_vide_cross.svg'
import pictoLinkExternal from '../../../assets/images/navigationLienExterne.svg'
import { useProjectTurnoverBreakdownContext } from '../../context/ProjectTurnoverBreakdownContext/ProjectTurnoverBreakdownContext'
import { ProjectTurnoverBreakdownInput } from '../ProjectTurnoverBreakdownInput/ProjectTurnoverBreakdownInput'
import { ProjectTurnoverBreakdownTotalAmountInput } from '../ProjectTurnoverBreakdownTotalAmountInput/ProjectTurnoverBreakdownTotalAmountInput'
import { ProjectTurnoverBreakdownComment } from '../ProjectTurnoverBreakdownComment/ProjectTurnoverBreakdownComment'

const Cell = ({ children, colSpanlength, className, onMouseEnter, onMouseLeave }) => (
  <td colSpan={!colSpanlength ? 0 : colSpanlength}>
    <div className={getClass('cell', className)} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onBlur={onMouseLeave}>{children}</div>
  </td>
)

const deltaDiv = (delta, year, month) => (
  <div className="delta">
    <div>{getNumberWithUnit(delta, '€')}</div>
    {
        delta > 0 && <img aria-label={`delta for ${year} ${month ? `month ${month} ` : ''}is positive`} src={pictoWarning} />
      }
    {
        delta < 0 && <img aria-label={`delta for ${year} ${month ? `month ${month} ` : ''}is negative`} src={pictoWarning} />
      }
  </div>
)

const CellHeader = ({ children, className }) => <th><div className={getClass('cell header', className)}>{children}</div></th>

const CellProposal = ({ children, price, timeInputConsumption, className, tooltipElementPosition = 'top' }) => {
  const { t } = useTranslation()
  const { setTooltipByName } = useTooltip()

  return (
    <Cell
      className={className}
      onMouseEnter={({ target }) => setTooltipByName('proposal', target, () => (
        <div>
          {
            timeInputConsumption || timeInputConsumption === 0
              ? (
                <div>
                  <span>{`${t('turnoverBreakdown.cutting.timeInputConsumption')} `}</span>
                  <b>{getNumberWithUnit(timeInputConsumption, '€')}</b>
                  <div className="separator" />
                </div>
              )
              : ''
          }
          <div>
            <span>{`${t('turnoverBreakdown.cutting.proposal')} `}</span>
            <b>{getNumberWithUnit(price, '€')}</b>
          </div>
        </div>
      ), tooltipElementPosition)}
      onMouseLeave={() => setTooltipByName('proposal', null)}
    >
      {children}
    </Cell>
  )
}

const Header = ({ tribes, intragroup, subcontracting, subcontractingExpenses, misc, benext }) => {
  const { t } = useTranslation()

  return (
    <thead>
      <tr>
        <CellHeader>{t('turnoverBreakdown.month')}</CellHeader>
        {
          tribes.map((lob) => <CellHeader key={`${lob.abbreviation} tribe`}>{lob.abbreviation}</CellHeader>)
        }
        {
          intragroup.abbreviation && <CellHeader>{t('turnoverBreakdown.lobs.intragroup')}</CellHeader>
        }
        {
          subcontracting.abbreviation && <CellHeader>{t('turnoverBreakdown.lobs.subcontracting')}</CellHeader>
        }
        {
          subcontractingExpenses.abbreviation && <CellHeader>{t('turnoverBreakdown.lobs.subcontractingExpenses')}</CellHeader>
        }
        {
          misc.abbreviation && <CellHeader>{t('turnoverBreakdown.lobs.misc')}</CellHeader>
        }
        {
          benext.abbreviation && <CellHeader>{t('turnoverBreakdown.lobs.benext')}</CellHeader>
        }
        <CellHeader>{t('turnoverBreakdown.totals.tribes')}</CellHeader>
        <CellHeader className="total">{t('turnoverBreakdown.totals.project')}</CellHeader>
        <CellHeader>{t('turnoverBreakdown.cutting.inputed_delta')}</CellHeader>
        <CellHeader>{t('turnoverBreakdown.cutting.progression')}</CellHeader>
        <CellHeader>{t('turnoverBreakdown.actions.title')}</CellHeader>
      </tr>
    </thead>
  )
}

const LineSpacing = ({
  months, month, year, index, emptyCells, totalsByYear,
}) => {
  const { t } = useTranslation()
  if (month === 12 || months.length === index + 1) {
    return (
      <tr key={`cutting-line-spacing-${year}`} className="annual-line-spacing">
        <Cell className="annual-line-spacing-cell">{t('turnoverBreakdown.cutting.yearlyCutted', { year })}</Cell>
        { Array.from({ length: emptyCells }, (emptyCellValue, emptyCellIndex) => <Cell key={`empty-cell-${emptyCellIndex}-${year}`} />)}
        <CellProposal className="annual-line-spacing-cell" price={totalsByYear.tribesProposals[year]}>
          { getNumberWithUnit(totalsByYear.tribesAmounts[year], '€') }
        </CellProposal>
        <CellProposal className={getClass('annual-line-spacing-cell', 'total')} price={totalsByYear.allProposals[year]}>
          { getNumberWithUnit(totalsByYear.allAmounts[year], '€') }
        </CellProposal>
        <Cell className={`
            ${getClass('annual-line-spacing-cell', totalsByYear.consumedAmountDelta[year] !== 0 && (totalsByYear.consumedAmountDelta[year] > 0 ? 'positive' : 'negative'))}
          `}
        >
          { deltaDiv(totalsByYear.consumedAmountDelta[year], year) }
        </Cell>
        <Cell />
        <Cell />
      </tr>
    )
  }
  return null
}

const footerSecondLine = (tribes, intragroup, subcontracting, subcontractingExpenses, misc, benext, totals, closed) => {
  const { t } = useTranslation()

  return (
    <tr className={closed ? 'last' : 'hidden'}>
      <Cell>{t('turnoverBreakdown.cutting.delta')}</Cell>
      {
        tribes.map((lob) => (
          <Cell
            key={`cell-${lob.id}`}
            className={getClass(totals.lobs[lob.abbreviation].distributedDelta !== 0 && (totals.lobs[lob.abbreviation].distributedDelta > 0 ? 'positive' : 'negative'))}
          >
            { deltaDiv(totals.lobs[lob.abbreviation].distributedDelta) }
          </Cell>
        ))
      }
      {
        intragroup.abbreviation && (
          <Cell
            className={getClass(totals.lobs[intragroup.abbreviation].distributedDelta !== 0
              && (totals.lobs[intragroup.abbreviation].distributedDelta > 0 ? 'positive' : 'negative'))}
          >
            { deltaDiv(totals.lobs[intragroup.abbreviation].distributedDelta) }
          </Cell>
        )
      }
      {
        subcontracting.abbreviation && (
          <Cell
            className={getClass(totals.lobs[subcontracting.abbreviation].distributedDelta !== 0
              && (totals.lobs[subcontracting.abbreviation].distributedDelta > 0 ? 'positive' : 'negative'))}
          >
            { deltaDiv(totals.lobs[subcontracting.abbreviation].distributedDelta) }
          </Cell>
        )
      }
      {
        subcontractingExpenses.abbreviation && (
          <Cell
            className={getClass(totals.lobs[subcontractingExpenses.abbreviation].distributedDelta !== 0
              && (totals.lobs[subcontractingExpenses.abbreviation].distributedDelta > 0 ? 'positive' : 'negative'))}
          >
            { deltaDiv(totals.lobs[subcontractingExpenses.abbreviation].distributedDelta) }
          </Cell>
        )
      }
      {
        misc.abbreviation && (
          <Cell
            className={getClass(totals.lobs[misc.abbreviation].distributedDelta !== 0
              && (totals.lobs[misc.abbreviation].distributedDelta > 0 ? 'positive' : 'negative'))}
          >
            { deltaDiv(totals.lobs[misc.abbreviation].distributedDelta) }
          </Cell>
        )
      }
      {
        benext.abbreviation && (
          <Cell
            className={getClass(totals.lobs[benext.abbreviation].distributedDelta !== 0
              && (totals.lobs[benext.abbreviation].distributedDelta > 0 ? 'positive' : 'negative'))}
          >
            { deltaDiv(totals.lobs[benext.abbreviation].distributedDelta) }
          </Cell>
        )
      }
      <Cell
        className={getClass(totals.project.tribes.distributedDelta !== 0 && (totals.project.tribes.distributedDelta > 0 ? 'positive' : 'negative'))}
      >
        { deltaDiv(totals.project.tribes.distributedDelta) }
      </Cell>
      <Cell />
      <Cell
        className={getClass(totals.project.distributedDelta !== 0 && (totals.project.distributedDelta > 0 ? 'positive' : 'negative'))}
      >
        { deltaDiv(totals.project.distributedDelta) }
      </Cell>
      <Cell>{getNumberAsPercentage(totals.project.progression)}</Cell>
      <Cell />
    </tr>
  )
}

const Footer = ({
  tribes, intragroup, subcontracting, subcontractingExpenses, misc, benext, totals, closed, hasValues,
}) => {
  const { t } = useTranslation()
  const offsetSuffix = totals.project.offset > 0 ? t('turnoverBreakdown.extra') : t('turnoverBreakdown.remaining')
  const offsetColor = `offset ${totals.project.offset > 0 ? 'positive-offset' : 'negative-offset'}`

  return (
    <tfoot>
      <tr className={closed ? '' : 'last'}>
        <Cell>{t('turnoverBreakdown.totals.project')}</Cell>
        {
          tribes.map((lob) => (
            <CellProposal
              key={`total ${lob.abbreviation}`}
              price={totals.lobs[lob.abbreviation].proposal}
            >
              {getNumberWithUnit(totals.lobs[lob.abbreviation].amount, '€')}
            </CellProposal>
          ))
        }
        {
          intragroup.abbreviation && (
            <CellProposal
              price={totals.lobs[intragroup.abbreviation].proposal}
            >
              {getNumberWithUnit(totals.lobs[intragroup.abbreviation].amount, '€')}
            </CellProposal>
          )
        }
        {
          subcontracting.abbreviation && (
            <CellProposal
              price={totals.lobs[subcontracting.abbreviation].proposal}
            >
              {getNumberWithUnit(totals.lobs[subcontracting.abbreviation].amount, '€')}
            </CellProposal>
          )
        }
        {
          subcontractingExpenses.abbreviation && (
            <CellProposal
              price={totals.lobs[subcontractingExpenses.abbreviation].proposal}
            >
              {getNumberWithUnit(totals.lobs[subcontractingExpenses.abbreviation].amount, '€')}
            </CellProposal>
          )
        }
        {
          misc.abbreviation && (
            <CellProposal
              price={totals.lobs[misc.abbreviation].proposal}
            >
              {getNumberWithUnit(totals.lobs[misc.abbreviation].amount, '€')}
            </CellProposal>
          )
        }
        {
          benext.abbreviation && (
            <CellProposal
              price={totals.lobs[benext.abbreviation].proposal}
            >
              {getNumberWithUnit(totals.lobs[benext.abbreviation].amount, '€')}
            </CellProposal>
          )
        }
        <CellProposal price={totals.project.tribes.proposal || 0}>{getNumberWithUnit(totals.project.tribes.amount || 0, '€')}</CellProposal>
        <CellProposal
          className="total"
          price={totals.project.all.proposal || 0}
        >
          {getNumberWithUnit(totals.project.all.amount || 0, '€')}
        </CellProposal>
        <Cell
          className={getClass(totals.project.consumedAmountDelta !== 0 && (totals.project.consumedAmountDelta > 0 ? 'positive' : 'negative'))}
        >
          { deltaDiv(totals.project.consumedAmountDelta) }
        </Cell>
        <Cell>{getNumberAsPercentage(totals.project.progression)}</Cell>
        <Cell>
          {
            totals.project.offset && hasValues
              ? <div className={offsetColor}>{getNumberWithUnit(Math.abs(totals.project.offset), `€ ${offsetSuffix}`)}</div>
              : ''
          }
        </Cell>
      </tr>
      { footerSecondLine(tribes, intragroup, subcontracting, subcontractingExpenses, misc, benext, totals, closed) }
    </tfoot>
  )
}

const Comment = ({ year, month }) => {
  const [currentComment, setCurrentComment] = useState(null)
  const ref = useRef(null)

  const { setTooltipByName } = useTooltip()
  const { turnoverBreakdown, fetchUpdateComment } = useProjectTurnoverBreakdownContext()

  const { comment, validation } = turnoverBreakdown.values[year][month]

  const date = validation?.date && new Date(validation.date)
  return (
    <>
      {
        date && (
        <div className="content-validate">
          <span>
            Validé par :
          </span>
          <span>
            { ` ${validation.firstname} ${validation.lastname} ` }
          </span>
          <a href={`https://askbob.octo.tools/people/${clean(validation.nickname)}`} target="_blank" rel="noreferrer">{`${validation.nickname}`}</a>
          <span>
            <img aria-label="picto external link" src={pictoLinkExternal} />
          </span>
        </div>
        )
      }
      {
        date && (
        <div className="content-modify-date">
          {' '}
          Dernière validation :
          {' '}
          {`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}
        </div>
        )
      }
      {
        (validation || date) && <div className="comment-line" />
      }
      <div className="title-text-area">
        Renseignez une information :
      </div>

      <div className="content-textarea">
        <textarea
          ref={ref}
          className="comment-textarea"
          type="textarea"
          aria-label={`comment ${year} ${month}`}
          rows={0}
          cols={0}
          style={{ boxShadow: 'initial', borderRadius: '20px', padding: '10px' }}
          value={comment?.text || ''}
          onChange={(e) => {
            e.preventDefault()

            const text = e.target.value
            if (turnoverBreakdown.values[year][month].comment === null) {
              turnoverBreakdown.values[year][month].comment = { text: '' }
            }
            turnoverBreakdown.values[year][month].comment.text = text

            setCurrentComment(text)
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 32) {
              e.preventDefault()
              ref.current.value += ' '
            }
          }}
        />
      </div>
      <div className="contain-button-validate">
        <button
          type="button"
          disabled={!(currentComment || comment?.text)}
          onClick={async () => {
            await fetchUpdateComment(year, month, currentComment || comment?.text)
            setTooltipByName('comment', null)
          }}
        >
          Valider
        </button>
      </div>
    </>
  )
}

export const ProjectTurnoverBreakdownTable = ({ className }) => {
  const { t } = useTranslation()
  const { publish, subscribe } = useJQuery()

  const [commentClicked, setCommentClicked] = useState()
  const fetchValidationAlreadySent = useRef(false)

  const { setTooltipByName } = useTooltip({ addedClassName: 'other' })
  const { turnoverBreakdown, monthToValidate, errors, fetchGetTurnoverBreakdown, fetchUpdateComment } = useProjectTurnoverBreakdownContext()

  useEffect(() => {
    fetchGetTurnoverBreakdown()
    subscribe('/octopod/ajax/reset_elements', () => {
      fetchGetTurnoverBreakdown()
    })
  }, [])

  const trClass = (index, year, month) => {
    if (monthToValidate && monthToValidate[0] === year && monthToValidate[1] === month) return ('highlight')
    if (index % 2) return ('odd')
    return ('even')
  }

  const refreshStatusContainerIfAllMonthsAreValidated = () => {
    let monthUnvalidated = 0
    Object.keys(turnoverBreakdown.values).forEach((year) => {
      Object.keys(turnoverBreakdown.values[year]).forEach((month) => {
        if (turnoverBreakdown.values[year][month].validation?.date === null) {
          monthUnvalidated += 1
        }
      })
    })
    if (monthUnvalidated === 1) {
      publish('/octopod/ajax/reset_status')
    }
  }
  const refreshStatusContainerIfOneMonthIsUnvalidated = () => {
    let monthUnvalidated = 0
    Object.keys(turnoverBreakdown.values).forEach((year) => {
      Object.keys(turnoverBreakdown.values[year]).forEach((month) => {
        if (turnoverBreakdown.values[year][month].validation?.date === null) {
          monthUnvalidated += 1
        }
      })
    })
    if (monthUnvalidated === 0) {
      publish('/octopod/ajax/reset_status')
    }
  }

  const fetchChangeTurnoverBreakdownValidation = async (year, month, turnoverBreakdownId, isValidated, closeModal) => {
    const { id } = turnoverBreakdown.values[year][month]
    await useFetch('PATCH', isValidated ? `/turnover_breakdown_months/${id}/unvalidate` : `/turnover_breakdown_months/${id}/validate`)
    fetchValidationAlreadySent.current = false
    document.querySelectorAll('.inner-loader-validation').forEach((element) => element.classList.add('hidden'))
    publish('/octopod/ajax/reset_elements')
    publish('/octopod/turnover_breakdown_months/update_validation')
    closeModal()
    if (!isValidated) {
      refreshStatusContainerIfAllMonthsAreValidated()
    } else {
      refreshStatusContainerIfOneMonthIsUnvalidated()
    }
  }

  const fetchCloseTurnoverBreakdown = async (turnoverBreakdownId, turnoverBreakdownClosed, closeModal) => {
    const postOrPatch = turnoverBreakdownClosed ? 'PUT' : 'POST'
    const fetchUrl = turnoverBreakdownClosed ? `/turnover_breakdowns/${turnoverBreakdownId}/unfinalise`
      : `/turnover_breakdowns/${turnoverBreakdownId}/finalise`
    await useFetch(postOrPatch, fetchUrl, {
      amounts_by_lob_id: {},
      from_new_table: true,
    })

    publish('/octopod/ajax/reset_elements')
    closeModal()
  }

  const PopupMonthValidation = ({ year, month, isValidated }) => {
    const [currentComment, setCurrentComment] = useState(null)
    const ref = useRef(null)

    const { comment } = turnoverBreakdown.values[year][month]

    const date = new Date()
    const pictoSource = isValidated ? pictoCross : pictoCheck

    const hasStarted = date.getFullYear() > year || (date.getFullYear() === year && (date.getMonth() + 1) >= month)
    const enabled = hasStarted && !turnoverBreakdown.closed

    const action = isValidated ? t('turnoverBreakdown.actions.invalidate') : t('turnoverBreakdown.actions.validate')
    const textShort = `${action} ${t('turnoverBreakdown.actions.month.short')}`
    const textFull = `${action} ${t('turnoverBreakdown.actions.month.full', { year, month })}`

    const changedByUserLobs = Object.keys(
      Object.fromEntries(
        Object.entries(turnoverBreakdown.values[year][month].lobs)
          .filter(([key]) => turnoverBreakdown.values[year][month].lobs[key].changed_by_user),
      ),
    )

    const isTotalChangedByUser = turnoverBreakdown.values[year][month].amount !== null
    const isChangedByUser = isTotalChangedByUser || changedByUserLobs.length > 0

    const updateComment = async (close) => {
      if (enabled) {
        await fetchUpdateComment(year, month, currentComment || comment?.text)
        setCurrentComment('')
        setTooltipByName('comment', null)
        await fetchChangeTurnoverBreakdownValidation(year, month, turnoverBreakdown.id, isValidated, close)
      } else {
        close()
      }
    }

    const validationDisabled = () => {
      const emptyTextArea = ref?.current?.value === ''
      const commentNotPresent = !(comment?.text) && isChangedByUser
      return ((emptyTextArea && commentNotPresent) || (emptyTextArea && !commentNotPresent))
    }

    return (
      isChangedByUser && !isValidated
        ? (
          <Popup
            className="change-validation-popup change-validation-surcharging-popup"
            name="change-validation-surcharging-popup"
            title={textShort}
            withoutParentsScroll
            trigger={({ open }) => (
              <button
                type="button"
                className={getClass('open-change-validation-button', enabled || 'disabled')}
                onClick={() => enabled && open()}
              >
                { textShort }
                <img aria-label={`invalidate for year ${year} month ${month} cutting row`} src={pictoSource} />
              </button>
            )}
            content={({ close }) => (
              <div>
                <div>{ t('turnoverBreakdown.actions.surcharging.warning', { action: textFull.toLowerCase(), lob: changedByUserLobs.join(', ') }) }</div>
                <div>
                  {
                    t('turnoverBreakdown.actions.surcharging.warning-description',
                      {
                        lobs: changedByUserLobs.join(', '),
                        totalMission: isTotalChangedByUser ? 'total mission' : '',
                        both: (isTotalChangedByUser && changedByUserLobs.length > 0) ? 'et' : '',
                      })
                  }
                </div>
                <div className="content-textarea-title">{ t('turnoverBreakdown.actions.surcharging.comment') }</div>
                <div className="content-textarea">
                  <textarea
                    ref={ref}
                    className="comment-textarea"
                    aria-label={`comment ${year} ${month} for validation`}
                    name="comment-textarea"
                    type="textarea"
                    rows={2}
                    cols={2}
                    style={{ boxShadow: 'initial', borderRadius: '20px', padding: '10px' }}
                    defaultValue={comment ? comment.text : ''}
                    placeholder={t('turnoverBreakdown.actions.comment')}
                    onChange={(e) => {
                      e.preventDefault()
                      setCurrentComment(e.target.value)
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 32) {
                        e.preventDefault()
                        ref.current.value += ' '
                      }
                    }}
                  />
                </div>
                <div className="button-wrapper">
                  <Button
                    className="button-cancel"
                    text={t('turnoverBreakdown.actions.cancel')}
                    onClick={() => close()}
                  />
                  <Button
                    className="button-validate "
                    text={t('turnoverBreakdown.actions.confirm', { action: textShort.toLowerCase() })}
                    onClick={() => {
                      if (!fetchValidationAlreadySent.current) updateComment(close)
                      fetchValidationAlreadySent.current = true
                      document.querySelectorAll('.inner-loader-validation').forEach((element) => element.classList.remove('hidden'))
                    }}
                    disabled={validationDisabled()}
                  />
                  <Loader className="inner-loader-validation hidden" />
                </div>
              </div>
            )}
          />
        )
        : (
          <Popup
            className="change-validation-popup"
            name="change-validation-popup"
            title={textShort}
            withoutParentsScroll
            trigger={({ open }) => (
              <button
                type="button"
                className={getClass('open-change-validation-button', enabled || 'disabled')}
                onClick={() => enabled && open()}
              >
                { textShort }
                <img aria-label={`invalidate for year ${year} month ${month} cutting row`} src={pictoSource} />
              </button>
            )}
            content={({ close }) => (
              <div>
                <span>{t('turnoverBreakdown.actions.warning', { action: textFull.toLowerCase() })}</span>
                <div className="button-wrapper">
                  <Button
                    className="button-cancel"
                    text={t('turnoverBreakdown.actions.cancel')}
                    onClick={() => close()}
                  />
                  <Button
                    className="button-validate"
                    text={t('turnoverBreakdown.actions.confirm', { action: textShort.toLowerCase() })}
                    onClick={() => {
                      if (enabled) {
                        if (!fetchValidationAlreadySent.current) fetchChangeTurnoverBreakdownValidation(year, month, turnoverBreakdown.id, isValidated, close)
                        fetchValidationAlreadySent.current = true
                        document.querySelectorAll('.inner-loader-validation').forEach((element) => element.classList.remove('hidden'))
                      } else {
                        close()
                      }
                    }}
                  />
                  <Loader className="inner-loader-validation hidden" />
                </div>
              </div>
            )}
          />
        )
    )
  }

  const closeCuttingButton = (year, month) => {
    const lastYear = Object.keys(turnoverBreakdown.values)[Object.keys(turnoverBreakdown.values).length - 1]
    const lastMonth = Object.keys(turnoverBreakdown.values[lastYear])[Object.keys(turnoverBreakdown.values[lastYear]).length - 1]

    if (lastYear !== year.toString() || lastMonth !== month.toString()) return (null)

    if (!turnoverBreakdown.can_be_finalised && !turnoverBreakdown.closed) return (null)

    if (turnoverBreakdown.project.status === 'mission_done'
        && (turnoverBreakdown.closed || turnoverBreakdown.project.kind === ProjectTurnoverBreakdown.PROJECT_TYPE_R)) {
      return (null)
    }
    const action = t('turnoverBreakdown.actions.close')
    const textShort = `${action} ${t('turnoverBreakdown.actions.project.short')}`
    const textFull = `${action} ${t('turnoverBreakdown.actions.project.full')}`

    return (
      <Popup
        className="close-cutting-popup"
        name="close-cutting-popup"
        title={textShort}
        withoutParentsScroll
        trigger={({ open }) => (
          <Button
            className="open-close-cutting"
            text={textShort}
            onClick={() => open()}
          />
        )}
        content={({ close }) => (
          <div>
            {
              t('turnoverBreakdown.actions.warning', { action: textFull.toLowerCase() })
            }
            <div className="button-wrapper">
              <Button
                className="button-cancel"
                text={t('turnoverBreakdown.actions.cancel')}
                onClick={() => close()}
              />
              <Button
                className="button-validate"
                text={t('turnoverBreakdown.actions.confirm', { action: textShort.toLowerCase() })}
                onClick={() => (fetchCloseTurnoverBreakdown(turnoverBreakdown.id, turnoverBreakdown.closed, close))}
              />
            </div>
          </div>
        )}
      />
    )
  }

  if (turnoverBreakdown === null) {
    return (
      <ProjectTurnoverBreakdownTableStyled className={className}>
        {
          (errors.length === 0) ? <Loader /> : <div>{t('turnoverBreakdown.error')}</div>
        }
      </ProjectTurnoverBreakdownTableStyled>
    )
  }

  const { lobs, months, totals } = turnoverBreakdown
  return (
    <ProjectTurnoverBreakdownTableStyled className={className}>
      <table>
        <Header
          tribes={lobs.tribes}
          intragroup={lobs.intragroup}
          subcontracting={lobs.subcontracting}
          subcontractingExpenses={lobs.subcontractingExpenses}
          misc={lobs.misc}
          benext={lobs.benext}
        />
        <tbody>
          {
              months.map(([year, month], index) => {
                const date = `${String(month).padStart(2, '0')}/${year}`
                const { consumedAmountDelta } = turnoverBreakdown.totals.months[year][month]
                const turnoverBreakdownMonth = turnoverBreakdown.values[year][month]
                const monthIsValidated = turnoverBreakdownMonth.validation?.date !== null

                return (
                  <React.Fragment key={date}>
                    <tr key={date} className={trClass(index, year, month)}>
                      <Cell>{date}</Cell>
                      {
                        lobs.tribes.map((lob) => (
                          <CellProposal
                            key={`${date} ${lob.abbreviation}`}
                            price={turnoverBreakdown.getValue(year, month, lob.abbreviation).proposal}
                            timeInputConsumption={turnoverBreakdown.getValue(year, month, lob.abbreviation).timeInputConsumption}
                            tooltipElementPosition={index === 0 ? 'bottom' : 'top'}
                          >
                            <ProjectTurnoverBreakdownInput
                              year={year}
                              month={month}
                              lob={lob}
                              turnoverBreakdownValue={turnoverBreakdown.getValue(year, month, lob.abbreviation)}
                              disabled
                            />
                          </CellProposal>
                        ))
                      }
                      {
                        lobs.intragroup.abbreviation && (
                          <CellProposal
                            price={turnoverBreakdown.getValue(year, month, lobs.intragroup.abbreviation).proposal}
                            tooltipElementPosition={index === 0 ? 'bottom' : 'top'}
                          >
                            <ProjectTurnoverBreakdownInput
                              year={year}
                              month={month}
                              lob={lobs.intragroup}
                              turnoverBreakdownValue={turnoverBreakdown.getValue(year, month, lobs.intragroup.abbreviation)}
                              disabled={monthIsValidated}
                            />
                          </CellProposal>
                        )
                      }
                      {
                        lobs.subcontracting.abbreviation && (
                          <CellProposal
                            price={turnoverBreakdown.getValue(year, month, lobs.subcontracting.abbreviation).proposal}
                            tooltipElementPosition={index === 0 ? 'bottom' : 'top'}
                          >
                            <ProjectTurnoverBreakdownInput
                              year={year}
                              month={month}
                              lob={lobs.subcontracting}
                              turnoverBreakdownValue={turnoverBreakdown.getValue(year, month, lobs.subcontracting.abbreviation)}
                              disabled={monthIsValidated}
                            />
                          </CellProposal>
                        )
                      }
                      {
                        lobs.subcontractingExpenses.abbreviation && (
                          <CellProposal
                            price={turnoverBreakdown.getValue(year, month, lobs.subcontractingExpenses.abbreviation).proposal}
                            tooltipElementPosition={index === 0 ? 'bottom' : 'top'}
                          >
                            <ProjectTurnoverBreakdownInput
                              year={year}
                              month={month}
                              lob={lobs.subcontractingExpenses}
                              turnoverBreakdownValue={turnoverBreakdown.getValue(year, month, lobs.subcontractingExpenses.abbreviation)}
                              disabled={monthIsValidated}
                            />
                          </CellProposal>
                        )
                      }
                      {
                        lobs.misc.abbreviation && (
                          <CellProposal
                            price={turnoverBreakdown.getValue(year, month, lobs.misc.abbreviation).proposal}
                            tooltipElementPosition={index === 0 ? 'bottom' : 'top'}
                          >
                            <ProjectTurnoverBreakdownInput
                              year={year}
                              month={month}
                              lob={lobs.misc}
                              turnoverBreakdownValue={turnoverBreakdown.getValue(year, month, lobs.misc.abbreviation)}
                              disabled={monthIsValidated}
                            />
                          </CellProposal>
                        )
                      }
                      {
                        lobs.benext.abbreviation && (
                          <CellProposal
                            price={turnoverBreakdown.getValue(year, month, lobs.benext.abbreviation).proposal}
                            tooltipElementPosition={index === 0 ? 'bottom' : 'top'}
                          >
                            <ProjectTurnoverBreakdownInput
                              year={year}
                              month={month}
                              lob={lobs.benext}
                              turnoverBreakdownValue={turnoverBreakdown.getValue(year, month, lobs.benext.abbreviation)}
                              disabled={monthIsValidated}
                            />
                          </CellProposal>
                        )
                      }
                      <CellProposal
                        price={turnoverBreakdown.totals.months[year][month].tribes.proposal}
                        timeInputConsumption={turnoverBreakdown.totals.months[year][month].tribes.timeInputConsumption}
                        tooltipElementPosition={index === 0 ? 'bottom' : 'top'}
                      >
                        <div className="proposal">
                          {
                            getNumberWithUnit(turnoverBreakdown.totals.months[year][month].tribes.amount, '€')
                          }
                        </div>
                      </CellProposal>
                      <CellProposal
                        className="total"
                        price={turnoverBreakdown.totals.months[year][month].all.proposal}
                        timeInputConsumption={turnoverBreakdown.totals.months[year][month].all.timeInputConsumption}
                        tooltipElementPosition={index === 0 ? 'bottom' : 'top'}
                      >
                        <ProjectTurnoverBreakdownTotalAmountInput year={year} month={month} />
                      </CellProposal>
                      <Cell className={getClass(consumedAmountDelta !== 0 && (consumedAmountDelta > 0 ? 'positive' : 'negative'))}>
                        { deltaDiv(consumedAmountDelta, year, month) }
                      </Cell>
                      <Cell>{getNumberAsPercentage(turnoverBreakdown.totals.months[year][month].progression)}</Cell>
                      <Cell className="action-cell">
                        <ProjectTurnoverBreakdownComment
                          comment={turnoverBreakdown.values[year][month].comment}
                          commentClicked={commentClicked}
                          onComment={(comment, target) => {
                            setTooltipByName('comment', target, <Comment year={year} month={month} comment={comment} />)
                            setCommentClicked(target)
                          }}
                        />
                        <PopupMonthValidation
                          year={year}
                          month={month}
                          isValidated={turnoverBreakdown.values[year][month].validation?.date}
                        />
                        { closeCuttingButton(year, month) }
                      </Cell>
                    </tr>
                    <LineSpacing
                      year={year}
                      months={months}
                      index={index}
                      month={month}
                      emptyCells={
                      (
                        (lobs.tribes.length || 0)
                        + (lobs.intragroup.id !== null)
                        + (lobs.subcontracting.id !== null)
                        + (lobs.subcontractingExpenses.id !== null)
                        + (lobs.misc.id !== null)
                        + (lobs.benext.id !== null)
                      )
                      }
                      totalsByYear={turnoverBreakdown.totals.byYear}
                      totalsByMonth={turnoverBreakdown.totals.months}
                    />
                  </React.Fragment>
                )
              })
            }
        </tbody>
        <Footer
          tribes={lobs.tribes}
          intragroup={lobs.intragroup}
          subcontracting={lobs.subcontracting}
          subcontractingExpenses={lobs.subcontractingExpenses}
          misc={lobs.misc}
          benext={lobs.benext}
          totals={totals}
          closed={turnoverBreakdown.closed
            || (turnoverBreakdown.project.status === 'mission_done' && turnoverBreakdown.project.kind === 'cost_reimbursable')}
          hasValues={months.length > 0}
        />
      </table>
    </ProjectTurnoverBreakdownTableStyled>
  )
}
