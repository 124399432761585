export class ProjectTurnoverBreakdownValue {
  constructor(source) {
    this.id = source?.amount_id || null
    this.proposal = source?.proposal || 0
    this.amount = (source?.amount || source?.amount === 0) ? source.amount : null
    this.timeInputConsumption = (source?.time_input_consumption || source?.time_input_consumption === 0) ? source.time_input_consumption : null
    this.distributedDelta = 0
    this.changedByUser = source?.changed_by_user || false
  }

  getAmountOrDefaultToProposal() {
    return (this.amount !== null ? this.amount : this.proposal) || 0
  }
}
