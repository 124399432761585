import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledPersonRow } from './PersonRow.styled'
import { useStateValue } from '../../../state'

const PersonRow = ({ personData }) => {
  const [{ range }] = useStateValue()
  const { i18n } = useTranslation()
  const locale = i18n.language

  const buildLinkUrl = () => {
    const date = new Date(range.startDate)
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    return `/timesheet/${personData.nickname}/${year}/${month}`
  }

  return (
    <StyledPersonRow>
      <td className="firstCol">
        <div>{`${personData.first_name} ${personData.last_name.toLowerCase()}`}</div>
        <a className="link" href={buildLinkUrl()}>
          <div>
            <i className="fa fa-table calendar" />
            <div className="nickname">{personData.nickname}</div>
          </div>
          {personData.input_rate !== '1.0' && <i className="fa fa-exclamation-circle warning" />}
        </a>
        <div className="job">{personData.job}</div>
      </td>
      <td>{`${new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(personData.activity_rate * 100)}%`}</td>
      <td>{`${new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(personData.activity_rate_pipe * 100)}%`}</td>
      <td>{`${new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(personData.input_rate * 100)}%`}</td>
      <td>{new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(personData.fte)}</td>
      <td>{new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(personData.business_days)}</td>
      <td>{new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(personData.billed_days)}</td>
      <td>{new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(personData.unbilled_days)}</td>
      <td>{new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(personData.prestaffing_days)}</td>
      <td>{new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(personData.absence_days)}</td>
      <td colSpan={4} className="empty" />
    </StyledPersonRow>
  )
}

export default PersonRow
