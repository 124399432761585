import React, { useRef, useState } from 'react'
import Popup from '../Popup/Popup'
import Button from '../Button/Button'
import Input from '../Input/Input'
import Options from '../Options/Options'
import SelectAsyncStyled from './SelectAsync.styled'
import pictoChevronBottom from '../../../../../assets/images/chevronBottom.svg'

const SEARCH_LENGTH_MIN = 3

export default function SelectAsync({
  className, name, title, text, placeholder, error, disabled, onSearch, onResponse, onChange,
}) {
  const debouncer = useRef(null)
  const [search, setSearch] = useState('')
  const [options, setOptions] = useState([])

  const debounceSearch = (value, time) => new Promise((resolve) => {
    if (debouncer.current !== null) {
      clearTimeout(debouncer.current)
    }

    setSearch(value)

    if (value.length >= SEARCH_LENGTH_MIN) {
      debouncer.current = setTimeout(async () => {
        const result = await onSearch(value)
        if (result) {
          setOptions(onResponse(result))
          resolve()
        } else {
          setOptions([])
        }
      },
      time)
    } else {
      setOptions([])
    }
  })

  return (
    <Popup
      className={className}
      name={`${name} select async`}
      title={title}
      trigger={({ open }) => (
        <Button
          title={title}
          name={`${name} select async popup trigger`}
          text={text}
          picto={pictoChevronBottom}
          disabled={disabled}
          error={error}
          onClick={() => open()}
        />
      )}
      content={({ close }) => (
        <SelectAsyncStyled>
          <Input className="search" name={`${name} select async search`} value={search} onChange={(value) => debounceSearch(value, 300)} />
          {
            options.length > 0
              ? (
                <Options
                  name={`${name} select async options`}
                  items={options}
                  onChange={(option) => {
                    onChange(option)
                    close()
                  }}
                />
              )
              : <div className="placeholder">{placeholder}</div>
          }
        </SelectAsyncStyled>
      )}
    />
  )
}
