import styled from 'styled-components'

export const TooltipStyled = styled.div`
  position: absolute;
  left: 50%;
  z-index: 9999;
  box-sizing: border-box;
  width: max-content;
  max-width: 40rem;
  padding: 2rem;
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
  color: ${({ theme }) => theme.colors.betterway.white};
  box-shadow: 0 0.4rem 1.8rem 0 ${({ theme }) => theme.colors.betterway.shadow};
  transform: translateX(-50%);

  &.bottom {
    top: calc(100% + 1.4rem);
  }

  &.top {
    bottom: calc(100% + 1.4rem);
  }

  &.hidden {
    display: none;
  }

  .tooltip-arrow {
    position: absolute;
    right: 0;
    left: 0;
    width: auto;
    height: 1.6rem;
    border: none;
    background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-image: url(${(props) => props.pictoArrow});

    &.bottom {
      transform: rotate(180deg);
      top: calc(-1.6rem + 2px);
    }

    &.top {
      bottom: calc(-1.6rem + 2px);
    }
  }
`
