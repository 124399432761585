import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectServiceAdjustmentPenaltyStyled } from './ProjectServiceAdjustmentPenalty.styled'
import Input from '../input/_ExportDS/InputWithStyle/Input'
import Button from '../input/_ExportDS/ButtonWithStyle/Button'
import pictoEdit from '../../../assets/images/editBlue.svg'
import { parseFloatWithAnySeparator } from '../../helpers/stringHelper'

export const ProjectServiceAdjustmentPenalty = () => {
  const { t } = useTranslation()
  const {
    project,
    errors,
    fetchUpdateServiceAdjustmentPenalty,
    serviceAdjustmentPenaltyAmount,
    serviceAdjustmentPenaltyPercent,
    setServiceAdjustmentPenaltyAmount,
    setServiceAdjustmentPenaltyPercent,
    isProjectPresales,
  } = useProjectSalesContext()

  const [isAllEditable, setIsAllEditable] = useState(true)
  const [serviceAdjustmentPenaltyAmountEditable, setServiceAdjustmentPenaltyAmountEditable] = useState(false)
  const [serviceAdjustmentPenaltyPercentEditable, setServiceAdjustmentPenaltyPercentEditable] = useState(false)
  const [computedServiceAdjustmentPenaltyAmount, setComputedServiceAdjustmentPenaltyAmount] = useState(null)
  const [computedServiceAdjustmentPenaltyPercent, setComputedServiceAdjustmentPenaltyPercent] = useState(null)

  const calculateComputedServiceAdjustmentPenaltyAmount = (isServiceAdjustmentPenaltyAmountEmpty) => {
    if (isServiceAdjustmentPenaltyAmountEmpty && serviceAdjustmentPenaltyPercent !== null && !Number.isNaN(serviceAdjustmentPenaltyPercent)) {
      return parseFloatWithAnySeparator((serviceAdjustmentPenaltyPercent / 100) * project.service).toFixed(2)
    }

    return ''
  }

  const calculateComputedServiceAdjustmentPenaltyPercent = (isServiceAdjustmentPenaltyPercentEmpty) => {
    if (isServiceAdjustmentPenaltyPercentEmpty && serviceAdjustmentPenaltyAmount !== null && !Number.isNaN(serviceAdjustmentPenaltyAmount)) {
      return parseFloatWithAnySeparator((serviceAdjustmentPenaltyAmount / project.service) * 100).toFixed(2)
    }

    return ''
  }

  useEffect(() => {
    const isServiceAdjustmentPenaltyPercentEmpty = (serviceAdjustmentPenaltyPercent === null || Number.isNaN(serviceAdjustmentPenaltyPercent))
    const isServiceAdjustmentPenaltyAmountEmpty = (serviceAdjustmentPenaltyAmount === null || Number.isNaN(serviceAdjustmentPenaltyAmount))

    setServiceAdjustmentPenaltyAmountEditable((serviceAdjustmentPenaltyPercent === null || Number.isNaN(serviceAdjustmentPenaltyPercent)))
    setServiceAdjustmentPenaltyPercentEditable((serviceAdjustmentPenaltyAmount === null || Number.isNaN(serviceAdjustmentPenaltyAmount)))

    setComputedServiceAdjustmentPenaltyAmount(calculateComputedServiceAdjustmentPenaltyAmount(isServiceAdjustmentPenaltyAmountEmpty))
    setComputedServiceAdjustmentPenaltyPercent(calculateComputedServiceAdjustmentPenaltyPercent(isServiceAdjustmentPenaltyPercentEmpty))
  }, [serviceAdjustmentPenaltyAmount, serviceAdjustmentPenaltyPercent, isAllEditable])

  useEffect(() => {
    if (project !== null) {
      const isServiceAdjustmentPenaltyAmountPresent = project.service_adjustment_penalty !== undefined && project.service_adjustment_penalty !== null
      const isServiceAdjustmentPenaltyPercentPresent = project.service_adjustment_penalty_percent !== undefined && project.service_adjustment_penalty_percent !== null
      const hasErrors = errors.length > 0

      setServiceAdjustmentPenaltyAmount(project.service_adjustment_penalty)
      setServiceAdjustmentPenaltyPercent(project.service_adjustment_penalty_percent && (project.service_adjustment_penalty_percent * 100).toFixed(2))
      setIsAllEditable((!isServiceAdjustmentPenaltyAmountPresent && !isServiceAdjustmentPenaltyPercentPresent) || hasErrors)

      setComputedServiceAdjustmentPenaltyAmount(calculateComputedServiceAdjustmentPenaltyAmount(true))
      setComputedServiceAdjustmentPenaltyPercent(calculateComputedServiceAdjustmentPenaltyPercent(true))
    }
  }, [project, errors])

  if (project === null) {
    return <div>...</div>
  }

  return (
    isProjectPresales() && (
      <ProjectServiceAdjustmentPenaltyStyled>
        <div className="content-left">
          <div className="input-group">
            <Input
              className="value"
              name={t('project.revenue.service_adjustment_penalty.amount_value.arialabel')}
              title={t('project.revenue.service_adjustment_penalty.amount_value.text')}
              value={(serviceAdjustmentPenaltyAmount || serviceAdjustmentPenaltyAmount === 0) ? serviceAdjustmentPenaltyAmount : computedServiceAdjustmentPenaltyAmount}
              type="number"
              disabled={!serviceAdjustmentPenaltyAmountEditable || !isAllEditable || project.service === null || project.service < 0}
              onChange={(value) => setServiceAdjustmentPenaltyAmount(parseFloatWithAnySeparator(value))}
              onValidate={() => Number.isInteger(serviceAdjustmentPenaltyAmount)
                && fetchUpdateServiceAdjustmentPenalty(serviceAdjustmentPenaltyAmount, serviceAdjustmentPenaltyPercent)}
            />
            <i className={!serviceAdjustmentPenaltyAmountEditable || !isAllEditable ? 'disabled-input' : ''}>€</i>
          </div>
          <div className="input-group">
            <Input
              className="value"
              name={t('project.revenue.service_adjustment_penalty.percent_value.arialabel')}
              title={t('project.revenue.service_adjustment_penalty.percent_value.text')}
              value={(serviceAdjustmentPenaltyPercent || serviceAdjustmentPenaltyPercent === 0) ? serviceAdjustmentPenaltyPercent : computedServiceAdjustmentPenaltyPercent}
              type="number"
              disabled={!serviceAdjustmentPenaltyPercentEditable || !isAllEditable || project.service === null || project.service < 0}
              onChange={(value) => setServiceAdjustmentPenaltyPercent(parseFloatWithAnySeparator(value))}
              onValidate={() => Number.isInteger(serviceAdjustmentPenaltyPercent)
                && fetchUpdateServiceAdjustmentPenalty(serviceAdjustmentPenaltyAmount, serviceAdjustmentPenaltyPercent)}
            />
            <i className={!serviceAdjustmentPenaltyPercentEditable || !isAllEditable ? 'disabled-input' : ''}>%</i>
          </div>
        </div>
        <div>
          {
            isAllEditable
              ? (
                <Button
                  className="create"
                  name={t('project.revenue.service_adjustment_penalty.create.arialabel')}
                  text={t('project.revenue.service_adjustment_penalty.create.text')}
                  disabled={
                    project.service === null || project.service < 0
                    || (Number.isNaN(serviceAdjustmentPenaltyAmount) && Number.isNaN(serviceAdjustmentPenaltyPercent))
                    || (serviceAdjustmentPenaltyAmount === null && Number.isNaN(serviceAdjustmentPenaltyPercent))
                    || (serviceAdjustmentPenaltyPercent === null && Number.isNaN(serviceAdjustmentPenaltyAmount))
                  }
                  onClick={() => fetchUpdateServiceAdjustmentPenalty(serviceAdjustmentPenaltyAmount, serviceAdjustmentPenaltyPercent)}
                />
              )
              : (
                <Button
                  className="edit"
                  name={t('project.revenue.service_adjustment_penalty.edit.arialabel')}
                  text={t('project.revenue.service_adjustment_penalty.edit.text')}
                  picto={pictoEdit}
                  onClick={() => setIsAllEditable(true)}
                />
              )
          }
        </div>
      </ProjectServiceAdjustmentPenaltyStyled>
    )
  )
}
