import styled from 'styled-components/macro'

export const StyledSkeletonRow = styled.tr`
  @keyframes aniHorizontal {
    0% {
      background-position: -100% 0;
    }

    100% {
      background-position: 100% 0;
    }
  }

  td{
    border-bottom: ${({ theme }) => `0.5px solid ${theme.colors.primaryDark}`};
    border-right: ${({ theme }) => `0.5px solid ${theme.colors.primaryDark}`};
    padding: 8px;
    position: relative;
    :before {
      content: "";
      top: 0;
      left: 0;
      position: absolute;
      mix-blend-mode: overlay;
      width: 100%;
      height: 100%;
      animation-name: aniHorizontal;
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      background: ${({ theme }) => `linear-gradient(
        to right,
        ${theme.colors.skeletonGreyLight} 0%,
        ${theme.colors.skeletonGreyDark} 50%,
        ${theme.colors.skeletonGreyLight} 100%
      )`};
      background-size: 50%;
    }

    :first-child {
      border-left: ${({ theme }) => `0.5px solid ${theme.colors.primaryDark}`};
      div {
        margin: 0;
      }
    }

    div {
      width: 80%;
      height: 14px;
      border-radius: 10px;
      margin: auto;
      background: ${({ theme }) => theme.colors.skeletonGreyLight};
    }
  }
`
