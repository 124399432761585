import React from 'react'
import { getNumberAsPercentage } from '../../helpers/stringHelper'
import { ProgressBarStyled } from './ProgressBar.styled'
import dangerRedFull from '../../../assets/images/dangerRedFull.svg'
import checkGreen from '../../../assets/images/checkGreen.svg'

export const ProgressBar = ({ progression, withProgressionIcon }) => {
  const fillingColorClassName = () => {
    if (progression > 1) return 'too-full'
    if (progression === 1) return 'full'
    return 'not-full'
  }

  const progressionIcon = () => {
    if (progression > 1) return <img src={dangerRedFull} alt="progress bar is too full icon" />
    if (progression === 1) return <img src={checkGreen} alt="progress bar is full icon" />
    return ''
  }

  return (
    <ProgressBarStyled className="progress-bar" percentage={progression}>
      <div className="container">
        <div className={`bar ${fillingColorClassName()}`} />
        <span className="percentage">
          {withProgressionIcon && progressionIcon()}
          {getNumberAsPercentage(progression)}
        </span>
      </div>
    </ProgressBarStyled>
  )
}
