import React from 'react'
import { ThemeProvider } from 'styled-components/macro'

import { ProjectQualityScreenStyled } from './ProjectQualityScreen.styled'
import { ProjectQualityContextProvider } from '../../context/ProjectQuality/ProjectQualityContext'
import { ProjectQualityTab } from '../../components/ProjectQualityTab/ProjectQualityTab'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'

export const ProjectQualityScreen = ({ id }) => (
  <ProjectQualityContextProvider id={id}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ProjectQualityScreenStyled>
        <ProjectQualityTab />
      </ProjectQualityScreenStyled>
    </ThemeProvider>
  </ProjectQualityContextProvider>
)
