import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectCoordinationContext } from '../../context/ProjectCoordination/ProjectCoordinationContext'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectExpensesTimeSpentTable } from './ProjectExpensesTimeSpentTable/ProjectExpensesTimeSpentTable'
import Loader from '../input/_ExportDS/Loader/Loader'
import { ProjectInlineStaffingForm } from '../ProjectInlineStaffingForm/ProjectInlineStaffingForm'
import { useJQuery } from '../../hooks/useJQuery/useJQuery'

export const ProjectExpensesTimeSpentTables = () => {
  const { t } = useTranslation()
  const { publish } = useJQuery()

  const {
    months,
    monthsWithValidation,
    expensesConsumption,
    totalAmountsConsumedPerMonths,
    totalConsumption,
    tableItemsDeleteMode,
  } = useProjectCoordinationContext()

  const {
    inlineStaffingFormVisible,
    setInlineStaffingFormVisible,
    turnoverTypeForm,
    setExpenseIdToUpdate,
  } = useProjectSalesContext()

  if (!months || !monthsWithValidation) return <Loader />

  return (
    <section>
      {
        <>
          <ProjectExpensesTimeSpentTable
            title={t('project.coordination.reporting.consumption_table.title.intragroup')}
            months={months}
            monthsWithValidation={monthsWithValidation}
            expensesConsumption={expensesConsumption?.intragroup || []}
            totalAmountsConsumedPerMonths={totalAmountsConsumedPerMonths?.intragroup || []}
            totalConsumption={totalConsumption?.intragroup || 0}
            expenseType="intragroup"
          />
          {
            (inlineStaffingFormVisible && !tableItemsDeleteMode && turnoverTypeForm === 'intragroup' && (
              <ProjectInlineStaffingForm
                onClose={() => {
                  setExpenseIdToUpdate(0)
                  publish('/octopod/project/update_kpis')
                  setInlineStaffingFormVisible(false)
                }}
              />
            ))
          }
        </>
      }
      {
        <>
          <ProjectExpensesTimeSpentTable
            title={t('project.coordination.reporting.consumption_table.title.subcontracting')}
            months={months}
            monthsWithValidation={monthsWithValidation}
            expensesConsumption={expensesConsumption?.subcontracting || []}
            totalAmountsConsumedPerMonths={totalAmountsConsumedPerMonths?.subcontracting || []}
            totalConsumption={totalConsumption?.subcontracting || 0}
            expenseType="subcontracting"
          />
          {
         (inlineStaffingFormVisible && !tableItemsDeleteMode && turnoverTypeForm === 'subcontracting' && (
         <ProjectInlineStaffingForm
           onClose={() => {
             setExpenseIdToUpdate(0)
             publish('/octopod/project/update_kpis')
             setInlineStaffingFormVisible(false)
           }}
         />
         ))
        }
        </>
      }
    </section>
  )
}
