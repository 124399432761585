import React, { useState, useContext, createContext, useEffect } from 'react'
import { useFetch } from '../../fetchOverviewApi'
import { useJQuery } from '../../hooks/useJQuery/useJQuery'

const ProjectSummaryContext = createContext()

export const ProjectSummaryContextProvider = ({ children, id }) => {
  const [errors, setErrors] = useState([])
  const [project, setProject] = useState(null)
  const [projectAffiliated, setProjectAffiliated] = useState(null)
  const [turnoverBreakdownUnvalidatedMonths, setTurnoverBreakdownUnvalidatedMonths] = useState(null)
  const [allMonthsValidated, setAllMonthsValidated] = useState(null)
  const [paymentSchedule, setPaymentSchedule] = useState(null)
  const [cuttedAmount, setCuttedAmount] = useState(null)
  const [turnoverStillToBeDone, setTurnoverStillToBeDone] = useState(null)
  const [totalAmountProject, setTotalAmountProject] = useState(null)
  const [cuttedAmountPercent, setCuttedAmountPercent] = useState(null)
  const [turnoverStillToBeDonePercent, setTurnoverStillToBeDonePercent] = useState(null)
  const [totalAmountProjectPercent, setTotalAmountProjectPercent] = useState(null)
  const [projectErrors, setProjectErrors] = useState({})
  const [projectPricingWarning, setProjectPricingWarning] = useState(null)

  const { subscribe } = useJQuery()

  const fetchGetProject = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}.json`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProject(response.project)
    }
  }

  const fetchProjectAffiliated = async () => {
    const response = await useFetch('GET', `/projects_affiliations/${project.projects_affiliation_id}`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProjectAffiliated(response.projects_affiliation)
    }
  }

  const fetchGetTurnoverBreakdownMonthsToValidate = async () => {
    const response = await useFetch('GET', `/turnover_breakdowns/${id}/unvalidated_months`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setTurnoverBreakdownUnvalidatedMonths(response.unvalidated_turnover_breakdown_months)
      setAllMonthsValidated(response.all_months_validated)
    }
  }

  const fetchProjectErrors = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/project_error`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProjectErrors(response.error_values)
    }
  }

  const fetchProjectPricingWarning = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/pricing_warning`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProjectPricingWarning({
        priceWarningStartDate: response.price_warning_start_date ? response.price_warning_start_date : false,
        priceWarningEndDate: response.price_warning_end_date ? response.price_warning_end_date : false,
      })
    }
  }

  const fetchGetPaymentSchedule = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/payment_schedule`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setPaymentSchedule(response.payment_schedule)
    }
  }

  const fetchGetSummaryTurnover = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/summary_turnover`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setCuttedAmount(response.cutted_amount)
      setTurnoverStillToBeDone(response.turnover_still_to_be_done)
      setTotalAmountProject(response.total_amount_project)
      setCuttedAmountPercent(response.cutted_amount_percent)
      setTurnoverStillToBeDonePercent(response.turnover_still_to_be_done_percent)
      setTotalAmountProjectPercent(response.total_amount_project_percent)
    }
  }

  useEffect(() => {
    subscribe('/octopod/project/update_project', () => {
      fetchGetProject()
    }, 0)
    subscribe('/octopod/tabchange', () => {
      fetchGetProject()
      fetchGetTurnoverBreakdownMonthsToValidate()
      fetchGetPaymentSchedule()
      fetchGetSummaryTurnover()
      fetchProjectErrors()
      fetchProjectPricingWarning()
    }, 0)
    fetchGetProject()
    fetchGetTurnoverBreakdownMonthsToValidate()
    fetchGetPaymentSchedule()
    fetchGetSummaryTurnover()
    fetchProjectErrors()
    fetchProjectPricingWarning()
  }, [])

  return (
    <ProjectSummaryContext.Provider value={{
      errors,
      project,
      projectErrors,
      projectPricingWarning,
      cuttedAmount,
      cuttedAmountPercent,
      turnoverStillToBeDone,
      turnoverStillToBeDonePercent,
      totalAmountProject,
      totalAmountProjectPercent,
      turnoverBreakdownUnvalidatedMonths,
      allMonthsValidated,
      projectAffiliated,
      paymentSchedule,
      fetchGetProject,
      fetchProjectAffiliated,
      fetchGetTurnoverBreakdownMonthsToValidate,
      fetchGetPaymentSchedule,
      fetchGetSummaryTurnover,
    }}
    >
      {children}
    </ProjectSummaryContext.Provider>
  )
}

export const useProjectSummaryContext = () => useContext(ProjectSummaryContext)
