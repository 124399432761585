import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { StyledBackdrop } from './Backdrop.styled'

const Backdrop = ({
  open,
  close,
  top,
}) => {
  const closeOnEscape = (event) => {
    if (event.keyCode === 27) close()
  }

  useEffect(() => {
    document.addEventListener('keydown', closeOnEscape)

    return () => {
      document.removeEventListener('keydown', closeOnEscape)
    }
  }, [])

  return ReactDOM.createPortal(
    <CSSTransition
      in={open}
      mountOnEnter
      unmountOnExit
      timeout={300}
      classNames="backdrop"
    >
      <StyledBackdrop
        role="backdrop"
        onClick={close}
        open={open}
        top={top}
      />
    </CSSTransition>,
    document.getElementById('backdrop-hook'),
  )
}

export default Backdrop
