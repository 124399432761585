import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useTimesheetStateValue } from './TimesheetStateContext'
import { usePeriodStateValue } from './PeriodStateContext'
import { requestWithCSRFToken } from '../../javascripts/HttpService'

export const TimesheetErrorsContext = createContext()

export const TimesheetErrorsProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const { periods, timesheet } = useTimesheetStateValue()
  const { setFirstPeriodFillRate, setSecondPeriodFillRate } = usePeriodStateValue()
  const [periodErrors, setPeriodErrors] = useState({ firstPeriod: { messages: [], blocking_messages: [] }, secondPeriod: { messages: [], blocking_messages: [] } })
  const [timesheetErrors, setTimesheetErrors] = useState({ activities: [], messages: [], has_blocking_errors: false })

  const body = {
    locale: i18n.language,
  }

  const checkPeriodRequest = useCallback(
    (periodNumber) => {
      const period = periods[periodNumber - 1]
      const url = `/periods/${period.id}/check`
      requestWithCSRFToken(url, {
        method: 'POST',
        body: JSON.stringify(body),
      })
        .then((responseData) => {
          if (periodNumber === 1) {
            setPeriodErrors((prevState) => ({ ...prevState, firstPeriod: responseData }))
            setFirstPeriodFillRate(responseData.fill_rate)
          }

          if (periodNumber === 2) {
            setPeriodErrors((prevState) => ({ ...prevState, secondPeriod: responseData }))
            setSecondPeriodFillRate(responseData.fill_rate)
          }
        })
      // eslint-disable-next-line no-console
        .catch((error) => console.log(error))
    }, [periods],
  )

  const checkTimesheetRequest = useCallback(
    () => {
      const url = `/timesheet/${timesheet.id}/check`

      requestWithCSRFToken(url, {
        method: 'POST',
        body: JSON.stringify(body),
      })
        .then((responseData) => setTimesheetErrors(responseData))
      // eslint-disable-next-line no-console
        .catch((error) => console.log(error))
    }, [timesheet],
  )

  useEffect(() => {
    checkTimesheetRequest()
    checkPeriodRequest(1)
    checkPeriodRequest(2)
  }, [periods, timesheet])

  return (
    <TimesheetErrorsContext.Provider value={
      {
        periodErrors,
        setPeriodErrors,
        timesheetErrors,
        setTimesheetErrors,
      }
    }
    >
      {children}
    </TimesheetErrorsContext.Provider>
  )
}

export const useTimesheetErrors = () => {
  const {
    periodErrors,
    setPeriodErrors,
    timesheetErrors,
    setTimesheetErrors,
  } = useContext(TimesheetErrorsContext)

  const periodsHasErrors = periodErrors?.firstPeriod?.messages?.length > 0 || periodErrors?.secondPeriod?.messages?.length > 0
  const periodsHasBlockingErrors = periodErrors?.firstPeriod?.blocking_messages?.length > 0 || periodErrors?.secondPeriod?.blocking_messages?.length > 0
  const hasTimesheetErrors = timesheetErrors?.messages?.length > 0

  const firstPeriodErrorDays = periodErrors?.firstPeriod?.days || []
  const secondPeriodErrorDays = periodErrors?.secondPeriod?.days || []
  const firstPeriodBlockingDays = periodErrors?.firstPeriod?.blocking_days || []
  const secondPeriodBlockingDays = periodErrors?.secondPeriod?.blocking_days || []

  const errorDays = [...new Set([...firstPeriodErrorDays, ...secondPeriodErrorDays])]
  const blockingDays = [...new Set([...firstPeriodBlockingDays, ...secondPeriodBlockingDays])]
  const daysWithError = [...new Set([...errorDays, ...blockingDays])]

  const errorTypeForDay = (fullDay) => {
    if (blockingDays.includes(fullDay)) return 'blockingError'
    if (errorDays.includes(fullDay)) return 'error'
    return ''
  }

  return {
    periodErrors,
    setPeriodErrors,
    timesheetErrors,
    setTimesheetErrors,
    periodsHasErrors,
    periodsHasBlockingErrors,
    hasTimesheetErrors,
    errorDays,
    blockingDays,
    daysWithError,
    errorTypeForDay,
  }
}
