/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { InputSelect, InputDate, InputSearch } from '@produits-internes-oss/design-system-components'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import Button from '../input/_ExportDS/ButtonWithStyle/Button'
import Input from '../input/_ExportDS/InputWithStyle/Input'
import Textarea from '../input/_ExportDS/Textarea/Textarea'
import { ProjectSalesExpensesFormStyled } from './ProjectSalesExpensesForm.styled'

export const ProjectSalesExpensesForm = ({ category }) => {
  const { t } = useTranslation()
  const {
    forms,
    projectSalesExpenses,
    setFormHidden,
    fetchCreateProjectSalesExpense,
    fetchUpdateProjectSalesExpense,
    fetchGetProjectSubcontractors,
  } = useProjectSalesContext()
  const {
    id,
    label,
    comment,
    unit_price,
    turnover_type,
    month,
    quantity,
    subcontractor_id,
  } = projectSalesExpenses.find((projectSalesExpense) => projectSalesExpense.id === forms[category]?.id) || {}
  const [form, setForm] = useState(null)
  const [formValid, setFormValid] = useState(false)
  const [subcontractorInput, setSubcontractorInput] = useState(false)
  const [projectSubcontractors, setProjectSubcontractors] = useState({})

  const isValid = (form
    && (form.comment || comment)
    && (form.unit_price || unit_price)
    && (form.unit_price > 0 || unit_price > 0)
    && ['revenue_additional', 'chargeable', 'nonchargeable'].includes(form.category))

  const isChargeableAndNonChargeableAttributes = (form
    && (form.turnover_type || turnover_type)
    && (form.quantity || quantity)
    && (form.quantity > 0 || quantity > 0))

  const isSubcontractorExpenseAttributes = form && (form.subcontractor_id || subcontractor_id) && (form.month !== null || month !== null)

  const isFormValid = (type = turnover_type) => {
    if (type === 'subcontractor_expenses') {
      return isValid && isChargeableAndNonChargeableAttributes && isSubcontractorExpenseAttributes
    }

    return isValid && isChargeableAndNonChargeableAttributes
  }

  const getSubcontractors = async () => {
    const result = await fetchGetProjectSubcontractors()
    setProjectSubcontractors(result)
  }

  useEffect(() => {
    setForm({
      'id': id || null,
      'label': label || '',
      'comment': comment || '',
      'unit_price': unit_price || null,
      'turnover_type': turnover_type || 'misc',
      'month': month || '',
      'quantity': quantity || 1,
      'subcontractor_id': subcontractor_id || null,
      'category': category,
    })
    getSubcontractors()
    setFormValid(isFormValid())
    setSubcontractorInput((turnover_type && turnover_type === 'subcontractor_expenses') || (form && form.turnover_type === 'subcontractor_expenses'))
  }, [forms])

  if (!form || !forms[category]) {
    return null
  }

  const chargeableOptions = {
    data_driver: t('project.expenses.turnover_type.options.data_driver'),
    travel: t('project.expenses.turnover_type.options.travel'),
    misc: t('project.expenses.turnover_type.options.misc'),
  }

  const nonChargeableOptions = {
    subcontractor_expenses: t('project.expenses.turnover_type.options.subcontractor_expenses'),
    data_driver: t('project.expenses.turnover_type.options.data_driver'),
    travel: t('project.expenses.turnover_type.options.travel'),
    misc: t('project.expenses.turnover_type.options.misc'),
  }

  return (
    <ProjectSalesExpensesFormStyled>
      <div className="header">
        <strong>{t(`project.expenses.${category}.requirements`)}</strong>
        {
          category === 'revenue_additional' && (
            <div className="example">
              <em>{t(`project.expenses.example`)}</em>
              <span>{t(`project.expenses.${category}.create.example`)}</span>
            </div>
          )
        }
      </div>
      <div className="form">
        <div className="form-values">
          <div className="form-values-row">
            {
              (category === 'chargeable' || category === 'nonchargeable') && (
                <InputSelect
                  className="type"
                  skin="light"
                  name="input search example"
                  valueInitial={[turnover_type]}
                  required
                  title={t(`project.expenses.turnover_type.title`)}
                  options={category === 'chargeable' ? chargeableOptions : nonChargeableOptions}
                  localization={{ validation: { text: 'Sauvegarder', arialabel: 'sauvegarder' } }}
                  multiple={false}
                  onChange={([type]) => {
                    setForm({ ...form, 'turnover_type': type })
                    setFormValid(isFormValid(type))
                    setSubcontractorInput((type === 'subcontractor_expenses'))
                  }}
                />
              )
            }
            <Input
              className="amount"
              type="number"
              name={t(`project.expenses.${category}.amount.arialabel`)}
              title={t(`project.expenses.${category}.amount.text`)}
              value={form.unit_price}
              symbol="€"
              onChange={(value) => {
                setForm({ ...form, 'unit_price': value })
                setFormValid(isFormValid())
              }}
            />
            {
              (category === 'chargeable' || category === 'nonchargeable') ? (
                <Input
                  className="quantity"
                  name={t(`project.expenses.${category}.quantity.arialabel`)}
                  title={t(`project.expenses.${category}.quantity.text`)}
                  value={form.quantity}
                  onChange={(value) => {
                    setForm({ ...form, quantity: value })
                    setFormValid(isFormValid())
                  }}
                />
              ) : (
                <Input
                  className="name"
                  name={t(`project.expenses.${category}.name.arialabel`)}
                  title={t(`project.expenses.${category}.name.text`)}
                  value={form.label}
                  onChange={(value) => {
                    setForm({ ...form, 'label': value })
                    setFormValid(isFormValid())
                  }}
                />
              )
            }
          </div>
          <div className="form-values-row last-row">
            {
              subcontractorInput && (
                <>
                  <InputSearch
                    className="suncontractor"
                    skin="light"
                    name="Nom du sous-traitant"
                    title="Nom du sous-traitant"
                    required
                    valueInitial={{ key: subcontractor_id, search: projectSubcontractors[subcontractor_id] }}
                    onSearch={async (search) => {
                      const subcontractors = await fetchGetProjectSubcontractors()
                      return Object.fromEntries(Object.entries(subcontractors).filter(([, value]) => value.toLowerCase().includes(search.toLowerCase())))
                    }}
                    onChange={({ key, search }) => {
                      setForm({ ...form, 'subcontractor_id': parseFloat(key), 'subcontractor_name': search })
                      setFormValid(isFormValid())
                    }}
                  />
                  <InputDate
                    className="month"
                    skin="light"
                    name="Mois"
                    title="Mois"
                    required
                    valueInitial={month && new Date(month)}
                    onCheck={() => {}}
                    onChange={(value) => {
                      setForm({ ...form, 'month': value })
                    }}
                  />
                </>
              )
            }
          </div>
          <Textarea
            className="comment"
            name={t(`project.expenses.${category}.comment.arialabel`)}
            title={t(`project.expenses.${category}.comment.text`)}
            value={form.comment}
            maxLength={255}
            onChange={(value) => {
              setForm({ ...form, 'comment': value })
              setFormValid(isFormValid())
            }}
          />
          <div className="max">{t(`project.expenses.${category}.comment.max`, { max: 255 })}</div>
        </div>
        <div className="form-button">
          <div className="form-cancel">
            <Button
              name={t(`project.expenses.${category}.cancel.arialabel`)}
              text={t(`project.expenses.${category}.cancel.text`)}
              onClick={() => { setFormHidden(category) }}
            />
          </div>
          <div className="form-submit">
            {
            forms[category].id
              ? (
                <Button
                  name={t(`project.expenses.${category}.update.arialabel`)}
                  text={t(`project.expenses.${category}.update.full`)}
                  disabled={!formValid}
                  onClick={async () => (await fetchUpdateProjectSalesExpense(form)) && setFormHidden(category)}
                />
              )
              : (
                <Button
                  name={t(`project.expenses.${category}.create.arialabel`)}
                  text={t(`project.expenses.${category}.create.text`)}
                  disabled={!formValid}
                  onClick={async () => (await fetchCreateProjectSalesExpense(form)) && setFormHidden(category)}
                />
              )
          }
          </div>
        </div>
      </div>
    </ProjectSalesExpensesFormStyled>
  )
}
