import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledActivityOverviewRow } from './ActivityOverviewRow.styled'

const ActivityOverviewRow = ({
  data, onClick, open, style,
}) => {
  const { i18n } = useTranslation()
  const locale = i18n.language

  return (
    <StyledActivityOverviewRow
      className={style}
      open={open}
      data={data}
      onClick={onClick}
    >
      <td className="firstCol">
        <div className={style}>
          <svg width="14" height="14">
            <line className="vertical" x1="7" x2="7" y1="0" y2="14" strokeLinecap="butt" strokeWidth="2" />
            <line className="horizontal" x1="0" x2="14" y1="7" y2="7" strokeLinecap="butt" strokeWidth="2" />
          </svg>
          <div>{data.name}</div>
        </div>
      </td>
      <td>{`${new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(data.activity_rate * 100)}%`}</td>
      <td>{`${new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(data.activity_rate_pipe * 100)}%`}</td>
      <td>{`${new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(data.input_rate * 100)}%`}</td>
      <td>{new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(data.fte)}</td>
      <td>{new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(data.business_days)}</td>
      <td>{new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(data.billed_days)}</td>
      <td>{new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(data.unbilled_days)}</td>
      <td>{new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(data.prestaffing_days)}</td>
      <td>{new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(data.absence_days)}</td>
      <td className="no-wrap">{new Intl.NumberFormat(locale, { style: 'currency', currency: data.currency_code }).format(data.turnover)}</td>
      <td className="no-wrap">{new Intl.NumberFormat(locale, { style: 'currency', currency: data.currency_code }).format(data.brokendown_turnover)}</td>
      <td>{new Intl.NumberFormat(locale, { style: 'currency', currency: data.currency_code }).format(data.average_daily_rate)}</td>
      <td>{new Intl.NumberFormat(locale, { style: 'currency', currency: data.currency_code }).format(data.brokendown_average_daily_rate)}</td>
    </StyledActivityOverviewRow>
  )
}

export default ActivityOverviewRow
