import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import ProjectPicker from '../../components/input/ProjectPicker/ProjectPicker'
import './ProjectExpenseReports.scss'
import ExpenseReportsTable from '../../components/ExpenseReportsTable/ExpenseReportsTable'
import { request } from '../../javascripts/HttpService'

const ExpenseReportScreen = ({ project = {} }) => {
  const [selectedProject, setSelectedProject] = useState(project)
  const [expenseList, setExpenseList] = useState()
  const { t } = useTranslation()

  const getProjectExpenses = () => {
    const url = `/projects/${selectedProject.id}/expenses_total_amount_by_invoice_kind?load_expenses=true`

    request(url, { method: 'GET' })
      .then((data_json) => {
        setExpenseList(data_json)
      })
      .catch((error) => console.error(error))
  }

  const handleProjectChange = (project) => (
    setSelectedProject({
      id: project.selectedValue,
      label: project.label,
    })
  )

  useEffect(() => {
    getProjectExpenses()
  }, [selectedProject])

  return (
    <div className="project-expenses-reports">
      <div className="project-expenses-reports-header">
        <h1>{t('projectExpenseReports.title')}</h1>
        <div className="project-search-section">
          <label>{t('projectExpenseReports.label')}</label>
          <div className="project-picker">
            <ProjectPicker
              value={selectedProject}
              onChange={handleProjectChange}
              scope="all"
            />
          </div>
        </div>
      </div>
      <div className="project-expenses-reports-body">
        {selectedProject.id && (
          <a className="btn-project" href={`/projects/${selectedProject.id}`}>
            {t('projectExpenseReports.project.link')}
          </a>
        )}
        {expenseList && (
          <ExpenseReportsTable
            projectExpenses={expenseList}
          />
        )}
      </div>
    </div>
  )
}

export default ExpenseReportScreen
