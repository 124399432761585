import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../input/_ExportDS/Button/Button'
import { SynchronizationCardStyled } from './SynchonizationCard.styled'
import Link from '../../Link/Link'

export default function SynchronizationCard({
  action,
  picto,
  textCard,
  executionDate,
  updaterInfo,
  buttonText,
  disableButton,
  handleClick,
  pictoButton,
  isButton,
  isButtonGray,
}) {
  const { t } = useTranslation()

  function displayUpdateInfos() {
    return (
      <div className="updater-info">
        <span>
          {action}
          {updaterInfo}
        </span>
        <span>
          {t('synchronization.launch.nameBy')}
          {executionDate}
        </span>
      </div>
    )
  }

  return (
    <SynchronizationCardStyled isButton={isButton} isButtonGray={isButtonGray}>
      <div className="card-header">
        <img aria-label="picto card" src={picto} />
        <div className="infos-header">
          <span>
            {textCard}
          </span>
          {updaterInfo ? displayUpdateInfos() : ''}
        </div>
      </div>
      {isButton ? (
        <Button
          name={buttonText}
          onClick={async () => { await handleClick() }}
          text={buttonText}
          picto={pictoButton}
          disabled={disableButton}
        />
      )
        : (
          <Link
            className="button-link gray"
            name="button-synchro"
            href="https://mytenext.accenture.com/#/time"
          >
            <span>
              {buttonText}
            </span>
            <img aria-label="picto card button" src={pictoButton} />

          </Link>
        )}
    </SynchronizationCardStyled>
  )
}
