import styled from 'styled-components'

export default styled.th`
  &.current-day {
    background-color: ${({ theme }) => theme.colors.paleBlue3};
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.1rem;
    height: 2.1rem;
    margin: auto;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primaryBlue};

    &.error {
      background-color: ${({ theme }) => theme.colors.betterway.orange[70]};
    }

    &.missing {
      background-color: ${({ theme }) => theme.colors.betterway.orange[70]};
    }
  }
`
