import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledDoubleProgressCircle, StyledCircleBar } from './DoubleProgressCircle.styled.jsx'

const DoubleProgressCircle = ({
  monthlyRate,
  yearlyRate,
}) => (
  <StyledDoubleProgressCircle>
    <CircleBar
      percentage={yearlyRate}
      radius={23}
      type="yearly"
    />
    <CircleBar
      percentage={monthlyRate}
      radius={30}
      type="monthly"
    />
  </StyledDoubleProgressCircle>
)

export default DoubleProgressCircle

const CircleBar = ({
  percentage,
  radius,
  type,
}) => {
  const { t } = useTranslation()
  const svgHeight = 80
  const circleRadius = radius
  const circumference = 2 * Math.PI * circleRadius
  const [offset, setOffset] = useState(circumference)
  const [count, setCount] = useState(0)

  useEffect(() => {
    let timer
    let stepValue = count
    const duration = 10

    timer = setInterval(() => {
      stepValue < percentage ? stepValue += 1 : stepValue -= 1
      setCount(stepValue)
      setOffset(circumference - (stepValue / 100) * circumference)
      stepValue === percentage && clearInterval(timer)
    }, duration)

    return () => {
      clearInterval(timer)
    }
  }, [percentage])

  return (
    <>
      <StyledCircleBar
        width="100"
        height={svgHeight}
        circumference={circumference}
      >
        <circle
          r={radius}
          cx="50"
          cy={svgHeight / 2}
        />
        <circle
          r={radius}
          cx={-svgHeight / 2}
          cy="50"
          style={{ strokeDashoffset: Math.max(offset, 0) }}
        />
      </StyledCircleBar>
      <span role={`progress-circle-percentage-${type}`}>
        {count}
        %
        {' '}
        {t(`${type}`)}
      </span>
    </>
  )
}
