import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledGdprFooter } from './GdprFooter.styled'

const GdprFooter = () => {
  const { t } = useTranslation()
  const gdprUrl = 'https://octo.atlassian.net/wiki/spaces/RGPD/pages/3134357576/Octopod'

  return (
    <StyledGdprFooter>
      {t('gdprFooter.accessibility')}
      <a href={gdprUrl} target="_blank" rel="noreferrer" aria-label="gdpr link">{t('gdprFooter.link')}</a>
    </StyledGdprFooter>
  )
}

export default GdprFooter
