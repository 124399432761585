import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePeriodStateValue, periodStatuses } from '../context/Timesheet/PeriodStateContext'
import { useTimeinputsContextValue } from '../context/Timesheet/TimeinputsContext'

export const usePeriodValidationHandler = (periodNumber) => {
  const { t } = useTranslation()
  const [openInvalidationPopin, setOpenInvalidationPopin] = useState(false)
  const [openValidationModal, setOpenValidationModal] = useState(false)
  const {
    invalidatePeriod,
    validatePeriod,
    firstPeriodStatus,
    secondPeriodStatus,
  } = usePeriodStateValue()
  const {
    firstPeriodTimeinputs,
    secondPeriodTimeinputs,
    totalInputsForFirstPeriod,
    totalInputsForSecondPeriod,
    totalFirstPeriodInputsForActivity,
    totalSecondPeriodInputsForActivity,
  } = useTimeinputsContextValue()

  const toggleInvalidationPopin = (setOpenCommentBlock) => {
    setOpenCommentBlock(false)
    const newOpenInvalidationPopinBlock = !openInvalidationPopin
    document.body.style.overflow = newOpenInvalidationPopinBlock ? 'hidden' : 'auto'
    setOpenInvalidationPopin(newOpenInvalidationPopinBlock)
  }

  const toggleValidationModal = (setOpenCommentBlock) => {
    setOpenCommentBlock(false)
    const newOpenValidationModal = !openValidationModal
    document.body.style.overflow = newOpenValidationModal ? 'hidden' : 'auto'
    setOpenValidationModal(newOpenValidationModal)
  }

  const statusToButton = {
    [periodStatuses.LOCKED]: {
      type: 'button outlined',
      text: t('period.locked'),
      action: () => { },
    },
    [periodStatuses.NOT_YET_VALIDATED]: {
      type: 'button',
      text: t('period.validate'),
      action: toggleValidationModal,
    },
    [periodStatuses.VALIDATED]: {
      type: 'button outlined',
      text: t('period.invalidate'),
      action: toggleInvalidationPopin,
    },
  }

  const periodStatus = periodNumber === 1 ? firstPeriodStatus : secondPeriodStatus
  const periodTimeinputs = periodNumber === 1 ? firstPeriodTimeinputs : secondPeriodTimeinputs
  const totalInputsForPeriod = periodNumber === 1 ? totalInputsForFirstPeriod : totalInputsForSecondPeriod
  const totalPeriodInputsForActivity = periodNumber === 1 ? totalFirstPeriodInputsForActivity : totalSecondPeriodInputsForActivity
  const isPeriodLocked = periodStatus === periodStatuses.LOCKED
  return {
    openInvalidationPopin,
    setOpenInvalidationPopin,
    openValidationModal,
    setOpenValidationModal,
    validatePeriod,
    invalidatePeriod,
    statusToButton,
    periodStatus,
    isPeriodLocked,
    periodTimeinputs,
    totalInputsForPeriod,
    totalPeriodInputsForActivity,
    toggleInvalidationPopin,
    toggleValidationModal,
  }
}
