import React, {
  createContext,
  useContext,
} from 'react'

export const TimesheetParamsContext = createContext()

export const TimesheetParamsProvider = ({ value, children }) => (
  <TimesheetParamsContext.Provider value={value}>
    {children}
  </TimesheetParamsContext.Provider>
)

export const useTimesheetParamsValue = () => useContext(TimesheetParamsContext)

export const timesheetParamsReducer = (state, action) => {
  switch (action.type) {
    case 'changeOwner':
      return {
        ...state,
        owner: action.owner,
      }
    case 'changeMonth':
      return {
        ...state,
        timesheetDate: action.timesheetDate,
      }
    default:
      return state
  }
}
