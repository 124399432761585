import styled from 'styled-components'

export const ProjectTurnoverBreakdownCommentStyled = styled.div`
  .button-comments {
    display: flex;
    align-items: center;
    background: rgb(0 0 0 / 0%);
    font-family: "Outfit", sans-serif;
    text-decoration: underline;

    >div {
      bottom: -22rem;
    }
  }

  .button-comments-bold {
    font-weight: bold;
  }

  .img-comment {
    margin-left: 0.5rem;
  }

`
