import React, {
  useState,
  createContext,
  useContext,
} from 'react'
import { useTimesheetStateValue } from './TimesheetStateContext'
import { requestWithCSRFToken } from '../../javascripts/HttpService'

export const periodStatuses = {
  LOCKED: 'locked',
  NOT_YET_VALIDATED: 'not_yet_validated',
  VALIDATED: 'validated',
}

export const PeriodStateContext = createContext()

export const PeriodStateProvider = ({ children, initialFirstPeriodStatus, initialSecondPeriodStatus }) => {
  const [firstPeriodFillRate, setFirstPeriodFillRate] = useState(0)
  const [secondPeriodFillRate, setSecondPeriodFillRate] = useState(0)
  const [firstPeriodStatus, setFirstPeriodStatus] = useState(initialFirstPeriodStatus)
  const [secondPeriodStatus, setSecondPeriodStatus] = useState(initialSecondPeriodStatus)
  const { periods, owner } = useTimesheetStateValue()

  const validatedFirstPeriodDays = ((firstPeriodStatus !== periodStatuses.NOT_YET_VALIDATED) && periods[0].range) || []
  const validatedSecondPeriodDays = ((secondPeriodStatus !== periodStatuses.NOT_YET_VALIDATED) && periods[1].range) || []
  const validatedPeriodDays = validatedFirstPeriodDays.concat(validatedSecondPeriodDays)

  const invalidatePeriod = (periodId, periodNumber) => {
    requestWithCSRFToken(`/periods/${periodId}/invalidate`, { method: 'POST' })
      .then(() => {
        if (periodNumber === 1) {
          setFirstPeriodStatus(periodStatuses.NOT_YET_VALIDATED)
        } else if (periodNumber === 2) {
          setSecondPeriodStatus(periodStatuses.NOT_YET_VALIDATED)
        }
      })
      /* eslint-disable-next-line no-console */
      .catch((error) => console.error(error))
  }

  const validatePeriod = (periodId, periodNumber) => {
    requestWithCSRFToken(`/periods/${periodId}/validate`, { method: 'POST' })
      .then(() => {
        if (periodNumber === 1) {
          setFirstPeriodStatus(periodStatuses.VALIDATED)
        } else if (periodNumber === 2) {
          setSecondPeriodStatus(periodStatuses.VALIDATED)
        }
      })
      /* eslint-disable-next-line no-console */
      .catch((error) => console.error(error))
  }

  const validationPeriodButtonDisabled = (periodErrors, hasTimesheetErrors, isPeriodLocked, periodNumber) => {
    const period = periods[periodNumber - 1]
    const isBeforeEntryDateOrAfterLeavingDate = period.end_date < owner.entry_date || (owner.leaving_date !== null && period.start_date > owner.leaving_date)
    return periodErrors?.has_blocking_errors || hasTimesheetErrors || isPeriodLocked || isBeforeEntryDateOrAfterLeavingDate
  }

  return (
    <PeriodStateContext.Provider value={
      {
        firstPeriodFillRate,
        setFirstPeriodFillRate,
        secondPeriodFillRate,
        setSecondPeriodFillRate,
        firstPeriodStatus,
        setFirstPeriodStatus,
        secondPeriodStatus,
        setSecondPeriodStatus,
        validatedPeriodDays,
        invalidatePeriod,
        validatePeriod,
        validationPeriodButtonDisabled,
      }
    }
    >
      {children}
    </PeriodStateContext.Provider>
  )
}

export const usePeriodStateValue = () => useContext(PeriodStateContext)
