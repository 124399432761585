import React from 'react'
import moment from 'moment'

import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import { Icon } from '@produits-internes-oss/design-system-components'
import { StyledCommentArea, StyledCommentPopin } from './Comment.styled'

import Backdrop from '../../../../../UI/Backdrop/Backdrop'

export const CommentPopin = ({
  open,
  close,
  initialComment,
  onChangeComment,
  updatedAt,
  updatedBy,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Backdrop open={open} close={close} />
      <CSSTransition
        in={open}
        mountOnEnter
        unmountOnExit
        timeout={0}
      >
        <StyledCommentPopin aria-label="comment-popin" updated={updatedAt}>
          <p className="gdpr-info">
            <Icon name="warning" className="warning" />
            <span className="text">{t('gdprInfoCRAComment')}</span>
          </p>
          <CommentArea
            initialComment={initialComment}
            onChangeComment={onChangeComment}
            updatedAt={updatedAt}
            updatedBy={updatedBy}
          />
        </StyledCommentPopin>
      </CSSTransition>
    </>
  )
}

export const CommentArea = ({
  initialComment,
  onChangeComment,
  updatedAt,
  updatedBy,
}) => {
  const { t } = useTranslation()

  const deltaTime = moment(updatedAt).fromNow()

  return (
    <StyledCommentArea>
      {
        updatedAt
        && <span id="comment-lastupdate" aria-label="comment-lastupdate">{t('lastUpdateComment', { date: deltaTime, person: updatedBy })}</span>
      }
      <textarea
        rows="4"
        placeholder={t('writeComment')}
        value={initialComment}
        aria-labelledby="comment-lastupdate"
        onChange={(e) => onChangeComment(e.target.value)}
      />
    </StyledCommentArea>
  )
}
