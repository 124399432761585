import styled, { css } from 'styled-components/macro'

export const StyledActivityOverviewRow = styled.tr`
  --font-size: 12px;
  --padding-left: 5px;
  --line-height: 30px;

  color: ${({ theme }) => theme.colors.primaryDark};
  line-height: var(--line-height);

  td {
    text-align: right;
    padding-right: 5px;
    font-size: 13px;
    font-weight: bold;
    border-bottom: ${({ theme }) => `0.5px solid ${theme.colors.primaryDark}`};
    border-right: ${({ theme }) => `0.5px solid ${theme.colors.primaryDark}`};
    &.firstCol {
      div {
        display: inline-grid;
        grid-template-columns: auto auto;
        align-items: center;
        div {
          font-size: var(--font-size);
          line-height: var(--line-height);
          padding-left: var(--padding-left);
          display: inline;
        }
        &.subsidiary {
          padding-left: 10px;
        }
        &.league {
          padding-left: 20px;
        }
        &.lob {
          padding-left: 30px;
        }
      }
    }
  }

  &.company {
    --line-height: 40px;
    --font-size: 18px;
  }

  &.subsidiary {
    --line-height: 35px;
    --font-size: 16px;
    --padding-left: 15px;
  }

  &.league {
    --font-size: 15px;
    --padding-left: 20px;
  }

  &.lob {
    --font-size: 14px;
    --padding-left: 25px;
  }


  svg {
    line {
      stroke: ${({ theme }) => theme.colors.primarySelected};

      &.vertical {
        transition: all 300ms ease-in-out;
      }
    }
  }

  ${({ open }) => open
    && css`
      line.vertical  {
        opacity: 0;
        transform: translateY(25%);
      }
    `
}
`
