import styled from 'styled-components'

export const ExpensesFormAttendeesPeopleStyled = styled.div`
>.form-search {
  display: flex;
  width: 100%;
  margin-bottom: 2.4rem;
}

.octo.hard.error > .control::before {
  border-color: ${({ theme }) => theme.colors.betterway.red[100]};
}

>.list-attendees {
  border: 0.1rem solid  ${({ theme }) => theme.colors.betterway.primary[20]};
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.betterway.white};
  font-family: 'Outfit', sans-serif;

  >ul {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    width: 100%;
    list-style-type: none;

    >li {
      display: flex;
      align-items: center;
      width: 22rem;
      height: fit-content;
      margin: 2rem 0;

      >div.octo.hard.default.primary.destroy {
        width: auto;

        >button {
          display: flex;
          gap: 0;
          width: auto;
          height: fit-content;
          padding: 0;
          border: none;
          border-style: none;

          &:hover {
            border-style: none;
            border-color: unset;
            background-color: transparent;
            color: unset;
          }
        }
      }
    }
  }
}

`
