import React from 'react'
import { getClass } from '../../helpers/stringHelper'
import Button from '../input/_ExportDS/Button/Button'
import ExpensesFormReceiptStyled from './ExpensesFormReceipt.styled'
import pictoClose from '../../../assets/images/close2.svg'

export default function ExpensesFormReceipt({ className, receipt, onDestroy }) {
  const { id, url, file } = receipt

  const name = `receipt ${id || url}`
  const isPDF = file && file.type === 'application/pdf'
  return (
    <ExpensesFormReceiptStyled className={className}>
      { isPDF ? <span className="name">{file.name}</span> : <img src={url} alt={name} /> }
      <Button
        className={getClass('destroy', isPDF ? '' : 'floating')}
        name={`${name} destroy`}
        picto={pictoClose}
        onClick={() => onDestroy(receipt)}
      />
    </ExpensesFormReceiptStyled>
  )
}
