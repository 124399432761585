import React from 'react'
import { useTranslation } from 'react-i18next'

import ExpensesReportByKind from './ExpensesReportByKind/ExpensesReportByKind'

const ExpenseReportsTable = ({ projectExpenses }) => {
  const { t } = useTranslation()

  return (
    <table className="expenses-reports-table">
      <thead>
        <tr>
          <th>{t('ExpensesReportTable.type')}</th>
          <th>{t('ExpensesReportTable.day')}</th>
          <th className="expense-owner">{t('ExpensesReportTable.person')}</th>
          <th>{t('ExpensesReportTable.amount')}</th>
          <th className="expense-description">
            {t('ExpensesReportTable.label')}
          </th>
          <th>{t('ExpensesReportTable.receipts')}</th>
        </tr>
      </thead>
      <tbody>
        {projectExpenses.expenses_by_kind.map((expense_by_kind, index) => (
          <ExpensesReportByKind
            key={index}
            kind={expense_by_kind.kind}
            total={expense_by_kind.total}
            currency={projectExpenses.currency_symbol}
            expenses={expense_by_kind.expenses}
          />
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={3}>{t('ExpensesReportTable.total_amount')}</td>
          <td>
            {projectExpenses.total}
            {' '}
            {projectExpenses.currency_symbol}
          </td>
          <td colSpan={2} />
        </tr>
      </tfoot>
    </table>
  )
}

export default ExpenseReportsTable
