import styled from 'styled-components'

export const PeriodPickerBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 18rem;
  margin: 2rem;
  padding: 1rem;
  padding: 1.5rem;
  border: solid 0.1rem ${({ theme }) => theme.colors.betterway.primary[100]};
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.betterway.white};

  button {
    margin: 1rem 0;
    border: solid 0.1rem ${({ theme }) => theme.colors.betterway.primary[100]};
    border-radius: 2.1rem;
    font-weight: bold;
    text-decoration: none;
  }

  .current-period-button {
    button {
      background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
      color: ${({ theme }) => theme.colors.betterway.white};
    }
  }
`
