import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectServiceAdjustmentPenaltyHeaderStyled } from './ProjectServiceAdjustmentPenaltyHeader.styled'
import { getNumberWithUnit, parseFloatWithAnySeparator } from '../../helpers/stringHelper'

export const ProjectServiceAdjustmentPenaltyHeader = () => {
  const { t } = useTranslation()
  const { project, presalesTextTranslation } = useProjectSalesContext()
  const hasAmount = project && project.service_adjustment_penalty
  const hasPercent = project && project.service_adjustment_penalty_percent

  return (
    <ProjectServiceAdjustmentPenaltyHeaderStyled>
      {
        (hasAmount || hasPercent)
          ? <span className="text-bold">{t('project.revenue.service_adjustment_penalty.present')}</span>
          : <span>{t(`project.revenue.service_adjustment_penalty.absent.${presalesTextTranslation()}`)}</span>
      }
      {
        hasAmount && (
        <span className="amount text-bold">
          {`${getNumberWithUnit(parseFloatWithAnySeparator(project.service_adjustment_penalty), '€')}`}
          <span className="percent">
            {' '}
            {`(${getNumberWithUnit(parseFloatWithAnySeparator((project.service_adjustment_penalty / project.service) * 100), '%')})`}
          </span>
        </span>
        )
      }
      {
        hasPercent && (
        <span className="amount text-bold">
          {`${getNumberWithUnit(parseFloatWithAnySeparator(project.service_adjustment_penalty_percent * project.service), '€')}`}
          <span className="percent">
            {' '}
            {`(${((project.service_adjustment_penalty_percent * 100) % 1 === 0)
              ? (project.service_adjustment_penalty_percent * 100)
              : (project.service_adjustment_penalty_percent * 100).toFixed(2)}%)`}
          </span>
        </span>
        )
      }
    </ProjectServiceAdjustmentPenaltyHeaderStyled>
  )
}
