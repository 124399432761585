import styled from 'styled-components'

export const StyledInvalidationPopin = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2rem 2rem 1rem 2rem;
	border-radius: 4px 4px 0 0;
	box-shadow: 0 4px 8px 0 ${({ theme }) => theme.colors.shadow};
	box-sizing: border-box;
	position: absolute;
	bottom: 100%;
	left: 0;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.blockingErrorLight};
	font-family: ${({ theme }) => theme.fonts.family.bold};

	p {
		width: 60%;
		text-align: left;
	}
`
