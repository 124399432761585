import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePeopleListingContext } from '../../../context/PeopleListing/PeopleListingContext'
import PeopleListingInputSearchStyled from './PeopleListingInputSearch.styled'

export default function PeopleListingInputSearch({ className }) {
  const { t } = useTranslation()
  const { setSearch } = usePeopleListingContext()
  return (
    <PeopleListingInputSearchStyled className={className}>
      <input
        aria-label="people listing search input"
        placeholder={t('people.listing.search.placeholder')}
        onChange={({ target }) => { setSearch(target.value) }}
      />
      <i className="fa fa-search" />
    </PeopleListingInputSearchStyled>
  )
}
