import styled from 'styled-components'

export const StyledNavHeader = styled.p`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grey};
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  font-family: ${({ theme }) => theme.fonts.family.standard}, sans-serif;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fonts.size.normal};
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: left;
  padding-left: 2.8rem;
  margin-bottom: .5rem;
  margin-top: 2rem;
`
