import styled from 'styled-components'

export const ProjectTurnoverBreakdownTotalAmountInputStyled = styled.div`
  .octo.hard > .control {
    min-width: 11rem;
    text-align: end;
  }

  .octo.hard.changed {
    input {
      text-decoration: underline;
    }
  }
`
