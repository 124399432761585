import React, { useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { StyledTimesheetHelp } from './TimesheetHelp.styled'
import { useTimesheetStateValue } from '../../../../context/Timesheet/TimesheetStateContext'
import Button from '../../../input/Buttons/Button/Button'
import HelpModal from '../../TimesheetModals/HelpModal/HelpModal'

const TimesheetHelp = () => {
  const { t, i18n } = useTranslation()
  moment.locale(`${i18n.language}`)
  const {
    timesheet,
    lastUpdater,
    owner,
    personRttCount,
    rttInfoHash,
  } = useTimesheetStateValue()

  const [openHelpModal, setOpenHelpModal] = useState(false)

  const openHelpModalHandler = () => {
    setOpenHelpModal(true)
    document.body.style.overflow = 'hidden'
  }

  const closeHelpModalHandler = () => {
    setOpenHelpModal(false)
    document.body.style.overflow = 'auto'
  }

  return (
    <StyledTimesheetHelp>
      <div>
        <p className="rtt-title">
          {t('timesheet.rtt.title', {
            rtt_count: personRttCount,
            start_day: moment().startOf('year').date(),
            start_month: moment().startOf('year').month() + 1,
            end_day: moment().endOf('year').date(),
            end_month: moment().endOf('year').month() + 1,
          })}
        </p>
        <span>•</span>
        <div className="tooltip">
          <p>
            {t('timesheet.rtt.info', {
              year: moment().year(),
              monthly_rtt_full_time: rttInfoHash.monthly_rtt_full_time,
              yearly_rtt_full_time: rttInfoHash.yearly_rtt_full_time,
              monthly_rtt_40h: rttInfoHash.monthly_rtt_40h,
              yearly_rtt_40h: rttInfoHash.yearly_rtt_40h,
              monthly_rtt_37h: rttInfoHash.monthly_rtt_37h,
              yearly_rtt_37h: rttInfoHash.yearly_rtt_37h,
            })}
            <a
              href="https://octolidays.octo.tools/"
              target="_blank"
              rel="noreferrer"
            >
              {t('timesheet.rtt.hrCounter')}
            </a>
          </p>
        </div>
      </div>
      {
        owner.modality_working_time === 'Hours'
          ? <p>{t('timesheet.workingTime.hours')}</p>
          : <p>{t('timesheet.workingTime.days')}</p>
      }
      <span>•</span>
      {lastUpdater && (
        <>
          <p>
            {`${t('timesheet.updatedAt')} ${moment(timesheet.last_modification_date).format('L')} `}
            {`${t('by')} ${lastUpdater.nickname}`}
          </p>
          <span>•</span>
        </>
      )}
      <Button
        role="button"
        click={openHelpModalHandler}
        type="text"
        color="primary"
      >
        {t('timesheet.help')}
      </Button>
      <HelpModal open={openHelpModal} close={closeHelpModalHandler} />
    </StyledTimesheetHelp>
  )
}

export default TimesheetHelp
