import styled from 'styled-components'
import textureProgress from '../../../../assets/images/progression.svg'
import textureProgressFull from '../../../../assets/images/progressionFull.svg'

export const SynchronizationProgressionStyled = styled.div`
  .progression-title {
    font-size: 1.6rem;
  }

  .progression-bar {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    height: 1.6rem;
    border-radius: 0.6rem;

    ::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[100]};
      border-radius: 0.6rem;
    }

    ::after {
      content: '';
      position: absolute;
      top: 0;
      right: ${({ percentage }) => (1 - percentage) * 100}%;
      bottom: 0;
      left: 0;
      border-radius: 0.6rem;
      background-image: url(${textureProgress});
      background-clip: border-box;
      background-repeat: repeat-x;
      transition: right 0.2s ease-in-out;
      animation-name: translation;
      animation-duration: 0.25s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }

  &.full {
    .progression-bar {
      ::after {
        background-image: url(${textureProgressFull});
        animation-duration: unset;
      }
    }
  }

  @keyframes translation {
    from {
      background-position-x: 0;
    }

    to {
      background-position-x: 18px;
    }
  }
`
