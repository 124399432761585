import styled from 'styled-components'
import { Dropdown } from '@produits-internes-oss/design-system-components'

export const ProjectFormFragmentStyled = styled(Dropdown)`
  background: none;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: normal;

  >.content {
    >.form-section {
      &:not(:first-child) {
        margin-top: 2.4rem;
      }

      > section {
        >.columns {
          display: flex;
          flex-flow: row nowrap;
          gap: 12.8rem;
          align-items: stretch;
          justify-content: stretch;

          >.column {
            flex: 1;

            > h3 {
              width: fit-content;
              margin-bottom: 4rem;
              padding-bottom: 0.8rem;
              border-bottom: 0.2rem solid ${({ theme }) => theme.colors.betterway.secondary[100]};
              font-weight: 600;
              font-size: 1.8rem;

              &:not(:first-child) {
                margin-top: 4rem;
              }
            }

            >.form-group {
              display: flex;
              flex-flow: column nowrap;
              gap: 3.2rem;
              align-items: stretch;
              justify-content: space-between;

              >.form-control {
                &.radio {
                  >.control {
                    > ul {
                      > li {
                        > button {
                          >.text {
                            font-size: 1.6rem;
                          }
                        }
                      }
                    }
                  }

                  &.radio-box {
                    >.control {
                      > ul {
                        max-height: 7.6rem;

                        > li {
                          width: 50%;
                        }
                      }
                    }
                  }

                  &.radio-inline {
                    >.control {
                      > ul {
                        flex-direction: row;

                        > li {
                          max-width: fit-content;

                          &:not(:last-child) {
                            margin-right: 4.8rem;
                          }
                        }
                      }
                    }
                  }
                }
              }

              &:not(:last-child) {
                margin-bottom: 2.4rem;
              }

              &.horizontal {
                flex-direction: row;
              }
            }
          }

          &.half {
            >.column {
              max-width: 50%;
            }
          }

          &.threefifth {
            >.column:first-child {
              flex: 3;
              max-width: calc((100% - 12.8rem) * (3 / 5));
            }

            >.column:last-child {
              flex: 2;
            }
          }
        }
      }
    }
  }
`
