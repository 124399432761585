import styled from 'styled-components/macro'

export const StyledPersonRow = styled.tr`
  color: ${({ theme }) => theme.colors.primaryDark};

  td {
    text-align: right;
    padding-right: 5px;
    line-height: 30px;
    font-size: 12px;
    border-bottom: ${({ theme }) => `0.5px solid ${theme.colors.primaryDark}`};
    border-right: ${({ theme }) => `0.5px solid ${theme.colors.primaryDark}`};
    &.firstCol {
      padding: 0;
      display: grid;
      grid-template-columns: minmax(110px, 1fr) 105px 110px;

      div {
        line-height: 32px;
        &:first-child {
          padding-left: 10px;
          text-transform: capitalize;
        }
      }

      .link {
        width: 100%;
        cursor: pointer;
        border-right: ${({ theme }) => `0.5px solid ${theme.colors.primaryDark}`};
        border-left: ${({ theme }) => `0.5px solid ${theme.colors.primaryDark}`};

        div {
          display: inline-block;
          width: 70%;
        }

        .nickname {
          padding-right: 5px;
          padding-left: 5px;
        }

        .warning:before {
          font-size: 14px;
        }
      }

      .job,
      .link {
        justify-self: center;
      }
    }
  }
`
