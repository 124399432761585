import styled from 'styled-components'

export default styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-around;

  >.calendar {
    width: calc(7 * 4rem);

    /* stylelint-disable-next-line selector-class-pattern */
    >.react-calendar__navigation {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      height: 3.2rem;
      margin-bottom: 1.4rem;

      /* stylelint-disable-next-line selector-class-pattern */
      >.react-calendar__navigation__label {
        flex-grow: 0 !important;
        height: 100%;
        padding: 0;
        padding: 0 0.4rem;
        background: none;
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        font-size: 16px;
        font-family: "Apercu-Medium", sans-serif;
        line-height: 1.5;
        letter-spacing: 0.012rem;
        text-transform: capitalize;
      }

      /* stylelint-disable-next-line selector-class-pattern */
      >.react-calendar__navigation__arrow {
        height: 2.8rem;
        padding: 0;
        background: none;
        color: ${({ theme }) => theme.colors.betterway.primary[100]};

        &:disabled {
          color: ${({ theme }) => theme.colors.betterway.primary[50]};
        }

        >.arrow {
          width: 2.8rem;
          height: 2.8rem;
          background-color: currentColor;
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
          mask-clip: border-box;

          &.previous {
            transform: scale(-1, 1);
            mask-image: ${(props) => `url(${props.pictoChevron}) `};
          }

          &.next {
            mask-image: ${(props) => `url(${props.pictoChevron}) `};
          }
        }
      }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .react-calendar__month-view__weekdays {
      /* stylelint-disable-next-line selector-class-pattern */
      >.react-calendar__month-view__weekdays__weekday {
        height: 1.6rem;
        margin-bottom: 1.6rem;
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        font-size: 1rem;
        font-size: 10px;
        font-family: "Apercu-Medium", sans-serif;
        line-height: 1.6;
        letter-spacing: 0.15rem;
        text-align: center;
        text-transform: uppercase;

        > abbr {
          border-bottom: none;
          text-decoration: none;
          cursor: default;
        }
      }
    }

    .calendar-tile {
      max-width: 3.2rem;
      height: 3.2rem;
      margin: 0.4rem;
      padding: 0;
      padding-left: 0.1rem;
      border-radius: 2rem;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.betterway.primary[70]};
      font-size: 1.4rem;
      text-align: center;

      &:hover {
        background-color: ${({ theme }) => theme.colors.betterway.primary[20]};
      }

      &.year {
        min-width: 6rem;
        font-size: 1.6rem;
      }

      /* stylelint-disable-next-line selector-class-pattern */
      &.react-calendar__tile--active {
        background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
        color: ${({ theme }) => theme.colors.betterway.primary[5]};
      }

      /* stylelint-disable-next-line selector-class-pattern */
      &:not(.react-calendar__month-view__days__day--neighboringMonth) {
        font-family: "Apercu-Bold", sans-serif;
      }
    }
  }

  >.selection {
    position: absolute;
    top: calc(50% - 1.5rem);
    right: 0;
    bottom: calc(50% - 1.5rem);
    left: 0;
    height: 3.2rem;
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.betterway.primary[5]};
  }

  >.error {
    border-color: ${({ theme }) => theme.colors.betterway.red[100]};
  }
`
