import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledExpenseSummaryRow } from './ExpenseSummaryRow.styled'

const ExpenseSummaryRow = ({ kind, total, currency }) => {
  const { t } = useTranslation()
  return (
    <StyledExpenseSummaryRow>
      <td className="table-border">{t(`ExpensesReportTable.total_${kind}`)}</td>
      <td className="text-aligned">
        {total}
        {' '}
        {currency}
      </td>
    </StyledExpenseSummaryRow>
  )
}

export default ExpenseSummaryRow
