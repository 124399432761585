import React from 'react'
import { clamp } from '../../../../helpers/numberHelper'
import TabsStyled from './Tabs.styled'

export default function Tabs({ name, tabs, tabSelected }) {
  const tabsIterable = Object.entries(tabs)
  const tabsIterableLength = tabsIterable.length
  const tabSelectedClamped = clamp(tabSelected, 0, tabsIterable.length - 1)

  return (
    <TabsStyled
      aria-label={`${name} tab list`}
      role="tablist"
    >
      <div
        className="track"
        style={{
          width: `calc(${tabsIterableLength} * 100%)`,
          left: `calc(-${tabSelectedClamped} * 100%)`,
        }}
      >
        {
          tabsIterable.map(([key, item]) => (
            <div
              key={key}
              className="tab"
              style={{ minWidth: `calc(1 / ${tabsIterableLength} * 100%)` }}
              aria-label={`${name} tab list tab ${key}`}
              role="tab"
            >
              {item}
            </div>
          ))
        }
      </div>
    </TabsStyled>
  )
}
