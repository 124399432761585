import styled from 'styled-components'
import Close from '../../../../assets/images/Close.svg'

export const StyledModal = styled.div`
  position: fixed;
  top: 15%;
  left: 50%;
  z-index: 2000;
  box-sizing: border-box;
  width: ${({ width }) => width} !important;
  height: ${({ height }) => height} !important;
  padding: ${({ nopadding }) => (nopadding ? null : '3.2rem 4rem')};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.colors.shadow};
  transform: translateX(-50%);

  &.modal-animated-enter {
    top: -150%;
    opacity: 0%;
  }

  &.modal-help {
    top: 10% !important;
  }

  &.modal-animated-enter-active {
    top: 15%;
    opacity: 100%;
    transition: all 300ms ease-in-out;
  }

  &.modal-animated-exit {
    top: 15%;
    opacity: 100%;
  }

  &.modal-animated-exit-active {
    top: -150%;
    opacity: 0%;
    transition: all 300ms ease-in-out;
  }

  .close-button {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 2rem;
    height: 2rem;
    border: none;
    background: url(${Close}) no-repeat center center;
    background-size: contain;
    cursor: pointer;

    &:hover {
      transform: translateY(-1px);
    }

    &:active {
      transform: translateY(0);
    }
  }
`
