import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@produits-internes-oss/design-system-components'
import pictoNavigateCross from '../../../../assets/images/iconsNavigateCross.svg'
import pictoWarningRed from '../../../../assets/images/warningFullRed.svg'
import { useProjectCoordinationContext } from '../../../context/ProjectCoordination/ProjectCoordinationContext'
import { useJQuery } from '../../../hooks/useJQuery/useJQuery'

import { ModalListDeleteActivityExpenseStyled } from './ModalListDeleteActivityExpense.styled'

export const ModalListDeleteActivityExpense = ({
  fetchDelete,
  setActivitiesDeleteMode,
  setOpenModalList,
  setInlineStaffingFormVisible,
}) => {
  const { t } = useTranslation()
  const { publish } = useJQuery()

  const {
    itemsToDelete,
    setTableItemsDeleteMode,
  } = useProjectCoordinationContext()

  const persons = () => {
    if (itemsToDelete.length <= 0) return []
    if (itemsToDelete[0].kind === 'intern') {
      return itemsToDelete.map((intern) => ({
        title: `${intern.title}`,
        name: intern.firstName ? `- ${intern.firstName} ${intern.lastNasme} / ${intern.nickname}` : '',
      }))
    }
    return itemsToDelete.map((subcontracting) => ({
      title: `${subcontracting.label}`,
      name: `${subcontracting.name}`,
    }))
  }

  return (
    <ModalListDeleteActivityExpenseStyled>
      <div className="container-modal">

        <div className="header-modal">
          <div className="title">
            <img
              aria-label="link description mission"
              src={pictoWarningRed}
            />
            <h2>{t('project.coordination.destroy.modal_title')}</h2>
          </div>
          <div
            className="picto-close"
            role="button"
            onClick={() => {
              setOpenModalList(false)
            }}
            onKeyDown={() => {
              setOpenModalList(false)
            }}
            tabIndex={0}
          >
            <img
              aria-label="link description mission"
              src={pictoNavigateCross}
            />
          </div>
        </div>
        <div className="content-modal">
          <div className="infos-text">{t('project.coordination.destroy.detroy_infos_list')}</div>
          <ul className="person-to-delete">
            {
              persons().map((person) => (
                <li key={person.title}>
                  <div className="text">
                    <span className="title">
                      {person.title}
                    </span>
                    <span className="name">
                      {person.name}
                    </span>
                  </div>

                </li>
              ))
            }
          </ul>
        </div>

        <div className="footer-modal">
          <Button
            className="cancel"
            skin="light"
            kind="secondary"
            name={t('project.activities.delete_activities')}
            text="Annuler"
            onClick={() => {
              setOpenModalList(false)
              setInlineStaffingFormVisible(false)
            }}
          />
          <Button
            className="delete-activity"
            skin="dark"
            name={t('project.coordination.destroy.validate_delete_list')}
            text={t('project.coordination.destroy.validate_delete_list')}
            onClick={() => {
              fetchDelete()
              publish('/octopod/ajax/reset_elements')
              setActivitiesDeleteMode(false)
              setTableItemsDeleteMode(false)
              setOpenModalList(false)
              setInlineStaffingFormVisible(false)
            }}
            kind="primary"
          />
        </div>
      </div>
    </ModalListDeleteActivityExpenseStyled>
  )
}
