import React, { useState, useCallback } from 'react'
import { Button, Icon, InputTextarea } from '@produits-internes-oss/design-system-components'
import { ProjectDataHatModalStyled } from './ProjectDataHatModal.styled'

export const ProjectDataHatModal = ({ title, localization, value, onChange }) => {
  const [update, setUpdate] = useState(null)

  const cleanHTML = useCallback((text) => {
    const div = document.createElement('div')
    div.innerHTML = text

    return div.textContent || div.innerText || ''
  }, [])

  return (
    <ProjectDataHatModalStyled
      title={title}
      trigger={({ open }) => (
        <button type="button" aria-label="open mission comment" onClick={() => open()}>
          <Icon name="comment" />
          <span>{localization.trigger}</span>
        </button>
      )}
    >
      {
        update === null ? <div className="text">{cleanHTML(value)}</div> : (
          <InputTextarea
            className="text"
            valueInitial={update}
            onChange={(other) => setUpdate(other)}
          />
        )
      }
      <div className="controls">
        {
          update === null
            ? <Button className="control" skin="dark" text={localization.edit} onClick={() => setUpdate(cleanHTML(value))} />
            : (
              <>
                <Button
                  className="control"
                  skin="light"
                  kind="secondary"
                  text={localization.cancel}
                  onClick={() => {
                    setUpdate(null)
                  }}
                />
                <Button
                  className="control"
                  skin="dark"
                  text={localization.validate}
                  onClick={() => {
                    setUpdate(null)
                    onChange(update)
                  }}
                />
              </>
            )
        }
      </div>
    </ProjectDataHatModalStyled>
  )
}
