import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePeopleListingContext } from '../../../context/PeopleListing/PeopleListingContext'
import Link from '../../Link/Link'
import PeopleListingInputSearch from '../PeopleListingInputSearch/PeopleListingInputSearch'
import PeopleListingHeaderStyled from './PeopleListingHeader.styled'

export default function PeopleListingHeader() {
  const { t } = useTranslation()
  const { urlAskbob, peopleFilteredBySearchAndSorted } = usePeopleListingContext()

  const count = peopleFilteredBySearchAndSorted.length
  return (
    <PeopleListingHeaderStyled>
      <h1>{`${t('people.listing.title')}`}</h1>
      <div className="count">
        {
          count > 1
            ? `${count} ${t('people.listing.recorded.multiple')}`
            : `${count === 1 ? count : t('none')} ${t('people.listing.recorded.single')}`
        }
      </div>
      <PeopleListingInputSearch className="search" />
      <div className="askbob">
        <span>{`${t('people.listing.askbob.info')} `}</span>
        <Link name="askbob" href={urlAskbob}>{`${t('people.listing.askbob.here')}`}</Link>
        <span>{`${t('people.listing.askbob.exclamation')}`}</span>
      </div>
    </PeopleListingHeaderStyled>
  )
}
