import React from 'react'
import LoaderStyled from './Loader.styled'

export default function Loader({ className }) {
  return (
    <LoaderStyled className={className}>
      <div className="spin">
        <div className="spin-interior" />
      </div>
    </LoaderStyled>
  )
}
