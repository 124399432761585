import styled from 'styled-components'

export const ProjectSatisfactionScreenStyled = styled.div`
  padding-bottom: 9.4rem;

  .copy {
    width: fit-content;
    margin-bottom: 3.5rem;

    &.octo.hard.default {
      > button {
        padding: 0;
        border: none;
        background: none;
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
        font-weight: normal;
        font-size: 1.4rem;
        text-decoration: underline;

        &:hover {
          background: none;
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
        }

        &:focus,
        &:focus-visible {
          border: none;
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          font-weight: bold;
        }

        ::before {
          content: none;
        }

        >.icon {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }

  .controls {
    display: flex;
    flex-flow: row nowrap;
    align-items: end;
    justify-content: space-between;

    >.year {
      width: fit-content;
      min-width: 30rem;
    }

    >.period {
      width: fit-content;
      min-width: 30rem;

      >.enum {
        height: 4.2rem;
        padding: 0;
        border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[70]};
        border-radius: 2rem;

        >.track {
          button {
            text-align: center;

            &:not(.selected) {
              color: ${({ theme }) => theme.colors.betterway.primary[100]};
              cursor: pointer;
            }
          }

          >.slider {
            box-sizing: border-box;
            border: 2px solid ${({ theme }) => theme.colors.betterway.white};
            border-radius: 2rem;
            background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
          }
        }
      }
    }
  }

  table {
    width: 100%;

    &:not(:only-child) {
      &:not(:first-child) {
        margin-top: 1.5em;
      }
    }

    thead {
      tr {
        th {
          vertical-align: middle;
          box-sizing: border-box;
          padding: 2rem 2.4rem;
          background-color: ${({ theme }) => theme.colors.betterway.primary[20]};
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          font-weight: 600;
          font-size: 1.4rem;
          text-align: left;
          text-transform: capitalize;

          &:first-child {
            border-top-left-radius: 2rem;
          }

          &:last-child {
            border-top-right-radius: 2rem;
          }

          &:not(:first-child) {
            border-left: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[70]};
          }
        }

        &:not(:first-child) {
          th {
            border-radius: 0;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: ${({ theme }) => theme.colors.betterway.primary[5]};
        }

        &:nth-child(odd) {
          background-color: ${({ theme }) => theme.colors.betterway.white};
        }

        td {
          vertical-align: middle;
          box-sizing: border-box;
          padding: 1.6rem 2.4rem;
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          font-size: 1.4rem;

          &.align-right {
            text-align: right;
          }

          &:not(:first-child) {
            border-left: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[70]};
          }
        }
      }
    }

    tfoot {
      tr {
        overflow: clip;

        td {
          vertical-align: middle;
          box-sizing: border-box;
          padding: 1.6rem 2.4rem;
          background-color: ${({ theme }) => theme.colors.betterway.primary[20]};
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          font-weight: 600;
          font-size: 1.4rem;
          text-align: left;

          &.align-right {
            text-align: right;
          }

          &:first-child {
            border-bottom-left-radius: 2rem;
          }

          &:last-child {
            border-bottom-right-radius: 2rem;
          }

          &:not(:first-child) {
            border-left: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[70]};
          }
        }
      }
    }
  }
`
