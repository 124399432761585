import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectRevenueInfoStyled } from './ProjectRevenueInfo.styled'

export const ProjectRevenueInfo = () => {
  const { t } = useTranslation()
  return (
    <ProjectRevenueInfoStyled>
      <strong className="header">{t('project.revenue.info.header')}</strong>
      <div className="content">
        <div className="content-header">{t('project.revenue.info.types')}</div>
        <ul>
          <li>
            <strong>{t('project.revenue.service.title')}</strong>
            <span>{t('project.revenue.service.help')}</span>
          </li>
          <li>
            <strong>{t('project.revenue.discount.title')}</strong>
            <span>{t('project.revenue.discount.help')}</span>
          </li>
          <li>
            <strong>{t('project.revenue.chargeable')}</strong>
          </li>
        </ul>
      </div>
    </ProjectRevenueInfoStyled>
  )
}
