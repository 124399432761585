import React, { useRef, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Section, Button, Modal, InputNumber, InputDate, InputTextarea, Table, Tooltip,
} from '@produits-internes-oss/design-system-components'
import Input from '../input/_ExportDS/InputWithStyle/Input'
import iconAddWhite from '../../../assets/images/addWhite.svg'
import iconDangerRed from '../../../assets/images/dangerRedEmpty.svg'
import warningRoundRed from '../../../assets/images/warningRoundRed.svg'
import pictoModify from '../../../assets/images/iconsModify.svg'
import picoDestroyEmpty from '../../../assets/images/destroyEmpty.svg'
import pictoCloseWhite from '../../../assets/images/crossWhite.svg'
import { getNumberWithUnit, getYearMonthDayFromDateString, getClass } from '../../helpers/stringHelper'
import { getRoundedToDecimal } from '../../helpers/numberHelper'
import { useProjectContractContext } from '../../context/ProjectContract/ProjectContractContext'
import { ProjectPaymentScheduleLinesStyled } from './ProjectPaymentScheduleLines.styled'
import pictoCopy from '../../../assets/images/copy.svg'

const DeletePaymentScheduleTooltip = ({ line }) => {
  const { t } = useTranslation()
  const { fetchDeletePaymentScheduleLine } = useProjectContractContext()

  return (
    <Tooltip
      className="tooltip-delete"
      position="left"
      skin="dark"
      trigger={
          ({ open }) => (
            <Button
              className="btn-destroy"
              skin="dark"
              name={t('project.contract.paymentScheduleLines.delete')}
              text={t('project.contract.paymentScheduleLines.delete')}
              onClick={() => open()}
              icon={{ url: picoDestroyEmpty, position: 'right' }}
            />
          )
        }
      content={
          ({ close }) => (
            <div className="tooptip-delete-content">
              <div className="header">
                <div className="title">{t('project.contract.paymentScheduleLines.delete')}</div>
                <button
                  type="button"
                  onClick={() => close()}
                  aria-label="close the modal"
                >
                  <img src={pictoCloseWhite} alt="cross" />
                </button>
              </div>
              <div className="body">
                <p>{t('project.contract.paymentScheduleLines.deleteConfirmationMessage.0')}</p>
                <p>{t('project.contract.paymentScheduleLines.deleteConfirmationMessage.1')}</p>
                <p>
                  {t('project.contract.paymentScheduleLines.deleteConfirmationMessage.2')}
                  <span className="text-bold">{t('project.contract.paymentScheduleLines.deleteConfirmationMessage.3')}</span>
                </p>
              </div>
              <div className="footer">
                <Button
                  className="cancel"
                  skin="dark"
                  kind="secondary"
                  name={t('project.contract.paymentScheduleLines.cancel')}
                  text={t('project.contract.paymentScheduleLines.cancel')}
                  onClick={() => close()}
                />
                <Button
                  className="delete"
                  skin="light"
                  kind="primary"
                  name={t('project.contract.paymentScheduleLines.delete')}
                  text={t('project.contract.paymentScheduleLines.delete')}
                  onClick={() => {
                    fetchDeletePaymentScheduleLine(line)
                    close()
                  }}
                />
              </div>
            </div>
          )
        }
      withCloseOnClickOutside
      withCloseOnEspace
    />
  )
}

const ModifyPaymentScheduleModal = ({ line }) => {
  const { t } = useTranslation()
  const { project, paymentSchedule, fetchPutPaymentScheduleLine } = useProjectContractContext()
  const [form, setForm] = useState({})

  useEffect(() => {
    setForm({
      id: null,
      amount: null,
      condition: null,
      payment_date: null,
      billed_date: null,
    })
  }, [])

  const isPaymentDateWhithinProjectDuration = (date) => {
    const projectStartDate = new Date(project.start_date)
    const projectEndDate = new Date(project.end_date)
    const selectedDate = new Date(date)
    return projectStartDate <= selectedDate && projectEndDate >= selectedDate
  }

  const isFormValid = () => (
    form && (form.amount !== null
      && form.amount !== 0
      && form.payment_date !== null
      && form.condition !== null
      && form.condition !== ''
      && isPaymentDateWhithinProjectDuration(form.payment_date)
    )
  )

  const handleFormEdit = (attributName, attributValue) => setForm((previous) => {
    const updatedStatus = { ...previous }
    updatedStatus[attributName] = attributValue
    updatedStatus['percentage'] = parseFloat((updatedStatus.amount * 100) / paymentSchedule.total_amount)
    return updatedStatus
  })

  return (
    <Modal
      skin="light"
      className="modal-payment-schedule-line"
      trigger={({ open }) => (
        <Button
          className="btn-modify"
          skin="dark"
          name={t('project.contract.paymentScheduleLines.edit')}
          text={t('project.contract.paymentScheduleLines.edit')}
          onClick={() => {
            setForm(line)
            open()
          }}
          icon={{ url: pictoModify, position: 'right' }}
        />
      )}
      onValidate={() => fetchPutPaymentScheduleLine(form)}
      disabled={!isFormValid()}
      localization={
        {
          validation: {
            text: t('project.contract.paymentScheduleLines.editLine'),
            arialabel: t('project.contract.paymentScheduleLines.editLine'),
          },
          cancel: {
            text: t('project.contract.paymentScheduleLines.cancel'),
            arialabel: t('project.contract.paymentScheduleLines.cancel'),
          },
        }
      }
    >
      <div className="payment-schedule-line-form">
        <div className="form-top-section">
          <InputNumber
            className="amount price-tag"
            skin="light"
            name={t('project.contract.paymentScheduleLines.amount')}
            title={t('project.contract.paymentScheduleLines.amount')}
            valueInitial={line?.amount}
            required
            localization={{
              invalid: { text: t('expenses.errors.mustBeANumber'), arialabel: t('expenses.errors.mustBeANumber') },
            }}
            onChange={(value) => handleFormEdit('amount', value)}
          />
          <Input
            className="percentage"
            name={t('project.contract.paymentScheduleLines.percentage')}
            title={t('project.contract.paymentScheduleLines.percentage')}
            value={getNumberWithUnit(((form.amount * 100) / paymentSchedule.total_amount), '%') || ''}
            type="text"
            disabled
          />
          <InputDate
            className="forecast-date"
            skin="light"
            name={t('project.contract.paymentScheduleLines.setForecastDate')}
            title={t('project.contract.paymentScheduleLines.setForecastDate')}
            valueInitial={line?.payment_date}
            required
            onCheck={(value) => (value && !isPaymentDateWhithinProjectDuration(value)) && `${t('project.contract.paymentScheduleLines.forecastDAteError')}`}
            onChange={(value) => handleFormEdit('payment_date', value)}
          />
          <InputDate
            className="billing-date"
            skin="light"
            name={t('project.contract.paymentScheduleLines.setBillingDate')}
            title={t('project.contract.paymentScheduleLines.setBillingDate')}
            valueInitial={line?.billed_date}
            onCheck={() => {}}
            onChange={(value) => handleFormEdit('billed_date', value)}
          />
        </div>
        <div className="form-bottom-section">
          <InputTextarea
            className="condition"
            skin="light"
            name={t('project.contract.paymentSchedule.comment')}
            title={t('project.contract.paymentSchedule.comment')}
            valueInitial={line?.condition}
            required
            maxLength={255}
            onChange={(value) => handleFormEdit('condition', value)}
          />
          <div className="condition-limit">
            <img src={warningRoundRed} alt="" />
            <p>{t('project.contract.paymentSchedule.textAreaLimit')}</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const CreatePaymentScheduleLineModal = () => {
  const { t } = useTranslation()
  const { project, paymentSchedule, fetchPostPaymentScheduleLine, setNewCreatedPaymentScheduleLine } = useProjectContractContext()
  const [form, setForm] = useState({})

  useEffect(() => {
    setForm({
      id: null,
      amount: null,
      condition: null,
      payment_date: null,
      billed_date: null,
      payment_schedule_id: paymentSchedule.id,
    })
  }, [])

  const isPaymentDateWhithinProjectDuration = (date) => {
    const projectStartDate = new Date(project.start_date)
    const projectEndDate = new Date(project.end_date)
    const selectedDate = new Date(date)
    return projectStartDate <= selectedDate && projectEndDate >= selectedDate
  }

  const isFormValid = () => (
    form && (form.amount !== null
      && form.amount !== 0
      && form.payment_date !== null
      && form.condition !== null
      && form.condition !== ''
      && isPaymentDateWhithinProjectDuration(form.payment_date)
    )
  )

  return (
    <Modal
      skin="light"
      className="modal-payment-schedule-line"
      trigger={({ open }) => (
        <Button
          className="btn-create"
          skin="dark"
          kind="primary"
          name={t('project.contract.paymentScheduleLines.addPaymentScheduleLine')}
          text={t('project.contract.paymentScheduleLines.addPaymentScheduleLine')}
          onClick={() => {
            open()
            setForm({
              id: null,
              amount: null,
              condition: null,
              payment_date: null,
              billed_date: null,
              payment_schedule_id: paymentSchedule.id,
            })
            setNewCreatedPaymentScheduleLine(null)
          }}
          icon={{ url: iconAddWhite, position: 'right' }}
        />
      )}
      disabled={!isFormValid()}
      onValidate={() => {
        fetchPostPaymentScheduleLine(form)
      }}
      localization={
        {
          validation: {
            text: t('project.contract.paymentScheduleLines.addLine'),
            arialabel: t('project.contract.paymentScheduleLines.addLine'),
          },
          cancel: {
            text: t('project.contract.paymentScheduleLines.cancel'),
            arialabel: t('project.contract.paymentScheduleLines.cancel'),
          },
        }
      }
    >
      <div className="payment-schedule-line-form">
        <div className="form-top-section">
          <InputNumber
            className="amount price-tag"
            skin="light"
            name={t('project.contract.paymentScheduleLines.amount')}
            title={t('project.contract.paymentScheduleLines.amount')}
            required
            localization={{
              invalid: { text: t('expenses.errors.mustBeANumber'), arialabel: t('expenses.errors.mustBeANumber') },
            }}
            onChange={(value) => setForm({ ...form, 'amount': value })}
          />
          <Input
            className="percentage"
            name={t('project.contract.paymentScheduleLines.percentage')}
            title={t('project.contract.paymentScheduleLines.percentage')}
            value={form ? getNumberWithUnit(((form.amount * 100) / paymentSchedule.total_amount), '%') : ''}
            type="text"
            disabled
          />
          <InputDate
            className="forecast-date"
            skin="light"
            name={t('project.contract.paymentScheduleLines.setForecastDate')}
            title={t('project.contract.paymentScheduleLines.setForecastDate')}
            required
            onCheck={(value) => (value && !isPaymentDateWhithinProjectDuration(value)) && `${t('project.contract.paymentScheduleLines.forecastDAteError')}`}
            onChange={(value) => setForm({ ...form, 'payment_date': value })}
          />
          <InputDate
            className="billing-date"
            skin="light"
            name={t('project.contract.paymentScheduleLines.setBillingDate')}
            title={t('project.contract.paymentScheduleLines.setBillingDate')}
            onCheck={() => {}}
            onChange={(value) => setForm({ ...form, 'billed_date': value })}
          />
        </div>
        <div className="form-bottom-section">
          <InputTextarea
            className="condition"
            skin="light"
            name={t('project.contract.paymentSchedule.comment')}
            title={t('project.contract.paymentSchedule.comment')}
            required
            maxLength={255}
            onChange={(value) => setForm({ ...form, 'condition': value })}
          />
          <div className="condition-limit">
            <img src={warningRoundRed} alt="" />
            <p>{t('project.contract.paymentSchedule.textAreaLimit')}</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const NewPaymentLineAlert = () => {
  const { t } = useTranslation()
  const { newCreatedPaymentScheduleLine, setNewCreatedPaymentScheduleLine } = useProjectContractContext()
  const [showElement, setShowElement] = useState(newCreatedPaymentScheduleLine !== null)

  useEffect(() => {
    setTimeout(() => {
      setShowElement(false)
      setNewCreatedPaymentScheduleLine(null)
    }, 5000)
  },
  [newCreatedPaymentScheduleLine])

  return (
    showElement && (
      <div className="new-line-alert">
        <p>
          {' '}
          { t('project.contract.paymentScheduleLines.newRowAlert')}
        </p>
      </div>
    )
  )
}

const PaymentScheduleLinesTable = () => {
  const { t } = useTranslation()
  const { paymentSchedule, paymentScheduleLines, newCreatedPaymentScheduleLine } = useProjectContractContext()
  const [copyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const table = useRef()

  const copyToClipboard = () => {
    if (table.current.childNodes[0]) {
      const nodes = Array.from(table.current.childNodes[0].childNodes)
        .flatMap((child) => Array.from(child.childNodes)).flatMap((child) => Array.from(child.childNodes))
        .map((line) => Array.from(line.childNodes))
      let lines = []
      if (nodes.length > 1) {
        lines = nodes.map((line) => (
          line.slice(0, line.length - 1)
            .map((cell) => cell.textContent).join('\t')
        )).join('\n')
      }

      navigator.clipboard.writeText(lines)
    }
    setCopyToClipboardSuccess(true)
    setTimeout(() => {
      setCopyToClipboardSuccess(false)
    }, 5000)
  }

  const paymentScheduleLinesArray = useMemo(() => paymentScheduleLines && Object.fromEntries(paymentScheduleLines.map((line) => [line.id, {
    ...line,
    'amount': getRoundedToDecimal(parseFloat(line['amount']), 2),
    'percentage': (line.amount * 100) / paymentSchedule.total_amount,
  }])), [paymentSchedule, paymentScheduleLines])

  const columns = useMemo(() => [
    {
      name: 'amount',
      title: t('project.contract.paymentScheduleLines.amount'),
      onRender: ({ item }) => (
        <div className={`${getClass(newCreatedPaymentScheduleLine?.id === item.id && 'flash')}`}>
          <span>
            {' '}
            {getNumberWithUnit(item['amount'], '€') || '-'}
          </span>
        </div>
      ),
    },
    {
      name: 'percentage',
      title: t('project.contract.paymentScheduleLines.percentage'),
      onRender: ({ item }) => (getNumberWithUnit(item['percentage'], '%') || '-'),
    },
    {
      className: 'condition-header',
      name: 'condition',
      title: t('project.contract.paymentScheduleLines.condition'),
      onRender: ({ item }) => (item['condition'] || '-'),
    },
    {
      name: 'payment_date',
      title: t('project.contract.paymentScheduleLines.forecastDate'),
      onRender: ({ item }) => (item['payment_date'] ? getYearMonthDayFromDateString(item['payment_date']) : '-'),
      onSort: (a, b) => new Date(a['payment_date']) - new Date(b['payment_date']),
    },
    {
      name: 'billed_date',
      title: t('project.contract.paymentScheduleLines.billedOn'),
      onRender: ({ item }) => (item['billed_date'] ? getYearMonthDayFromDateString(item['billed_date']) : '-'),
    },
    {
      name: 'actions',
      title: t('project.contract.paymentScheduleLines.actions'),
      onRender: ({ item }) => (
        <div className="actions">
          <ModifyPaymentScheduleModal line={item} />
          <DeletePaymentScheduleTooltip line={item} />
        </div>
      ),
    },
  ], [paymentScheduleLines])

  if (!paymentScheduleLinesArray) return null

  const copySucceedText = () => (
    <div className="copy-succeed">{t('copySucceed')}</div>
  )

  return (
    <>
      <Button
        className="copy"
        skin="dark"
        kind="primary"
        name={t('clipboard.arialabel')}
        text={t('clipboard.text')}
        icon={{ url: pictoCopy, position: 'right' }}
        onClick={() => copyToClipboard()}
      />
      {copyToClipboardSuccess && copySucceedText()}
      <div ref={table}>
        <Table className="payment-schedule-lines-table" columns={columns} data={paymentScheduleLinesArray} sortInitial={{ column: 'payment_date', order: 'asc' }} />
      </div>
      <div className="alerts">
        {newCreatedPaymentScheduleLine !== null && <NewPaymentLineAlert />}
      </div>
    </>
  )
}

const PaymentScheduleLineBoard = () => {
  const { t } = useTranslation()
  const { paymentSchedule, paymentScheduleLines } = useProjectContractContext()

  const warningBlock = (message) => (
    <div className="percentage-warning">
      <img src={iconDangerRed} alt="warning icon" />
      {message}
    </div>
  )

  const isFullyBilled = () => paymentScheduleLines && paymentScheduleLines.reduce((sum, line) => sum + parseFloat(line.amount), 0) === paymentSchedule.total_amount

  const linePercentageWarningMessage = (
    <p>
      {t('project.contract.paymentScheduleLines.warning.rowPercentages.0')}
      <b>{t('project.contract.paymentScheduleLines.warning.rowPercentages.1')}</b>
    </p>
  )

  const billedLinesWarningMessage = (
    <p>
      <b>{t('project.contract.paymentScheduleLines.warning.billedLignes.0')}</b>
      {t('project.contract.paymentScheduleLines.warning.billedLignes.1')}
      <b>{t('project.contract.paymentScheduleLines.warning.billedLignes.2')}</b>
    </p>
  )

  return (
    <Section>
      <div className="payment-schedule-line-creation">
        <div className="info-section">
          <p>
            {t('project.contract.paymentScheduleLines.billingDatesInfo.0')}
            <b>{t('project.contract.paymentScheduleLines.billingDatesInfo.1')}</b>
            {t('project.contract.paymentScheduleLines.billingDatesInfo.2')}
            <b>{t('project.contract.paymentScheduleLines.billingDatesInfo.3')}</b>
          </p>
          <CreatePaymentScheduleLineModal />
        </div>
        {!isFullyBilled() && warningBlock(linePercentageWarningMessage)}
        {paymentSchedule.has_billed_lines && warningBlock(billedLinesWarningMessage)}
      </div>
    </Section>
  )
}

export const ProjectPaymentScheduleLines = () => {
  const { project, paymentSchedule } = useProjectContractContext()

  return (
    (paymentSchedule && paymentSchedule.total_amount !== null && paymentSchedule.total_amount !== 0 && project.kind === 'fixed_price') && (
      <ProjectPaymentScheduleLinesStyled className="octo">
        <PaymentScheduleLineBoard />
        <PaymentScheduleLinesTable />
      </ProjectPaymentScheduleLinesStyled>
    )
  )
}
