import styled from 'styled-components'

export const StyledProjectAccordion = styled.details`
  summary {
    color: ${({ theme }) => theme.colors.lighterBlue};
    cursor: pointer;
    display: flex;
    padding: 8px 18px;
    border-bottom: ${({ unfold, theme }) => (unfold ? `1px solid${theme.colors.lighterGray}` : '')};
    h3 {
      font-size: 18px;
      line-height: 30px;
      margin-left: 6px;
      &:hover {
        color: ${({ theme }) => theme.colors.skyBlue};
        text-decoration: underline;
      }
    }

    &::-webkit-details-marker {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }
  .body-content {
    padding: 9px 9px 36px 15px;
  }
  .btn-project-detail {
    display: block;
    background-color: ${({ theme }) => theme.colors.secondaryBlue};
    background-color: ${({ theme }) => theme.colors.secondaryBlue};
    color: #ffffff;
    font-size: 13px;
    border-radius: 4px;
    text-decoration: none;
    width: 11em;
    text-align: center;
    line-height: 2.5em;
  }
`
