import React from 'react'
import { ThemeProvider } from 'styled-components'
import { ExpenseSummaryAccordion } from '../../components'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'

const ProjectExpenseSummary = ({ project }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <ExpenseSummaryAccordion project={project} />
  </ThemeProvider>
)

export default ProjectExpenseSummary
