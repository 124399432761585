import styled from 'styled-components'
import pictoInfo from '../../../assets/images/exclamationInfo.svg'

export const ProjectAffiliatedStyled = styled.div`
  width: 100%;
  height: 100%;

  div.octo.opened {
    > div.popup-tooltip {
      z-index: 11;
    }

    > div.popup-overlay {
      z-index: 10;
      pointer-events: none;
    }
  }

  .container-affiliated {
    display: flex;
    gap: 3.2rem;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .vertical-line {
      width: 0.1rem;
      height: 100%;
      border-right: solid 0.1rem ${({ theme }) => theme.colors.betterway.primary[20]};
    }

    .infos-affiliated {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      .pictos {
        width: 6.4rem;
        height: 6.4rem;
      }

      .number-affiliated {
        margin-top: 1.6rem;
        font-weight: 600;
        font-size: 4.6rem;
      }

      .amount-project {
        margin-top: 1rem;
        font-weight: 600;
        font-size: 3rem;
      }

      .details-section {
        display: flex;
        margin-top: 3.4rem;

        h2 {
          margin-right: 0.8rem;
          font-weight: inherit;
          font-size: 1.4rem;
        }

        .affiliated-project-tooltip {
          width: auto;

          span {
            font-weight: 600;
          }

          p {
            font-size: 1.4rem;
          }
        }

        .tooltip-trigger-button {
          width: 2rem;
          height: 2rem;
          background-image: url(${pictoInfo});
          background-position: center;
          background-clip: border-box;
          background-size: contain;
          background-repeat: no-repeat;
          cursor: pointer;
        }
      }

      .affiliated-mission {
        display: flex;
        align-items: center;
        height: auto;
        color: ${({ theme }) => theme.colors.primaryDark};
        font-weight: normal;
        font-size: 1.4rem;
        text-decoration: underline;

        a {
          img {
            margin-left: 0.5rem;
          }
        }
      }

      .disabled-link {
        text-decoration: none;
        pointer-events: none;
      }
    }
  }

  .border {
    border-right: solid 0.125rem ${({ theme }) => theme.colors.grey};
  }
`
