import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@produits-internes-oss/design-system-components'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { SalesHeaderStyled } from './SalesHeader.styled'
import { parseFloatWithAnySeparator, getNumberWithUnit } from '../../helpers/stringHelper'

export const SalesHeader = () => {
  const { t } = useTranslation()
  const { projectRevenue, projectCosts, projectCCISold, project } = useProjectSalesContext()

  if (project === null) {
    return <div>...</div>
  }
  return (
    <SalesHeaderStyled>
      <div className="card">
        <div className="card-header">
          {t('project.salesHeader.revenue.text')}
          <Tooltip
            className="infos-card-header-tooltip"
            position="right"
            skin="light"
            trigger={
                  ({ open, close }) => (
                    <button
                      type="button"
                      onMouseEnter={() => open()}
                      onMouseLeave={() => close()}
                      className="tooltip-trigger-button"
                      aria-label="tooltip trigger button"
                    />
                  )
                }
            content={
                  () => (
                    <div className="content-tooltip">
                      <span className="one-line">
                        <span>{t('project.salesHeader.revenue.tooltip.0')}</span>
                        <em>{t('project.salesHeader.revenue.tooltip.1')}</em>
                      </span>
                      <em>{t('project.salesHeader.revenue.tooltip.2')}</em>
                      <span className="one-line">
                        <em>{t('project.salesHeader.revenue.tooltip.3')}</em>
                        <span>{t('project.salesHeader.revenue.tooltip.4')}</span>
                      </span>
                      <span>{t('project.salesHeader.revenue.tooltip.5')}</span>
                      <span>{t('project.salesHeader.revenue.tooltip.6')}</span>
                      <span>{t('project.salesHeader.revenue.tooltip.7')}</span>
                    </div>
                  )
                }
          />
        </div>
        <div className="card-value">
          {projectRevenue ? `${getNumberWithUnit(parseFloatWithAnySeparator(projectRevenue), '€')}` : '0 €'}
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          {t('project.salesHeader.costs.text')}
          <Tooltip
            className="infos-card-header-tooltip"
            position="right"
            skin="light"
            trigger={
                  ({ open, close }) => (
                    <button
                      type="button"
                      onMouseEnter={() => open()}
                      onMouseLeave={() => close()}
                      className="tooltip-trigger-button"
                      aria-label="tooltip trigger button"
                    />
                  )
                }
            content={
                  () => (
                    <div className="content-tooltip">
                      <span className="one-line">
                        <span>{t('project.salesHeader.costs.tooltip.0')}</span>
                        <em>{t('project.salesHeader.costs.tooltip.1')}</em>

                      </span>
                      <em>{t('project.salesHeader.costs.tooltip.2')}</em>
                      <ul>
                        <li>{t('project.salesHeader.costs.tooltip.3')}</li>
                        <li>{t('project.salesHeader.costs.tooltip.4')}</li>
                        <li>{t('project.salesHeader.costs.tooltip.5')}</li>
                        <li>{t('project.salesHeader.costs.tooltip.6')}</li>
                        <li>{t('project.salesHeader.costs.tooltip.7')}</li>
                      </ul>

                    </div>
                  )
                }
          />
        </div>
        <div className="card-value">
          {projectCosts ? `${getNumberWithUnit(parseFloatWithAnySeparator(projectCosts), '€')}` : '0 €'}
        </div>
      </div>
      {!project.is_acn_prime && (
      <div className="card">
        <div className="card-header">
          {t('project.salesHeader.cci_sold.text')}
          <Tooltip
            className="infos-card-header-tooltip"
            position="right"
            skin="light"
            trigger={
                  ({ open, close }) => (
                    <button
                      type="button"
                      onMouseEnter={() => open()}
                      onMouseLeave={() => close()}
                      className="tooltip-trigger-button"
                      aria-label="tooltip trigger button"
                    />
                  )
                }
            content={
                  () => (
                    <div className="content-tooltip">
                      <span className="one-line">
                        <span>{t('project.salesHeader.cci_sold.tooltip.0')}</span>
                        <em>{t('project.salesHeader.cci_sold.tooltip.1')}</em>
                      </span>
                      <em>{t('project.salesHeader.cci_sold.tooltip.2')}</em>
                      <span className="one-line">
                        <span>{t('project.salesHeader.cci_sold.tooltip.3')}</span>
                        <em>{t('project.salesHeader.cci_sold.tooltip.4')}</em>
                      </span>
                      <span className="one-line">
                        <em>{t('project.salesHeader.cci_sold.tooltip.5')}</em>
                        <span>{t('project.salesHeader.cci_sold.tooltip.6')}</span>
                      </span>
                    </div>
                  )
                }
          />
        </div>
        <div className="card-value">
          {projectCCISold ? `${getNumberWithUnit(parseFloatWithAnySeparator(projectCCISold), '%')}` : '0 %'}
        </div>
      </div>
      )}
    </SalesHeaderStyled>
  )
}
