import styled from 'styled-components'

export const ProjectSummaryScreenStyled = styled.div`
.summary {
  .turnover-breakdown-section,
  .billing-section {
    height: 20rem;
  }

  .section-team,
  .section-cci,
  .status-history,
  .row-section-infos,
  .row-section-turnover,
  .row-section-team-info,
  .row-section-subcontractors-info {
    margin-bottom: 3.2rem;
  }

  .row-section {
    display: flex;
    gap: 2rem;
    margin-bottom: 3.2rem;

    .octo {
      width: 100%;
    }

    &.finance {
      flex-direction: row;
      margin-right: auto;
      margin-left: auto;
    }

    .section-infos {
      height: 17rem;
    }

    .section-affiliated {
      width: 100%;
      height: 17rem;
    }

    .row-section-publisher-info {
      width: 100%;
      height: 17rem;
    }

    &.sales {
      display: flex;
      flex-direction: row;
      gap: 2.4rem;

      .section-state {
        height: 17rem;
      }

      .section-infos {
        height: 17rem;
      }
    }
  }
}

.kind {
  max-width: calc(30rem - 0.8rem);
  margin: auto;
  margin-top: 2rem;
  margin-right: 0;

  >.enum {
    height: 4.2rem;
    padding: 0;
    border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[70]};
    border-radius: 2rem;

    >.track {
      button {
        text-align: center;

        &:not(.selected) {
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          cursor: pointer;
        }
      }

      >.slider {
        box-sizing: border-box;
        border: 2px solid ${({ theme }) => theme.colors.betterway.white};
        border-radius: 2rem;
        background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
      }
    }
  }
}
`
