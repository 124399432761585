import styled from 'styled-components'

export const ProjectServiceHeaderStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;

  .amount {
    margin-left: 1.6rem;
    font-size: 2.4rem;
  }
`
