import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@produits-internes-oss/design-system-components'
import { ProjectCciStyled } from './projectCci.styled'
import { useProjectSummaryContext } from '../../context/ProjectSummaryContext/ProjectSummaryContext'

export const ProjectCci = () => {
  const { t } = useTranslation()
  const { project } = useProjectSummaryContext()

  if (!project) return <div>...</div>

  return (
    <ProjectCciStyled>
      <section>
        <div className="title">
          <h2>{t('project.summary.cci.title-type')}</h2>
          <Tooltip
            className="cci-tooltip"
            position="top"
            skin="light"
            trigger={
              ({ open, close }) => (
                <button
                  type="button"
                  onMouseEnter={() => open()}
                  onMouseLeave={() => close()}
                  className="tooltip-trigger-button"
                  aria-label="tooltip trigger button"
                />
              )
            }
            content={
              () => (
                <div>
                  <p>{t('project.summary.cci.tooltipContent.targeted.explanation')}</p>
                  <ul>
                    <li>
                      {project.nature_target_cci.delivery}
                      {t('project.summary.cci.tooltipContent.targeted.deliveryPercent')}
                    </li>
                    <li>
                      {project.nature_target_cci.consulting}
                      {t('project.summary.cci.tooltipContent.targeted.consultingPercent')}
                    </li>
                    <li>
                      {project.nature_target_cci.strategic}
                      {t('project.summary.cci.tooltipContent.targeted.strategyPercent')}
                    </li>
                  </ul>
                </div>
              )
            }
          />
        </div>
        <span className="amount">{project.target_cci ? `${parseFloat(project.target_cci).toFixed(2)} %` : '-'}</span>
      </section>
      <div className="vertical-line" />
      <section>
        <div className="title">
          <h2>{t('project.summary.cci.title-sold')}</h2>
          <Tooltip
            className="cci-tooltip"
            position="top"
            skin="light"
            trigger={
              ({ open, close }) => (
                <button
                  type="button"
                  onMouseEnter={() => open()}
                  onMouseLeave={() => close()}
                  className="tooltip-trigger-button"
                  aria-label="tooltip trigger button"
                />
              )
            }
            content={
              () => (
                <div className="content">
                  <p>
                    {t('project.summary.cci.tooltipContent.sold.first')}
                    <span>
                      {' '}
                      {t('project.summary.cci.tooltipContent.sold.second')}
                    </span>
                    {' '}
                    {t('project.summary.cci.tooltipContent.sold.third')}
                  </p>
                </div>
              )
            }
          />
        </div>
        <span className="amount">{project.cci_sold ? `${parseFloat(project.cci_sold).toFixed(2)} %` : '-'}</span>
      </section>
      <div className="vertical-line" />
      <section>
        <div className="title">
          <h2 className="long-title">{t('project.summary.cci.title-inProgress')}</h2>
          <Tooltip
            className="cci-tooltip"
            position="top"
            skin="light"
            trigger={
              ({ open, close }) => (
                <button
                  type="button"
                  onMouseEnter={() => open()}
                  onMouseLeave={() => close()}
                  className="tooltip-trigger-button"
                  aria-label="tooltip trigger button"
                />
              )
            }
            content={
              () => (
                <div className="content">
                  <p>
                    {t('project.summary.cci.tooltipContent.inProgress.first')}
                    <span>
                      {' '}
                      {t('project.summary.cci.tooltipContent.inProgress.second')}
                    </span>
                  </p>
                </div>
              )
            }
          />
        </div>
        <span className="amount">{project.contract_ci_percent ? `${parseFloat(project.contract_ci_percent).toFixed(2)} %` : '-'}</span>
      </section>
    </ProjectCciStyled>
  )
}
