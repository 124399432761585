import styled from 'styled-components'
import textureProgressRed from '../../../assets/images/progressionRed.svg'
import textureProgressGreen from '../../../assets/images/progressionGreen.svg'
import textureProgressBlue from '../../../assets/images/progressionBlue.svg'

export const ProgressBarStyled = styled.div`
  &.progress-bar {
    .container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .bar {
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        width: 60%;
        height: 1.6rem;
        border-radius: 0.6rem;

        &.too-full {
          ::after {
            background-image: url(${textureProgressRed});
          }
        }

        &.full {
          ::after {
            background-image: url(${textureProgressGreen});
          }
        }

        &.not-full {
          ::before {
            border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[100]};
          }

          ::after {
            background-image: url(${textureProgressBlue});
          }
        }

        ::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 0.6rem;
        }

        ::after {
          content: '';
          position: absolute;
          top: 0;
          right: ${({ percentage }) => (1 - percentage) * 100}%;
          bottom: 0;
          left: 0;
          border-radius: 0.6rem;
          background-clip: border-box;
          background-repeat: repeat-x;
          transition: right 0.2s ease-in-out;
          animation-name: translation;
          animation-duration: 0.25s;
          animation-timing-function: linear;
          animation-iteration-count: infinite;
        }
      }

      .percentage {
        display: flex;
        gap: 0.4rem;
        align-items: center;
        margin-left: 1rem;
        font-size: 1.4rem;

        img {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }

`
