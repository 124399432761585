import styled from 'styled-components'

export const StaffingTabsStyled = styled.div`
ul {
  display: flex;
  gap: 1em;
  margin: 0;
  margin-bottom: 2rem;

  li {
    width: 50%;
    list-style: none;

    button {
      width: 100%;
      height: 2em;
      border-radius: 0.8rem 0.8rem 0 0;
      background-color: ${({ theme }) => theme.colors.betterway.primary[70]};
      color: white;
      font-weight: 600;
      font-size: 3.2rem;
      text-align: left;
      text-indent: 1.125rem;

      &[aria-selected='true'] {
        background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
        text-decoration: underline;
      }

      >img {
        margin-right: 1.125rem;
      }
    }
  }
}
`
