import React, { Component } from 'react'
import './TextArea.css'

const WAIT_INTERVAL = 1000

class TextArea extends Component {
  state = {
    value: this.props.value,
  }

  componentDidMount() {
    this.staffingInfoTimer = null
  }

  handleChange = ({ target }) => {
    clearTimeout(this.staffingInfoTimer)

    this.setState({ value: target.value })

    this.staffingInfoTimer = setTimeout(this.triggerChange, WAIT_INTERVAL)
  }

  triggerChange = () => {
    const { value } = this.state

    this.props.onChange(value)
  }

  render() {
    const { value } = this.state

    return (
      <div>
        <textarea
          id="staffing-info-field"
          onChange={this.handleChange}
          value={value}
          rows="6"
        />
      </div>
    )
  }
}

export default TextArea
