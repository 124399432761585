import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, InputSelect, Section, Modal, Button } from '@produits-internes-oss/design-system-components'
import { useFetch } from '../../fetchOverviewApi'
import { OpportunitiesStyled } from './Opportunities.styled'
import { useStaffingContext } from '../../context/Staffing/StaffingContext'
import { getDateStringFromTimestamp } from '../../helpers/datePickerHelper'
import Loader from '../UI/Loader/Loader'

export const Opportunities = () => {
  const urlAskbobUser = `https://askbob.octo.tools/people/`

  const { t } = useTranslation()
  const {
    opportunitiesBizDevs,
    opportunitiesRoles,
    setSelectedBizDevs,
    setSelectedRoles,
    opportunitiesResult,
    setErrors,
  } = useStaffingContext()

  if (opportunitiesResult === null) {
    return <Loader />
  }

  const columns = [
    {
      name: 'project_status',
      title: t('staffingTabs.opportunities.tableHeaders.projectStatus'),
      className: 'value-centered',
      onRender: ({ item }) => (
        <div className={`status ${item['project_status'].replaceAll('_', '-')}`}>
          {t(`project.status.${item['project_status']}`)}
        </div>
      ),
      onSort: ((a, b) => t(`project.status.${a['project_status']}`).localeCompare(t(`project.status.${b['project_status']}`))),
    },
    {
      name: 'project_name',
      title: t('staffingTabs.opportunities.tableHeaders.projectName'),
      onRender: ({ item }) => (
        <a
          className="project-link"
          target="blank"
          rel="noreferrer"
          aria-label={`link to ${item['project_name']}`}
          title={item['project_reference']}
          href={`/projects/billable/${item['project_id']}`}
        >
          {item['project_name']}
        </a>
      ),
      onSort: ((a, b) => a['project_name'].localeCompare(b['project_name'])),
    },
    {
      name: 'customer_name',
      title: t('staffingTabs.opportunities.tableHeaders.customerName'),
      onRender: ({ item }) => item['customer_name'],
      onSort: ((a, b) => a['customer_name'].localeCompare(b['customer_name'])),
    },
    {
      name: 'role_name',
      title: t('staffingTabs.opportunities.tableHeaders.roleName'),
      onRender: ({ item }) => item['role_name'],
      onSort: ((a, b) => a['role_name'].localeCompare(b['role_name'])),
    },
    {
      name: 'nb_days',
      title: t('staffingTabs.opportunities.tableHeaders.nbDays'),
      className: 'value-centered',
      onRender: ({ item }) => item['nb_days'],
      onSort: ((a, b) => parseFloat(a['nb_days']) - parseFloat(b['nb_days'])),
    },
    {
      name: 'profile_name',
      title: t('staffingTabs.opportunities.tableHeaders.profileName'),
      className: 'value-centered',
      onRender: ({ item }) => t(`staffingTabs.opportunities.profiles.${item['profile_name']}`),
      onSort: ((a, b) => t(`staffingTabs.opportunities.profiles.${a['profile_name']}`).localeCompare(t(`staffingTabs.opportunities.profiles.${b['profile_name']}`))),
    },
    {
      name: 'activity_start_date',
      title: t('staffingTabs.opportunities.tableHeaders.activityStartDate'),
      className: 'value-centered',
      onRender: ({ item }) => {
        if (!item['activity_start_date']) {
          return '-'
        }
        return (getDateStringFromTimestamp(new Date(item['activity_start_date'])))
      },
      onSort: ((a, b) => {
        const startDateA = a['activity_start_date'] || 'not_a_date'
        const startDateB = b['activity_start_date'] || 'not_a_date'

        return startDateA.localeCompare(startDateB)
      }),
    },
    {
      name: 'project_location',
      title: t('staffingTabs.opportunities.tableHeaders.projectLocation'),
      onRender: ({ item }) => item['project_location'] || '-',
      onSort: ((a, b) => a['project_location'].localeCompare(b['project_location'])),
    },
    {
      name: 'comment',
      title: t('staffingTabs.opportunities.tableHeaders.activityTitle'),
      onRender: ({ item }) => (item['activity_title'] || '-'),
    },
    {
      name: 'project_business_contact_nickname',
      title: t('staffingTabs.opportunities.tableHeaders.projectMissionContact'),
      className: 'value-centered',
      onRender: ({ item }) => {
        if (!item['project_business_contact_nickname']) {
          return '-'
        }
        return (
          <a
            target="blank"
            rel="noreferrer"
            aria-label={`contact ${item['project_business_contact_nickname']} on mattermost`}
            href={item['project_business_contact_mattermost']}
            title={`contacter ${item['project_business_contact_nickname']} sur mattermost`}
            className="business-contact-link"
          >
            { item['project_business_contact_nickname']}
          </a>
        )
      },
      onSort: ((a, b) => a['project_business_contact_nickname'].localeCompare(b['project_business_contact_nickname'])),
    },
    {
      name: 'Matchs',
      title: 'Matchs',
      onRender: ({ item }) => {
        const [matchingPeopleDetails, setMatchingPeopleDetails] = useState(null)

        const fetchOpportunityPeopleDetails = async (peopleIds) => {
          setMatchingPeopleDetails(null)
          const response = await useFetch('GET', `/activities/opportunities_people_details?people_ids=${peopleIds}`)

          if (response.errors) {
            setErrors('errors', response.errors)
          } else {
            setMatchingPeopleDetails(response.people)
          }
        }

        if (!item.people_matching) {
          return 'Loading...'
        }
        return (
          <Modal
            className="opportunity-modale"
            title={`${item.people_matching.length > 1
              ? `${item.people_matching.length} ${t('staffingTabs.opportunities.modal.title.plural')}`
              : `${item.people_matching.length} ${t('staffingTabs.opportunities.modal.title.singular')}`} - ${item.role_name} - ${item.customer_name}`}
            trigger={({ open }) => (
              <Button
                kind="primary"
                skin="dark"
                name="open_matches"
                text={`${item.people_matching.length} ${item.people_matching.length > 1 ? 'matchs' : 'match'}`}
                onClick={() => {
                  fetchOpportunityPeopleDetails(item.people_matching.map((person) => person.id))
                  open()
                }}
              />
            )}
          >
            {
                !matchingPeopleDetails && (
                <Loader />
                )
              }
            {matchingPeopleDetails && (
              matchingPeopleDetails.map((matchingPersonDetail) => (
                <Section key={`matchingPersonDetail-${matchingPersonDetail.id}-${item.id}`}>
                  <div>
                    <a
                      className="person-link"
                      target="blank"
                      rel="noreferrer"
                      aria-label={`link to ${matchingPersonDetail['nickname']}`}
                      href={`/timesheet/${matchingPersonDetail['nickname']}`}
                    >
                      {matchingPersonDetail['nickname']}
                    </a>
                    <a
                      className="person-askbob-link"
                      target="blank"
                      rel="noreferrer"
                      aria-label={`link to askbob profile of ${matchingPersonDetail['nickname']}`}
                      href={`${urlAskbobUser}${matchingPersonDetail['id']}`}
                    >
                      <img alt={`person ${matchingPersonDetail['nickname']}`} className="person-picture" src={matchingPersonDetail['photo_url']} />
                    </a>
                  </div>
                  <div>
                    <span className="data-title">{t('staffingTabs.opportunities.modal.dataTitles.mainRole')}</span>
                    {' '}
                    {matchingPersonDetail.main_role_name || '-'}
                  </div>
                  <div>
                    <span className="data-title">{t('staffingTabs.opportunities.modal.dataTitles.level')}</span>
                    {' '}
                    {matchingPersonDetail.job || '-'}
                  </div>
                  <div>
                    <span className="data-title">{t('staffingTabs.opportunities.modal.dataTitles.3LastActivities')}</span>
                    <div>
                      {matchingPersonDetail.last_activities.map((lastActivity) => (
                        <p key={`${matchingPersonDetail.id}-${lastActivity.role_name}-${lastActivity.project_id}`} className="inline-project-description">
                          <span className="activity-role">{`${lastActivity.role_name} -`}</span>
                          <a
                            className="project-link"
                            target="blank"
                            rel="noreferrer"
                            aria-label={`link to ${lastActivity.project_name}`}
                            href={`/projects/billable/${lastActivity.project_id}`}
                          >
                            {lastActivity.project_name}
                          </a>
                        </p>
                      ))}
                    </div>
                  </div>
                  <div>
                    <span className="data-title">{t('staffingTabs.opportunities.modal.dataTitles.infoStaffing')}</span>
                    {' '}
                    {matchingPersonDetail.staffing_info || '-'}
                  </div>
                  <Button
                    kind="primary"
                    skin="dark"
                    highlight
                    text={t('staffingTabs.opportunities.modal.contact')}
                    name={`contact ${matchingPersonDetail.nickname} on mattermost`}
                    onClick={() => {
                      window.open(
                        matchingPersonDetail.mattermost_contact,
                        '_blank',
                        'noreferrer',
                      )
                      return false
                    }}
                    className="mattermost-button"
                  />
                </Section>
              ))
            )}
          </Modal>
        )
      },
    },
  ]

  return (
    <OpportunitiesStyled id="opportunities" role="tabpanel" aria-labelledby="opportunities" tabIndex={0}>
      <h2>{t('staffingTabs.opportunities.filterBy')}</h2>
      <div className="filters">
        <InputSelect
          className="filter"
          skin="light"
          name="bizdev select"
          title={t('staffingTabs.opportunities.filters.title.bizdev')}
          options={opportunitiesBizDevs()}
          localization={{ validation: { text: t('staffingTabs.opportunities.filters.validate'), arialabel: t('staffingTabs.opportunities.filters.validate') } }}
          multiple
          onChange={(value) => {
            setSelectedBizDevs(value)
          }}
        />
        <InputSelect
          className="filter"
          skin="light"
          name="roles select"
          title={t('staffingTabs.opportunities.filters.title.role')}
          options={opportunitiesRoles()}
          localization={{ validation: { text: t('staffingTabs.opportunities.filters.validate'), arialabel: t('staffingTabs.opportunities.filters.validate') } }}
          multiple
          onChange={(value) => {
            setSelectedRoles(value)
          }}
        />
      </div>
      <h2>{t('staffingTabs.results')}</h2>
      <Table
        columns={columns}
        data={opportunitiesResult}
        sortInitial={{ column: 'activity_start_date', order: 'asc' }}
      />
    </OpportunitiesStyled>
  )
}
