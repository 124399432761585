import styled from 'styled-components'

export const StyledTimesheetPicker = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 4rem;
  background: ${({ theme, open }) => (open ? theme.colors.primaryGreyLight : 'initial')};
  border-radius: 4px 4px 0 0;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryGreyLight};
  }

  * {
    box-sizing: border-box;
  }

  .timesheet-picker-input {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 1rem;

    a:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .timesheet-picker-text {
    text-decoration: none;
    text-transform: capitalize;
    height: 100%;
    line-height: 4rem;
    width: 12rem;
    text-align: left;
    font-size: 1.6rem;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }

  .timesheet-selector {
    height: 19rem;
    display: flex;
    z-index: 1000;
    position: relative;
    background-color: white;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    padding: 2rem 2.2rem;
    top: 100%;
    left: 0;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 ${({ theme }) => theme.colors.shadow};
  }

  .timesheet {
    padding: 1.2rem;
    width: 18rem;
    border: solid ${({ theme }) => theme.colors.secondaryLight} 3px;
    border-radius: 4px;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 1.2rem;
    }

    h3 {
      font-size: 1.6rem;
    }
    p {
      font-size: 1.2rem;
      margin: 2px 0;
    }
  }
`
