import React from 'react'
import { useTranslation } from 'react-i18next'
import NavHeader from './NavHeader/NavHeader'
import Accordion from './Accordion/Accordion'
import NavLink from './NavLink/NavLink'

const NavItems = ({ navItem }) => {
  const nestedNavItems = (navItem.subitems || []).map((item) => <NavItems key={item.id} navItem={item} />)
  const { t } = useTranslation()

  const item = (type) => {
    if (type == 'header') {
      return (
        <>
          <NavHeader title={t(navItem.title)} />
          {nestedNavItems}
        </>
      )
    } if (accordionType(type)) {
      return (
        <div style={{ marginLeft: '20px' }}>
          <Accordion title={t(navItem.title)} type={navItem.type}>
            {nestedNavItems}
          </Accordion>
        </div>
      )
    } if (navLinkType(type)) {
      return (
        <div style={{ marginLeft: '20px' }}>
          <NavLink link={navItem.url} title={t(navItem.title)} type={navItem.type}>
            {nestedNavItems}
          </NavLink>
        </div>
      )
    }
    return null
  }

  const accordionType = (type) => type == 'accordion' || type == 'accordionMedium'
  const navLinkType = (type) => type == 'navLink' || type == 'navLinkMedium' || type == 'navLinkLight'

  return navItem.authorization && item(navItem.type)
}

export default NavItems
