import React from 'react'

import Checkbox from '../input/Checkbox/Checkbox'
import { requestWithCSRFToken } from '../../javascripts/HttpService'

class Role extends React.Component {
    httpVerbForState = {
      true: 'POST',
      false: 'DELETE',
    }

    updateRole = (isChecked) => {
      const { person_id, role_id, onChange } = this.props

      const url = `/people/${person_id}/roles/${role_id}`
      const options = { method: this.httpVerbForState[isChecked] }

      requestWithCSRFToken(url, options).then(() => {
        onChange()
      })
    }

    render() {
      const { name, className, isChecked } = this.props
      return <Checkbox name={name} className={className} onChange={this.updateRole} isChecked={isChecked} />
    }
}

export default Role
