import { clamp, getRoundedToDecimal } from '../../helpers/numberHelper'
import { parseFloatWithAnySeparator } from '../../helpers/stringHelper'
import { milliSecPerDay } from '../../helpers/datePickerHelper'

const KILOMETERS_RATE = 0.529
const REFUND_CLAMP_MIN = 0
const REFUND_CLAMP_MAX = 200

export class Expense {
  constructor(source) {
    this.attributes = [
      'id',
      'status',
      'type',
      'day',
      'day_end',
      'project_id',
      'wbs_category_id',
      'value',
      'refund_value',
      'country_id',
      'invoicing_kind',
      'meal_kind',
      'attendees_number',
      'departure',
      'arrival',
      'kilometers',
      'reason',
      'supplier',
      'travel_class',
      'vat',
      'label',
      'to_send',
      'receipts',
      'people_attendee_ids',
    ].map((key) => {
      let value = null
      if (key in source) {
        if (key === 'vat' && source[key] === null) {
          value = true
        } else {
          value = source[key]
        }
      } else if (key === 'receipts') {
        value = []
      } else if (key === 'people_attendee_ids') {
        value = []
      }

      return ({ key, value })
    })
  }

  getAttribute(key) {
    const result = this.attributes.find((attribute) => attribute.key === key)
    if (!result) {
      throw new Error(`Found no attributes with key ${key}`)
    }

    return result
  }

  getValue(key) {
    const { value } = this.getAttribute(key)

    if (key === 'day' || key === 'day_end') {
      return value ? new Date(value) : null
    }

    if (key === 'departure' || key === 'arrival' || key === 'label') {
      return value || ''
    }

    if (key === 'vat') {
      return (value === null || value) ? 'yes' : 'no'
    }

    return value
  }

  getRefund() {
    return clamp((parseFloatWithAnySeparator(this.getValue('value')) || 0) / 2, REFUND_CLAMP_MIN, REFUND_CLAMP_MAX)
  }

  getValueFromKilometers() {
    const kilometers = this.getValue('kilometers')
    const dateStart = this.getValue('day')
    const dateEnd = this.getValue('day_end')
    if (kilometers > 0 && dateStart && dateEnd) {
      const duration = ((dateEnd - dateStart) / milliSecPerDay) + 1
      return getRoundedToDecimal((kilometers * duration * KILOMETERS_RATE), 3)
    }

    return 0
  }

  getVisibility(key) {
    return !this.getAttribute(key).hidden
  }

  setValue(key, value) {
    const attribute = this.getAttribute(key)
    if (key === 'day' || key === 'day_end') {
      attribute.value = value || null
    } else if (key === 'departure' || key === 'arrival' || key === 'label') {
      attribute.value = value || null
    } else if (key === 'value' || key === 'refund_value' || key === 'kilometers') {
      attribute.value = parseFloatWithAnySeparator(value)
      if (Number.isNaN(attribute.value)) {
        attribute.value = null
      }
    } else if (key === 'vat') {
      attribute.value = value === 'yes'
    } else {
      attribute.value = value
    }
  }

  setVisibility(key, visibility) {
    this.getAttribute(key).hidden = !visibility
  }

  toObject() {
    return Object.fromEntries(this.attributes.map(({ key, value }) => [key, value]))
  }
}
