import styled from 'styled-components'

export const ProjectContingencyStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;

  .amount {
    margin-left: 1.6rem;
    font-size: 2.4rem;

    .percent {
      margin-left: 0.5rem;
      font-weight: 300;
      font-size: 1.8rem;
    }
  }

  >.header-container {
    p {
      margin-bottom: 1.8rem;
      font-size: 1.6rem;
    }

    ul {
      margin-bottom: 2.4rem;
      padding-left: 0.8rem;
      list-style: inside;
    }
  }
`
