import styled from 'styled-components'

export const StyledTimesheetForm = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 4rem;
  color: ${({ theme }) => theme.colors.primary};

  p {
    margin-right: 1rem;
    font-size: 1.6rem;
  }

  .person-picker {
    width: 25rem;
    margin-right: 1rem;
  }
`
