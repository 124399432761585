import styled from 'styled-components'
import pictoInfo from '../../../assets/images/exclamationInfo.svg'

export const ProjectTurnoverStyled = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
height: 9rem;

.octo {
  width: 100%;

  &.opened {
    > div.popup-tooltip {
      z-index: 11;
    }

    > div.popup-overlay {
      z-index: 10;
      pointer-events: none;
    }
  }
}

section {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40rem;

  .subtitle {
    margin-bottom: 0.9rem;

    h2 {
      font-weight: 600;
      font-size: 1.8rem;
    }

    .summary-team-info-tooltip {
      align-self: flex-end;
      width: auto;
      margin-left: 0.8rem;

      p {
        font-weight: initial;
        font-size: 1.4rem !important;

        span {
          font-weight: initial;
          font-size: 1.4rem !important;
        }

        b {
          font-weight: 600;
        }
      }
    }

    .tooltip-trigger-button {
      width: 2rem;
      height: 2rem;
      background-image: url(${pictoInfo});
      background-position: center;
      background-clip: border-box;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  span {
    font-weight: 600;
    font-size: 3.2rem;
  }

  .percent {
    font-size: 1.6rem;
  }
}

.vertical-line {
  width: 0.1rem;
  height: 100%;
  border-right: solid 0.1rem ${({ theme }) => theme.colors.betterway.primary[20]};
}
`
