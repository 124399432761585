import React, { useState, useRef } from 'react'
import { StyledPeriodValidationModal } from './PeriodValidationModal.styled.jsx'

import Modal from '../../../UI/Modal/Modal'
import Side from './Side/Side'
import TimeRecap from './TimeRecap/TimeRecap'
import RestForm from './RestForm/RestForm'
import RemoteOfficeDaysForm from './RemoteOfficeDaysForm/RemoteOfficeDaysForm'
import HappinessIndicatorForm from './HappinessIndicatorForm/HappinessIndicatorForm'
import MealForm from './MealForm/MealForm'
import PeriodSubmit from './PeriodSubmit/PeriodSubmit'
import ValidationConfirmationModal from './ValidationConfirmationModal/ValidationConfirmationModal'

import { usePeriodValidationForm } from '../../../../hooks/usePeriodValidationForm'

const PeriodValidationModal = ({
  open,
  close,
  dates,
  periodId,
  periodNumber,
  periodTimeinputs,
  totalInputsForPeriod,
  totalPeriodInputsForActivity,
  periodErrorsMessages,
  initialComment,
  onChangeComment,
  validatePeriod,
  updatedAt,
  updatedBy,
}) => {
  const [submitted, setSubmitted] = useState(false)
  const {
    activities,
    activityCategories,
    activityCategoryHasInputs,
    restRespected,
    updateRestRespected,
    daysInRemote,
    debounceUpdateNbDaysInRemote,
    hasRemoteDaysError,
    daysInCustomerSite,
    debounceUpdateNbDaysInCustomerSite,
    hasCustomerSiteDaysError,
    validationDisabled,
    happinessIndicatorForm,
    missingLegalInfoMessages,
    timesheetId,
    workingDaysCount,
  } = usePeriodValidationForm(periodId, open)

  const timeRecapRef = useRef(null)
  const restRef = useRef(null)
  const remoteOfficeDaysRef = useRef(null)
  const happinessIndicatorRef = useRef(null)
  const mealRef = useRef(null)
  const validationFormRef = useRef()
  const sectionRefs = (
    periodNumber == 2
      ? [
        { name: 'time-recap', ref: timeRecapRef },
        { name: 'rest-form', ref: restRef },
        { name: 'remote-office-days', ref: remoteOfficeDaysRef },
        { name: 'happiness-indicator-form', ref: happinessIndicatorRef },
        { name: 'meal-form', ref: mealRef },
      ]
      : [
        { name: 'time-recap', ref: timeRecapRef },
        { name: 'rest-form', ref: restRef },
        { name: 'remote-office-days', ref: remoteOfficeDaysRef },
      ]
  )

  const submitPeriod = () => {
    validatePeriod()
    close()
    setSubmitted(true)
  }

  const closeSubmittedModal = () => {
    setSubmitted(false)
  }

  const showSection = (sectionName) => {
    const section = sectionRefs.find((section) => section.name == sectionName)
    const options = section != sectionRefs[sectionRefs.length - 1] ? { block: 'start' } : {}
    section.ref.current.scrollIntoView(options)
  }

  return (
    <>
      <Modal open={open} close={close} height="572px" width="860px" noPadding>
        <StyledPeriodValidationModal aria-label="period-validation-modal">
          <Side
            dates={dates}
            periodNumber={periodNumber}
            showSection={showSection}
          />
          <div className="validation-form" ref={validationFormRef} role="modal-right-side">
            <div className="time-recap" ref={timeRecapRef}>
              <TimeRecap
                activities={activities}
                activityCategories={activityCategories}
                periodTimeinputs={periodTimeinputs}
                activityCategoryHasInputs={activityCategoryHasInputs}
                totalPeriodInputsForActivity={totalPeriodInputsForActivity}
                totalInputsForPeriod={totalInputsForPeriod}
                periodErrorsMessages={periodErrorsMessages}
                initialComment={initialComment}
                onChangeComment={onChangeComment}
                updatedAt={updatedAt}
                updatedBy={updatedBy}
              />
            </div>
            <div className="rest-form" ref={restRef} role="rest-form">
              <RestForm
                restRespected={restRespected}
                updateRestRespected={updateRestRespected}
              />
            </div>
            <div className="remote-office-days" ref={remoteOfficeDaysRef}>
              <RemoteOfficeDaysForm
                hasRemoteDaysError={hasRemoteDaysError}
                hasCustomerSiteDaysError={hasCustomerSiteDaysError}
                daysInRemote={daysInRemote}
                debounceUpdateNbDaysInRemote={debounceUpdateNbDaysInRemote}
                daysInCustomerSite={daysInCustomerSite}
                debounceUpdateNbDaysInCustomerSite={debounceUpdateNbDaysInCustomerSite}
                workingDaysCount={workingDaysCount}
              />
            </div>
            <div className="happiness-indicator-form" ref={happinessIndicatorRef}>
              <HappinessIndicatorForm
                periodNumber={periodNumber}
                happinessIndicatorForm={happinessIndicatorForm}
                timesheetId={timesheetId}
              />
            </div>
            <div className="meal-form" ref={mealRef}>
              <MealForm
                periodNumber={periodNumber}
                timesheetId={timesheetId}
              />
            </div>
            <div className="period-validation">
              <PeriodSubmit
                validationDisabled={validationDisabled}
                submitPeriod={submitPeriod}
                missingLegalInfoMessages={missingLegalInfoMessages}
              />
            </div>
          </div>
        </StyledPeriodValidationModal>
      </Modal>
      <ValidationConfirmationModal
        periodSubmitted={submitted}
        close={closeSubmittedModal}
      />
    </>
  )
}

export default PeriodValidationModal
