import { useRef } from 'react'

export const useDebouncer = () => {
  const debouncer = useRef(null)

  const debounce = (callback, timeout) => new Promise((resolve) => {
    if (debouncer.current !== null) {
      clearTimeout(debouncer.current)
    }

    debouncer.current = setTimeout(() => resolve(callback()), timeout)
  })

  return { debounce }
}
