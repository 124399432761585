import translationFR from './fr.json'
import translationEN from './en.json'
import iconFR from '../assets/images/localeFR.svg'
import iconEN from '../assets/images/localeEN.svg'

export default {
  fr: {
    translation: translationFR,
    icon: iconFR,
  },
  en: {
    translation: translationEN,
    icon: iconEN,
  },
}
