import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectServiceEndOfYearDiscountStyled } from './ProjectServiceEndOfYearDiscount.styled'
import Input from '../input/_ExportDS/InputWithStyle/Input'
import Button from '../input/_ExportDS/ButtonWithStyle/Button'
import pictoEdit from '../../../assets/images/editBlue.svg'
import { parseFloatWithAnySeparator } from '../../helpers/stringHelper'

export const ProjectServiceEndOfYearDiscount = () => {
  const { t } = useTranslation()
  const {
    project,
    errors,
    fetchUpdateServiceEndOfYearDiscount,
    serviceEndOfYearDiscountAmount,
    serviceEndOfYearDiscountPercent,
    setServiceEndOfYearDiscountAmount,
    setServiceEndOfYearDiscountPercent,
    isProjectPresales,
  } = useProjectSalesContext()

  const [isAllEditable, setIsAllEditable] = useState(true)
  const [serviceEndOfYearDiscountAmountEditable, setServiceEndOfYearDiscountAmountEditable] = useState(false)
  const [serviceEndOfYearDiscountPercentEditable, setServiceEndOfYearDiscountPercentEditable] = useState(false)
  const [computedServiceEndOfYearDiscountAmount, setComputedServiceEndOfYearDiscountAmount] = useState(null)
  const [computedServiceEndOfYearDiscountPercent, setComputedServiceEndOfYearDiscountPercent] = useState(null)

  const calculateComputedServiceEndOfYearDiscountAmount = (isServiceEndOfYearDiscountAmountEmpty) => {
    if (isServiceEndOfYearDiscountAmountEmpty && serviceEndOfYearDiscountPercent !== null && !Number.isNaN(serviceEndOfYearDiscountPercent)) {
      return parseFloatWithAnySeparator((serviceEndOfYearDiscountPercent / 100) * project.service).toFixed(2)
    }

    return ''
  }

  const calculateComputedServiceEndOfYearDiscountPercent = (isServiceEndOfYearDiscountPercentEmpty) => {
    if (isServiceEndOfYearDiscountPercentEmpty && serviceEndOfYearDiscountAmount !== null && !Number.isNaN(serviceEndOfYearDiscountAmount)) {
      return parseFloatWithAnySeparator((serviceEndOfYearDiscountAmount / project.service) * 100).toFixed(2)
    }

    return ''
  }

  useEffect(() => {
    const isServiceEndOfYearDiscountPercentEmpty = (serviceEndOfYearDiscountPercent === null || Number.isNaN(serviceEndOfYearDiscountPercent))
    const isServiceEndOfYearDiscountAmountEmpty = (serviceEndOfYearDiscountAmount === null || Number.isNaN(serviceEndOfYearDiscountAmount))

    setServiceEndOfYearDiscountAmountEditable((serviceEndOfYearDiscountPercent === null || Number.isNaN(serviceEndOfYearDiscountPercent)))
    setServiceEndOfYearDiscountPercentEditable((serviceEndOfYearDiscountAmount === null || Number.isNaN(serviceEndOfYearDiscountAmount)))

    setComputedServiceEndOfYearDiscountAmount(calculateComputedServiceEndOfYearDiscountAmount(isServiceEndOfYearDiscountAmountEmpty))
    setComputedServiceEndOfYearDiscountPercent(calculateComputedServiceEndOfYearDiscountPercent(isServiceEndOfYearDiscountPercentEmpty))
  }, [serviceEndOfYearDiscountAmount, serviceEndOfYearDiscountPercent, isAllEditable])

  useEffect(() => {
    if (project !== null) {
      const isServiceEndOfYearDiscountAmounPresent = project.service_end_of_year_discount !== undefined && project.service_end_of_year_discount !== null
      const isServiceEndOfYearDiscountPercentPresent = project.service_end_of_year_discount_percent !== undefined && project.service_end_of_year_discount_percent !== null
      const hasErrors = errors.length > 0

      setServiceEndOfYearDiscountAmount(project.service_end_of_year_discount)
      setServiceEndOfYearDiscountPercent(project.service_end_of_year_discount_percent && (project.service_end_of_year_discount_percent * 100).toFixed(2))
      setIsAllEditable((!isServiceEndOfYearDiscountAmounPresent && !isServiceEndOfYearDiscountPercentPresent) || hasErrors)

      setComputedServiceEndOfYearDiscountAmount(calculateComputedServiceEndOfYearDiscountAmount(true))
      setComputedServiceEndOfYearDiscountPercent(calculateComputedServiceEndOfYearDiscountPercent(true))
    }
  }, [project, errors])

  if (project === null) {
    return <div>...</div>
  }

  return (
    isProjectPresales() && (
      <ProjectServiceEndOfYearDiscountStyled>
        <div className="content-left">
          <div className="input-group">
            <Input
              className="value"
              name={t('project.revenue.service_end_of_year.amount_value.arialabel')}
              title={t('project.revenue.service_end_of_year.amount_value.text')}
              value={(serviceEndOfYearDiscountAmount || serviceEndOfYearDiscountAmount === 0) ? serviceEndOfYearDiscountAmount : computedServiceEndOfYearDiscountAmount}
              type="number"
              disabled={!serviceEndOfYearDiscountAmountEditable || !isAllEditable || project.service === null || project.service < 0}
              onChange={(value) => setServiceEndOfYearDiscountAmount(parseFloatWithAnySeparator(value))}
              onValidate={() => serviceEndOfYearDiscountAmount >= 0
                && (Number.isInteger(serviceEndOfYearDiscountAmount))
                && fetchUpdateServiceEndOfYearDiscount(serviceEndOfYearDiscountAmount, serviceEndOfYearDiscountPercent)}
            />
            <i className={!serviceEndOfYearDiscountAmountEditable || !isAllEditable ? 'disabled-input' : ''}>€</i>
          </div>
          <div className="input-group">
            <Input
              className="value"
              name={t('project.revenue.service_end_of_year.percent_value.arialabel')}
              title={t('project.revenue.service_end_of_year.percent_value.text')}
              value={(serviceEndOfYearDiscountPercent || serviceEndOfYearDiscountPercent === 0) ? serviceEndOfYearDiscountPercent : computedServiceEndOfYearDiscountPercent}
              type="number"
              disabled={!serviceEndOfYearDiscountPercentEditable || !isAllEditable || project.service === null || project.service < 0}
              onChange={(value) => setServiceEndOfYearDiscountPercent(parseFloatWithAnySeparator(value))}
              onValidate={() => serviceEndOfYearDiscountPercent >= 0
                && (Number.isInteger(serviceEndOfYearDiscountPercent))
                && fetchUpdateServiceEndOfYearDiscount(serviceEndOfYearDiscountAmount, serviceEndOfYearDiscountPercent)}
            />
            <i className={!serviceEndOfYearDiscountPercentEditable || !isAllEditable ? 'disabled-input' : ''}>%</i>
          </div>
        </div>
        <div>
          {
            isAllEditable
              ? (
                <Button
                  className="create"
                  name={t('project.revenue.service_end_of_year.create.arialabel')}
                  text={t('project.revenue.service_end_of_year.create.text')}
                  disabled={
                    project.service === null
                    || project.service < 0
                    || serviceEndOfYearDiscountAmount < 0
                    || serviceEndOfYearDiscountPercent < 0
                    || (Number.isNaN(serviceEndOfYearDiscountAmount) && Number.isNaN(serviceEndOfYearDiscountPercent))
                    || (serviceEndOfYearDiscountAmount === null && Number.isNaN(serviceEndOfYearDiscountPercent))
                    || (serviceEndOfYearDiscountPercent === null && Number.isNaN(serviceEndOfYearDiscountAmount))
                  }
                  onClick={() => fetchUpdateServiceEndOfYearDiscount(serviceEndOfYearDiscountAmount, serviceEndOfYearDiscountPercent)}
                />
              )
              : (
                <Button
                  className="edit"
                  name={t('project.revenue.service_end_of_year.edit.arialabel')}
                  text={t('project.revenue.service_end_of_year.edit.text')}
                  picto={pictoEdit}
                  onClick={() => setIsAllEditable(true)}
                />
              )
          }
        </div>
      </ProjectServiceEndOfYearDiscountStyled>
    )
  )
}
