import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { StyledProjectPickerModal, customStyles } from './ProjectPickerModal.styled'
import Modal from '../../../UI/Modal/Modal'
import ProjectPicker from '../../../input/ProjectPicker/ProjectPicker'
import Button from '../../../input/Buttons/Button/Button'

import { useActivityValues } from '../../../../context/Timesheet/ActivityContext'
import { useTimesheetStateValue } from '../../../../context/Timesheet/TimesheetStateContext'
import { request } from '../../../../javascripts/HttpService'

const ProjectPickerModal = ({ open, closeModal }) => {
  const { t } = useTranslation()
  const { owner } = useTimesheetStateValue()
  const { addActivity } = useActivityValues()
  const [selectedProject, setSelectedProject] = useState('')
  const [options, setOptions] = useState([])
  const [selectedActivity, setSelectedActivity] = useState('')

  const getProjectActivities = (projectId) => {
    if (projectId === undefined) return

    const url = `/activities/project_activities.json?${
      new URLSearchParams({ project_id: projectId })}`

    request(url, { method: 'GET' })
      .then((dataJson) => {
        setOptions(
          dataJson.map((activity) => ({
            selectedValue: activity.id,
            label: activity.full_title,
          })),
        )
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  useEffect(() => {
    getProjectActivities(selectedProject.id)
  }, [selectedProject])

  const changeProject = (project) => {
    setSelectedProject({
      id: project.selectedValue,
      label: project.label,
    })

    setSelectedActivity('')
  }

  const changeActivity = (activity) => {
    setSelectedActivity({
      id: activity.selectedValue,
      label: activity.label,
    })
  }

  const assignActivityToPerson = () => {
    closeModal()
    addActivity(selectedActivity)
  }

  return (
    <Modal
      open={open}
      close={closeModal}
      height="380x"
      width="730px"
    >
      <StyledProjectPickerModal>
        <h3>{t('project.addProject')}</h3>
        <p>{t('timesheet.addRowInTwoSteps')}</p>
        <div>
          <label htmlFor="project-picker">
            {`1 : ${t('project.selectProject')}`}
          </label>
          <div aria-label="Project picker" role="search" data-project={selectedProject.label}>
            <ProjectPicker
              id="project-picker"
              value={selectedProject}
              onChange={changeProject}
              scope="active"
              personId={owner.id}
            />
          </div>
        </div>
        <div>
          <label htmlFor="activity-picker">
            {`2 : ${t('activity.selectActivity')}`}
          </label>
          <div aria-label="Activity picker" data-activity={selectedActivity.label}>
            <Select
              id="activity-picker"
              isDisabled={!selectedProject}
              value={selectedActivity}
              options={options}
              styles={customStyles}
              onChange={changeActivity}
              noOptionsMessage={() => (
                <span>{t('select.activity.noOptionsMessage')}</span>
              )}
            />
            {!selectedProject && <p className="missingProject">{t('activity.missingProject')}</p>}
          </div>
        </div>
        <Button
          type="button"
          color="primary"
          disabled={!selectedActivity || !selectedProject}
          click={assignActivityToPerson}
          ariaLabel="Add project"
        >
          {t('project.addProject')}
        </Button>
      </StyledProjectPickerModal>
    </Modal>
  )
}

export default ProjectPickerModal
