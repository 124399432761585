import React from 'react'
import { useTranslation } from 'react-i18next'

import './ExpensesReportRow.scss'
import { request } from '../../../javascripts/HttpService'

const ExpensesReportDetail = ({
  index,
  kind,
  expense,
  currency,
  expensesCount,
}) => {
  const { t } = useTranslation()
  const expenseDay = new Date(expense.day).toLocaleDateString()

  const mergeKindRows = () => (
    <td className="expense-kind" rowSpan={expensesCount}>
      {t(`ExpensesReportTable.${kind}`)}
    </td>
  )

  const navigateToExpense = () => {
    const expenseCreatedAt = new Date(expense.created_at)
    const day = expenseCreatedAt.getDate()
    const month = expenseCreatedAt.getMonth() + 1
    const year = expenseCreatedAt.getFullYear()
    return `/expenses?utf8utf8=✓&date%5Byear%5D=${year}&date%5Bmonth%5D=${month}&date%5Bday%5D=${day}&person_id=${expense.person_id}`
  }

  const showReceiptLink = (receiptIndex) => (
    <div
      className="receipt-link"
      key={receiptIndex}
      onClick={() => navigateToReceipt(receiptIndex)}
    >
      {t('ExpensesReportTable.showReceipt')}
    </div>
  )

  const navigateToReceipt = (receiptIndex) => {
    const url = `/expenses/${expense.id}/receipts/${receiptIndex}`

    request(url, { method: 'GET' })
      .then((data_json) => {
        window.open(data_json.url, '_blank')
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <tr>
      {index == 0 && mergeKindRows()}
      <td>
        {' - '}
        {`${expenseDay}`}
        {' - '}
      </td>
      <td>
        <a href={navigateToExpense()} target="_blank" rel="noopener noreferrer">
          {expense.first_name}
          {' '}
          {expense.last_name}
          {' ('}
          {expense.nickname}
          {')'}
        </a>
      </td>
      <td>
        {expense.value}
        {' '}
        {currency}
      </td>
      <td>
        {' '}
        {expense.label}
        {' '}
      </td>
      <td>
        {expense.has_receipt
          ? expense.receipts.map((receipt, receiptIndex) => showReceiptLink(receiptIndex))
          : t('ExpensesReportTable.noReceipt')}
      </td>
    </tr>
  )
}

export default ExpensesReportDetail
