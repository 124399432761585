/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Support component names relative to this directory:
import '../locale/i18n'
import '../style/application.scss'
import WebpackerReact from 'webpacker-react'

import {
  ProfileScreen,
  ReleaseNoteScreen,
  ActivityOverviewScreen,
  ExpensesScreen,
  ExpenseReportScreen,
  ProjectExpenseSummary,
  TimesheetScreen,
  SubcontractorsOverviewScreen,
  PeopleListingScreen,
  ManagePeriodsScreen,
  ProjectFormScreen,
  ProjectSalesScreen,
  ProjectSatisfactionScreen,
  ProjectSummaryScreen,
  ProjectDataHatScreen,
  ProjectQualityScreen,
  ProjectContractScreen,
  ProjectCoordinationScreen,
  HolidaysScreen,
  ConsecutivePaidLeaveScreen,
  StaffingScreen,
} from '../src/screens'
import { NavbarContainer, ProjectTurnoverBreakdown } from '../src/components'
import { usePackedComponent } from '../src/hooks/usePackedComponent/usePackedComponent'
import theme from '../style/theme'
import SynchronizationScreen from '../src/screens/SynchronizationScreen/SynchronizationScreen'

WebpackerReact.setup({ ProfileScreen })
WebpackerReact.setup({ ReleaseNoteScreen })
WebpackerReact.setup({ NavbarContainer })
WebpackerReact.setup({ ManagePeriodsScreen })
WebpackerReact.setup({ ActivityOverviewScreen })
WebpackerReact.setup({ ExpensesScreen })
WebpackerReact.setup({ ExpenseReportScreen })
WebpackerReact.setup({ ProjectExpenseSummary })
WebpackerReact.setup({ TimesheetScreen })
WebpackerReact.setup({ SubcontractorsOverviewScreen })
WebpackerReact.setup({ PeopleListingScreen })
WebpackerReact.setup({ SynchronizationScreen })
WebpackerReact.setup({ ProjectFormScreen })
WebpackerReact.setup({ ProjectSalesScreen })
WebpackerReact.setup({ ProjectSatisfactionScreen })
WebpackerReact.setup({ ProjectSummaryScreen })
WebpackerReact.setup({ ProjectDataHatScreen })
WebpackerReact.setup({ ProjectQualityScreen })
WebpackerReact.setup({ ProjectContractScreen })
WebpackerReact.setup({ ProjectCoordinationScreen })
WebpackerReact.setup({ ProjectTurnoverBreakdown: usePackedComponent(ProjectTurnoverBreakdown, theme) })
WebpackerReact.setup({ HolidaysScreen })
WebpackerReact.setup({ ConsecutivePaidLeaveScreen })
WebpackerReact.setup({ StaffingScreen })
