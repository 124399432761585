import React, {
  useState, useContext, createContext,
} from 'react'
import { cleanWithPunctuation } from '../../helpers/stringHelper'
import { getDurationInYear } from '../../helpers/datePickerHelper'

const PeopleListingContext = createContext()

export const PeopleListingContextProvider = ({ children, people }) => {
  const urlAskbob = 'https://askbob.octo.tools'
  const urlAskbobUsers = `${urlAskbob}/users`

  const [search, setSearch] = useState('')
  const [sortKey, setSortKey] = useState('')
  const [sortIsAscendant, setSortIsAscendant] = useState(true)
  const [showOldPeople, setShowOldPeople] = useState(false)

  const searchElements = cleanWithPunctuation(search).split(' ')

  const peopleScoped = people.filter((person) => {
    const yearsThreshold = showOldPeople ? 5 : 1
    const yearsSincePersonLeft = getDurationInYear(new Date(person.date_departure), Date.now())
    return person.date_departure === null || yearsSincePersonLeft < yearsThreshold
  })

  const peopleFilteredBySearch = peopleScoped
    .filter((person) => {
      const columns = [person.first_name, person.last_name, person.nickname, person.lob, person.league, person.job]
        .map((column) => cleanWithPunctuation(column))
      return searchElements.every((searchElement) => columns.some((column) => column.includes(searchElement)))
    })

  const peopleFilteredBySearchAndSorted = peopleFilteredBySearch
    .sort((personLeft, personRight) => {
      let comparison = 0
      const personLeftColumn = personLeft[sortKey]
      const personRightColumn = personRight[sortKey]
      if (typeof personLeftColumn === 'string' || personLeftColumn === null || personLeftColumn === undefined) {
        comparison = cleanWithPunctuation(personLeftColumn).localeCompare(cleanWithPunctuation(personRightColumn))
      } else {
        comparison = personLeftColumn - personRightColumn
      }

      return sortIsAscendant ? comparison : -comparison
    })

  const toggleSort = (columnToSort) => {
    if (sortKey === columnToSort) {
      setSortIsAscendant(!sortIsAscendant)
    } else {
      setSortKey(columnToSort)
      setSortIsAscendant(true)
    }
  }

  const toggleShowOldPeople = () => {
    setShowOldPeople(!showOldPeople)
  }

  return (
    <PeopleListingContext.Provider value={{
      urlAskbob,
      urlAskbobUsers,
      people,
      peopleScoped,
      peopleFilteredBySearch,
      peopleFilteredBySearchAndSorted,
      search,
      setSearch,
      showOldPeople,
      toggleShowOldPeople,
      sortKey,
      sortIsAscendant,
      toggleSort,
    }}
    >
      {children}
    </PeopleListingContext.Provider>
  )
}

export const usePeopleListingContext = () => useContext(PeopleListingContext)
