import styled from 'styled-components'

export const ManagePeriodsStyled = styled.div`
  width: 90%;
  padding: 5rem;
  color: ${({ theme }) => theme.colors.betterway.primary[100]};
  font-size: 1.4rem;
  display: flex;
  flex-flow: column nowrap;

  h1 {
    font-size: 2.4rem;
  }

  .manage-periods-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 20rem;
    margin-bottom: 2rem;

    .header-content {
      display: flex;
      flex-direction: row;
      height: 100%;
      margin-top: 1rem;
      padding: 1.6rem;
      border: 1px solid ${({ theme }) => theme.colors.betterway.primary[50]};
      border-radius: 1rem;
      background-color: ${({ theme }) => theme.colors.betterway.primary[5]};

      .header-content-left {
        display: flex;
        flex-direction: column;

        h2 {
          font-weight: normal;
          font-size: 1.4rem;
        }
      }
    }
  }
`
