import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectSummaryContext } from '../../context/ProjectSummaryContext/ProjectSummaryContext'
import { ProjectPublishersInfoStyled } from './ProjectPublishersInfos.styled'
import { convertFirstLetterUpperCase } from '../../helpers/stringHelper'

export const ProjectPublishersInfos = () => {
  const { project } = useProjectSummaryContext()
  const { t } = useTranslation()

  if (!project) return <div>...</div>
  return (
    <ProjectPublishersInfoStyled>
      <ul>
        {
          project.publishers.length === 0
            ? t('project.summary.publisher.no_publisher')
            : project.publishers.map((publisher) => <li key={publisher.id}>{convertFirstLetterUpperCase(publisher.name)}</li>)
}
      </ul>
    </ProjectPublishersInfoStyled>
  )
}
