import React, { useState, createContext, useContext } from 'react'
import { useTimesheetParamsValue } from './TimesheetParamsContext'
import { request, requestWithCSRFToken } from '../../javascripts/HttpService'
import { usePrev } from '../../hooks/hooks'

export const ActivityContext = createContext()

export const ActivityContextProvider = ({ children, initialActivities }) => {
  const [activities, setActivities] = useState(initialActivities)
  const [inboundActivityIds, setInboundActivityIds] = useState([])
  const [activityFocus, setActivityFocus] = useState('')

  return (
    <ActivityContext.Provider value={{
      activities,
      setActivities,
      inboundActivityIds,
      setInboundActivityIds,
      activityFocus,
      setActivityFocus,
    }}
    >
      {children}
    </ActivityContext.Provider>
  )
}
const activityIdsFromActivitiesObject = (activities) => (
  Object.keys(activities).map((activityCategory) => activities[activityCategory].map((activity) => activity.id)).flat()
)

export const useActivityValues = () => {
  const {
    activities, setActivities, inboundActivityIds, setInboundActivityIds,
  } = useContext(ActivityContext)
  const [timesheetParams] = useTimesheetParamsValue()
  const { nickname } = timesheetParams.owner
  const { year } = timesheetParams.timesheetDate
  const month = timesheetParams.timesheetDate.month + 1
  const assignPersonToActivityUrl = '/activities/assign_person'
  const unassignPersonFromActivityUrl = '/activities/unassign_person.json'
  const hidePersonFromActivityUrl = '/activities/hide_visibility_activity_person'
  const getTimesheetActivitiesUrl = `/timesheet/activities/${nickname}/${year}/${month}`
  const prevActivitiesList = usePrev(activities)

  const activityCategories = Object.keys(activities)

  const activityCategoryIsNotEmpty = (activityCategory) => activities[activityCategory.toString()].length !== 0

  const activityIdsOfCategory = (activityCategory) => activities[activityCategory.toString()].map((activity) => activity.id)

  const activityCategoryHasInputs = (activityCategory, timeinputs) => {
    const receiveTimeInputs = { ...timeinputs }
    Object.keys(receiveTimeInputs).forEach((activityId) => {
      if (Object.keys(timeinputs[activityId]).length === 0) delete receiveTimeInputs[activityId]
    })

    return activityIdsOfCategory(activityCategory).some((activityId) => Object.keys(receiveTimeInputs).includes(activityId.toString()))
  }

  const addActivity = (activity) => {
    const body = {
      person_id: timesheetParams.owner.id,
      activity_id: activity.id,
      reload_erb: false,
    }

    requestWithCSRFToken(assignPersonToActivityUrl, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
      .then(() => request(getTimesheetActivitiesUrl, { method: 'GET' })
        .then((newActivitiesList) => {
          setActivities(newActivitiesList)

          const activityIdsFromNewList = activityIdsFromActivitiesObject(newActivitiesList)
          const activityIdsFromPrevList = activityIdsFromActivitiesObject(prevActivitiesList)
          const newActivityIds = activityIdsFromNewList.filter((idFromNewList) => !activityIdsFromPrevList.includes(idFromNewList))

          setInboundActivityIds(newActivityIds)
        })
        /* eslint-disable-next-line no-console */
        .catch((error) => console.log(error)))
    /* eslint-disable-next-line no-console */
      .catch((error) => console.log(error))
  }

  const deleteActivity = (activity) => {
    const body = {
      person_id: timesheetParams.owner.id,
      activity_id: activity.id,
    }

    requestWithCSRFToken(unassignPersonFromActivityUrl, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
      .then(() => request(getTimesheetActivitiesUrl, { method: 'GET' })
        .then((newActivitiesList) => {
          setActivities(newActivitiesList)
        })
        /* eslint-disable-next-line no-console */
        .catch((error) => console.log(error)))
    /* eslint-disable-next-line no-console */
      .catch((error) => console.log(error))
  }

  const hideActivity = (activity) => {
    const body = {
      person_id: timesheetParams.owner.id,
      activity_id: activity.id,
    }

    requestWithCSRFToken(hidePersonFromActivityUrl, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
      .then(() => request(getTimesheetActivitiesUrl, { method: 'GET' })
        .then((newActivitiesList) => {
          setActivities(newActivitiesList)
        })
      /* eslint-disable-next-line no-console */
        .catch((error) => console.log(error)))
    /* eslint-disable-next-line no-console */
      .catch((error) => console.log(error))
  }

  return {
    activities,
    activityCategories,
    activityCategoryIsNotEmpty,
    activityIdsOfCategory,
    activityCategoryHasInputs,
    addActivity,
    deleteActivity,
    inboundActivityIds,
    hideActivity,

  }
}
