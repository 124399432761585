import React, { useMemo } from 'react'
import Markdown from 'react-markdown'
import { useTranslation } from 'react-i18next'
import { Icon, Tooltip } from '@produits-internes-oss/design-system-components'
import { monthList } from '../../helpers/datePickerHelper'
import { getClass } from '../../helpers/stringHelper'
import { HolidaysCounterStyled } from './HolidaysCounter.styled'

export const HolidaysCounter = ({ title, advices, alert, limit, consumed, total, isImportant }) => {
  const { t } = useTranslation()

  const { year, month, day } = useMemo(() => ({ year: limit.getFullYear(), month: limit.getMonth(), day: limit.getDate() }), [limit])

  const remaining = useMemo(() => total - consumed, [consumed, total])

  return (
    <HolidaysCounterStyled className="octo">
      {
        alert ? (
          <>
            <div className="title"><h3>{title}</h3></div>
            <div className="alert">{alert}</div>
          </>
        ) : (
          <>
            <div className="title">
              <h3>{title}</h3>
              {
                advices && advices.length > 0 && (
                <Tooltip
                  className="advices"
                  skin="light"
                  position="bottom"
                  trigger={({ open, close }) => (
                    <button type="button" tabIndex={-1} aria-hidden onMouseEnter={() => open()} onMouseLeave={() => close()}>
                      <Icon className="trigger" name="warning" />
                    </button>
                  )}
                  content={() => <ul>{advices.map((advice) => <li key={advice}><Markdown>{advice}</Markdown></li>)}</ul>}
                />
                )
              }
            </div>
            <div className={getClass('limit', isImportant && 'important')}>
              {t('holidays.limit', { date: t('holidays.date', { day, month: t(`months.${monthList[month]}.full`), year }) })}
            </div>
            <div className="counter">
              <span className={getClass('remaining', remaining < 0 && 'negative')}>{remaining.toFixed(2)}</span>
              <span className="total">{t('holidays.counter', { total: total.toFixed(2) })}</span>
            </div>
          </>
        )
      }
    </HolidaysCounterStyled>
  )
}
