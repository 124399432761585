import React, { useCallback, useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  InputNumber, InputSelect, InputText, Button, Tooltip, InputSearch,
} from '@produits-internes-oss/design-system-components'
import { useDebouncer } from '../../hooks/useDebouncer/useDebouncer'
import { useSearch } from '../../hooks/useSearch/useSearch'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectInlineStaffingFormStyled } from './ProjectInlineStaffingForm.styled'

export const ProjectInlineStaffingForm = ({ onClose }) => {
  const { t } = useTranslation()
  const { debounce } = useDebouncer()
  const { fetchSearch } = useSearch()
  const [validateDisabled, setValidateDisabled] = useState(false)
  const roleInputRef = useRef(null)
  const dailyRateInputRef = useRef(null)
  const titleInputRef = useRef(null)
  const personSearchInputRef = useRef(null)
  const profileInputRef = useRef(null)
  const nbDaysInputRef = useRef(null)
  const expertiseInputRef = useRef(null)
  const fullNameInputRef = useRef(null)
  const subcontractorInputRef = useRef(null)
  const activityInputRef = useRef(null)
  const quantityDaysInputRef = useRef(null)
  const purchaseOrderRef = useRef(null)
  const unitPriceInputRef = useRef(null)
  const markupInputRef = useRef(null)
  const salePriceinputRef = useRef(null)

  const {
    fetchCreateActivity,
    fetchPutActivities,
    fetchCreateProjectExpense,
    fetchPutProjectExpenses,
    updateFormStaffing,
    personRole,
    personProfile,
    setTurnoverTypeForm,
    turnoverTypeForm,
    updateFormSubcontracting,
    fetchAverageDailyRate,
    subcontractor,
    formSubcontracting,
    formStaffing,
    formDefaultValue,
    formSubcontractingError,
    formStaffingError,
    reloadForm,
    setReloadForm,
    project,
    isProjectPresales,
    getPersonName,
    inlineStaffingFormVisible,
    isKeyboardNavigation,
  } = useProjectSalesContext()

  useEffect(() => {
    if (roleInputRef.current || fullNameInputRef.current || subcontractorInputRef.current) {
      let focusableElement
      if (turnoverTypeForm === 'from_internal_team') {
        focusableElement = roleInputRef.current.querySelector('.role .control button')
      }

      if (turnoverTypeForm === 'intragroup') {
        focusableElement = fullNameInputRef.current.querySelector('.full-name .control input')
      }

      if (turnoverTypeForm === 'subcontracting') {
        focusableElement = subcontractorInputRef.current.querySelector('.subcontractor .control button')
      }

      if (focusableElement) {
        if (isKeyboardNavigation) {
          focusableElement.focus()
          focusableElement.click()
        }
      }
    }
  }, [inlineStaffingFormVisible, turnoverTypeForm, reloadForm])

  const focusNextElement = (nextElementRef, nextElementType) => {
    if (nextElementRef && nextElementRef.current) {
      const target = `.control ${nextElementType}`
      const nextElement = nextElementRef.current?.querySelector(target)
      nextElement.focus()
    }
  }

  const SalePrice = useCallback(() => (
    <div className="form-item" ref={salePriceinputRef}>
      <InputNumber
        className="form-control"
        skin="light"
        name="sale price number input"
        title={t('project.staffing.form.sale_price')}
        localization={{ invalid: { text: 'invalid', arialabel: 'aria label' } }}
        required
        disabled
        valueInitial={formSubcontracting['sale_price'] !== null ? parseInt(formSubcontracting['sale_price'], 10) : null}
        onCheck={() => formSubcontracting['sale_price'] < 0 && 'doit être compléter par un nombre positif  '}
      />
    </div>
  ), [formSubcontracting['sale_price']])

  const ControlledAverageDailyRate = useCallback(() => (
    <InputNumber
      className="form-control"
      skin="light"
      name="daily_rate number input"
      title={t('project.staffing.form.daily_rate')}
      localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
      required
      disabled
      valueInitial={formStaffing['average_daily_rate']}
      onCheck={() => formStaffing['average_daily_rate'] < 0 && t('project.staffing.form.error_number')}
    />
  ), [formStaffing['average_daily_rate']])

  const errorsFormStaffing = Object.values(formStaffingError).filter(Boolean).length > 0
  const errorsFormSubcontracting = Object.values(formSubcontractingError).filter(Boolean).length > 0

  const initValueNameSubcontracting = () => {
    const idSubcontractorInArray = (personSubocontractor) => personSubocontractor[1].toString() === formSubcontracting['subcontractor_id']
    const indexSubcontractor = subcontractor.findIndex(idSubcontractorInArray)
    return indexSubcontractor
  }
  const initValueRole = () => {
    const idRoleInArray = (role) => role[1] === formStaffing['role_id']
    const indexRole = personRole.findIndex(idRoleInArray)
    return indexRole
  }
  const initProfile = () => {
    const idProfileInArray = (profile) => profile[1] === formStaffing['profile_id']
    const indexProfile = personProfile.findIndex(idProfileInArray)
    return indexProfile
  }

  const isRoleOther = () => {
    const roleOtherIdString = personRole.find(([roleName]) => roleName === 'Autre')?.[1].toString()
    const initialRoleValue = personRole[initValueRole()]

    return (roleOtherIdString === formStaffing['role_id'] || (initialRoleValue && roleOtherIdString === initialRoleValue[1]?.toString()))
  }

  return (
    <ProjectInlineStaffingFormStyled
      className="inline-staffing-form"
      tabindex="0"
      role="form"
      aria-label={t('project.staffing.form.title')}
    >
      {
        (turnoverTypeForm && !reloadForm) && (
          <div className="form-content">
            {
              turnoverTypeForm === 'from_internal_team'
                ? (
                  <>
                    <div className="form-item" ref={roleInputRef}>
                      <InputSelect
                        className="form-control role"
                        skin="light"
                        name="person role"
                        title={t('project.staffing.form.role')}
                        options={personRole.map((role) => role[0])}
                        required
                        valueInitial={initValueRole().toString()}
                        onChange={([value]) => {
                          updateFormStaffing('role_id', (personRole[value][1]).toString())
                          if (isKeyboardNavigation) {
                            debounce(() => {
                              if (isProjectPresales()) {
                                return (personRole[value][0] === 'Autre'
                                  ? focusNextElement(titleInputRef, 'input')
                                  : focusNextElement(profileInputRef, 'button'))
                              }

                              return personRole[value][0] === 'Autre'
                                ? focusNextElement(titleInputRef, 'input')
                                : focusNextElement(personSearchInputRef, 'input')
                            }, 500)
                          }
                        }}
                      />
                    </div>
                    {
                      isRoleOther() && (
                        <div className="form-item" ref={titleInputRef}>
                          <InputText
                            className="form-control"
                            skin="light"
                            name="activity title"
                            required
                            title={t('project.staffing.form.activity_title')}
                            localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
                            valueInitial={formStaffing['title']}
                            onCheck={() => (!formStaffing['title'] || formStaffing['title'].length === 0) && t('project.staffing.form.error_title_length')}
                            onChange={(value) => {
                              updateFormStaffing('title', value.toString())
                            }}
                            onValidate={() => {
                              if (isKeyboardNavigation) {
                                if (isProjectPresales()) {
                                  focusNextElement(profileInputRef, 'button')
                                } else {
                                  focusNextElement(personSearchInputRef, 'input')
                                }
                              }
                            }}
                          />
                        </div>
                      )
                    }
                    {
                      !isProjectPresales() && (
                        <Tooltip
                          className="input-search-tooltip"
                          position="top"
                          skin="light"
                          trigger={
                            ({ open, close }) => (
                              <div
                                className="input-search"
                                onMouseEnter={() => formStaffing['total_time_in_days'] && open()}
                                onMouseLeave={() => formStaffing['total_time_in_days'] && close()}
                              >
                                <div className="form-item" ref={personSearchInputRef}>
                                  <InputSearch
                                    className="form-control"
                                    skin="light"
                                    name="search a person"
                                    title={t('project.staffing.form.person_intern')}
                                    disabled={formStaffing['total_time_in_days']}
                                    required={false}
                                    valueInitial={{ key: formStaffing['person_id'], search: formStaffing['person_search'] }}
                                    onSearch={(search) => debounce(() => search.length >= 3 && fetchSearch(search, 'Person', null, getPersonName), 500)}
                                    onChange={({ key }) => {
                                      fetchAverageDailyRate(
                                        project?.mission_maker === 'acn_prime' || project?.mission_maker === 'acn_prime_by_octo',
                                        key && parseInt(key, 10).toString(),
                                        formStaffing['profile_id'],
                                      )
                                      updateFormStaffing('person_id', key && parseInt(key, 10).toString())
                                      debounce(() => { focusNextElement(profileInputRef, 'button') }, 1500)
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          }
                          content={
                            () => (
                              <div>
                                <p>Cette personne a déjà pointé sur cette ligne, vous ne pouvez pas la désaffecter</p>
                              </div>
                            )
                          }
                        />
                      )
                    }
                    <div className="form-item" ref={profileInputRef}>
                      <InputSelect
                        className="form-control"
                        skin="light"
                        name="person level"
                        title={t('project.staffing.form.level')}
                        options={personProfile.map((profile) => profile[0])}
                        required={false}
                        valueInitial={initProfile().toString()}
                        onChange={([value]) => {
                          fetchAverageDailyRate(
                            project?.mission_maker === 'acn_prime' || project?.mission_maker === 'acn_prime_by_octo',
                            formStaffing['person_id'],
                            (personProfile[value][1]).toString(),
                          )
                          updateFormStaffing('profile_id', (personProfile[value][1]).toString())
                          if (isKeyboardNavigation) {
                            debounce(() => { focusNextElement(nbDaysInputRef, 'input') }, 1500)
                          }
                        }}
                      />
                    </div>
                    <div className="form-item" ref={nbDaysInputRef}>
                      <InputNumber
                        className="form-control"
                        skin="light"
                        name="activity number of days"
                        title={t('project.staffing.form.nb_days')}
                        localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
                        required
                        valueInitial={formStaffing['nb_days']}
                        onCheck={() => formStaffing['nb_days'] < 0 && t('project.staffing.form.error_number')}
                        onChange={(value) => {
                          updateFormStaffing('nb_days', value.toString())
                        }}
                        onValidate={() => {
                          if (isKeyboardNavigation) {
                            focusNextElement(dailyRateInputRef, 'input')
                          }
                        }}

                      />
                    </div>
                    {
                      project?.mission_maker === 'acn_prime' || project?.mission_maker === 'acn_prime_by_octo'
                        ? <ControlledAverageDailyRate />
                        : (
                          <div className="form-item" ref={dailyRateInputRef}>
                            <InputNumber
                              className="form-control"
                              skin="light"
                              name="activity daily rate"
                              title={t('project.staffing.form.daily_rate')}
                              localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
                              required
                              valueInitial={formStaffing['average_daily_rate']}
                              onCheck={() => formStaffing['average_daily_rate'] < 0 && t('project.staffing.form.error_number')}
                              onChange={(value) => {
                                updateFormStaffing('average_daily_rate', value.toString())
                              }}
                              onValidate={() => {
                                if (isKeyboardNavigation) {
                                  focusNextElement(expertiseInputRef, 'input')
                                }
                              }}
                            />
                          </div>
                        )
                    }
                    <div className="form-item" ref={expertiseInputRef}>
                      <InputText
                        className="form-control"
                        skin="light"
                        name="person expertise"
                        title={t('project.staffing.form.expertise')}
                        localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
                        valueInitial={formStaffing['expertise']}
                        onCheck={() => formStaffing['expertise'] < 0 && t('project.staffing.form.error_number')}
                        onChange={(value) => updateFormStaffing('expertise', value.toString())}
                      />
                    </div>
                  </>
                )
                : (
                  <>
                    {
                      turnoverTypeForm === 'intragroup'
                        ? (
                          <div className="form-item" ref={fullNameInputRef}>
                            <InputText
                              className="form-control full-name"
                              skin="light"
                              name="person first name and last name"
                              title={t('project.staffing.form.name_last_name')}
                              required
                              valueInitial={formSubcontracting['subcontractor_name']}
                              onChange={(value) => {
                                updateFormSubcontracting('subcontractor_name', value)
                              }}
                              onValidate={() => {
                                if (isKeyboardNavigation) {
                                  focusNextElement(activityInputRef, 'input')
                                }
                              }}
                            />
                          </div>
                        )
                        : (
                          <div className="form-item" ref={subcontractorInputRef}>
                            <InputSelect
                              className="form-control subcontractor"
                              skin="light"
                              name="person role"
                              title={t('project.staffing.form.subcontracting')}
                              options={subcontractor.map((subcontractorData) => subcontractorData[0])}
                              required
                              valueInitial={initValueNameSubcontracting().toString()}
                              onChange={([value]) => {
                                updateFormSubcontracting('subcontractor_id', (subcontractor[value][1]).toString())
                                if (isKeyboardNavigation) {
                                  debounce(() => { focusNextElement(activityInputRef, 'input') }, 1500)
                                }
                              }}
                            />
                          </div>
                        )
                    }
                    <div className="form-item" ref={activityInputRef}>
                      <InputText
                        className="form-control"
                        skin="light"
                        name="activity name"
                        title={t('project.staffing.form.activity')}
                        required
                        valueInitial={formSubcontracting['label']}
                        onChange={(value) => {
                          updateFormSubcontracting('label', value)
                        }}
                        onValidate={() => {
                          if (isKeyboardNavigation) {
                            focusNextElement(quantityDaysInputRef, 'input')
                          }
                        }}
                      />
                    </div>
                    <div className="form-item" ref={quantityDaysInputRef}>
                      <InputNumber
                        className="form-control"
                        skin="light"
                        name="activity number of days"
                        title={t('project.staffing.form.quantity_day')}
                        localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
                        required
                        valueInitial={formSubcontracting['quantity']}
                        onCheck={() => formSubcontracting['quantity'] < 0 && t('project.staffing.form.error_number')}
                        onChange={(value) => {
                          updateFormSubcontracting('quantity', value.toString())
                        }}
                        onValidate={() => {
                          if (isKeyboardNavigation) {
                            if (turnoverTypeForm !== 'intragroup') {
                              focusNextElement(purchaseOrderRef, 'input')
                            } else {
                              focusNextElement(unitPriceInputRef, 'input')
                            }
                          }
                        }}
                      />
                    </div>
                    {
                      turnoverTypeForm !== 'intragroup' && (
                        <div className="form-item" ref={purchaseOrderRef}>
                          <InputText
                            className="form-control"
                            skin="light"
                            name="purchase order"
                            title={t('project.staffing.form.number_po')}
                            required
                            valueInitial={formSubcontracting['purchase_order']}
                            onCheck={() => formSubcontracting['purchase_order'] < 0 && t('project.staffing.form.error_number')}
                            onChange={(value) => {
                              updateFormSubcontracting('purchase_order', value.toString())
                            }}
                            onValidate={() => {
                              if (isKeyboardNavigation) {
                                focusNextElement(unitPriceInputRef, 'input')
                              }
                            }}
                          />
                        </div>
                      )
                    }
                    <div className="form-item" ref={unitPriceInputRef}>
                      <InputNumber
                        className="form-control"
                        skin="light"
                        name="unit price"
                        title={t('project.staffing.form.unit_price')}
                        localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
                        required
                        valueInitial={formSubcontracting['unit_price']}
                        onCheck={() => formSubcontracting['unit_price'] < 0 && t('project.staffing.form.error_number')}
                        onChange={(value) => {
                          updateFormSubcontracting('unit_price', value.toString())
                          if (formSubcontracting['markup']) {
                            updateFormSubcontracting('sale_price', (value + parseInt(formSubcontracting['markup'], 10)).toString())
                          }
                        }}
                        onValidate={() => {
                          if (isKeyboardNavigation) {
                            focusNextElement(markupInputRef, 'input')
                          }
                        }}
                      />
                    </div>
                    <div className="form-item" ref={markupInputRef}>
                      <InputNumber
                        className="form-control"
                        skin="light"
                        name="markup"
                        title={t('project.staffing.form.markup')}
                        localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
                        required
                        valueInitial={formSubcontracting['markup']}
                        onCheck={() => formSubcontracting['markup'] < 0 && t('project.staffing.form.error_number')}
                        onChange={(value) => {
                          updateFormSubcontracting('markup', value.toString())
                          if (formSubcontracting['unit_price']) {
                            updateFormSubcontracting('sale_price', (parseInt(formSubcontracting['unit_price'], 10) + value).toString())
                          }
                        }}
                      />
                    </div>
                    <SalePrice />
                  </>
                )
            }
            {
              <Button
                className="validate"
                skin="dark"
                name="add activity"
                text={(formStaffing['id'] || formSubcontracting['id']) ? t('project.staffing.form.update') : t('project.staffing.form.add')}
                disabled={validateDisabled || turnoverTypeForm === 'from_internal_team' ? errorsFormStaffing : errorsFormSubcontracting}
                onClick={async () => {
                  setValidateDisabled(true)
                  if ((formStaffing['id'] || formSubcontracting['id'])) {
                    if (turnoverTypeForm === 'from_internal_team') {
                      await fetchPutActivities([
                        {
                          id: formStaffing['id'],
                          nb_days: formStaffing['nb_days'],
                          role_id: formStaffing['role_id'],
                          title: formStaffing['title'],
                          profile_id: formStaffing['profile_id'],
                          person_ids: [formStaffing['person_id']],
                          average_daily_rate: formStaffing['average_daily_rate'],
                          expertise: formStaffing['expertise'],
                        },
                      ])
                    } else {
                      await fetchPutProjectExpenses(formSubcontracting)
                    }
                  } else if (turnoverTypeForm === 'from_internal_team') {
                    fetchCreateActivity()
                  } else {
                    fetchCreateProjectExpense()
                  }
                  setValidateDisabled(false)
                  setReloadForm(true)
                  setTurnoverTypeForm(null)
                  formDefaultValue(null)
                  onClose()
                }}
              />
            }
          </div>
        )
      }
    </ProjectInlineStaffingFormStyled>
  )
}
