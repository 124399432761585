const getInput = (x, y) => document.querySelector(`input[data-x='${x}'][data-y='${y}']`)

const getLeftInputFrom = (currentInput, numberOfDays, arrowNavigation) => {
  const x = parseInt(currentInput.dataset.x, 10)
  const y = parseInt(currentInput.dataset.y, 10)
  if (y === 1 && arrowNavigation) return getInput(x, numberOfDays)
  if (y === 1) return getInput(x, y)
  return getInput(x, y - 1)
}

const getRightInputFrom = (currentInput, numberOfDays, arrowNavigation) => {
  const x = parseInt(currentInput.dataset.x, 10)
  const y = parseInt(currentInput.dataset.y, 10)
  if (y === numberOfDays && arrowNavigation) return getInput(x, 1)
  if (y === numberOfDays) return getInput(x, y)
  return getInput(x, y + 1)
}

const getUpInputFrom = (currentInput, numberOfActivities) => {
  const x = parseInt(currentInput.dataset.x, 10)
  const y = parseInt(currentInput.dataset.y, 10)
  if (x === 1) return getInput(numberOfActivities, y)
  return getInput(x - 1, y)
}

const getDownInputFrom = (currentInput, numberOfActivities) => {
  const x = parseInt(currentInput.dataset.x, 10)
  const y = parseInt(currentInput.dataset.y, 10)
  if (x === numberOfActivities) return getInput(1, y)
  return getInput(x + 1, y)
}

const focusOnLeftInput = (event, numberOfDays, arrowNavigation) => {
  let leftInput = getLeftInputFrom(event.target, numberOfDays, arrowNavigation)

  while (leftInput.dataset.holiday === 'holiday') {
    if (leftInput === getLeftInputFrom(leftInput, numberOfDays, arrowNavigation)) return
    leftInput = getLeftInputFrom(leftInput, numberOfDays, arrowNavigation)
  }
  leftInput.focus()
}

const focusOnRightInput = (event, numberOfDays, arrowNavigation) => {
  let rightInput = getRightInputFrom(event.target, numberOfDays, arrowNavigation)
  while (rightInput.dataset.holiday === 'holiday') {
    if (rightInput === getRightInputFrom(rightInput, numberOfDays, arrowNavigation)) return
    rightInput = getRightInputFrom(rightInput, numberOfDays, arrowNavigation)
  }
  rightInput.focus()
}

const focusOnUpInput = (event, numberOfActivities) => getUpInputFrom(event.target, numberOfActivities).focus()

const focusOnDownInput = (event, numberOfActivities) => getDownInputFrom(event.target, numberOfActivities).focus()

const keyBoardCodes = {
  keyLeft: 37,
  keyUp: 38,
  keyRight: 39,
  keyDown: 40,
  backSpace: 8,
  spaceBar: 32,
  zero: 48,
  zeroNumpad: 96,
}

const validDigitsKeyCodesForHalfOrFullDayLeaves = {
  52: 4,
  56: 8,
  100: 4,
  104: 8,
}

const validDigitsKeyCodesForFullDayLeaves = {
  56: 8,
  104: 8,
}

const validDigitsKeyCodes = {
  49: 1,
  50: 2,
  51: 3,
  52: 4,
  53: 5,
  54: 6,
  55: 7,
  56: 8,
  97: 1,
  98: 2,
  99: 3,
  100: 4,
  101: 5,
  102: 6,
  103: 7,
  104: 8,
}

const updateIfInputIsDifferent = (event, updateActivityInputs) => {
  const previousInput = event.target
  const previousInputValue = previousInput.value
  const isPreviousInputAutomaticallyGenerated = previousInput.dataset.autoGenerated
  const newInputValue = validDigitsKeyCodes[event.keyCode].toString()
  const newEvent = event

  if (previousInputValue !== newInputValue || isPreviousInputAutomaticallyGenerated === 'true') {
    newEvent.target.value = newInputValue
    updateActivityInputs(newEvent)
  }
}

const handleDigitalInputs = (event, numberOfDays, updateActivityInputs, isHalfOrFullDayAbsence, isFullDayAbsence) => {
  if (!Object.keys(validDigitsKeyCodes).includes(event.keyCode.toString())) return
  if (isHalfOrFullDayAbsence && !Object.keys(validDigitsKeyCodesForHalfOrFullDayLeaves).includes(event.keyCode.toString())) return
  if (isFullDayAbsence && !Object.keys(validDigitsKeyCodesForFullDayLeaves).includes(event.keyCode.toString())) return
  updateIfInputIsDifferent(event, updateActivityInputs)
  focusOnRightInput(event, numberOfDays, false)
}

const clearIfPreviousInputNotEmpty = (event, updateActivityInputs) => {
  const previousInput = event.target.value
  const newEvent = event
  if (previousInput) {
    newEvent.target.value = ''
    updateActivityInputs(newEvent)
  }
}

export const keyBoardNavigation = (event, numberOfDays, numberOfActivities, updateActivityInputs, isHalfOrFullDayAbsence, isFullDayAbsence) => {
  event.preventDefault()
  switch (event.keyCode) {
    case keyBoardCodes.keyLeft:
      return focusOnLeftInput(event, numberOfDays, true)
    case keyBoardCodes.keyRight:
      return focusOnRightInput(event, numberOfDays, true)
    case keyBoardCodes.keyUp:
      return focusOnUpInput(event, numberOfActivities)
    case keyBoardCodes.keyDown:
      return focusOnDownInput(event, numberOfActivities)
    case keyBoardCodes.backSpace:
      clearIfPreviousInputNotEmpty(event, updateActivityInputs)
      return focusOnLeftInput(event, numberOfDays, false)
    case keyBoardCodes.spaceBar:
      clearIfPreviousInputNotEmpty(event, updateActivityInputs)
      return focusOnRightInput(event, numberOfDays, false)
    case keyBoardCodes.zero:
    case keyBoardCodes.zeroNumpad:
      clearIfPreviousInputNotEmpty(event, updateActivityInputs)
      return false
    default:
      return handleDigitalInputs(event, numberOfDays, updateActivityInputs, isHalfOrFullDayAbsence, isFullDayAbsence)
  }
}
