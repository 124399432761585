import styled from 'styled-components'

export const ModalInfosDeleteActivityExpenseStyled = styled.div`
      position: fixed;
      top: 0;
      left: 0;
      z-index: 3000;
      width: 100vw;
      height: 100vh;
      background-color: #00000061;

      .container-modal {
        position: fixed;
        top: 40%;
        left: 50%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 96rem;
        height: 15rem;
        padding: 3.2rem 4rem;
        border-radius: 20px;
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
        box-shadow: 0 4px 8px 0 ${({ theme }) => theme.colors.shadow};
        transform: translateX(-50%);

        .header-modal {
          display: flex;
          justify-content: end;
          width: 100%;

          .title {
            display: flex;
            justify-content: center;
            width: 100%;
            font-weight: 600;
            font-size: 1.8rem;
          }

          .picto-close {
            cursor: pointer;
          }
        }

        .content-modal {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .text {
            width: fit-content;
            height: auto;
          }
        }
      }
`
