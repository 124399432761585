import React, { useState } from 'react'

import { ReleaseNoteScreenStyled } from './ReleaseNoteScreenStyled'
import { ReleaseNote } from '../../components/ReleaseNote/ReleaseNote'

export function ReleaseNoteScreen({releaseNotes}) {
  return (
    <ReleaseNoteScreenStyled >
      <ReleaseNote releaseNotes={releaseNotes}/>
    </ReleaseNoteScreenStyled>
  )
}
