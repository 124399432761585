import styled from 'styled-components'

export default styled.div`
  video {
    overflow: hidden;
    width: 100% !important;
    border: 0.1rem solid ${({ theme }) => theme.colors.betterway.secondary[100]};
    border-radius: 1rem;
  }
`
