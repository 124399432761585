import { useState, useRef, useEffect } from 'react'
import { request, requestWithCSRFToken } from '../javascripts/HttpService'

export const usePeriodComment = (id) => {
  const [openCommentBlock, setOpenCommentBlock] = useState(false)
  const [comment, setComment] = useState('')
  const saveCommentTimer = useRef()
  const [updatedBy, setUpdatedBy] = useState()
  const [updatedAt, setUpdatedAt] = useState()

  useEffect(() => {
    request(`/periods/${id}/comment`)
      .then((responseData) => {
        setComment(responseData.comment || '')
        setUpdatedAt(responseData.updatedAt || '')
        setUpdatedBy(responseData.updatedBy || '')
      })
      .catch((error) => console.error(error))
  }, [])

  const toggleCommentBlock = () => {
    const newOpenCommentBlock = !openCommentBlock
    document.body.style.overflow = newOpenCommentBlock ? 'hidden' : 'auto'
    setOpenCommentBlock(newOpenCommentBlock)
  }

  const handleChangeComment = (newComment) => {
    setComment(newComment)
    debounceSaveComment(newComment)
  }

  const debounceSaveComment = (newComment) => {
    clearTimeout(saveCommentTimer.current)
    saveCommentTimer.current = setTimeout(() => updateComment(newComment), 500)
  }

  const updateComment = (newComment) => {
    const body = { comment: newComment }
    requestWithCSRFToken(`/periods/${id}/comment`, { method: 'POST', body: JSON.stringify(body) })
      .then((responseData) => {
        setUpdatedAt(responseData.updatedAt || '')
        setUpdatedBy(responseData.updatedBy || '')
      })
      .catch((error) => console.error(error))
  }

  return {
    comment,
    updatedAt,
    updatedBy,
    toggleCommentBlock,
    handleChangeComment,
    openCommentBlock,
    setOpenCommentBlock,
  }
}
