import React from 'react'
import Button from '../Button/Button'
import { AlertStyled } from './Alert.styled'
import pictoAlertCheck from '../../../../../assets/images/alertCheck.svg'

export const Alert = ({ className, name, text, button }) => (
  <AlertStyled className={className} icon={pictoAlertCheck}>
    <span className="alert-content">
      <span className="alert-content-icon" />
      <span className="alert-content-text">{text}</span>
    </span>
    {
      button && <Button className="alert-button" name={`alert ${name}`} text={button.text} onClick={button.onClick} />
    }
  </AlertStyled>
)
