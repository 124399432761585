let options = {
  fallback: '',
  supported: [],
}

export default {
  type: 'languageDetector',
  init: (_, { fallback, supported }) => { options = { fallback, supported } },
  cacheUserLanguage: () => {},
  detect: () => {
    const parameterLocale = new URLSearchParams(window.location.search).get('locale')
    if (parameterLocale) {
      const locale = parameterLocale.toLowerCase().split('-')[0]
      return options.supported.includes(locale) ? locale : options.fallback
    }

    return options.fallback
  },
}
