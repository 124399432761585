import styled from 'styled-components'

export const StyledBackdrop = styled.div`
  position: fixed;
  top: ${({ theme, top }) => (top === 'belowNavbar' ? theme.sizes.navbarHeight : 0)};
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.primaryBlueLight};
  overflow: hidden;

  &.backdrop-enter {
    opacity: 0;
  }

  &.backdrop-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }

  &.backdrop-exit {
    transform: translateY(0);
    opacity: 1;
  }

  &.backdrop-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }
`
