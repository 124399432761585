import styled from 'styled-components'

export const SendEmailToFilteredPeopleStyled = styled.div`
  .text-area-container {
    margin-bottom: 2rem;

    .email-text-area {
      width: 50rem;
      height: 14rem;
      margin-bottom: 0;
      padding: 1rem;
      border: 1px solid ${({ theme }) => theme.colors.betterway.primary[50]};
      border-radius: 1.25rem;
      background-color: ${({ theme }) => theme.colors.betterway.white};
      color: ${({ theme }) => theme.colors.betterway.primary[50]};
      outline: none;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      font-size: 1.4rem;
      font-family: "Apercu-Regular", "Apercu", sans-serif;
      line-height: normal;
      letter-spacing: normal;
      resize: none;
      transition: border 0.1s ease-in-out, color 0.1s ease-in-out;

      &:hover,
      &:focus-within {
        border-color: ${({ theme }) => theme.colors.primaryBlue};
        color: ${({ theme }) => theme.colors.primaryBlue};
        box-shadow: none;
      }
    }
  }

  .button-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-self: flex-end;
    justify-content: flex-end;
    margin: 1rem 0;

    .send-mail {
      align-items: center;
      width: 17rem;
      height: 4rem;
      border-radius: 3.125rem;

      button {
        justify-content: center;
        width: 100%;
        height: 4rem;
        border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
        background-color: ${({ theme }) => theme.colors.betterway.primary[100]};

        &:hover {
          border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
          background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
        }

        &:disabled {
          border-color: ${({ theme }) => theme.colors.betterway.primary[50]};
          background-color: ${({ theme }) => theme.colors.betterway.primary[50]};

          &:hover {
            border-color: ${({ theme }) => theme.colors.betterway.primary[50]};
            background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
          }
        }

        span {
          color: ${({ theme }) => theme.colors.betterway.white};
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          font-size: 1.4rem;
          font-family: "Apercu-Bold", "Apercu-Regular", "Apercu", sans-serif;
          line-height: 1.44;
          letter-spacing: 0.063rem;
          text-align: center;
        }

        div {
          &.picto {
            margin-left: 0.5rem;
            background-color: ${({ theme }) => theme.colors.betterway.white};
          }
        }
      }
    }
  }
`
