import React from 'react'
import Popup from '../Popup/Popup'
import PopupFormStyled from './PopupForm.styled'

export default function PopupForm({
  className, name, title, textCancel, textValidate, trigger, content, onValidate,
}) {
  return (
    <Popup
      className={className}
      name={name}
      title={title}
      trigger={trigger}
      content={({ close }) => (
        <PopupFormStyled>
          {
            content({ close })
          }
          <div className="separator" />
          <div className="actions">
            <button
              className="cancel"
              type="button"
              aria-label={`${name} popup form cancel`}
              onClick={() => close()}
            >
              {textCancel}
            </button>
            <button
              className="validate"
              type="button"
              aria-label={`${name} popup form validate`}
              onClick={() => {
                close()
                onValidate()
              }}
            >
              {textValidate}
            </button>
          </div>
        </PopupFormStyled>
      )}
    />
  )
}
