import styled from 'styled-components'

export const LockPeriodsStyled = styled.div`
  .button-container {
    margin-bottom: 0.45rem;

    .unlock-periods {
      width: 236px;
      border-radius: 3.125rem;
      height: 3.8rem;

      button {
        gap: 0.8rem;
        border: solid 1px ${({ theme }) => theme.colors.betterway.secondary[100]};
        background-color: ${({ theme }) => theme.colors.betterway.secondary[20]};
        height: 4rem;
        color: ${({ theme }) => theme.colors.primaryBlue};

        span {
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          font-size: 1.4rem;
          font-family: "Apercu-Bold", "Apercu-Regular", "Apercu", sans-serif;
          line-height: 1.44;
          letter-spacing: 0.063rem;
          text-align: center;
        }
      }
    }

    .lock-periods {
      width: 236px;
      border-radius: 3.125rem;
      height: 3.8rem;

      button {
        gap: 0.8rem;
        height: 4rem;
        border: solid 1px ${({ theme }) => theme.colors.primaryBlue};
        background-color: ${({ theme }) => theme.colors.white};
        justify-content: flex;
        color: ${({ theme }) => theme.colors.primaryBlue};
        padding-left: 3rem;

        span {
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          font-size: 1.4rem;
          font-family: "Apercu-Bold", "Apercu-Regular", "Apercu", sans-serif;
          line-height: 1.44;
          letter-spacing: 0.063rem;
          text-align: center;
        }

        &:hover {
          background-color: ${({ theme }) => theme.colors.primaryBlue};
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
`
