import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTimesheetStateValue } from '../context/Timesheet/TimesheetStateContext'
import { useTimeinputsContextValue } from '../context/Timesheet/TimeinputsContext'
import { useActivityRateValue } from '../context/Timesheet/ActivityRateContext'
import { usePeriodStateValue } from '../context/Timesheet/PeriodStateContext'
import { useLocationValue } from '../context/Timesheet/LocationContext'
import { useTimesheetErrors } from '../context/Timesheet/TimesheetErrorsContext'
import { useHttpRequestsInSequence } from './httpRequest'

const useInputsHandler = (activityId, activityType, consumedDaysQuantity) => {
  const { i18n } = useTranslation()
  const {
    owner,
    timesheet,
    periods,
    daysInMonth,
    holidayAndWeekendDays,
  } = useTimesheetStateValue()
  const {
    timeinputs,
    updateTimeinputs,
    nbHoursInDay,
  } = useTimeinputsContextValue()
  const {
    setYearlyActivityRate,
    setMonthlyActivityRate,
  } = useActivityRateValue()
  const {
    setFirstPeriodFillRate,
    setSecondPeriodFillRate,
  } = usePeriodStateValue()
  const {
    locationPerDay,
  } = useLocationValue()
  const {
    setPeriodErrors,
    setTimesheetErrors,
    errorTypeForDay,
  } = useTimesheetErrors()
  const {
    sendRequestWithCSRFToken,
    sendRequestAfterAllPendingRequests,
  } = useHttpRequestsInSequence()

  const [activityInputs, setActivityInputs] = useState(timeinputs[activityId] || '')
  const [updateTimeinputsTimer, setUpdateTimeinputsTimer] = useState(null)
  const [timesheetCheckTimer, setTimesheetCheckTimer] = useState(null)
  const [firstPeriodCheckTimer, setFirstPeriodCheckTimer] = useState(null)
  const [secondPeriodCheckTimer, setSecondPeriodCheckTimer] = useState(null)
  const [bothPeriodCheckTimer, setBotheriodCheckTimer] = useState(null)
  const [consumedDays, setConsumedDays] = useState(consumedDaysQuantity)

  const numberOfDays = daysInMonth.length
  const paidLeaveActivityId = 2140309429
  const rttActivityId = 2140298843
  const totalActivityInputsInDays = Object.keys(activityInputs)
    .map((input) => parseFloat(activityInputs[input].time_in_days))
    .reduce((a, b) => a + b, 0)

  const totalActivityInputsInHours = Object.keys(activityInputs)
    .map((input) => parseFloat(activityInputs[input].time_in_hours))
    .reduce((a, b) => a + b, 0)

  const inputFillingType = (day, previousDay, nextDay) => {
    if (!activityInputs) return ''
    const inputDays = Object.keys(activityInputs)
    if (!inputDays.includes(day)) return ''
    if (inputDays.includes(day) && inputDays.includes(previousDay) && inputDays.includes(nextDay)) return 'fill between'
    if (inputDays.includes(day) && inputDays.includes(previousDay)) return 'fill last'
    if (inputDays.includes(day) && inputDays.includes(nextDay)) return 'fill first'
    return 'fill'
  }

  const isHolidayOrFilled = (day, index) => {
    const isHoliday = holidayAndWeekendDays.includes(day) ? 'holiday' : null
    const isFilled = inputFillingType(day, daysInMonth[index - 1], daysInMonth[index + 1])
    return [isHoliday, isFilled].join(' ')
  }

  const fetchConsumedDays = () => {
    if (activityType !== 'BillableActivity') return
    const url = `/activities/${activityId}/consumed_days`
    sendRequestAfterAllPendingRequests(url)
      .then((dataJson) => (
        setConsumedDays(dataJson.consumed_days)
      ))
      /* eslint-disable-next-line no-console */
      .catch((error) => console.error(error))
  }

  const postTimeInput = (newActivityInputs, day) => {
    const url = '/timesheet/day_input.json'
    const body = {
      time_input: {
        day,
        activity_id: activityId,
        person_id: owner.id,
        time_in_hours: newActivityInputs[day].time_in_hours,
        time_in_days: newActivityInputs[day].time_in_days,
        auto_generated: newActivityInputs[day].auto_generated,
      },
      location: {
        country_code: locationPerDay[day].country_code,
        location_1_code: locationPerDay[day].location_1_code,
        location_2_code: locationPerDay[day].location_2_code,
      },
    }

    sendRequestWithCSRFToken(url, 'POST', JSON.stringify(body))
    /* eslint-disable-next-line no-console */
      .catch((error) => console.log(error))
  }

  const deleteTimeInput = (day) => {
    const url = '/timesheet/day_input/delete.json'
    const body = {
      time_input: {
        day,
        activity_id: activityId,
        person_id: owner.id,
      },
    }

    sendRequestWithCSRFToken(url, 'DELETE', JSON.stringify(body))
    /* eslint-disable-next-line no-console */
      .catch((error) => console.log(error))
  }

  const setNewActivityAndTimeInputs = (newActivityInputs, newTimeinputsState) => {
    clearTimeout(updateTimeinputsTimer)
    setActivityInputs(newActivityInputs)
    const newTimeinputs = newTimeinputsState
    newTimeinputs[activityId] = newActivityInputs
    setUpdateTimeinputsTimer(setTimeout(() => {
      updateTimeinputs(newTimeinputs)
    }, 20))
  }

  const checkTimesheetRequest = () => {
    const url = `/timesheet/${timesheet.id}/check`
    const body = {
      locale: i18n.language,
    }

    sendRequestAfterAllPendingRequests(url, 'POST', JSON.stringify(body))
      .then((responseData) => setTimesheetErrors(responseData))
      /* eslint-disable-next-line no-console */
      .catch((error) => console.log(error))
  }

  const fetchActivityRate = () => {
    const date = new Date(timesheet.start_date)

    sendRequestAfterAllPendingRequests(`/activity_rate/monthly_and_yearly_rate/${owner.id}/${date.getFullYear()}/${date.getMonth() + 1}`)
      .then((responseData) => {
        setYearlyActivityRate(responseData.fiscal_year_rate)
        setMonthlyActivityRate(responseData.monthly_rate)
      })
      /* eslint-disable-next-line no-console */
      .catch((error) => console.log(error))
  }
  const debounceCheckTimesheet = () => {
    clearTimeout(timesheetCheckTimer)

    setTimesheetCheckTimer(setTimeout(() => {
      checkTimesheetRequest()
      fetchActivityRate()
    }, 500))
  }

  const checkFirstPeriodRequest = () => {
    const firstPeriod = periods[0]
    const url = `/periods/${firstPeriod.id}/check`

    const body = {
      locale: i18n.language,
    }

    sendRequestAfterAllPendingRequests(url, 'POST', JSON.stringify(body))
      .then((responseData) => {
        setFirstPeriodFillRate(responseData.fill_rate)
        setPeriodErrors((prevState) => ({ ...prevState, firstPeriod: responseData }))
      })
      /* eslint-disable-next-line no-console */
      .catch((error) => console.log(error))
  }

  const checkSecondPeriodRequest = () => {
    const secondPeriod = periods[1]
    const url = `/periods/${secondPeriod.id}/check`

    const body = {
      locale: i18n.language,
    }

    sendRequestAfterAllPendingRequests(url, 'POST', JSON.stringify(body))
      .then((responseData) => {
        setSecondPeriodFillRate(responseData.fill_rate)
        setPeriodErrors((prevState) => ({ ...prevState, secondPeriod: responseData }))
      })
      /* eslint-disable-next-line no-console */
      .catch((error) => console.log(error))
  }

  const debounceCheckPeriods = (day) => {
    const firstPeriod = periods[0]
    if (parseFloat(activityId) === paidLeaveActivityId || parseFloat(activityId) === rttActivityId) {
      clearTimeout(bothPeriodCheckTimer)
      setBotheriodCheckTimer(setTimeout(() => {
        checkFirstPeriodRequest()
        checkSecondPeriodRequest()
      }, 100))
    } else if (day <= firstPeriod.end_date) {
      clearTimeout(firstPeriodCheckTimer)
      setFirstPeriodCheckTimer(setTimeout(() => {
        checkFirstPeriodRequest()
      }, 100))
    } else {
      clearTimeout(secondPeriodCheckTimer)

      setSecondPeriodCheckTimer(setTimeout(() => {
        checkSecondPeriodRequest()
      }, 100))
    }
  }
  const updateActivityInputs = (event) => {
    const newActivityInputs = { ...activityInputs }
    const newTimeinputsState = { ...timeinputs }
    const dayOfUpdatedInput = event.target.dataset.day
    if (event.target.value === '') {
      delete newActivityInputs[dayOfUpdatedInput]
      deleteTimeInput(dayOfUpdatedInput)
      debounceCheckTimesheet()
      debounceCheckPeriods(dayOfUpdatedInput)
      fetchConsumedDays(event)
      return setNewActivityAndTimeInputs(newActivityInputs, newTimeinputsState)
    }

    newActivityInputs[dayOfUpdatedInput] = {
      time_in_days: event.target.value / nbHoursInDay,
      time_in_hours: event.target.value,
      auto_generated: false,
    }
    postTimeInput(newActivityInputs, dayOfUpdatedInput)
    debounceCheckTimesheet()
    debounceCheckPeriods(dayOfUpdatedInput)
    fetchConsumedDays(event)
    return setNewActivityAndTimeInputs(newActivityInputs, newTimeinputsState)
  }

  return {
    activityInputs,
    daysInMonth,
    holidayAndWeekendDays,
    isHolidayOrFilled,
    numberOfDays,
    totalActivityInputsInDays,
    totalActivityInputsInHours,
    updateActivityInputs,
    errorTypeForDay,
    consumedDays,
  }
}

export default useInputsHandler
