import styled from 'styled-components'

export const ProjectActivitiesTimeSpentStyled = styled.div`
  &.octo {
    .activities-time-spent-header {
      h2 {
        font-weight: 600;
        font-size: 2.4rem;
      }

      .time-spent-date-picker-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 2.4rem;

        .time-spent-date-picker {
          max-width: 30rem;
        }

        .time-inputs {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
`
