import styled from 'styled-components'

export const ProjectPublishersInfoStyled = styled.div`
  >ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;

    >li {
      width: 20rem;
    }
  }
`
