import styled from 'styled-components'

export const fontFamilyByType = (type, theme) => {
  switch (type) {
    case 'navLinkLight':
      return theme.fonts.family.light
    case 'navLinkMedium':
    case 'bottomLink':
      return theme.fonts.family.medium
    case 'navLink':
      return theme.fonts.family.bold
    default:
      theme.fonts.family.bold
  }
}

export const StyledNavLink = styled.a`
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  border-bottom: ${({ theme }) => theme.colors.primaryGreyLight} solid .1px;
  outline: none;
  transition: background-color 0.6s ease;
  display: block;
  width: 100%;
  line-height: 3.6rem;
  text-decoration: none;
  color: ${({ type, theme }) => (type == 'bottomLink' ? theme.colors.grey : theme.colors.primaryDark)};
  font-family: ${({ type, theme }) => fontFamilyByType(type, theme)}, sans-serif;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fonts.size.normal};
  text-align: left;
  padding-left: .8rem;

  &:hover {
    color: ${({ type, theme }) => (type == 'bottomLink' ? theme.colors.grey : theme.colors.primaryDark)};
    text-decoration: none;
    background-color: ${({ theme }) => theme.colors.paleBlue2};
  }
`
