/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledHPIQuestion } from './HPIQuestion.styled'
import { requestWithCSRFToken } from '../../../../../javascripts/HttpService'

import Emoji1 from '../../../../../../assets/images/Emoji-1.svg'
import Emoji2 from '../../../../../../assets/images/Emoji-2.svg'
import Emoji3 from '../../../../../../assets/images/Emoji-3.svg'
import Emoji4 from '../../../../../../assets/images/Emoji-4.svg'
import Emoji0 from '../../../../../../assets/images/Emoji-0.svg'

const emotions = [
  { emoji: Emoji0, feeling: 'noAnswer' },
  { emoji: Emoji1, feeling: 'bad' },
  { emoji: Emoji2, feeling: 'average' },
  { emoji: Emoji3, feeling: 'good' },
  { emoji: Emoji4, feeling: 'great' },
]

const HPIQuestion = ({
  happinessIndicator,
  allowedValues,
  even,
}) => {
  const updateHPIValue = (value) => {
    const url = '/happiness_indicators'
    const body = {
      billable_project_id: happinessIndicator?.billable_project_id,
      activity_id: happinessIndicator?.activity_id,
      timesheet_id: happinessIndicator?.timesheet_id,
      value,
    }
    requestWithCSRFToken(url, { method: 'POST', body: JSON.stringify(body) })
  }

  const id = happinessIndicator?.billable_project_id || happinessIndicator?.activity_id
  return (
    <StyledHPIQuestion even={even}>
      <p>{happinessIndicator?.label}</p>
      {
        allowedValues.filter((value) => value).map((allowedValue) => (
          <EmojiButton
            key={allowedValue}
            allowedValue={allowedValue}
            id={`hpi-project-${id}-${allowedValue}`}
            name={`hpi-project-${id}`}
            defaultChecked={allowedValue === happinessIndicator?.value}
            emotion={emotions[allowedValue]}
            click={() => updateHPIValue(allowedValue)}
          />
        ))
      }
      <EmojiButton
        allowedValue={undefined}
        id={`hpi-project-${id}-0`}
        name={`hpi-project-${id}`}
        defaultChecked={!happinessIndicator?.value}
        emotion={emotions[0]}
        click={() => updateHPIValue(undefined)}
      />
    </StyledHPIQuestion>
  )
}

export default HPIQuestion

const EmojiButton = ({
  allowedValue,
  id,
  name,
  defaultChecked,
  emotion,
  click,
}) => {
  const { t } = useTranslation()

  return (
    <div className="form-radio">
      <input
        type="radio"
        value={allowedValue}
        id={id}
        name={name}
        defaultChecked={defaultChecked}
        onClick={click}
      />
      <label htmlFor={id}>
        <img src={emotion.emoji} alt="emoji" />
      </label>
      <span>{t(`periodValidationModal.happinessIndicator.legends.${emotion.feeling}`)}</span>
    </div>
  )
}
