import styled from 'styled-components'

export default styled.div`
  color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 29rem;
  z-index: 2000;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.colors.shadow};
  text-align: left;
  cursor: default;
  padding: 2rem;

  h5 {
    color: ${({ theme }) => theme.colors.primaryGrey};
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 16px;
    margin-bottom: 1rem;
  }

  .input {
    margin: 0.5rem 0;
    width: 100%;
  }

  button {
    margin-top: 1rem;
  }
`
