import styled from 'styled-components'
import { Section } from '@produits-internes-oss/design-system-components'

export const ProjectQualityInfoStyled = styled(Section)`
width: 50%;
height: 12.1rem;

.quality-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  font-weight: 600;

  .text-info {
    display: flex;

    .link-info {
      margin-left: 0.8rem;
    }
  }
}

.info-content {
  display: flex;
  align-items: center;

  img {
    width: 1.8rem;
  }

  .info-tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    border-radius: 0.3rem;

    .picto {
      content: '';
      display: inline-block;
      width: 2.4rem;
      height: 2.4rem;
      margin: 0.8rem;
      background-image: ${(props) => `url(${props.picto}) `};
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .info-value {
      display: flex;
      flex-direction: row;
      margin-right: 0.8rem;
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      font-size: 1.6rem;
    }
  }

  .link-info {
    display: flex;
    margin-left: 1.6rem;
    color: ${({ theme }) => theme.colors.betterway.primary[100]};
    font-weight: normal;
    font-size: 1.6rem;
    text-decoration: underline;
  }
}

.info-sensitive {
  border: 0.1rem solid ${({ theme }) => theme.colors.betterway.red[100]};
  background-color: ${({ theme }) => theme.colors.betterway.red[5]};
}

.not-info-sensitive {
  border: 0.1rem solid ${({ theme }) => theme.colors.betterway.green[100]};
  background-color: ${({ theme }) => theme.colors.betterway.green[5]};
}
`
