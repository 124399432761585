import styled from 'styled-components'

export default styled.div`
  width: 100%;

  >.title {
    margin-bottom: 0.3rem;
    color: ${({ theme }) => theme.colors.betterway.primary[70]};
    font-weight: 400;
    font-style: normal;
    font-size: 1.7rem;
  }

  >.area {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: stretch;
    box-sizing: border-box;
    width: 100%;
    height: 14rem;
    padding: 1rem;
    border: 1px solid ${({ theme }) => theme.colors.betterway.primary[50]};
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.betterway.white};
    transition: border-color 0.1s ease-in-out;

    > textarea {
      flex-grow: 1;
      box-sizing: border-box;
      width: 100%;
      margin: 0;
      padding: 0 0.6rem;
      border: none;
      color: ${({ theme }) => theme.colors.betterway.primary[50]};
      font-weight: 400;
      font-variant: normal;
      font-size: 1.7rem;
      resize: none;
      transition: color 0.1s ease-in-out;
    }

    >.error {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;

      >.error-icon {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0.4rem 0 0;
        background-color: ${({ theme }) => theme.colors.betterway.red[100]};
        mask-image: ${(props) => `url(${props.pictoWarning}) `};
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-clip: border-box;
      }

      >.error-text {
        color: ${({ theme }) => theme.colors.betterway.red[100]};
        font-weight: 400;
        font-style: normal;
        font-size: 1.4rem;
      }
    }

    &:focus-within {
      border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};

      > textarea {
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
      }
    }
  }

  &.error {
    >.area {
      border-color: ${({ theme }) => theme.colors.betterway.red[100]};
    }
  }

`
