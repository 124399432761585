import React, { useState, useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import { StyledActivityRatePopin } from './ActivityRatePopin.styled'
import ProgressBar from '../../../../UI/ProgressBar/ProgressBar'
import Backdrop from '../../../../UI/Backdrop/Backdrop'

import { rateKind } from '../../../../../context/Timesheet/ActivityRateContext'
import { useFetchData } from '../../../../../fetchOverviewApi'

const ActivityRatePopin = ({
  open,
  close,
  initialYearlyActivityRate,
  ownerId,
}) => {
  const { t } = useTranslation()
  const [loading, hasError, data, handleFetch] = useFetchData(true)
  const [yearlyActivityRate, setYearlyActivityRate] = useState(0)
  const [referenceYearlyRate, setReferenceYearlyRate] = useState(0)
  const [strategicInternalRate, setStrategicInternalRate] = useState(0)
  const [presalesRate, setPresalesRate] = useState(0)
  const [prospectionRate, setProspectionRate] = useState(0)
  const isMounted = useRef(true)
  const [fiscalYear, setFiscalYear] = useState('')
  const getReferenceAndAverageRate = () => {
    const url = `/activity_rate/person_rates/${ownerId}`
    handleFetch(isMounted, url)
  }

  useEffect(() => {
    if (open) {
      getReferenceAndAverageRate()
    }
  }, [open])

  useEffect(() => {
    if (!hasError) {
      setReferenceYearlyRate(data?.rate_reference)
      setStrategicInternalRate(data?.strategic_internal_rate)
      setPresalesRate(data?.presales_rate)
      setProspectionRate(data?.prospection_rate)
      setYearlyActivityRate(initialYearlyActivityRate)
      const formattedFiscalYear = data?.end_fiscal_year && data.end_fiscal_year.length === 4 ? data.end_fiscal_year.slice(-2) : ''
      setFiscalYear(formattedFiscalYear)
    }
  }, [hasError, data])

  return (
    <>
      <Backdrop open={open} close={close} />
      <CSSTransition
        in={open}
        mountOnEnter
        unmountOnExit
        timeout={0}
      >
        <StyledActivityRatePopin role="dialog" aria-label="activity-rate-popin">
          <h3>
            {t('rate.summary')}
            {fiscalYear}
          </h3>
          <div className="rate-bar">
            <p>{t('rate.referenceRate')}</p>
            <ProgressBar
              rate={Math.round(yearlyActivityRate * 100) || 0}
              referenceRate={Math.round(referenceYearlyRate * 100) || 0}
              size="large"
              kind={rateKind.SUMMARY}
              loading={loading}
            />
          </div>
          <div className="rate-bar">
            <p>{t('rate.presalesRate')}</p>
            <ProgressBar
              rate={Math.round(presalesRate * 100) || 0}
              size="large"
              kind={rateKind.PRESALES}
              loading={loading}
            />
          </div>
          <div className="rate-bar">
            <p>{t('rate.internalContributionRate')}</p>
            <ProgressBar
              rate={Math.round(strategicInternalRate * 100) || 0}
              size="large"
              kind={rateKind.INTERNAL}
              loading={loading}
            />
          </div>
          <div className="rate-bar">
            <p>{t('rate.prospectionRate')}</p>
            <ProgressBar
              rate={Math.round(prospectionRate * 100) || 0}
              size="large"
              kind={rateKind.PROSPECTION}
              loading={loading}
            />
          </div>
        </StyledActivityRatePopin>
      </CSSTransition>
    </>
  )
}

export default ActivityRatePopin
