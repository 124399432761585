import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from '@produits-internes-oss/design-system-components'
import { ProjectQuality } from '../ProjectQuality/ProjectQuality'
import { ProjectQualityInfo } from '../ProjectQualityInfo/ProjectQualityInfo'
import { useProjectQualityContext } from '../../context/ProjectQuality/ProjectQualityContext'
import pictoSensitiveData from '../../../assets/images/PictoSensitiveData.svg'

export const ProjectQualityTab = () => {
  const { t } = useTranslation()
  const { project } = useProjectQualityContext()
  const openSensitiveData = { text: t('open'), arialabel: 'open the sensitive data dropdown' }
  const closeSensitiveData = { text: t('close'), arialabel: 'close the sensitive data dropdown' }

  if (project === null) {
    return <div>...</div>
  }

  return (
    <Dropdown className="quality" title="Données sensibles" localization={{ open: openSensitiveData, close: closeSensitiveData }} picto={pictoSensitiveData} withStartupOpen>
      <div className="quality-infos">
        <ProjectQualityInfo title="Estimation du risque opérationnel" infoType="risky_countries" />
        <ProjectQualityInfo title="Traitement de données personnelles" infoType="personal_data" />
      </div>
      <ProjectQuality />
    </Dropdown>
  )
}
