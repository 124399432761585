import styled from 'styled-components/macro'

export default styled.table`
  border-collapse: separate;
  border-spacing: 0;
  position: relative;
  width: 100%;
  margin-bottom: 2rem;

  .firstCol {
    text-align: left;
    padding-left: 10px;
    border-left: ${({ theme }) => `0.5px solid ${theme.colors.primaryDark}`};
  }

  .no-wrap {
    white-space: nowrap;
  }

  .empty {
    background: ${({ theme }) => theme.colors.secondaryLight};
  }

  thead {
    color: ${({ theme }) => theme.colors.white};

    th {
      position: sticky;
      top: -2rem;
      text-transform: uppercase;
      padding: 0.5em;
      background: ${({ theme }) => theme.colors.primaryDark};

      .small {
        font-size: 10px;
        font-weight: normal;
      }
    }

    tr:first-child {
      th {
        border-right: ${({ theme }) => `0.5px solid ${theme.colors.white}`};
      }
      th:first-child {
        border-radius: 5px 0 0 0;
        width: 400px;
        text-align: left;
        padding-left: 10px;
      }
      th:last-child {
        border-radius: 0 5px 0 0;
        border-right: ${({ theme }) => `0.5px solid ${theme.colors.primaryDark}`};
      }
    }

    tr:last-child {
      .border-right {
        border-right: ${({ theme }) => `0.5px solid ${theme.colors.white}`};
      }

      th {
        top: 1.3rem;
      }
    }
  }

  tr {
    :hover {
      background: ${({ theme }) => theme.colors.primaryLight};
    }
  }
`
