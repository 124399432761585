import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table, Section, Modal, Button, InputSelect } from '@produits-internes-oss/design-system-components'
import { AvailabilitiesStyled } from './Availabilities.styled'
import { useStaffingContext } from '../../context/Staffing/StaffingContext'
import Loader from '../UI/Loader/Loader'

export const Availabilities = () => {
  const { t } = useTranslation()
  const {
    dateRanges,
    availabilitiesMainRoles,
    availabilitiesResult,
    setSelectedMainRoles,
  } = useStaffingContext()

  if (!availabilitiesResult || !dateRanges) {
    return <Loader />
  }

  const months = [dateRanges.currentMonth.name, dateRanges.secondMonth.name, dateRanges.thirdMonth.name]

  const personFullName = (personData) => {
    const firstName = personData['first_name']
    const lastName = `${personData['last_name'].charAt(0).toUpperCase() + personData['last_name'].slice(1).toLowerCase()}`
    const nickname = personData['nickname'].toUpperCase()

    return `${firstName} ${lastName} (${nickname})`
  }

  const columns = [
    {
      name: 'league',
      title: t('staffingTabs.availabilities.tableHeaders.league'),
      onRender: ({ item }) => t(`staffingTabs.availabilities.league.${item['person']['league']['name'].toLowerCase()}`),
      onSort: ((a, b) => {
        const leagueA = t(`staffingTabs.availabilities.league.${a['person']['league']['name'].toLowerCase()}`)
        const leagueB = t(`staffingTabs.availabilities.league.${b['person']['league']['name'].toLowerCase()}`)
        return leagueA.localeCompare(leagueB)
      }),
    },
    {
      name: 'full_name',
      title: t('staffingTabs.availabilities.tableHeaders.fullName'),
      onRender: ({ item }) => (
        <a
          className="person-link"
          target="blank"
          rel="noreferrer"
          aria-label={`link to ${personFullName(item['person'])}`}
          title={personFullName(item['person'])}
          href={`/timesheet/${item['person']['nickname']}`}
        >
          {personFullName(item['person'])}
        </a>
      ),
      onSort: ((a, b) => personFullName(a['person']).localeCompare(personFullName(b['person']))),
    },
    {
      name: 'main_role',
      title: t('staffingTabs.availabilities.tableHeaders.mainRole'),
      onRender: ({ item }) => (item['person']['main_role'] ? item['person']['main_role']['name'] : '-'),
      onSort: ((a, b) => {
        const roleA = a['person']['main_role']?.name ? a['person']['main_role']['name'] : '-'
        const roleB = b['person']['main_role']?.name ? b['person']['main_role']['name'] : '-'
        return roleA.localeCompare(roleB)
      }),
    },
    {
      name: 'job_name',
      title: t('staffingTabs.availabilities.tableHeaders.jobName'),
      onRender: ({ item }) => item['person']['job_name'],
      onSort: (a, b) => a['person']['job_name'].localeCompare(b['person']['job_name']),
    },
    ...(months.map((month) => ({
      name: `${month}_month`,
      title: t(`staffingTabs.availabilities.tableHeaders.months.${month}`),
      onRender: ({ item }) => (
        <span className={item['availability_rate'][month] >= 75 ? 'highlight-value' : ''}>
          {item['availability_rate'][`${month}`]}
          %
        </span>
      ),
      onSort: ((a, b) => parseFloat(a['availability_rate'][month]) - parseFloat(b['availability_rate'][month])),
    }))),
    {
      name: 'in_idf',
      title: t('staffingTabs.availabilities.tableHeaders.inIdf'),
      onRender: ({ item }) => (item['person']['in_idf'] === null ? '-' : t(`staffingTabs.availabilities.inIdf.${item['person']['in_idf']}`)),
      onSort: ((a, b) => t(`staffingTabs.availabilities.inIdf.${a['person']['in_idf']}`).localeCompare(t(`staffingTabs.availabilities.inIdf.${b['person']['in_idf']}`))),
    },
    {
      name: 'matchs',
      title: 'Matchs',
      onRender: ({ item }) => {
        if (!item.opportunities_matching) {
          return 'Loading...'
        }
        return (
          <Modal
            className="availability-modale"
            title={`${item.opportunities_matching.length > 1
              ? `${item.opportunities_matching.length} ${t('staffingTabs.availabilities.modal.title.plural')}`
              : `${item.opportunities_matching.length} ${t('staffingTabs.availabilities.modal.title.singular')}`}`}
            trigger={({ open }) => (
              <Button
                kind="primary"
                skin="dark"
                name="open_matches"
                text={`${item.opportunities_matching.length} ${item.opportunities_matching.length > 1 ? 'matchs' : 'match'}`}
                onClick={() => {
                  open()
                }}
              />
            )}
          >
            {item.opportunities_matching && (
              item.opportunities_matching.map((opportunity) => (
                <Section key={`matchingOpportunityDetail-${item.person.id}-${opportunity.activity_id}`}>
                  <div>
                    <span className="data-title">{t('staffingTabs.availabilities.modal.dataTitles.role')}</span>
                    {' '}
                    {opportunity.role_name || '-'}
                  </div>
                  <div>
                    <a
                      className="project-link"
                      target="blank"
                      rel="noreferrer"
                      aria-label={`link to ${opportunity.project_name}`}
                      href={`/projects/billable/${opportunity.project_id}`}
                    >
                      {`${opportunity.customer_name ? (`${opportunity.customer_name} - `) : ''} ${opportunity.project_name}`}
                    </a>
                  </div>
                  <div>
                    <span className="data-title">{t('staffingTabs.availabilities.modal.dataTitles.bizDev')}</span>
                    {' '}
                    {opportunity.project_business_contact_full_name || '-'}
                    {opportunity.project_business_contact_full_name && (
                    <Button
                      kind="primary"
                      skin="dark"
                      highlight
                      className="modal-mattermost-button"
                      text={t('staffingTabs.availabilities.modal.contact')}
                      name={`contact ${opportunity.project_business_contact_full_name} on mattermost`}
                      onClick={() => {
                        window.open(
                          opportunity.project_business_contact_mattermost,
                          '_blank',
                          'noreferrer',
                        )
                        return false
                      }}
                    />
                    )}
                  </div>
                  <div>
                    <span className="data-title">{t('staffingTabs.availabilities.modal.dataTitles.diMi')}</span>
                    {' '}
                    {opportunity.project_mission_director_full_name || '-'}
                    {opportunity.project_mission_director_full_name && (
                    <Button
                      kind="primary"
                      skin="dark"
                      highlight
                      className="modal-mattermost-button"
                      text={t('staffingTabs.availabilities.modal.contact')}
                      name={`contact ${opportunity.project_mission_director_full_name} on mattermost`}
                      onClick={() => {
                        window.open(
                          opportunity.project_mission_director_mattermost,
                          '_blank',
                          'noreferrer',
                        )
                        return false
                      }}
                    />
                    )}
                  </div>
                </Section>
              ))
            )}
          </Modal>
        )
      },
    },
  ]

  return (
    <AvailabilitiesStyled id="availabilities" role="tabpanel" aria-labelledby="availabilities" tabIndex={0}>
      <h2>{t('staffingTabs.availabilities.filterBy')}</h2>
      <div className="filters">
        <InputSelect
          className="filter"
          skin="light"
          name="main role select"
          title={t('staffingTabs.availabilities.filters.title.mainRole')}
          options={availabilitiesMainRoles()}
          localization={{ validation: { text: t('staffingTabs.availabilities.filters.validate'), arialabel: t('staffingTabs.availabilities.filters.validate') } }}
          multiple
          onChange={(value) => {
            setSelectedMainRoles(value)
          }}
        />
      </div>

      <h2>{t('staffingTabs.results')}</h2>
      <div className="table-legend">
        <p>{t('staffingTabs.availabilities.tableLegend.0')}</p>
        <p>{t('staffingTabs.availabilities.tableLegend.1')}</p>
        <p>{t('staffingTabs.availabilities.tableLegend.2')}</p>
      </div>

      <Table
        columns={columns}
        data={availabilitiesResult}
        sortInitial={{ column: `${months[0]}_month`, order: 'desc' }}
      />
    </AvailabilitiesStyled>
  )
}
