import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  padding-right: 10%;

  >.title {
    margin-bottom: 0.3rem;
    color: ${({ theme }) => theme.colors.betterway.primary[70]};
    font-weight: 400;
    font-style: normal;
    font-size: 1.7rem;
  }

  >.enum {
    box-sizing: border-box;
    width: 100%;
    height: 3.2rem;
    padding: 0.2rem;
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.betterway.white};
    font-weight: 500;
    font-variant: normal;
    font-size: 1.6rem;
    line-height: 2rem;
    letter-spacing: -0.08px;

    >.track {
      position: relative;
      z-index: 1;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: stretch;
      height: 100%;

      > button {
        z-index: 1;
        flex: 1;
        padding: 0;
        background: transparent;
        color: ${({ theme }) => theme.colors.betterway.primary[50]};
        transition: color 0.1s ease-in-out;

        &.selected {
          color: ${({ theme }) => theme.colors.betterway.white};
        }
      }

      >.slider {
        position: absolute;
        top: 0;
        bottom: 0;
        border-radius: 0.8rem;
        background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
        transition: left 0.1s ease-out;
      }
    }
  }

  &.disabled {
    >.enum {
      >.track {
        >.slider {
          background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
        }
      }
    }
  }
`
