import React from 'react'

import StyledActivityOverviewTable from './ActivityOverviewTable.styled'
import Header from './ActivityOverviewTableHeader/ActivityOverviewTableHeader'
import SkeletonTableRow from './SkeletonRow/SkeletonRow'
import CompanyRow from './CompanyRow/CompanyRow'
import SubsidiaryRow from './SubsidiaryRow/SubsidiaryRow'

const ActivityOverviewTable = ({ data, loading }) => (
  <StyledActivityOverviewTable>
    <Header />
    <tbody>
      {loading
        ? <SkeletonTableRow rows={3} columns={14} />
        : (
          <CompanyRow data={data}>
            {data.subsidiaries.map((subsidiary) => (
              <SubsidiaryRow
                key={subsidiary.id}
                subsidiaryData={subsidiary}
              />
            ))}
          </CompanyRow>
        )}
    </tbody>
  </StyledActivityOverviewTable>
)

export default ActivityOverviewTable
