import styled from 'styled-components'

export const ProjectContractScreenStyled = styled.div`
display: flex;
flex-direction: column;
gap: 2.4rem;

.contract,
.status-historic {
  table {
    >tbody {
      >tr {
        >td {
          &[aria-label*='mop'] {
            text-align: right;
          }
        }
      }
    }
  }
}
`
