export const clamp = (n, min, max) => {
  if (min < max) {
    return Math.min(Math.max(n, min), max)
  }

  return min
}

export const mod = (a, n) => ((a % n) + n) % n

export const getNaturalIntegers = (n) => {
  if (n <= 0) {
    throw new Error('n must be strictly positive')
  }

  return Array.from({ length: n }, (_, i) => i + 1)
}

export const getRoundedToDecimal = (f, decimal) => {
  const factor = 10 ** decimal
  return Math.round(f * factor) / factor
}
