import React from 'react'
import { ThemeProvider } from 'styled-components/macro'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import { StaffingContextProvider } from '../../context/Staffing/StaffingContext'
import { StaffingScreenStyled } from './StaffingScreen.styled'
import { StaffingTabs } from '../../components/StaffingTabs/StaffingTabs'

export function StaffingScreen() {
  return (
    <StaffingScreenStyled>
      <StaffingContextProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <StaffingTabs />
        </ThemeProvider>
      </StaffingContextProvider>
    </StaffingScreenStyled>
  )
}
