import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { Tooltip } from '../input/_ExportDS/Tooltip/Tooltip'
import { getClass, getNumberWithUnit, parseFloatWithAnySeparator } from '../../helpers/stringHelper'
import { ProjectSalesExpensesListStyled } from './ProjectSalesExpensesList.styled'
import Button from '../input/_ExportDS/ButtonWithStyle/Button'

export const ProjectSalesExpensesList = ({ category, withoutTitle, isAlwaysEditable }) => {
  const [fullListItem, setFullListItem] = useState(false)
  const { t } = useTranslation()
  const {
    forms,
    projectSalesExpenses,
    projectSnapshot,
    setFormVisible,
    setFormHidden,
    fetchDestroyProjectSalesExpense,
    isProjectPresales,
    presalesTextTranslation,
  } = useProjectSalesContext()

  const isEditable = isAlwaysEditable || isProjectPresales()
  const expensesSource = projectSnapshot && !isEditable ? projectSnapshot['project_expenses'] : projectSalesExpenses
  const expenses = expensesSource.filter((expense) => (category === expense.category))
  const expensesSum = expenses.reduce((sum, projectSalesExpense) => {
    const amount = parseFloatWithAnySeparator(projectSalesExpense['quantity']) * parseFloatWithAnySeparator(projectSalesExpense['unit_price'])
    return sum + amount
  }, 0)

  const typeTag = (turnoverType) => {
    if (turnoverType === 'travel') return 'travel'
    if (turnoverType === 'data_driver') return 'data_driver'
    if (turnoverType === 'subcontractor_expenses') return 'subcontractor_expenses'
    if (turnoverType === 'misc') return 'misc'

    return ''
  }

  const typeTagCss = (turnoverType) => {
    if (turnoverType === 'travel') return 'travel'
    if (turnoverType === 'data_driver') return 'data-driver'
    if (turnoverType === 'subcontractor_expenses') return 'subcontractor-expenses'
    if (turnoverType === 'misc') return 'misc'

    return ''
  }

  const expandListButton = () => (
    <button type="button" onClick={() => setFullListItem(true)} aria-label="view all">
      <span>{t(`project.expenses.expand_list`)}</span>
      <span className="btn-expand-list" />
    </button>
  )

  const shrinkListButton = () => (
    <button type="button" onClick={() => setFullListItem(false)} aria-label="shrink">
      <span><span>{t(`project.expenses.shrink_list`)}</span></span>
      <span className="btn-close-list" />
    </button>
  )

  const displayLimit = () => (
    fullListItem ? expenses.length : 5
  )

  return (
    <ProjectSalesExpensesListStyled className={withoutTitle && 'notitle'} title={{ text: t(`project.expenses.${category}.title`), level: 2 }}>
      <header>
        <div>
          {
            expenses.length > 0
              ? (
                <>
                  <div className="count">
                    <em>{t(`project.expenses.${category}.count.some`)}</em>
                    <em className="big">{getNumberWithUnit(expensesSum, `€`)}</em>
                  </div>
                </>
              )
              : (
                <>
                  <div className="count">{t(`project.expenses.${category}.count.none.${presalesTextTranslation()}`)}</div>
                  {
                    isEditable && (
                      <div className="help">
                        <em>{t(`project.expenses.${category}.create.help.0`)}</em>
                        <span>{t(`project.expenses.${category}.create.help.1`)}</span>
                      </div>
                    )
                  }
                  {
                    category === 'revenue_additional' && (
                      <div className="example">
                        <em>{t(`project.expenses.example`)}</em>
                        <span>{t(`project.expenses.${category}.create.example`)}</span>
                      </div>
                    )
                  }
                </>
              )
          }
        </div>
        <div>
          {
            isEditable && (
              <Button
                className="create"
                name={t(`project.expenses.${category}.create.arialabel`)}
                text={t(`project.expenses.${category}.create.text`)}
                onClick={() => setFormVisible(category)}
                disabled={forms[category]}
              />
            )
          }
        </div>
      </header>
      {
        expenses.length > 0 && (
          <div className="list">
            {
              expenses.slice(0, displayLimit()).map((expense, key) => (
                <div key={expense.id} className={getClass(`list-item`, key % 2 !== 0 && `odd`)}>
                  {(category === 'chargeable' || category === 'nonchargeable') && (
                  <div className="infos-item">
                    { expense.turnover_type && (
                    <div className="list-item-name">
                      <div className={`tag-expense ${typeTagCss(expense.turnover_type)}`}>
                        {`${t(`project.expenses.turnover_type.options.${typeTag(expense.turnover_type)}`)}`}
                      </div>
                    </div>
                    )}
                    { expense.subcontractor_id && (
                    <div className="name-subcontractor">
                      <em>{`${expense.subcontractor_name}`}</em>
                    </div>
                    )}
                  </div>
                  )}
                  { ((category === 'chargeable' || category === 'nonchargeable') && (
                  <>
                    <div className="unit-price">
                      <div>{`${t(`project.expenses.${category}.unitPrice.text`)} : `}</div>
                      <em>{getNumberWithUnit(parseFloatWithAnySeparator(expense['unit_price']), '€')}</em>
                    </div>
                    <div className="quantity">
                      <div>{`${t(`project.expenses.${category}.quantity.text`)} : `}</div>
                      <em>{expense['quantity']}</em>
                    </div>
                    <div className="total-amount">
                      <div>{`${t(`project.expenses.${category}.totalPrice.text`)} : `}</div>
                      <em>{getNumberWithUnit(parseFloatWithAnySeparator(expense['unit_price']) * parseFloatWithAnySeparator(expense['quantity']), '€')}</em>
                    </div>
                  </>
                  )) || (
                  <div className="value">
                    <span>{`${t(`project.expenses.${category}.amount.text`)} : `}</span>
                    <em>{getNumberWithUnit(parseFloatWithAnySeparator(expense['unit_price']) * parseFloatWithAnySeparator(expense['quantity']), '€')}</em>
                  </div>
                  )}
                  {
                    isEditable && (
                      <div className="list-item-actions">
                        <Tooltip
                          title={t(`project.expenses.${category}.comment.full`)}
                          trigger={({ open }) => <button className="trigger comment" type="button" onClick={() => open()}>{t(`project.expenses.${category}.info`)}</button>}
                          content={() => expense.comment}
                        />
                        <button
                          className="trigger update"
                          type="button"
                          aria-label={t(`project.expenses.${category}.update.arialabel`)}
                          onClick={() => {
                            setFormVisible(category, { id: expense.id })
                          }}
                        >
                          {t(`project.expenses.${category}.update.text`)}
                        </button>
                        <button
                          className="trigger destroy"
                          type="button"
                          aria-label={t(`project.expenses.${category}.destroy.arialabel`)}
                          onClick={async () => {
                            setFormHidden(category)
                            await fetchDestroyProjectSalesExpense({ id: expense.id })
                          }}
                        >
                          {t(`project.expenses.${category}.destroy.text`)}
                        </button>
                      </div>
                    )
                  }
                </div>
              ))
            }
            <div className="full-list-button">
              {
                (expenses.length > 5) && (
                  fullListItem ? shrinkListButton() : expandListButton()
                )
              }
            </div>
          </div>
        )
      }

    </ProjectSalesExpensesListStyled>
  )
}
