import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectContingencyStyled } from './ProjectContingencyHeader.styled'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { getNumberWithUnit, parseFloatWithAnySeparator } from '../../helpers/stringHelper'

export const ProjectContingencyHeader = () => {
  const { t } = useTranslation()
  const { project, presalesTextTranslation } = useProjectSalesContext()
  const hasPercent = project && project.contingency_percent

  return (
    <ProjectContingencyStyled>
      <div className="header-container">
        <p>
          <span className="text-bold">
            {t('project.costs.contingency.header.title.first')}
            {' '}
          </span>
          {t('project.costs.contingency.header.title.second')}
        </p>
        <ul>
          <li>{t('project.costs.contingency.header.body.first')}</li>
          <li>{t('project.costs.contingency.header.body.second')}</li>
        </ul>
        {
          hasPercent
            ? <span className="text-bold">{t('project.costs.contingency.present')}</span>
            : <span>{t(`project.costs.contingency.absent.${presalesTextTranslation()}`)}</span>
        }
        {
          hasPercent && (
          <span className="amount text-bold">
            {`${getNumberWithUnit(parseFloatWithAnySeparator(project.contingency), '€')}`}
            <span className="percent">
              {`(${(project.contingency_percent * 100).toFixed(2)}%)`}
            </span>
          </span>
          )
        }
      </div>
    </ProjectContingencyStyled>
  )
}
