import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectServiceEndOfYearDiscountHeaderStyled } from './ProjectServiceEndOfYearDiscountHeader.styled'
import { getNumberWithUnit, parseFloatWithAnySeparator } from '../../helpers/stringHelper'

export const ProjectServiceEndOfYearDiscountHeader = () => {
  const { t } = useTranslation()
  const { project, presalesTextTranslation } = useProjectSalesContext()
  const hasAmount = project && project.service_end_of_year_discount
  const hasPercent = project && project.service_end_of_year_discount_percent

  return (
    <ProjectServiceEndOfYearDiscountHeaderStyled>
      {
        hasAmount || hasPercent
          ? <span className="text-bold">{t('project.revenue.service_end_of_year.present')}</span>
          : <span>{t(`project.revenue.service_end_of_year.absent.${presalesTextTranslation()}`)}</span>
      }
      {
        hasAmount && (
        <span className="amount text-bold">
          {`${getNumberWithUnit(parseFloatWithAnySeparator(project.service_end_of_year_discount), '€')}`}
          <span className="percent">
            {`(${getNumberWithUnit(parseFloatWithAnySeparator((project.service_end_of_year_discount / project.service) * 100), '%')})`}
          </span>
        </span>
        )
      }
      {
        hasPercent && (
        <span className="amount text-bold">
          {`${getNumberWithUnit(parseFloatWithAnySeparator(project.service_end_of_year_discount_percent * project.service), '€')}`}
          <span className="percent">
            {`(${((project.service_end_of_year_discount_percent * 100) % 1 === 0)
              ? (project.service_end_of_year_discount_percent * 100)
              : (project.service_end_of_year_discount_percent * 100).toFixed(2)}%)`}
          </span>
        </span>
        )
      }
    </ProjectServiceEndOfYearDiscountHeaderStyled>
  )
}
