import React from 'react'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import { SynchronizationContextProvider } from '../../context/Synchronization/SynchronizationContext'
import Synchronization from '../../components/Synchronization/Synchronization'

export default function SynchronizationScreen() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SynchronizationContextProvider>
        <Synchronization />
      </SynchronizationContextProvider>
    </ThemeProvider>
  )
}
