import React, { useRef } from 'react'
import { getClass } from '../../helpers/stringHelper'
import pictoCommentsEmpty from '../../../assets/images/IconsVideMessage.svg'
import pictoCommentsFull from '../../../assets/images/IconsPleinMessage.svg'
import { ProjectTurnoverBreakdownCommentStyled } from './ProjectTurnoverBreakdownComment.styled'

export const ProjectTurnoverBreakdownComment = ({
  comment, commentClicked, onComment,
}) => {
  const buttonComment = useRef()
  return (
    <ProjectTurnoverBreakdownCommentStyled>
      <button
        type="button"
        aria-label="button comment"
        className={getClass('button-comments', buttonComment.current && buttonComment.current === commentClicked && 'button-comments-bold')}
        onClick={() => onComment(comment, buttonComment.current)}
        ref={buttonComment}
      >
        Info
        <img className="img-comment" aria-label="comments cutting month" src={comment && comment.text ? pictoCommentsFull : pictoCommentsEmpty} />
      </button>
    </ProjectTurnoverBreakdownCommentStyled>
  )
}
