import styled from 'styled-components'

export const StyledPeriodValidationModal = styled.div`
  display: flex;
  height: 100%;
  box-sizing: border-box;

  .validation-form {
    width: 70%;
    max-height: 100%;
    margin: 1rem 0;
    padding: 2.2rem 5rem 0 2rem;
    overflow: auto;
  }
`
