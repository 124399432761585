import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getClass } from '../../../helpers/stringHelper'
import { PeriodPicker } from '../PeriodPicker/PeriodPicker'
import { ManagePeriodsTabsStyled } from './ManagePeriodsTabs.styled'
import { useManagerPeriodsContext } from '../../../context/ManagePeriods/ManagePeriodsContext'
import ManagePeriodsTable from '../ManagePeriodsTable/ManagePeriodsTable'
import Lockperiods from '../LockPeriods/LockPeriods'

export default function ManagePeriodsTabs() {
  const { t } = useTranslation()
  const {
    peopleFilteredBySearch,
    fetchGetUnvalidatedPeriods,
    fetchGetPeriodsWithEmptyDays,
    fetchGetPeriodsToReview,
    loading,
    activeTab,
    date,
  } = useManagerPeriodsContext()

  useEffect(() => {
    fetchGetUnvalidatedPeriods(date.year, date.month, date.day)
  }, [])

  const countPeople = (tabName) => ((loading || activeTab !== tabName) ? <p><br /></p> : (
    <p>
      {peopleFilteredBySearch.length}
      {' '}
      {`${t('managePeriods.tabs.profils')}`}
    </p>
  ))

  return (
    <ManagePeriodsTabsStyled>
      <div className="tabs-container">
        <ul className="tab-group">
          <li>
            <button
              className={`tab ${getClass(activeTab === 'unvalidated' && 'active')} ${getClass(loading && activeTab !== 'unvalidated' && 'disabled')}`}
              disabled={`${getClass((loading && activeTab !== 'unvalidated') && 'disabled')}`}
              onClick={() => fetchGetUnvalidatedPeriods(date.year, date.month, date.day)}
              type="button"
              aria-label="unvalidated periods tab"
            >
              <p>{`${t('managePeriods.tabs.unvalidatedPeriods')}`}</p>
              {countPeople('unvalidated')}
            </button>
          </li>
          <li>
            <button
              className={`tab ${getClass(activeTab === 'emptyDays' && 'active')} ${getClass((loading && activeTab !== 'emptyDays') && 'disabled')}`}
              disabled={`${getClass((loading && activeTab !== 'emptyDays') && 'disabled')}`}
              onClick={() => fetchGetPeriodsWithEmptyDays(date.year, date.month, date.day)}
              type="button"
              aria-label="periods with empty days tab"
            >
              <p>{`${t('managePeriods.tabs.emptyDaysPeriods')}`}</p>
              {countPeople('emptyDays')}
            </button>
            <button
              className={`tab ${getClass(activeTab === 'toReview' && 'active')} ${getClass(loading && activeTab !== 'toReview' && 'disabled')}`}
              disabled={`${getClass((loading && activeTab !== 'toReview') && 'disabled')}`}
              onClick={() => fetchGetPeriodsToReview(date.year, date.month, date.day)}
              type="button"
              aria-label="periods to review tab"
            >
              <p>{`${t('managePeriods.tabs.periodsToReview')}`}</p>
              {countPeople('toReview')}
            </button>
          </li>
        </ul>
        <PeriodPicker className="period-picker" />
        <Lockperiods />
      </div>
      <ManagePeriodsTable />
    </ManagePeriodsTabsStyled>
  )
}
