import React from 'react'
import TimesheetErrors from '../TimesheetErrors/TimesheetErrors'
import { useTimesheetErrors } from '../../../../context/Timesheet/TimesheetErrorsContext'

const BlockingErrors = ({ nbDays }) => {
  const {
    periodsHasBlockingErrors,
    periodErrors,
    hasTimesheetErrors,
    timesheetErrors,
  } = useTimesheetErrors()

  return (
    <>
      {
        (periodsHasBlockingErrors || hasTimesheetErrors)
        && (
        <TimesheetErrors
          nbDays={nbDays}
          type="blockingError"
          firstPeriodErrors={[...periodErrors?.firstPeriod?.blocking_messages, ...timesheetErrors?.messages]}
          secondPeriodErrors={[...periodErrors?.secondPeriod?.blocking_messages, ...timesheetErrors?.messages]}
          label="blockingError"
        />
        )
      }
    </>
  )
}

export default BlockingErrors
