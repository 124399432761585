import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react'

import {
  useFetchData,
  buildUrlWithId,
} from '../../../fetchOverviewApi'
import { useStateValue } from '../../../state'

import ActivityOverviewRow from '../ActivityOverviewRow/ActivityOverviewRow'
import SkeletonTableRow from '../SkeletonRow/SkeletonRow'
import LeagueRow from '../LeagueRow/LeagueRow'
import TurnoverRow from '../TurnoverRow/TurnoverRow'

const SubsidiaryRow = ({ subsidiaryData }) => {
  const [open, setOpen] = useState(false)
  const [{ range }] = useStateValue()
  const [loading, hasError, data, handleFetch] = useFetchData(true)
  const isMounted = useRef(true)

  useEffect(() => {
    if (!open || data) return

    const url = buildUrlWithId('subsidiary', subsidiaryData.id, range)
    handleFetch(isMounted, url)
  }, [open, data])

  const renderChildren = useCallback(() => (
    loading ? <SkeletonTableRow rows={3} columns={14} /> : renderRows()
  ), [loading, data])

  const hasExternalTurnover = () => {
    const total = subsidiaryData.external_turnover.reduce((a, b) => ({ turnover: a.turnover + b.turnover })).turnover
    return parseInt(total) > 0
  }

  const renderRows = () => {
    if (!hasError) {
      return (
        <>
          {
            data.leagues.map((league) => (
              <LeagueRow
                key={league.id}
                leagueData={league}
              />
            ))
          }
          {hasExternalTurnover()
            && subsidiaryData.external_turnover.map((turnover) => (
              <TurnoverRow
                key={turnover.turnover}
                currency_code={subsidiaryData.currency_code}
                data={turnover}
              />
            ))}
        </>
      )
    }
  }

  useEffect(() => () => {
    isMounted.current = false
  }, [])

  return (
    <>
      <ActivityOverviewRow
        style="subsidiary"
        open={open}
        data={subsidiaryData}
        onClick={() => setOpen(!open)}
        key={subsidiaryData.id}
      />
      {open && renderChildren()}
    </>
  )
}

export default SubsidiaryRow
