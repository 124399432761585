import React from 'react'
import { StyledNavLink } from './NavLink.styled'

const NavLink = ({ link, title, type }) => (
  <StyledNavLink
    type={type}
    href={link}
    target={title === 'Admin' ? '_blank' : ''}
    rel="noreferrer"
  >
    {title}
  </StyledNavLink>
)

export default NavLink
