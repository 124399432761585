import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSubcontracting } from '../../../context/Subcontracting/SubcontractingContext'
import StyledSubcontractorsTableHeader from './SubcontractorsTableHeader.styled'

const SubcontractorsTableHeader = () => {
  const { t } = useTranslation()
  const {
    filterValues,
    updateFilterValue,
  } = useSubcontracting()

  return (
    <StyledSubcontractorsTableHeader>
      <tr className="subcontractor-categories">
        <td className="white-header" style={{ width: '6rem' }} />
        <td className="white-header" />
        <td className="white-header" style={{ width: '6.5rem' }} />
        <td className="white-header" style={{ width: '5rem' }} />
        <td className="white-header" style={{ width: '6rem' }} />
        <td className="white-header" />
        <th style={{ width: '19rem' }} colSpan={3}>{t('subcontractorsOverview.SubcontractorsTableHeader.subcontractorsForm')}</th>
        <th className="white-header" style={{ width: '12rem' }} colSpan={2}>{t('subcontractorsOverview.SubcontractorsTableHeader.cutting')}</th>
        <th style={{ width: '26rem' }} colSpan={3}>{t('subcontractorsOverview.SubcontractorsTableHeader.billing')}</th>
        <td className="white-header" style={{ width: '8rem' }} />
      </tr>
      <tr className="subcontractor-headers">
        <th>{t('subcontractorsOverview.SubcontractorsTableHeader.month')}</th>
        <th>{t('subcontractorsOverview.SubcontractorsTableHeader.subcontractorName')}</th>
        <th>{t('subcontractorsOverview.SubcontractorsTableHeader.purchaseOrder')}</th>
        <th>{t('subcontractorsOverview.SubcontractorsTableHeader.purchasePrice')}</th>
        <th>{t('subcontractorsOverview.SubcontractorsTableHeader.form')}</th>
        <th>{t('subcontractorsOverview.SubcontractorsTableHeader.project')}</th>
        <th>{t('subcontractorsOverview.SubcontractorsTableHeader.daysSpent')}</th>
        <th>{t('subcontractorsOverview.SubcontractorsTableHeader.expenses')}</th>
        <th>{t('subcontractorsOverview.SubcontractorsTableHeader.hapinessIndicator')}</th>
        <th>{t('subcontractorsOverview.SubcontractorsTableHeader.daysSpent')}</th>
        <th>{t('subcontractorsOverview.SubcontractorsTableHeader.expenses')}</th>
        <th>{t('subcontractorsOverview.SubcontractorsTableHeader.daysSpent')}</th>
        <th>{t('subcontractorsOverview.SubcontractorsTableHeader.expenses')}</th>
        <th>{t('subcontractorsOverview.SubcontractorsTableHeader.purchasePrice')}</th>
        <th>{t('subcontractorsOverview.SubcontractorsTableHeader.actions')}</th>
      </tr>
      <tr className="subcontractor-filters">
        <td />
        <th>
          <input
            aria-label="subcontractor filter"
            value={filterValues.subcontractor}
            onChange={(event) => updateFilterValue('subcontractor', event.target.value)}
          />
        </th>
        <td />
        <td />
        <th>
          <select
            aria-label="subcontracting form filter"
            value={filterValues.form !== null ? filterValues.form : 'none'}
            onChange={(event) => updateFilterValue('form', event.target.value)}
          >
            <option aria-label="subcontracting form filter none" value="none" />
            <option aria-label="subcontracting form filter from" value="form">{t('subcontractorsOverview.subcontractorsTableRow.form')}</option>
            <option aria-label="subcontracting form filter email" value="email">{t('subcontractorsOverview.subcontractorsTableRow.email')}</option>
          </select>
        </th>
        <th>
          <input
            aria-label="subcontracting project filter"
            value={filterValues.project}
            onChange={(event) => updateFilterValue('project', event.target.value)}
          />
        </th>
        <td />
        <td />
        <td />
        <td />
        <td />
        <th>
          <select
            aria-label="subcontracting expense quantity filter"
            value={filterValues.expenseQuantity !== null ? filterValues.expenseQuantity : 'none'}
            onChange={(event) => updateFilterValue('expenseQuantity', event.target.value)}
          >
            <option aria-label="subcontracting working days filter none" value="none" />
            <option aria-label="subcontracting working days filter not_empty" value="not-empty">{t('subcontractorsOverview.subcontractorsTableRow.not_empty')}</option>
            <option aria-label="subcontracting working days filter empty" value="empty">{t('subcontractorsOverview.subcontractorsTableRow.empty')}</option>
          </select>
        </th>
        <th>
          <select
            aria-label="subcontracting misc expense quantity filter"
            value={filterValues.miscExpenseQuantity !== null ? filterValues.miscExpenseQuantity : 'none'}
            onChange={(event) => updateFilterValue('miscExpenseQuantity', event.target.value)}
          >
            <option aria-label="subcontracting ndf filter none" value="none" />
            <option aria-label="subcontracting ndf filter not_empty" value="not-empty">{t('subcontractorsOverview.subcontractorsTableRow.not_empty')}</option>
            <option aria-label="subcontracting ndf filter empty" value="empty">{t('subcontractorsOverview.subcontractorsTableRow.empty')}</option>
          </select>
        </th>
        <th>
          <select
            aria-label="subcontracting unit price filter"
            value={filterValues.unitPrice !== null ? filterValues.unitPrice : 'none'}
            onChange={(event) => updateFilterValue('unitPrice', event.target.value)}
          >
            <option aria-label="subcontracting coast filter none" value="none" />
            <option aria-label="subcontracting coast filter not_empty" value="not-empty">{t('subcontractorsOverview.subcontractorsTableRow.not_empty')}</option>
            <option aria-label="subcontracting coast filter empty" value="empty">{t('subcontractorsOverview.subcontractorsTableRow.empty')}</option>
          </select>
        </th>
        <th>
          <select
            aria-label="subcontracting action filter"
            value={filterValues.action !== null ? filterValues.action : 'none'}
            onChange={(event) => updateFilterValue('action', event.target.value)}
          >
            <option aria-label="subcontracting action filter none" value="none" />
            <option aria-label="subcontracting action filter to_review" value="to_review">{t('subcontractorsOverview.subcontractorsTableRow.to_review')}</option>
            <option aria-label="subcontracting action filter reviewed" value="reviewed">{t('subcontractorsOverview.subcontractorsTableRow.reviewed')}</option>
            <option aria-label="subcontracting action filter empty" value="empty">{t('subcontractorsOverview.subcontractorsTableRow.empty')}</option>
          </select>
        </th>
      </tr>
    </StyledSubcontractorsTableHeader>
  )
}

export default SubcontractorsTableHeader
