import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectQualityInfoStyled } from './ProjectQualityInfo.styled'
import { getClass } from '../../helpers/stringHelper'
import { useProjectQualityContext } from '../../context/ProjectQuality/ProjectQualityContext'
import Link from '../Link/Link'
import pictoCheck from '../../../assets/images/pictoCheck.svg'
import pictoWarning from '../../../assets/images/warningCircle.svg'
import pictoExternalLink from '../../../assets/images/externalLinkBlue.svg'

export const ProjectQualityInfo = ({ title, infoType }) => {
  const { t } = useTranslation()
  const { project } = useProjectQualityContext()
  const [infosSensivitve, setInfosSensivite] = useState('')
  const [picto, setPicto] = useState(pictoWarning)

  const infosSensitiveText = () => {
    if (infoType === 'risky_countries') {
      if (project.operational_risk_level === 'low') return t(`project.quality.infos.risk_level_low`)
      if (project.operational_risk_level === 'medium') return t(`project.quality.infos.risk_level_medium`)
      if (project.operational_risk_level === 'high') return t(`project.quality.infos.risk_level_high`)
    }
    return ''
  }

  const typeSensitive = () => {
    if ((infoType === 'risky_countries' && (project.operational_risk_level === 'low' || project.operational_risk_level === 'medium'))) {
      setInfosSensivite('not-info-sensitive')
      setPicto(pictoCheck)
    }
    if ((infoType === 'risky_countries' && project.operational_risk_level === 'high')
      || (infoType === 'personal_data' && project.personal_data)) {
      setInfosSensivite('info-sensitive')
      setPicto(pictoWarning)
    }
  }

  useEffect(() => {
    if (project) {
      typeSensitive()
    }
  })

  if (project === null) {
    return <div>...</div>
  }

  return (
    <ProjectQualityInfoStyled picto={picto}>
      <div className="quality-info">
        {title}
        <div className="info-content">
          { infoType !== 'personal_data'
          && (
          <div className={getClass('info-tag', infosSensivitve)}>
            {picto && <div className="picto" /> }
            <div className="info-value">
              { infosSensitiveText() }
            </div>
          </div>
          )}
          {infoType === 'personal_data' ? (
            <Link
              className="link-info"
              name="mattermost"
              href="https://docs.google.com/document/d/1RAaeRWoO5CdsTWnG2uCTWW7UebW2X34S73n_htY77LM/edit#heading=h.1a6umccz1dpa"
            >
              <span>
                {t(`project.quality.infos.rgpd_form`)}
              </span>
              <img aria-label="picto link externe affiliated mission" src={pictoExternalLink} />
            </Link>
          ) : null}
        </div>
      </div>
    </ProjectQualityInfoStyled>
  )
}
