import styled from 'styled-components'

export default styled.div`
  width: 100%;

  >.popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    opacity: 0%;
    pointer-events: none;
    transition: opacity 0.1s ease-in-out;

    >.overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      background: ${({ theme }) => theme.colors.betterway.overlay};
    }

    >.window {
      z-index: 1;
      box-sizing: border-box;
      padding: 1.6rem;
      background-color: ${({ theme }) => theme.colors.betterway.primary[5]};
      color: ${({ theme }) => theme.colors.betterway.primary[100]};

      >.header {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        height: 3.2rem;
        margin-bottom: 1.6rem;

        >.header-close {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          box-sizing: border-box;
          width: 3.2rem;
          height: 3.2rem;
          padding: 0;
          border-radius: 1.6rem;
          background-color: ${({ theme }) => theme.colors.betterway.transparent};
          background-position: center;
          transition: background-color 0.1s ease-in-out;

          &:hover {
            background-color: ${({ theme }) => theme.colors.betterway.primary[20]};
          }
        }

        >.header-title {
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          font-weight: 400;
          font-variant: normal;
          font-size: 1.7rem;
        }
      }

      >.content {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        max-height: calc(100% - 4.8rem);
      }
    }

    &.opened {
      opacity: 100%;
      visibility: visible;
      pointer-events: initial;
    }

    &.center {
      justify-content: center;

      >.window {
        width: 90%;
        max-width: 40rem;
        max-height: 60%;
        border-radius: 1rem;
      }
    }

    &.bottom {
      justify-content: flex-end;

      >.window {
        width: 100%;
        max-width: 100%;
        max-height: 90%;
        border-radius: 1rem 1rem 0 0;
      }
    }

    &.window-stretch {
      justify-content: center;

      >.window {
        width: 60%;
        max-width: 200rem;
        max-height: 80%;
        border-radius: 1rem;
      }
    }
  }
`
