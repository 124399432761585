import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTimesheet } from './Timesheet.styled'

import Period from '../Period/Period'

const Timesheet = (
  {
    disabled,
    monthName,
    monthNum,
    year,
    changeMonth,
    active,
    firstPeriodStatus,
    secondPeriodStatus,
    firstPeriodFillRate,
    secondPeriodFillRate,
    loading,
  },
) => {
  const lastDayOfMonth = (year, month) => new Date(year, month, 0).getDate()
  const { t } = useTranslation()

  return (
    <StyledTimesheet onClick={changeMonth} active={active} role={`timesheet-${monthName}`} disabled={disabled}>
      <h3>{`${monthName} ${year}`}</h3>
      {
        disabled
          ? (
            <span>
              {t('timesheet.unauthorizedMonth')}
            </span>
          )
          : (
            <>
              <Period loading={loading} periodStatus={firstPeriodStatus} periodFillRate={firstPeriodFillRate} periodRange="1 - 15" />
              <Period loading={loading} periodStatus={secondPeriodStatus} periodFillRate={secondPeriodFillRate} periodRange={`16 - ${lastDayOfMonth(year, monthNum)}`} />
            </>
          )
      }
    </StyledTimesheet>
  )
}

export default Timesheet
