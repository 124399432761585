import React, { useState } from 'react'
import { Icon } from '@produits-internes-oss/design-system-components'
import { DropdownStyled } from './Dropdown.styled'

export const Dropdown = ({ children, className, title, localization, picto, withStartupOpen }) => {
  const [isOpened, setIsOpened] = useState(withStartupOpen)

  return (
    <DropdownStyled className={className} picto={picto} opened={isOpened}>
      <button
        className="header"
        type="button"
        aria-label={isOpened ? localization.close.arialabel : localization.open.arialabel}
        tabIndex={0}
        onClick={() => setIsOpened(!isOpened)}
      >
        {
          picto && <div className="picto" />
        }
        <div className="title" aria-level={2}>{title}</div>
        <div className="state" aria-hidden>
          <span className="state-text">{isOpened ? localization.close.text : localization.open.text}</span>
          <Icon className="state-icon" name="chevron-simple" />
        </div>
      </button>
      {
        isOpened && <div className="content">{children}</div>
      }
    </DropdownStyled>
  )
}
