import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../../../UI/Modal/Modal'
import Icon from '../../../UI/Icon/Icon'
import { StyledHelpModal } from './HelpModal.styled'
import inputBlue from '../../../../../assets/images/inputBlue.svg'
import inputRed from '../../../../../assets/images/inputRed.svg'
import inputOrange from '../../../../../assets/images/inputOrange.svg'

const HelpModal = ({ open, close }) => {
  const { t } = useTranslation()

  return (
    <Modal open={open} close={close} height="810px" width="830px" className="modal-help">
      <StyledHelpModal className="help-modal">
        <h3>{t('timesheet.helpModal.title')}</h3>
        <TimeInputColorExplanationTable />
        <NavigationExplanationTable />
        <p>{t('timesheet.helpModal.remarks.replaceCell')}</p>
      </StyledHelpModal>
    </Modal>
  )
}

const NavigationExplanationTable = () => {
  const { t } = useTranslation()

  return (
    <table>
      <thead>
        <tr>
          <th>{t('timesheet.helpModal.key')}</th>
          <th>{t('timesheet.helpModal.action')}</th>
        </tr>
      </thead>
      <tbody>
        <HelpTopic title="keys" description="actions" topic="directions">
          <span className="keyboard-key">
            <Icon name="ArrowLeft" color="white" />
          </span>
          <span className="keyboard-key">
            <Icon name="ArrowRight" color="white" />
          </span>
          <span className="keyboard-key">
            <Icon name="ArrowUp" color="white" />
          </span>
          <span className="keyboard-key">
            <Icon name="ArrowDown" color="white" />
          </span>
        </HelpTopic>
        <HelpTopic title="keys" description="actions" topic="oneToEight">
          <span className="keyboard-key">1</span>
          <Icon name="ArrowRight" color="primary" />
          <span className="keyboard-key">8</span>
        </HelpTopic>
        <HelpTopic title="keys" description="actions" topic="spaceBar">
          <span className="space-bar keyboard-key">
            <Icon name="SpaceBar" color="white" />
          </span>
        </HelpTopic>
        <HelpTopic title="keys" description="actions" topic="backspace">
          <span className="backspace keyboard-key">
            <Icon name="ArrowLeft" color="white" />
          </span>
        </HelpTopic>
        <HelpTopic title="keys" description="actions" topic="zero">
          <span className="keyboard-key">0</span>
        </HelpTopic>
      </tbody>
    </table>
  )
}

const TimeInputColorExplanationTable = () => {
  const { t } = useTranslation()

  return (
    <table className="color-code">
      <thead>
        <tr>
          <th>{t('timesheet.helpModal.color')}</th>
          <th>{t('timesheet.helpModal.meaning')}</th>
        </tr>
      </thead>
      <tbody>
        <HelpTopic title="colors" description="colorMeaning" topic="blue">
          <img src={inputBlue} alt="blue input" />
        </HelpTopic>
        <HelpTopic title="colors" description="colorMeaning" topic="orange">
          <img src={inputOrange} alt="blue input" />
        </HelpTopic>
        <HelpTopic title="colors" description="colorMeaning" topic="red">
          <img src={inputRed} alt="blue input" />
        </HelpTopic>
      </tbody>
    </table>
  )
}

const HelpTopic = ({ title, description, topic, children }) => {
  const { t } = useTranslation()

  return (
    <tr>
      <th>
        {t(`timesheet.helpModal.${title}.${topic}`)}
        <div>{children}</div>
      </th>
      <th>{t(`timesheet.helpModal.${description}.${topic}`)}</th>
    </tr>
  )
}

export default HelpModal
