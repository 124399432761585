import React, {
  createContext,
  useContext,
  useState,
} from 'react'
import { useLocalObservable } from 'mobx-react'

// Polyfill for Object.fromEntries which does not run with jest
Object.fromEntries = Object.fromEntries || function objFromArray(array) {
  return array.reduce((acc, curr) => {
    const toReplace = curr[1]
    acc[curr[0]] = toReplace
    return acc
  }, {})
}

export const TimeinputsContext = createContext()

export const TimeinputsContextProvider = ({ initialState, children }) => {
  const [dateInput, setDateInput] = useState()
  const timeinputsStore = useLocalObservable(
    () => (
      {
        setDateInput,
        dateInput,
        timeinputs: initialState,
        updateTimeinputs(newTimeinputs) {
          timeinputsStore.timeinputs = {
            ...newTimeinputs,
          }
        },
        nbHoursInDay: 8,
        inputsInHoursForDate(date) {
          const listOfActivities = Object.keys(timeinputsStore.timeinputs)
          const inputsByDay = listOfActivities.map((activity) => {
            const timeInputsForActivityAndDay = timeinputsStore.timeinputs[activity][date] || {}
            return +timeInputsForActivityAndDay.time_in_hours || 0
          })
          return inputsByDay
        },
        firstPeriodDays(daysInMonth) {
          return daysInMonth
            .filter((date) => {
              const fullDate = new Date(date)
              return fullDate < new Date(fullDate.getFullYear(), fullDate.getMonth(), 16)
            })
        },
        secondPeriodDays(daysInMonth) {
          return daysInMonth
            .filter((date) => {
              const fullDate = new Date(date)
              return !(fullDate < new Date(fullDate.getFullYear(), fullDate.getMonth(), 16))
            })
        },
        timeinputsFilteredOnDate(condition) {
          const activityInputs = Object.entries(timeinputsStore.timeinputs)
          const activityInputsFilteredOnDate = activityInputs.map(([activity, inputs]) => {
            const inputsByDate = Object.entries(inputs).filter(([date]) => condition(date))
            return [activity, Object.fromEntries(inputsByDate)]
          })

          return Object.fromEntries(activityInputsFilteredOnDate)
        },
        firstPeriodTimeinputs(daysInMonth) {
          return timeinputsStore.timeinputsFilteredOnDate((date) => timeinputsStore.firstPeriodDays(daysInMonth).includes(date))
        },
        secondPeriodTimeinputs(daysInMonth) {
          return timeinputsStore.timeinputsFilteredOnDate((date) => timeinputsStore.secondPeriodDays(daysInMonth).includes(date))
        },
        totalInputsInHoursForDate(date) {
          return timeinputsStore.inputsInHoursForDate(date).reduce((total, input) => total + input, 0)
        },
        totalInputsInDaysForDate(date) {
          return timeinputsStore.totalInputsInHoursForDate(date) / timeinputsStore.nbHoursInDay
        },
        totalInputsForMonth(daysInMonth) {
          const totalInputsInHoursForMonth = daysInMonth
            .map((date) => timeinputsStore.totalInputsInHoursForDate(date))
            .reduce((total, input) => total + input, 0)

          return {
            inHours: totalInputsInHoursForMonth,
            inDays: totalInputsInHoursForMonth / timeinputsStore.nbHoursInDay,
          }
        },
        totalInputsForFirstPeriod(daysInMonth) {
          const inputsInHoursForFirstPeriod = timeinputsStore.firstPeriodDays(daysInMonth)
            .map((date) => timeinputsStore.totalInputsInHoursForDate(date))
          const inputsInDaysForFirstPeriod = timeinputsStore.firstPeriodDays(daysInMonth)
            .map((date) => timeinputsStore.totalInputsInDaysForDate(date))

          return {
            inHours: inputsInHoursForFirstPeriod.reduce((total, input) => total + input, 0),
            inDays: inputsInDaysForFirstPeriod.reduce((total, input) => total + input, 0),
          }
        },
        totalInputsForSecondPeriod(daysInMonth) {
          const inputsInHoursForSecondPeriod = timeinputsStore.secondPeriodDays(daysInMonth)
            .map((date) => timeinputsStore.totalInputsInHoursForDate(date))
          const inputsInDaysForSecondPeriod = timeinputsStore.secondPeriodDays(daysInMonth)
            .map((date) => timeinputsStore.totalInputsInDaysForDate(date))

          return {
            inHours: inputsInHoursForSecondPeriod.reduce((total, input) => total + input, 0),
            inDays: inputsInDaysForSecondPeriod.reduce((total, input) => total + input, 0),
          }
        },
        totalFirstPeriodInputsForActivity(daysInMonth) {
          const firstPeriodTimeinputs = timeinputsStore.firstPeriodTimeinputs(daysInMonth)

          return (activityId) => {
            const firstPeriodTimeinputsForActivity = firstPeriodTimeinputs[activityId] || {}

            return {
              inHours: Object.values(firstPeriodTimeinputsForActivity).reduce((total, input) => total + parseFloat(input.time_in_hours), 0),
              inDays: Object.values(firstPeriodTimeinputsForActivity).reduce((total, input) => total + parseFloat(input.time_in_days), 0),
            }
          }
        },
        totalSecondPeriodInputsForActivity(daysInMonth) {
          const secondPeriodTimeinputs = timeinputsStore.secondPeriodTimeinputs(daysInMonth)

          return (activityId) => {
            const secondPeriodTimeinputsForActivity = secondPeriodTimeinputs[activityId] || {}

            return {
              inHours: Object.values(secondPeriodTimeinputsForActivity).reduce((total, input) => total + parseFloat(input.time_in_hours), 0),
              inDays: Object.values(secondPeriodTimeinputsForActivity).reduce((total, input) => total + parseFloat(input.time_in_days), 0),
            }
          }
        },
        timeInputDisabled(validatedPeriodDays, day, owner) { return validatedPeriodDays.includes(day) || owner.leaving_date < day || owner.entry_date > day },
      }
    ),
  )

  return (
    <TimeinputsContext.Provider value={timeinputsStore}>
      {children}
    </TimeinputsContext.Provider>
  )
}

export const useTimeinputsContextValue = () => useContext(TimeinputsContext)
