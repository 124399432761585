const isInViewport = (element, border) => {
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= (border?.top || 0)
    && rect.left >= (border?.left || 0)
    && rect.bottom <= (window.innerHeight - border?.bottom || document.documentElement.clientHeight - border?.bottom || window.innerHeight || document.documentElement.clientHeight)
    && rect.right <= (window.innerWidth - border?.right || document.documentElement.clientWidth - border?.right || window.innerWidth || document.documentElement.clientWidth)
  )
}

export const repositionElement = (element, position, border = {}) => {
  if (!isInViewport(element, border)) {
    element.scrollIntoView({
      block: position,
      behavior: 'smooth',
      inline: 'nearest',
    })
  }
}

export const tableHeightsInPx = {
  footer: 80,
  header: 160,
}
