import styled from 'styled-components'

export const StyledProgressBar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;

  span {
    width: 10%;
    margin-left: 10px;
    font-size: ${({ size }) => `${size.fontSize}rem`};
    color: ${({ theme, rate }) => (rate > 100 ? theme.colors.blockingError : 'inherit')};
    text-align: right;
  }

  .progress-bar {
    height: ${({ size }) => `${size.barHeight}px`};
    width: 90%;
    background: ${({ theme }) => theme.colors.paleGrey2};
    border-radius: 10px;
    position: relative;

    :after {
      content: '';
      display: block;
      position: absolute;
      transition: width 1s ease-in-out;
      width: ${({ rate }) => Math.min(rate, 100)}%;
      height: 100%;
      border-radius: 10px;
      background: ${({ color, theme }) => theme.colors[color]};
    }

    &.reference {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;

      :after {
        content: '';
        display: block;
        position: absolute;
        transition: width 1s ease-in-out;
        width: ${({ referenceRate }) => Math.min(referenceRate, 100)}%;
        height: 100%;
        border-radius: 10px;
        background: ${({ theme }) => theme.colors.primaryHover};
      }
    }
  }

  .rate-reference {
    position: absolute;
    bottom: 2.3rem;
    right: 0;
    font-size: 1.2rem;
    border-width: 1px;
    border-style: solid;
    border-radius: 0.8rem;
    padding: 0 0.8rem;
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
  }
`
