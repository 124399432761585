import React, { useState, useEffect, useRef } from 'react'
import { StyledActivityPopin } from './ActivityPopin.styled'
import ActivityInfo from './ActivityInfo/ActivityInfo'
import ActivityInfoSkeleton from './ActivityInfo/ActivityInfoSkeleton'

import { useFetchData } from '../../../../../fetchOverviewApi'
import { useTimesheetStateValue } from '../../../../../context/Timesheet/TimesheetStateContext'
import { repositionElement } from '../../../../../helpers/repositionElementInScreen'

const ActivityPopin = ({ toggleActivityPopin, activityId }) => {
  const [loading, hasError, data, handleFetch] = useFetchData(true)
  const [activityState, setActivityState] = useState(null)
  const { timesheet } = useTimesheetStateValue()
  const isMounted = useRef(true)
  const ref = useRef()
  useEffect(() => {
    repositionElement(ref.current, 'end')
    const url = `/activities/${activityId}/activity_info?timesheet_id=${timesheet.id}`

    handleFetch(isMounted, url)
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (hasError) {
      setActivityState()
    } else {
      setActivityState(data)
    }
  }, [hasError, data])

  return (
    <StyledActivityPopin
      onClick={(e) => e.stopPropagation()}
      ref={ref}
      role={`activity-popin-${activityId}`}
    >
      {
        loading
          ? <ActivityInfoSkeleton />
          : <ActivityInfo activityState={activityState} toggleActivityPopin={toggleActivityPopin} />
      }
    </StyledActivityPopin>
  )
}

export default ActivityPopin
