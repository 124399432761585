import React from 'react'
import './Checkbox.css'

class Checkbox extends React.Component {
    state = {
      isChecked: this.props.isChecked,
    }

    handleChecked = () => {
      this.setState(() => ({ isChecked: !this.state.isChecked }))
    }

    componentDidUpdate(prevProps, prevState) {
      const currentState = this.state
      if (prevState.isChecked != currentState.isChecked) {
        this.props.onChange(currentState.isChecked)
      }
    }

    render() {
      const { name, className } = this.props
      const { isChecked } = this.state
      return (
        <div className={className}>
          <input className="checkbox-input" onChange={this.handleChecked} type="checkbox" id={name} name={name} checked={isChecked} />
          <label className="checkbox-label" htmlFor={name}>{ name }</label>
        </div>
      )
    }
}

export default Checkbox
