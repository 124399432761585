import React from 'react'
import { StyledIcon } from './Icon.styled'
import theme from '../../../../style/theme'

const ArrowLeft = (color) => <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 11H6.83L10.41 7.41L9 6L3 12L9 18L10.41 16.59L6.83 13H21V11Z" fill={color} /></svg>
const ArrowRight = (color) => <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 13L17.17 13L13.59 16.59L15 18L21 12L15 6L13.59 7.41L17.17 11L3 11V13Z" fill={color} /></svg>
const ArrowUp = (color) => <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 21L13 6.83L16.59 10.41L18 9L12 3L6 9L7.41 10.41L11 6.83L11 21H13Z" fill={color} /></svg>
const ArrowDown = (color) => <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 3V17.17L7.41 13.59L6 15L12 21L18 15L16.59 13.59L13 17.17V3H11Z" fill={color} /></svg>
const SpaceBar = (color) => <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18 9v4H6V9H4v6h16V9z" fill={color} /></svg>

const icons = {
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowDown,
  SpaceBar,
}

const Icon = ({ name, color, width }) => (
  <StyledIcon width={width}>
    { icons[name](theme.colors[color])}
  </StyledIcon>
)

export default Icon
