import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  overflow-y: auto;
  height: 57.8rem;

  >.search {
    margin-bottom: 1.6rem;
  }

  >.placeholder {
    padding: 0 1rem;
    color: ${({ theme }) => theme.colors.betterway.primary[50]};
    font-weight: 400;
    font-variant: normal;
    font-size: 1.7rem;
    line-height: 1.2;
  }
`
