import styled from 'styled-components/macro'

export default styled.div`
  margin-top: calc(var(--web-header-height) + 20px);
  margin-bottom: 35px;
  margin-left: 20px;
  display: inline-grid;
  grid-template-columns: auto auto;
  align-items: center;

  h1 {
    text-transform: capitalize;
    margin-right: 10rem;
  }
`
