import React from 'react'
import { ThemeProvider } from 'styled-components/macro'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import { ConsecutivePaidLeaveContextProvider } from '../../context/ConsecutivePaidLeave/ConcecutivePaidLeaveContext'
import { ConsecutivePaidLeave } from '../../components/ConsecutivePaidLeave/ConsecutivePaidLeave'
import { ConsecutivePaidLeaveScreenStyled } from './ConcecutivePaidLeaveScreen.styled'

export function ConsecutivePaidLeaveScreen({ peopleWithoutMandatoryPaidLeaves }) {
  return (
    <ConsecutivePaidLeaveScreenStyled>
      <ConsecutivePaidLeaveContextProvider peopleWithoutMandatoryPaidLeaves={peopleWithoutMandatoryPaidLeaves}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <ConsecutivePaidLeave peopleWithoutMandatoryPaidLeaves={peopleWithoutMandatoryPaidLeaves} />
        </ThemeProvider>
      </ConsecutivePaidLeaveContextProvider>
    </ConsecutivePaidLeaveScreenStyled>
  )
}
