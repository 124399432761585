import styled from 'styled-components'

export const StyledAccordion = styled.summary`
  outline: none;
  list-style-type: none;

  .title {
    background-color: ${({ unfold, theme }) => (unfold ? theme.colors.paleBlue2 : theme.colors.white)};
    color: ${({ theme }) => theme.colors.primaryDark};
    cursor: pointer;
    display: flex;
    max-height: 3.6rem;
    border-bottom: ${({ theme }) => theme.colors.primaryGreyLight} solid 0.1px;
    transition: background-color 0.6s ease;
  }

  p {
    font-family: ${({ type, theme }) => (type === 'accordionMedium'
    ? theme.fonts.family.medium
    : theme.fonts.family.bold)},
      sans-serif;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fonts.size.normal};
    margin: 0;
    padding: 0.8rem;
  }

  div:hover {
    background-color: ${({ theme }) => theme.colors.paleBlue2};
  }

  &::-webkit-details-marker {
    display: none;
  }

  & > p > p {
    font-family: Apercu-Light;
  }
`
