import styled from 'styled-components'

export const StyledDoubleProgressCircle = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  margin-right: 1rem;
  span {
    position: absolute;
    padding: 0 1rem;
    left: 100%;
    font-size: 1.3rem;
    border-radius: 12px;
    width: max-content;
    color: ${({ theme }) => theme.colors.white};
    &:first-of-type {
      background: ${({ theme }) => theme.colors.blockingError};
      top: 15%;
    }
    &:nth-of-type(2) {
      background: ${({ theme }) => theme.colors.secondary};
      top: 60%;
    }
  }

  svg:first-of-type {
    position: absolute;

    circle {
      &:nth-child(1) {
        stroke: ${({ theme }) => theme.colors.blockingErrorPale};
        stroke-width: 7;
        fill: transparent;
      }
      &:nth-child(2) {
        stroke: ${({ theme }) => theme.colors.blockingError};
        stroke-width: 8;
        stroke-linecap: round;
        fill: transparent;
        transform: rotate(-90deg);
      }
    }
  }

  svg:nth-of-type(2) {
    circle {
      &:nth-child(1) {
        stroke: ${({ theme }) => theme.colors.secondaryPale};
        stroke-width: 7;
        fill: transparent;
      }
      &:nth-child(2) {
        stroke: ${({ theme }) => theme.colors.secondary};
        stroke-width: 8;
        stroke-linecap: round;
        fill: transparent;
        transform: rotate(-90deg);
      }
    }
  }
`
export const StyledCircleBar = styled.svg`
  circle {
    &:nth-child(2) {
      stroke-dasharray: ${({ circumference }) => circumference};
    }
  }
`
