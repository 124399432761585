import React from 'react'
import { useTranslation } from 'react-i18next'
import { Section } from '@produits-internes-oss/design-system-components'
import { getNumberWithUnit, parseFloatWithAnySeparator } from '../../helpers/stringHelper'
import { useProjectCoordinationContext } from '../../context/ProjectCoordination/ProjectCoordinationContext'
import { ProjectInvoicesStyled } from './ProjectInvoices.styled'
import pictoLink from '../../../assets/images/link.svg'

export const ProjectInvoices = () => {
  const { t } = useTranslation()
  const { project } = useProjectCoordinationContext()

  if (!project) return <div>...</div>

  return (
    <ProjectInvoicesStyled className="project-invoices">
      <div className="header-container">
        <h3 className="title">{t('project.invoices.title')}</h3>
        <a className="invoice-resume-link" href={`/projects/${project.id}/project_expenses_reports`} target="_blank" rel="noopener noreferrer">
          {t('project.invoices.resume_link_title')}
          <img className="link-icon" src={pictoLink} alt="link icon" />
        </a>
      </div>
      <div className="resume-container">
        <div className="detail">
          <Section>
            <div>
              <p className="text">{t('project.invoices.detail.totalInvoicable')}</p>
              <p className="value">
                {getNumberWithUnit(
                  parseFloatWithAnySeparator(project.project_expenses_totals.expenses_by_kind.filter((expense) => expense.kind === 'invoiceable')[0].total),
                  project.project_expenses_totals.currency_symbol,
                )}
              </p>
            </div>
          </Section>
          <Section>
            <div>
              <p className="text">{t('project.invoices.detail.totalCostPackage')}</p>
              <p className="value">
                {getNumberWithUnit(
                  parseFloatWithAnySeparator(project.project_expenses_totals.expenses_by_kind.filter((expense) => expense.kind === 'cost_packages')[0].total),
                  project.project_expenses_totals.currency_symbol,
                )}
              </p>
            </div>
          </Section>
          <Section>
            <div>
              <p className="text">{t('project.invoices.detail.totalNotInvoicable')}</p>
              <p className="value">
                {getNumberWithUnit(
                  parseFloatWithAnySeparator(project.project_expenses_totals.expenses_by_kind.filter((expense) => expense.kind === 'not_invoiceable')[0].total),
                  project.project_expenses_totals.currency_symbol,
                )}
              </p>
            </div>
          </Section>
        </div>
        <Section className="global-total">
          <div>
            <p className="text">{t('project.invoices.totalAmount')}</p>
            <p className="value">{getNumberWithUnit(parseFloatWithAnySeparator(project.project_expenses_totals.total), project.project_expenses_totals.currency_symbol)}</p>
          </div>
        </Section>
      </div>
    </ProjectInvoicesStyled>
  )
}
