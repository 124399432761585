import styled from 'styled-components'
import { Section } from '@produits-internes-oss/design-system-components'
import pictoExclamation from '../../../assets/images/exclamation.svg'

export const ProjectModalStaffingFormStyled = styled(Section)`
  > section {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: space-between;
    margin: ${(props) => (props.modalAddStaffing ? `0 !important` : ``)};
    padding: ${(props) => (props.modalAddStaffing ? `0 !important` : ``)};
    border: ${(props) => (props.modalAddStaffing ? `none !important` : ``)};
    background-color: ${(props) => (props.modalAddStaffing ? `transparent !important` : ``)};

    >.header {
      >.form-control {
        width: 30rem;
        margin-bottom: 3rem;
      }

      > strong {
        font-weight: 600;

        ::after {
          content: ' *';
          color: ${({ theme }) => theme.colors.betterway.red[100]};
        }
      }

      >.example {
        margin-top: 0.4rem;

        > em {
          text-decoration: underline;
        }

        > span {
          ::before {
            content: ' ';
          }
        }
      }
    }

    .octo.hard > .control {
      width: 30rem;
    }

    >.staffing-form {
      >.attribute {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;

        >.form-control {
          width: 30rem;
          margin-bottom: 3rem;

          .control {
            width: 100%;
          }
        }
      }

      >.comment {
        .form-control {
          width: 50%;
        }

        >.max {
          display: flex;
          flex-flow: row nowrap;
          gap: 0.4rem;
          align-items: center;
          justify-content: flex-start;
          margin-top: 1rem;
          margin-left: 1.6rem;
          font-weight: 600;
          font-size: 1.2rem;

          ::before {
            content: '';
            width: 2rem;
            height: 2rem;
            background-color: ${({ theme }) => theme.colors.betterway.red[100]};
            mask-image: url(${pictoExclamation});
            mask-size: contain;
            mask-repeat: no-repeat;
          }
        }
      }

      >.footer {
        display: flex;
        flex-direction: row;
        justify-content: end;

        >.control {
          display: flex;
          gap: 2rem;
          width: 31rem;

          button {
            display: flex;
            justify-content: center;
          }

          .cancel {
            width: auto;

            button {
              border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
              background-color: ${({ theme }) => theme.colors.betterway.white};
              color: ${({ theme }) => theme.colors.betterway.primary[100]};

              &:hover {
                border-color: ${({ theme }) => theme.colors.betterway.white};
                background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
                color: ${({ theme }) => theme.colors.betterway.white};
              }
            }
          }

          .validate {
            display: flex;
            justify-content: center;
            width: fit-content;
          }
        }
      }
    }
  }
`
