import React from 'react'

import { StyledSkeletonRow } from './SkeletonRow.styled'

const SkeletonRow = ({ rows, columns }) => (
  Array.from({ length: rows }).map((row, i) => (
    <StyledSkeletonRow key={i}>
      {Array.from({ length: columns }).map((col, i) => (
        <td key={i}>
          <div />
        </td>
      ))}
    </StyledSkeletonRow>
  ))
)

export default SkeletonRow
