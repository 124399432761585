import React from 'react'
import AsyncSelect from 'react-select/async'
import { Translation } from 'react-i18next'

const customStyles = {
  input: (base) => ({
    ...base,
    'input:focus': {
      boxShadow: 'none',
      margin: 'auto',
      fontFamily: 'Apercu',
    },
  }),
  control: (base) => ({
    ...base,
    borderRadius: 50,
    cursor: 'pointer',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  menu: (base) => ({
    ...base,
    zIndex: 1001,
  }),
}

class AutoCompleteSelect extends React.Component {
  state = {
    focus: false,
  };

  onFocus = () => this.setState({ focus: true });

  onBlur = () => this.setState({ focus: false });

  value() {
    if (this.state.focus) {
      return ''
    }
    return this.props.value
  }

  render() {
    const {
      loadOptions,
      getOptionValue,
      onChange,
      noOptionsMessageType,
    } = this.props

    return (
      <AsyncSelect
        value={this.value()}
        loadOptions={loadOptions}
        onChange={onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        blurInputOnSelect
        styles={customStyles}
        getOptionValue={getOptionValue}
        noOptionsMessage={() => (
          <Translation>
            {(t) => (
              <span>
                {t(`select.${noOptionsMessageType}.noOptionsMessage`)}
              </span>
            )}
          </Translation>
        )}
      />
    )
  }
}

export default AutoCompleteSelect
