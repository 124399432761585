import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../input/_ExportDS/Button/Button'
import { monthList } from '../../../../helpers/datePickerHelper'
import { useManagerPeriodsContext } from '../../../../context/ManagePeriods/ManagePeriodsContext'
import { PeriodPickerBlockStyled } from './PeriodPickerBlock.styled'

export default function PeriodPickerBlock({ date, currentPeriod, handlePopupClose, getCurrentActiveTabData }) {
  const {
    setDate,
    setPeriodsValidationButtonDisabled,
  } = useManagerPeriodsContext()

  const { t } = useTranslation()
  const givenMonth = t(`months.${monthList[date.month - 1]}.full`)
  const monthCapitalize = givenMonth.charAt(0).toUpperCase() + givenMonth.slice(1)
  const isCurrentMonth = currentPeriod !== undefined && currentPeriod.year === date.year && currentPeriod.month === date.month

  return (
    <PeriodPickerBlockStyled>
      {monthCapitalize}
      {' '}
      {date.year}
      <Button
        className={isCurrentMonth && currentPeriod.period === 1 ? 'current-period-button' : 'period-button'}
        text={t('managePeriods.periodPicker.firstPeriod')}
        name="periode 1"
        onClick={() => {
          setDate({ year: date.year, month: date.month, day: 1 })
          setPeriodsValidationButtonDisabled(false)
          getCurrentActiveTabData(date.year, date.month, 1)
          handlePopupClose()
        }}
      />
      <Button
        className={isCurrentMonth && currentPeriod.period === 2 ? 'current-period-button' : 'period-button'}
        text={t('managePeriods.periodPicker.secondPeriod')}
        name="periode 2"
        onClick={() => {
          setDate({ year: date.year, month: date.month, day: 16 })
          setPeriodsValidationButtonDisabled(false)
          getCurrentActiveTabData(date.year, date.month, 16)
          handlePopupClose()
        }}
      />
    </PeriodPickerBlockStyled>
  )
}
