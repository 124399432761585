import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectContingencyStyled } from './ProjectContingency.styled'
import Input from '../input/_ExportDS/InputWithStyle/Input'
import Button from '../input/_ExportDS/ButtonWithStyle/Button'
import pictoEdit from '../../../assets/images/editBlue.svg'
import { parseFloatWithAnySeparator } from '../../helpers/stringHelper'

export const ProjectContingency = () => {
  const { t } = useTranslation()
  const {
    project,
    contingencyPercent,
    fetchUpdateContingency,
    isProjectPresales,
  } = useProjectSalesContext()

  const [contingencyPercentValue, setContingencyPercentValue] = useState(contingencyPercent)
  const [isEditable, setIsEditable] = useState(false)

  const updateContingency = async () => {
    await fetchUpdateContingency(contingencyPercentValue)
    setIsEditable(false)
  }

  useEffect(() => {
    setContingencyPercentValue(contingencyPercent)
  }, [contingencyPercent])

  if (project === null) {
    return <div>...</div>
  }

  return (
    isProjectPresales() && (
      <ProjectContingencyStyled>
        <div className="content-left">
          <div className="input-group">
            <Input
              className="value"
              type="number"
              name={t('project.costs.contingency.percent_value.arialabel')}
              title={t('project.costs.contingency.percent_value.text')}
              value={contingencyPercentValue}
              disabled={!isEditable}
              onChange={(value) => setContingencyPercentValue(parseFloatWithAnySeparator(value))}
              onValidate={() => contingencyPercentValue >= 0 && (Number.isInteger(contingencyPercentValue)) && updateContingency()}
            />
            <i className={!isEditable ? 'disabled-input' : ''}>%</i>
          </div>
        </div>
        <div>
          {
            isEditable
              ? (
                <Button
                  className="create"
                  name={t('project.costs.contingency.create.arialabel')}
                  text={t('project.costs.contingency.create.text')}
                  disabled={contingencyPercentValue < 0 || (Number.isNaN(contingencyPercentValue))}
                  onClick={() => updateContingency()}
                />
              )
              : (
                <Button
                  className="edit"
                  name={t('project.costs.contingency.edit.arialabel')}
                  text={t('project.costs.contingency.edit.text')}
                  picto={pictoEdit}
                  onClick={() => setIsEditable(true)}
                />
              )
          }
        </div>
      </ProjectContingencyStyled>
    )
  )
}
