import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { clean } from '../../../helpers/stringHelper'
import { usePeopleListingContext } from '../../../context/PeopleListing/PeopleListingContext'
import usePagination from '../../../hooks/usePagination/usePagination'
import Link from '../../Link/Link'
import PeopleListingTableStyled from './PeopleListingTable.styled'

export default function PeopleListingTable({ isEditable }) {
  const { t } = useTranslation()
  const {
    urlAskbobUsers, peopleFilteredBySearchAndSorted, sortKey, sortIsAscendant, toggleSort,
  } = usePeopleListingContext()

  useEffect(() => {
    toggleSort('last_name')
  }, [])

  const {
    page,
    pageLast,
    pageStart,
    pageEnd,
    pageEndBlanks,
    getNavigator,
    navigateToPage,
  } = usePagination(peopleFilteredBySearchAndSorted.length, 10)

  const columns = [
    {
      key: 'last_name', label: t('people.listing.columns.name'), isSortable: true,
    },
    {
      key: 'nickname', label: t('people.listing.columns.nickname'), width: '10rem', isSortable: true,
    },
    {
      key: 'registration_number', label: t('people.listing.columns.sap_id'), width: '9rem', isSortable: true,
    },
    {
      key: 'lob', label: t('people.listing.columns.lob'), width: '11rem', isSortable: true,
    },
    {
      key: 'email', label: t('people.listing.columns.email'), width: '32rem', isSortable: true,
    },
    {
      key: 'league', label: t('people.listing.columns.league'), width: '16rem', isSortable: true,
    },
    {
      key: 'job', label: t('people.listing.columns.job'), width: '16rem', isSortable: true,
    },
    {
      key: 'is_in_activity_rate', label: t('people.listing.columns.activity_rate'), width: '13rem', isSortable: true,
    },
    {
      key: 'presence', label: t('people.listing.columns.staff'), width: '13rem', isSortable: true,
    },
    {
      key: 'date_arrival', label: t('people.listing.columns.arrival'), width: '10rem', isSortable: true,
    },
    {
      key: 'date_departure', label: t('people.listing.columns.departure'), width: '10rem', isSortable: true,
    },
    {
      key: 'action', label: isEditable ? t('people.listing.columns.action.edit') : t('people.listing.columns.action.view'), width: '8rem',
    },
  ]
  const peopleWithPagination = [...peopleFilteredBySearchAndSorted.slice(pageStart, pageEnd + 1), ...Array(pageEndBlanks)]
  const navigator = getNavigator()
  return (
    <PeopleListingTableStyled>
      <thead>
        <tr>
          {
            columns.map((column) => {
              let icon = 'sort'
              if (column.key === sortKey) {
                icon = sortIsAscendant ? 'long-arrow-down' : 'long-arrow-up'
              }
              return (
                <th
                  key={column.key}
                  style={{ width: column.width }}
                >
                  <div className="column-content">
                    <span className="column-content-title">{column.label}</span>
                    {
                      column.isSortable && (
                      <button
                        className="column-content-sort"
                        aria-label={`sort ${column.key} column`}
                        type="button"
                        onClick={() => {
                          if (column.isSortable) {
                            toggleSort(column.key)
                          }
                        }}
                      >
                        <i className={`fa fa-${icon}`} />
                      </button>
                      )
                    }
                  </div>
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          peopleWithPagination.map((person, personIndex) => (
            person === undefined
              ? (
                <tr key={`blank line ${pageEnd + personIndex}`} className="blank">
                  <td colSpan={12} />
                </tr>
              )
              : (
                <tr key={person.id} className={personIndex % 2 === 0 ? 'even' : 'odd'}>
                  <td>
                    <Link name={`person ${person.id} askbob profile`} href={`${urlAskbobUsers}/${clean(person.nickname)}`}>
                      {`${person.last_name.toUpperCase()} ${person.first_name}`}
                    </Link>
                  </td>
                  <td>{person.nickname}</td>
                  <td>{person.registration_number}</td>
                  <td>{person.lob}</td>
                  <td>{person.email}</td>
                  <td className={person.is_not_in_a_league ? 'grayed' : ''}>
                    {person.league.toUpperCase()}
                  </td>
                  <td>{person.job}</td>
                  <td>{person.is_in_activity_rate ? t('yes') : t('no')}</td>
                  <td>{t(`people.listing.presence.${person.presence}`)}</td>
                  <td>{person.date_arrival}</td>
                  <td>{person.date_departure}</td>
                  <td>
                    <span className="actions">
                      {
                        isEditable
                          ? (
                            <Link name={`person ${person.id} edit`} href={`/people/${person.id}/edit`}>
                              <i className="fa fa-pencil-square" />
                            </Link>
                          )
                          : (
                            <Link name={`person ${person.id} view`} href={`/people/${person.id}`}>
                              <i className="fa fa-eye" />
                            </Link>
                          )
                      }
                    </span>
                  </td>
                </tr>
              )
          ))
        }
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={12}>
            <span className="navigator">
              <button
                className="arrow"
                type="button"
                onClick={() => navigateToPage(page - 1)}
                disabled={page === 0}
              >
                <i className="fa fa-chevron-left" />
              </button>
              {
                navigator.pages.map((pageNavigation, pageNavigationIndex) => {
                  const pageNavigationParsed = parseInt(pageNavigation, 10)
                  const isCollapse = Number.isNaN(pageNavigationParsed)
                  return (
                    <button
                      key={isCollapse ? `collapse_${pageNavigationIndex}` : pageNavigation}
                      className={['page', pageNavigation === navigator.current ? 'selected' : ''].join(' ')}
                      type="button"
                      onClick={() => navigateToPage(pageNavigationParsed - 1)}
                      disabled={isCollapse}
                    >
                      {pageNavigation}
                    </button>
                  )
                })
              }
              <button
                className="arrow"
                type="button"
                onClick={() => navigateToPage(page + 1)}
                disabled={page === pageLast}
              >
                <i className="fa fa-chevron-right" />
              </button>
            </span>
          </td>
        </tr>
      </tfoot>
    </PeopleListingTableStyled>
  )
}
