import styled from 'styled-components'

export const StyledSide = styled.div`
  padding-top: 3.2rem;
  padding-left: 2rem;
  width: 30%;
  height: 100%;
  border-radius: 4px 0 0 4px;
  background-color: ${({ theme }) => theme.colors.paleGrey3};
  box-sizing: border-box;

  h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
    span {
      font-size: 1.3rem;
      margin-left: 0.5rem;
      font-family: ${({ theme }) => theme.fonts.family.medium};
    }
  }

  div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 3.5rem;
    cursor: pointer;
    p {
      font-family: ${({ theme }) => theme.fonts.family.medium};
      padding-left: 1rem;
    }
    &:hover{
      background-color: ${({ theme }) => theme.colors.paleBlue2}
    }
  }
`
