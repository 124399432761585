import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTimesheetPicker } from './TimesheetPicker.styled'

import NavigationButton from '../Buttons/NavigationButton/NavigationButton'
import Timesheet from './Timesheet/Timesheet'
import { useFetchData } from '../../../fetchOverviewApi'
import { useTimesheetParamsValue } from '../../../context/Timesheet/TimesheetParamsContext'
import { useOnClickOutside } from '../../../hooks/hooks'
import { monthList } from '../../../helpers/datePickerHelper'
import { useTimesheetStateValue } from '../../../context/Timesheet/TimesheetStateContext'
import TimesheetDate from '../../../javascripts/models/TimesheetDate'

const TimesheetPicker = () => {
  const { t } = useTranslation()
  const ref = useRef()
  const [open, setOpen] = useState(false)
  const [nextThreeTimesheets, setNextThreeTimesheets] = useState()
  const [timesheetParams, dispatchTimesheetParams] = useTimesheetParamsValue()
  const timesheetState = useTimesheetStateValue()
  const [loading, hasError, data, handleFetch] = useFetchData(true)
  const isMounted = useRef(true)
  const nbMonthsAllowed = 24

  const ownerEntryTimesheetDate = new TimesheetDate(
    new Date(timesheetState?.owner?.entry_date).getMonth(),
    new Date(timesheetState?.owner?.entry_date).getFullYear(),
  )

  const currentTimesheetDate = new TimesheetDate(
    new Date(Date.now()).getMonth(),
    new Date(Date.now()).getFullYear(),
  )

  const [nextThreeMonths, setNextThreeMonths] = useState(timesheetParams.timesheetDate.getNextThreeMonths())

  useEffect(() => () => {
    isMounted.current = false
  }, [])

  useEffect(() => {
    open && fetchNextThreeTimesheets()
  }, [open, nextThreeMonths])

  useEffect(() => {
    if (data) {
      setNextThreeTimesheets(data)
    } else {
      setNextThreeTimesheets()
    }
  }, [hasError, data])

  const fetchNextThreeTimesheets = () => {
    const timesheets = nextThreeMonths.map((timesheetDate) => (formatTimesheetDateToString(timesheetDate.year, timesheetDate.month)))
    const url = `/timesheet/search/${timesheetState?.owner?.id}/?timesheet_dates=[${timesheets.flat()}]`
    handleFetch(isMounted, url)
  }

  const formatTimesheetDateToString = (year, month) => {
    const monthFormatted = (month + 1).toString().padStart(2, '0')
    return `"${year}-${monthFormatted}"`
  }

  const setNextThreeMonthsFrom = (timesheetDate) => {
    setNextThreeMonths(timesheetDate.getNextThreeMonths())
  }

  const isButtonDisabled = (nbMonths, buttonType) => {
    const initialTimesheetDate = buttonType === 'timesheet'
      ? new TimesheetDate(nextThreeMonths[0].month, nextThreeMonths[0].year)
      : new TimesheetDate(timesheetParams.timesheetDate.month, timesheetParams.timesheetDate.year)

    const newTimesheetDate = initialTimesheetDate.addMonths(nbMonths)

    return !newTimesheetDate.isBetween(ownerEntryTimesheetDate, currentTimesheetDate.addMonths(nbMonthsAllowed))
  }

  const updateMonth = (timesheetDate) => {
    if (timesheetDate.isBetween(ownerEntryTimesheetDate, currentTimesheetDate.addMonths(nbMonthsAllowed))) {
      dispatchTimesheetParams({
        type: 'changeMonth',
        timesheetDate,
      })
    }
  }

  const monthHandler = (nbMonths) => {
    const firstTimesheetDate = Math.abs(nbMonths) === 1
      ? new TimesheetDate(timesheetParams.timesheetDate.month, timesheetParams.timesheetDate.year)
      : new TimesheetDate(nextThreeMonths[0].month, nextThreeMonths[0].year)

    const newTimesheetDate = firstTimesheetDate.addMonths(nbMonths)

    if (Math.abs(nbMonths) === 1) {
      updateMonth(newTimesheetDate)
      if (newTimesheetDate.isBetween(ownerEntryTimesheetDate, currentTimesheetDate.addMonths(nbMonthsAllowed))) setNextThreeMonthsFrom(newTimesheetDate)
    } else {
      setNextThreeMonthsFrom(newTimesheetDate)
    }
  }

  const setCurrentMonth = () => {
    dispatchTimesheetParams({
      type: 'changeMonth',
      timesheetDate: currentTimesheetDate,
    })
    setNextThreeMonthsFrom(currentTimesheetDate)
  }

  useOnClickOutside(ref, () => setOpen(false))

  return (
    <StyledTimesheetPicker ref={ref} open={open}>
      <div className="timesheet-picker-input">
        <a
          className="timesheet-picker-text"
          role="timesheet-picker-text"
          onClick={() => setOpen(!open)}
        >
          {t(`months.${monthList[timesheetParams.timesheetDate.month]}.full`)}
          {' '}
          {timesheetParams.timesheetDate.year}
        </a>
        <NavigationButton
          disabled={isButtonDisabled(-1)}
          type="prev"
          role="prev-month-button"
          click={() => {
            monthHandler(-1)
          }}
        />
        <NavigationButton
          disabled={isButtonDisabled(1)}
          type="next"
          role="next-month-button"
          click={() => {
            monthHandler(1)
          }}
        />
        <NavigationButton
          type="current"
          role="current-month-button"
          title={t('timesheet.backToCurrentMonth')}
          click={() => {
            setCurrentMonth()
          }}
        />
      </div>
      {open && (
        <div className="timesheet-selector" role="timesheet-selector">
          <NavigationButton
            type="prev"
            className="left side"
            role="prev-3-months-button"
            click={() => monthHandler(-3)}
          />
          <NavigationButton
            type="next"
            className="right side"
            role="next-3-months-button"
            click={() => monthHandler(3)}
          />
          {nextThreeMonths.map((timesheetDate, index) => (
            <Timesheet
              disabled={isButtonDisabled(index, 'timesheet')}
              key={`${timesheetDate.month} ${timesheetDate.year}`}
              monthName={t(`months.${monthList[timesheetDate.month]}.full`)}
              monthNum={timesheetDate.month + 1}
              year={timesheetDate.year}
              active={`${timesheetDate.month} ${timesheetDate.year}` === `${timesheetParams.timesheetDate.month} ${timesheetParams.timesheetDate.year}`}
              changeMonth={() => {
                setOpen(false)
                updateMonth(new TimesheetDate(timesheetDate.month, timesheetDate.year))
              }}
              firstPeriodStatus={nextThreeTimesheets?.timesheets?.[index].periods[0]?.status}
              secondPeriodStatus={nextThreeTimesheets?.timesheets?.[index].periods[1]?.status}
              firstPeriodFillRate={nextThreeTimesheets?.timesheets?.[index].periods[0]?.fill_rate}
              secondPeriodFillRate={nextThreeTimesheets?.timesheets?.[index].periods[1]?.fill_rate}
              loading={loading}
            />
          ))}
        </div>
      )}
    </StyledTimesheetPicker>
  )
}

export default TimesheetPicker
