import React from 'react'
import { ProjectActivitiesTimeSpent } from '../ProjectActivitiesTimeSpent/ProjectActivitiesTimeSpent'
import { ProjectExpensesTimeSpentTables } from '../ProjectExpensesTimeSpentTables/ProjectExpensesTimeSpentTables'
import { ProjectActivitiesComsumptionStyled } from './ProjectActivitiesComsumption.styled'

export const ProjectActivitiesComsumption = () => (
  <ProjectActivitiesComsumptionStyled className="octo">
    <ProjectActivitiesTimeSpent />
    <ProjectExpensesTimeSpentTables />
  </ProjectActivitiesComsumptionStyled>
)
