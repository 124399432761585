import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  overflow-y: auto;
  box-sizing: border-box;
  max-height: 100%;
  padding: 1rem 2.6rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.betterway.white};

  >.category {
    display: flex;
    flex: 0 0 5rem;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    font-weight: 700;
    font-variant: normal;
    font-size: 1.5rem;
    text-overflow: ellipsis;
    white-space: pre;
  }

  > button.option {
    display: flex;
    flex: 0 0 5rem;
    flex-flow: column wrap;
    align-items: center;
    justify-content: stretch;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.betterway.transparent};
    color: ${({ theme }) => theme.colors.betterway.primary[100]};
    font-weight: 400;
    font-variant: normal;
    font-size: 1.7rem;
    transition:
      background-color 0.1s ease-in-out,
      color 0.1s ease-in-out;

    .option-content {
      display: flex;
      flex-grow: 1;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      .option-content-title {
        overflow: hidden;
        font-weight: 400;
        font-variant: normal;
        font-size: 1.5rem;
        text-overflow: ellipsis;
        white-space: pre;
      }
    }

    &:not(:last-child) {
      .option-content {
        border-bottom: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[20]};
      }
    }
  }
`
