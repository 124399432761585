import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledPeriod } from './Period.styled'

import ProgressBar from '../../../UI/ProgressBar/ProgressBar'
import Skeleton from '../../../UI/Skeleton/Skeleton'

const Period = (
  {
    loading,
    periodStatus,
    periodFillRate,
    periodRange,
  },
) => {
  const { t } = useTranslation()

  return (
    <StyledPeriod>
      <div className="period">
        <p>{periodRange}</p>
        {
          loading
            ? <Skeleton width="45px" height="10px" lineHeight="10px" />
            : <span className={`status ${periodStatus}`}>{t(`timesheet.status.${periodStatus}`)}</span>
        }
      </div>
      <ProgressBar kind={periodStatus} rate={periodFillRate ? Math.round(periodFillRate * 100) : 0} size="small" />
    </StyledPeriod>
  )
}

export default Period
