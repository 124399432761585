import React from 'react'
import askbobLogo from '../../../../../assets/images/askbob_logo.png'
import { StyledAskbobLogo } from './AskbobLogo.styled'

const AskbobLogo = ({ nickname }) => {
  const askbobUrl = `https://askbob.octo.tools/people/${nickname}`
  return (
    <StyledAskbobLogo href={askbobUrl} target="_blank">
      <img src={askbobLogo} alt="Askbob Logo" title="Profil Askbob" />
    </StyledAskbobLogo>
  )
}

export default AskbobLogo
