import React from 'react'
import { useTranslation } from 'react-i18next'
import { useManagerPeriodsContext } from '../../../context/ManagePeriods/ManagePeriodsContext'
import PeopleListingInputSearchStyled from '../../PeopleListing/PeopleListingInputSearch/PeopleListingInputSearch.styled'

export default function ManagePeriodsSearch({ className }) {
  const { t } = useTranslation()
  const { setSearch } = useManagerPeriodsContext()
  return (
    <PeopleListingInputSearchStyled className={className}>
      <input
        aria-label="people listing search input"
        placeholder={t('managePeriods.search.placeholder')}
        onChange={({ target }) => { setSearch(target.value) }}
      />
      <i className="fa fa-search" />
    </PeopleListingInputSearchStyled>
  )
}
