import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getHashFromDate, monthList } from '../../../../helpers/datePickerHelper'
import Button from '../Button/Button'
import { PeriodPickerStyled } from './PeriodPicker.styled'
import NavigationButton from '../../Buttons/NavigationButton/NavigationButton'

const dateToday = new Date()

export const PeriodPicker = ({ className, onChange }) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const [date, setDate] = useState({ year: dateToday.getFullYear(), month: dateToday.getMonth(), day: dateToday.getDate() })
  const [dateNavigation, setDateNavigation] = useState(date)

  const update = ({ year, month, day }) => {
    setOpen(false)
    setDate({ year, month, day })
    setDateNavigation({ year, month, day })
    onChange({ year, month, day })
  }

  function monthOfDatePickerBlock(dateToChange, monthToAdd) {
    if (dateToChange.month === 11 && monthToAdd >= 1) {
      return { year: dateToChange.year + 1, month: 0, day: dateToChange.day }
    }
    if (dateToChange.month === 0 && monthToAdd <= -1) {
      return { year: dateToChange.year - 1, month: 11, day: dateToChange.day }
    }

    return { year: dateToChange.year, month: dateToChange.month + monthToAdd, day: dateToChange.day }
  }

  function monthOfNavigationButton(dateToChange, monthToAdd) {
    if (monthToAdd === 3) {
      if ([9, 10, 11].includes(dateToChange.month)) {
        return { year: dateToChange.year + 1, month: (dateToChange.month + monthToAdd) % 3, day: dateToChange.day }
      }
      return { year: dateToChange.year, month: dateToChange.month + monthToAdd, day: dateToChange.day }
    }
    if ([0, 1, 2].includes(dateToChange.month)) {
      let newMonth
      switch (dateToChange.month) {
        case 0:
          newMonth = 9
          break
        case 1:
          newMonth = 10
          break
        case 2:
          newMonth = 11
          break
        default:
      }
      return { year: dateToChange.year - 1, month: newMonth, day: dateToChange.day }
    }
    return { year: dateToChange.year, month: dateToChange.month + monthToAdd, day: dateToChange.day }
  }

  function prevPeriod(currentDate) {
    let newDate
    if (currentDate.day <= 15) {
      newDate = monthOfDatePickerBlock(currentDate, -1)
      newDate.day = 16
    } else {
      newDate = monthOfDatePickerBlock(currentDate, 0)
      newDate.day = 1
    }
    update(newDate)
  }

  function nextPeriod(currentDate) {
    let newDate
    if (currentDate.day > 15) {
      newDate = monthOfDatePickerBlock(currentDate, 1)
      newDate.day = 1
    } else {
      newDate = monthOfDatePickerBlock(currentDate, 0)
      newDate.day = 16
    }
    update(newDate)
  }

  useEffect(() => { setDateNavigation(date) }, [date])

  const monthFullname = t(`months.${monthList[date.month]}.full`)
  const monthCapitalize = monthFullname.charAt(0).toUpperCase() + monthFullname.slice(1)
  const currentPeriod = { period: date.day <= 15 ? 1 : 2, month: date.month, year: date.year }
  const dateNavigationPrevious = getHashFromDate(new Date(new Date(dateNavigation.year, dateNavigation.month, dateNavigation.day).setMonth(dateNavigation.month - 1)))
  const dateNavigationNext = getHashFromDate(new Date(new Date(dateNavigation.year, dateNavigation.month, dateNavigation.day).setMonth(dateNavigation.month + 1)))
  return (
    <PeriodPickerStyled className={className}>
      <div className="title">
        {t('managePeriods.periodPicker.title')}
      </div>
      <div className="buttons-period-picker">
        <NavigationButton
          className="navigation-period-button-prev"
          type="chevron"
          ariaLabel="prev period button"
          click={() => prevPeriod(date)}
        />
        <Button
          className="trigger"
          name="period picker trigger"
          text={`${monthCapitalize} ${date.year} - ${date.day <= 15 ? t('managePeriods.periodPicker.firstPeriod') : t('managePeriods.periodPicker.secondPeriod')}`}
          onClick={() => setOpen(!open)}
        />
        <NavigationButton
          className="navigation-period-button-next"
          type="chevron"
          ariaLabel="next period button"
          click={() => nextPeriod(date)}
        />
      </div>
      {
        open && (
        <div className="period-picker-popup">
          <NavigationButton
            className="navigation-popup-button"
            type="prev"
            ariaLabel="prev three month button"
            click={() => setDateNavigation(monthOfNavigationButton(dateNavigation, -3))}
          />
          {
            [dateNavigationPrevious, dateNavigation, dateNavigationNext].map(({ year, month }, key) => (
              <div key={`block ${year}-${month}`} className="block">
                <div className="block-title">{`${t(`months.${monthList[month]}.full`)} ${year}`}</div>
                <Button
                  className={key === 1 && currentPeriod.period === 1 ? 'current-period-button' : 'period-button'}
                  text={t('managePeriods.periodPicker.firstPeriod')}
                  name={`period picker ${year}-${month}-01`}
                  onClick={() => update({ year, month, day: 1 })}
                />
                <Button
                  className={key === 1 && currentPeriod.period === 2 ? 'current-period-button' : 'period-button'}
                  text={t('managePeriods.periodPicker.secondPeriod')}
                  name={`period picker ${year}-${month}-16`}
                  onClick={() => update({ year, month, day: 16 })}
                />
              </div>
            ))
          }
          <NavigationButton
            className="navigation-popup-button"
            type="next"
            ariaLabel="next three month button"
            click={() => setDateNavigation(monthOfNavigationButton(dateNavigation, 3))}
          />
        </div>
        )
      }
    </PeriodPickerStyled>
  )
}
