import React from 'react'
import { CSSTransition } from 'react-transition-group'
import Select, { components } from 'react-select'
import { useTranslation } from 'react-i18next'
import StyledLocationPopin from './LocationPopin.styled'
import { customStyles, iconStyle } from './LocationPopin.styled.custom'

import { useLocationSelector } from '../../../../../../hooks/useLocationSelector'
import Button from '../../../../../input/Buttons/Button/Button'
import Backdrop from '../../../../../UI/Backdrop/Backdrop'

const LocationPopin = ({
  open,
  close,
  toggleLocationPopin,
  dateOfDay,
  fullDate,
}) => {
  const { t } = useTranslation()
  const {
    changeCountry,
    countries,
    selectedCountry,
    changeCity,
    cityOptions,
    selectedCity,
    changeSubCity,
    subCityOptions,
    selectedSubCity,
    updateLocation,
  } = useLocationSelector(open, fullDate)

  return (
    <>
      <Backdrop open={open} close={close} />
      <CSSTransition
        in={open}
        mountOnEnter
        unmountOnExit
        timeout={0}
      >
        <StyledLocationPopin
          onClick={(e) => e.stopPropagation()}
          className="location-popin"
          role={`location-popin-${dateOfDay}`}
        >
          <h5>{t('timesheet.location.interventionArea')}</h5>
          <div className="input" role="country-picker" data-country={selectedCountry?.label}>
            <Select
              components={{ ValueContainer }}
              value={selectedCountry}
              onChange={changeCountry}
              icon="fa fa-globe"
              placeholder={t('timesheet.location.findACountry')}
              styles={customStyles}
              options={countries}
              noOptionsMessage={() => (
                <span>{t('select.activity.noOptionsMessage')}</span>
              )}
            />
          </div>
          <div className="input" role="city-picker" data-city={selectedCity?.label}>
            <Select
              components={{ ValueContainer }}
              onChange={changeCity}
              value={selectedCity}
              icon="fa fa-map-marker"
              placeholder={t('timesheet.location.findACity')}
              styles={customStyles}
              options={cityOptions}
              noOptionsMessage={() => (
                <span>{t('select.activity.noOptionsMessage')}</span>
              )}
            />
          </div>
          {
            subCityOptions.length > 0
            && (
            <div className="input" role="subCity-picker" data-city={selectedSubCity?.label}>
              <Select
                components={{ ValueContainer }}
                onChange={changeSubCity}
                value={selectedSubCity}
                icon="fa fa-map-marker"
                placeholder={t('timesheet.location.findACity')}
                styles={customStyles}
                options={subCityOptions}
                noOptionsMessage={() => (
                  <span>{t('select.activity.noOptionsMessage')}</span>
                )}
              />
            </div>
            )
          }
          <Button
            type="button"
            color="primary"
            disabled={!selectedCountry || !selectedCity || (subCityOptions.length > 0 && !selectedSubCity)}
            click={() => {
              updateLocation()
              toggleLocationPopin()
            }}
          >
            {t('save')}
          </Button>
        </StyledLocationPopin>
      </CSSTransition>
    </>
  )
}

export default LocationPopin

const ValueContainer = ({ children, ...props }) => (
  components.ValueContainer && (
    <components.ValueContainer {...props}>
      {!!children && (
        <i
          className={props.selectProps.icon}
          aria-hidden="true"
          style={iconStyle}
        />
      )}
      {children}
    </components.ValueContainer>
  )
)
