import styled from 'styled-components'

export const StyledButton = styled.button`
  white-space: nowrap;
  text-overflow: ellipsis;
    
  &:disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.grey};
  }

  &.button {
    padding: 0.6rem 2rem;
    background: ${({ theme, color, disabled }) => (disabled ? theme.colors.primaryGreyDark : theme.colors[color])};
    color: ${({ theme }) => theme.colors.white};
    border-style: solid;
    border-width: 2px;
    border-color: ${({ theme, color, disabled }) => (disabled ? theme.colors.primaryGreyDark : theme.colors[color])};
    border-radius: 4.8rem;
    &.outlined {
      background-color: transparent;
      color: ${({ theme, color, disabled }) => (disabled ? theme.colors.primaryGreyDark : theme.colors[color])};
      &:hover {
        background-color: ${({ theme, disabled }) => (disabled ? 'transparent' : theme.colors.paleGrey3)};
      }
    }
    &:hover {
      opacity: ${({ disabled }) => (disabled ? 1 : 0.85)};
    }
  }

  &.text {
    color: ${({ theme, color }) => theme.colors[color]};
    background: none;
    outline: none;
    padding: 0;
    &:hover {
      text-decoration: underline;
    }
    &:disabled {
      cursor: not-allowed;
      color: ${({ theme }) => theme.colors.grey};
      text-decoration: none;
    }
  }

  &.bold {
    font-family: ${({ theme }) => theme.fonts.family.bold};
  }
`
