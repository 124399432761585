import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectServiceAdjustmentBonusHeaderStyled } from './ProjectServiceAdjustmentBonusHeader.styled'
import { getNumberWithUnit, parseFloatWithAnySeparator } from '../../helpers/stringHelper'

export const ProjectServiceAdjustmentBonusHeader = () => {
  const { t } = useTranslation()
  const { project, presalesTextTranslation } = useProjectSalesContext()
  const hasAmount = project && project.service_adjustment_bonus
  const hasPercent = project && project.service_adjustment_bonus_percent

  return (
    <ProjectServiceAdjustmentBonusHeaderStyled>
      {
        (hasAmount || hasPercent)
          ? <span className="text-bold">{t('project.revenue.service_adjustment_bonus.present')}</span>
          : <span>{t(`project.revenue.service_adjustment_bonus.absent.${presalesTextTranslation()}`)}</span>
      }
      {
        hasAmount && (
        <span className="amount text-bold">
          {`${getNumberWithUnit(parseFloatWithAnySeparator(project.service_adjustment_bonus), '€')}`}
          <span className="percent">
            {' '}
            {`(${getNumberWithUnit(parseFloatWithAnySeparator((project.service_adjustment_bonus / project.service) * 100), '%')})`}
          </span>
        </span>
        )
      }
      {
        hasPercent && (
        <span className="amount text-bold">
          {`${getNumberWithUnit(parseFloatWithAnySeparator(project.service_adjustment_bonus_percent * project.service), '€')}`}
          <span className="percent">
            {' '}
            {`(${((project.service_adjustment_bonus_percent * 100) % 1 === 0)
              ? (project.service_adjustment_bonus_percent * 100)
              : (project.service_adjustment_bonus_percent * 100).toFixed(2)}%)`}
          </span>
        </span>
        )
      }
    </ProjectServiceAdjustmentBonusHeaderStyled>
  )
}
