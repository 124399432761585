import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledProgressBar } from './ProgressBar.styled'
import { periodStatuses } from '../../../context/Timesheet/PeriodStateContext'
import { rateKind } from '../../../context/Timesheet/ActivityRateContext'
import Skeleton from '../Skeleton/Skeleton'

const ProgressBar = ({
  rate,
  referenceRate,
  kind,
  size,
  loading,
}) => {
  const { t } = useTranslation()

  const barSize = (size) => {
    switch (size) {
      case 'small':
        return { barHeight: '4', fontSize: '1' }
      case 'medium':
        return { barHeight: '6', fontSize: '1.4' }
      case 'large':
        return { barHeight: '9', fontSize: '1.6' }
      default:
        return { barHeight: '4', fontSize: '1' }
    }
  }

  const barColor = (kind) => {
    switch (kind) {
      case periodStatuses.LOCKED:
        return 'primaryGreyDark'
      case periodStatuses.VALIDATED:
        return 'primary'
      case periodStatuses.NOT_YET_VALIDATED:
        return (rate === 100) ? 'green' : 'yellow'
      case rateKind.SUMMARY:
        return 'yellow'
      case rateKind.AVERAGE:
        return 'orange'
      case rateKind.PRESALES:
        return 'darkPurple'
      case rateKind.PROSPECTION:
        return 'primarySelected'
      case rateKind.INTERNAL:
        return 'darkPink'
      case rateKind.GLOBAL:
        return 'primary'
    }
  }

  return (
    <StyledProgressBar
      color={barColor(kind)}
      size={barSize(size)}
      rate={rate}
      referenceRate={referenceRate}
    >
      {
        loading
          ? (
            <Skeleton
              width="100%"
              height={`${barSize(size)?.barHeight}px`}
            />
          )
          : (
            <div className="progress-bar" role="progress-bar">
              {
              referenceRate
                ? (
                  <>
                    <div className="reference progress-bar"> </div>
                    <div className="rate-reference" role="rate-tooltip">{t('rate.expectedRate', { expectedRate: referenceRate })}</div>
                  </>
                )
                : null
            }
            </div>
          )
      }
      <span>
        {rate}
        %
      </span>
    </StyledProgressBar>
  )
}

export default ProgressBar
