import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  InputRadio, InputSelect, Section, InputSearch, Tooltip, InputNumber, InputText, InputTextarea,
} from '@produits-internes-oss/design-system-components'
import { useDebouncer } from '../../hooks/useDebouncer/useDebouncer'
import { useProjectFormContext } from '../../context/ProjectForm/ProjectFormContext'
import { ProjectFormProposalInProgressStyled } from './ProjectFormProposalInProgress.styled'
import { useSearch } from '../../hooks/useSearch/useSearch'
import { convertHTMLToText } from '../../helpers/stringHelper'

export const ProjectFormProposalInProgress = () => {
  const { t } = useTranslation()
  const { debounce } = useDebouncer()
  const { fetchSearch } = useSearch()
  const [initialValueResponsibleDigital, setInitialValueResponsibleDigital] = useState([])
  const [notKnowResponssibleDigital, setNotKnowResponsibleDigital] = useState(false)

  const { cache, form, updateForm, getProjectName } = useProjectFormContext()

  const responsibleTechOptionsDescription = (optionName) => (
    <div className="option-responsible-tech">
      <p className="name">
        {t(`project.form.responsible_tech.options.${optionName}.name`)}
      </p>
      <p>
        {t(`project.form.responsible_tech.options.${optionName}.description`)}
      </p>
    </div>
  )

  const saveInitialValueResponsibleDigital = (initialValue) => {
    let initialValueRadio = []
    if (!notKnowResponssibleDigital && initialValue.length === 0) {
      initialValueRadio = ['do_not_know']
      setInitialValueResponsibleDigital([...initialValueRadio])
    } else {
      setInitialValueResponsibleDigital([...initialValue])
    }
  }

  const changeResponsibleDigital = (value) => {
    const responsibleTechs = ['responsible_tech', 'optimisation_tech', 'essential_tech', 'redirection_tech']
    let initialValue = []

    setNotKnowResponsibleDigital(false)
    responsibleTechs.map((responsibleTech) => {
      if (value.includes(responsibleTech)) {
        updateForm(responsibleTech, true)
        initialValue = [...initialValue, responsibleTech]
      } else {
        updateForm(responsibleTech, false)
      }
      return ''
    })

    saveInitialValueResponsibleDigital(initialValue)
  }

  useEffect(() => {
    let initialValueRadio = []
    if (form['responsible_tech'].value === true) initialValueRadio = [...initialValueRadio, 'responsible_tech']
    if (form['optimisation_tech'].value === true) initialValueRadio = [...initialValueRadio, 'optimisation_tech']
    if (form['essential_tech'].value === true) initialValueRadio = [...initialValueRadio, 'essential_tech']
    if (form['redirection_tech'].value === true) initialValueRadio = [...initialValueRadio, 'redirection_tech']
    saveInitialValueResponsibleDigital([...initialValueRadio])
  }, [])

  const ResponsibleDigital = useCallback(() => (
    <InputRadio
      multiple="true"
      className="form-control radio radio-inline button-responsible-tech"
      skin="light"
      name="responsible_tech radio"
      options={{
        responsible_tech: responsibleTechOptionsDescription('responsible_tech'),
        optimisation_tech: responsibleTechOptionsDescription('optimisation_tech'),
        essential_tech: responsibleTechOptionsDescription('essential_tech'),
        redirection_tech: responsibleTechOptionsDescription('redirection_tech'),
      }}
      valueInitial={initialValueResponsibleDigital}
      required={form['responsible_tech'].required}
      onChange={(value) => {
        changeResponsibleDigital(value)
      }}
    />
  ), [initialValueResponsibleDigital])

  return (
    <ProjectFormProposalInProgressStyled status="proposal_in_progress">
      <Section className="form-section">
        {form['ResponsibleDigital']}
        <InputSearch
          className="form-control affiliation"
          skin="light"
          name="affiliated project search"
          title={t('project.form.affiliated_project_id')}
          valueInitial={{ key: form['affiliated_project_id'].value, search: form['affiliated_project_name'].value }}
          required={form['affiliated_project_id'].required}
          onSearch={(search) => debounce(() => search.length >= 3 && fetchSearch(search, 'BillableProject', null, (project) => getProjectName(project)), 500)}
          onChange={({ key }) => updateForm('affiliated_project_id', key && parseInt(key, 10))}
        />
        <div className="columns half">
          <div className="column">
            <h3>{t('project.form.competition')}</h3>
            <div className="form-group horizontal competition">
              <InputRadio
                className="form-control radio radio-box"
                skin="light"
                name="success_probability radio"
                title={t('project.form.success_probability')}
                options={Object.fromEntries(cache.successProbabilities.map((probability) => [probability, `${probability}%`]))}
                valueInitial={form['success_probability'].value}
                required={form['success_probability'].required}
                onChange={([value]) => updateForm('success_probability', value ? parseInt(value, 10) : value)}
              />
              <InputRadio
                className="form-control radio"
                skin="light"
                name="competition_type radio"
                title={t('project.form.competition_type')}
                options={Object.fromEntries(cache.competitionTypes.map((type) => [type, t(`project.form.competition_types.${type}`)]))}
                valueInitial={form['competition_type'].value}
                required={form['competition_type'].required}
                onChange={([value]) => updateForm('competition_type', value)}
              />
            </div>
            <div className="form-group">
              <InputRadio
                className="form-control radio radio-inline"
                skin="light"
                name="benext_synergy radio"
                title={t('project.form.benext_synergy')}
                options={Object.fromEntries(cache.benextSynergies.map((synergy) => [synergy, synergy]))}
                valueInitial={form['benext_synergy'].value}
                required={form['benext_synergy'].required}
                onChange={([value]) => updateForm('benext_synergy', value)}
              />
              <InputRadio
                className="form-control radio radio-inline"
                skin="light"
                name="transboundary radio"
                title={t('project.form.transboundary')}
                options={{ true: t('yes'), false: t('no') }}
                valueInitial={form['transboundary'].value?.toString()}
                required={form['transboundary'].required}
                onChange={([value]) => updateForm('transboundary', value === 'true')}
              />
            </div>
            <h3>{t('project.form.geography')}</h3>
            <div className="form-group">
              <InputText
                className="form-control"
                skin="light"
                name="locations text input"
                title={t('project.form.locations')}
                valueInitial={form['locations'].value}
                required={form['locations'].required}
                onChange={(value) => updateForm('locations', value)}
              />
              <InputSelect
                className="form-control"
                skin="light"
                name="camp_lob_id select"
                title={t('project.form.camp_lob_id')}
                options={cache.camps}
                valueInitial={form['camp_lob_id'].value}
                required={form['camp_lob_id'].required}
                onChange={([value]) => updateForm('camp_lob_id', value && parseInt(value, 10))}
              />
              <InputRadio
                className="form-control radio radio-inline"
                skin="light"
                name="travel_abroad radio"
                title={t('project.form.travel_abroad')}
                options={{ yes: t('yes'), no: t('no') }}
                valueInitial={form['travel_abroad'].value}
                required={form['travel_abroad'].required}
                onChange={([value]) => updateForm('travel_abroad', value)}
              />
              <InputRadio
                className="form-control radio radio-inline"
                skin="light"
                name="remote radio"
                title={t('project.form.remote')}
                options={Object.fromEntries(cache.remotes.map((remote) => [remote, t(`project.form.remotes.${remote}`)]))}
                valueInitial={form['remote'].value}
                required={form['remote'].required}
                onChange={([value]) => updateForm('remote', value)}
              />
            </div>
            <h3>
              <span>{t('project.form.responsible_tech.title')}</span>
              <Tooltip
                className="responsible-tech"
                position="top"
                skin="light"
                trigger={({ open, close }) => (
                  <button
                    type="button"
                    aria-label="tooltip trigger button"
                    onMouseEnter={() => open()}
                    onMouseLeave={() => close()}
                  />
                )}
                content={() => (
                  <div>
                    <p>{t('project.form.responsible_tech.tooltip.0')}</p>
                    <p>{t('project.form.responsible_tech.tooltip.1')}</p>
                  </div>
                )}
              />
            </h3>
            <div className="form-group">
              <ResponsibleDigital />
            </div>
          </div>
          <div className="column">
            <h3>{t('project.form.mission')}</h3>
            <div className="form-group">
              <InputRadio
                className="form-control radio radio-box"
                skin="light"
                name="mission_maker radio"
                title={t('project.form.mission_maker')}
                options={Object.fromEntries(cache.missionMakers.map((maker) => [maker, t(`project.form.mission_makers.${maker}`)]))}
                valueInitial={form['mission_maker'].value}
                required={form['mission_maker'].required}
                onChange={([value]) => updateForm('mission_maker', value)}
              />
              <InputNumber
                className="form-control"
                skin="light"
                name="amount_with_accenture number input"
                title={t('project.form.amount_with_accenture')}
                localization={{ invalid: { text: t('project.form.amount_with_accenture_invalid.text'), arialabel: t('project.form.amount_with_accenture_invalid.text') } }}
                valueInitial={form['amount_with_accenture'].value}
                required={form['amount_with_accenture'].required}
                disabled={form['amount_with_accenture'].disabled}
                onCheck={() => form['amount_with_accenture'].error && t('project.form.amount_with_accenture_error_negative')}
                onChange={(value) => updateForm('amount_with_accenture', value)}
              />
              <InputRadio
                className="form-control radio radio-box"
                skin="light"
                name="nature radio"
                title={t('project.form.nature')}
                options={Object.fromEntries(cache.natures.map((nature) => [nature, t(`project.form.natures.${nature}`)]))}
                valueInitial={form['nature'].value}
                required={form['nature'].required}
                onChange={([value]) => updateForm('nature', value)}
              />
            </div>
            <h3>{t('project.form.contact')}</h3>
            <div className="form-group">
              <InputText
                className="form-control"
                skin="light"
                name="decision_maker text input"
                title={t('project.form.decision_maker')}
                valueInitial={form['decision_maker'].value}
                required={form['decision_maker'].required}
                onChange={(value) => updateForm('decision_maker', value)}
              />
            </div>
            <h3>{t('project.form.risks')}</h3>
            <div className="form-group">
              <InputRadio
                className="form-control radio radio-inline"
                skin="light"
                name="operational_risk_level radio"
                title={t('project.form.operational_risk_level')}
                options={{
                  low: t('project.form.operational_risk_levels.low'),
                  medium: t('project.form.operational_risk_levels.medium'),
                  high: t('project.form.operational_risk_levels.high'),
                }}
                valueInitial={form['operational_risk_level'].value}
                required={form['operational_risk_level'].required}
                onChange={([value]) => updateForm('operational_risk_level', value)}
              />
              <InputSelect
                className="form-control"
                skin="light"
                name="operations_domain_ids select"
                title={t('project.form.operations_domain_ids')}
                options={cache.operationsDomains}
                localization={{ validation: { text: t('project.form.operations_domain_ids_save.text'), arialabel: t('project.form.operations_domain_ids_save.arialabel') } }}
                valueInitial={form['operations_domain_ids'].value}
                multiple
                required={form['operations_domain_ids'].required}
                onChange={(value) => updateForm('operations_domain_ids', value.map((id) => parseInt(id, 10)))}
              />
              <InputSelect
                className="form-control"
                skin="light"
                name="risky_country_ids select"
                title={t('project.form.risky_country_ids')}
                options={cache.riskyCountries}
                localization={{ validation: { text: t('project.form.risky_country_ids_save.text'), arialabel: t('project.form.risky_country_ids_save.arialabel') } }}
                valueInitial={form['risky_countries'].value}
                multiple
                required={form['risky_countries'].required}
                onChange={(value) => updateForm('risky_countries', value)}
              />
              <InputRadio
                className="form-control radio radio-inline"
                skin="light"
                name="imply_export_or_transfer radio"
                title={t('project.form.imply_export_or_transfer')}
                options={{ true: t('yes'), false: t('no') }}
                valueInitial={form['imply_export_or_transfer'].value?.toString()}
                required={form['imply_export_or_transfer'].required}
                onChange={([value]) => updateForm('imply_export_or_transfer', value === 'true')}
              />
              <InputRadio
                className="form-control radio radio-inline"
                skin="light"
                name="concern_commercial_laws radio"
                title={t('project.form.concern_commercial_laws')}
                options={{ true: t('yes'), false: t('no') }}
                valueInitial={form['concern_commercial_laws'].value?.toString()}
                required={form['concern_commercial_laws'].required}
                onChange={([value]) => updateForm('concern_commercial_laws', value === 'true')}
              />
              <InputSelect
                className="form-control"
                skin="light"
                name="country_ids select"
                title={t('project.form.country_ids')}
                options={cache.countries}
                localization={{ validation: { text: t('project.form.country_ids_save.text'), arialabel: t('project.form.country_ids_save.arialabel') } }}
                valueInitial={form['country_ids'].value}
                multiple
                required={form['country_ids'].required}
                onChange={(value) => updateForm('country_ids', value)}
              />
            </div>
          </div>
        </div>
      </Section>
      <Section className="form-section">
        <div className="columns">
          <div className="column">
            <div className="form-group">
              <InputTextarea
                className="form-control"
                skin="light"
                name="mission_description textarea"
                title={t('project.form.mission_description')}
                valueInitial={convertHTMLToText(form['mission_description'].value)}
                required={form['mission_description'].required}
                onChange={(value) => updateForm('mission_description', value)}
              />
              <InputTextarea
                className="form-control"
                skin="light"
                name="comment textarea"
                title={t('project.form.comment')}
                valueInitial={form['comment'].value}
                required={form['comment'].required}
                onChange={(value) => updateForm('comment', value)}
              />
            </div>
          </div>
        </div>
        <div className="columns half">
          <div className="column">
            <div className="form-group">
              <InputSelect
                className="form-control"
                skin="light"
                name="publisher_ids search"
                title={t('project.form.publisher_ids')}
                localization={{ validation: { text: t('project.form.publisher_ids_save.text'), arialabel: t('project.form.publisher_ids_save.arialabel') } }}
                options={cache.publishers}
                valueInitial={form['publisher_ids'].value}
                multiple
                required={form['publisher_ids'].required}
                onChange={(value) => updateForm('publisher_ids', value.map((id) => parseInt(id, 10)))}
              />
              <InputRadio
                className="form-control radio radio-inline"
                skin="light"
                name="eligible_cir radio"
                title={t('project.form.eligible_cir')}
                options={{ true: t('yes'), false: t('no') }}
                valueInitial={Boolean(form['eligible_cir'].value).toString()}
                required={form['eligible_cir'].required}
                onChange={([value]) => updateForm('eligible_cir', value === 'true')}
              />
            </div>
          </div>
        </div>
      </Section>
    </ProjectFormProposalInProgressStyled>
  )
}
