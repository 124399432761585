import React from 'react'
import Popup from '../Popup/Popup'
import Button from '../Button/Button'
import Options from '../Options/Options'
import pictoChevronBottom from '../../../../../assets/images/chevronBottom.svg'

export default function Select({
  className, name, title, text, disabled, error, options, onChange,
}) {
  return (
    <Popup
      className={className}
      name={`${name} select`}
      title={title}
      trigger={({ open }) => (
        <Button
          title={title}
          name={`${name} select popup trigger`}
          text={text}
          picto={pictoChevronBottom}
          disabled={disabled}
          error={error}
          onClick={() => open()}
        />
      )}
      content={({ close }) => (
        <Options
          name={`${name} select options`}
          items={options}
          onChange={(option) => {
            onChange(option)
            close()
          }}
        />
      )}
    />
  )
}
