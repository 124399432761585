import styled from 'styled-components'
import pictoNavigationLienExterne from '../../../../assets/images/navigationLienExterne.svg'
import pictoWarning from '../../../../assets/images/warningFull.svg'

export const SynchronizationErrorsStyled = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  padding: 3.2rem;
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
  color: ${({ theme }) => theme.colors.betterway.white};

  >.title {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    height: 4rem;
    margin-bottom: 2.4rem;
    padding-bottom: 0.5rem;
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.betterway.white};
    font-weight: 600;
    font-size: 1.8rem;

    >.warning {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.8rem;
      background-image: url(${pictoWarning});
    }
  }

  >.list {
    position: relative;
    flex-grow: 1;

    >.list-scroll {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-y: auto;

      >.error {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.8rem 1.6rem;
        border-radius: 0.8rem;
        background-color: ${({ theme }) => theme.colors.betterway.white};
        color: ${({ theme }) => theme.colors.betterway.primary[100]};

        &:not(:last-child) {
          margin-bottom: 1.6rem;
        }

        >.error-person {
          flex-grow: 0;
          flex-shrink: 0;

          >.error-person-name {
            font-weight: 600;
            font-size: 1.6rem;
          }

          >.error-person-link {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: flex-start;
            color: inherit;
            text-decoration: none;

            >.error-person-link-nickname {
              font-size: 1.2rem;
              text-decoration: underline;
              text-underline-offset: 0.4rem;
            }

            >.error-person-link-icon {
              width: 1.6rem;
              height: 1.8rem;
              background-color: currentColor;
              mask-image: url(${pictoNavigationLienExterne});
              mask-size: contain;
              mask-repeat: no-repeat;
              mask-position: center;
              mask-clip: border-box;
            }
          }
        }

        >.error-status {
          margin-left: 2rem;
          font-size: 1.4rem;
          text-align: right;
        }
      }
    }
  }
`
