import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon, Tooltip, Modal } from '@produits-internes-oss/design-system-components'
import { useProjectFormContext } from '../../context/ProjectForm/ProjectFormContext'
import { ProjectFormStyled } from './ProjectForm.styled'
import { ProjectFormLead } from '../ProjectFormLead/ProjectFormLead'
import { ProjectFormProposalInProgress } from '../ProjectFormProposalInProgress/ProjectFormProposalInProgress'
import { ProjectFormProposalSent } from '../ProjectFormProposalSent/ProjectFormProposalSent'
import { ProjectFormMissionAccepted } from '../ProjectFormMissionAccepted/ProjectFormMissionAccepted'
import { ProjectFormMissionSigned } from '../ProjectFormMissionSigned/ProjectFormMissionSigned'
import Loader from '../input/_ExportDS/Loader/Loader'

export const ProjectForm = () => {
  const { t } = useTranslation()
  const {
    cache, form, project, fetchCreateOrUpdateProject, fetchDuplicateProjectWithoutActivities, isLoading,
    setIsLoading,
  } = useProjectFormContext()

  if (!project || !cache) {
    return null
  }

  const action = form.id.value ? 'update' : 'create'
  const errors = Object.entries(form).map(([name, { error }]) => error && name).filter(Boolean)
  const requirements = Object.entries(form).map(([name, { required, value }]) => required && (value === null || value === undefined || value?.length === 0) && name).filter(Boolean)
  const isDisabled = isLoading || errors.length > 0 || requirements.length > 0
  const modalContentText = () => (
    <div className="popup-text">
      <p>
        {t('project.form.duplication.popupText.0')}
        <em>{t('project.form.duplication.popupText.1')}</em>
      </p>
      <p>
        {t('project.form.duplication.popupText.2')}
      </p>
    </div>
  )

  const modal = () => (
    <Modal
      skin="light"
      kind="info"
      title="Duplication"
      className="modal-duplication"
      trigger={({ open }) => (
        <Button
          skin="dark"
          name={t(`project.form.${action}.arialabel`)}
          text={t(`project.form.${action}.text`)}
          disabled={isDisabled}
          onClick={() => open()}
        />
      )}
    >
      {modalContentText()}
      <div className="duplication-buttons">
        <Button
          className="without-duplication"
          skin="light"
          kind="secondary"
          name="arialabel"
          text={t('project.form.duplication.no')}
          onClick={async () => {
            setIsLoading(true)
            const id = await fetchDuplicateProjectWithoutActivities()
            if (id) {
              window.location.href = `/projects/billable/${id}`
            } else {
              setIsLoading(false)
            }
          }}
        />
        <Button
          className="with-duplication"
          skin="dark"
          kind="primary"
          name="arialabel"
          text={t('project.form.duplication.yes')}
          onClick={async () => {
            setIsLoading(true)
            const id = await fetchCreateOrUpdateProject()
            if (id) {
              window.location.href = `/projects/billable/${id}`
            } else {
              setIsLoading(false)
            }
          }}
        />
      </div>
    </Modal>
  )

  return (
    <ProjectFormStyled>
      <a className="return" href="/projects/billable">
        <Icon className="return-icon" name="chevron-simple" />
        <span className="return-text">{t('project.form.return')}</span>
      </a>
      <h1 id="title">{t('project.form.title')}</h1>
      <label htmlFor="title">
        <span>{t('project.form.subtitle.0')}</span>
        <span className="asterisk">{t('project.form.subtitle.1')}</span>
        <span>{t('project.form.subtitle.2')}</span>
      </label>
      <div className="forms">
        <ProjectFormLead />
        <ProjectFormProposalInProgress />
        <ProjectFormProposalSent />
        <ProjectFormMissionAccepted />
        <ProjectFormMissionSigned />
      </div>
      <div className="controls form-cta">
        {
          isLoading
            ? <Loader className="loading" />
            : (
              <Tooltip
                className="validate"
                skin="dark"
                position="top"
                trigger={({ open, close }) => (
                  <div onMouseEnter={() => isDisabled && open()} onMouseLeave={() => close()}>
                    {project['duplicate_activities']
                      ? modal()
                      : (
                        <Button
                          skin="dark"
                          name={t(`project.form.${action}.arialabel`)}
                          text={t(`project.form.${action}.text`)}
                          disabled={isDisabled}
                          onClick={async () => {
                            setIsLoading(true)

                            const id = await fetchCreateOrUpdateProject()
                            if (id) {
                              window.location.href = action === 'create' ? `/projects/billable/${id}#sale_tab` : `/projects/billable/${id}`
                            } else {
                              setIsLoading(false)
                            }
                          }}
                        />
                      )}
                  </div>
                )}
                content={() => (
                  <div className="invalid">
                    {
                errors.length > 0 && (
                <div className="invalid-category">
                  <h4>{t('project.form.errors')}</h4>
                  <ul>{errors.map((error) => <li key={error}>{t(`project.form.${error}`)}</li>)}</ul>
                </div>
                )
              }
                    {
                requirements.length > 0 && (
                <div className="invalid-category">
                  <h4>{t('project.form.requirements')}</h4>
                  <ul>{requirements.map((requirement) => <li key={requirement}>{t(`project.form.${requirement}`)}</li>)}</ul>
                </div>
                )
              }
                  </div>
                )}
              />
            )
        }
      </div>
      <p className="tip">{t('project.form.tip')}</p>
    </ProjectFormStyled>
  )
}
