import styled, { css } from 'styled-components'

export const StyledNavigationButton = styled.a`
  ${({ disabled }) => disabled && css`
      opacity: 0.3;
      cursor: not-allowed !important;
  `}

  &,
  :hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
  }
  &.side {
    position: absolute;
    top: 50%;
    background: ${({ theme }) => theme.colors.white};
    &.left {
      transform: translate(-50%, -50%);
      left: 0;
    }
    &.right {
      transform: translate(50%, -50%);
      right: 0;
    }
  }
  
  img {
    width: 2.5rem;
    height: 2.5rem;
  }
  border-radius: 50%;
  cursor: pointer;
`
