import React from 'react'
import { useTranslation } from 'react-i18next'
import { InputSelect, InputText, Section } from '@produits-internes-oss/design-system-components'
import { useProjectFormContext } from '../../context/ProjectForm/ProjectFormContext'
import { ProjectFormProposalSentStyled } from './ProjectFormProposalSent.styled'

export const ProjectFormProposalSent = () => {
  const { t } = useTranslation()
  const { cache, form, updateForm } = useProjectFormContext()

  return (
    <ProjectFormProposalSentStyled status="proposal_sent">
      <Section className="form-section">
        <div className="columns half">
          <div className="column">
            <div className="form-group">
              <InputSelect
                className="form-control"
                skin="light"
                name="qa_director select"
                title={t('project.form.qa_director')}
                options={{ ...cache.qaDirectors, 'other': 'Autre' }}
                valueInitial={Object.keys(cache.qaDirectors).find((id) => cache.qaDirectors[id] === form['qa_director'].value)}
                disabled={form['qa_director'].disabled}
                required={form['qa_director'].required}
                onChange={([value]) => {
                  if (value !== 'other') {
                    updateForm('qa_director', cache.qaDirectors[value])
                    updateForm('other_qa_director', null)
                  } else {
                    updateForm('qa_director', 'other')
                  }
                }}
              />
              {
                !form['other_qa_director'].disabled && (
                  <InputText
                    className="form-control"
                    skin="light"
                    name="other_qa_director text input"
                    title={t('project.form.other_qa_director')}
                    valueInitial={form['other_qa_director'].value}
                    disabled={form['other_qa_director'].disabled}
                    required={form['other_qa_director'].required}
                    onCheck={() => form['other_qa_director'].error && t('project.form.other_qa_director_error_syntax')}
                    onChange={(value) => updateForm('other_qa_director', value)}
                  />
                )
              }
            </div>
          </div>
        </div>
      </Section>
    </ProjectFormProposalSentStyled>
  )
}
