import styled from 'styled-components'

const StyledHappinessIndicatorMondal = styled.div`
position: absolute;
right: -1%;
bottom: 100%;
z-index: 1001;
overflow-y: scroll;
width: 500%;
max-height: 54rem;
padding: 2rem;
border-radius: 4px 4px 0 0;
background-color: ${({ theme }) => theme.colors.white};
box-shadow: 0 1px 1px 0 ${({ theme }) => theme.colors.shadow};
text-align: initial;
cursor: auto;

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(0 0 0 / 50%);
  box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}

::-webkit-scrollbar {
  width: 0.7rem;
  appearance: none;
}

h3 {
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.5rem;
}

ul {
  display: flex;
  flex-direction: column;
  margin: 0%;
}

.project-name {
  align-self: center;
  max-width: 85%;
  padding-bottom: 1rem;
}

li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 1rem 0;
  font-size: 1.2rem;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5.4rem;

    img {
      display: flex;
      max-width: 2rem;
      height: 80%;
      padding: 0.6rem;
      border: 2pt solid ${({ theme }) => theme.colors.primaryBlue};
      border-radius: 12px;
      background: ${({ theme }) => theme.colors.primaryBlue};
      font-family: ${({ theme }) => theme.fonts.family.bold};
    }

    li:nth-child(odd) { background: ${({ theme }) => theme.colors.paleGrey3}; }

    p {
      color: ${({ theme }) => theme.colors.primaryBlue};
      font-size: 1.1rem;
      text-align: center;
    }
  }
}
`
export default StyledHappinessIndicatorMondal
