import styled from 'styled-components/macro'

export const StyledDateRangePickerSimplified = styled.div`
  position: relative;
  margin: 0 2rem;

  ul.nav.nav-tabs li a {
    outline: 0;
  }

  .tab-content {
    overflow: visible;
  }

  .submitButton {
    width: 6rem;
    height: 3rem;
    margin-left: 0.8rem;
    border-radius: 0.3rem;
    color: #fff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
    background: #6ab756
  }
`
