import styled from 'styled-components'

export const ProjectCoordinationScreenStyled = styled.div`
  .copy {
    width: fit-content;
    margin-bottom: 1.5rem;

    &.octo.hard.default {
      > button {
        padding: 0;
        border: none;
        background: none;
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
        font-weight: normal;
        font-size: 1.4rem;
        text-decoration: underline;

        &:hover {
          background: none;
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          font-weight: bold;
        }

        &:focus,
        &:focus-visible {
          border: none;
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
        }

        ::before {
          content: none;
        }

        >.icon {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }

  .reporting {
    margin: 3rem 0;
  }

  .expenses {
    margin: 3rem 0;

    .content {
      display: flex;
      flex-flow: column nowrap;
      gap: 1.5em;
      align-items: stretch;
      justify-content: stretch;
    }
  }
`
