export function request(url, options) {
  return fetch(url, options).then((data) => data.json())
}

export function requestWithCSRFToken(url, options) {
  const csrfToken = document.querySelector('[name=csrf-token]').content

  options.headers = {
    'X-CSRF-TOKEN': csrfToken,
    'Content-Type': 'application/json',
  }
  return fetch(url, options).then((data) => data.json())
}
