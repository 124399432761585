import styled from 'styled-components'

export default styled.tfoot`
  box-shadow: 0px -2px 4px rgba(14, 35, 86, 0.12);

  th {
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lighterGray};
  }

  th:first-child {
    z-index: ${({ openActivityRatePopin }) => (openActivityRatePopin ? 1001 : 1)};
  }

  th:last-child {
    z-index: ${({ openHappinessIndicatorModal }) => (openHappinessIndicatorModal ? 1001 : 1)};
  }

  tr {
    th:first-child {
      font-size: 1.8rem;
      background: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
      border-radius: 0 0 0 5px;
      cursor: pointer
    }
    th:nth-child(3) {
      border-left: 1px solid ${({ theme }) => theme.colors.grey};
      border-right: 1px solid ${({ theme }) => theme.colors.grey};
    }
    th:last-child {
      background: ${({ theme }) => theme.colors.white};
      border-radius: 0 0 5px 0;
      border-right: 1px solid ${({ theme }) => theme.colors.lighterGray};
    }
  }

  .tace {
    padding-left: 1.5rem;
    display: flex;
    align-items: center;
  }

  .thi {
    all: unset;
    font-size: 1.3rem;
    cursor: pointer
  }
`
