import React from 'react'
import { useTranslation } from 'react-i18next'
import { useExpensesContext } from '../../context/Expenses/ExpensesContext'
import PersonPicker from '../input/PersonPicker/PersonPicker'
import { PeriodPicker } from '../input/_ExportDS/PeriodPicker/PeriodPicker'
import Button from '../input/_ExportDS/Button/Button'
import ExpensesDetails from '../ExpensesDetails/ExpensesDetails'
import ExpensesListStyled from './ExpensesList.styled'
import imageRocket from '../../../assets/images/rocket.svg'
import pictoAdd from '../../../assets/images/add.svg'
import imageQRCodeNDFProduction from '../../../assets/images/octo_qrcode_ndf_prod.png'
import imageQRCodeNDFDemo from '../../../assets/images/octo_qrcode_ndf_demo.png'
import { getUserDevice } from '../../helpers/deviceHelper'
import SelectEnum from '../input/_ExportDS/SelectEnum/SelectEnum'
import DatePicker from '../input/_ExportDS/DatePicker/DatePicker'

export default function ExpensesList() {
  const { t } = useTranslation()
  const {
    year,
    month,
    period,
    person,
    expenses,
    totalAmount,
    setViewToCreate,
    fetchGetPersonExpensesForPeriod,
    isPeriodCurrent,
  } = useExpensesContext()

  const QRCodeImage = window.location.hostname === 'octopod.octo.com' ? imageQRCodeNDFProduction : imageQRCodeNDFDemo
  return (
    <ExpensesListStyled QRCodeImage={QRCodeImage}>
      <div className="expense-header-img" />
      {
        <PersonPicker
          className="person"
          value={person}
          onChange={(value) => fetchGetPersonExpensesForPeriod(year, month, period, value.selectedValue)}
          withPeopleLeft
        />
      }

      {
        person
          ? (
            <>
              <h1>{t('expenses.title')}</h1>
              {
                getUserDevice() === 'mobile'
                  ? (
                    <div className="date">
                      <DatePicker
                        name="expenses"
                        value={new Date(year, month)}
                        yearStart={2013}
                        yearEnd={new Date().getFullYear()}
                        detail="year"
                        onChange={(value) => fetchGetPersonExpensesForPeriod(value.getFullYear(), value.getMonth(), period, person.id)}
                      />
                      <SelectEnum
                        name="expenses period"
                        value={period === 0 ? 'period1' : 'period2'}
                        items={[{ name: 'period1', title: 'Période 1' }, { name: 'period2', title: 'Période 2' }]}
                        onChange={(item) => fetchGetPersonExpensesForPeriod(year, month, item.name === 'period1' ? 0 : 1, person.id)}
                      />
                    </div>
                  )
                  : (
                    <PeriodPicker
                      className="date"
                      onChange={(value) => fetchGetPersonExpensesForPeriod(value.year, value.month, value.day === 16 ? 1 : 0, person.id)}
                    />
                  )
              }
              <div className="balance">
                <div className="total">
                  <div className="total-title">{t('expenses.total')}</div>
                  <div className="total-amount">{totalAmount.toFixed(2).replace('.', '€')}</div>
                </div>
                <img src={imageRocket} alt="octo rocket" />
              </div>
              {
                isPeriodCurrent
                && <Button className="add" name="add expense" text={t('expenses.add')} picto={pictoAdd} onClick={() => setViewToCreate()} />
              }
              <div className="expenses">
                {
                  expenses.length > 0
                    ? (
                      <>
                        <div className="expenses-title">Détails</div>
                        {
                          expenses.map((expense) => (
                            <ExpensesDetails className="expenses-item" key={expense.getValue('id')} expense={expense} />
                          ))
                        }
                      </>
                    )
                    : ''
                }
              </div>
            </>
          )
          : <div />
      }
    </ExpensesListStyled>
  )
}
