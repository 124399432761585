import styled from 'styled-components'
import destroyFull from '../../../assets/images/destroyFull.svg'

export const ProjectAddPresalesStyled = styled.div`
  .form-search {
    display: flex;
    gap: 1.6rem;
    width: 65rem;
    margin-bottom: 2.4rem;
  }

  >ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    .row-person-presale {
      display: flex;
      align-items: center;
      width: 100%;
      height: 6.8rem;
    }

    .even {
      background-color: none;
    }

    .odd {
      background-color: ${({ theme }) => theme.colors.betterway.white};
    }

    .cell-person-presale {
      display: flex;
      align-items: end;
      justify-content: start;
      width: 27rem;
      height: fit-content;
      margin-left: 1.6rem;

      .name {
        width: fit-content;

        a {
          display: flex;
          gap: 1rem;
          align-items: center;
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          font-weight: 400;
          font-size: 1.4rem;
          text-decoration: underline;
          cursor: pointer;

          img {
            width: 2.1rem;
            height: 2.1rem;
            margin-left: 0.4rem;
          }
        }
      }

      .destroy {
        width: auto;
        margin-right: 1rem;

        button {
          height: fit-content;
          padding: 0;
          border: unset;
          background-color: unset;

          &:hover {
            border: none;
            background-image: url(${destroyFull});
            mask-image: url(${destroyFull});
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .total {
      margin-top: 1rem;

      em {
        font-weight: 700;
      }
    }

    .time-in-days {
      display: flex;

      .total-days {
        display: flex;
        gap: 0.5rem;
        margin-left: 0.5rem;
        font-weight: 700;
      }
    }
  }
`
