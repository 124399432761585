import styled from 'styled-components'

export default styled.div`
  &.eds-button {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: stretch;
    width: 100%;

    >.title {
      margin-bottom: 0.3rem;
      color: ${({ theme }) => theme.colors.betterway.primary[70]};
      font-weight: 400;
      font-style: normal;
      font-size: 1.7rem;
    }

    > button {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 100%;
      height: 4.2rem;
      padding: 0 1.6rem;
      border: 1px solid ${({ theme }) => theme.colors.betterway.primary[100]};
      border-radius: 2.1rem;
      background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
      color: ${({ theme }) => theme.colors.betterway.white};
      font-weight: 600;
      font-variant: normal;
      font-size: 1.6rem;
      cursor: pointer;
      user-select: none;
      transition:
        background-color 0.1s ease-in-out,
        border-color 0.1s ease-in-out,
        color 0.1s ease-in-out;

      &:disabled {
        border-color: ${({ theme }) => theme.colors.betterway.primary[50]};
        background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
        cursor: not-allowed;
      }

      >.text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
      }

      >.picto {
        width: 2.4rem;
        height: 2.4rem;
        background-image: ${(props) => `url(${props.picto}) `};
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-clip: border-box;
      }
    }

    &.error {
      > button {
        border-color: ${({ theme }) => theme.colors.betterway.red[100]};
      }

      >.description {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;

        >.description-icon {
          width: 1.5rem;
          height: 1.5rem;
          margin: 0 0.4rem 0 0;
          background-color: ${({ theme }) => theme.colors.betterway.red[100]};
          mask-image: ${(props) => `url(${props.pictoWarning}) `};
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
          mask-clip: border-box;
        }

        >.description-text {
          color: ${({ theme }) => theme.colors.betterway.red[100]};
          font-weight: 400;
          font-style: normal;
          font-size: 1.4rem;
        }
      }
    }
  }
`
