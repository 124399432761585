import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectServiceStyled } from './ProjectService.styled'
import Input from '../input/_ExportDS/InputWithStyle/Input'
import Button from '../input/_ExportDS/ButtonWithStyle/Button'
import pictoEdit from '../../../assets/images/editBlue.svg'
import { parseFloatWithAnySeparator } from '../../helpers/stringHelper'

export const ProjectService = () => {
  const { t } = useTranslation()
  const { project, errors, fetchUpdateService, isProjectPresales } = useProjectSalesContext()

  const [service, setService] = useState(null)
  const [isEditable, setIsEditable] = useState(false)

  useEffect(() => {
    if (project !== null) {
      const isServicePresent = project.service !== undefined && project.service !== null

      let hasErrors = false

      if (errors !== null && errors.service) {
        hasErrors = true
      }
      setService(project.service)
      setIsEditable(!isServicePresent || hasErrors)
    }
  }, [project, errors])

  if (project === null) {
    return <div>...</div>
  }

  return (
    isProjectPresales() && (
      <ProjectServiceStyled>
        <div className="service-content-left">
          <div className="input-group">
            <Input
              className="value"
              name={t('project.revenue.service.value.arialabel')}
              title={t('project.revenue.service.value.text')}
              value={service}
              type="number"
              disabled={!isEditable || (project.kind === 'cost_reimbursable' && project.status.current !== 'lead')}
              onChange={(value) => setService(parseFloatWithAnySeparator(value))}
              onValidate={() => service >= 0 && (Number.isInteger(service)) && fetchUpdateService(service)}
            />
            <i className={!isEditable ? 'disabled-input' : ''}>€</i>
          </div>
        </div>
        <div>
          {
            isEditable
              ? (
                <Button
                  className="create"
                  name={t('project.revenue.service.create.arialabel')}
                  text={t('project.revenue.service.create.text')}
                  disabled={Number.isNaN(service) || service < 0 || (project.kind === 'cost_reimbursable' && project.status.current !== 'lead')}
                  onClick={() => fetchUpdateService(service)}
                />
              )
              : (
                <Button
                  className="edit"
                  name={t('project.revenue.service.edit.arialabel')}
                  text={t('project.revenue.service.edit.text')}
                  picto={pictoEdit}
                  onClick={() => setIsEditable(true)}
                  disabled={project.kind === 'cost_reimbursable' && project.status.current !== 'lead'}
                />
              )
          }
        </div>
      </ProjectServiceStyled>
    )
  )
}
