import styled from 'styled-components'

export const StyledActivityInfo = styled.div`
  a {
    font-family: ${({ theme }) => theme.fonts.family.medium};
    font-size: 1.3rem;
    line-height: 20px;
    cursor: pointer;
    color: inherit;
  }

  h4 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.primaryGrey};
    letter-spacing: 1px;
    font-size: 1.2rem;
  }

  span.icon {
    text-align: center;
    display: block;
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }

  i {
    color: ${({ theme }) => theme.colors.primaryGrey};
    &.fa-trash {
      color: ${({ theme, notDeletable }) => (notDeletable ? theme.colors.grey : theme.colors.blockingError)};
    }
  }
  
  button {
    font-size: 1.2rem;
  }
  
  .activity-title {
    margin: 1.2rem 0;
  }

  .project-dates {
    letter-spacing: .5px;
    color: ${({ theme }) => theme.colors.primaryGrey};
    margin: 1.2rem 0 2rem 0;
  }

  .row-info {
    margin: .5rem 0;
    display: flex;
    justify-content: space-between;
    text-align: left;
  }

  .info {
    display: flex;
    align-items: center;
    width: 50%;
  }

  .delete-activity {
    margin-top: 1.2rem;
  }

  .delete-warning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    clip-path: polygon(0 15%, 14% 15%, 16% 0%, 18% 15%, 100% 15%, 100% 100%, 0 100%);
    padding: 2rem 2rem 1rem 2rem;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 4px 8px 0 ${({ theme }) => theme.colors.shadow};
    position: absolute;
    top: 94%;
    left: 0;
    width: 55rem;
    background-color: ${({ theme }) => theme.colors.blockingErrorLight};
    font-family: ${({ theme }) => theme.fonts.family.bold};
  }
`
