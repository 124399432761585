import React from 'react'
import { useTranslation } from 'react-i18next'
import { InputRadio, Section } from '@produits-internes-oss/design-system-components'
import { useProjectFormContext } from '../../context/ProjectForm/ProjectFormContext'
import { ProjectFormMissionSignedStyled } from './ProjectFormMissionSigned.styled'

export const ProjectFormMissionSigned = () => {
  const { t } = useTranslation()
  const { form, updateForm } = useProjectFormContext()

  return (
    <ProjectFormMissionSignedStyled status="mission_signed">
      <Section className="form-section">
        <div className="columns half">
          <div className="column">
            <div className="form-group">
              <InputRadio
                className="form-control radio radio-inline"
                skin="light"
                name="invoiceable_fees radio"
                title={t('project.form.invoiceable_fees')}
                options={{ true: t('yes'), false: t('no') }}
                valueInitial={form['invoiceable_fees'].value?.toString()}
                required={form['invoiceable_fees'].required}
                onChange={([value]) => updateForm('invoiceable_fees', value === 'true')}
              />
            </div>
          </div>
        </div>
      </Section>
    </ProjectFormMissionSignedStyled>
  )
}
