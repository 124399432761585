import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTurnoverRow } from './TurnoverRow.styled'

const TurnoverRow = ({ data, currency_code }) => {
  const { i18n } = useTranslation()
  const locale = i18n.language

  return (
    <StyledTurnoverRow StyledTurnoverRow>
      <td className="firstCol"><div>{data.name}</div></td>
      <td colSpan={9} className="empty" />
      <td><div>{new Intl.NumberFormat(locale, { style: 'currency', currency: currency_code }).format(data.turnover)}</div></td>
      <td><div>{new Intl.NumberFormat(locale, { style: 'currency', currency: currency_code }).format(data.brokendown_turnover)}</div></td>
      <td colSpan={2} className="empty" />
    </StyledTurnoverRow>
  )
}

export default TurnoverRow
