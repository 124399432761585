import React, { useState, useContext, createContext } from 'react'
import { clean, cleanWithPunctuation } from '../../helpers/stringHelper'
import { useFetch } from '../../fetchOverviewApi'

const SubcontractingContext = createContext()

export const SubcontractingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [entries, setEntries] = useState([])
  const [filterValues, setFilterValues] = useState({
    project: '',
    subcontractor: '',
    action: 'none',
    form: 'none',
  })

  const fetchEntries = async (url) => {
    setIsLoading(true)

    const response = await useFetch('GET', `${url}`)
    setEntries(response.errors ? [] : response)
    setIsLoading(false)
  }

  const findEntry = (entryKey) => entries.find((entry) => entry?.subcontractor_index === entryKey)

  const updateEntry = (entryKey, entryValue) => {
    const dataUpdated = [...entries]
    dataUpdated[entryKey] = entryValue
    setEntries(dataUpdated)
  }

  const updateEntryBill = (entryKey, attributeKey, attributeValue) => {
    const entry = { ...findEntry(entryKey) }
    if (entry.subcontractor_bill) {
      entry.subcontractor_bill[attributeKey] = attributeValue
      updateEntry(entryKey, entry)
    } else {
      throw new ReferenceError(`Could not find an entry with key ${entryKey}`)
    }
  }

  const doesEntryHaveABillBadFunctionMustBeRemovedWhenBackIsRefactored = (entry) => {
    const toReviewIsTrue = entry.subcontractor_bill.to_review === true
    const toReviewIsNull = entry.subcontractor_bill.to_review === null
    const projectExpenseInputIDExists = entry.project_expense_input.id !== null
    return toReviewIsTrue || (toReviewIsNull && projectExpenseInputIDExists)
  }

  const updateFilterValue = (filter, value) => setFilterValues({ ...filterValues, [filter]: value })

  const filters = {
    project:
      (entry, toMatch) => clean(entry.project_expense_input.project).includes(clean(toMatch)),
    subcontractor:
      (entry, toMatch) => clean(entry.project_expense_input.name).includes(clean(toMatch)),
    action:
      (entry, toMatch) => {
        if (toMatch === 'to_review') {
          return doesEntryHaveABillBadFunctionMustBeRemovedWhenBackIsRefactored(entry)
        }
        if (toMatch === 'reviewed') {
          return entry.subcontractor_bill.to_review === false
        }
        if (toMatch === 'empty') {
          return entry.project_expense_input.id === null
        }
        return true
      },
    form:
      (entry, toMatch) => {
        if (toMatch === 'form') {
          return entry.subcontractor_form_input.form_link !== null
        }
        if (toMatch === 'email') {
          return entry.subcontractor_form_input.form_link === null
        }
        return true
      },
    expenseQuantity:
        (entry, toMatch) => {
          if (toMatch === 'empty') {
            return entry.subcontractor_bill['expense_quantity'] === null || entry.subcontractor_bill['expense_quantity'] === ''
          }
          if (toMatch === 'not-empty') {
            return entry.subcontractor_bill['expense_quantity'] !== null && entry.subcontractor_bill['expense_quantity'] !== ''
          }
          return true
        },
    miscExpenseQuantity:
        (entry, toMatch) => {
          if (toMatch === 'empty') {
            return entry.subcontractor_bill['misc_expense_quantity'] === null || entry.subcontractor_bill['misc_expense_quantity'] === ''
          }
          if (toMatch === 'not-empty') {
            return entry.subcontractor_bill['misc_expense_quantity'] !== null && entry.subcontractor_bill['misc_expense_quantity'] !== ''
          }
          return true
        },
    unitPrice:
        (entry, toMatch) => {
          if (toMatch === 'empty') {
            return entry.subcontractor_bill['unit_price'] === null || entry.subcontractor_bill['unit_price'] === ''
          }
          if (toMatch === 'not-empty') {
            return entry.subcontractor_bill['unit_price'] !== null && entry.subcontractor_bill['unit_price'] !== ''
          }
          return true
        },
  }

  const sortings = {
    subcontractorLastname:
      (entry1, entry2) => cleanWithPunctuation(entry1.project_expense_input.last_name)
        .localeCompare(cleanWithPunctuation(entry2.project_expense_input.last_name)),
    projectName:
      (entry1, entry2) => cleanWithPunctuation(entry1.project_expense_input.project_name)
        .localeCompare(cleanWithPunctuation(entry2.project_expense_input.project_name)),
    date:
      (entry1, entry2) => new Date(`1 ${entry1.project_expense_input.month}`).getTime()
        - new Date(`1 ${entry2.project_expense_input.month}`).getTime(),
  }

  const filterData = () => entries
    .filter((subcontractingEntry) => Object.entries(filterValues)
      .map(([filterKey, filterValue]) => filters[filterKey](subcontractingEntry, filterValue))
      .every((result) => result))

  const filteredAndSortedData = () => filterData()
    .sort((project1, project2) => sortings.date(project1, project2)
      || sortings.projectName(project1, project2)
      || sortings.subcontractorLastname(project1, project2))

  return (
    <SubcontractingContext.Provider value={
      {
        isLoading,
        entries,
        fetchEntries,
        findEntry,
        updateEntry,
        updateEntryBill,
        filterValues,
        updateFilterValue,
        doesEntryHaveABillBadFunctionMustBeRemovedWhenBackIsRefactored,
        filteredAndSortedData,
      }
    }
    >
      {children}
    </SubcontractingContext.Provider>
  )
}

export const useSubcontracting = () => useContext(SubcontractingContext)
