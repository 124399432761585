import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../../../UI/Modal/Modal'

import StyledActivityInputWarningModal from './ActivityInputWarningModal.styled'

const ActivityInputWarningModal = ({ open, close }) => {
  const { t } = useTranslation()

  return (
    <Modal open={open} close={close} height="100px" width="750px">
      <StyledActivityInputWarningModal>
        <h3>
          {t('timesheet.inputWarining')}
        </h3>
      </StyledActivityInputWarningModal>
    </Modal>
  )
}

export default ActivityInputWarningModal
