import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectContingencyExplanationStyled } from './ProjectContingencyExplanation.styled'

export const ProjectContingencyExplanation = () => {
  const { t } = useTranslation()
  const { project } = useProjectSalesContext()

  const isPrefilledContingency = () => (project
    && ['delivery', 'consulting'].includes(project.nature)
    && ['fixed_price', 'cost_reimbursable'].includes(project.kind)
  )

  const defaultPercentage = () => (isPrefilledContingency()
    ? t(`project.costs.contingency.explanation.${project.nature}.${project.kind}`)
    : '')

  return (
    isPrefilledContingency()
      ? (
        <ProjectContingencyExplanationStyled className="info-contingency">
          <div>
            <p className="explanation-text">
              {t('project.costs.contingency.explanation.first.0')}
              <em>{t('project.costs.contingency.explanation.first.1')}</em>
              {t('project.costs.contingency.explanation.first.2')}
              <em>{ defaultPercentage() }</em>
            </p>
          </div>
        </ProjectContingencyExplanationStyled>
      )
      : ''
  )
}
