import { useTranslation } from 'react-i18next'

export const milliSecPerMin = 1000 * 60
export const milliSecPerDay = milliSecPerMin * 60 * 24
export const milliSecPerYear = milliSecPerDay * 365

export const monthList = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]

export const dayList = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]

export const getMonthOptions = () => monthList.map((month, index) => {
  const { t } = useTranslation()
  return { value: index, label: t(`months.${month}.short`) }
})

export const getYearRangeOptions = (start, end) => (start <= end
  ? [...Array(end - start + 1).keys()].map((year) => ({
    value: start + year,
    label: start + year,
  }))
  : [])

export const todayTimestamp = () => Date.now()
  - (Date.now() % milliSecPerDay)
  + new Date().getTimezoneOffset() * milliSecPerMin

export const currentMonthFirstDayTimestamp = () => new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
).getTime()

export const getMonthFirstDay = (date) => new Date(date.getFullYear(), date.getMonth(), 1)

export const getMonthLastDay = (date) => new Date(date.getFullYear(), date.getMonth() + 1, 0)

export const monthFirstDayTimestamp = (date) => getMonthFirstDay(date).getTime()

export const monthLastDayTimestamp = (date) => getMonthLastDay(date).getTime()

export const yearQuarters = (startYear, startMonth) => (
  [
    {
      startDate: monthFirstDayTimestamp(new Date(startYear, startMonth)),
      endDate: monthLastDayTimestamp(new Date(startYear, startMonth + 2)),
      value: 1,
    },
    {
      startDate: monthFirstDayTimestamp(new Date(startYear, startMonth + 3)),
      endDate: monthLastDayTimestamp(new Date(startYear, startMonth + 5)),
      value: 2,
    },
    {
      startDate: monthFirstDayTimestamp(new Date(startYear, startMonth + 6)),
      endDate: monthLastDayTimestamp(new Date(startYear, startMonth + 8)),
      value: 3,
    },
    {
      startDate: monthFirstDayTimestamp(new Date(startYear, startMonth + 9)),
      endDate: monthLastDayTimestamp(new Date(startYear, startMonth + 11)),
      value: 4,
    },
  ]
)

const twoDigits = (number) => (number < 10 ? `0${number}` : number)
export const getDateStringFromTimestamp = (timestamp) => {
  const dateObject = new Date(timestamp)
  const month = dateObject.getMonth() + 1
  const date = dateObject.getDate()
  const year = dateObject.getFullYear()

  return [twoDigits(date), twoDigits(month), year].join('/')
}

export const getDurationInYear = (dateStart, dateEnd) => (dateEnd - dateStart) / milliSecPerYear

export const dateWithoutTimezone = (date) => new Date(date - (date.getTimezoneOffset() * 60000))

export const getHashFromDate = (date) => ({ year: date.getFullYear(), month: date.getMonth(), day: date.getDate() })

export const getMonthRange = (start, end) => {
  if (!start || !end || !Number.isFinite(start.getTime()) || !Number.isFinite(end.getTime()) || start > end) return []

  const difference = ((end.getFullYear() - start.getFullYear()) * 12) + end.getMonth() - start.getMonth() + 1
  return Array.from(Array(difference).keys()).map((offset) => new Date(start.getFullYear(), start.getMonth() + offset, 1))
}
