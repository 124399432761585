export default class TimesheetDate {
  constructor(month, year) {
    this.month = month
    this.year = year
  }

	addMonths = (nbMonths) => new TimesheetDate(
	  new Date(this.year, this.month + nbMonths).getMonth(),
	  new Date(this.year, this.month + nbMonths).getFullYear(),
	)

	getNextThreeMonths = () => ([
	  this.addMonths(0),
	  this.addMonths(1),
	  this.addMonths(2),
	])

	isBetween = (startTimesheetDate, endTimesheetDate) => {
	  const isAfterStartTimesheetDate = new Date(startTimesheetDate.year, startTimesheetDate.month) <= new Date(this.year, this.month)
	  const isBeforeEndTimesheetDate = new Date(endTimesheetDate.year, endTimesheetDate.month) >= new Date(this.year, this.month)

	  return isAfterStartTimesheetDate && isBeforeEndTimesheetDate
	}
}
