import styled from 'styled-components'

export const StyledPeriod = styled.div`
  p {
    font-size: 1.2rem;
    margin: 2px 0;
  }

  .status {
    padding: 4px;
    line-height: .5;
    font-size: 1rem;
    border-radius: 2px;
    &.not_yet_validated {
      background-color: ${({ theme }) => theme.colors.lightYellow};
      color: ${({ theme }) => theme.colors.yellow};
    }
    &.validated {
      background-color: ${({ theme }) => theme.colors.primaryBlueLight};
      color: ${({ theme }) => theme.colors.primaryBlue};
    }
    &.locked {
      background-color: ${({ theme }) => theme.colors.primaryGreyLight};
      color: ${({ theme }) => theme.colors.primaryGrey};
    }
  }

  .period {
    display: flex;
    align-items: center;
    justify-content: left;
  }
`
