import React, { useState, useEffect } from 'react'
import OptionsStyled from './Options.styled'

export default function Options({ name, items, itemSelectedInitial, onChange }) {
  const [itemSelected, setItemSelected] = useState(itemSelectedInitial)
  const [itemsCategorized, setItemsCategorized] = useState({ categories: {}, options: [] })

  const createCategories = () => {
    let offset = 0
    const categories = {}
    const options = items.flatMap((item, itemKey) => {
      if (item.children) {
        categories[itemKey + offset] = item
        offset += item.children.length - 1
        return item.children
      }

      return item
    })

    setItemsCategorized({ categories, options })
  }

  useEffect(() => {
    createCategories()
  },
  [items])

  return (
    <OptionsStyled>
      {
        itemsCategorized.options.map((option, optionKey) => {
          const category = itemsCategorized.categories[optionKey]
          return [
            category && <div key={`category ${category.name}`} className="category">{category.title}</div>,
            <button
              key={option.name}
              className={['option', optionKey === itemSelected ? 'selected' : null].filter(Boolean).join(' ')}
              type="button"
              aria-label={`${name} item ${option.name}`}
              onClick={() => {
                setItemSelected(optionKey)
                onChange(itemsCategorized.options[optionKey])
              }}
            >
              <div className="option-content">
                <div className="option-content-title">
                  {option.title}
                </div>
              </div>
            </button>,
          ]
        })
      }
    </OptionsStyled>
  )
}
