import React from 'react'
import { ThemeProvider } from 'styled-components'
import theme from '../../../style/theme'
import GlobalStyle from '../../../style/globalStyle'
import { ManagePeriodsContextProvider } from '../../context/ManagePeriods/ManagePeriodsContext'
import ManagePeriods from '../../components/ManagePeriods/ManagePeriods'

export default function ManagePeriodsScreen({ personIsAdmin }) {
  return (
    <ThemeProvider theme={theme}>
      <ManagePeriodsContextProvider>
        <GlobalStyle />
        <ManagePeriods personIsAdmin={personIsAdmin} />
      </ManagePeriodsContextProvider>
    </ThemeProvider>
  )
}
