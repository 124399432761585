import React, { useState, useContext, createContext, useEffect } from 'react'
import { useFetch } from '../../fetchOverviewApi'
import { useJQuery } from '../../hooks/useJQuery/useJQuery'
import { getRoundedToDecimal } from '../../helpers/numberHelper'

const ProjectCoordinationContext = createContext()

export const ProjectCoordinationContextProvider = ({ children, id }) => {
  const [errors, setErrors] = useState([])
  const [project, setProject] = useState(null)
  const [projectStatus, setProjectStatus] = useState(null)
  const [months, setMonths] = useState(null)
  const [monthsWithValidation, setMonthsWithValidation] = useState(null)
  const [expensesConsumption, setExpensesConsumption] = useState(null)
  const [totalAmountsConsumedPerMonths, setTotalAmountsConsumedPerMonths] = useState(null)
  const [totalConsumption, setTotalConsumption] = useState(null)
  const [projectActivitiesTimeSpent, setProjectActivitiesTimeSpent] = useState(null)
  const [projectTimeInputs, setProjectTimeInputs] = useState(null)
  const [subcontractingExpenses, setSubcontractingExpenses] = useState(null)
  const [itemsToDelete, setItemsToDelete] = useState([])
  const [tableItemsDeleteMode, setTableItemsDeleteMode] = useState(false)
  const { publish, subscribe } = useJQuery()

  const isProjectPresales = () => ['lead', 'proposal_in_progress', 'proposal_sent'].includes(projectStatus)

  const addItemsToBeDeleted = async (dataToDelete) => {
    await setItemsToDelete(((previous) => {
      const previousId = previous.map((value) => value.id)
      if (previousId.includes(dataToDelete.id)) {
        const groupUpdated = previous.filter((value) => value.id !== dataToDelete.id)
        return ([...groupUpdated])
      }
      if (previous.length === 0) {
        return [dataToDelete]
      }
      return [...previous, dataToDelete]
    }
    ))
  }

  const fetchGetProject = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}.json`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProject(response.project)
    }
  }

  const fetchGetProjectStatus = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/billable_project_status.json`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProjectStatus(response.project_status)
    }
  }

  const fetchGetMonthsWithValidation = async () => {
    const response = await useFetch('GET', `/turnover_breakdowns/${id}/months_with_validation`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      await setMonthsWithValidation(response.months_of_project_turnover_breakdown_with_validation)
    }
  }

  const fetchGetProjectExpensesConsumption = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/project_expenses_consumption`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setMonths(response.all_months_with_project_expenses_quantities)
      setExpensesConsumption(response.project_expenses_consumption_by_turnover_type)
      setTotalAmountsConsumedPerMonths(response.total_amount_consumed_per_month_by_turnover_type)
      setTotalConsumption(response.total_consumption_by_turnover_type)
    }
  }

  const fetchGetProjectExpenses = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/project_expenses`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      const expenses = response['project_expenses'].subcontracting.concat(response['project_expenses'].intragroup)

      const makeOjbectExpenses = () => (
        Object.fromEntries(expenses.map((expense) => [expense.id, {
          ...expense,
          'unit_price': getRoundedToDecimal(parseFloat(expense['unit_price']), 2),
          'markup': getRoundedToDecimal(parseFloat(expense['markup']), 2),
          'selling_price': getRoundedToDecimal(parseFloat(expense['selling_price']), 2),
          'quantity': getRoundedToDecimal(parseFloat(expense['quantity']), 2),
          'total': getRoundedToDecimal(parseFloat(expense['selling_price']) * parseFloat(expense['quantity']), 2),
        }]))
      )
      setSubcontractingExpenses(makeOjbectExpenses())
    }
  }

  const fetchGetProjectActivitiesTimeSpent = async (date) => {
    const parsedDate = date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : null
    const response = await useFetch('GET', `/projects/${id}/time_spent?time_spent_date=${parsedDate}`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProjectActivitiesTimeSpent(response.activities_time_spent)
    }
  }

  const fetchGetProjectTimeInputs = async (year, month) => {
    const response = await useFetch('GET', `/projects/billable/${id}/time_inputs?year=${year}&month=${month}`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProjectTimeInputs(response.time_inputs)
    }
  }

  const fetchGetKPIs = async () => {
    await fetchGetProject()
    await fetchGetProjectStatus()
    await fetchGetMonthsWithValidation()
    await fetchGetProjectExpensesConsumption()
    await fetchGetProjectActivitiesTimeSpent()
    await fetchGetProjectExpenses()
  }

  const fetchDeleteActivities = async () => {
    const ids = itemsToDelete.map((value) => value.id)
    const response = await useFetch('DELETE', `/activities/bulk_delete/`, { ids })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
      setItemsToDelete([])
    } else {
      setItemsToDelete([])
      publish('/octopod/project/turnover_breakdown')

      await fetchGetKPIs()
    }
  }

  const fetchDeleteProjectExpense = async () => {
    const ids = itemsToDelete.map((value) => value.id)
    const response = await useFetch('DELETE', `/project_expenses/bulk_delete/`, { ids })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
      setItemsToDelete([])
    } else {
      setItemsToDelete([])
      publish('/octopod/project/turnover_breakdown')

      await fetchGetKPIs()
    }
  }

  const fetchPutProjectExpenses = async (projectExpense) => {
    const response = await useFetch('PUT', `/project_expenses/update`, { project_expense: projectExpense })

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      publish('/octopod/project/turnover_breakdown')

      await fetchGetKPIs()
    }
  }

  const updateExpense = (expense, attributName, attributValue) => {
    const updatedStatus = { ...subcontractingExpenses }
    const { expenseId } = expense
    updatedStatus[expenseId][attributName] = attributValue
    updatedStatus[expenseId]['total'] = getRoundedToDecimal(parseFloat(expense['selling_price']) * parseFloat(expense['quantity']), 2)
    const filteredExpense = Object.fromEntries(Object.entries(updatedStatus[expenseId]).filter((attribut) => attribut[0] !== 'total'))
    fetchPutProjectExpenses(filteredExpense)
  }

  const fetchUpdateProjectExpenseQuantity = async (params) => {
    const { month, quantity } = params
    const response = await useFetch('PUT', `/project_expenses/${params.id}/update_quantity?month=${month}&quantity=${quantity}`)

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      publish('/octopod/project/turnover_breakdown')

      await fetchGetKPIs()
    }
  }

  const fetchUpdateExpenseQuantity = async (newQuantity, quantityId, month, expenseId) => {
    const response = quantityId
      ? await useFetch(
        'PUT',
        `/project_expense_quantities/${quantityId}`,
        { quantity: newQuantity },
      )
      : await useFetch(
        'POST',
        `/project_expense_quantities`,
        { month, quantity: newQuantity, project_expense_id: expenseId },
      )
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      publish('/octopod/project/turnover_breakdown')

      await fetchGetProjectExpensesConsumption()
    }
  }

  const fetchPutActivities = async (activities) => {
    const response = await useFetch('PUT', `/projects/billable/${id}/activities`, activities)
    if (response.errors) {
      setErrors([...response.errors])
    } else {
      fetchGetProjectActivitiesTimeSpent()
      publish('/octopod/project/turnover_breakdown')
    }
  }

  const fetchDeleteExpense = async (ids) => {
    const response = await useFetch('DELETE', `/project_expenses/bulk_delete`, { ids })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      publish('/octopod/project/turnover_breakdown')

      await fetchGetKPIs()
    }
  }

  useEffect(() => {
    subscribe('/octopod/tabchange', () => fetchGetKPIs(), 0)
    subscribe('/octopod/project/update_kpis', () => fetchGetKPIs(), 0)
    subscribe('/octopod/turnover_breakdown_months/update_validation', () => fetchGetMonthsWithValidation(), 0)

    fetchGetKPIs()
  }, [])

  return (
    <ProjectCoordinationContext.Provider value={{
      fetchGetMonthsWithValidation,
      fetchGetProjectExpensesConsumption,
      fetchUpdateExpenseQuantity,
      fetchUpdateProjectExpenseQuantity,
      fetchGetProject,
      fetchGetProjectStatus,
      fetchGetProjectActivitiesTimeSpent,
      fetchGetProjectTimeInputs,
      fetchDeleteActivities,
      fetchDeleteProjectExpense,
      fetchPutProjectExpenses,
      fetchPutActivities,
      fetchDeleteExpense,
      isProjectPresales,
      subcontractingExpenses,
      setSubcontractingExpenses,
      errors,
      months,
      monthsWithValidation,
      expensesConsumption,
      totalAmountsConsumedPerMonths,
      totalConsumption,
      project,
      projectStatus,
      projectActivitiesTimeSpent,
      projectTimeInputs,
      addItemsToBeDeleted,
      tableItemsDeleteMode,
      setTableItemsDeleteMode,
      itemsToDelete,
      setItemsToDelete,
      updateExpense,
    }}
    >
      {children}
    </ProjectCoordinationContext.Provider>
  )
}

export const useProjectCoordinationContext = () => useContext(ProjectCoordinationContext)
