import React from 'react'
import Select from 'react-select'
import { getYearRangeOptions, getMonthOptions } from '../../../helpers/datePickerHelper'
import { StyledSelectMonthYear } from './SelectMonthYear.styled'

const SelectMonthYear = ({
  name,
  month,
  year,
  startYear,
  endYear,
  onChangeSelectedMonthYear,
}) => {
  const selectMonthName = `${name} month selector`
  const selectYearName = `${name} year selector`

  const months = getMonthOptions()
  const years = getYearRangeOptions(startYear, endYear)

  const updateSelectedMonth = (month) => {
    onChangeSelectedMonthYear && onChangeSelectedMonthYear(month.value, year)
  }

  const updateSelectedYear = (year) => {
    onChangeSelectedMonthYear && onChangeSelectedMonthYear(month, year.value)
  }

  return (
    <StyledSelectMonthYear>
      <Select
        className="select select-month"
        aria-label={selectMonthName}
        name={selectMonthName}
        value={months[month]}
        options={months}
        maxMenuHeight={150}
        onChange={(object) => updateSelectedMonth(object)}
      />
      <Select
        className="select select-year"
        aria-label={selectYearName}
        name={selectYearName}
        value={years.find((yearOption) => yearOption.value === year)}
        options={years}
        maxMenuHeight={150}
        onChange={(object) => updateSelectedYear(object)}
      />
    </StyledSelectMonthYear>
  )
}

export default SelectMonthYear
