import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useTimesheetStateValue } from '../../../../../context/Timesheet/TimesheetStateContext'
import { useTimeinputsContextValue } from '../../../../../context/Timesheet/TimeinputsContext'
import { useTimesheetErrors } from '../../../../../context/Timesheet/TimesheetErrorsContext'
import { useDateContextValue } from '../../../../../context/Timesheet/DateContext'

import StyledTimesheetHeaderTotal from './TimesheetHeaderTotal.styled'

const TimesheetHeaderTotal = observer(() => {
  const { t } = useTranslation()
  const {
    totalInputsInHoursForDate,
    totalInputsForMonth,
    firstPeriodTimeinputs,
    secondPeriodTimeinputs,
  } = useTimeinputsContextValue()
  const {
    errorTypeForDay,
  } = useTimesheetErrors()
  const {
    isToday,
  } = useDateContextValue()

  const { daysInMonth, holidayAndWeekendDays, periods, owner } = useTimesheetStateValue()
  const totalInputsForMonthsInDays = totalInputsForMonth(daysInMonth).inDays
  const daysInMonthObject = daysInMonth.map((dateItem) => {
    const date = new Date(dateItem)
    return { dayInWeek: date.getDay(), dateOfDay: date.getDate(), fullDate: dateItem }
  })
  const role = 'totalInputsInDays'
  return (
    <>
      {
        daysInMonthObject.map(({ dateOfDay, fullDate }) => {
          const fullDateInputsInHours = totalInputsInHoursForDate(fullDate)
          const periodTimeInputs = periods[0].end_date >= fullDate ? firstPeriodTimeinputs(daysInMonth) : secondPeriodTimeinputs(daysInMonth)
          const hasPeriodAtLeastOneTimeInput = Object.values(periodTimeInputs).some((input) => Object.keys(input).length > 0)
          return (
            <StyledTimesheetHeaderTotal
              key={`sum-input-${dateOfDay}`}
              aria-label={`timesheet header total day ${dateOfDay}`}
              data-day={fullDate}
              className={isToday(fullDate) ? 'current-day' : undefined}
            >
              {
                totalInputsForMonthsInDays > 0
                && !holidayAndWeekendDays.includes(fullDate)
                && hasPeriodAtLeastOneTimeInput
                && owner.entry_date <= fullDate
                && (owner.leaving_date === null || owner.leaving_date >= fullDate)
              && (
              <span className={[errorTypeForDay(fullDate), fullDateInputsInHours === 0 && 'missing'].filter(Boolean).join(' ')}>
                {fullDateInputsInHours}
              </span>
              )
            }
            </StyledTimesheetHeaderTotal>
          )
        })
      }

      <th role={role}>
        <span>
          {totalInputsForMonthsInDays}
          {' '}
          {t('days.short')}
        </span>
      </th>
    </>
  )
})

export default TimesheetHeaderTotal
