import React, { useEffect, useCallback } from 'react'
import { InputSearch, Button } from '@produits-internes-oss/design-system-components'
import { useTranslation } from 'react-i18next'
import { useSearch } from '../../hooks/useSearch/useSearch'
import { useDebouncer } from '../../hooks/useDebouncer/useDebouncer'
import { ExpensesFormAttendeesPeopleStyled } from './ExpensesFormAttendeesPeople.styled'
import { useExpensesContext } from '../../context/Expenses/ExpensesContext'

export const ExpensesFormAttendeesPeople = ({ updateExpenseAttribute, error }) => {
  const {
    getPersonName,
    addToGroupAttendees,
    removeFromGroupAttendees,
    groupAttendees,
  } = useExpensesContext()
  const { fetchSearch } = useSearch()
  const { debounce } = useDebouncer()

  const { t } = useTranslation()

  const SearchPerson = useCallback(() => (
    <InputSearch
      className={error ? 'form-search error' : 'form-search'}
      skin="light"
      name="person search"
      title={t('project.presales.search')}
      required={false}
      onSearch={(search) => debounce(() => search.length >= 3 && fetchSearch(search, 'Person', null, getPersonName), 500)}
      onChange={({ key, search }) => {
        if (key) {
          addToGroupAttendees({ id: key, personName: search })
        }
      }}
    />
  ), [groupAttendees, error])

  useEffect(() => {
    if (groupAttendees) {
      const ids = groupAttendees.map((person) => parseInt(person.id, 10))
      updateExpenseAttribute('people_attendee_ids', ids)
    }
  }, [groupAttendees])

  const listAttendees = () => {
    if (!groupAttendees) return null
    return (
      <div className="list-attendees">
        <ul>
          {groupAttendees.map((attendeePerson) => (
            <li key={`person-id-${attendeePerson.id}`}>
              <Button
                skin="light"
                className="destroy"
                onClick={() => { removeFromGroupAttendees(attendeePerson.id) }}
                icon={{ url: 'cross', position: 'left' }}
              />
              {attendeePerson.personName}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <ExpensesFormAttendeesPeopleStyled>
      <SearchPerson />
      {listAttendees()}
    </ExpensesFormAttendeesPeopleStyled>
  )
}
