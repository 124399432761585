import React, { useEffect, useState, useContext, createContext } from 'react'
import { useFetch } from '../../fetchOverviewApi'
import { ProjectTurnoverBreakdown } from '../../structures/ProjectTurnoverBreakdown/ProjectTurnoverBreakdown'
import { useJQuery } from '../../hooks/useJQuery/useJQuery'

const ProjectTurnoverBreakdownContext = createContext()

export const ProjectTurnoverBreakdownContextProvider = ({ children, id }) => {
  const [turnoverBreakdown, setTurnoverBreakdown] = useState(null)
  const [monthToValidate, setMonthToValidate] = useState([])
  const [errors, setErrors] = useState([])
  const [errorsInAmounts, setErrorsInAmounts] = useState({})
  const [turnoverBreakdownMonthAmount, setTurnoverBreakdownMonthAmount] = useState(null)
  const { subscribe } = useJQuery()

  const fetchGetTurnoverBreakdown = async () => {
    const response = await useFetch('GET', `/turnover_breakdowns/${id}`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setTurnoverBreakdown(null)
      const turnoverBreakdownFromResponse = new ProjectTurnoverBreakdown(response['turnover_breakdown'])
      setTurnoverBreakdown(turnoverBreakdownFromResponse)
      setMonthToValidate(turnoverBreakdownFromResponse.months.find(([year, month]) => turnoverBreakdownFromResponse.values[year][month].validation.date === null))
    }
  }

  const fetchCreateTurnoverBreakdownAmount = async (year, month, lob, amount) => {
    const response = await useFetch('POST', '/turnover_breakdown_amounts', { month: turnoverBreakdown.values[year][month].id, lob: lob.id, amount })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
      setErrorsInAmounts({ ...errorsInAmounts, [`${year}-${month}-${lob.abbreviation}`]: true })
    } else {
      setErrorsInAmounts({ ...errorsInAmounts, [`${year}-${month}-${lob.abbreviation}`]: false })
    }
  }

  const fetchUpdateTurnoverBreakdownAmount = async (year, month, lob, amount) => {
    const response = await useFetch('PATCH', `/turnover_breakdown_amounts/${turnoverBreakdown.values[year][month].lobs[lob.abbreviation]['amount_id']}`, { amount })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
      setErrorsInAmounts({ ...errorsInAmounts, [`${year}-${month}-${lob.abbreviation}`]: true })
    } else {
      setErrorsInAmounts({ ...errorsInAmounts, [`${year}-${month}-${lob.abbreviation}`]: false })
    }
  }

  const fetchPutTurnoverBreakdownReopen = async () => {
    const response = await useFetch('PUT', `/turnover_breakdowns/${id}/unfinalise`, { from_new_table: true })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setTurnoverBreakdown(new ProjectTurnoverBreakdown(response['turnover_breakdown']))
    }
  }

  const fetchUpdateComment = async (year, month, comment) => {
    const response = await useFetch('PATCH', `/turnover_breakdown_months/${turnoverBreakdown.values[year][month].id}/comment`, { comment })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    }
  }

  const fetchUpdateTurnoverBreakdownMonthAmount = async (amount, year, month) => {
    const postBody = {
      turnover_breakdown_id: id,
      month: `${year}-${month}-01`,
      amount,
    }

    const response = await useFetch('POST', '/turnover_breakdown_months/update_amount', postBody)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setTurnoverBreakdownMonthAmount(response)
    }

    await fetchGetTurnoverBreakdown()
  }

  useEffect(() => {
    subscribe('/octopod/project/turnover_breakdown', () => fetchGetTurnoverBreakdown())
  }, [])

  return (
    <ProjectTurnoverBreakdownContext.Provider value={
      {
        turnoverBreakdown,
        turnoverBreakdownMonthAmount,
        monthToValidate,
        errors,
        errorsInAmounts,
        setTurnoverBreakdown,
        fetchGetTurnoverBreakdown,
        fetchCreateTurnoverBreakdownAmount,
        fetchUpdateTurnoverBreakdownAmount,
        fetchPutTurnoverBreakdownReopen,
        fetchUpdateComment,
        fetchUpdateTurnoverBreakdownMonthAmount,
      }
    }
    >
      {children}
    </ProjectTurnoverBreakdownContext.Provider>
  )
}

export const useProjectTurnoverBreakdownContext = () => useContext(ProjectTurnoverBreakdownContext)
