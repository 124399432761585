import styled from 'styled-components'

export const StyledSidedrawer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  padding-left: 0.4%;
  padding-bottom: 2rem;
  height: calc(100vh - 2rem - ${({ theme }) => theme.sizes.navbarHeight});
  border-top: 0.5px solid rgba(66, 39, 164, 0.2);
  width: 30%;
  position: absolute;
  top: calc(${({ theme }) => theme.sizes.navbarHeight});
  right: ${({ open }) => (open ? '0' : '-35%')};
  z-index: 2000;

  .bottomLinks {
    margin-top: 30px;
  }

  &.side-animated-enter {
    right: -35%;
    opacity: 0;
  }

  &.side-animated-enter-active {
    right: 0;
    opacity: 1;
    transition: all 300ms ease-in-out;
  }

  &.side-animated-exit {
    right: 0;
    opacity: 1;
  }

  &.side-animated-exit-active {
    right: -35%;
    opacity: 0;
    transition: all 300ms ease-in-out;
  }

  @media (min-width: ${({ theme }) => theme.sizes.mobile}) and (max-width: ${({ theme }) => theme.sizes.desktop}) {
    width: 50%;
    right: ${({ open }) => (open ? '0' : '-60%')};
  }

  @media (max-width: ${({ theme }) => theme.sizes.mobile}) {
    width: 100%;
    right: ${({ open }) => (open ? '0' : '-100%')};
  }
`
