import React, { useState, useEffect, useContext, createContext } from 'react'
import { useTranslation } from 'react-i18next'
import { map } from '../../helpers/objectHelper'
import { useFetch } from '../../fetchOverviewApi'

const REGEX_EMAIL = /[\w\-.]+@([\w-]+\.)+[\w-]{2,4}/
const PROJECT_STATUSES = ['lead', 'proposal_in_progress', 'proposal_sent', 'mission_accepted', 'mission_signed']
const PROJECT_AMOUNT_THRESHOLD_QA_DIRECTOR = 800000

const ProjectFormContext = createContext()

export const ProjectFormContextProvider = ({ children, id, idToDuplicate, currentPerson }) => {
  const { t } = useTranslation()
  const [project, setProject] = useState(null)
  const [projectAffiliated, setProjectAffiliated] = useState(null)
  const [isLoading, setIsLoading] = useState(null)

  const isProjectInOrAfterStatus = (status) => project && PROJECT_STATUSES.indexOf(project.status) >= PROJECT_STATUSES.indexOf(status)

  const [cache, setCache] = useState(null)
  const [errors, setErrors] = useState([])
  const [form, setForm] = useState({
    'affiliated_project_id': null,
    'affiliated_project_name': '',
    'amount': null,
    'amount_with_accenture': null,
    'benext_synergy': '0%',
    'business_contact_id': (project && project.business_contact_id) ? project.business_contact_id : currentPerson.id,
    'business_contact_name': '',
    'camp_lob_id': null,
    'camp_lob_name': '',
    'comment': null,
    'competition_type': null,
    'concern_commercial_laws': null,
    'country_ids': [],
    'customer_contact_email': null,
    'customer_id': null,
    'customer_name': '',
    'decision_maker': null,
    'eligible_cir': null,
    'end_date': null,
    'essential_tech': null,
    'extra_resource': null,
    'imply_export_or_transfer': null,
    'invoiceable_fees': null,
    'kind': null,
    'locations': null,
    'mission_description': null,
    'mission_director_id': null,
    'mission_director_name': '',
    'mission_maker': null,
    'name': null,
    'nature': null,
    'operational_risk_level': null,
    'operations_domain_ids': [],
    'optimisation_tech': null,
    'other_qa_director': null,
    'prevention_plan_status': null,
    'publisher_ids': [],
    'qa_director': null,
    'remote': null,
    'responsible_tech': null,
    'risky_countries': [],
    'start_date': null,
    'status': 'lead',
    'subcontractor_presence': null,
    'success_probability': null,
    'transboundary': null,
    'travel_abroad': null,
  })
  const formDisabler = {
    'amount_with_accenture': !form['mission_maker'] || ['octo_only', 'octo_prime'].includes(form['mission_maker']),
    'other_qa_director': !form['amount'] || form['amount'] < PROJECT_AMOUNT_THRESHOLD_QA_DIRECTOR || form['qa_director'] !== 'other',
    'qa_director': !form['amount'] || form['amount'] < PROJECT_AMOUNT_THRESHOLD_QA_DIRECTOR,
    'amount': isProjectInOrAfterStatus('proposal_in_progress'),
  }

  const formRequirements = {
    'amount': true,
    'benext_synergy': isProjectInOrAfterStatus('proposal_in_progress'),
    'business_contact_id': true,
    'competition_type': isProjectInOrAfterStatus('proposal_in_progress'),
    'concern_commercial_laws': isProjectInOrAfterStatus('proposal_in_progress'),
    'customer_contact_email': true,
    'customer_id': true,
    'eligible_cir': isProjectInOrAfterStatus('proposal_in_progress'),
    'end_date': isProjectInOrAfterStatus('proposal_in_progress'),
    'extra_resource': isProjectInOrAfterStatus('mission_accepted'),
    'imply_export_or_transfer': isProjectInOrAfterStatus('proposal_in_progress'),
    'invoiceable_fees': isProjectInOrAfterStatus('mission_signed'),
    'kind': true,
    'locations': isProjectInOrAfterStatus('proposal_in_progress'),
    'mission_description': isProjectInOrAfterStatus('proposal_in_progress'),
    'mission_director_id': isProjectInOrAfterStatus('mission_accepted'),
    'mission_maker': isProjectInOrAfterStatus('proposal_in_progress'),
    'name': true,
    'nature': isProjectInOrAfterStatus('proposal_in_progress'),
    'operational_risk_level': isProjectInOrAfterStatus('proposal_in_progress'),
    'other_qa_director': isProjectInOrAfterStatus('proposal_sent') && !formDisabler['other_qa_director'],
    'prevention_plan_status': isProjectInOrAfterStatus('mission_accepted'),
    'qa_director': isProjectInOrAfterStatus('proposal_sent') && !formDisabler['qa_director'],
    'start_date': isProjectInOrAfterStatus('proposal_in_progress'),
    'status': true,
    'subcontractor_presence': isProjectInOrAfterStatus('mission_accepted'),
    'success_probability': isProjectInOrAfterStatus('proposal_in_progress'),
    'transboundary': isProjectInOrAfterStatus('proposal_in_progress'),
    'travel_abroad': isProjectInOrAfterStatus('proposal_in_progress'),
    'responsible_tech': isProjectInOrAfterStatus('proposal_in_progress'),
  }

  const formValidation = {
    'amount':
      form['amount'] === null || form['amount'] > 0 || (form['amount'] === 0 && form['kind'] === 'framework_agreement'),
    'amount_with_accenture':
      form['amount_with_accenture'] === null || form['amount_with_accenture'] >= 0 || (form['amount_with_accenture'] === 0 && form['kind'] === 'framework_agreement'),
    'customer_id':
      !projectAffiliated || projectAffiliated['customer_id'] === form['customer_id'],
    'customer_contact_email':
      !form['customer_contact_email'] || form['customer_contact_email'].match(REGEX_EMAIL),
    'kind':
      !form['mission_maker'] || ['octo_only', 'octo_prime'].includes(form['mission_maker']) || form['kind'] === 'cost_reimbursable',
    'other_qa_director':
      !form['other_qa_director'] || form['other_qa_director'].match(REGEX_EMAIL),
    'start_date': ((form['start_date'] !== null) || !(form['start_date'] > form['end_date'])),
    'end_date': (form['end_date'] === null || !(form['end_date'] < form['start_date'])),
  }

  const getProjectName = (other) => (other ? [other.reference, other['customer_name'], other.name].filter(Boolean).join(' - ') : '')

  const getPersonName = (person) => (person ? `${person['first_name']} ${person['last_name'].toUpperCase()} - ${person['nickname']}` : '')

  const fetchGetProject = async (params) => {
    const response = await useFetch('GET', `/projects/billable/${params.id}.json`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
      return null
    }

    return response.project
  }

  const fetchCreateOrUpdateProject = async () => {
    const body = { 'billable_project': Object.fromEntries(Object.entries(form).map(([key, value]) => [key, value])) }
    const response = id ? await useFetch('PUT', `/projects/billable/${id}.json`, body) : await useFetch('POST', '/projects/billable.json', body)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
      return null
    }

    return response.id
  }

  const fetchDuplicateProjectWithoutActivities = async () => {
    const body = { 'billable_project': Object.fromEntries(Object.entries(form).map(([key, value]) => [key, value])) }
    body['billable_project']['duplicate_activities'] = false
    const response = id ? await useFetch('PUT', `/projects/billable/${id}.json`, body) : await useFetch('POST', '/projects/billable.json', body)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
      return null
    }

    return response.id
  }

  const fetchLoad = async () => {
    const idToLoad = idToDuplicate || id
    const response = await useFetch('GET', `/projects/billable/${idToLoad ? `${idToLoad}/edit` : 'new'}.json`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      const projectFetched = response['project']
      projectFetched['business_contact_id'] = projectFetched['business_contact_id'] ? projectFetched['business_contact_id'] : currentPerson.id

      if (idToDuplicate) {
        projectFetched['affiliated_project_id'] = idToDuplicate
        projectFetched['affiliated_project_name'] = projectFetched['full_name']
        projectFetched['id'] = null
        projectFetched['start_date'] = null
        projectFetched['end_date'] = null
        projectFetched['status'] = 'lead'
        projectFetched['customer_contract_reference'] = null
        projectFetched['customer_contract_comment'] = null
        projectFetched['duplicate_activities'] = true
      }
      setForm({ ...form, ...projectFetched })
      setCache({
        benextSynergies: response['benext_synergies'],
        camps: response['camps'],
        competitionTypes: response['competition_types'],
        countries: map(response['countries'], (_, item) => t(`countries.${item}`)),
        kinds: response['kinds'],
        missionMakers: response['mission_makers'],
        natures: response['natures'],
        operationsDomains: map(response['operations_domains'], (_, item) => t(`project.form.operations_domains.${item}`)),
        preventionPlanStatuses: response['prevention_plan_statuses'],
        publishers: response['publishers'],
        qaDirectors: response['qa_directors'],
        remotes: response['remotes'],
        riskyCountries: map(response['risky_countries'], (_, item) => t(`countries.${item}`)),
        statuses: response['statuses'],
        successProbabilities: response['success_probabilities'],
      })
      setProject(projectFetched)
      setProjectAffiliated(projectFetched['affiliated_project_id'] ? await fetchGetProject({ id: projectFetched['affiliated_project_id'] }) : null)
    }
  }
  useEffect(() => { fetchLoad() }, [])

  const updateForm = async (key, value) => {
    if (key === 'affiliated_project_id') {
      setProjectAffiliated(value ? await fetchGetProject({ id: value }) : null)
    }
    setForm((previous) => ({ ...previous, [key]: value }))
  }

  return (
    <ProjectFormContext.Provider value={{
      cache,
      errors,
      form: map(form, (key, value) => ({
        value,
        disabled: (key in formDisabler) && Boolean(formDisabler[key]),
        error: (key in formValidation) && Boolean(!formValidation[key]),
        required: (key in formRequirements) && Boolean(formRequirements[key]),
      })),
      project,
      projectAffiliated,
      getProjectName,
      getPersonName,
      fetchCreateOrUpdateProject,
      updateForm,
      fetchDuplicateProjectWithoutActivities,
      currentPerson,
      isLoading,
      setIsLoading,
    }}
    >
      {children}
    </ProjectFormContext.Provider>
  )
}

export const useProjectFormContext = () => useContext(ProjectFormContext)
