import styled from 'styled-components'

export const StyledPeriodSubmit = styled.div`
  .period-errors {
    font-size: 1.2rem;
    height: auto;
    margin: 2rem 0;
    &.legal {
      margin-top: 5rem;
    }
  }

  .send-period {
    margin: 5rem 0 2rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`
