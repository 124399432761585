import { useState, useEffect } from 'react'
import { useLocationValue } from '../context/Timesheet/LocationContext'
import { useTimesheetStateValue } from '../context/Timesheet/TimesheetStateContext'
import { request, requestWithCSRFToken } from '../javascripts/HttpService'

export const useLocationSelector = (open, fullDate) => {
  const { locationPerDay, setLocationPerDay, countries } = useLocationValue()
  const { owner } = useTimesheetStateValue()
  const locationOfDay = locationPerDay[fullDate]

  const [selectedCountry, setSelectedCountry] = useState(
    {
      value: locationOfDay.country_code,
      name: locationOfDay.country_name,
      label: [locationOfDay.country_name, locationOfDay.country_code].join(' - '),
    },
  )
  const [selectedCity, setSelectedCity] = useState(
    {
      value: locationOfDay.location_1_code,
      label: locationOfDay.location_1_name,
    },
  )
  const [selectedSubCity, setSelectedSubCity] = useState(
    {
      value: locationOfDay.location_2_code,
      label: locationOfDay.location_2_name,
    },
  )

  const [cityOptions, setCityOptions] = useState([])
  const [subCityOptions, setSubCityOptions] = useState([])

  const changeCountry = (country) => {
    setSelectedCountry({
      value: country.value,
      name: country.name,
      label: country.label,
    })
    setSelectedCity('')
    setSelectedSubCity('')
  }

  const changeCity = (city) => {
    setSelectedCity({
      value: city.value,
      label: city.label,
    })
    setSelectedSubCity('')
  }

  const changeSubCity = (subCity) => {
    setSelectedSubCity({
      value: subCity.value,
      label: subCity.label,
    })
  }

  const getCities = (countryCode) => {
    const url = `/location/sublocations.json?location[country_code]=${countryCode}`

    request(url, { method: 'GET' })
      .then((data_json) => setCityOptions(
        data_json.map((city) => ({
          value: city.location_code,
          label: city.location_name,
        })),
      ))
      .catch((error) => console.error(error))
  }

  const getSubCities = (countryCode, cityCode) => {
    const url = `/location/sublocations.json?location[country_code]=${countryCode}&location[location_code]=${cityCode}`

    request(url, { method: 'GET' })
      .then((data_json) => setSubCityOptions(
        data_json.map((subCity) => ({
          value: subCity.location_code,
          label: subCity.location_name,
        })),
      ))
      .catch((error) => console.error(error))
  }

  const setNewLocationPerDay = () => {
    const newLocations = { ...locationPerDay }
    newLocations[fullDate] = {
      country_code: selectedCountry.value,
      country_name: selectedCountry.name,
      location_1_code: selectedCity.value,
      location_1_name: selectedCity.label,
      location_2_code: selectedSubCity?.value,
      location_2_name: selectedSubCity?.label,
    }
    setLocationPerDay(newLocations)
  }

  const updateLocation = () => {
    const url = '/time_input/update_location.json'

    const body = {
      time_input: {
        person_id: owner.id,
        day: fullDate,
        country_code: selectedCountry.value,
        location_1_code: selectedCity.value,
        location_2_code: selectedSubCity?.value,
      },
    }

    requestWithCSRFToken(url, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
      .then(setNewLocationPerDay())
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    if (open) {
      getCities(selectedCountry.value)
      setSubCityOptions([])
    }
  }, [selectedCountry])

  useEffect(() => {
    if (selectedCity.value && open) {
      getSubCities(selectedCountry.value, selectedCity.value)
    }
  }, [selectedCity])

  return {
    changeCountry,
    countries,
    selectedCountry,
    changeCity,
    cityOptions,
    selectedCity,
    changeSubCity,
    subCityOptions,
    selectedSubCity,
    updateLocation,
  }
}
