import styled from 'styled-components'

export const StyledTimesheetHelp = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.primaryGrey};

  & > span {
    margin: 0 1rem;
  }

  & > div {
    position: relative;
    display:inline-flex;
    span {
      margin: 0 1rem;
    }

    .rtt-title {
      color: ${({ theme }) => theme.colors.primary};
    }

    &:hover > .tooltip {
      visibility: visible;
      opacity: 1;
    }

    .tooltip {
      font-size: inherit;
      visibility: hidden;
      width: 100%;
      padding: 1.5rem;
      position: absolute;
      top: 140%;
      left: 0;
      opacity: 0;
      transition: 0.5s opacity;
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.primary};
      border-radius: 1rem;
      box-shadow: 0 0.2rem 0.8rem ${({ theme }) => theme.colors.secondary};
      text-align: inherit;
      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 30%;
        position: absolute;
        top: -2.5rem;
        left: 0;
      }
    }
  }

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
  }



`
