import React, { useState, useContext, createContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetch } from '../../fetchOverviewApi'
import { cleanWithPunctuation } from '../../helpers/stringHelper'

const ManagePeriodsContext = createContext()

export const ManagePeriodsContextProvider = ({ children }) => {
  const { t } = useTranslation()
  const timesheetUrl = '/timesheet'

  const [errors, setErrors] = useState([])

  const [date, setDate] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    day: new Date().getDate(),
  })

  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [people, setPeople] = useState([])
  const [periodsLocked, setPeriodsLocked] = useState(true)
  const [subsidiaryId, setSubsidiaryId] = useState()
  const [validatePeriodsJobLaunch, setValidatePeriodsJobLaunch] = useState(false)
  const [activeTab, setActiveTab] = useState('unvalidated')
  const [periodsValidationButtonDisabled, setPeriodsValidationButtonDisabled] = useState(false)
  const [emailBody, setEmailBody] = useState(t('managePeriods.email.unvalidatedPeriods.body'))
  const [periodMailerType, setPeriodMailerType] = useState('unvalidated_period')
  const [emailButtonPictoCheck, setEmailButtonPictoCheck] = useState(null)
  const [buttonSendMailText, setButtonSendMailText] = useState(t('managePeriods.email.button.send'))

  const searchElements = cleanWithPunctuation(search).split(' ')

  const peopleFilteredBySearch = people.filter((person) => {
    const columns = [person.first_name, person.last_name, person.nickname, person.lob, person.league]
      .map((column) => cleanWithPunctuation(column))
    return searchElements.every((searchElement) => columns.some((column) => column.includes(searchElement)))
  })

  const fetchGetUnvalidatedPeriods = async (year, month, day) => {
    setLoading(true)
    setActiveTab('unvalidated')
    setEmailBody(t('managePeriods.email.unvalidatedPeriods.body'))
    setPeriodMailerType('unvalidated_period')
    setEmailButtonPictoCheck(null)
    setButtonSendMailText(t('managePeriods.email.button.send'))
    setPeople([])

    const response = await useFetch('GET', `/periods/unvalidated?year=${year}&month=${month}&day=${day}`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setPeople(response.people)
      setPeriodsLocked(response.periods_locked)
      setSubsidiaryId(response.subsidiary_id)
    }
    setLoading(false)
  }

  const fetchGetPeriodsWithEmptyDays = async (year, month, day) => {
    setLoading(true)
    setActiveTab('emptyDays')
    setEmailBody(t('managePeriods.email.emptyDaysPeriods.body'))
    setPeriodMailerType('empty_days_period')
    setEmailButtonPictoCheck(null)
    setButtonSendMailText(t('managePeriods.email.button.send'))
    setPeople([])

    const response = await useFetch('GET', `/periods/with_empty_days?year=${year}&month=${month}&day=${day}`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setPeople(response.people)
    }
    setLoading(false)
  }

  const fetchGetPeriodsToReview = async (year, month, day) => {
    setLoading(true)
    setActiveTab('toReview')
    setPeople([])

    const response = await useFetch('GET', `/periods/to_review?year=${year}&month=${month}&day=${day}`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setPeople(response.people)
    }
    setLoading(false)
  }

  const fetchPostUnlockPeriods = async () => {
    await useFetch(
      'POST',
      '/periods/unlock',
      {
        date: { day: date.day, month: date.month, year: date.year },
        subsidiary_id: subsidiaryId,
      },
      false,
    ).then(() => setPeriodsLocked(false))
  }

  const fetchPostLockPeriods = async () => {
    await useFetch(
      'POST',
      '/periods/lock',
      {
        date: { day: date.day, month: date.month, year: date.year },
        subsidiary_id: subsidiaryId,
      },
      false,
    ).then(() => setPeriodsLocked(true))
  }

  const fetchPostSendEmailToSelectedPeople = async (body) => {
    await useFetch(
      'POST',
      '/periods/notify_people_with_periods_with_anomalies',
      {
        people_ids: peopleFilteredBySearch.map((person) => person.id),
        date: { day: date.day, month: date.month, year: date.year },
        email_body: body,
        mailer_type: periodMailerType,
      },
      false,
    )
  }

  const fetchPostValidateAllUnvalidatedPeriods = async () => {
    const params = {
      date: { day: date.day, month: date.month, year: date.year },
    }

    const response = await useFetch('POST', '/periods/validate_all_unvalidated_periods', params)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setValidatePeriodsJobLaunch(response.job_lauched)
    }
  }

  return (
    <ManagePeriodsContext.Provider value={
      {
        timesheetUrl,
        people,
        peopleFilteredBySearch,
        periodsLocked,
        validatePeriodsJobLaunch,
        loading,
        errors,
        activeTab,
        date,
        periodsValidationButtonDisabled,
        periodMailerType,
        emailBody,
        emailButtonPictoCheck,
        buttonSendMailText,
        setSearch,
        setPeriodsLocked,
        setDate,
        setPeriodsValidationButtonDisabled,
        setEmailBody,
        setPeriodMailerType,
        setEmailButtonPictoCheck,
        setButtonSendMailText,
        fetchPostSendEmailToSelectedPeople,
        fetchPostUnlockPeriods,
        fetchPostLockPeriods,
        fetchPostValidateAllUnvalidatedPeriods,
        fetchGetUnvalidatedPeriods,
        fetchGetPeriodsWithEmptyDays,
        fetchGetPeriodsToReview,
      }
    }
    >
      {children}
    </ManagePeriodsContext.Provider>
  )
}

export const useManagerPeriodsContext = () => useContext(ManagePeriodsContext)
