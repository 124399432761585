import React, { useState, useContext, createContext, useEffect } from 'react'
import { useFetch } from '../../fetchOverviewApi'
import { useJQuery } from '../../hooks/useJQuery/useJQuery'

const ProjectContractContext = createContext()

export const ProjectContractContextProvider = ({ children, id }) => {
  const { subscribe } = useJQuery()
  const [errors, setErrors] = useState([])
  const [project, setProject] = useState(null)
  const [projectStatusLog, setProjectStatusLog] = useState(null)
  const [paymentSchedule, setPaymentSchedule] = useState(null)
  const [paymentScheduleLines, setPaymentScheduleLines] = useState(null)
  const [newCreatedPaymentScheduleLine, setNewCreatedPaymentScheduleLine] = useState(null)
  const [referenceContract, setReferenceContract] = useState({
    reference: null,
    comment: null,
  })

  const { publish } = useJQuery()

  const getPersonName = (person) => (person ? `${person['first_name']} ${person['last_name'].toUpperCase()} - ${person['nickname']}` : '')

  const updateReferenceContract = async (key, value) => {
    setReferenceContract((previous) => ({ ...previous, [key]: value }))
  }

  const defaultReferenceContract = async () => {
    setReferenceContract((previous) => ({ ...previous, reference: null, comment: null }))
  }

  const fetchGetProject = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProject(response.project)
    }
  }

  const fetchUpdateContract = async () => {
    const body = {
      customer_contract_reference: referenceContract.reference,
      customer_contract_comment: referenceContract.comment,
    }
    const response = await useFetch('PUT', `/projects/billable/${id}/update_contract`, body)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      defaultReferenceContract()
      fetchGetProject()
      publish('/octopod/project/update_project')
    }
  }

  const fetchGetProjectStatusLog = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/status_log`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProjectStatusLog(response.project_status_logs)
    }
  }

  const fetchGetPaymentSchedule = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/payment_schedule`)

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setPaymentSchedule(response.payment_schedule)
      setPaymentScheduleLines(response.payment_schedule.payment_schedule_lines)
    }
  }

  const fetchGetKPIs = async () => {
    await fetchGetProject()
    await fetchGetPaymentSchedule()
    await fetchGetProjectStatusLog()
  }

  const fetchPutPaymentSchedule = async (paymentScheduleParams) => {
    const paymentScheduleBody = {
      id: paymentScheduleParams.id,
      amount: paymentScheduleParams.totalAmount,
      comment: paymentScheduleParams.comment,
      fees_included: paymentScheduleParams.feesIncluded,
    }

    const response = await useFetch('PUT', `/payment_schedules/${paymentScheduleBody.id}`, { payment_schedule: paymentScheduleBody })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setPaymentSchedule(response.payment_schedule)
      publish('/octopod/project/update_project')
      await fetchGetKPIs()
    }
  }

  const fetchPostPaymentScheduleLine = async (paymentScheduleLineParams) => {
    const response = await useFetch('POST', '/payment_schedules/create_payment_schedule_line', { payment_schedule_line: paymentScheduleLineParams })

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setNewCreatedPaymentScheduleLine(response.payment_schedule_line)
      fetchGetPaymentSchedule()
      publish('/octopod/project/update_project')
    }
  }

  const fetchPutPaymentScheduleLine = async (paymentScheduleLineParams) => {
    const response = await useFetch('PUT', '/payment_schedules/update_payment_schedule_line', { payment_schedule_line: paymentScheduleLineParams })

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      fetchGetPaymentSchedule()
      publish('/octopod/project/update_project')
    }
  }

  const fetchDeletePaymentScheduleLine = async (paymentScheduleLineParams) => {
    const response = await useFetch(
      'DELETE',
      '/payment_schedules/delete_payment_schedule_line',
      { payment_schedule_id: paymentSchedule.id, payment_schedule_line_id: paymentScheduleLineParams.id },
    )

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      fetchGetPaymentSchedule()
      publish('/octopod/project/update_project')
    }
  }

  useEffect(() => {
    subscribe('/octopod/tabchange', () => fetchGetKPIs(), 0)

    fetchGetKPIs()
  }, [])

  return (
    <ProjectContractContext.Provider value={{
      errors,
      project,
      getPersonName,
      updateReferenceContract,
      fetchUpdateContract,
      fetchPutPaymentSchedule,
      fetchGetPaymentSchedule,
      fetchPostPaymentScheduleLine,
      fetchPutPaymentScheduleLine,
      fetchDeletePaymentScheduleLine,
      defaultReferenceContract,
      projectStatusLog,
      paymentSchedule,
      paymentScheduleLines,
      newCreatedPaymentScheduleLine,
      setNewCreatedPaymentScheduleLine,
    }}
    >
      {children}
    </ProjectContractContext.Provider>
  )
}

export const useProjectContractContext = () => useContext(ProjectContractContext)
