import styled from 'styled-components'

export default styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  .track {
    position: absolute;
    top: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    max-height: 100%;
    transition: left 0.1s ease-in-out;

    .tab {
      position: relative;
      overflow: auto;
      box-sizing: border-box;
    }
  }
`
