import React from 'react'
import { Tooltip } from '@produits-internes-oss/design-system-components'
import { getClass } from '../../helpers/stringHelper'
import { ProjectDataHatInfoStyled } from './ProjectDataHatInfo.styled'

export const ProjectDataHatInfo = ({ className, title, value, large, tooltipInfos }) => (
  <ProjectDataHatInfoStyled className={getClass(className, 'octo', large && 'large')}>
    <span>{title}</span>
    <b>{value}</b>
    { tooltipInfos && (
      <Tooltip
        className="value-tooltip"
        position="top"
        skin="dark"
        trigger={
          ({ open, close }) => (
            <button
              type="button"
              onMouseEnter={() => open()}
              onMouseLeave={() => close()}
              className="tooltip-trigger-button"
              aria-label="tooltip trigger button"
            />
          )
        }
        content={
          () => (
            <div>
              {tooltipInfos()}

            </div>
          )
        }
      />
    )}
  </ProjectDataHatInfoStyled>
)
