import styled from 'styled-components'

export const ProjectPricingExportModalStyled = styled.div`
  align-self: center;

  .octo {
    .popup-trigger {
      width: fit-content;
      margin-bottom: 1.8rem;

      button.export-button {
        display: flex;
        align-items: flex-end;
        text-decoration: underline;
        cursor: pointer;

        span.download-icon {
          width: 2.4rem;
          height: 2.4rem;
          margin-left: 0.4rem;
          transform: rotate(90deg);
        }
      }
    }

    .popup-tooltip {
      .modal {
        .header {
          h2 {
            text-align: center;
          }
        }

        .content {
          p {
            margin-bottom: 1.6rem;
          }

          .input-payment-delay {
            height: 10rem;

            div.control {
              width: 30rem;
            }
          }

          .footer {
            margin-top: 0;
          }
        }
      }
    }
  }
`
