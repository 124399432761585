import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectSummaryContext } from '../../context/ProjectSummaryContext/ProjectSummaryContext'
import { ProjectInfoStyled } from './ProjectInfo.styled'

export const ProjectInfo = () => {
  const { t } = useTranslation()
  const { project } = useProjectSummaryContext()

  if (!project) return <div>...</div>

  const qaDirectorInfo = () => {
    if (project.qa_director && project.qa_director !== 'other') {
      return (
        <div>
          <p className="title">{t('project.summary.info.qaDirector')}</p>
          <p>
            {`${project.qa_director.first_name} ${project.qa_director.last_name} - ${project.qa_director.nickname}`}
          </p>
          <a href={`mailto:${project.qa_director.email}`} target="_blank" rel="noreferrer">{project.qa_director.email}</a>
        </div>
      )
    }
    return ((project.other_qa_director && project.other_qa_director.length > 0) ? (
      <div>
        <p className="title">{t('project.summary.info.qaDirector')}</p>
        <p>{project.other_qa_director}</p>
      </div>
    ) : (
      <div />
    ))
  }

  return (
    <ProjectInfoStyled>
      <div className="row-infos first-row">
        <div>
          <p className="title">{t('project.summary.info.customer.title')}</p>
          <a href={`/customers/${project.customer.id}`} target="_blank" rel="noreferrer">{project.customer.name}</a>
        </div>
        <div>
          <p className="title">{t('project.summary.info.customer.contact')}</p>
          <p>{project.decision_maker}</p>
          <a href={`mailto:${project.customer_contact_email}`} target="_blank" rel="noreferrer">{project.customer_contact_email}</a>
        </div>
      </div>
      <div className="row-infos">
        <div>
          <p className="title">{t('project.summary.info.internalContact')}</p>
          <p>
            {`${project.business_contact.first_name} ${project.business_contact.last_name} - ${project.business_contact.nickname}`}
          </p>
          <a href={`mailto:${project.business_contact.email}`} target="_blank" rel="noreferrer">{project.business_contact.email}</a>
        </div>
        { qaDirectorInfo() }
      </div>
      <div className="row-infos">
        <div className="line-infos">
          <span>{t('project.form.kind')}</span>
          :
          <b>{t(`project.form.kinds.${project.kind}`)}</b>
        </div>
        <div className="line-infos">
          <span>{t('project.form.competition_type')}</span>
          :
          <b>{project.competition_type ? t(`project.form.competition_types.${project.competition_type}`) : '-'}</b>
        </div>
        <div className="line-infos">
          <span>{t('project.form.success_probability')}</span>
          :
          <b>
            {project.success_probability ? `${project.success_probability} %` : '-'}

          </b>
        </div>
        <div className="line-infos">
          <span>{t('project.form.remote')}</span>
          :
          <b>{project.remote ? t(`project.form.remotes.${project.remote}`) : '-'}</b>
        </div>
        <div className="line-infos">
          <span>{t('people.listing.columns.sap_id')}</span>
          :
          <b>{project.sap_number ? project.sap_number : '-'}</b>
        </div>
      </div>
    </ProjectInfoStyled>
  )
}
