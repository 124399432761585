import styled from 'styled-components'
import pictoInfo from '../../../assets/images/exclamationInfo.svg'

export const ProjectCciStyled = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
height: 9rem;

.octo {
  width: 100%;

  &.opened {
    > div.popup-tooltip {
      z-index: 11;
    }

    > div.popup-overlay {
      z-index: 10;
      pointer-events: none;
    }
  }
}

section {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40rem;

  .title {
    display: flex;

    h2 {
      margin-right: 0.8rem;
      margin-bottom: 0.9rem;
      font-weight: 600;
      font-size: 1.8rem;
    }

    .long-title {
      min-width: 75px;
    }

    .tooltip-trigger-button {
      width: 2rem;
      height: 2rem;
      background-image: url(${pictoInfo});
      background-position: center;
      background-clip: border-box;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  .cci-tooltip {
    p {
      margin-bottom: 1.4rem;
      font-size: 1.4rem;
    }

    ul {
      padding-left: 1.2rem;
      list-style: inside;
      font-size: 1.4rem;

      li {
        font-weight: 600;
      }
    }
  }

  .amount {
    font-weight: 600;
    font-size: 3.2rem;
  }

  .content {
    span {
      font-weight: 600;
    }
  }
}

.vertical-line {
  width: 0.1rem;
  height: 100%;
  border-right: solid 0.1rem ${({ theme }) => theme.colors.betterway.primary[20]};
}
`
