import React from 'react'
import { useTranslation } from 'react-i18next'
import { useManagerPeriodsContext } from '../../../context/ManagePeriods/ManagePeriodsContext'
import { ManagePeriodsValidationButtonStyled } from './ManagePeriodsValidationButton.styled'

export default function ManagePeriodsValidationButton() {
  const { t } = useTranslation()
  const {
    fetchPostValidateAllUnvalidatedPeriods,
    fetchGetUnvalidatedPeriods,
    periodsValidationButtonDisabled,
    setPeriodsValidationButtonDisabled,
    date,
  } = useManagerPeriodsContext()

  const validateUnvalidatedPeriods = async () => {
    await fetchPostValidateAllUnvalidatedPeriods()
    await fetchGetUnvalidatedPeriods(date.year, date.month, date.day)
  }
  return (
    <ManagePeriodsValidationButtonStyled>
      <button
        type="button"
        className={`validate-periods ${periodsValidationButtonDisabled ? 'btn-grey' : ''}`}
        aria-label="validate unvalidated periods"
        onClick={() => {
          setPeriodsValidationButtonDisabled(true)
          validateUnvalidatedPeriods()
        }}
        disabled={periodsValidationButtonDisabled}
      >
        {t('managePeriods.validatePeriods.validate')}
      </button>
    </ManagePeriodsValidationButtonStyled>
  )
}
