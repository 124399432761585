import React, { useState } from 'react'
import { Chevron } from '../../../../UI'
import { StyledAccordion } from './Accordion.styled'

const Accordion = ({ title, children, type }) => {
  const [unfold, setunfold] = useState(false)

  return (
    <details>
      <StyledAccordion
        onClick={() => setunfold(!unfold)}
        unfold={unfold}
        type={type}
      >
        <div className="title">
          <p>{title}</p>
          <Chevron unfold={unfold} fill="rgb(170, 165, 182)" />
        </div>
      </StyledAccordion>
      {children}
    </details>
  )
}

export default Accordion
