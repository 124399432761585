import React, { useState } from 'react'
import { StyledMonthPickerSimplified } from './MonthPickerSimplified.styled'
import SelectMonthYear from '../../SelectMonthYear/SelectMonthYear'

const MonthPickerSimplified = ({
  currentMonth,
  currentYear,
  startYear,
  onSubmitMonthYear,
}) => {
  const [selectedMonthYear, setSelectedMonthYear] = useState({
    month: currentMonth, year: currentYear,
  })

  return (
    <StyledMonthPickerSimplified>
      <SelectMonthYear
        name="month picker"
        month={selectedMonthYear.month}
        year={selectedMonthYear.year}
        startYear={startYear}
        endYear={currentYear + 5}
        onChangeSelectedMonthYear={(month, year) => setSelectedMonthYear({ month, year })}
      />
      <button
        className="btn"
        aria-label="month picker previous month"
        type="submit"
        onClick={() => {
          const previousMonth = new Date(selectedMonthYear.year, selectedMonthYear.month - 1)
          setSelectedMonthYear({
            month: previousMonth.getMonth(), year: previousMonth.getFullYear(),
          })
        }}
      >
        <i className="fa fa-chevron-left" />
      </button>
      <button
        className="btn"
        aria-label="month picker next month"
        type="submit"
        onClick={() => {
          const nextMonth = new Date(selectedMonthYear.year, selectedMonthYear.month + 1)
          setSelectedMonthYear({ month: nextMonth.getMonth(), year: nextMonth.getFullYear() })
        }}
      >
        <i className="fa fa-chevron-right" />
      </button>
      <button
        className="submitButton"
        aria-label="month picker submit"
        type="submit"
        onClick={() => onSubmitMonthYear(selectedMonthYear.month, selectedMonthYear.year)}
      >
        Ok
      </button>
    </StyledMonthPickerSimplified>
  )
}

export default MonthPickerSimplified
