import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStaffingContext } from '../../context/Staffing/StaffingContext'
import { Availabilities } from '../Availabilities/Availabilities'
import { StaffingTabsStyled } from './StaffingTabs.styled'
import { Opportunities } from '../Opportunities/Opportunities'
import speedCounter from '../../../assets/images/speedCounter.svg'
import heart from '../../../assets/images/heart.svg'

export const StaffingTabs = () => {
  const { t } = useTranslation()
  const {
    fetchGetOpportunities,
    filteredOpportunities,
    setSelectedBizDevs,
    setSelectedRoles,
    setSelectedMainRoles,
    fetchGetAvailabilities,
    setAvailabilitiesResult,
    availabilities,
    view,
    setView,
  } = useStaffingContext()

  const views = {
    'opportunities': <Opportunities />,
    'availabilities': <Availabilities />,
  }

  const opportunities = Object.keys(filteredOpportunities())

  return (
    <StaffingTabsStyled>
      <div>
        <ul role="tablist">
          <li role="presentation">
            <button
              role="tab"
              type="button"
              aria-selected={view === 'opportunities'}
              tabIndex={0}
              onClick={() => {
                window.stop()
                setView('opportunities')
                setSelectedBizDevs([])
                setSelectedRoles([])
                fetchGetOpportunities()
              }}
            >
              <img alt="" src={speedCounter} />
              { `${t('staffingTabs.tabsTitles.opportunities')} ${opportunities !== null && view === 'opportunities' ? `(${opportunities.length})` : ''}`}
            </button>
          </li>
          <li role="presentation">
            <button
              role="tab"
              type="button"
              aria-selected={view === 'availabilities'}
              tabIndex={0}
              onClick={() => {
                window.stop()
                setView('availabilities')
                setSelectedMainRoles([])
                setAvailabilitiesResult(null)
                fetchGetAvailabilities()
              }}
            >
              <img alt="" src={heart} />
              {`${t('staffingTabs.tabsTitles.availabilities')} ${(availabilities && view === 'availabilities') ? `(${availabilities.length})` : ''}`}
            </button>
          </li>
        </ul>
      </div>
      {views[view]}
    </StaffingTabsStyled>
  )
}
