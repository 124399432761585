import React from 'react'
import TimesheetErrors from '../TimesheetErrors/TimesheetErrors'
import { useTimesheetErrors } from '../../../../context/Timesheet/TimesheetErrorsContext'

const Anomalies = ({ nbDays }) => {
  const {
    periodsHasErrors,
    periodErrors,
  } = useTimesheetErrors()

  return (
    <>
      {
        periodsHasErrors
        && (
        <TimesheetErrors
          nbDays={nbDays}
          type="error"
          firstPeriodErrors={periodErrors?.firstPeriod?.messages}
          secondPeriodErrors={periodErrors?.secondPeriod?.messages}
          label="error"
        />
        )
      }
    </>
  )
}

export default Anomalies
