import React, { useRef, useState, useEffect, useContext, createContext } from 'react'
import { useFetch } from '../../fetchOverviewApi'
import { clamp } from '../../helpers/numberHelper'

const SynchronizationContext = createContext()

export const SynchronizationContextProvider = ({ children }) => {
  const timeout = useRef(null)
  const [synchronizationDateLaunch, setSynchronizationDateLaunch] = useState({})
  const [synchronizationUpdater, setSynchronizationUpdater] = useState({})
  const [jobLaunched, setJobLaunched] = useState(false)
  const [expensesExportDateLaunch, setExpensesExportDateLaunch] = useState({})
  const [expensesExportUpdater, setExpensesExportUpdater] = useState({})
  const [expensesExportJobLaunch, setExpensesExportJobLaunch] = useState(false)
  const [mailersDateLaunch, setMailersDateLaunch] = useState({})
  const [mailersUpdater, setMailersUpdater] = useState({})
  const [mailersJobLaunch, setMailersJobLaunch] = useState(false)
  const [alertClosed, setAlertClosed] = useState(true)
  const [progression, setProgression] = useState(null)
  const [errors, setErrors] = useState([])

  const updateProgression = ({ errors: errorsProgression, remaining, total }) => setProgression({
    errors: errorsProgression || [],
    remaining,
    done: total - remaining,
    total,
    percentage: clamp(1 - (Math.round((remaining / total) * 100) / 100), 0, 1),
  })

  const fetchLaunchSynchro = async () => {
    const response = await useFetch('GET', '/synchronization/generate_people_synchronisation_jobs')

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setSynchronizationDateLaunch(response.synchronization_date)
      setSynchronizationUpdater(response.synchronization_updater)
      setJobLaunched(response.job_launched)
      setAlertClosed(false)
      if (response.progression) {
        updateProgression(response.progression)
      }
    }
  }

  const fetchGetSynchronizationData = async () => {
    const response = await useFetch('GET', '/synchronization')

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setSynchronizationDateLaunch(response.synchronization_date)
      setSynchronizationUpdater(response.synchronization_updater)
      setJobLaunched(response.job_launched)
      setExpensesExportDateLaunch(response.expenses_export_date)
      setExpensesExportUpdater(response.expenses_export_updater)
      setExpensesExportJobLaunch(response.expenses_exported_job_launch)
      setMailersDateLaunch(response.mailers_date)
      setMailersUpdater(response.mailers_updater)
    }
  }

  const fetchLaunchExpensesExport = async () => {
    const response = await useFetch('GET', '/synchronization/generate_myte_expense_records_export')

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setExpensesExportDateLaunch(response.expenses_export_date)
      setExpensesExportUpdater(response.expenses_export_updater)
      setExpensesExportJobLaunch(response.expenses_exported_job_launch)

      if (response.progression) {
        updateProgression(response.progression)
      }
    }
  }

  const fetchGetLaunchMailers = async () => {
    const response = await useFetch('GET', '/synchronization/generate_myte_synchronization_mailers')

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setMailersDateLaunch(response.mailers_date)
      setMailersUpdater(response.mailers_updater)
      setMailersJobLaunch(true)
    }
  }

  const fetchGetSynchronizationProgression = async () => {
    const response = await useFetch('GET', '/synchronization/progression')

    if (response.errors) {
      return setErrors([...errors, ...response.errors])
    }

    if (!response || !response.progression) {
      return setErrors([...errors, 'progression should not be null'])
    }

    if (Number.isNaN(parseInt(response.progression.remaining, 10))) {
      return setErrors([...errors, 'remaining should be a number'])
    }

    if (Number.isNaN(parseInt(response.progression.total, 10))) {
      return setErrors([...errors, 'total should be a number'])
    }

    return updateProgression(response.progression)
  }

  useEffect(() => {
    if (!progression || progression.percentage < 1) {
      timeout.current = setTimeout(() => fetchGetSynchronizationProgression(), 2000)
    }

    return () => clearTimeout(timeout.current)
  }, [progression, jobLaunched])

  return (
    <SynchronizationContext.Provider value={{
      errors,
      fetchLaunchSynchro,
      fetchGetSynchronizationData,
      fetchGetSynchronizationProgression,
      fetchLaunchExpensesExport,
      fetchGetLaunchMailers,
      synchronizationDateLaunch,
      synchronizationUpdater,
      jobLaunched,
      expensesExportDateLaunch,
      expensesExportUpdater,
      expensesExportJobLaunch,
      mailersDateLaunch,
      mailersUpdater,
      mailersJobLaunch,
      alertClosed,
      setAlertClosed,
      progression,
    }}
    >
      {children}
    </SynchronizationContext.Provider>
  )
}

export const useSynchronizationContext = () => useContext(SynchronizationContext)
