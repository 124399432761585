import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectCoordinationContext } from '../../context/ProjectCoordination/ProjectCoordinationContext'
import { ProjectInvoices } from '../ProjectInvoices/ProjectInvoices'
import { ProjectCoordinationExpensesTable } from '../ProjectCoordinationExpensesTable/ProjectCoordinationExpensesTable'
import { ProjectCoordinationExpensesStyled } from './ProjectCoordinationExpenses.styled'

export const ProjectCoordinationExpenses = () => {
  const { t } = useTranslation()
  const { isProjectPresales } = useProjectCoordinationContext()

  return (
    <ProjectCoordinationExpensesStyled className="octo">
      <ProjectInvoices />
      {
        !isProjectPresales() && (
        <>
          <h3>{t('project.coordination.expenses.chargeable.title')}</h3>
          <p>
            <span>{t('project.coordination.expenses.chargeable.description.0')}</span>
            <b>{t('project.coordination.expenses.chargeable.description.1')}</b>
          </p>
          <ProjectCoordinationExpensesTable category="chargeable" />
          <h3>{t('project.coordination.expenses.nonchargeable.title')}</h3>
          <p>
            <span>{t('project.coordination.expenses.nonchargeable.description.0')}</span>
            <b>{t('project.coordination.expenses.nonchargeable.description.1')}</b>
          </p>
          <ProjectCoordinationExpensesTable category="nonchargeable" />
        </>
        )
      }
    </ProjectCoordinationExpensesStyled>
  )
}
