import styled from 'styled-components'

export const StyledHPIQuestion = styled.div`  
  font-size: 1.2rem;
  box-sizing: border-box;
  padding: 0 1rem;
  height: 7rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme, even }) => (even ? theme.colors.paleGrey3 : theme.colors.paleGrey4)};
  
  p {
    width: 55%;
    margin-right: 2rem;
  }

  .form-radio {
    display: flex;
    position: relative;

    input {
      display: none;

      &:checked ~ label {
        border: 2pt solid ${({ theme }) => theme.colors.primaryBlue};
        background: ${({ theme }) => theme.colors.primaryBlue};
        color: ${({ theme }) => theme.colors.white};
      }

      &:not(:checked) ~ label {
        &:hover {
          background: ${({ theme }) => theme.colors.lightGrey};
        }
      }

      &:checked ~ span,
      &:hover ~ span {
        display: block;
      }
    }

    label {
      cursor: pointer;
      font-family: ${({ theme }) => theme.fonts.family.bold};
      display: flex;
      margin-bottom: 0;
      margin-right: 0.5rem;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      border: 2pt solid ${({ theme }) => theme.colors.skeletonGreyDark};
      height: 80%;
      width: auto;
      padding: 0.6rem;
    }

    img {
      height: auto;
      width: 100%;
    }

    span {
      display: none;
      position: absolute;
      width: 100%;
      bottom: -45%;
      left: 45%;
      transform: translateX(-50%);
      text-align: center;
      font-size: 1rem;
    }
  }
`
