import styled from 'styled-components'

export const StyledActivityLines = styled.tr` 
  th {
    padding: 1.5rem 0 0.8rem 0.8rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.primaryGrey};
    letter-spacing: 1px;
    font-size: 1.2rem;
    text-align: left;
  }

  th:nth-child(1) {
    border-left: 1px solid ${({ theme }) => theme.colors.lighterGray};
  }

  td {
    border-right: 1px solid ${({ theme }) => theme.colors.lighterGray};
    &[data-day="15"] {
      border-right: none;
    }
    &[data-day="16"] {
      border-left: solid 1px ${({ theme }) => theme.colors.grey} !important;
    }
    &[data-day="1"] {
      border-left: solid 1px ${({ theme }) => theme.colors.grey};
    }
    &.holiday {
      background-color: ${({ theme, even }) => (even ? theme.colors.lighterGray : theme.colors.paleGrey2)};
      border-right: none;
    }
    &:nth-last-child(2) {
      border-right: solid 1px ${({ theme }) => theme.colors.grey};
    }
    &.validated {
      opacity: 0.2;
    }
  }

  &.no-details {
    td {
      color: ${({ theme }) => theme.colors.white};
      text-align: center;
    }

    td.fill div span {
      padding-bottom: 1px;
    }

    td:last-child {
      color: inherit;
      padding: 5px 0;
      text-align: center;
      border-right: solid 1px ${({ theme }) => theme.colors.lighterGray};
      font-family: ${({ theme }) => theme.fonts.family.bold};
    }
  }
`
