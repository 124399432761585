import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledRemoteOfficeDaysForm } from './RemoteOfficeDaysForm.styled'

const RemoteOfficeDaysForm = ({
  hasRemoteDaysError,
  hasCustomerSiteDaysError,
  daysInRemote,
  debounceUpdateNbDaysInRemote,
  // daysInCustomerSite,
  // debounceUpdateNbDaysInCustomerSite,
  workingDaysCount,
}) => {
  const { t } = useTranslation()

  return (
    <StyledRemoteOfficeDaysForm>
      <h3>{t('periodValidationModal.remote.title')}</h3>
      <div
        role="input-rules"
        className={['input-rules', (hasRemoteDaysError || hasCustomerSiteDaysError) && 'alert'].join(' ')}
      >
        <p>{t('periodValidationModal.remote.inputRule')}</p>
        <p>{t('periodValidationModal.remote.maxDaysExceeded', { days: workingDaysCount })}</p>
      </div>
      <div className="question">
        <p id="remote-question">{t('periodValidationModal.remote.remoteQuestion')}</p>
        <input
          aria-labelledby="remote-question"
          type="number"
          step="0.5"
          min="0"
          defaultValue={daysInRemote}
          onChange={(event) => debounceUpdateNbDaysInRemote(event.target.value)}
        />
      </div>
    </StyledRemoteOfficeDaysForm>
  )
}

export default RemoteOfficeDaysForm
