import styled from 'styled-components'
import pictoInfo from '../../../assets/images/exclamationInfo.svg'

export const ProjectDataHatInfoStyled = styled.div`
&.octo {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: stretch;

  > span {
    ::after {
      content: '\\00a0';
    }
  }

  > b {
    font-weight: 600;
  }

  >.value-tooltip {
    align-self: center;
    margin-left: 1rem;

    >.popup-trigger {
      >button {
        width: 2rem;
        height: 2rem;
        background-image: url(${pictoInfo});
        background-position: center;
        background-clip: border-box;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }

    >.popup-tooltip {
      font-weight: 200;
      font-size: 1.4rem;

      > div {
        > p {
          > b {
            display: inline;
            font-weight: 600;
          }
        }
      }
    }
  }

  &.large {
    > b {
      font-size: 2.4rem;
    }
  }
}
`
