import React from 'react'
import { Tag } from '@produits-internes-oss/design-system-components'
import { ReleaseNoteStyled } from './ReleaseNote.styled'

/* eslint-disable react/no-danger */

export function ReleaseNote({ releaseNotes }) {
  return (
    <ReleaseNoteStyled>
      <div className="page-content">
        <h2 className="title">Release Notes</h2>
        {
          releaseNotes.map((releaseNote) => (
            <div key={`release_note_id_${releaseNote.id}`} className="release-note-container">
              <h3 className="release-note-subtitle">
                <div className="date">{releaseNote.date}</div>
                <Tag className="application-name" color={releaseNote.application_color || 'marine'} text={releaseNote.application_name} />
                <Tag className="epic-name" color={releaseNote.epic_color || 'turquoise'} text={releaseNote.epic_name} />
              </h3>
              <div className="release-note-text">

                <div dangerouslySetInnerHTML={{ __html: releaseNote.text }} className="text" />
              </div>
            </div>
          ))
        }
      </div>
    </ReleaseNoteStyled>
  )
}
