import styled from 'styled-components'
import pictoLink from '../../../assets/images/link.svg'

export const ProjectStatusHistoryStyled = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: stretch;

  >.history {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    padding: 1.6rem 0.2rem 0;

    >.log {
      width: 25%;

      >.gauge {
        position: relative;
        width: 100%;

        ::before {
          content: '';
          position: relative;
          z-index: 1;
          display: block;
          box-sizing: border-box;
          width: 2rem;
          height: 2rem;
          border-width: 0.3rem;
          border-style: solid;
          border-color: ${({ theme }) => theme.colors.betterway.primary[70]};
          border-radius: 2rem;
          background-color: ${({ theme }) => theme.colors.betterway.primary[20]};
        }

        ::after {
          content: '';
          position: absolute;
          top: calc(50% - 0.1rem);
          right: 0;
          z-index: 0;
          width: 100%;
          height: 0.2rem;
          background: ${({ theme }) => theme.colors.betterway.primary[50]};
        }
      }

      >.details {
        margin-top: 1.8rem;

        >.date {
          font-weight: normal;
          font-size: 1.4rem;
        }

        >.status {
          font-weight: 600;
          font-size: 1.6rem;
        }

        >.person {
          margin-top: 0.8rem;
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          font-weight: normal;
          font-size: 1.4rem;
          text-decoration: underline;

          &:hover {
            color: ${({ theme }) => theme.colors.betterway.secondary[100]};
          }
        }
      }

      &:nth-last-child(2) {
        >.gauge {
          ::after {
            background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
          }
        }
      }

      &:last-child {
        >.gauge {
          ::before {
            border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
            background-color: ${({ theme }) => theme.colors.betterway.secondary[20]};
          }

          ::after {
            display: none;
          }
        }
      }
    }
  }

  >.link {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    align-self: flex-end;
    justify-content: flex-end;
    margin-top: 3.2rem;
    color: ${({ theme }) => theme.colors.betterway.primary[100]};
    text-decoration: underline;
    cursor: pointer;

    >.link-icon {
      display: inline-block;
      width: 2.4rem;
      height: 2.4rem;
      background-color: currentColor;
      mask-image: url(${pictoLink});
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-clip: border-box;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.betterway.secondary[100]};
    }
  }
`
