import React from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { StyledModal } from './Modal.styled'
import Backdrop from '../Backdrop/Backdrop'

const Modal = ({
  open,
  close,
  height,
  width,
  noPadding,
  children,
  className,
}) => (
  ReactDOM.createPortal(
    <>
      <Backdrop open={open} close={close} />
      <CSSTransition
        in={open}
        mountOnEnter
        unmountOnExit
        timeout={300}
        classNames="modal-animated"
      >
        <StyledModal
          className={`modal-animated ${className}`}
          height={height}
          width={width}
          noPadding={noPadding}
        >
          {children}
          <button
            className="close-button"
            type="button"
            onClick={close}
            aria-label="Close"
          />
        </StyledModal>
      </CSSTransition>
    </>,
    document.getElementById('backdrop-hook'),
  )
)

export default Modal
