import styled from 'styled-components'

export const StyledHappinessIndicatorForm = styled.div`
  margin-bottom: 5rem;
  
  h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  h6 {
    color: inherit;
    font-size: inherit;
    text-transform: none;
    margin-bottom: 2rem;
    font-family: ${({ theme }) => theme.fonts.family.bold};
  }
`
