import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useManagerPeriodsContext } from '../../../context/ManagePeriods/ManagePeriodsContext'
import Button from '../../input/_ExportDS/Button/Button'
import { PeriodPickerStyled } from './PeriodPicker.styled'
import { monthList } from '../../../helpers/datePickerHelper'
import NavigationButton from '../../input/Buttons/NavigationButton/NavigationButton'
import PeriodPickerBlock from './PeriodPickerBlock/PeriodPickerBlock'

export const PeriodPicker = ({ className }) => {
  const {
    date,
    setDate,
    setPeriodsValidationButtonDisabled,
    fetchGetUnvalidatedPeriods,
    fetchGetPeriodsWithEmptyDays,
    fetchGetPeriodsToReview,
    activeTab,
  } = useManagerPeriodsContext()
  const { t } = useTranslation()
  const month = t(`months.${monthList[date.month - 1]}.full`)
  const monthCapitalize = month.charAt(0).toUpperCase() + month.slice(1)
  const [open, setOpen] = useState(false)
  const [dateOfBlocks, setDateOfBlocks] = useState(date)
  const currentPeriod = { period: date.day <= 15 ? 1 : 2, month: date.month, year: date.year }

  const getCurrentActiveTabData = (newYear, newMonth, newDay) => {
    switch (activeTab) {
      case 'unvalidated':
        fetchGetUnvalidatedPeriods(newYear, newMonth, newDay)
        break
      case 'emptyDays':
        fetchGetPeriodsWithEmptyDays(newYear, newMonth, newDay)
        break
      case 'toReview':
        fetchGetPeriodsToReview(newYear, newMonth, newDay)
        break
      default:
        fetchGetUnvalidatedPeriods(newYear, newMonth, newDay)
        break
    }
  }

  function monthOfDatePickerBlock(dateToChange, monthToAdd) {
    if (dateToChange.month === 12 && monthToAdd >= 1) {
      return { year: dateToChange.year + 1, month: 1, day: dateToChange.day }
    }
    if (dateToChange.month === 1 && monthToAdd <= -1) {
      return { year: dateToChange.year - 1, month: 12, day: dateToChange.day }
    }
    return { year: dateToChange.year, month: dateToChange.month + monthToAdd, day: dateToChange.day }
  }

  useEffect(() => {
    setDateOfBlocks(date)
  }, [date])

  function handlePopupClose() {
    setOpen(!open)
  }

  function monthOfNavigationButton(dateToChange, monthToAdd) {
    if (monthToAdd === 3) {
      if ([10, 11, 12].includes(dateToChange.month)) {
        return { year: dateToChange.year + 1, month: (dateToChange.month + monthToAdd) % 12, day: dateToChange.day }
      }
      return { year: dateToChange.year, month: dateToChange.month + monthToAdd, day: dateToChange.day }
    }
    if ([1, 2, 3].includes(dateToChange.month)) {
      let newMonth
      switch (dateToChange.month) {
        case 1:
          newMonth = 10
          break
        case 2:
          newMonth = 11
          break
        case 3:
          newMonth = 12
          break
        default:
      }
      return { year: dateToChange.year - 1, month: newMonth, day: dateToChange.day }
    }
    return { year: dateToChange.year, month: dateToChange.month + monthToAdd, day: dateToChange.day }
  }

  function prevPeriod(currentDate) {
    let newDate
    if (currentDate.day <= 15) {
      newDate = monthOfDatePickerBlock(currentDate, -1)
      newDate.day = 16
    } else {
      newDate = monthOfDatePickerBlock(currentDate, 0)
      newDate.day = 1
    }
    setDate(newDate)
    setPeriodsValidationButtonDisabled(false)
    getCurrentActiveTabData(newDate.year, newDate.month, newDate.day)
  }

  function nextPeriod(currentDate) {
    let newDate
    if (currentDate.day > 15) {
      newDate = monthOfDatePickerBlock(currentDate, 1)
      newDate.day = 1
    } else {
      newDate = monthOfDatePickerBlock(currentDate, 0)
      newDate.day = 16
    }
    setDate(newDate)
    setPeriodsValidationButtonDisabled(false)
    getCurrentActiveTabData(newDate.year, newDate.month, newDate.day)
  }

  return (
    <PeriodPickerStyled className={className}>
      <div className="title">
        {t('managePeriods.periodPicker.title')}
      </div>
      <div className="buttons-period-picker">
        <NavigationButton
          className="navigation-period-button-prev"
          type="chevron"
          ariaLabel="prev period button"
          click={() => prevPeriod(date)}
        />
        <Button
          className="trigger"
          name="period picker trigger"
          text={`${monthCapitalize} ${date.year} - ${date.day <= 15 ? t('managePeriods.periodPicker.firstPeriod') : t('managePeriods.periodPicker.secondPeriod')}`}
          onClick={() => setOpen(!open)}
        />
        <NavigationButton
          className="navigation-period-button-next"
          type="chevron"
          ariaLabel="next period button"
          click={() => nextPeriod(date)}
        />
      </div>
      {open && (
      <div className="period-picker-popup">
        <NavigationButton
          className="navigation-popup-button"
          type="prev"
          ariaLabel="prev three month button"
          click={() => setDateOfBlocks(monthOfNavigationButton(dateOfBlocks, -3))}
        />
        <PeriodPickerBlock
          date={monthOfDatePickerBlock(dateOfBlocks, -1)}
          handlePopupClose={() => handlePopupClose()}
          getCurrentActiveTabData={(currentYear, currentMonth, currentDay) => getCurrentActiveTabData(currentYear, currentMonth, currentDay)}
        />
        <PeriodPickerBlock
          date={monthOfDatePickerBlock(dateOfBlocks, 0)}
          currentPeriod={currentPeriod}
          handlePopupClose={() => handlePopupClose()}
          getCurrentActiveTabData={(currentYear, currentMonth, currentDay) => getCurrentActiveTabData(currentYear, currentMonth, currentDay)}
        />
        <PeriodPickerBlock
          date={monthOfDatePickerBlock(dateOfBlocks, 1)}
          handlePopupClose={() => handlePopupClose()}
          getCurrentActiveTabData={(currentYear, currentMonth, currentDay) => getCurrentActiveTabData(currentYear, currentMonth, currentDay)}
        />
        <NavigationButton
          className="navigation-popup-button"
          type="next"
          ariaLabel="next three month button"
          click={() => setDateOfBlocks(monthOfNavigationButton(dateOfBlocks, 3))}
        />
      </div>
      )}
    </PeriodPickerStyled>
  )
}
