import styled from 'styled-components'

export const ProjectErrorsSummaryStyled = styled.div`
  .errors-date {
    font-weight: normal;

    strong {
      font-weight: 600;
    }
  }

  .warning-price {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: normal;

    ul {
      margin: 1.6rem 0;

      li {
        font-weight: 600;
      }
    }

    .warning-text {
      font-weight: normal;

      strong {
        font-weight: 600;
      }
    }
  }

  .list-error {
    margin-bottom: 0.8rem;
  }

`
