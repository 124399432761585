import styled from 'styled-components'

export default styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  min-height: 4rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.betterway.secondary[100]};
  border-radius: 1rem;
  text-align: center;

  >.name {
    overflow: hidden;
    margin: 0 1rem;
    text-overflow: ellipsis;
  }

  > img {
    width: 100% !important;
    height: auto !important;
  }

  >.destroy {
    max-width: 2.4rem;
    max-height: 2.4rem;
    margin-right: 1rem;

    > button {
      padding: 0.6rem;
      border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
      color: ${({ theme }) => theme.colors.betterway.primary[100]};

      >.picto {
        width: 100%;
        height: 100%;
      }
    }

    &.floating {
      position: absolute;
      top: 1rem;
      right: 0;
    }
  }
`
