import styled from 'styled-components'

export const ProjectCoordinationExpensesStyled = styled.div`
&.octo {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.6rem;
  align-items: stretch;
  justify-content: space-between;

  > h3 {
    font-weight: 600;
    font-size: 2.4rem;
  }

  > p {
    > b {
      font-weight: 600;
    }
  }
}
`
