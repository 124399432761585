import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTimesheetErrors, StyledErrorsBlock } from './TimesheetErrors.styled'

export const TimesheetErrors = ({
  nbDays, firstPeriodErrors, secondPeriodErrors, type, label,
}) => (
  <StyledTimesheetErrors role="timesheet-errors">
    <tr>
      <th colSpan={2} />
      <th colSpan={15}>
        {
            firstPeriodErrors?.length > 0
            && <ErrorsBlock type={type} errors={firstPeriodErrors} label={label} />
          }
      </th>
      <th colSpan={nbDays - 15}>
        {
            secondPeriodErrors?.length > 0
            && <ErrorsBlock type={type} errors={secondPeriodErrors} label={label} />
          }
      </th>
      <th colSpan={1} />
    </tr>
  </StyledTimesheetErrors>
)

export const ErrorsBlock = ({ type, errors, label }) => {
  const { t } = useTranslation()

  return (
    <StyledErrorsBlock type={type}>
      <h4>
        {t(`timesheet.${label}.message`)}
      </h4>
      <ul>
        {errors.map((error) => (
          <li key={error}>
            <span dangerouslySetInnerHTML={{ __html: error }} />
          </li>
        ))}
      </ul>
      <h4>
        {t(`timesheet.${label}.check`)}
      </h4>
    </StyledErrorsBlock>
  )
}

export default TimesheetErrors
