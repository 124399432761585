import React, { Component } from 'react'
import { Translation } from 'react-i18next'

import TextArea from '../input/TextArea/TextArea'
import { requestWithCSRFToken } from '../../javascripts/HttpService'

class StaffingInfoContainer extends Component {
  onChange = (staffingInfo) => {
    const { person, getProfile } = this.props
    const url = '/people/staffing_info'
    const body = JSON.stringify({ person: { id: person.id, staffing_info: staffingInfo } })
    const options = { method: 'PUT', body }

    requestWithCSRFToken(url, options)
      .then(() => {
        getProfile()
      })
  }

  updateSTaffingInfoBlock() {
    const { staffingInfo } = this.props
    const { updated_at, updated_by } = staffingInfo
    const updated_at_date = new Date(updated_at).toLocaleDateString('fr-FR')
    const updated_at_time = new Date(updated_at).toLocaleTimeString('fr-FR')

    if (updated_at && updated_by) {
      return (
        <Translation>
          {(t) => <p>{t('profile.updated_on', { date: updated_at_date, person: updated_by, time: updated_at_time })}</p>}
        </Translation>
      )
    }
  }

  render() {
    const { staffingInfo } = this.props

    return (
      <div>
        <Translation>
          {(t) => (
            <label htmlFor="staffing-info-field">
              <h4 className="profile-section-message">
                {t('staffingInfo.message')}
              </h4>
            </label>
          )}
        </Translation>
        {this.updateSTaffingInfoBlock()}
        <TextArea
          value={staffingInfo.staffing_info}
          onChange={this.onChange}
        />
      </div>
    )
  }
}

export default StaffingInfoContainer
