import styled from 'styled-components'

export const StyledActivityPopin = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1001;
  width: 55rem;
  padding: 2rem;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.colors.shadow};
  cursor: default;
`
