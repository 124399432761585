import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { InputNumber, Section, InputTextarea, Button } from '@produits-internes-oss/design-system-components'
import checkWhite from '../../../assets/images/checkWhite.svg'
import warningRoundRed from '../../../assets/images/warningRoundRed.svg'
import pictoModify from '../../../assets/images/iconsModify.svg'
import { getClass, getNumberWithUnit } from '../../helpers/stringHelper'
import { ProjectPaymentScheduleStyled } from './ProjectPaymentSchedule.styled'
import { useProjectContractContext } from '../../context/ProjectContract/ProjectContractContext'

export const ProjectPaymentSchedule = ({ personIsAdmin }) => {
  const { t } = useTranslation()
  const {
    project,
    paymentSchedule,
    fetchPutPaymentSchedule,
  } = useProjectContractContext()
  const [form, setForm] = useState(null)
  const [displayEditionForm, setDisplayEditionForm] = useState(null)
  const [includedFees, setIncludedFees] = useState(null)
  const formEmpty = form && ((form.totalAmount === null || form.totalAmount === 0) && form.feesIncluded === false && (form.comment === null || form.comment === ''))

  const projectStatusEditableOnlyByFinance = () => {
    const editableByFinanceStatus = ['mission_signed', 'mission_done']
    return editableByFinanceStatus.includes(project?.status.current)
  }

  useEffect(() => {
    if (paymentSchedule) {
      setForm({
        id: paymentSchedule.id || null,
        totalAmount: paymentSchedule.total_amount || null,
        comment: paymentSchedule.comment || null,
        feesIncluded: paymentSchedule.fees_included,
      })
      setDisplayEditionForm(form && (form.totalAmount === null && form.feesIncluded === null && form.comment === null))
      setIncludedFees(form && form.feesIncluded)
    }
  }, [paymentSchedule])

  const amountTitleByProjectKind = () => (
    (project && project.kind === 'fixed_price') ? t('project.contract.paymentSchedule.fixedPriceAmount') : t('project.contract.paymentSchedule.costReimbursableAmount')
  )

  const paymentScheduleForm = useMemo(() => (
    <div className="contract-payment-schedule">
      <div className="form-section">
        <div className="left-section">
          <InputNumber
            className="schedule-amount price-tag"
            skin="light"
            name={amountTitleByProjectKind()}
            title={amountTitleByProjectKind()}
            valueInitial={form && form.totalAmount}
            disabled={(!personIsAdmin && projectStatusEditableOnlyByFinance()) || (!personIsAdmin && (project && project.nature === 'training'))}
            localization={{
              invalid: { text: t('expenses.errors.mustBeANumber'), arialabel: t('expenses.errors.mustBeANumber') },
            }}
            onCheck={(value) => value < 0 && `${t('expenses.errors.greaterThanOrEqualToZero')}`}
            onChange={(totalAmount) => totalAmount >= 0 && setForm({ ...form, totalAmount })}
          />
          <div className={getClass((includedFees || (form && form.feesIncluded)) && 'checked')}>
            <button
              type="button"
              aria-label="checkbox"
              disabled={(!personIsAdmin && projectStatusEditableOnlyByFinance()) || (!personIsAdmin && (project && project.nature === 'training'))}
              onClick={() => {
                const feesIncluded = !includedFees
                setIncludedFees(!includedFees)
                setForm({ ...form, feesIncluded })
              }}
            >
              {
                <span className="checkbox">
                  {includedFees && <img src={checkWhite} alt="" className="checkbox-icon" />}
                </span>
              }
              {
                <span className="text">{t('project.contract.paymentSchedule.feesIncluded.translate')}</span>
              }
            </button>
          </div>
        </div>
        <div className="right-section">
          <InputTextarea
            className="schedule-comment"
            skin="light"
            name={t('project.contract.paymentSchedule.comment')}
            title={t('project.contract.paymentSchedule.comment')}
            disabled={(!personIsAdmin && projectStatusEditableOnlyByFinance()) || (!personIsAdmin && (project && project.nature === 'training'))}
            valueInitial={form && form.comment}
            maxLength={255}
            onChange={(comment) => {
              setForm({ ...form, comment })
            }}
          />
          <div className="comment-limit">
            <img src={warningRoundRed} alt="" />
            <p>{t('project.contract.paymentSchedule.textAreaLimit')}</p>
          </div>
        </div>
      </div>
      <div className="cta">
        <Button
          className="cancel"
          skin="light"
          kind="secondary"
          name={t('project.contract.paymentSchedule.cancel')}
          text={t('project.contract.paymentSchedule.cancel')}
          onClick={() => {
            setDisplayEditionForm(false)
          }}
        />
        <Button
          className="submit"
          skin="dark"
          name={t('project.contract.paymentSchedule.add')}
          text={t('project.contract.paymentSchedule.add')}
          disabled={formEmpty || (!personIsAdmin && projectStatusEditableOnlyByFinance()) || (!personIsAdmin && (project && project.nature === 'training'))}
          onClick={() => {
            setDisplayEditionForm(false)
            fetchPutPaymentSchedule(form)
          }}
        />
      </div>
    </div>
  ), [form])

  const translateFeesIncluded = () => (
    (form && form.feesIncluded === true)
      ? t('project.contract.paymentSchedule.feesIncluded.yes')
      : t('project.contract.paymentSchedule.feesIncluded.no')
  )

  const feesIncludedClassName = () => ((form && form.feesIncluded === true) ? 'included' : 'not-included')

  const noFeesIncludedSectionClassName = () => (
    form && form.feesIncluded === null ? 'no-fees' : ''
  )

  const dashboard = () => (
    <div className="payment-schedule-dashboard">
      <div className="modify-btn-section">
        <Button
          className="btn-modify"
          skin="dark"
          name={t('project.contract.paymentSchedule.edit')}
          text={t('project.contract.paymentSchedule.edit')}
          onClick={() => {
            setDisplayEditionForm(true)
          }}
          icon={{ url: pictoModify, position: 'right' }}
        />
      </div>
      <div className="dashboard">
        <div className={getClass('amount', noFeesIncludedSectionClassName())}>
          <p>{amountTitleByProjectKind()}</p>
          {
            (form && form.totalAmount) ? <p>{ getNumberWithUnit(form.totalAmount, '€')}</p> : <span>-</span>
          }

        </div>
        <div className={getClass('comment', noFeesIncludedSectionClassName())}>
          <p>{t('project.contract.paymentSchedule.comment')}</p>
          <p>{(form && form.comment) ? form.comment : '-' }</p>
        </div>
        {
          form && form.feesIncluded !== null && (
            <div className={getClass(form && form.feesIncluded !== null ? 'fees' : 'fees-hidden')}>
              <p>{t('project.contract.paymentSchedule.feesIncluded.translate')}</p>
              <div className="box-container">
                <div className={getClass('fees-box', feesIncludedClassName())}>
                  <p>{translateFeesIncluded()}</p>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )

  if (paymentSchedule === null) {
    return (
      <ProjectPaymentScheduleStyled>
        <Section title={{ text: t(`project.contract.paymentSchedule.title`), level: 2 }}>...</Section>
      </ProjectPaymentScheduleStyled>
    )
  }

  return (
    <ProjectPaymentScheduleStyled className="octo schedule-container">
      <Section title={{ text: t('project.contract.paymentSchedule.title'), level: 2 }}>
        {
          displayEditionForm ? paymentScheduleForm : dashboard()
        }
      </Section>
    </ProjectPaymentScheduleStyled>
  )
}
