import React from 'react'
import AutoCompleteSelect from '../AutoCompleteSelect/AutoCompleteSelect'

import { request } from '../../../javascripts/HttpService'

export default function PersonPicker({ className, value, onChange, withPeopleLeft }) {
  const loadOptions = (inputValue) => {
    const url = `/people?format=json&search=${inputValue}&with_people_left=${withPeopleLeft}`
    return request(url, { method: 'GET' }).then((data) => data)
  }

  return (
    <div className={className}>
      <AutoCompleteSelect
        value={value}
        loadOptions={loadOptions}
        onChange={onChange}
        getOptionValue={(option) => option.selectedValue}
        noOptionsMessageType="default"
      />
    </div>
  )
}
