import styled from 'styled-components'

export const SynchronizationHeaderStyled = styled.div`
  > h1 {
    font-size: 3rem;
  }

  > h2 {
    margin-top: 1.6rem;
    font-size: 1.8rem;
    line-height: normal;
  }

  .in-progress-alert {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    padding: 1.2rem;
    border: 0.1rem solid ${({ theme }) => theme.colors.betterway.secondary[100]};
    border-radius: 1.5rem;
    background-color: ${({ theme }) => theme.colors.betterway.secondary[5]};

    .img-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.48rem;
      height: 3.48rem;
      margin-right: 1.8rem;
      border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[100]};
      background-color: ${({ theme }) => theme.colors.betterway.white};
    }

    p {
      margin: 0;
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      font-family: ${({ theme }) => theme.fonts.family.bold};
    }

    .close-btn {
      img {
        position: absolute;
        top: 11px;
        right: 17px;
        width: 1.2rem;
      }
    }
  }
`
