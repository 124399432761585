import { useMemo, useState } from 'react'
import { useFetch } from '../../fetchOverviewApi'

export const useSearch = () => {
  const [errors, setErrors] = useState([])

  const fetchSearch = async (search, model, scope = null, callback = null) => {
    const response = await useFetch('GET', `/search?search=${search}&model=${model}&scope=${scope}`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
      return null
    }

    return Object.fromEntries(Object.entries(response).map(([id, item]) => [id, callback ? callback(item) : item]))
  }

  return useMemo(() => ({ errors, fetchSearch }), [errors, fetchSearch])
}
