import styled from 'styled-components'

export default styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  z-index: 999;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  >.spin {
    width: 4rem;
    height: 4rem;
    margin: 1rem;
    border: 0.3em solid ${({ theme }) => theme.colors.betterway.secondary[50]};
    border-radius: 50%;

    >.spin-interior {
      box-sizing: content-box;
      width: 100%;
      height: 100%;
      margin: -0.3rem;
      border: 0.3em solid transparent;
      border-top-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
      border-radius: 50%;
      animation: 1s spin linear infinite;
    }
  }
`
