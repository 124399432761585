import React from 'react'
import './ExpensesReportByKind.scss'
import { useTranslation } from 'react-i18next'
import ExpensesReportRow from '../ExpensesReportRow/ExpensesReportRow'

const ExpensesReportByKind = ({
  kind, total, currency, expenses,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {
         expenses.map((expense, index) => (
           <ExpensesReportRow
             key={index}
             index={index}
             kind={kind}
             expense={expense}
             currency={currency}
             expensesCount={expenses.length}
           />
         ))
      }
      <tr className="expense-total-by-kind">
        <td colSpan={3}>
          {t(`ExpensesReportTable.total_${kind}`)}
        </td>
        <td>
          {total}
          {' '}
          {currency}
        </td>
        <td colSpan={2} />
      </tr>
    </>
  )
}

export default ExpensesReportByKind
