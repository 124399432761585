import React from 'react'
import { getClass } from '../../../../helpers/stringHelper'
import InputStyled from './Input.styled'
import pictoWarning from '../../../../../assets/images/attention.svg'

export default function Input({
  className, name, title, type, value, error, disabled, onChange, onBlur,
}) {
  return (
    <InputStyled className={getClass(className, error && 'error')} pictoWarning={pictoWarning}>
      {
        title && <div className="title">{title}</div>
      }
      <input
        style={{ boxShadow: 'initial' }}
        type={type || 'text'}
        aria-label={`${name} input`}
        value={value || value === 0 ? value : ''}
        disabled={disabled}
        onChange={({ target }) => disabled || onChange(target.value.toString())}
        onBlur={() => disabled || onBlur()}
      />
      {
        error && (
        <div className="description">
          <span className="description-icon" />
          <span className="description-text">{error}</span>
        </div>
        )
      }
    </InputStyled>
  )
}
