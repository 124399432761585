import React from 'react'
import { getClass } from '../../../../helpers/stringHelper'
import InputStyled from './Input.styled'
import pictoWarning from '../../../../../assets/images/attention.svg'

export default function Input({
  className, name, title, type, value, symbol, error, placeholder, disabled, onChange, onValidate,
}) {
  return (
    <InputStyled className={getClass(className, 'eds-input', error && 'error')} pictoWarning={pictoWarning}>
      {
        title && <label className="title" htmlFor={name}>{title}</label>
      }
      <div className="input">
        <input
          style={{ boxShadow: 'initial' }}
          type={type || 'text'}
          id={name}
          name={name}
          aria-label={`${name} input`}
          value={value || value === 0 ? value : ''}
          placeholder={placeholder}
          disabled={disabled}
          onChange={({ target }) => disabled || onChange(target.value.toString())}
          onKeyDown={({ key }) => key === 'Enter' && onValidate()}
        />
        {
          symbol && <i className="symbol">{symbol}</i>
        }
      </div>
      {
        error && (
          <div className="description">
            <span className="description-icon" />
            <span className="description-text">{error}</span>
          </div>
        )
      }
    </InputStyled>
  )
}
