import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectTurnoverBreakdownContext } from '../../context/ProjectTurnoverBreakdownContext/ProjectTurnoverBreakdownContext'
import { ProjectTurnoverBreakdown } from '../../structures/ProjectTurnoverBreakdown/ProjectTurnoverBreakdown'
import { Alert } from '../input/_ExportDS/Alert/Alert'

export const ProjectTurnoverBreakdownAlert = ({ className }) => {
  const { t } = useTranslation()
  const { turnoverBreakdown, fetchPutTurnoverBreakdownReopen } = useProjectTurnoverBreakdownContext()

  return turnoverBreakdown && turnoverBreakdown.closed && turnoverBreakdown.project.kind === ProjectTurnoverBreakdown.PROJECT_TYPE_F
    ? (
      <Alert
        className={className}
        name="turnover breakdown closing"
        text={t('turnoverBreakdown.closed')}
        button={{
          text: `${t('turnoverBreakdown.actions.reopen')} ${t('turnoverBreakdown.actions.project.short')}`,
          onClick: () => fetchPutTurnoverBreakdownReopen(),
        }}
      />
    )
    : null
}
