import React, { useState } from 'react'
import ActivityOverviewRow from '../ActivityOverviewRow/ActivityOverviewRow'

const CompanyRow = ({ data, children }) => {
  const [open, setOpen] = useState(true)

  return (
    <>
      <ActivityOverviewRow
        style="expandable company"
        open={open}
        data={data}
        onClick={() => setOpen(!open)}
      />
      {open && children}
    </>
  )
}

export default CompanyRow
