import React, { useState, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Table, Button, InputRadio, InputNumber, InputDate,
} from '@produits-internes-oss/design-system-components'
import { ProjectActivitiesTimeSpentTableStyled } from './ProjectActivitiesTimeSpentTable.styled'
import { useProjectCoordinationContext } from '../../context/ProjectCoordination/ProjectCoordinationContext'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import {
  getNumberWithUnit,
  getYearMonthFromDateString,
} from '../../helpers/stringHelper'
import { getRoundedToDecimal } from '../../helpers/numberHelper'
import externalLinkBlue from '../../../assets/images/externalLinkBlue.svg'
import { ProgressBar } from '../ProgressBar/ProgressBar'
import eye from '../../../assets/images/eye.svg'
import { ProjectStaffingFormHandler } from '../ProjectStaffingFormHandler/ProjectStaffingFormHandler'
import pictoCopy from '../../../assets/images/copy.svg'
import { ModalInfosDeleteActivityExpense } from '../ProjectCoordinationModal/ModalInfosDeleteActivityExpense/ModalInfosDeleteActivityExpense'
import { ModalListDeleteActivityExpense } from '../ProjectCoordinationModal/ModalListDeleteActivityExpense/ModalListDeleteActivityExpense'
import { ButtonDeleteActivityMode } from '../ButtonDeleteActivityMode/ButtonDeleteActivityMode'
import { ProjectInlineStaffingForm } from '../ProjectInlineStaffingForm/ProjectInlineStaffingForm'
import pictoDestroy from '../../../assets/images/destroy.svg'
import { useDebouncer } from '../../hooks/useDebouncer/useDebouncer'
import { monthList } from '../../helpers/datePickerHelper'
import pictoCommentsFull from '../../../assets/images/IconsPleinMessage.svg'
import { useJQuery } from '../../hooks/useJQuery/useJQuery'

export const ProjectActivitiesTimeSpentTable = ({ hasPresalesActivities }) => {
  const { t } = useTranslation()
  const { publish } = useJQuery()
  const {
    projectActivitiesTimeSpent,
    addItemsToBeDeleted,
    fetchDeleteActivities,
    tableItemsDeleteMode,
    fetchPutActivities,
    isProjectPresales,
    project,
  } = useProjectCoordinationContext()

  const {
    inlineStaffingFormVisible,
    setInlineStaffingFormVisible,
    turnoverTypeForm,
    setExpenseIdToUpdate,
  } = useProjectSalesContext()

  const [presalesToggle, setPresalesToggle] = useState(false)
  const [compactedVueToggle, setCompactedVueToggle] = useState(false)
  const [copyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const table = useRef()
  const [activitiesDeleteMode, setActivitiesDeleteMode] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openModalList, setOpenModalList] = useState(false)
  const { debounce } = useDebouncer()

  const personTimesheetLink = (personData) => {
    const date = new Date()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    return personData && `/timesheet/${personData.nickname}/${year}/${month}`
  }

  const copyToClipboard = () => {
    if (table.current.childNodes[0]) {
      let nodes = Array.from(table.current.childNodes[0].childNodes)
        .flatMap((child) => Array.from(child.childNodes)).flatMap((child) => Array.from(child.childNodes))

      const presalesSubtotalIndex = nodes.findIndex((el) => el.className === 'section-sub total')

      nodes = nodes.map((line) => Array.from(line.childNodes))
      const header = nodes[0].map((cell) => cell.textContent)
      if (compactedVueToggle) {
        header[0] = t('project.activities.timeSpent.clipboard.columnNames.nickname')
      } else {
        header[0] = t('project.activities.timeSpent.clipboard.columnNames.name_last_name')
      }

      header.splice(1, 0, t('project.activities.timeSpent.clipboard.columnNames.role'))

      const subHeader = nodes[1].map((cell) => cell.textContent.replace(t('project.activities.timeSpent.mask'), ''))
      subHeader.splice(1, 0, '')

      const subFooter = nodes[nodes.length - 2].map((cell) => cell.textContent)
      subFooter.splice(1, 0, '')

      const footer = nodes[nodes.length - 1].map((cell) => cell.textContent)
      footer.splice(1, 0, '')

      if (!compactedVueToggle) {
        header.splice(0, 0, t('project.activities.timeSpent.clipboard.columnNames.expertise'))
        footer.splice(1, 0, '')
        subFooter.splice(1, 0, '')
      }

      let lines = []
      if (nodes.length > 2) {
        lines = nodes.slice(2, nodes.length - 2)
          .map((line, lineNumber) => line.map((cell, columnNumber) => {
            if (columnNumber === 0) {
              if (lineNumber < presalesSubtotalIndex - 2) {
                if (compactedVueToggle) {
                  return [cell.outerText, ''].join('\t')
                }

                return ['', cell.outerText.replace('\n', ' / '), ''].join('\t')
              }

              if (lineNumber === (presalesSubtotalIndex - 2)) {
                if (compactedVueToggle) {
                  return [cell.textContent, ''].join('\t')
                }

                return [cell.textContent, '', ''].join('\t')
              }

              const cellOuterText = cell.outerText
              const cellInArray = cellOuterText.replace(/(^[ \t]*\n)/gm, '').split('\n').reverse()

              if (compactedVueToggle) {
                if (cellInArray.length === 1) {
                  cellInArray.unshift('')
                }
                if (cellInArray.length === 3) {
                  cellInArray.splice(1, 1)
                }
                return cellInArray.join('\t')
              }

              if (cellInArray.length === 1) {
                cellInArray.unshift('', '')
                return cellInArray.join('\t')
              }

              if (cellInArray.length === 2) {
                const regexCommentPresent = new RegExp('\\n\\n', 'g')

                if (regexCommentPresent.test(cellOuterText)) {
                  cellInArray.splice(1, 0, '')
                  return cellInArray.join('\t')
                }

                cellInArray.unshift('')
                return cellInArray.join('\t')
              }
              return cell.outerText.replace(/(^[ \t]*\n)/gm, '').split('\n').reverse().join('\t')
            }
            const input = cell.getElementsByTagName('input')[0]

            return (input && input.value) || cell.textContent
          }).join('\t'))
      }

      navigator.clipboard.writeText([header.join('\t'), subHeader.join('\t'), ...lines, subFooter.join('\t'), footer.join('\t')].join('\n'))
    }
    setCopyToClipboardSuccess(true)
    setTimeout(() => {
      setCopyToClipboardSuccess(false)
    }, 5000)
  }

  const createBillableActivitiesRows = () => {
    const rows = {}
    projectActivitiesTimeSpent.billable_activities.map((activityTimeSpent) => {
      const row = {
        activityId: activityTimeSpent.id,
        id: activityTimeSpent.id,
        person: activityTimeSpent.person,
        title: activityTimeSpent.title,
        displayTitle: activityTimeSpent.display_title,
        progression: activityTimeSpent.progression,
        daysSold: activityTimeSpent.time_sold,
        startDate: activityTimeSpent.staffing_needed_from,
        totalAmountDaysSold: activityTimeSpent.total_amount_sold_of_activities,
        daysInputed: activityTimeSpent.total_time_spent,
        totalAmountDaysInputed: activityTimeSpent.total_time_spent_amount,
        activityType: 'billable',
        totalTimeInDays: activityTimeSpent.total_time_in_days,
        averageDailyRate: activityTimeSpent.average_daily_rate,
        profileId: activityTimeSpent.profile_id,
        roleId: activityTimeSpent.role_id,
        expertise: activityTimeSpent.expertise,
      }

      projectActivitiesTimeSpent.months.forEach((month) => {
        row[month] = {
          monthlyInputedDays:
            activityTimeSpent.monthly_time_spent?.[month] || 0,
          monthlyTotalAmountTimeSpent:
            activityTimeSpent.monthly_total_amount_time_spent?.[month] || 0,
        }
      })

      rows[activityTimeSpent.id] = row
      return row
    })
    return rows
  }

  const createPresalesActivitiesRows = () => {
    const rows = {}
    projectActivitiesTimeSpent.presales_activities.forEach(
      (activityTimeSpent, index) => {
        const row = {
          id: activityTimeSpent.id,
          person: activityTimeSpent.person,
          title: activityTimeSpent.title,
          displayTitle: activityTimeSpent.display_title,
          progression: null,
          daysSold: null,
          daysInputed: activityTimeSpent.total_time_spent,
          activityType: 'presales',
          totalTimeInDays: activityTimeSpent.total_time_in_days,
          nbDays: activityTimeSpent.nb_days,
        }
        projectActivitiesTimeSpent.months.forEach((month) => {
          row[month] = {
            monthlyInputedDays:
              activityTimeSpent.monthly_time_spent?.[month] || 0,
            monthlyTotalAmountTimeSpent:
              activityTimeSpent.monthly_total_amount_time_spent?.[month] || 0,
          }
        })

        rows[index] = row
      },
    )

    return rows
  }

  const createColumns = useMemo(() => {
    if (projectActivitiesTimeSpent === null) return []
    const monthsColumns = projectActivitiesTimeSpent.months.map((month) => ({
      name: month,
      title: getYearMonthFromDateString(month),
      onRender: ({ item }) => {
        const monthlyTimeSpent = getRoundedToDecimal(
          item[month]['monthlyInputedDays'],
          2,
        )
        return <span>{monthlyTimeSpent === 0 ? '' : monthlyTimeSpent}</span>
      },
      onFooter: ({ section, items }) => (section !== null
        ? getRoundedToDecimal(items.reduce((sum, item) => sum + parseFloat(item[month]['monthlyInputedDays']), 0), 2)
        : getNumberWithUnit(items.reduce((_, item) => parseFloat(item[month]['monthlyTotalAmountTimeSpent']), 0), '€')),
    }))

    const billableActivityPersonDisplay = (item) => (
      <>
        { compactedVueToggle ? (
          <div className="person-details">
            { item['displayTitle'] }
            { item['person'] && (
            <span className="inline-content">
              <span className="spaced">
                -
              </span>
              <a
                href={personTimesheetLink(item['person'])}
                target="_blank"
                rel="noreferrer"
              >
                {`${item['person'].nickname}`}
                <img src={externalLinkBlue} alt="check person timesheet" />
              </a>
            </span>
            )}
          </div>
        ) : (
          <div className="person-details">
            <span>{item['displayTitle']}</span>
            <div className="activity-person">
              {item['person'] && (
                <a
                  href={personTimesheetLink(item['person'])}
                  target="_blank"
                  rel="noreferrer"
                >
                  {` ${item['person'].nickname} / ${item['person'].accenture_id}`}
                  <img src={externalLinkBlue} alt="check person timesheet" />
                </a>
              )}
            </div>
            <p title={item['expertise']}>
              {
                item['expertise']
                  ? (
                    <>
                      <img alt="commz" src={pictoCommentsFull} />
                      {`"${item['expertise']}"`}
                    </>
                  )
                  : ''
              }
            </p>
          </div>
        )}
      </>
    )

    const presalesActivityPersonDisplay = (item) => (
      <div className="person-details">
        {
          compactedVueToggle ? '' : <span>{`${item['person'].first_name} ${item['person'].last_name}`}</span>
        }
        <a
          href={personTimesheetLink(item['person'])}
          target="_blank"
          rel="noreferrer"
        >
          {item['person'].nickname}
          <img src={externalLinkBlue} alt="check person timesheet" />
        </a>
      </div>
    )

    const deleteColumn = {
      name: 'delete_activity',
      title: `${activitiesDeleteMode ? t('project.activities.delete') : ''}`,
      onRender: ({ item }) => (
        <div className="input-radio-destroy">
          <InputRadio
            multiple="false"
            className="form-control radio radio-inline button-delete-activity"
            skin="light"
            name="delete_activity radio"
            disabled={item.totalTimeInDays > 0}
            icon=""
            options={{
              name: null,
            }}
            onChange={() => {
              addItemsToBeDeleted({
                id: item.activityId,
                firstName: item.person ? item.person.first_name : '',
                lastNasme: item.person ? item.person.last_name : '',
                nickname: item.person ? item.person.nickname : '',
                accenture_id: item.person ? item.person.accenture_id : '',
                kind: 'intern',
                title: item.displayTitle,
              })
            }}
          />
          <img
            aria-label="image delete"
            src={pictoDestroy}
          />
        </div>
      ),
    }

    const columnsDefault = [
      {
        name: 'name',
        title: (
          <div>
            {t('project.activities.timeSpent.name')}
            <Button
              className="compact"
              skin="dark"
              kind="primary"
              name={compactedVueToggle ? t('project.activities.timeSpent.detailedVue') : t('project.activities.timeSpent.compactVue')}
              text={compactedVueToggle ? t('project.activities.timeSpent.detailedVue') : t('project.activities.timeSpent.compactVue')}
              onClick={() => setCompactedVueToggle(!compactedVueToggle)}
            />
          </div>
        ),
        onTitle: ({ section }) => (section !== 0 ? null : (
          <div className="presales-header">
            <span className="title">
              {t('project.activities.timeSpent.presales')}
            </span>
            <button
              onClick={() => setPresalesToggle(!presalesToggle)}
              type="button"
            >
              <span>{presalesToggle ? t('project.activities.timeSpent.display') : t('project.activities.timeSpent.mask')}</span>
              <img src={eye} alt="mask presales activities" />
            </button>
          </div>
        )),
        onRender: ({ item }) => (item.activityType === 'presales' ? presalesActivityPersonDisplay(item) : billableActivityPersonDisplay(item, compactedVueToggle)),
        onFooter: ({ section }) => {
          if (section === 0) return <span className="title">{t('project.activities.timeSpent.subtotal')}</span>
          if (section === 1) return <span className="title">{t('project.activities.timeSpent.totalWithoutPresales')}</span>

          return <span className="total title">{t('project.activities.timeSpent.turnover')}</span>
        },
      },
      {
        name: 'daysSold',
        title: t('project.activities.timeSpent.daysSold'),
        onTitle: ({ section, items }) => {
          const activityPresales = items.filter((item) => item.activityType === 'presales')
          return ((section !== 0 || activityPresales.length === 0) ? ''
            : (
              <InputNumber
                valueInitial={activityPresales[0].nbDays}
                disabled={activitiesDeleteMode || isProjectPresales()}
                localization={{ invalid: { text: '', arialabel: '' } }}
                onChange={(value) => {
                  debounce(() => fetchPutActivities([{ id: activityPresales[0].id, nb_days: value }]), 1000)
                }}
              />
            )
          )
        },
        onRender: ({ item }) => (item.activityType === 'presales' ? ''
          : (
            <InputNumber
              className="days-sold"
              valueInitial={item['daysSold']}
              disabled={activitiesDeleteMode || isProjectPresales()}
              localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
              onChange={(value) => {
                debounce(() => fetchPutActivities([{ id: item.id, nb_days: value }]), 1000)
              }}
            />
          )),
        onFooter: ({ section, items }) => {
          if (section === 0) return null

          return section !== null
            ? getNumberWithUnit(getRoundedToDecimal(items.reduce((sum, item) => sum + parseFloat(item['daysSold']), 0), 2), '')
            : getNumberWithUnit(parseFloat(items[items.length - 1]?.['totalAmountDaysSold'] || 0), '€')
        },
      },
      {
        name: 'averageDailyRate',
        title: 'TJM',
        onRender: ({ item }) => (item.activityType === 'presales' ? ''
          : (
            <span className="unit price">
              <InputNumber
                className="daily-rate"
                name="average_daily_rate"
                valueInitial={getRoundedToDecimal(item['averageDailyRate'], 2)}
                disabled={activitiesDeleteMode || isProjectPresales() || project?.mission_maker === 'acn_prime' || project?.mission_maker === 'acn_prime_by_octo'}
                localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
                onCheck={() => item['average_daily_rate'] < 0 && t('project.staffing.form.error_number')}
                onChange={(value) => {
                  debounce(() => fetchPutActivities([{ id: item.id, average_daily_rate: value }]), 1000)
                }}
              />
            </span>
          )
        ),
      },
      {
        name: 'daysInputed',
        title: t('project.activities.timeSpent.daysInputed'),
        onRender: ({ item }) => getRoundedToDecimal(item['daysInputed'], 2),
        onFooter: ({ section, items }) => {
          const total = section !== null
            ? getRoundedToDecimal(items.reduce((sum, item) => sum + parseFloat(item['daysInputed']), 0), 2)
            : getNumberWithUnit(parseFloat(items[items.length - 1]?.['totalAmountDaysInputed'] || 0), '€')

          return <span>{total}</span>
        },
      },
      {
        name: 'consumption',
        title: t('project.activities.timeSpent.consumption'),
        onRender: ({ item }) => (item.activityType === 'presales' ? null : (
          <ProgressBar
            progression={parseFloat(item['progression'])}
            withProgressionIcon
          />
        )),
        onFooter: ({ section, items }) => {
          const activitiesBillable = items.filter((item) => item.activityType !== 'presales')

          const totalDaysInputed = section !== null
            ? activitiesBillable.reduce((sum, item) => sum + parseFloat(item['daysInputed'] || 0), 0)
            : parseFloat(items[items.length - 1]?.['totalAmountDaysInputed'] || 0)

          const totalDaysSold = section !== null
            ? activitiesBillable.reduce((sum, item) => sum + parseFloat(item['daysSold'] || 0), 0)
            : parseFloat(items[items.length - 1]?.['totalAmountDaysSold'] || 0)

          return section === 0 ? null : (
            <ProgressBar
              progression={totalDaysSold === 0 ? 0 : totalDaysInputed / totalDaysSold}
              withProgressionIcon
            />
          )
        },
      },
    ]

    const lastColumns = [
      {
        name: 'startDate',
        className: 'start-date',
        title: t('project.activities.staffingDate'),
        onRender: ({ item }) => (item.activityType === 'presales' ? '' : (
          <InputDate
            skin="light"
            valueInitial={item['startDate']}
            precision="day"
            localization={{
              months: [...monthList.map((month) => t(`months.${month}.short`)), ...monthList.map((month) => t(`months.${month}.full`))],
              previous: t('project.coordination.expenses.month.previous'),
              next: t('project.coordination.expenses.month.next'),
            }}
            onChange={(value) => {
              debounce(() => fetchPutActivities([{ id: item.id, staffing_needed_from: value }]), 1000)
            }}
            disabled={activitiesDeleteMode || isProjectPresales()}
          />
        )),
      },
      {
        name: 'modify',
        title: t('project.activities.timeSpent.modify'),
        onRender: ({ item }) => (item.activityType === 'presales' ? '' : <ProjectStaffingFormHandler turnoverTypeSelect="from_internal_team" valueInitial={item} />),
      },
    ]

    if (activitiesDeleteMode) {
      columnsDefault.splice(0, 0, deleteColumn)
    }

    return [
      ...columnsDefault,
      ...monthsColumns,
      ...lastColumns,
    ]
  }, [projectActivitiesTimeSpent, activitiesDeleteMode, presalesToggle, compactedVueToggle])

  const copySucceedText = () => (
    <div className="copy-succeed">{t('copySucceed')}</div>
  )

  if (projectActivitiesTimeSpent === null) return null
  return (
    <ProjectActivitiesTimeSpentTableStyled
      className="octo"
      presalesToggle={presalesToggle}
      compactedVue={compactedVueToggle}
      hasPresalesActivities={hasPresalesActivities}
      activitiesDeleteMode={activitiesDeleteMode}
    >

      <div className="view">
        <Button
          className="copy"
          skin="dark"
          kind="primary"
          name={t('clipboard.arialabel')}
          text={t('clipboard.text')}
          icon={{ url: pictoCopy, position: 'right' }}
          onClick={() => copyToClipboard()}
        />
        {copyToClipboardSuccess && copySucceedText()}
        <div ref={table}>
          <Table
            className={tableItemsDeleteMode ? 'table wrapper delete-mode' : 'table wrapper'}
            columns={createColumns}
            data={[
              createPresalesActivitiesRows(),
              createBillableActivitiesRows(),
            ]}
          />
        </div>
        { !isProjectPresales() && (
          <>
            <div className="control button-container">
              { tableItemsDeleteMode
                ? null
                : <ProjectStaffingFormHandler turnoverTypeSelect="from_internal_team" />}

              { projectActivitiesTimeSpent.billable_activities.length === 0
                ? null
                : (
                  <ButtonDeleteActivityMode
                    activitiesDeleteMode={activitiesDeleteMode}
                    setActivitiesDeleteMode={setActivitiesDeleteMode}
                    setOpenModal={setOpenModal}
                    setOpenModalList={setOpenModalList}
                    setInlineStaffingFormVisible={setInlineStaffingFormVisible}
                  />
                )}
            </div>
            {
              (inlineStaffingFormVisible && !tableItemsDeleteMode && turnoverTypeForm === 'from_internal_team') && (
                <ProjectInlineStaffingForm
                  onClose={() => {
                    setExpenseIdToUpdate(0)
                    publish('/octopod/project/update_kpis')
                  }}
                />
              )
            }
          </>
        )}
        {openModal ? <ModalInfosDeleteActivityExpense setOpenModal={setOpenModal} /> : null}
        {openModalList ? (
          <ModalListDeleteActivityExpense
            fetchDelete={fetchDeleteActivities}
            setActivitiesDeleteMode={setActivitiesDeleteMode}
            setOpenModalList={setOpenModalList}
            setInlineStaffingFormVisible={setInlineStaffingFormVisible}
          />
        )
          : null }

      </div>
    </ProjectActivitiesTimeSpentTableStyled>
  )
}
