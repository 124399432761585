import React from 'react'
import { ProjectErrorsStyled } from './ProjectErrors.styled'
import { getClass } from '../../helpers/stringHelper'

export const ProjectErrors = ({ errorType, picto, children, errorsColumn }) => (

  <ProjectErrorsStyled picto={picto} errorsColumn={errorsColumn}>
    <div className={getClass('error-container', errorType)}>
      {picto && <div className="picto" /> }
      <div className="error-text">
        { children }
      </div>
    </div>
  </ProjectErrorsStyled>
)
