import React from 'react'
import { StyledTimesheetTop } from './TimesheetTop.styled'
import TimesheetHelp from './TimesheetHelp/TimesheetHelp'
import TimesheetForm from './TimesheetForm/TimesheetForm'

const TimesheetTop = ({ loading }) => (
  <StyledTimesheetTop>
    <TimesheetForm />
    {
        !loading
        && <TimesheetHelp />
      }
  </StyledTimesheetTop>
)

export default TimesheetTop
