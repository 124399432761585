import styled from 'styled-components'

export const StatusStyled = styled.div`
  &.octo.hard {
    .status {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 0;
      padding: 0.8rem;
      border-radius: 0.3rem;
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      font-weight: 600;
      font-size: 1.6rem;
      font-family: 'Outfit', sans-serif;
      line-height: normal;
      cursor: pointer;

      &.current {
        cursor: unset;
      }

      &.lead {
        background-color: ${({ theme }) => theme.colors.betterway.orange[100]};
      }

      &.proposal-in-progress {
        background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
      }

      &.proposal-sent {
        background-color: ${({ theme }) => theme.colors.betterway.secondary[70]};
      }

      &.mission-accepted {
        background-color: ${({ theme }) => theme.colors.betterway.green[50]};
      }

      &.mission-signed {
        background-color: ${({ theme }) => theme.colors.betterway.green[50]};
      }

      &.mission-done {
        background-color: ${({ theme }) => theme.colors.betterway.green[100]};
      }

      &.proposal-lost,
      &.proposal-no-go,
      &.proposal-canceled,
      &.proposa-canceled-by-client,
      &.lead-transferred-to-acn {
        background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
      }

      &.disabled {
        background-color: ${({ theme }) => theme.colors.betterway.primary[20]};
        cursor: not-allowed;
      }
    }

    .popup-tooltip {
      z-index: 100;

      p {
        margin-bottom: 0.8rem;
      }

      .message {
        ul {
          margin-left: 1rem;
          list-style: inside;

          li {
            display: list-item;
            margin-bottom: 0.8rem;
          }
        }
      }

      ul {
        margin-left: 1rem;
        list-style: inside;

        li {
          display: list-item;
          margin-bottom: 0.8rem;
        }
      }

      >.modal {
        .comment-title {
          margin-top: 1.1rem;
          margin-bottom: 2rem;
          font-weight: 600;
          font-size: 1.8rem;

          &.lost-title {
            width: 585px;
            white-space: pre-line;
          }
        }

        .text-bottom {
          margin-bottom: 0 !important;
        }

        p {
          margin-bottom: 1rem;

          a {
            display: inline;
            color: ${({ theme }) => theme.colors.betterway.secondary[100]};
            font-weight: 600;
            text-decoration: underline;
          }
        }

        .lost-status-comment {
          margin-top: 2.4rem;
        }

        >.header {
          height: 0;
          margin: 0;

          >.kind {
            margin-top: 4.4rem;
          }

          >.close {
            position: relative;
            top: ${(props) => (props.commentRequiredModal ? '2.3rem' : '2.1rem')};
            right: ${(props) => (props.commentRequiredModal ? '0' : '2%')};
          }
        }

        >.content {
          overflow-y: visible;
          width: 100%;

          >.normal-modal {
            min-width: 70.1rem;
            padding: 0 10.8rem;
            padding-bottom: 1.4rem;

            >.title {
              margin-top: 1rem;
              margin-bottom: 2rem;
              font-weight: 600;
              font-size: 1.8rem;
              text-align: center;
            }
          }

          .comment-modal {
            width: auto;
            padding: auto;
            padding-bottom: 1.4rem;
          }
        }

        ul {
          margin-left: 0;
          list-style: none;
        }

        .footer {
          min-width: ${(props) => (props.commentRequiredModal ? 'auto' : '70.1rem')};
          padding: ${(props) => (props.commentRequiredModal ? 'auto' : '0 10.8rem')};
        }
      }
    }

    .tooltip-bold {
      font-weight: 600;
    }

    .input-date {
      max-width: 23.6rem;
      margin-bottom: 2rem;
    }

    .input-qa-director,
    .other-qa-director {
      width: 80%;
      margin-top: 2rem;
    }
  }
`
