import styled from 'styled-components'

export const StyledHelpModal = styled.div`
  overflow: auto;
  max-height: 759px;
  color: ${({ theme }) => theme.colors.primary};

  p {
    margin-top: 2rem;
  }

  h3 {
    margin-bottom: 2rem;
  }

  table {
    width: 100%;
    border-collapse: separate;

    th {
      padding: 10px;
    }

    thead {
      background: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};

      tr {
        th:first-child {
          width: 214px;
        }
      }

      th {
        border: solid 1px ${({ theme }) => theme.colors.primary};

        &:first-child {
          border-radius: 0.5rem 0 0;
        }

        &:last-child {
          border-radius: 0 0.5rem 0 0;
        }
      }
    }

    tbody {
      img {
        height: 21px;
      }

      th {
        &:first-child {
          border-right: solid 1px ${({ theme }) => theme.colors.primary};
          border-bottom: solid 1px ${({ theme }) => theme.colors.primary};
          border-left: solid 1px ${({ theme }) => theme.colors.primary};
        }

        &:last-child {
          border-right: solid 1px ${({ theme }) => theme.colors.primary};
          border-bottom: solid 1px ${({ theme }) => theme.colors.primary};
        }
      }

      div {
        margin-top: 0.5rem;

        span.keyboard-key {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          width: 2.4rem;
          height: 2.4rem;
          padding: 0.2rem;
          border-radius: 0.3rem;
          background: ${({ theme }) => theme.colors.primary};
          color: ${({ theme }) => theme.colors.white};

          &.space-bar {
            width: 9.8rem;
          }

          &.backspace {
            justify-content: flex-end;
            width: 4.8rem;
          }
        }

        span {
          vertical-align: top;
        }

        & > *:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }

  table.color-code {
    margin-bottom: 26px;

    thead {
      tr {
        th:first-child {
          width: 214px;
        }
      }
    }

    tbody {
      tr:last-child {
        th:first-child {
          color: ${({ theme }) => theme.colors.blockingError};
        }
      }

      tr:nth-of-type(2) {
        th:first-child {
          color: ${({ theme }) => theme.colors.error};
        }
      }
    }
  }
`
