import styled from 'styled-components'

export const StyledSelectMonthYear = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  padding: 0.4rem;

  .select {
    min-width: 9rem;
    margin: 0rem 0.4rem;
    z-index: 10
  }
`
