const regexpDiacritics = /\p{Diacritic}/gu
const regexpPunctuation = /['`’.,;:!?\-_()[\]{}<>]+/g

export const clean = (value) => (value ? value.toLowerCase().normalize('NFD').replace(regexpDiacritics, '').trim() : '')

export const cleanWithPunctuation = (value) => (value ? clean(value.replace(regexpPunctuation, '')) : '')

export const getClass = (...classes) => (classes.filter(Boolean).join(' '))

export const parseFloatWithAnySeparator = (value) => (value && typeof value === 'string'
  ? parseFloat(value.replace(',', '.'), 10)
  : parseFloat(value, 10))

export const parseFloatWithAnySeparatorInObject = (object, keysToIgnore) => {
  const type = typeof object
  if (object !== null && ['string', 'object'].includes(type)) {
    if (type === 'string') {
      const parsed = parseFloatWithAnySeparator(object)
      return Number.isNaN(parsed) ? object : parsed
    }

    return Array.isArray(object)
      ? object.map((item) => parseFloatWithAnySeparatorInObject(item, keysToIgnore))
      : Object.fromEntries(Object.entries(object).map(([key, value]) => {
        if (keysToIgnore && keysToIgnore.includes(key)) {
          return ([key, value])
        }
        return ([key, parseFloatWithAnySeparatorInObject(value, keysToIgnore)])
      }))
  }

  return object
}

export const convertFirstLetterUpperCase = (str) => {
  const strLowerCase = str.toLowerCase()
  const strDivide = strLowerCase.split(' ')

  const strDivideClean = strDivide.map((strToClean) => strToClean.charAt(0).toUpperCase() + strToClean.slice(1))

  const concatenatStrWithSpace = strDivideClean.join(' ')
  return (
    concatenatStrWithSpace
  )
}

export const convertHTMLToText = (html) => {
  if (!html) { return '' }

  const temp = document.createElement('div')
  temp.innerHTML = html

  return temp.textContent || temp.innerText || ''
}

export const convertSnakecaseToCamelcase = (s) => s.replace(/([-_][a-z])/ig, (match) => match.toUpperCase().replace('_', ''))

export const convertCamelcaseToSnakecase = (s) => s.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`)

export const getNumberWithLeadingZero = (number, leadingZeroCount) => String(number).padStart(leadingZeroCount, '0')

export const getNumberWithUnit = (number, unit) => `${number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${unit}`

export const getNumberAsPercentage = (number) => getNumberWithUnit(number * 100, '%')

export const getFileNameAndExtension = (name) => {
  const filename = name.replace(/^.*[\\/]/, '')
  const hasNoName = filename[0] === '.'
  const hasNoExtension = filename.indexOf('.') === -1
  return hasNoName
    ? { name: filename, extension: '' }
    : { name: filename.replace(/\.[^/.]+$/, ''), extension: hasNoExtension ? '' : filename.split('.').pop() }
}

export const getYearMonthFromDateString = (date) => {
  const splittedDate = date.split('-')
  return `${splittedDate[1]}/${splittedDate[0]}`
}

export const getYearMonthDayFromDateString = (date) => {
  const splittedDate = date.split('-')
  return `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`
}
