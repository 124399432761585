import styled from 'styled-components'
import pictoInfo from '../../../assets/images/exclamationInfoWhite.svg'

export const SalesHeaderStyled = styled.div`
  display: flex;
  gap: 2.4em;
  justify-content: center;
  box-sizing: border-box;
  margin-bottom: 4rem;

  .card {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 32rem;
    height: 11.2rem;
    margin-bottom: 2rem;
    padding: 1.2rem 0.8rem 1.2rem 1.6rem;
    padding-right: 0;
    padding-left: 0;
    border-radius: 1.5rem;
    background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
    place-content: space-evenly;

    .card-header {
      display: flex;
      gap: 1rem;
      margin-bottom: 0.8rem;
      color: ${({ theme }) => theme.colors.betterway.white};
      font-size: 1.6rem;

      .infos-card-header-tooltip {
        width: auto;

        .popup-tooltip {
          .content-tooltip {
            flex-wrap: nowrap;

            .one-line {
              display: flex;
              gap: 0.5rem;

              em {
                font-weight: 600;
              }
            }

            em {
              font-weight: 600;
            }

            ul {
              margin-top: 2rem;
              padding-left: 3rem;

              li {
                display: list-item;
                gap: 5.6rem;
                width: 100%;
                list-style: disc;

                >.risk-label {
                  width: 50%;
                }

                >.risk-answer {
                  width: 50%;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }

      .tooltip-trigger-button {
        width: 2rem;
        height: 2rem;
        background-image: url(${pictoInfo});
        background-position: center;
        background-clip: border-box;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }

    .card-value {
      color: ${({ theme }) => theme.colors.betterway.white};
      font-size: 3.2rem;
    }
  }
`
