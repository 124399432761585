import React, {
  useState, useRef, useEffect, useContext,
} from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { StyledActivityLine } from './ActivityLine.styled'

import ActivityPopin from '../ActivityPopin/ActivityPopin'
import ActivityInput from '../ActivityInput/ActivityInput'
import Backdrop from '../../../../UI/Backdrop/Backdrop'
import ActivityInputWarningModal from '../../../TimesheetModals/activityInputWarningModal/ActivityInputWarningModal'

import { useActivityUtils } from '../../../../../hooks/useActivityUtils'
import { ActivityContext } from '../../../../../context/Timesheet/ActivityContext'
import { repositionElement, tableHeightsInPx } from '../../../../../helpers/repositionElementInScreen'
import { keyBoardNavigation } from './keyboardNavigationHelper'
import useInputsHandler from '../../../../../hooks/useInputsHandler'
import { useTimesheetErrors } from '../../../../../context/Timesheet/TimesheetErrorsContext'

const ActivityLine = observer(
  ({
    title,
    even,
    dataX,
    numberOfActivities,
    activityId,
    activityType,
    inbound,
    validatedPeriodDays,
    limitedHalfOrFullDayInput,
    limitedFullDayInput,
    isAbsence,
    comment,
    nbDays,
    consumedDaysQuantity,
    editable,
  }) => {
    const { t } = useTranslation()
    const ref = useRef()
    const [openActivityPopin, setOpenActivityPopin] = useState(false)
    const [activityLineClassName, setActivityLineClassName] = useState('')
    const [highlightedTitleClassName, setHighlightedTitleClassName] = useState('')
    const [openWarningModal, setOpenWarningModal] = useState(false)

    const {
      totalActivityInputsInDays,
      activityInputs,
      updateActivityInputs,
      consumedDays,
    } = useInputsHandler(activityId, activityType, consumedDaysQuantity)

    const consumptionRate = activityType === 'BillableActivity' && nbDays !== '0.0' ? `${Math.round((consumedDays / nbDays) * 100)}%` : ''

    const {
      daysInMonth,
      numberOfDays,
      isHolidayOrFilled,
    } = useActivityUtils()

    const { timesheetErrors } = useTimesheetErrors()

    const toggleActivityPopin = () => {
      if (activityType !== 'PermanentActivity') {
        setOpenActivityPopin(!openActivityPopin)
        document.body.style.overflow = !openActivityPopin ? 'hidden' : 'auto'
      }
    }

    const closeActivityPopin = () => {
      setOpenActivityPopin(false)
      document.body.style.overflow = 'auto'
    }

    const closeWarningModalHandler = () => {
      setOpenWarningModal(false)
      document.body.style.overflow = 'auto'
    }

    const fullyConsumedDays = consumedDays >= nbDays ? 'full-consumption' : ''

    useEffect(() => {
      if (inbound) {
        repositionElement(ref.current, 'center', { top: tableHeightsInPx.header, bottom: tableHeightsInPx.footer })
      }
    }, [inbound])

    const { activityFocus } = useContext(ActivityContext)

    useEffect(() => {
      if (activityFocus === activityId) {
        setActivityLineClassName('focus-line')
        setHighlightedTitleClassName('highlighted-title')
      } else if (activityLineClassName !== '') {
        setActivityLineClassName('')
        setHighlightedTitleClassName('')
      }
    }, [activityFocus])

    return (
      <StyledActivityLine
        even={even}
        openActivityPopin={openActivityPopin}
        clickable={activityType !== 'PermanentActivity'}
        inbound={inbound}
        activityError={timesheetErrors?.activities?.length > 0 && timesheetErrors?.activities?.includes(parseInt(activityId, 10))}
        className={activityLineClassName}
      >
        <th
          onClick={toggleActivityPopin}
          ref={ref}
          aria-label={`activity-title-${activityId}`}
        >

          {
            isAbsence
            && (
            <div className="activity-tooltip" aria-label="activity-tooltip">
              <p>
                {comment}
              </p>
            </div>
            )
          }

          <span className={`activity-title ${highlightedTitleClassName}`} id={`activity-title-${activityId}`}>
            {title}
          </span>
          {
            openActivityPopin
            && (
            <ActivityPopin
              toggleActivityPopin={toggleActivityPopin}
              activityId={activityId}
            />
            )
          }
        </th>
        <td className={fullyConsumedDays}>{consumptionRate}</td>
        {
          daysInMonth.map((day, index) => (
            <ActivityInput
              key={day}
              day={day}
              index={index}
              dataX={dataX}
              activityInputs={activityInputs}
              activityId={activityId}
              isHolidayOrFilled={isHolidayOrFilled}
              keydownHandler={(event) => keyBoardNavigation(event, numberOfDays, numberOfActivities, updateActivityInputs, limitedHalfOrFullDayInput, limitedFullDayInput)}
              validatedPeriodDays={validatedPeriodDays}
              editable={editable}
              setOpenWarningModal={setOpenWarningModal}
            />
          ))
        }
        {totalActivityInputsInDays === 0 ? <td /> : (
          <td>
            {totalActivityInputsInDays}
            {' '}
            {t('days.short')}
          </td>
        )}
        <Backdrop open={openActivityPopin} close={closeActivityPopin} />
        <ActivityInputWarningModal open={openWarningModal} close={closeWarningModalHandler} />
      </StyledActivityLine>
    )
  },
)

export default ActivityLine
