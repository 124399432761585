import styled from 'styled-components'

export const StyledCommentPopin = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 1001;
  box-sizing: border-box;
  width: 100%;
  height: ${({ updated }) => (updated ? '22.5rem' : '20rem')};
  padding: 2rem;
  border-radius: 4px 4px 0 0;
  background-color: white;
  box-shadow: 0 4px 4px 0 ${({ theme }) => theme.colors.shadow};

  > p.gdpr-info {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
    margin-bottom: 1rem;
    font-weight: 400;

    >span.warning {
      width: 2.6rem;
      height: 2.1rem;
    }

    >span.text {
      text-align: start;
    }
  }
`

export const StyledCommentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  span {
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.colors.primaryGrey};
    font-size: 12px;
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 0;
    padding: 1.5rem 2rem;
    border: none;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.paleGrey3};
    font-family: inherit;
    resize: none;

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.secondaryBlue};
      box-shadow: none;
    }
  }
`
