import styled from 'styled-components'

export const ProjectQualityScreenStyled = styled.div`
.quality {
  >.content {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    width: 100%;

    >.quality-infos {
      display: flex;
      flex-direction: row;
      gap: 2.4rem;
      width: 100%;
    }
  }
}
`
