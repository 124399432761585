import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { StyledSidedrawer } from './Sidedrawer.styled'

const Sidedrawer = ({ open, children }) => (
  <CSSTransition
    in={open}
    mountOnEnter
    unmountOnExit
    timeout={300}
    classNames="side-animated"
  >
    <StyledSidedrawer
      open={open}
      role="side-navigation"
    >
      {children}
    </StyledSidedrawer>
  </CSSTransition>
)

export default Sidedrawer
