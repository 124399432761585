import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledSide } from './Side.styled'

const Side = ({
  dates,
  periodNumber,
  showSection,
}) => {
  const { t } = useTranslation()

  return (
    <StyledSide>
      <h3>
        {t('cra')}
        {' '}
        <span>{dates}</span>
      </h3>
      <div onClick={() => showSection('time-recap')}>
        <p>{t('periodValidationModal.recap')}</p>
      </div>
      <div onClick={() => showSection('rest-form')} aria-label="rest-respected">
        <p>{t('periodValidationModal.restRespected.title')}</p>
      </div>
      <div onClick={() => showSection('remote-office-days')}>
        <p>{t('periodValidationModal.remote.title')}</p>
      </div>
      {
        periodNumber === 2
        && (
        <>
          <div onClick={() => showSection('happiness-indicator-form')}>
            <p>{t('periodValidationModal.happinessIndicator.title')}</p>
          </div>
          <div onClick={() => showSection('meal-form')}>
            <p>{t('periodValidationModal.mealExpenses.title')}</p>
          </div>
        </>
        )
      }
    </StyledSide>
  )
}

export default Side
