import styled from 'styled-components'

export const StyledPeriod = styled.th`
  background: ${({ theme }) => theme.colors.white};

  &.back-drop-opened {
    z-index: 1001;
  }

  &.back-drop-not-opened {
    z-index: 0;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;

    & > div {
      width: 100%;
      margin-right: 2rem;
    }

    h3 {
      font-size: 1.8rem;
      text-align: left;

      span {
        color: ${({ theme }) => theme.colors.primaryGrey};
        font-size: 1.4rem;
      }
    }

    .comment-button {
      display: inline-block;
      width: 3rem;
      height: 3rem;
      margin-left: 2rem;
      padding: 0;
      background: transparent;
      cursor: pointer;
    }
  }
`
