import styled from 'styled-components'

export const ProjectInvoicesStyled = styled.div`
div.header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.6rem;

  h3.title {
    font-weight: 600;
    font-size: 2.4rem;
  }

  .invoice-resume-link {
    align-self: center;
    color: ${({ theme }) => theme.colors.betterway.primary[100]};
    font-size: 1.4rem;
    text-decoration: underline;

    :hover {
      font-weight: 600;
    }

    img {
      vertical-align: bottom;
      width: 2rem;
      height: 2rem;
    }
  }
}

.resume-container {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;

  .detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 2.4rem;

    div.octo {
      width: 100%;

      >section {
        padding: 0;

        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 11.8rem;

          p {
            font-size: 1.6rem;
            text-align: center;

            &.text {
              margin-bottom: 1rem;
              padding: 0 6.4rem;
            }

            &.value {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  div.octo.global-total {
    height: 8.8rem;

    section {
      display: flex;
      height: 100%;
      padding: 0 4rem;

      div {
        display: inline-flex;
        align-items: center;
        width: 100%;
        font-weight: 600;
        font-size: 1.6rem;
        place-content: center;

        p.text {
          margin-right: 1.6rem;
        }

        p.value {
          font-size: 2.4rem;
        }
      }
    }
  }
}
`
