import styled from 'styled-components'

export const ProjectInlineStaffingFormStyled = styled.div`
  &.inline-staffing-form {
    margin-top: 2rem;
    padding: 2.4rem;
    border-width: 0.1rem;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.betterway.primary[20]};
    border-radius: 2rem;
    background-color: ${({ theme }) => theme.colors.betterway.primary[5]};

    .form-content {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      align-items: stretch;

      .octo.hard {
        width: auto;
      }

      .full-name {
        min-width: 30rem !important;
      }

      .validate {
        flex-direction: row;
        align-items: flex-end;
      }

      .form-item {
        flex-grow: 1;
      }

      .form-control {
        flex: 1 1 auto;
        min-width: 150px;

        .control {
          width: 100%;

          button,
          input {
            &:focus-visible {
              border-width: 0.3rem;
              border-style: solid;
              border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
            }
          }
        }
      }

      .octo.hard > .popup-trigger > .input-search {
        min-width: 30rem;
      }
    }
  }
`
