import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectContractExplanationStyled } from './ProjectContractExplanation.styled'

export const ProjectContractExplanation = () => {
  const { t } = useTranslation()
  return (
    <ProjectContractExplanationStyled className="info-contract">
      <div>
        <p className="chargeable">
          {t(`project.contract.explanation.0`)}
          <em>
            {' '}
            {t(`project.contract.explanation.1`)}
          </em>
          {t(`project.contract.explanation.2`)}
          <em>
            {' '}
            {t(`project.contract.explanation.3`)}
          </em>
          {t(`project.contract.explanation.4`)}
        </p>
      </div>
    </ProjectContractExplanationStyled>
  )
}
