import { theme } from '@produits-internes-oss/design-system'
import styled from 'styled-components'

export const HolidaysCounterStyled = styled.div`
&.octo {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  gap: 0.8rem;
  align-items: stretch;
  justify-content: space-between;
  height: 15rem;
  padding: 1.6rem 2.4rem;
  border: 0.1em solid ${theme.colors.marine[20]};
  border-radius: 2rem;
  background-color: ${theme.colors.marine[5]};
  color: ${theme.colors.marine[100]};

  @media (min-width: 880px) {
    min-width: 36rem;
    max-width: 40rem;
  }

  @media (max-width: 880px) {
    min-width: 100%;
    max-width: none;
  }

  >.title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    > h3 {
      font-weight: 600;
      font-size: 1.8rem;
    }

    >.advices {
      >.popup-trigger {
        > button {
          >.trigger {
            width: 2.4rem;
            height: 2.4rem;
            cursor: pointer;
            transform: rotateZ(180deg);
          }
        }
      }

      >.popup-tooltip {
        > ul {
          display: flex;
          flex-flow: column nowrap;
          gap: 1rem;
          align-items: stretch;
          justify-content: stretch;

          > li {
            display: list-item;
            margin-left: 0.8rem;
            padding-left: 0.4rem;
            list-style-type: disc;
            font-size: 1.4rem;

            > p {
              display: inline;

              > * {
                display: inline;
              }

              > strong {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  >.limit {
    &.important {
      color: ${theme.colors.red[70]};
      font-weight: 600;
    }
  }

  >.counter {
    margin-top: 0.8rem;
    padding-top: 0.8rem;
    border-top: 0.1rem solid ${theme.colors.marine[20]};

    >.remaining {
      color: ${theme.colors.turquoise[100]};
      font-weight: 600;
      font-size: 2.4rem;

      &.negative {
        color: ${theme.colors.red[100]};
      }
    }

    >.total {
      font-size: 2rem;
    }
  }

  >.alert {
    flex: 1;
    margin-top: 0.6rem;
    background: none;
    color: ${theme.colors.marine[50]};
    font-weight: 600;
    font-size: 1.4rem;
  }
}
`
