import styled from 'styled-components'

export const ProjectRevenueInfoStyled = styled.div`
  >.header {
    font-weight: 600;
    font-size: 1.8rem;
  }

  >.content {
    margin-top: 2rem;

    >.content-header {
      margin-bottom: 0.8rem;
    }

    > ul {
      padding-left: 0.8rem;
      list-style: inside;

      > li {
        > strong {
          font-weight: 600;
        }

        > *:not(:first-child)::before {
          content: " ";
        }
      }
    }
  }
`
