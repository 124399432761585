import theme from './theme'

export function isMobile() {
  return /mobile/i.test(window.navigator.userAgent)
}

const breakPointPortrait = `(max-width: ${theme.sizes.mobilePortrait}) and (orientation: portrait)`
const breakPointLandscape = `(max-width: ${theme.sizes.mobileLandscape}) and (orientation: landscape)`

export const mediaQueryForMobile = isMobile()
  ? '@media only screen'
  : `@media only screen and, ${breakPointPortrait} only screen and ${breakPointLandscape}`
