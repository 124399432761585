import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledValidationConfirmationModal } from './ValidationConfirmationModal.styled'

import Modal from '../../../../UI/Modal/Modal'

const ValidationConfirmationModal = ({
  periodSubmitted,
  close,
}) => {
  const { t, i18n } = useTranslation()

  return (
    <Modal open={periodSubmitted} close={close} height="auto" width="auto">
      <StyledValidationConfirmationModal>
        <h3>{t('period.submitted')}</h3>
        <p>{t('periodValidationModal.newSkills')}</p>
        <p>{t('periodValidationModal.updateProfile')}</p>
        <div>
          <a href={`/people/profile?locale=${i18n.language}`}>
            {t('profile.update')}
          </a>
        </div>
      </StyledValidationConfirmationModal>
    </Modal>
  )
}

export default ValidationConfirmationModal
