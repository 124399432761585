import styled from 'styled-components'

export default styled.header`
  color: ${({ theme }) => theme.colors.betterway.primary[100]};
  font-size: 1.4rem;

  h1 {
    font-size: 2.4rem;
  }

  .count {
    color: ${({ theme }) => theme.colors.betterway.primary[70]};
  }

  .search {
    margin: 2.4rem 0 1.4rem;
  }

  .askbob {
    margin: 0 0 0.8rem;

    a {
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      text-decoration: underline;

      &:hover {
        color: ${({ theme }) => theme.colors.betterway.secondary[70]};
      }
    }
  }
`
