import styled from 'styled-components'

export const ConsecutivePaidLeaveStyled = styled.div`
div.header {
  h1 {
    margin-bottom: 2.4rem;
    font-weight: 600;
    font-size: 2.4rem;
  }

  p {
    margin-bottom: 2.4rem;
    font-weight: 400;
    font-size: 1.6rem;
  }

  .send-email-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding: 2rem 0;
    border: 1px solid ${({ theme }) => theme.colors.betterway.primary[50]};
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.betterway.primary[5]};

    p {
      display: flex;
      width: 90%;
      margin: 0;
      font-weight: 400;
      font-size: 1.6rem;
    }

    .subject-mail-container {
      display: flex;
      width: 90%;

      p {
        align-items: center;
        width: 15%;
      }

      .subject-email {
        width: 90%;
      }
    }

    .body-mail-container {
      display: flex;
      flex-direction: column;
      width: 90%;

      p {
        width: 15%;
        margin-bottom: 2rem;
      }

      .body-email {
        width: 100%;
      }
    }

    .button-container {
      display: flex;
      justify-content: end;
      width: 90%;
    }
  }
}

.copy-table {
  display: flex;
  flex-direction: column;
  width: 100%;

  .copy {
    width: fit-content;
    margin-top: 1.4rem;
    margin-bottom: 1rem;

    &.octo.hard.default {
      > button {
        padding: 0;
        border: none;
        background: none;
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
        font-weight: normal;
        font-size: 1.4rem;
        text-decoration: underline;

        &:hover {
          background: none;
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          font-weight: bold;
        }

        &:focus,
        &:focus-visible {
          border: none;
          color: ${({ theme }) => theme.colors.betterway.secondary[100]};
          font-weight: bold;
        }

        ::before {
          content: none;
        }

        >.icon {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }

  .copy-succeed {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    padding: 1.6rem;
    border: solid 0.2rem ${({ theme }) => theme.colors.betterway.green[100]};
    border-radius: 0.3rem;
    background-color: ${({ theme }) => theme.colors.betterway.green[5]};
  }
}

table {
  thead > tr {
    > th.octo.hard {
      > div.head {
        width: max-content;
      }
    }
  }

  td {
    div > a {
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      font-weight: bold;

      &:hover {
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
`
