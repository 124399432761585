import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledPeriod } from './Period.styled'
import { CommentPopin } from './Comment/Comment'
import InvalidationPopin from './InvalidationPopin/InvalidationPopin'

import ProgressBar from '../../../../UI/ProgressBar/ProgressBar'
import Button from '../../../../input/Buttons/Button/Button'
import PeriodValidationModal from '../../../TimesheetModals/PeriodValidationModal/PeriodValidationModal'
import CommentLogo from '../../../../../../assets/images/Comment.png'

import { usePeriodStateValue } from '../../../../../context/Timesheet/PeriodStateContext'
import { useTimesheetStateValue } from '../../../../../context/Timesheet/TimesheetStateContext'
import { usePeriodComment } from '../../../../../hooks/usePeriodComment'
import { usePeriodValidationHandler } from '../../../../../hooks/usePeriodValidationHandler'

const Period = ({
  id,
  periodNumber,
  dates,
  rate,
  kind,
  colSpan,
  periodErrors,
  hasTimesheetErrors,
}) => {
  const { t } = useTranslation()
  const { validationPeriodButtonDisabled } = usePeriodStateValue()
  const { daysInMonth } = useTimesheetStateValue()
  const {
    comment,
    updatedAt,
    updatedBy,
    toggleCommentBlock,
    handleChangeComment,
    openCommentBlock,
    setOpenCommentBlock,
  } = usePeriodComment(id)
  const {
    openInvalidationPopin,
    setOpenInvalidationPopin,
    openValidationModal,
    setOpenValidationModal,
    validatePeriod,
    invalidatePeriod,
    statusToButton,
    periodStatus,
    isPeriodLocked,
    periodTimeinputs,
    totalInputsForPeriod,
    totalPeriodInputsForActivity,
    toggleInvalidationPopin,
  } = usePeriodValidationHandler(periodNumber)

  const closeHandler = (callback) => {
    callback(false)
    document.body.style.overflow = 'auto'
  }

  const backDropOpenedClass = (openCommentBlock || openInvalidationPopin || openValidationModal) ? 'back-drop-opened' : 'back-drop-not-opened'

  return (
    <StyledPeriod
      colSpan={colSpan}
      className={backDropOpenedClass}
    >
      <PeriodValidationModal
        open={openValidationModal}
        close={() => closeHandler(setOpenValidationModal)}
        dates={dates}
        periodId={id}
        periodNumber={periodNumber}
        periodTimeinputs={periodTimeinputs(daysInMonth)}
        totalInputsForPeriod={totalInputsForPeriod(daysInMonth)}
        totalPeriodInputsForActivity={totalPeriodInputsForActivity(daysInMonth)}
        periodErrorsMessages={periodErrors?.messages}
        initialComment={comment}
        onChangeComment={(commentChange) => handleChangeComment(commentChange)}
        validatePeriod={() => validatePeriod(id, periodNumber)}
        updatedAt={updatedAt}
        updatedBy={updatedBy}
      />
      <div>
        <CommentPopin
          open={openCommentBlock}
          close={() => closeHandler(setOpenCommentBlock)}
          initialComment={comment}
          onChangeComment={(commentChange) => handleChangeComment(commentChange)}
          updatedAt={updatedAt}
          updatedBy={updatedBy}
        />
        <InvalidationPopin
          open={openInvalidationPopin}
          close={() => closeHandler(setOpenInvalidationPopin)}
          invalidatePeriod={() => invalidatePeriod(id, periodNumber)}
          toggleInvalidationPopin={() => toggleInvalidationPopin(setOpenCommentBlock)}
        />
        <div>
          <h3>
            {t('cra')}
            {' '}
            <span>{dates}</span>
            <button
              type="button"
              className="comment-button"
              onClick={() => {
                if (openInvalidationPopin || openValidationModal) return
                toggleCommentBlock()
              }}
              aria-label="comment-block"
            >
              <img alt="comment logo open popup" src={CommentLogo} />
            </button>
          </h3>
          <ProgressBar rate={rate} kind={kind} size="medium" />
        </div>
        <Button
          color="primary"
          disabled={validationPeriodButtonDisabled(periodErrors, hasTimesheetErrors, isPeriodLocked, periodNumber)}
          type={statusToButton[periodStatus].type}
          click={() => statusToButton[periodStatus].action(setOpenCommentBlock)}
        >
          {statusToButton[periodStatus].text}
        </Button>
      </div>
    </StyledPeriod>
  )
}

export default Period
