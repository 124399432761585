import { useEffect, useRef } from 'react'

export function useInitDefaultValue(ref, defaultValue) {
  useEffect(() => {
    if (defaultValue) {
      ref.current.value = defaultValue
    }
  }, [])
}

export function useOnClickInside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        handler(event)
      }
    }
    document.addEventListener('mousedown', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler])
}

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target) || event.buttons !== 1) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler])
}

export function usePrev(value) {
  const prevValue = useRef()

  useEffect(() => {
    prevValue.current = value
  })

  return prevValue.current
}
