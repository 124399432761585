import styled from 'styled-components'

export const ProjectSalesScreenStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .revenue,
  .expenses {
    margin-bottom: 2.4rem;

    >.content {
      display: flex;
      flex-flow: column nowrap;
      gap: 1.5em;
      align-items: stretch;
      justify-content: stretch;
    }
  }

  .revenue {
    .service-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .content-column {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        justify-content: space-between;
        width: 49%;
      }
    }
  }

  .staffing {
    margin-top: 2.4rem;
  }

  .costs {
    margin-bottom: 2.4rem;
  }

  .text-bold {
    font-weight: 600;
  }

  .row-section-add-presales {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`
