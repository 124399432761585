import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@produits-internes-oss/design-system-components'
import { ProjectTurnoverStyled } from './ProjectTurnover.styled'
import { useProjectSummaryContext } from '../../context/ProjectSummaryContext/ProjectSummaryContext'
import { parseFloatWithAnySeparator, getNumberWithUnit } from '../../helpers/stringHelper'

export const ProjectTurnover = () => {
  const { t } = useTranslation()
  const {
    project,
    cuttedAmount,
    turnoverStillToBeDone,
    totalAmountProject,
    cuttedAmountPercent,
    turnoverStillToBeDonePercent,
    totalAmountProjectPercent,
  } = useProjectSummaryContext()

  if (!project) return <div>...</div>

  return (
    <ProjectTurnoverStyled>
      <section>
        <div className="subtitle">
          <h2>
            {t('project.summary.turnover.cutting')}
          </h2>
          <Tooltip
            className="summary-team-info-tooltip"
            position="top"
            skin="light"
            trigger={
                ({ open, close }) => (
                  <button
                    type="button"
                    onMouseEnter={() => open()}
                    onMouseLeave={() => close()}
                    className="tooltip-trigger-button"
                    aria-label="tooltip trigger button"
                  />
                )
                }
            content={
                  () => (
                    <p>
                      <b>{t('project.summary.turnover.tooltipContent.cutting.first')}</b>
                      {' '}
                      {t('project.summary.turnover.tooltipContent.cutting.second')}
                    </p>
                  )
                }
          />
        </div>
        <span className="percent">
          (
          {cuttedAmountPercent || cuttedAmountPercent === 0 ? `${(parseFloat(cuttedAmountPercent) * 100).toFixed(2)}%` : '0%'}
          )
        </span>
        <span>{cuttedAmount || cuttedAmount === 0 ? `${getNumberWithUnit(parseFloatWithAnySeparator(cuttedAmount), '€')}` : '-'}</span>
      </section>
      <div className="vertical-line" />
      <section>
        <div className="subtitle">
          <h2>{t('project.summary.turnover.remaining')}</h2>
          <Tooltip
            className="summary-team-info-tooltip"
            position="top"
            skin="light"
            trigger={
                ({ open, close }) => (
                  <button
                    type="button"
                    onMouseEnter={() => open()}
                    onMouseLeave={() => close()}
                    className="tooltip-trigger-button"
                    aria-label="tooltip trigger button"
                  />
                )
                }
            content={
                  () => (
                    <p>
                      <b>
                        {' '}
                        {t('project.summary.turnover.tooltipContent.remaining.first')}
                        {' '}
                      </b>
                    </p>
                  )
                }
          />
        </div>

        <span className="percent">
          (
          {turnoverStillToBeDonePercent ? `${(parseFloat(turnoverStillToBeDonePercent) * 100).toFixed(2)}%` : '0%'}
          )
        </span>
        <span>{turnoverStillToBeDone || turnoverStillToBeDone === 0 ? `${getNumberWithUnit(parseFloatWithAnySeparator(turnoverStillToBeDone), '€')}` : '-'}</span>
      </section>
      <div className="vertical-line" />
      <section>
        <div className="subtitle">
          <h2>{t('project.summary.turnover.timeInputConsumption')}</h2>
          <Tooltip
            className="summary-team-info-tooltip"
            position="top"
            skin="light"
            trigger={
                ({ open, close }) => (
                  <button
                    type="button"
                    onMouseEnter={() => open()}
                    onMouseLeave={() => close()}
                    className="tooltip-trigger-button"
                    aria-label="tooltip trigger button"
                  />
                )
                }
            content={
                  () => (
                    <p>

                      <span>
                        {t('project.summary.turnover.tooltipContent.timeInputConsumption.first')}
                        {' '}
                        <b>{t('project.summary.turnover.tooltipContent.timeInputConsumption.second')}</b>
                      </span>
                      <br />
                      <span><b>{t('project.summary.turnover.tooltipContent.timeInputConsumption.third')}</b></span>
                      <br />
                      <span>{t('project.summary.turnover.tooltipContent.timeInputConsumption.fourth')}</span>
                      <br />
                      <span>{t('project.summary.turnover.tooltipContent.timeInputConsumption.fith')}</span>
                    </p>
                  )
                }
          />
        </div>
        <span className="percent">
          (
          {totalAmountProjectPercent ? `${(parseFloat(totalAmountProjectPercent) * 100).toFixed(2)}%` : '0%'}
          )
        </span>
        <span>
          {totalAmountProject || totalAmountProject === 0 ? `${getNumberWithUnit(parseFloatWithAnySeparator(totalAmountProject), '€')}` : '-'}
        </span>
      </section>
    </ProjectTurnoverStyled>
  )
}
