import styled from 'styled-components'

export const ProjectStatusStyled = styled.div`
  .status-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 8.4rem;
    background-color: ${({ theme }) => theme.colors.betterway.white};

    .status-box {
      display: flex;
      align-items: center;
      height: 3.4rem;
      background-color: ${({ theme }) => theme.colors.betterway.white};

      .next-status {
        display: flex;
        gap: 0.8rem;
      }

      hr {
        height: 100%;
        margin-right: 2.8rem;
        margin-left: 2.8rem;
        border-width: 0.05rem;
        border-style: solid;
        border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
      }

      .current-status {
        display: flex;
        gap: 0.8rem;
        align-items: center;

        p {
          font-weight: 600;
          font-size: 1.6rem;
        }
      }
    }
  }
`
