import styled from 'styled-components'

export const StyledTimesheetErrors = styled.tbody`
  tr {
    height: 1px;
  }

  th {
    width: 1px;
    vertical-align: top;
    padding: 0 0.5rem;
  }

  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    .selector:not(*:root),
    th {
      height: 1px;
    } 
  }

  @-moz-document url-prefix() {
    th {
      height: 100%;
    }
  }
`

export const StyledErrorsBlock = styled.div`
  box-sizing: border-box;
  height: 100%;
  display: block;
  text-align: left;
  border-radius: 4px;
  padding: 2rem 4rem 2rem 2rem;
  background-color: ${({ theme, type }) => theme.colors[`${type}Light`]};
  font-size: inherit;

  h4 {
    font-family: ${({ theme }) => theme.fonts.family.bold};
    font-size: inherit;
    &:first-of-type {
      margin-bottom: 1rem;
    }
  }

  ul {
    list-style: none;
    li {
      margin-bottom: 1rem;
      &::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        background-color: ${({ theme, type }) => theme.colors[`${type}`]};
        margin-left: -2rem;
      }
      span {
        display: inline-block;
        vertical-align: top;
        position: relative;
        left: 2rem;
      }
    }
  }
`
