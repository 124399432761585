import React from 'react'
import { useTranslation } from 'react-i18next'
import { useManagerPeriodsContext } from '../../../context/ManagePeriods/ManagePeriodsContext'
import Button from '../../input/_ExportDS/Button/Button'
import pictoLock from '../../../../assets/images/emptyLock.svg'
import pictoUnlock from '../../../../assets/images/emptyUnlock.svg'
import { LockPeriodsStyled } from './LockPeriods.styled'

export default function Lockperiods() {
  const { t } = useTranslation()
  const { fetchPostLockPeriods, fetchPostUnlockPeriods, periodsLocked } = useManagerPeriodsContext()
  if (periodsLocked) {
    return (
      <LockPeriodsStyled>
        <div className="button-container">
          <Button
            text={t('managePeriods.unlockPeriods')}
            className="unlock-periods"
            picto={pictoLock}
            onClick={async () => { await fetchPostUnlockPeriods() }}
            name="unlock periods of all Octos"
          />
        </div>
      </LockPeriodsStyled>
    )
  }

  return (
    <LockPeriodsStyled>
      <div className="button-container">
        <Button
          text={t('managePeriods.lockPeriods')}
          className="lock-periods"
          picto={pictoUnlock}
          onClick={async () => { await fetchPostLockPeriods() }}
          name="lock periods of all Octos"
        />
      </div>
    </LockPeriodsStyled>
  )
}
