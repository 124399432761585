import React from 'react'

import Role from '../Role/Role'

const RolesContainer = ({ person, roles, getProfile }) => (
  roles.sort((a, b) => a.name.localeCompare(b.name))
    .map((role) => (
      <Role
        key={role.id}
        person_id={person.id}
        role_id={role.id}
        name={role.name}
        isChecked={role.has_role}
        onChange={getProfile}
        className="role-item"
      />
    ))
)

export default RolesContainer
