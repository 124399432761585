import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectServiceAdjustmentBonusStyled } from './ProjectServiceAdjustmentBonus.styled'
import Input from '../input/_ExportDS/InputWithStyle/Input'
import Button from '../input/_ExportDS/ButtonWithStyle/Button'
import pictoEdit from '../../../assets/images/editBlue.svg'
import { parseFloatWithAnySeparator } from '../../helpers/stringHelper'

export const ProjectServiceAdjustmentBonus = () => {
  const { t } = useTranslation()
  const {
    project,
    errors,
    fetchUpdateServiceAdjustmentBonus,
    serviceAdjustmentBonusAmount,
    serviceAdjustmentBonusPercent,
    setServiceAdjustmentBonusAmount,
    setServiceAdjustmentBonusPercent,
    isProjectPresales,
  } = useProjectSalesContext()

  const [isAllEditable, setIsAllEditable] = useState(true)
  const [serviceAdjustmentBonusAmountEditable, setServiceAdjustmentBonusAmountEditable] = useState(false)
  const [serviceAdjustmentBonusPercentEditable, setServiceAdjustmentBonusPercentEditable] = useState(false)
  const [computedServiceAdjustmentBonusAmount, setComputedServiceAdjustmentBonusAmount] = useState(null)
  const [computedServiceAdjustmentBonusPercent, setComputedServiceAdjustmentBonusPercent] = useState(null)

  const calculateComputedServiceAdjustmentBonusAmount = (isServiceAdjustmentBonusAmountEmpty) => {
    if (isServiceAdjustmentBonusAmountEmpty && serviceAdjustmentBonusPercent !== null && !Number.isNaN(serviceAdjustmentBonusPercent)) {
      return parseFloatWithAnySeparator((serviceAdjustmentBonusPercent / 100) * project.service).toFixed(2)
    }

    return ''
  }

  const calculateComputedServiceAdjustmentBonusPercent = (isServiceAdjustmentBonusPercentEmpty) => {
    if (isServiceAdjustmentBonusPercentEmpty && serviceAdjustmentBonusAmount !== null && !Number.isNaN(serviceAdjustmentBonusAmount)) {
      return parseFloatWithAnySeparator((serviceAdjustmentBonusAmount / project.service) * 100).toFixed(2)
    }

    return ''
  }

  useEffect(() => {
    const isServiceAdjustmentBonusPercentEmpty = (serviceAdjustmentBonusPercent === null || Number.isNaN(serviceAdjustmentBonusPercent))
    const isServiceAdjustmentBonusAmountEmpty = (serviceAdjustmentBonusAmount === null || Number.isNaN(serviceAdjustmentBonusAmount))

    setServiceAdjustmentBonusAmountEditable((serviceAdjustmentBonusPercent === null || Number.isNaN(serviceAdjustmentBonusPercent)))
    setServiceAdjustmentBonusPercentEditable((serviceAdjustmentBonusAmount === null || Number.isNaN(serviceAdjustmentBonusAmount)))

    setComputedServiceAdjustmentBonusAmount(calculateComputedServiceAdjustmentBonusAmount(isServiceAdjustmentBonusAmountEmpty))
    setComputedServiceAdjustmentBonusPercent(calculateComputedServiceAdjustmentBonusPercent(isServiceAdjustmentBonusPercentEmpty))
  }, [serviceAdjustmentBonusAmount, serviceAdjustmentBonusPercent, isAllEditable])

  useEffect(() => {
    if (project !== null) {
      const isServiceAdjustmentBonusAmountPresent = project.service_adjustment_bonus !== undefined && project.service_adjustment_bonus !== null
      const isServiceAdjustmentBonusPercentPresent = project.service_adjustment_bonus_percent !== undefined && project.service_adjustment_bonus_percent !== null
      const hasErrors = errors.length > 0

      setServiceAdjustmentBonusAmount(project.service_adjustment_bonus)
      setServiceAdjustmentBonusPercent(project.service_adjustment_bonus_percent && (project.service_adjustment_bonus_percent * 100).toFixed(2))
      setIsAllEditable((!isServiceAdjustmentBonusAmountPresent && !isServiceAdjustmentBonusPercentPresent) || hasErrors)

      setComputedServiceAdjustmentBonusAmount(calculateComputedServiceAdjustmentBonusAmount(true))
      setComputedServiceAdjustmentBonusPercent(calculateComputedServiceAdjustmentBonusPercent(true))
    }
  }, [project, errors])

  if (project === null) {
    return <div>...</div>
  }

  return (
    isProjectPresales() && (
      <ProjectServiceAdjustmentBonusStyled>
        <div className="content-left">
          <div className="input-group">
            <Input
              className="value"
              name={t('project.revenue.service_adjustment_bonus.amount_value.arialabel')}
              title={t('project.revenue.service_adjustment_bonus.amount_value.text')}
              value={(serviceAdjustmentBonusAmount || serviceAdjustmentBonusAmount === 0) ? serviceAdjustmentBonusAmount : computedServiceAdjustmentBonusAmount}
              type="number"
              disabled={!serviceAdjustmentBonusAmountEditable || !isAllEditable || project.service === null || project.service < 0}
              onChange={(value) => setServiceAdjustmentBonusAmount(parseFloatWithAnySeparator(value))}
              onValidate={() => Number.isInteger(serviceAdjustmentBonusAmount) && fetchUpdateServiceAdjustmentBonus(serviceAdjustmentBonusAmount, serviceAdjustmentBonusPercent)}
            />
            <i className={!serviceAdjustmentBonusAmountEditable || !isAllEditable ? 'disabled-input' : ''}>€</i>
          </div>
          <div className="input-group">
            <Input
              className="value"
              name={t('project.revenue.service_adjustment_bonus.percent_value.arialabel')}
              title={t('project.revenue.service_adjustment_bonus.percent_value.text')}
              value={(serviceAdjustmentBonusPercent || serviceAdjustmentBonusPercent === 0) ? serviceAdjustmentBonusPercent : computedServiceAdjustmentBonusPercent}
              type="number"
              disabled={!serviceAdjustmentBonusPercentEditable || !isAllEditable || project.service === null || project.service < 0}
              onChange={(value) => setServiceAdjustmentBonusPercent(parseFloatWithAnySeparator(value))}
              onValidate={() => Number.isInteger(serviceAdjustmentBonusPercent) && fetchUpdateServiceAdjustmentBonus(serviceAdjustmentBonusAmount, serviceAdjustmentBonusPercent)}
            />
            <i className={!serviceAdjustmentBonusPercentEditable || !isAllEditable ? 'disabled-input' : ''}>%</i>
          </div>
        </div>
        <div>
          {
            isAllEditable
              ? (
                <Button
                  className="create"
                  name={t('project.revenue.service_adjustment_bonus.create.arialabel')}
                  text={t('project.revenue.service_adjustment_bonus.create.text')}
                  disabled={
                    project.service === null || project.service < 0
                    || (Number.isNaN(serviceAdjustmentBonusAmount) && Number.isNaN(serviceAdjustmentBonusPercent))
                    || (serviceAdjustmentBonusAmount === null && Number.isNaN(serviceAdjustmentBonusPercent))
                    || (serviceAdjustmentBonusPercent === null && Number.isNaN(serviceAdjustmentBonusAmount))
                  }
                  onClick={() => fetchUpdateServiceAdjustmentBonus(serviceAdjustmentBonusAmount, serviceAdjustmentBonusPercent)}
                />
              )
              : (
                <Button
                  className="edit"
                  name={t('project.revenue.service_adjustment_bonus.edit.arialabel')}
                  text={t('project.revenue.service_adjustment_bonus.edit.text')}
                  picto={pictoEdit}
                  onClick={() => setIsAllEditable(true)}
                />
              )
          }
        </div>
      </ProjectServiceAdjustmentBonusStyled>
    )
  )
}
