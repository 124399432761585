import React from 'react'
import { useActivityValues } from '../../../../context/Timesheet/ActivityContext'
import ActivityLines from './ActivityLines/ActivityLines'
import { StyledTimesheetBody } from './TimesheetBody.styled'

const TimesheetBody = () => {
  const { activities } = useActivityValues()

  const activityCategories = Object.keys(activities)

  let cumulatedLength = 0
  const activityCategoriesCumulatedLength = activityCategories.map((activityCategory) => {
    cumulatedLength += activities[activityCategory.toString()].length
    return cumulatedLength
  })

  const activityCategoryIsNotEmpty = (activityCategory) => activities[activityCategory.toString()].length !== 0

  return (
    <StyledTimesheetBody>
      {
        activityCategories.map((activityCategory, index) => {
          if (activityCategoryIsNotEmpty(activityCategory)) {
            return (
              <ActivityLines
                key={activityCategory}
                activityCategory={activityCategory.toString()}
                cumulatedNbActivities={activityCategoriesCumulatedLength[index - 1] || 0}
                numberOfActivities={activityCategoriesCumulatedLength[activityCategoriesCumulatedLength.length - 1]}
              />
            )
          }
        })
      }
    </StyledTimesheetBody>
  )
}

export default TimesheetBody
