import React from 'react'
import { useTranslation } from 'react-i18next'
import { InputRadio, InputSearch, InputSelect, Section } from '@produits-internes-oss/design-system-components'
import { useDebouncer } from '../../hooks/useDebouncer/useDebouncer'
import { useSearch } from '../../hooks/useSearch/useSearch'
import { useProjectFormContext } from '../../context/ProjectForm/ProjectFormContext'
import { ProjectFormMissionAcceptedStyled } from './ProjectFormMissionAccepted.styled'

export const ProjectFormMissionAccepted = () => {
  const { t } = useTranslation()
  const { debounce } = useDebouncer()
  const { fetchSearch } = useSearch()
  const { cache, form, getPersonName, updateForm } = useProjectFormContext()

  return (
    <ProjectFormMissionAcceptedStyled status="mission_accepted">
      <Section className="form-section">
        <div className="columns half">
          <div className="column">
            <div className="form-group">
              <InputSearch
                className="form-control"
                skin="light"
                name="mission_director_id search"
                title={t('project.form.mission_director_id')}
                valueInitial={{ key: form['mission_director_id'].value, search: getPersonName(form['mission_director_name'].value) }}
                required={form['mission_director_id'].required}
                onSearch={(search) => debounce(() => search.length >= 3 && fetchSearch(search, 'Person', null, getPersonName), 500)}
                onChange={({ key }) => updateForm('mission_director_id', key && parseInt(key, 10))}
              />
              <InputSelect
                className="form-control"
                skin="light"
                name="prevention_plan_status select"
                title={t('project.form.prevention_plan_status')}
                options={Object.fromEntries(cache.preventionPlanStatuses.map((status) => [status, t(`project.form.prevention_plan_statuses.${status}`)]))}
                valueInitial={form['prevention_plan_status'].value}
                required={form['prevention_plan_status'].required}
                onChange={([value]) => updateForm('prevention_plan_status', value && value)}
              />
              <InputRadio
                className="form-control radio radio-inline"
                skin="light"
                name="subcontractor_presence radio"
                title={t('project.form.subcontractor_presence')}
                options={{ true: t('yes'), false: t('no') }}
                valueInitial={form['subcontractor_presence'].value?.toString()}
                required={form['subcontractor_presence'].required}
                onChange={([value]) => updateForm('subcontractor_presence', value === 'true')}
              />
              <InputRadio
                className="form-control radio radio-inline"
                skin="light"
                name="extra_resource radio"
                title={t('project.form.extra_resource')}
                options={{ true: t('yes'), false: t('no') }}
                valueInitial={form['extra_resource'].value?.toString()}
                required={form['extra_resource'].required}
                onChange={([value]) => updateForm('extra_resource', value === 'true')}
              />
            </div>
          </div>
        </div>
      </Section>
    </ProjectFormMissionAcceptedStyled>
  )
}
