import React, { useState } from 'react'
import Chevron from '../Chevron/Chevron'
import { StyledProjectAccordion } from './ProjectAccordion.styled'

const ProjectAccordion = ({ open, children, title }) => {
  const [unfold, setUnfold] = useState(open)

  return (
    <StyledProjectAccordion open={open} unfold={unfold}>
      <summary onClick={() => setUnfold(!unfold)}>
        <Chevron fill="rgb(0, 136, 204)" type="project" unfold />
        <h3>{title}</h3>
      </summary>
      {children}
    </StyledProjectAccordion>
  )
}

export default ProjectAccordion
