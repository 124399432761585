import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledExpenseSummaryAccordion } from './ExpenseSummaryAccordion.styled'
import { ProjectAccordion } from '../UI'
import ExpenseSummaryTable from './ExpenseSummaryTable/ExpenseSummaryTable'

const ExpenseSummaryAccordion = ({ project }) => {
  const [expenses, setExpenses] = useState(null)
  const [projectId] = useState(project)
  const [hasError, setError] = useState(false)
  const [load_expenses] = useState(false)

  const { t } = useTranslation()

  const getProjectExpenses = async () => {
    setError(false)
    const url = `/projects/${projectId}/expenses_total_amount_by_invoice_kind?load_expenses=${load_expenses}`
    const response = await fetch(url)
    if (response.ok) {
      setExpenses(await response.json())
    } else {
      setError(true)
    }
    return [hasError, expenses]
  }

  useEffect(() => {
    getProjectExpenses()
  }, [])

  return (
    <StyledExpenseSummaryAccordion>
      {expenses && (
        <ProjectAccordion
          open
          title={t('ExpenseSummaryAccordion.title')}
        >
          <div className="body-content">
            <ExpenseSummaryTable expenses={expenses} />
            <a
              className="btn-project-detail"
              href={`/projects/${projectId}/project_expenses_reports`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('ExpenseSummaryAccordion.expenses.detail')}
            </a>
          </div>
        </ProjectAccordion>
      )}
    </StyledExpenseSummaryAccordion>
  )
}

export default ExpenseSummaryAccordion
