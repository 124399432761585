import styled, { css } from 'styled-components'

export const StyledTimesheet = styled.div`
  padding: 1.2rem;
  width: 18rem;
  border: solid 3px ${({ theme, active }) => (active ? theme.colors.primary : theme.colors.secondaryLight)};
  border-radius: 4px;
  cursor: pointer;
  height: 100%;

  ${({ disabled }) => disabled && css`
      opacity: 0.4;
      cursor: not-allowed !important;
  `}
  ${({ disabled }) => !disabled && css`
    :hover {
      border: solid ${({ theme }) => theme.colors.primary} 3px;
    }
  `}

  &:not(:last-child) {
    margin-right: 1.2rem;
  }

  h3 {
    font-size: 1.6rem;
    text-transform: capitalize;
  }

  p {
    font-size: 1.2rem;
    margin: 2px 0;
  }

  .status {
    padding: 4px;
    line-height: .5;
    font-size: 1rem;
    border-radius: 2px;
    &.to-send {
      background-color: ${({ theme }) => theme.colors.lightYellow};
      color: ${({ theme }) => theme.colors.yellow};
    }
    &.sent {
      background-color: ${({ theme }) => theme.colors.primaryBluePale};
      color: ${({ theme }) => theme.colors.primaryBlue};
    }
    &.closed {
      background-color: ${({ theme }) => theme.colors.lightYellow};
      color: ${({ theme }) => theme.colors.yellow};
    }
  }

  .period {
    display: flex;
    align-items: center;
    justify-content: left;
  }
`
