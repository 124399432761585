import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledHappinessIndicatorForm } from './HappinessIndicatorForm.styled'

import { request } from '../../../../../javascripts/HttpService'

import HPIQuestion from '../HPIQuestion/HPIQuestion'

const HappinessIndicatorForm = ({
  periodNumber,
  timesheetId,
}) => {
  const { t } = useTranslation()
  const [happinessIndicatorForm, setHappinessIndicatorForm] = useState({})

  const getHappinessIndicatorForm = () => {
    if (periodNumber === 2) {
      request(`/happiness_indicators/${timesheetId}`).then((responseData) => setHappinessIndicatorForm(responseData))
    }
  }

  useEffect(() => {
    getHappinessIndicatorForm()
  }, [])

  return (
    periodNumber === 2 && (
      <StyledHappinessIndicatorForm>
        <h3>{t('periodValidationModal.happinessIndicator.title')}</h3>
        <p>{t('periodValidationModal.happinessIndicator.subtitleInfo')}</p>
        {
          happinessIndicatorForm?.happinessIndicators?.length === 0
            ? <h6>{t('periodValidationModal.happinessIndicator.noneForThisMonth')}</h6>
            : happinessIndicatorForm?.happinessIndicators?.map((happinessIndicator, index) => (
              <HPIQuestion
                key={happinessIndicator?.billable_project_id || happinessIndicator?.activity_id}
                happinessIndicator={happinessIndicator}
                even={index % 2 === 0}
                allowedValues={happinessIndicatorForm.allowedValues}
              />
            ))
        }
      </StyledHappinessIndicatorForm>
    )
  )
}

export default HappinessIndicatorForm
