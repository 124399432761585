import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react'

import {
  useFetchData,
  buildUrlWithId,
} from '../../../fetchOverviewApi'
import { useStateValue } from '../../../state'

import ActivityOverviewRow from '../ActivityOverviewRow/ActivityOverviewRow'
import SkeletonTableRow from '../SkeletonRow/SkeletonRow'
import LobRow from '../LobRow/LobRow'

const LeagueRow = ({ leagueData }) => {
  const [open, setOpen] = useState(false)
  const [{ range }] = useStateValue()
  const [loading, hasError, data, handleFetch] = useFetchData(true)
  const isMounted = useRef(true)

  useEffect(() => {
    if (!open || data) return

    const url = buildUrlWithId('league', leagueData.id, range)
    handleFetch(isMounted, url)
  }, [open, data])

  const renderChildren = useCallback(() => (
    loading ? <SkeletonTableRow rows={3} columns={14} /> : renderRows()
  ), [loading, data])

  const renderRows = () => {
    if (!hasError) {
      return (
        <>
          {
            data.lobs.map((lob) => (
              <LobRow
                key={lob.id}
                lobData={lob}
              />
            ))
          }
        </>
      )
    }
  }

  useEffect(() => () => {
    isMounted.current = false
  }, [])

  return (
    <>
      <ActivityOverviewRow
        style="league"
        open={open}
        data={leagueData}
        onClick={() => setOpen(!open)}
        key={leagueData.id}
      />
      {open && renderChildren()}
    </>
  )
}

export default LeagueRow
