import styled from 'styled-components'

export const StyledActivityRecap = styled.div`  
  font-size: 1.2rem;
  box-sizing: border-box;
  padding: 0 1rem;
  height: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme, even }) => (even ? theme.colors.paleGrey3 : theme.colors.paleGrey4)};
  
  p {
    width: 80%;
  }

  &.total {
    margin: 2rem 0;
    background: ${({ theme }) => theme.colors.paleBlue};
    border-radius: 4px;
    span {
      background: ${({ theme }) => theme.colors.lightBlue};
      color:  ${({ theme }) => theme.colors.white};
    }
  }
  
  strong {
    font-family: ${({ theme }) => theme.fonts.family.bold};
  }

  span {
    display: flex;
    align-items: center;
    height: 65%;
    background-color: ${({ theme }) => theme.colors.paleBlue};
    padding: 0 1rem;
    border-radius: 20px;
  }
`
