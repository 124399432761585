import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledDateRangePickerSimplified } from './DateRangePickerSimplified.styled'
import MonthPickerSimplified from './MonthPickerSimplified/MonthPickerSimplified'
import MonthRangePickerSimplified from './MonthRangePickerSimplified/MonthRangePickerSimplified'

const DateRangePickerSimplified = ({ onChangeMonthYearRange }) => {
  const { t } = useTranslation()

  const startYear = 2013
  const currentDate = new Date(Date.now())
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()

  const updateDateRange = (startDate, endDate) => {
    onChangeMonthYearRange && onChangeMonthYearRange(startDate, endDate)
  }

  useEffect(() => {
    const startDate = new Date(currentYear, currentMonth, 1)
    const endDate = new Date(currentYear, currentMonth + 1, 0)
    updateDateRange(startDate, endDate)
  }, [])

  return (
    <StyledDateRangePickerSimplified>
      <ul className="nav nav-tabs">
        <li>
          <a data-toggle="tab" href="#range">{t('subcontractorsOverview.SubcontractorsTableHeader.range')}</a>
        </li>
        <li className="active">
          <a data-toggle="tab" href="#month">{t('subcontractorsOverview.SubcontractorsTableHeader.month')}</a>
        </li>
      </ul>
      <div className="tab-content">
        <div className="tab-pane" id="range">
          <MonthRangePickerSimplified
            currentMonth={currentMonth}
            currentYear={currentYear}
            startYear={startYear}
            onSubmitMonthYearRange={(startMonth, startYear, endMonth, endYear) => {
              updateDateRange(new Date(startYear, startMonth, 1), new Date(endYear, endMonth + 1, 0))
            }}
          />
        </div>
        <div className="tab-pane active" id="month">
          <MonthPickerSimplified
            currentMonth={currentMonth}
            currentYear={currentYear}
            startYear={startYear}
            onSubmitMonthYear={(month, year) => {
              updateDateRange(new Date(year, month, 1), new Date(year, month + 1, 0))
            }}
          />
        </div>
      </div>
    </StyledDateRangePickerSimplified>
  )
}

export default DateRangePickerSimplified
