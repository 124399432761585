import styled from 'styled-components'

export const ProjectExpensesTimeSpentTableStyled = styled.div`
  &.octo {
    margin-bottom: 3rem !important;

    h3.title {
      font-weight: 600;
      font-size: 2.4rem;
    }

    div.name-and-label {
      p.label {
        font-weight: 600;
        font-size: 1.5rem;
      }

      p.full-name {
        font-weight: 400;
        font-size: 1.2rem;
      }
    }

    .expenses-comsumption-table {
      > table {
        border-collapse: collapse;

        .expense-status {
          display: flex;
          align-items: center;
          width: 100%;
          margin: auto;
          margin-top: 0.8rem;

          .expense-status-toggle {
            display: inline-block;
            vertical-align: middle;
          }

          span {
            position: inherit;
            margin: 0.7rem 0.2rem;
            margin-left: 0.8rem;
            font-weight: 600;
            font-size: 1.4rem;
            user-select: none;
          }

          .toggle-btn {
            position: relative;
            width: 3.6rem;
            height: 2.1rem;
            border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[100]};
            border-radius: 10rem;
            background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
            cursor: pointer;
          }

          .btn-inactive {
            border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[50]};
            background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
          }

          .circle {
            position: absolute;
            top: 50%;
            width: 1.3rem;
            height: 1.3rem;
            border-radius: 10rem;
            transition: all 0.5s ease;
            transform: translateY(-50%);
          }

          .off {
            left: 0.3rem;
            background-color: ${({ theme }) => theme.colors.betterway.white};
          }

          .on {
            left: calc(100% - 1.7rem);
            background-color: ${({ theme }) => theme.colors.betterway.white};
          }
        }

        thead > tr > th {
          position: sticky;
          top: 0;
          z-index: 3;
        }

        thead > tr > th:first-child,
        thead > tr > th:nth-of-type(6) {
          z-index: 4;
          background-color: ${({ theme }) => theme.colors.betterway.primary[20]};
        }

        tbody > tr > td:first-child,
        tfoot > tr > td:first-child,
        thead > tr > th:first-child {
          position: sticky;
          left: 0;
          z-index: 4;
          width: ${(props) => (props.activitiesDeleteMode ? `12rem` : `auto`)};
          min-width: ${(props) => (props.activitiesDeleteMode ? `12rem` : `35rem`)};
          border-right: 0.0625em solid ${({ theme }) => theme.colors.betterway.primary[50]};
        }

        tbody > tr > td:first-child,
        tbody > tr > td:nth-child(6) {
          z-index: 3;
        }

        > tbody > tr > td:nth-child(6),
        > tfoot > tr > td:nth-child(6),
        > thead > tr > th:nth-of-type(6) {
          position: sticky;
          left: ${(props) => (props.activitiesDeleteMode ? `o` : `35rem`)};
          width: ${(props) => (props.activitiesDeleteMode ? `12rem` : `auto`)};
          min-width: ${(props) => (props.activitiesDeleteMode ? `12rem` : `35rem`)};
          border-right: 0.0625em solid ${({ theme }) => theme.colors.betterway.primary[50]};
        }

        > tbody > tr > td {
          background-color: inherit;

          &.align-right {
            >div.cell {
              text-align: right;

              >div {
                text-align: right;
              }

              >span.unit.price {
                justify-content: flex-end;
              }
            }
          }
        }

        .input-align-right > div.control > input {
          text-align: right;
        }

        > tfoot > tr > td {
          background-color: ${({ theme }) => theme.colors.betterway.primary[20]};
        }

        tfoot > tr > td:not(:nth-child(1)) {
          text-align: right;
        }

        > thead > tr > th:nth-of-type(2) {
          border-left: none;
        }

        tbody > tr > td:nth-child(2) {
          border-left: none;
          text-align: ${(props) => (props.activitiesDeleteMode ? 'left' : 'right')};
        }

        > tfoot > tr > td:nth-child(2) {
          border-left: none;
          text-align: ${(props) => (props.activitiesDeleteMode ? 'left' : 'right')};
        }

        .input-radio-destroy {
          display: flex;
          gap: 1rem;
          align-items: center;

          img {
            width: 2.4rem;
            height: 2.4rem;
            margin: 0;
          }

          .button-delete-activity {
            width: auto;

            &.disabled {
              button {
                .checkbox {
                  background-color: ${({ theme }) => theme.colors.betterway.primary[70]} !important;

                  .checkbox-icon {
                    background-color: ${({ theme }) => theme.colors.betterway.primary[70]} !important;
                  }
                }
              }
            }
          }

          .control {
            margin: 0;
            padding: 0;
          }
        }

        .delete-mode-hide {
          width: 0%;
        }

        .disabled-input-tooltip {
          width: auto;

          .popup-tooltip {
            background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
            color: ${({ theme }) => theme.colors.betterway.white};
          }

          .popup-tooltip::after {
            background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
          }

          .popup-overlay {
            opacity: 0%;
          }

          em {
            font-weight: 600;
          }
        }
      }
    }

    .view {
      margin-top: 1.6rem;

      .control {
        display: flex;
        gap: 0.8rem;
        width: auto;
        margin-top: 1rem;
      }
    }

    .wrapper {
      position: relative;
      overflow: auto;
      max-height: 80rem;
      border-radius: 2rem;
      white-space: nowrap;
    }

    .copy-succeed {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
      padding: 1.6rem;
      border: solid 0.2rem ${({ theme }) => theme.colors.betterway.green[100]};
      border-radius: 0.3rem;
      background-color: ${({ theme }) => theme.colors.betterway.green[5]};
    }
  }
`
