import React, {
  useState,
  createContext,
  useContext,
} from 'react'

export const LocationContext = createContext()

export const LocationContextProvider = ({ initialLocations, countries, children }) => {
  const [locationPerDay, setLocationPerDay] = useState(initialLocations)

  return (
    <LocationContext.Provider value={{ locationPerDay, setLocationPerDay, countries }}>
      {children}
    </LocationContext.Provider>
  )
}

export const useLocationValue = () => useContext(LocationContext)
