import styled from 'styled-components'

export const StyledAskbobLogo = styled.a`
  img {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`
