import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import { registerServiceWorkerForPWA } from '../../registerServiceWorker'
import { useOnScreenResize } from '../../hooks/useOnScreenResize/useOnScreenResize'
import { ExpensesContextProvider } from '../../context/Expenses/ExpensesContext'
import ExpensesTabs from '../../components/ExpensesTabs/ExpensesTabs'

export default function ExpensesScreen({ year, month, period, person, canEditAlreadySentExpenses }) {
  const { height } = useOnScreenResize()

  const updateHeightForMobile = () => {
    document.documentElement.style.setProperty('--vh', `${height * 0.01}px`)
  }

  useEffect(() => {
    registerServiceWorkerForPWA('/expenses')
  }, [])

  useEffect(() => {
    updateHeightForMobile()
  },
  [height])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ExpensesContextProvider
        initialProps={{
          year,
          month: month - 1,
          period: period - 1,
          person,
        }}
        canEditAlreadySentExpenses={canEditAlreadySentExpenses}
      >
        <ExpensesTabs />
      </ExpensesContextProvider>
    </ThemeProvider>
  )
}
