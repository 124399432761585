import React, { useRef, useCallback, useState, useMemo } from 'react'
import { Button, Table, InputNumber, InputRadio, Tooltip } from '@produits-internes-oss/design-system-components'
import { useTranslation } from 'react-i18next'
import { getNumberWithUnit, getYearMonthFromDateString, getClass } from '../../../helpers/stringHelper'
import { useProjectCoordinationContext } from '../../../context/ProjectCoordination/ProjectCoordinationContext'
import { useProjectSalesContext } from '../../../context/ProjectSale/ProjectSalesContext'
import { ProgressBar } from '../../ProgressBar/ProgressBar'
import { ProjectExpensesTimeSpentTableStyled } from './ProjectExpensesTimeSpentTable.styled'
import { useDebouncer } from '../../../hooks/useDebouncer/useDebouncer'
import { ProjectStaffingFormHandler } from '../../ProjectStaffingFormHandler/ProjectStaffingFormHandler'
import { ModalInfosDeleteActivityExpense } from '../../ProjectCoordinationModal/ModalInfosDeleteActivityExpense/ModalInfosDeleteActivityExpense'
import { ButtonDeleteActivityMode } from '../../ButtonDeleteActivityMode/ButtonDeleteActivityMode'
import { ModalListDeleteActivityExpense } from '../../ProjectCoordinationModal/ModalListDeleteActivityExpense/ModalListDeleteActivityExpense'
import { getRoundedToDecimal } from '../../../helpers/numberHelper'
import { useJQuery } from '../../../hooks/useJQuery/useJQuery'

import pictoDestroy from '../../../../assets/images/destroy.svg'

import pictoCopy from '../../../../assets/images/copy.svg'

const formatNameColumn = (label, fullName) => (
  <div className="name-and-label">
    <p className="label">{label}</p>
    <p className="full-name">{fullName}</p>
  </div>
)

export const ProjectExpensesTimeSpentTable = ({ title, months, monthsWithValidation, expensesConsumption, totalAmountsConsumedPerMonths, totalConsumption, expenseType }) => {
  const { t } = useTranslation()
  const { debounce } = useDebouncer()
  const { publish } = useJQuery()
  const {
    fetchUpdateExpenseQuantity,
    addItemsToBeDeleted,
    fetchDeleteProjectExpense,
    tableItemsDeleteMode,
    isProjectPresales,
    subcontractingExpenses,
    updateExpense,
  } = useProjectCoordinationContext()

  const {
    setInlineStaffingFormVisible,
  } = useProjectSalesContext()

  const [copyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const [activitiesDeleteMode, setActivitiesDeleteMode] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openModalList, setOpenModalList] = useState(false)

  const ToggleButton = useCallback(({ className, initialValue, onChange }) => {
    const isOn = initialValue
    const btnClassCircle = () => (isOn ? 'circle on' : 'circle off')
    return (
      <div
        className={getClass('toggle-btn', !isOn && 'btn-inactive', className)}
        role="button"
        tabIndex={0}
        aria-label="expense activation button"
        onClick={() => {
          onChange(!isOn)
        }}
        onKeyDown={() => {
          onChange(!isOn)
        }}
      >
        <div className={btnClassCircle()} />
      </div>
    )
  }, [subcontractingExpenses])

  const table = useRef()
  const copyToClipboard = () => {
    if (table.current.childNodes[0]) {
      const nodes = Array.from(table.current.childNodes[0].childNodes)
        .flatMap((child) => Array.from(child.childNodes)).flatMap((child) => Array.from(child.childNodes))
        .map((line) => Array.from(line.childNodes))
      const header = nodes[0].map((cell) => cell.textContent)
      header.splice(1, 0, t('project.coordination.reporting.clipboard.columnNames.description'))
      const footer = nodes[nodes.length - 1].map((cell) => cell.textContent)
      footer.splice(1, 0, '')
      let lines = []
      let activitableInfo = false

      if (nodes.length > 2) {
        if (nodes.map((line) => (line.filter((cell) => (cell.getElementsByClassName('circle')[0] && 1)).length > 0) && 1).filter((el) => el === 1).length > 0) {
          activitableInfo = true
          header.splice(2, 0, t('project.coordination.reporting.clipboard.columnNames.active'))
          footer.splice(2, 0, '')
        }

        lines = nodes.slice(1, nodes.length - 1)
          .map((line) => line.map((cell) => {
            const input = cell.getElementsByTagName('input')[0]
            let textArray = cell.outerText.split('\n\n')

            if (activitableInfo && textArray.length === 3) {
              textArray = [textArray[1], textArray[0], textArray[2] === t('project.costs.projectExpenses.active') ? t('yes') : t('no')]
            } else {
              textArray.reverse()
            }

            return (input && input.value) || textArray.join('\t')
          }).join('\t'))
      }

      navigator.clipboard.writeText([header.join('\t'), ...lines, footer.join('\t')].join('\n'))
    }
    setCopyToClipboardSuccess(true)
    setTimeout(() => {
      setCopyToClipboardSuccess(false)
    }, 5000)
  }

  const MonthColumn = useCallback(({ item, month, disabled }) => (
    <div>
      <Tooltip
        className="disabled-input-tooltip"
        position="left"
        skin="light"
        trigger={
              ({ open, close }) => (
                <div
                  onMouseEnter={() => disabled && open()}
                  onMouseLeave={() => disabled && close()}
                  className="tooltip-trigger-input"
                  aria-label="tooltip trigger input"
                >
                  <InputNumber
                    className="input-align-right"
                    name={`quantity-${month}-${item.name}`}
                    valueInitial={item[month]?.quantity}
                    disabled={disabled || activitiesDeleteMode}
                    localization={{ invalid: { text: '', arialabel: t('project.form.amount_invalid.arialabel') } }}
                    onChange={(value) => value >= 0 && debounce(() => { fetchUpdateExpenseQuantity(value, item[month]?.quantityId, month, item.expenseId) }, 500)
                                                                  && publish('/octopod/ajax/reset_elements')}
                    onCheck={(value) => (value >= 0 ? null : '')}
                  />
                </div>
              )
            }
        content={
              () => (
                !item.expenseIsActive
                  ? (
                    <div className="text-tooltip">
                      <em>{t('project.coordination.time_inputs.tooltip-disabled.0')}</em>
                      <span>Inactif sur la mission</span>
                    </div>
                  )
                  : (
                    <div className="text-tooltip">
                      <em>{t('project.coordination.time_inputs.tooltip-disabled.0')}</em>
                      <span>{t('project.coordination.time_inputs.tooltip-disabled.1')}</span>
                    </div>
                  )
              )
            }
      />
    </div>
  ), [activitiesDeleteMode])

  const createColumns = useMemo(() => {
    const monthsColumns = months.map((month) => (
      {
        name: month,
        title: getYearMonthFromDateString(month),
        onRender: ({ item }) => (
          <MonthColumn
            item={item}
            month={month}
            disabled={!monthsWithValidation[month] || monthsWithValidation[month].is_validated || !item.expenseIsActive}
          />
        ),
        onFooter: () => <p>{getNumberWithUnit(parseFloat(totalAmountsConsumedPerMonths[month] || 0), '€')}</p>,
      }
    ))

    const deleteColumn = {
      name: 'delete_activity',
      title: `${activitiesDeleteMode ? t('project.activities.delete') : ''}`,
      onRender: ({ item }) => (activitiesDeleteMode
        ? (
          <div className="input-radio-destroy">
            <InputRadio
              multiple="false"
              className="form-control radio radio-inline button-delete-activity"
              skin="light"
              name="delete_activity radio"
              disabled={item.quantityByMonthSum > 0}
              icon=""
              options={{
                name: null,
              }}
              onChange={() => {
                if (expenseType === 'subcontracting') {
                  addItemsToBeDeleted({
                    id: item.expenseId,
                    name: item.name,
                    label: item.label,
                    kind: 'subcontracting',
                  })
                } else {
                  addItemsToBeDeleted({
                    id: item.expenseId,
                    label: item.label,
                    name: item.name,
                    kind: 'intragroup',
                  })
                }
              }}
            />
            <img
              aria-label="image delete"
              src={pictoDestroy}
            />
          </div>
        )
        : (<div className="delete-mode-hide" />)
      ),
    }

    const columnsDefault = [
      {
        name: 'name',
        title: t('project.coordination.reporting.consumption_table.name'),
        onRender: ({ item }) => (
          <>
            {formatNameColumn(item['label'], item['name'])}
            {expenseType === 'subcontracting' && !isProjectPresales()
              ? (
                <div className="expense-status">
                  <ToggleButton
                    className="expense-status-toggle"
                    initialValue={item['expenseIsActive']}
                    onChange={(status) => {
                      updateExpense(item, 'active', status)
                    }}
                  />
                  <span className="text-toggle-button">
                    {
                  item['expenseIsActive']
                    ? t('project.coordination.reporting.consumption_table.active')
                    : t('project.coordination.reporting.consumption_table.inactive')
                  }
                  </span>
                </div>
              )
              : null}
          </>
        ),
        onFooter: () => <p>Total</p>,
      },
      {
        name: 'modify',
        title: t('project.coordination.reporting.consumption_table.modify'),
        onRender: ({ item }) => (expenseType === 'subcontracting'
          ? <ProjectStaffingFormHandler turnoverTypeSelect="subcontracting" valueInitial={item} />
          : <ProjectStaffingFormHandler turnoverTypeSelect="intragroup" valueInitial={item} />),
      },
      {
        name: 'Days sold',
        className: 'align-right',
        title: t('project.coordination.reporting.consumption_table.days_sold'),
        onRender: ({ item }) => (
          <InputNumber
            valueInitial={parseFloat(item['quantity'])}
            disabled={activitiesDeleteMode || isProjectPresales()}
            onChange={(value) => {
              debounce(() => updateExpense(item, 'quantity', value), 1000)
            }}
          />
        ),
        onFooter: ({ items }) => (items !== null && items.length
          ? getRoundedToDecimal(items.reduce((sum, item) => sum + parseFloat(item['quantity']), 0), 2)
          : 0),
      },
      {
        name: 'unit price',
        className: 'align-right',
        title: t('project.coordination.reporting.consumption_table.unit_price'),
        onRender: ({ item }) => (getNumberWithUnit(parseFloat(item['unitPrice']), '€')),
        onFooter: ({ items }) => (items !== null && items.length
          ? getNumberWithUnit(getRoundedToDecimal(items.reduce((sum, item) => sum + parseFloat(item['unitPrice']), 0), 2), '€')
          : getNumberWithUnit(getRoundedToDecimal(0, 2), '€')),
      },
      {
        name: 'Selling price',
        className: 'align-right',
        title: t('project.coordination.reporting.consumption_table.selling_price'),
        onRender: ({ item }) => (getNumberWithUnit(parseFloat(item['sellingPrice']), '€')),
        onFooter: ({ items }) => (items !== null && items.length
          ? getNumberWithUnit(getRoundedToDecimal(items.reduce((sum, item) => sum + parseFloat(item['sellingPrice']), 0), 2), '€')
          : getNumberWithUnit(getRoundedToDecimal(0, 2), '€')),
      },
      {
        name: 'consumption',
        title: t('project.coordination.reporting.consumption_table.consumption'),
        onRender: ({ item }) => <ProgressBar progression={parseFloat(item['consumption'])} />,
        onFooter: () => <ProgressBar progression={parseFloat(totalConsumption)} />
        ,
      },

    ]

    if (activitiesDeleteMode) {
      columnsDefault.splice(0, 0, deleteColumn)
    }

    return [
      ...columnsDefault,
      ...monthsColumns,
    ]
  }, [months, monthsWithValidation, totalAmountsConsumedPerMonths, totalConsumption, activitiesDeleteMode, subcontractingExpenses])

  const createRows = useCallback(() => {
    const rows = {}
    expensesConsumption.forEach((expenseConsumption) => {
      const row = {
        name: expenseConsumption.subcontractor_name,
        label: expenseConsumption.label,
        consumption: expenseConsumption.total_consumption,
        expenseId: expenseConsumption.id,
        expenseIsActive: expenseConsumption.is_active,
        unitPrice: expenseConsumption.unit_price,
        markup: expenseConsumption.markup,
        sellingPrice: parseFloat(expenseConsumption.unit_price) + parseFloat(expenseConsumption.markup),
        quantity: expenseConsumption.quantity,
        purchaseOrder: expenseConsumption.purchase_order,
        subcontractorId: expenseConsumption.subcontractor_id,
      }
      let quantityExpentTotal = 0
      months.forEach((month) => {
        quantityExpentTotal += parseFloat(expenseConsumption.consumption_per_month?.[month]?.quantity || 0)
        row[month] = {
          quantityId: expenseConsumption.consumption_per_month?.[month]?.id || null,
          quantity: expenseConsumption.consumption_per_month?.[month]?.quantity || 0,
        }
      })
      row['quantityByMonthSum'] = quantityExpentTotal
      rows[expenseConsumption.id] = row
    })
    return rows
  }, [expensesConsumption, activitiesDeleteMode])

  const copySucceedText = () => (
    <div className="copy-succeed">{t('copySucceed')}</div>
  )

  const handleStaffingForm = () => {
    if (expenseType === 'subcontracting') return <ProjectStaffingFormHandler turnoverTypeSelect="subcontracting" />
    if (expenseType === 'intragroup') return <ProjectStaffingFormHandler turnoverTypeSelect="intragroup" />
    return null
  }

  return (
    <ProjectExpensesTimeSpentTableStyled
      className=" octo expenses-time-spent-tables-section"
      activitiesDeleteMode={activitiesDeleteMode}
    >
      <div className="view">
        <h3 className="title">{title}</h3>
        <Button
          className="copy"
          skin="dark"
          kind="primary"
          name={t('clipboard.arialabel')}
          text={t('clipboard.text')}
          icon={{ url: pictoCopy, position: 'right' }}
          onClick={() => copyToClipboard()}
        />
        {copyToClipboardSuccess && copySucceedText()}
        <div ref={table}>
          <Table
            className="wrapper expenses-comsumption-table"
            columns={createColumns}
            data={createRows()}
          />
        </div>
        { !isProjectPresales() && (
          <div className="control">
            {
              tableItemsDeleteMode
                ? null
                : handleStaffingForm()
            }
            {expensesConsumption.length === 0 || isProjectPresales()
              ? null
              : (
                <ButtonDeleteActivityMode
                  activitiesDeleteMode={activitiesDeleteMode}
                  setActivitiesDeleteMode={setActivitiesDeleteMode}
                  setOpenModal={setOpenModal}
                  setOpenModalList={setOpenModalList}
                  setInlineStaffingFormVisible={setInlineStaffingFormVisible}
                />
              )}
          </div>

        )}
        {openModal ? <ModalInfosDeleteActivityExpense setOpenModal={setOpenModal} /> : null}
        {openModalList ? (
          <ModalListDeleteActivityExpense
            fetchDelete={fetchDeleteProjectExpense}
            setActivitiesDeleteMode={setActivitiesDeleteMode}
            setTableItemsDeleteMode={setActivitiesDeleteMode}
            setInlineStaffingFormVisible={setInlineStaffingFormVisible}
            setOpenModalList={setOpenModalList}
          />
        )
          : null }
      </div>
    </ProjectExpensesTimeSpentTableStyled>
  )
}
