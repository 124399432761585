import { useRef } from 'react'

// KEEP THIS COMMENT this hook is only useful for the project page refactoring.
// This is a very bad practice to hack the global JQuery object in order to use it in React.
// Not only is this anti-pattern, this is also dangerous considering JQuery implicit requirement (and obsolesence but that's another subject).
//
// When the project page will be refactored, it should normally hold a ProjectContext which should implicitely manage all rerendering.
// You should not have any kind of events to refresh the page when something is modified.
// Then only, may this hook (and its folder) be sent back where it belong : hell.
//
// LATH, 2022/07/27 14:58

export const useJQuery = () => {
  const debounce = useRef(null)

  const publish = (event) => {
    global.$.publish(event)
  }

  const subscribe = (event, callback, timer = 1000) => {
    global.$.subscribe(event, () => {
      if (debounce.current !== null) {
        clearTimeout(debounce.current)
      }
      debounce.current = setTimeout(() => callback(), timer)
    })
  }

  return {
    publish,
    subscribe,
  }
}
