import React, { useState, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, InputText, InputTextarea, Button } from '@produits-internes-oss/design-system-components'
import { ConsecutivePaidLeaveStyled } from './ConsecutivePaidLeave.styled'
import { useConsecutivePaidLeaveContext } from '../../context/ConsecutivePaidLeave/ConcecutivePaidLeaveContext'
import pictoCopy from '../../../assets/images/copy.svg'
import Link from '../Link/Link'

export const ConsecutivePaidLeave = ({ peopleWithoutMandatoryPaidLeaves }) => {
  const { t } = useTranslation()
  const { fetchPostSendEmailToSelectedPeople } = useConsecutivePaidLeaveContext()

  const [email, setEmail] = useState({ subject: '', body: '' })
  const [sendMailDisabled, setSendMailDisabled] = useState(false)

  const [copyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const table = useRef()

  const peopleWithoutMandatoryPaidLeavesMap = useMemo(() => Object.fromEntries(peopleWithoutMandatoryPaidLeaves.map((person) => [person['id'], person])),
    [peopleWithoutMandatoryPaidLeaves])
  const columns = [
    {
      name: 'first_name',
      title: t('consecutivePaidLeave.tableHeaders.firstName'),
      onRender: ({ item }) => (
        <Link name={`holidays page of ${item['nickname']}`} href={`/people/${item['nickname']}/holidays`}>
          {item['first_name']}
        </Link>
      ),
      onSort: (a, b) => a['first_name'].localeCompare(b['first_name']),
    },
    {
      name: 'last_name',
      title: t('consecutivePaidLeave.tableHeaders.lastName'),
      onRender: ({ item }) => (
        <Link name={`holidays page of ${item['nickname']}`} href={`/people/${item['nickname']}/holidays`}>
          {item['last_name']}
        </Link>
      ),
      onSort: (a, b) => a['last_name'].localeCompare(b['last_name']),
    },
    {
      name: 'nickname',
      title: t('consecutivePaidLeave.tableHeaders.nickname'),
      onRender: ({ item }) => (
        <Link href={`/people/${item['nickname']}/holidays`}>
          {item['nickname']}
        </Link>
      ),
      onSort: (a, b) => a['nickname'].localeCompare(b['nickname']),
    },
    {
      name: 'email',
      title: t('consecutivePaidLeave.tableHeaders.email'),
      onRender: ({ item }) => item['email'],
      onSort: (a, b) => a['email'].localeCompare(b['email']),
    },
    {
      name: 'sap_id',
      title: t('consecutivePaidLeave.tableHeaders.SAPID'),
      onRender: ({ item }) => item['sap_id'],
      onSort: ((a, b) => a['sap_id'].localeCompare(b['sap_id'])),
    },
    {
      name: 'league',
      title: t('consecutivePaidLeave.tableHeaders.league'),
      onRender: ({ item }) => item['league'],
      onSort: ((a, b) => a['league'].localeCompare(b['league'])),
    },
    {
      name: 'lob',
      title: t('consecutivePaidLeave.tableHeaders.lob'),
      onRender: ({ item }) => item['lob'],
      onSort: ((a, b) => a['lob'].localeCompare(b['lob'])),
    },
    {
      name: 'manager',
      title: t('consecutivePaidLeave.tableHeaders.manager'),
      onRender: ({ item }) => item['manager'],
      onSort: ((a, b) => a['manager'].localeCompare(b['manager'])),
    },
  ]

  const copyToClipboard = () => {
    if (table.current.childNodes[0]) {
      const nodes = Array.from(table.current.childNodes[0].childNodes)
        .flatMap((child) => Array.from(child.childNodes)).flatMap((child) => Array.from(child.childNodes))
        .map((line) => Array.from(line.childNodes))
      const header = nodes[0].map((cell) => cell.textContent)

      let lines = []
      if (nodes.length > 2) {
        lines = nodes.slice(1, nodes.length)
          .map((line) => line.map((cell) => (
            (cell.textContent)
          )).join('\t'))
      }

      navigator.clipboard.writeText([header.join('\t'), ...lines].join('\n'))
    }
    setCopyToClipboardSuccess(true)
    setTimeout(() => {
      setCopyToClipboardSuccess(false)
    }, 5000)
  }

  const copySucceedText = () => (
    <div className="copy-succeed">{t('copySucceed')}</div>
  )

  return (
    <ConsecutivePaidLeaveStyled>
      <div className="header">
        <h1>
          {t('consecutivePaidLeave.pageTitle')}
        </h1>
        <p>
          {t('consecutivePaidLeave.pageDescription')}
        </p>
        <div className="send-email-container">
          <p>
            {t('consecutivePaidLeave.email.description')}
          </p>
          <div className="subject-mail-container">
            <p>
              {t('consecutivePaidLeave.email.mailSubject')}
            </p>
            <InputText
              skin="light"
              className="subject-email"
              name={t('consecutivePaidLeave.email.labelSubject')}
              onChange={(value) => setEmail({ subject: value, body: email.body })}
              onValidate={() => { }}
            />
          </div>
          <div className="body-mail-container">
            <p>
              {t('consecutivePaidLeave.email.mailBody')}
            </p>
            <InputTextarea
              className="body-email"
              skin="light"
              name={t('consecutivePaidLeave.email.labelBody')}
              onChange={(value) => setEmail({ subject: email.subject, body: value })}
            />
          </div>
          <div className="button-container">
            <Button
              className="send-mail"
              name={t('consecutivePaidLeave.email.sendEmail')}
              skin="dark"
              disabled={sendMailDisabled || email.body === '' || email.subject === ''}
              onClick={
                async () => {
                  setSendMailDisabled(true)
                  await fetchPostSendEmailToSelectedPeople(email)
                  window.location.reload()
                }
              }
              text={t('consecutivePaidLeave.email.sendEmail')}
            />
          </div>
        </div>
      </div>
      <div className="copy-table">
        <Button
          className="copy"
          skin="dark"
          kind="primary"
          name={t('clipboard.arialabel')}
          text={t('clipboard.text')}
          icon={{ url: pictoCopy, position: 'right' }}
          onClick={() => copyToClipboard()}
        />
        {copyToClipboardSuccess && copySucceedText()}
      </div>
      <div className="table-container" ref={table}>
        <Table
          className="consecutive-paid-leave-table"
          columns={columns}
          data={peopleWithoutMandatoryPaidLeavesMap}
          sortInitial={{ column: 'last_name', order: 'asc' }}
        />
      </div>
    </ConsecutivePaidLeaveStyled>
  )
}
