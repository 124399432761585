import React from 'react'
import { useExpensesContext } from '../../context/Expenses/ExpensesContext'
import Loader from '../input/_ExportDS/Loader/Loader'
import Tabs from '../input/_ExportDS/Tabs/Tabs'
import ExpensesTabsStyled from './ExpenseTabs.styled'
import ExpensesList from '../ExpensesList/ExpensesList'
import ExpensesForm from '../ExpensesForm/ExpensesForm'
import { getUserDevice } from '../../helpers/deviceHelper'

export default function ExpensesTabs() {
  const { expenseSelected, isLoading } = useExpensesContext()

  if (getUserDevice() === 'web') {
    // fix de merde du double scroll en attendant un code plus propre
    const container = document.getElementById('limited-main-container')
    if (container) {
      container.style.boxSizing = 'border-box'
      container.style.padding = 0
    }
  }

  return (
    <ExpensesTabsStyled>
      {
        isLoading
          ? <Loader className="loading" />
          : (
            <Tabs
              name="expenses"
              tabs={{
                expenseList: <ExpensesList />,
                expenseEditor: <ExpensesForm />,
              }}
              tabSelected={expenseSelected ? 1 : 0}
            />
          )
      }
    </ExpensesTabsStyled>
  )
}
