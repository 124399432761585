import styled from 'styled-components'

export const StyledActivityInput = styled.td`
  padding: 5px 0;
  text-align: center;
  border-right: solid 1px ${({ theme }) => theme.colors.lighterGray};
  &[data-day="15"] {
    border-right: none;
  }
  &[data-day="16"] {
    border-left: solid 1px ${({ theme }) => theme.colors.grey} !important;
  }
  &[data-day="1"] {
    border-left: solid 1px ${({ theme }) => theme.colors.grey};
  }
  &:nth-last-child(2) {
    border-right: solid 1px ${({ theme }) => theme.colors.grey};
  }

  & > div {
    margin: auto;
    width: 100%;
    transform-style: preserve-3d;
    position: relative;
  }

  &.fill {
    span {
      display: block;
      margin: auto;
      width: 2rem;
      background: ${({ theme, error }) => (error ? theme.colors[error] : theme.colors.primaryBlue)};
      border-radius: 50%;
    }

    &.first div {
      span {
        border-radius: 50% 0 0 50%;
      }
      &::after {
        content: '';
        transform: translateZ(-1px);
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        background: ${({ theme, error }) => (error ? theme.colors[error] : theme.colors.primaryBlue)};
        height: 100%;
        width: 51%;
        @-moz-document url-prefix() {
          width: 52%;
        }
      }
    }

    &.between div {
      span {
        border-radius: initial;
      }
      &::after {
        content: '';
        transform: translateZ(-1px);
        position: absolute;
        top: 0;
        left: -2%;
        display: block;
        background: ${({ theme, error }) => (error ? theme.colors[error] : theme.colors.primaryBlue)};
        height: 100%;
        width: 103%;
        @-moz-document url-prefix() {
          width: 103%;
        }
      }
    }

    &.last div {
      span {
        border-radius: 0 50% 50% 0;
      }
      &::after {
        content: '';
        transform: translateZ(-1px);
        position: absolute;
        top: 0;
        left: -2%;
        display: block;
        background: ${({ theme, error }) => (error ? theme.colors[error] : theme.colors.primaryBlue)};
        height: 100%;
        width: 50%;
      }
    }
  }

  &.holiday {
    background-color: ${({ theme, even }) => (even ? theme.colors.paleGrey : theme.colors.paleGrey2)};
    border-right: none;
  }

  &.validated {
    opacity: 0.2;
  }

  &:last-child {
    font-family: ${({ theme }) => theme.fonts.family.bold};
  }

  input {
    border: none;
    width: 95%;
    padding: 0;
    height: 2rem;
    width: 2rem;
    :focus {
      outline-color: ${({ theme }) => theme.colors.secondaryBlue};
    }
  }

  .activity-hour {
    color: ${({ theme }) => theme.colors.white};
    background-color: transparent;
    font-family: Arial;
    font-size: 1.2rem;
    text-align: center;
  }

  .activity-hour:not(input) {
    padding-top: 1px;
  }
`
