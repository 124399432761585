import { parseFloatWithAnySeparatorInObject } from '../../helpers/stringHelper'
import { getRoundedToDecimal } from '../../helpers/numberHelper'
import { forEach, map } from '../../helpers/objectHelper'
import { ProjectTurnoverBreakdownValue } from '../ProjectTurnoverBreakdownValue/ProjectTurnoverBreakdownValue'

export class ProjectTurnoverBreakdown {
  static PROJECT_TYPE_F = 'fixed_price'

  static PROJECT_TYPE_R = 'cost_reimbursable'

  constructor(source) {
    this.id = source.id
    this.closed = source.closed
    this.can_be_finalised = source.can_be_finalised

    this.lobs = source.lobs
    this.lobs.tribes.sort((a, b) => (a.abbreviation > b.abbreviation ? 1 : -1))
    this.lobs.subcontractingExpenses = source.lobs.subcontracting_expenses

    this.project = parseFloatWithAnySeparatorInObject(source.project)

    this.values = parseFloatWithAnySeparatorInObject(source.values, ['validation', 'comment', 'date'])
    this.recalculateMonths()
    this.recalculateTotals()
  }

  recalculateMonths() {
    this.months = Object.keys(this.values).flatMap((year) => Object.keys(this.values[year]).map((month) => [parseInt(year, 10), parseInt(month, 10)]))
  }

  recalculateTotals() {
    this.totals = {
      lobs: Object.fromEntries(this.lobs.tribes.map((tribe) => [tribe.abbreviation, {
        amount: 0,
        changedByUser: false,
        distributedDelta: 0,
        id: null,
        proposal: 0,
        timeInputConsumption: null,
      }])),
      months: {},
      project: {
        tribes: new ProjectTurnoverBreakdownValue(),
        all: new ProjectTurnoverBreakdownValue(),
        progression: 0,
        distributedDelta: 0,
        consumedAmountDelta: 0,
        offset: 0,
      },
      byYear: {
        consumedAmountDelta: {},
        tribesAmounts: {},
        allAmounts: {},
        tribesProposals: {},
        allProposals: {},
      },
    }

    this.totals.months = map(this.values, (year, months) => {
      this.totals.byYear.consumedAmountDelta[year] = 0
      this.totals.byYear.tribesAmounts[year] = 0
      this.totals.byYear.allAmounts[year] = 0
      this.totals.byYear.tribesProposals[year] = 0
      this.totals.byYear.allProposals[year] = 0

      return map(months, (month, monthValues) => {
        const totalForTribes = new ProjectTurnoverBreakdownValue({ proposal: 0, amount: 0, timeInputConsumption: 0 })
        const totalForAll = new ProjectTurnoverBreakdownValue({ proposal: 0, amount: 0, timeInputConsumption: 0 })
        const { lobs } = monthValues

        forEach(lobs, (lob) => {
          const value = this.getValue(year, month, lob)
          const valueAmountOrDefaultToProposal = value.getAmountOrDefaultToProposal()

          if (!(lob in this.totals.lobs)) {
            this.totals.lobs[lob] = new ProjectTurnoverBreakdownValue()
          }

          this.totals.lobs[lob].proposal += value.proposal
          this.totals.lobs[lob].amount += valueAmountOrDefaultToProposal
          this.totals.lobs[lob].distributedDelta += valueAmountOrDefaultToProposal - value.proposal

          if (lob !== this.lobs.subcontracting.abbreviation && lob !== this.lobs.intragroup.abbreviation && lob !== this.lobs.subcontractingExpenses.abbreviation) {
            totalForTribes.proposal += value.proposal
            totalForTribes.amount += valueAmountOrDefaultToProposal
            totalForTribes.timeInputConsumption += value.timeInputConsumption
            totalForAll.timeInputConsumption += value.timeInputConsumption
          }

          totalForAll.proposal += value.proposal

          if (monthValues.amount) {
            totalForAll.amount = monthValues.amount
          } else {
            totalForAll.amount += valueAmountOrDefaultToProposal
          }
        })

        this.totals.project.tribes.proposal += totalForTribes.proposal
        this.totals.project.tribes.amount += totalForTribes.amount
        this.totals.project.tribes.distributedDelta += totalForTribes.amount - totalForTribes.proposal

        this.totals.project.all.proposal += totalForAll.proposal
        this.totals.project.all.amount += totalForAll.amount
        this.totals.project.all.distributedDelta += totalForAll.amount - totalForAll.proposal

        this.totals.byYear.tribesAmounts[year] += totalForTribes.amount
        this.totals.byYear.tribesProposals[year] += totalForTribes.proposal

        this.totals.byYear.allAmounts[year] += totalForAll.amount

        this.totals.byYear.allProposals[year] += totalForAll.proposal

        let distributedDelta = getRoundedToDecimal(totalForAll.amount - totalForAll.proposal, 2)
        distributedDelta = Math.abs(distributedDelta) >= 0.1 ? distributedDelta : 0

        let consumedAmountDelta = getRoundedToDecimal(totalForAll.amount - monthValues.consumption, 2)
        consumedAmountDelta = Math.abs(consumedAmountDelta) >= 0.1 ? consumedAmountDelta : 0

        const progression = getRoundedToDecimal(this.totals.project.all.amount / this.project.amount, 4)

        this.totals.byYear.consumedAmountDelta[year] += consumedAmountDelta

        this.totals.project.progression = progression
        this.totals.project.distributedDelta += distributedDelta
        this.totals.project.consumedAmountDelta += consumedAmountDelta

        return {
          tribes: totalForTribes,
          all: totalForAll,
          progression,
          distributedDelta,
          consumedAmountDelta,
        }
      })
    })

    this.totals.project.offset = this.totals.project.all.amount - this.project.amount
    this.totals.project.offset = Math.abs(this.totals.project.offset) >= 0.1 ? this.totals.project.offset : 0
  }

  getValue(year, month, lob) {
    return new ProjectTurnoverBreakdownValue(this.values[year]?.[month]?.lobs?.[lob])
  }
}
