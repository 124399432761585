import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SynchronizationHeader from './SynchronizationHeader/SynchronizationHeader'
import SynchronizationCard from './SynchronizationCard/SynchonizationCard'
import spaceShip from '../../../assets/images/Spaceship.svg'
import Files from '../../../assets/images/Files.svg'
import FileCase from '../../../assets/images/fileCase.svg'
import rocket2 from '../../../assets/images/rocket2.svg'
import { getClass } from '../../helpers/stringHelper'
import { SynchronizationStyled } from './Synchronization.styled'
import { useSynchronizationContext } from '../../context/Synchronization/SynchronizationContext'
import { SynchronizationProgression } from './SynchronizationProgression/SynchronizationProgression'
import comptuter from '../../../assets/images/computer.svg'
import download from '../../../assets/images/iconDownload.svg'
import navigationLienExterne from '../../../assets/images/navigationExternalLinkBlue.svg'
import { SynchronizationErrors } from './SynchronizationErrors/SynchronizationErrors'

export default function Synchronization() {
  const { t } = useTranslation()
  const [disableSynchronizationButton, setDisableSynchronizationButton] = useState(false)
  const [disableExpensesExportButton, setDisableExpensesExportButton] = useState(false)
  const [disableMailersButton, setDisableMailersButton] = useState(false)

  const {
    synchronizationDateLaunch,
    synchronizationUpdater,
    jobLaunched,
    fetchGetSynchronizationData,
    fetchLaunchSynchro,
    fetchLaunchExpensesExport,
    fetchGetLaunchMailers,
    expensesExportDateLaunch,
    expensesExportUpdater,
    expensesExportJobLaunch,
    mailersDateLaunch,
    mailersUpdater,
    mailersJobLaunch,
  } = useSynchronizationContext()

  useEffect(() => {
    fetchGetSynchronizationData()
  }, [])

  const handleSynchronizationLaunch = async () => {
    setDisableSynchronizationButton(true)
    await fetchLaunchSynchro()
  }

  const handleExpensesExportLaunch = () => {
    setDisableExpensesExportButton(true)
    fetchLaunchExpensesExport()
  }

  const handleMailersExportLaunch = () => {
    setDisableMailersButton(true)
    fetchGetLaunchMailers()
  }

  return (
    <SynchronizationStyled>
      <div className="controls">
        <SynchronizationHeader className="header" />
        <SynchronizationProgression className="progression" />
        <div className="cards">
          <div className="cards-actions">
            <SynchronizationCard
              isButton
              action={t('synchronization.expensesExport.dateLaunch')}
              picto={Files}
              textCard={t('synchronization.expensesExport.export')}
              executionDate={expensesExportJobLaunch && `${`${expensesExportUpdater.first_name} ${expensesExportUpdater.last_name}`}`}
              updaterInfo={expensesExportJobLaunch && `${`${expensesExportDateLaunch.day}/${expensesExportDateLaunch.month}`}`}
              buttonText={t('synchronization.expensesExport.receiveByMail')}
              disableButton={getClass((expensesExportJobLaunch || disableExpensesExportButton) && 'disabled')}
              handleClick={() => handleExpensesExportLaunch()}
              buttonClassName="button-synchro"
              pictoButton={download}
              isButtonGray
            />
            <SynchronizationCard
              isButton
              action={t('synchronization.launch.dateLaunch')}
              picto={spaceShip}
              textCard={t('synchronization.launch.textSend')}
              executionDate={jobLaunched && `${`${synchronizationUpdater.first_name} ${synchronizationUpdater.last_name}`}`}
              updaterInfo={jobLaunched && `${`${synchronizationDateLaunch.day}/${synchronizationDateLaunch.month}`}`}
              buttonText={t('synchronization.launch.textSynchro')}
              disableButton={getClass((jobLaunched || disableSynchronizationButton) && 'disabled')}
              handleClick={() => handleSynchronizationLaunch()}
              buttonClassName="button-expenses"
              isButtonGray={false}
            />
          </div>
          <div className="cards-actions">
            <SynchronizationCard
              isButton
              action={t('synchronization.mailers.dateLaunch')}
              picto={FileCase}
              textCard={t('synchronization.mailers.export')}
              executionDate={(mailersJobLaunch || mailersUpdater?.first_name) && `${`${mailersUpdater.first_name} ${mailersUpdater.last_name}`}`}
              updaterInfo={(mailersJobLaunch || mailersDateLaunch?.day) && `${`${mailersDateLaunch.day}/${mailersDateLaunch.month}`}`}
              buttonText={t('synchronization.mailers.receiveByMail')}
              disableButton={getClass((disableMailersButton || !synchronizationDateLaunch.day) && 'disabled')}
              handleClick={() => handleMailersExportLaunch()}
              buttonClassName="button-synchro"
              pictoButton={download}
              isButtonGray
            />
          </div>
          <SynchronizationCard
            isButton={false}
            className="synchronization-card row"
            picto={comptuter}
            textCard={t('synchronization.netMyt&e.textNet')}
            buttonText={t('synchronization.netMyt&e.textButton')}
            pictoButton={navigationLienExterne}
          />
        </div>
      </div>
      {
        jobLaunched
          ? <SynchronizationErrors className="errors" />
          : <img className="rocket" src={rocket2} alt="rocket launch" />
      }
    </SynchronizationStyled>
  )
}
