import styled from 'styled-components'

export const StyledActivityRatePopin = styled.div`
	position: absolute;
	bottom: 100%;
	left: 0;
	z-index: 1001;
	width: 100%;
	color: ${({ theme }) => theme.colors.primaryDark};
	text-align: initial;
	box-sizing: border-box;
	border-radius: 4px 4px 0 0;
	padding: 2rem;
	background-color: ${({ theme }) => theme.colors.white};
	box-shadow: 0 4px 4px 0 ${({ theme }) => theme.colors.shadow};
	cursor: auto;

	h3 {
		margin-bottom: 3rem;
	}

	.rate-bar {
		width: 100%; 
		margin-bottom: 2rem;
		font-size: small; 
	}

	p {
		max-width: 55%;
		margin-bottom: 0.5rem;
	}
`
