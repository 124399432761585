import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledMealForm } from './MealForm.styled'

import { request, requestWithCSRFToken } from '../../../../../javascripts/HttpService'

import RadioButtons from '../../../../UI/Forms/RadioButtons/RadioButtons'

const MealForm = ({ periodNumber, timesheetId }) => {
  const { t } = useTranslation()
  const [mealRefundChoice, setMealRefundChoice] = useState('')
  const mealRefundOptions = [
    // {
    //   option: 'meal_expenses',
    //   label: 'periodValidationModal.mealExpenses.legends.mealRefund'
    // },
    {
      option: 'tickets',
      label: 'periodValidationModal.mealExpenses.legends.tickets',
    },
    {
      option: 'refuse_tickets',
      label: 'periodValidationModal.mealExpenses.legends.refuseTickets',
    },
  ]

  const getMealRefundChoice = () => {
    const url = `/meal_refunds/show?timesheet_id=${timesheetId}`
    request(url).then((responseData) => setMealRefundChoice(responseData.choice))
  }

  const updateMealRefundChoice = (value) => {
    const url = '/meal_refunds/choose'
    const body = {
      timesheet_id: timesheetId,
      meal_refunds: {
        choice: value,
      },
    }

    requestWithCSRFToken(url, { method: 'POST', body: JSON.stringify(body) })
      .then(() => setMealRefundChoice(value))
  }

  useEffect(() => {
    getMealRefundChoice()
  }, [])

  return (
    periodNumber === 2 && (
      <StyledMealForm>
        <h3>{t('periodValidationModal.mealExpenses.title')}</h3>
        <h6>{t('periodValidationModal.mealExpenses.question')}</h6>
        <RadioButtons
          topic="meal"
          defaultValue={mealRefundChoice}
          options={mealRefundOptions}
          click={updateMealRefundChoice}
        />
      </StyledMealForm>
    )
  )
}

export default MealForm
