import styled from 'styled-components'

export const ProjectServiceAdjustmentPenaltyStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.1rem;
  align-items: flex-end;
  justify-content: space-between;
  width: 60rem;

  .content-left {
    display: flex;
    gap: 2rem;

    .disabled-input {
      color: ${({ theme }) => theme.colors.betterway.primary[70]};
    }
  }

  .value.eds-input {
    .title {
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      font-weight: 300;
      font-size: 1.6rem;
    }

    input {
      width: 28.5rem;
      font-size: 1.6rem;
      font-family: 'Outfit', sans-serif;

      &:disabled {
        border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
        background-color: ${({ theme }) => theme.colors.betterway.primary[20]};
        color: ${({ theme }) => theme.colors.betterway.primary[70]};
      }

      &:not(:disabled) {
        border-color: ${({ theme }) => theme.colors.betterway.primary[100]};

        &:hover {
          border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
        }
      }
    }
  }

  .input-group {
    display: flex;
    align-items: center;
    margin-right: -1rem;

    i {
      position: relative;
      top: 1rem;
      right: 10%;
    }
  }

  .create.eds-button {
    button {
      border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
      background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
      color: ${({ theme }) => theme.colors.betterway.white};
      font-weight: 600;
      font-size: 1.6rem;
      cursor: pointer;

      &:hover {
        border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
        background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
      }

      &:focus,
      &:focus-visible {
        color: ${({ theme }) => theme.colors.betterway.white};
      }

      &:disabled {
        border-color: ${({ theme }) => theme.colors.betterway.primary[50]};
        background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
        cursor: not-allowed;
      }
    }
  }

  .edit.eds-button {
    button {
      border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
      background-color: ${({ theme }) => theme.colors.betterway.secondary[20]};
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      font-weight: 600;
      font-size: 1.6rem;
      cursor: pointer;

      .picto {
        margin-left: 0.8rem;
      }
    }
  }
`
