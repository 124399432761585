import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectErrors } from '../ProjectErrors/ProjectErrors'
import pictoInfo from '../../../assets/images/exclamationInfo.svg'

export const SalesTabBlockedError = () => {
  const { t } = useTranslation()
  const { isProjectPresales } = useProjectSalesContext()

  return (
    !isProjectPresales() && (
      <ProjectErrors errorType="warning" picto={pictoInfo} errorsColumn={false}>
        <p>
          {t('project.errors.status.missionAccepted.0')}
          <span>{t('project.errors.status.missionAccepted.1')}</span>
          {t('project.errors.status.missionAccepted.2')}
        </p>
      </ProjectErrors>
    )
  )
}
