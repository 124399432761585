import styled from 'styled-components'
import { Section } from '@produits-internes-oss/design-system-components'

export const ProjectContractReferenceStyled = styled(Section)`
    .modify {
      display: flex;
      justify-content: end;
      height: 0;

      .btn-modify {
        position: relative;
        top: -6.3rem;
        right: -1.9rem;
        display: flex;
        align-items: flex-end;

        button {
          display: flex !important;
          width: auto !important;
          height: auto !important;
          padding: 0;
          border: none !important;
          background-color: transparent !important;
          color: ${({ theme }) => theme.colors.primary} !important;
          font-weight: normal !important;
          font-size: 1.4rem !important;
          text-decoration: underline !important;

          &:hover {
            border: none !important;
            background-color: transparent !important;
          }
        }
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      width: 100%;
      min-height: 6.4rem;
      padding: 1rem 0;

      .reference,
      .comment {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        align-items: center;
        justify-content: center;
        justify-content: start;
        width: 50%;
        margin-left: 2.4rem;
        font-weight: 600;
        overflow-wrap: break-word;

        .text {
          width: 100%;
          text-align: center;
        }
      }

      .reference {
        padding-right: 2.4rem;
        border-right: 1px solid ${({ theme }) => theme.colors.lighterGray};
      }
    }

    .content-modify {
      display: flex;
      flex-direction: column;
      width: 100%;

      .form {
        display: flex;
        gap: 4rem;
        margin-bottom: 2.4rem;

        .form-ref {
          width: 30rem;
        }

        .form-comment {
          width: 61.6rem;
        }
      }

      .command {
        display: flex;
        gap: 1rem;
        justify-content: end;
        width: 100%;

        .cancel {
          button {
            border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
            background-color: ${({ theme }) => theme.colors.betterway.white};
            color: ${({ theme }) => theme.colors.betterway.primary[100]};

            &:hover {
              border-color: ${({ theme }) => theme.colors.betterway.white};
              background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
              color: ${({ theme }) => theme.colors.betterway.white};
            }
          }
        }
      }

      .text-area {
        display: flex;
        flex-direction: column;

        span {
          font-weight: normal;
        }
      }
    }
`
