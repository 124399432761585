import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@produits-internes-oss/design-system-components'

import { ButtonDeleteActivityModeStyled } from './ButtonDeleteActivityMode.styled'
import { useProjectCoordinationContext } from '../../context/ProjectCoordination/ProjectCoordinationContext'

export const ButtonDeleteActivityMode = ({
  activitiesDeleteMode,
  setActivitiesDeleteMode,
  setOpenModal,
  setOpenModalList,
  setInlineStaffingFormVisible,
}) => {
  const { t } = useTranslation()
  const {
    setItemsToDelete,
    tableItemsDeleteMode,
    setTableItemsDeleteMode,
    isProjectPresales,
    itemsToDelete,
  } = useProjectCoordinationContext()
  return (
    <ButtonDeleteActivityModeStyled>
      <Button
        className="delete-activity"
        skin={activitiesDeleteMode ? 'dark' : 'light'}
        kind={activitiesDeleteMode ? 'primary' : 'secondary'}
        name={t('project.activities.delete_activities')}
        disabled={(tableItemsDeleteMode && !activitiesDeleteMode) || isProjectPresales() || (tableItemsDeleteMode && itemsToDelete.length <= 0)}
        text={activitiesDeleteMode ? t('project.activities.delete_activities_selected') : t('project.activities.delete_activities')}
        onClick={() => {
          if (activitiesDeleteMode) {
            setOpenModalList(true)
            setInlineStaffingFormVisible(false)
          } else {
            setOpenModal(true)
            setActivitiesDeleteMode(true)
            setTableItemsDeleteMode(true)
          }
        }}
      />
      {
          activitiesDeleteMode
            ? (
              <Button
                className="cancel"
                skin="light"
                kind="secondary"
                name={t('project.form.cancel.arialabel')}
                text={t('project.form.cancel.text')}
                onClick={() => {
                  setActivitiesDeleteMode(false)
                  setTableItemsDeleteMode(false)
                  setItemsToDelete([])
                  setInlineStaffingFormVisible(false)
                }}
              />
            )
            : null
        }
    </ButtonDeleteActivityModeStyled>

  )
}
