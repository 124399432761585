import React from 'react'
import { useTranslation } from 'react-i18next'

const SkillMatrix = () => {
  const { t } = useTranslation()
  const url = `https://askbob.octo.tools/skills/matrix`

  return (
    <div>
      <h4 className="profile-section-message">
        {t('skillMatrix.message')}
      </h4>
      <a className="skill-matrix-link" href={url} target="_blank" rel="noopener noreferrer">
        {t('skillMatrix.update')}
      </a>
    </div>
  )
}

export default SkillMatrix
