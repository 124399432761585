import React from 'react'
import { useTranslation } from 'react-i18next'
import pictoNavigateCross from '../../../../assets/images/iconsNavigateCross.svg'
import { ModalInfosDeleteActivityExpenseStyled } from './ModalInfosDeleteActivityExpense.styled'

export const ModalInfosDeleteActivityExpense = ({ setOpenModal }) => {
  const { t } = useTranslation()

  return (
    <ModalInfosDeleteActivityExpenseStyled>
      <div className="container-modal">
        <div className="header-modal">
          <div className="title">{t('project.coordination.destroy.input')}</div>
          <div
            className="picto-close"
            role="button"
            onClick={() => {
              setOpenModal(false)
            }}
            onKeyDown={() => {
              setOpenModal(false)
            }}
            tabIndex={0}
          >
            <img
              aria-label="link description mission"
              src={pictoNavigateCross}
            />
          </div>
        </div>
        <div className="content-modal">
          <div className="text">{t('project.coordination.destroy.infos_input')}</div>
        </div>
      </div>
    </ModalInfosDeleteActivityExpenseStyled>
  )
}
