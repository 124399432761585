import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Tag, Icon } from '@produits-internes-oss/design-system-components'
import { useProjectDataHatContext } from '../../context/ProjectDataHatContext/ProjectDataHatContext'
import { ProjectDataHatStyled } from './ProjectDataHat.styled'
import { parseFloatWithAnySeparator, getNumberWithUnit } from '../../helpers/stringHelper'
import { ProjectDataHatModal } from '../ProjectDataHatModal/ProjectDataHatModal'
import { ProjectDataHatInfo } from '../ProjectDataHatInfo/ProjectDataHatInfo'

export const ProjectDataHat = ({ personIsAdmin }) => {
  const { t } = useTranslation()
  const { project, fetchUpdateMissionDescription, fetchUpdateMissionCommentary } = useProjectDataHatContext()

  const projectAmountTooltipInfo = () => {
    let info = null

    if (project.status.current === 'lead' && ['cost_reimbursable', 'fixed_price'].includes(project.kind)) {
      info = (
        <p>
          {t('project.infos.sold_amount.lead.0')}
          <b>{` ${t('project.infos.sold_amount.lead.1')} `}</b>
          {t('project.infos.sold_amount.lead.2')}
        </p>
      )
    } else if (project.kind === 'cost_reimbursable') {
      if (['proposal_in_progress', 'proposal_sent'].includes(project.status.current)) {
        info = t('project.infos.sold_amount.cost_reimbursable.in_proposal')
      }
      if (['mission_accepted', 'mission_signed', 'mission_done'].includes(project.status.current)) {
        info = t('project.infos.sold_amount.cost_reimbursable.signed')
      }
    } else if (project.kind === 'fixed_price') {
      if (['proposal_in_progress', 'proposal_sent'].includes(project.status.current)) {
        info = t('project.infos.sold_amount.fixed_price.in_proposal')
      }
      if (['mission_accepted', 'mission_signed', 'mission_done'].includes(project.status.current)) {
        info = t('project.infos.sold_amount.fixed_price.signed')
      }
    }
    return info
  }

  return !project ? null : (
    <ProjectDataHatStyled>
      <div className="header">
        <div className="controls">
          <a className="return" href="/projects/billable">
            <Icon name="return" />
            <span>{t(`project.backToTheList`)}</span>
          </a>
          <Button
            className="duplicate"
            skin="dark"
            kind="secondary"
            name={t('project.duplicate')}
            text={t('project.duplicate')}
            onClick={() => { window.location.href = `/projects/billable/${project.id}/duplicate?locale=fr` }}
          />
          <Button
            className="edit"
            skin="light"
            kind="primary"
            name={t('project.modify_data')}
            text={t('project.modify_data')}
            onClick={() => { window.location.href = `/projects/billable/${project.id}/edit?locale=fr` }}
          />
        </div>
        <h1 className="name">
          <b>{project.reference}</b>
          <span>{` - ${project.customer.name} - ${project.name}`}</span>
        </h1>
      </div>
      <div className="details">
        <div className="details-left">
          <div className="details-summary">
            {
              project.mission_maker && (
                <Tag className="tag" color="yellow" text={t(`project.mission_makers.${project.mission_maker}`)} />
              )
            }
            {
              project.nature && (
                <Tag className="tag" color="cyan" text={t(`project.natures.${project.nature}`)} />
              )
            }
            <div className="dates">
              {
                (project.start_date || project.end_date) && <Icon className="arrow" name="arrow" />
              }
              {
                project.start_date && (
                <ProjectDataHatInfo
                  title={`${t(`project.infos.start`)} : `}
                  value={new Date(project.start_date).toLocaleDateString('fr-FR')}
                />
                )
              }
              {
                project.end_date && (
                <ProjectDataHatInfo
                  title={`${t(`project.infos.end`)} : `}
                  value={new Date(project.end_date).toLocaleDateString('fr-FR')}
                />
                )
              }
            </div>
          </div>
          <hr />
          <div className="details-infos">
            <ProjectDataHatInfo
              title={`${t('project.infos.dimi')} : `}
              value={project.mission_director ? `${project.mission_director.first_name} ${project.mission_director.last_name} ` : ' - '}
            />
            <ProjectDataHatInfo
              title={`${t(`project.infos.locations`)} : `}
              value={project.locations || ' - '}
            />
            <ProjectDataHatInfo
              title={`${t('project.infos.wbs')} : `}
              value={project.wbs ? project.wbs : ' - '}
            />
            <ProjectDataHatInfo
              title={`${t('project.infos.bizdev')} : `}
              value={project.business_contact ? `${project.business_contact.first_name} ${project.business_contact.last_name}` : ' - '}
            />

            <ProjectDataHatInfo
              className="expense"
              title={`${t('project.infos.invoiceable_fees')} : `}
              value={project.invoiceable_fees ? t('yes') : t('no')}
            />

            <ProjectDataHatInfo
              title={`${t('project.infos.wbs_bd')} : `}
              value={project.wbs_bd ? project.wbs_bd : ' - '}
            />
            <ProjectDataHatInfo
              title={`${t(`project.infos.sector`)} : `}
              value={project.customer.sector.name || ' - '}
            />
            <ProjectDataHatInfo
              title={`${t(`project.infos.approval_date`)} : `}
              value={project.approval_date ? new Date(project.approval_date).toLocaleDateString('fr-FR') : ' - '}
            />

            <ProjectDataHatInfo
              title={`${t('project.infos.mms_id')} : `}
              value={project.mms_id ? project.mms_id : ' - '}
            />
          </div>
          <hr />
          <div className="details-links">
            <ProjectDataHatModal
              title={t('project.modal-data-infos.title.mission-description')}
              localization={{
                cancel: t('popup.cancel'),
                edit: t('project.modal-data-infos.title.modify-mission-description'),
                trigger: t(`project.description_mission`),
                validate: t('popup.validate'),
              }}
              value={project.mission_description}
              onChange={(value) => fetchUpdateMissionDescription(value)}
            />
            <ProjectDataHatModal
              title={t('project.modal-data-infos.title.mission-comment')}
              localization={{
                cancel: t('popup.cancel'),
                edit: t('project.modal-data-infos.title.modify-mission-comment'),
                trigger: t(`project.comment_mission`),
                validate: t('popup.validate'),
              }}
              value={project.comment}
              onChange={(value) => fetchUpdateMissionCommentary(value)}
            />
            {
              project.projects_affiliation_id && (
              <a href={`/projects_affiliations/${project.projects_affiliation_id}`} target="_blank" rel="noreferrer">
                <span>{t(`project.affiliated_mission`)}</span>
                <Icon name="share" />
              </a>
              )
            }
            <a href={project.proposal_link} target="_blank" rel="noreferrer">
              <span>{t(`project.drive_mission`)}</span>
              <Icon name="share" />
            </a>
          </div>
        </div>
        <div className="details-right">
          <div className="finance">
            {
              !project.is_acn_prime && (
              <ProjectDataHatInfo
                title={`${t(`project.infos.cci_sold`)} : `}
                value={project.cci_sold ? `${parseFloat(project.cci_sold).toFixed(2)} %` : '- %'}
                large
                tooltipInfos={project.cci_sold ? () => t('project.infos.cci_sold_tooltip') : false}
              />
              )
            }
            {
              !project.is_acn_prime && personIsAdmin && (
              <ProjectDataHatInfo
                title={`${t(`project.infos.cci_in_progress`)} : `}
                value={project.contract_ci_percent ? `${parseFloat(project.contract_ci_percent).toFixed(2)} %` : '- %'}
                large
                tooltipInfos={project.contract_ci_percent ? () => t('project.infos.cci_in_progress_tooltip') : false}
              />
              )
            }
            {
              !project.is_acn_prime && !personIsAdmin && (
              <ProjectDataHatInfo
                title={`${t(`project.infos.cutted_cci`)} : `}
                value={project.contract_ci_percent_last_cutting ? `${parseFloat(project.contract_ci_percent_last_cutting).toFixed(2)} %` : '- %'}
                large
                tooltipInfos={project.contract_ci_percent_last_cutting ? () => t('project.infos.cci_cutted_tooltip') : false}
              />
              )
            }
            <ProjectDataHatInfo
              title={`${t(`project.infos.amount_mission`)} : `}
              value={project.amount ? ` ${getNumberWithUnit(parseFloatWithAnySeparator(project.amount), '€')}` : '- €'}
              large
              tooltipInfos={() => projectAmountTooltipInfo()}
            />
          </div>
          <div className="other" />
        </div>
      </div>
    </ProjectDataHatStyled>
  )
}
