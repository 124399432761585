import styled from 'styled-components'

export const StyledRadioButtons = styled.div`
  display: flex;

  .form-radio {
    display: flex;
    
    &:not(:last-of-type) {
      margin-right: 1rem;
    }

    input {
      display: none;
    }

    label {
      cursor: pointer;
      font-family: ${({ theme }) => theme.fonts.family.bold};
      display: flex;
      margin-bottom: 0;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      border: 1pt solid ${({ theme }) => theme.colors.skeletonGreyDark};
      height: 2.4rem;
      width: auto;
      padding: 0 2rem;
    }

    input:checked ~ label {
      border: 1pt solid ${({ theme }) => theme.colors.primaryBlue};
      background: ${({ theme }) => theme.colors.primaryBlue};
      color: ${({ theme }) => theme.colors.white};
    }

    input:not(:checked) ~ label {
      &:hover {
        background: ${({ theme }) => theme.colors.lightGrey};
      }
    }
  }
`
