import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { requestWithCSRFToken } from '../../../javascripts/HttpService'
import { useSubcontracting } from '../../../context/Subcontracting/SubcontractingContext'
import StyledSubcontractorsTableRow from './SubcontractorsTableRow.styled'

const SubcontractorsTableRow = ({ subcontractorInput }) => {
  const name = `subcontractor ${subcontractorInput.subcontractor_index}`
  const { t } = useTranslation()
  const [hoverButton, setHoverButton] = useState(false)
  const [formSent, setFormSent] = useState(false)

  const debouncers = useRef({})

  useEffect(() => () => {
    Object.values(debouncers.current).forEach((debouncer) => {
      clearTimeout(debouncer)
    })
  }, [])

  const {
    updateEntryBill,
  } = useSubcontracting()

  const debounceUpdateSubcontractorBill = (key, value, debounce = 0) => {
    if (key in debouncers.current) {
      clearTimeout(debouncers.current[key])
    }

    updateEntryBill(subcontractorInput.subcontractor_index, key, value)

    const fetchPromise = async () => {
      await requestWithCSRFToken('/subcontractor_bills/update_or_create', {
        method: 'POST',
        body: JSON.stringify({
          project_expense_id: subcontractorInput.project_expense_id,
          month: subcontractorInput.project_expense_input.month,
          project_expense_quantity_id: subcontractorInput.project_expense_input.id,
          [key]: value,
        }),
      })
    }

    if (debounce > 0) {
      debouncers.current[key] = setTimeout(async () => fetchPromise(), debounce)
    } else {
      fetchPromise()
    }

    return fetchPromise
  }

  const createAndSendFormLinkToSubcontractor = async () => {
    const date = new Date(subcontractorInput.project_expense_input.month)

    await requestWithCSRFToken('/project_expenses/create_and_send_form_link_to_subcontractors', {
      method: 'POST',
      body: JSON.stringify({
        date: new Date(date.getTime() - date.getTimezoneOffset() * 60000),
        subcontractors_ids: [subcontractorInput.project_expense_input.subcontractor_id],
      }),
    })

    setFormSent(true)
  }

  const formInput = () => {
    if (subcontractorInput.subcontractor_form_input.form_link) {
      return (
        <div className="action-button">
          <button
            aria-label={`${name} copy link button`}
            type="button"
            title={t('subcontractorsOverview.subcontractorsTableRow.form_copy_link')}
            onClick={() => {
              navigator.clipboard.writeText(subcontractorInput.subcontractor_form_input.form_link)
            }}
          >
            <i className="fa fa-file-text-o" />

          </button>
        </div>
      )
    }

    if (formSent) {
      return (
        <div aria-label={`${name} email sent`} className="action-button">
          <i className="fa fa-check-square" />
        </div>
      )
    }

    return (
      <div className="action-button">
        <button
          type="button"
          aria-label={`${name} send email button`}
          title={t('subcontractorsOverview.subcontractorsTableRow.form_copy_link')}
          onClick={createAndSendFormLinkToSubcontractor}
        >
          <i className="fa fa-envelope" />
        </button>
      </div>
    )
  }

  const projectUrl = () => `/projects/billable/${subcontractorInput.project_expense_input.project_id}`
  const subcontractorBill = subcontractorInput.subcontractor_bill
  const comment = subcontractorBill.comment || ''
  const isToReview = subcontractorBill.to_review || subcontractorBill.to_review === null
  return (
    <StyledSubcontractorsTableRow>
      <td>{subcontractorInput.project_expense_input.month}</td>
      <td>{subcontractorInput.project_expense_input.name}</td>
      <td>{subcontractorInput.project_expense_input.purchase_order}</td>
      <td>
        {subcontractorInput.project_expense_input.price}
        {' '}
        {subcontractorInput.project_expense_input.currency}
      </td>
      <td>
        {formInput()}
      </td>
      <td><a href={projectUrl()} target="_blank" rel="noreferrer">{subcontractorInput.project_expense_input.project}</a></td>
      <td>{subcontractorInput.subcontractor_form_input.quantity_spent}</td>
      <td>{subcontractorInput.subcontractor_form_input.misc_expense}</td>
      <td>{t(`happiness_indicator.${subcontractorInput.subcontractor_form_input.happiness_indicator}`)}</td>
      <td>{subcontractorInput.cutting_input.quantity_spent}</td>
      <td>{subcontractorInput.subcontractor_form_input.quantity_spent ? subcontractorInput.cutting_input.misc_expense : ''}</td>
      <td>
        <input
          type="number"
          lang="en-US"
          aria-label={`${name} bill expense quantity input`}
          value={subcontractorBill.expense_quantity || ''}
          onChange={(event) => debounceUpdateSubcontractorBill('expense_quantity', event.target.value, 1500)}
        />
      </td>
      <td>
        <div className="currency-input">
          <input
            type="number"
            lang="en-US"
            aria-label={`${name} bill miscellaneous expense quantity input`}
            value={subcontractorBill.misc_expense_quantity || ''}
            onChange={(event) => debounceUpdateSubcontractorBill('misc_expense_quantity', event.target.value, 1500)}
          />
          <div className="currency">
            <span>€</span>
          </div>
        </div>
      </td>
      <td>
        <div className="currency-input">
          <input
            type="number"
            lang="en-US"
            aria-label={`${name} bill unit price input`}
            value={subcontractorBill.unit_price || ''}
            onChange={(event) => debounceUpdateSubcontractorBill('unit_price', event.target.value, 1500)}
          />
          <div className="currency">
            <span>€</span>
          </div>
        </div>
      </td>
      <td>
        <div className="actions-buttons">
          <div
            className="relative"
            aria-label={`${name} bill comment toggle`}
            onMouseEnter={() => setHoverButton(true)}
            onMouseLeave={() => setHoverButton(false)}
          >
            {
                  (hoverButton)
                  && (
                    <textarea
                      aria-label={`${name} bill comment text area`}
                      rows="4"
                      placeholder={t('writeCommentLine')}
                      value={comment}
                      onChange={(e) => debounceUpdateSubcontractorBill('comment', e.target.value, 1000)}
                    />
                  )
                }
            <div className="comment">
              <button
                type="button"
                className={['compact', comment.length > 0 ? 'comment' : ''].join(' ')}
              >
                <i className="fa fa-info" />
              </button>
            </div>
          </div>
          <div className="status">
            <button
              className={['review', isToReview ? 'to-review' : ''].join(' ')}
              type="button"
              aria-label={`${name} bill to review toggle`}
              onClick={() => { debounceUpdateSubcontractorBill('to_review', !isToReview) }}
              onKeyPress={() => {}}
            >
              {
                    isToReview
                      ? t('subcontractorsOverview.subcontractorsTableRow.to_review')
                      : t('subcontractorsOverview.subcontractorsTableRow.reviewed')
                  }
            </button>
          </div>
        </div>
      </td>
    </StyledSubcontractorsTableRow>
  )
}

export default SubcontractorsTableRow
