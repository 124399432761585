import React, { useState } from 'react'
import Calendar from 'react-calendar'
import { useTranslation } from 'react-i18next'
import { monthList } from '../../../../helpers/datePickerHelper'
import PopupForm from '../PopupForm/PopupForm'
import Button from '../Button/Button'
import DatePickerStyled from './DatePicker.styled'
import pictoCalendar from '../../../../../assets/images/calendar.svg'
import pictoChevron from '../../../../../assets/images/chevron-copy-for-cache-issue.svg'

const today = new Date()
const todayNormalized = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0)

export default function DatePicker({
  className, name, title, value, error, yearStart, yearEnd, detail, onChange,
}) {
  const { t, i18n } = useTranslation()
  const [dateSelected, setDateSelected] = useState(value || todayNormalized)

  return (
    <PopupForm
      className={className}
      name={`${name} date picker`}
      title={title}
      textCancel={t('popup.cancel')}
      textValidate={t('popup.validate')}
      trigger={({ open }) => {
        const dateToDisplay = value || new Date()
        const year = dateToDisplay.getFullYear()
        const month = t(`months.${monthList[dateToDisplay.getMonth()]}.full`)
        const day = detail === 'month' ? dateToDisplay.getDate() : null
        return (
          <Button
            title={title}
            name={`${name} date picker popup trigger`}
            text={[day, month, year].filter(Boolean).join(' ')}
            picto={pictoCalendar}
            error={error}
            onClick={() => open()}
          />
        )
      }}
      content={() => (
        <DatePickerStyled pictoChevron={pictoChevron}>
          <Calendar
            className="calendar"
            tileClassName={`calendar-tile ${detail}`}
            locale={i18n.language}
            minDate={new Date(yearStart, 0, 1)}
            maxDate={new Date(yearEnd, 11, 31)}
            minDetail={detail}
            maxDetail={detail}
            prevLabel={<div className="arrow previous" />}
            prevAriaLabel={`${name} date picker previous ${detail} button`}
            prev2Label={null}
            nextLabel={<div className="arrow next" />}
            nextAriaLabel={`${name} date picker next ${detail} button`}
            next2Label={null}
            value={dateSelected}
            formatMonth={(locale, date) => date.toLocaleDateString(locale, { month: 'short' })}
            formatMonthYear={(locale, date) => date.toLocaleDateString(locale, { month: 'long', year: 'numeric' })}
            onChange={(target) => setDateSelected(target)}
          />
        </DatePickerStyled>
      )}
      onValidate={() => {
        onChange(dateSelected)
      }}
    />
  )
}
