import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from '@produits-internes-oss/design-system-components'
import { ThemeProvider } from 'styled-components/macro'
import { ProjectSalesContextProvider } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectCoordinationContextProvider } from '../../context/ProjectCoordination/ProjectCoordinationContext'
import { ProjectActivitiesComsumption } from '../../components/ProjectActivitiesComsumption/ProjectActivitiesComsumption'
import { ProjectCoordinationExpenses } from '../../components/ProjectCoordinationExpenses/ProjectCoordinationExpenses'
import { ProjectCoordinationScreenStyled } from './ProjectCoordinationScreen.styled'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import pictoRevenue from '../../../assets/images/pictoRevenue.svg'
import pictoExpenses from '../../../assets/images/pictoExpenses.svg'

export const ProjectCoordinationScreen = ({ id }) => {
  const { t } = useTranslation()

  const openReporting = { text: t('open'), arialabel: 'open the reporting dropdown' }
  const closeReporting = { text: t('close'), arialabel: 'close the reporting dropdown' }
  const openExpenses = { text: t('open'), arialabel: 'open the expenses dropdown' }
  const closeExpenses = { text: t('close'), arialabel: 'close the expenses dropdown' }

  return (
    <ProjectCoordinationContextProvider id={id}>
      <ProjectSalesContextProvider id={id}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <ProjectCoordinationScreenStyled>
            <Dropdown
              className="reporting"
              title={t('project.coordination.reporting.title')}
              localization={{ open: openReporting, close: closeReporting }}
              picto={pictoRevenue}
              withStartupOpen
            >
              <ProjectActivitiesComsumption />
            </Dropdown>
            <Dropdown
              className="expenses"
              title={t('project.expenses.title')}
              localization={{ open: openExpenses, close: closeExpenses }}
              picto={pictoExpenses}
              withStartupOpen
            >
              <ProjectCoordinationExpenses />
            </Dropdown>
          </ProjectCoordinationScreenStyled>
        </ThemeProvider>
      </ProjectSalesContextProvider>
    </ProjectCoordinationContextProvider>
  )
}
