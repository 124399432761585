import styled from 'styled-components'

export default styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 3rem;

  font-family: ${({ theme }) => theme.fonts.family.bold};

  .title {
    margin-right: 10rem;
  }

  h1 {
    font-size: 3rem;
  }

  p {
    font-size: 1.8rem;
    font-family: ${({ theme }) => theme.fonts.family.medium};
  }
`
