import React, { useState, useEffect } from 'react'
import { InputNumber } from '@produits-internes-oss/design-system-components'
import { getClass, parseFloatWithAnySeparator } from '../../helpers/stringHelper'
import { useDebouncer } from '../../hooks/useDebouncer/useDebouncer'
import { useProjectTurnoverBreakdownContext } from '../../context/ProjectTurnoverBreakdownContext/ProjectTurnoverBreakdownContext'
import { ProjectTurnoverBreakdown } from '../../structures/ProjectTurnoverBreakdown/ProjectTurnoverBreakdown'
import { ProjectTurnoverBreakdownInputStyled } from './ProjectTurnoverBreakdownInput.styled'

export const ProjectTurnoverBreakdownInput = ({ year, month, lob, turnoverBreakdownValue, disabled }) => {
  const { debounce } = useDebouncer()
  const {
    turnoverBreakdown,
    errorsInAmounts,
    setTurnoverBreakdown,
    fetchCreateTurnoverBreakdownAmount,
    fetchUpdateTurnoverBreakdownAmount,
  } = useProjectTurnoverBreakdownContext()

  const [amount, setAmount] = useState(turnoverBreakdownValue.getAmountOrDefaultToProposal())
  const [isInvalid, setIsInvalid] = useState(false)

  useEffect(() => {
    setAmount(turnoverBreakdownValue.getAmountOrDefaultToProposal())
  }, [turnoverBreakdownValue])

  const clean = () => {
    if (isInvalid) {
      setAmount(0)
      setIsInvalid(false)
    }
  }

  const update = (value) => {
    setAmount(value)
    setIsInvalid(false)

    let valueParsed = parseFloatWithAnySeparator(value)

    if (Number.isNaN(valueParsed)) {
      valueParsed = 0
      setIsInvalid(true)
    }

    const turnoverBreakdownToUpdate = new ProjectTurnoverBreakdown(turnoverBreakdown)
    turnoverBreakdownToUpdate.values[year][month].lobs[lob.abbreviation].changed_by_user = true
    turnoverBreakdownToUpdate.values[year][month].lobs[lob.abbreviation].amount = valueParsed
    turnoverBreakdownToUpdate.recalculateMonths()
    turnoverBreakdownToUpdate.recalculateTotals()
    setTurnoverBreakdown(turnoverBreakdownToUpdate)
    debounce(() => (turnoverBreakdownValue.id
      ? fetchUpdateTurnoverBreakdownAmount(year, month, lob, valueParsed)
      : fetchCreateTurnoverBreakdownAmount(year, month, lob, valueParsed)), 1000)
  }

  const hasError = errorsInAmounts[`${year}-${month}-${lob.abbreviation}`] || isInvalid
  return (
    <ProjectTurnoverBreakdownInputStyled>
      <InputNumber
        className={getClass(turnoverBreakdownValue.changedByUser && 'changed', hasError && 'error')}
        name={`${year} ${month} ${lob.abbreviation}`}
        valueInitial={turnoverBreakdownValue.changedByUser && amount === null ? '' : amount}
        disabled={disabled}
        localization={{ invalid: { text: 'only numbers allowed', arialabel: 'only numbers allowed' } }}
        onChange={(value) => update((value || value === 0) ? value : null)}
        onBlur={() => clean()}
      />
    </ProjectTurnoverBreakdownInputStyled>
  )
}
