import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectServiceHeaderStyled } from './ProjectServiceHeader.styled'
import { getNumberWithUnit, parseFloatWithAnySeparator } from '../../helpers/stringHelper'

export const ProjectServiceHeader = () => {
  const { t } = useTranslation()
  const { project, presalesTextTranslation } = useProjectSalesContext()
  const hasAmount = project && project.service

  return (
    <ProjectServiceHeaderStyled>
      {
        hasAmount
          ? <span className="text-bold">{t('project.revenue.service.present')}</span>
          : <span>{t(`project.revenue.service.absent.${presalesTextTranslation()}`)}</span>
      }
      {
        hasAmount && <span className="amount text-bold">{getNumberWithUnit(parseFloatWithAnySeparator(project.service), '€')}</span>
      }
    </ProjectServiceHeaderStyled>
  )
}
