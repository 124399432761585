import styled from 'styled-components'

export const DropdownStyled = styled.div`
padding-bottom: 5rem;

> button.header {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 4.5em;
  padding: 1em;
  border-radius: 1.25em;
  background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
  color: ${({ theme }) => theme.colors.betterway.white};
  font-style: normal;
  font-stretch: normal;
  font-size: 16px;
  font-family: outfit, sans-serif;
  line-height: normal;
  letter-spacing: 0.05em;
  text-align: left;
  cursor: pointer;
  user-select: none;

  >.picto {
    width: 2.5em;
    height: 2.5em;
    background-image: url(${({ picto }) => picto});
    background-position: center;
    background-clip: border-box;
    background-size: contain;
    background-repeat: no-repeat;
  }

  >.title {
    font-weight: 600;
    font-size: 1.5em;
  }

  >.state {
    display: flex;
    flex-flow: row;
    gap: 0.5em;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0.125em;
    margin-left: auto;
    font-family: inherit;

    >.state-text {
      font-size: 0.875em;
      text-decoration: underline;

      &:hover {
        font-weight: 600;
      }
    }

    >.state-icon {
      font-size: 1.375em;
      transform: ${({ opened }) => (opened ? 'rotateZ(90deg)' : '')};
    }
  }
}

>.content {
  margin-top: 1.5em;
}

`
