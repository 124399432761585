import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectExpensesExplanationStyled } from './ProjectExpensesExplanation.styled'

export const ProjectExpensesExplanation = () => {
  const { t } = useTranslation()
  return (
    <ProjectExpensesExplanationStyled className="info-no-expense">
      <div>
        <p className="chargeable">
          {t('project.expenses.explanation.chargeable.0')}
          <em>{t('project.expenses.explanation.chargeable.1')}</em>
          {t('project.expenses.explanation.chargeable.2')}
          <em>{t('project.expenses.explanation.chargeable.3')}</em>
          {t('project.expenses.explanation.chargeable.4')}
          <em>{t('project.expenses.explanation.chargeable.5')}</em>
        </p>
        <p className="not-chargeable">
          {t('project.expenses.explanation.notChargeable.0')}
          <em>{t('project.expenses.explanation.notChargeable.1')}</em>
          {t('project.expenses.explanation.notChargeable.2')}
          <em>{t('project.expenses.explanation.notChargeable.3')}</em>
        </p>
      </div>
    </ProjectExpensesExplanationStyled>
  )
}
