import styled from 'styled-components'
import { Modal } from '@produits-internes-oss/design-system-components'

export const ProjectConsumptionTimeInputsStyled = styled(Modal)`
&.octo.hard {
  >.popup-trigger {
    button {
      &:hover {
        font-weight: 600;
      }
    }
  }

  >.popup-tooltip {
    >.modal {
      >.content {
        width: fit-content;
        max-width: 90vw;
        min-height: 80vh;
        max-height: 80vh;
        padding: 0;

        >.header {
          position: sticky;
          top: 0;
          left: 0;
          z-index: 1;
          box-sizing: border-box;
          width: 100%;
          padding-bottom: 1rem;
          background: white;

          >.copy {
            margin-bottom: 2.6rem;

            &.octo.hard.default {
              > button {
                gap: 0.4rem;
                width: fit-content;
                padding: 0;
                border: none;
                background: none;
                color: ${({ theme }) => theme.colors.betterway.primary[100]};
                font-weight: normal;
                font-size: 1.2rem;
                text-decoration: underline;

                &:hover {
                  background: none;
                  color: ${({ theme }) => theme.colors.betterway.primary[100]};
                  font-weight: bold;
                }

                &:focus,
                &:focus-visible {
                  border: none;
                  color: ${({ theme }) => theme.colors.betterway.primary[100]};
                }

                ::before {
                  content: none;
                }

                >.icon {
                  width: 1.6rem;
                  height: 1.6rem;
                  mask-size: contain;
                }
              }
            }
          }

          >.picker {
            width: 30rem;
            margin-bottom: 1.2rem;

            >div.control {
              > div.button-group-month {
                > button.month-button-navigator.arrow-right,
                > button.month-button-navigator.arrow-left,
                > button.month-button {
                  font-size: 1.5rem;
                }
              }
            }
          }
        }

        >.inputs {
          > table {
            position: relative;
            z-index: 0;
            cursor: auto;

            > thead {
              > tr {
                > th {
                  position: sticky;
                  top: 14.6rem;
                  z-index: 2;
                  min-width: 5.6rem;
                  padding: 2rem 1rem;
                  background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
                  color: ${({ theme }) => theme.colors.betterway.white};

                  &:not(:first-child) {
                    text-align: center;
                  }

                  &:first-child {
                    position: sticky;
                    left: 0;
                    z-index: 3;
                    min-width: 20rem;
                  }

                  &:nth-child(2),
                  &:nth-child(3) {
                    min-width: 7rem;
                  }

                  >.head {
                    >.title {
                      width: 100%;
                    }
                  }
                }
              }
            }

            > tbody {
              > tr {
                &.title,
                &.total {
                  font-weight: bold;
                }

                td:first-child {
                  ::before {
                    content: '';
                    position: absolute;
                    inset: 0;
                  }

                  >.cell {
                    position: relative;
                    z-index: 1;
                  }
                }

                &.section-sub td:first-child {
                  ::before {
                    background-color: ${({ theme }) => theme.colors.betterway.secondary[20]};
                  }
                }

                &.section-sub.item:nth-child(even) td:first-child {
                  ::before {
                    background-color: ${({ theme }) => theme.colors.betterway.secondary[5]};
                  }
                }

                &.section-sub.item:nth-child(odd) td:first-child {
                  ::before {
                    background-color: ${({ theme }) => theme.colors.betterway.white};
                  }
                }

                &.section-main.item:nth-child(even) td:first-child {
                  ::before {
                    background-color: ${({ theme }) => theme.colors.betterway.primary[5]};
                  }
                }

                &.section-main.item:nth-child(odd) td:first-child {
                  ::before {
                    background-color: ${({ theme }) => theme.colors.betterway.white};
                  }
                }

                > td {
                  >.cell {
                    >.person {
                      display: flex;
                      flex-flow: column nowrap;
                      gap: 0.4rem;
                      align-items: flex-start;
                      justify-content: flex-start;
                      text-align: left;

                      >.person-title {
                        font-weight: bold;
                      }

                      >.person-subtitle {
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;
                        justify-content: flex-start;
                        text-decoration: underline;
                        white-space: pre;
                        cursor: pointer;

                        >.person-subtitle-text {
                          font-size: 1.4rem;
                        }

                        >.person-subtitle-icon {
                          width: 2rem;
                          height: 2rem;
                        }
                      }
                    }

                    >.presales {
                      >.presales-button {
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;
                        justify-content: flex-start;
                        text-decoration: underline;
                        white-space: pre;
                        cursor: pointer;

                        >.presales-button-text {
                          font-size: 1.4rem;

                          ::after {
                            content: ' ';
                          }
                        }

                        >.presales-button-icon {
                          width: 2rem;
                          height: 2rem;
                        }
                      }
                    }
                  }

                  &:not(:first-child) {
                    text-align: center;
                  }

                  &:first-child {
                    position: sticky;
                    left: 0;
                  }
                }
              }
            }

            > tfoot {
              > tr {
                > td {
                  padding: 2rem 1rem;
                  background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
                  color: ${({ theme }) => theme.colors.betterway.white};
                  font-weight: bold;

                  &:not(:first-child) {
                    text-align: center;
                  }

                  &:first-child {
                    position: sticky;
                    left: 0;
                    z-index: 3;
                  }
                }
              }
            }
          }

          &.compact {
            > table {
              > tbody {
                > tr.section-sub.item {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .copy-succeed {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    padding: 1.6rem;
    border: solid 0.2rem ${({ theme }) => theme.colors.betterway.green[100]};
    border-radius: 0.3rem;
    background-color: ${({ theme }) => theme.colors.betterway.green[5]};
  }
}
`
