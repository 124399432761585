import styled from 'styled-components'

export const StyledRemoteOfficeDaysForm = styled.div`
  margin-bottom: 5rem;
  
  h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .input-rules {
    margin-bottom: 2rem;

    p {
      color: inherit;
      font-size: inherit;
      text-transform: none;
      font-family: ${({ theme }) => theme.fonts.family.bold};
      &:first-of-type {
        margin-bottom: 1rem;
      }
    }

    &.alert {
      color: ${({ theme }) => theme.colors.blockingError};
    }
  }

  .question {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin-right: 1rem;
    }

    h6 {
      margin-bottom: 0;
    }

    input {
      font-family: inherit;
      width: 12%;
      border-radius: 7px;
      margin-bottom: 0;
      &:focus {
        border: 1px solid ${({ theme }) => theme.colors.secondaryBlue};
        box-shadow: none;
      }
    }

    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: inner-spin-button !important;
    }
  }
`
