import styled from 'styled-components'

export const StyledBurgerBtn = styled.a`
  background: transparent;
  border-left: 1px solid ${({ theme }) => theme.colors.primaryMedium};
  cursor: pointer;
  height: 100%;
  padding: 0;
  display: inline-block;
  justify-content: center;
  align-items: center;
  
  div {
    width: 2.5rem;
    height: 2rem;
    position: relative;
    margin: 2rem 2rem;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
  }
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 50%;
    background: ${({ theme }) => theme.colors.primaryGrey};
    opacity: 1;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
  span:nth-child(even) {
    left: 50%;
    border-radius: 0 .9rem .9rem 0;
  }
  span:nth-child(odd) {
    left: 0px;
    border-radius: .9rem 0 0 .9rem;
  }
  span:nth-child(1),
  span:nth-child(2) {
    top: ${({ open }) => (open ? '3.5px' : '0')};
  }
  span:nth-child(3),
  span:nth-child(4) {
    top: .8rem;
  }
  span:nth-child(5),
  span:nth-child(6) {
    top: ${({ open }) => (open ? '1.25rem' : '1.6rem')};
  }
  span:nth-child(1),
  span:nth-child(6) {
    transform: ${({ open }) => open && 'rotate(45deg)'};
  }
  span:nth-child(2),
  span:nth-child(5) {
    transform: ${({ open }) => open && 'rotate(-45deg)'};
  }
  span:nth-child(1),
  span:nth-child(5) {
    left: ${({ open }) => open && '1px'};
  }
  span:nth-child(2),
  span:nth-child(6) {
    left: ${({ open }) => open && 'calc(50% - 3px)'};
  }
  span:nth-child(3) {
    left: ${({ open }) => open && '-50%'};
    opacity: ${({ open }) => open && '0'};
  }
  span:nth-child(4) {
    left: ${({ open }) => open && '100%'};
    opacity: ${({ open }) => open && '0'};
  }
`
