import styled from 'styled-components'
import { ProjectFormFragment } from '../ProjectFormFragment/ProjectFormFragment'
import pictoInfo from '../../../assets/images/exclamationInfo.svg'

export const ProjectFormProposalInProgressStyled = styled(ProjectFormFragment)`
  .column {
    >.form-group.competition {
      >.form-control {
        max-width: 20.5rem;
      }
    }
  }

  .form-control {
    &.affiliation {
      max-width: 48rem;
    }
  }

  h3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    >.responsible-tech {
      margin-left: 1rem;

      >.popup-trigger {
        >button {
          width: 2rem;
          height: 2rem;
          background-image: url(${pictoInfo});
          background-position: center;
          background-clip: border-box;
          background-size: contain;
          background-repeat: no-repeat;
          cursor: pointer;
        }
      }

      >.popup-tooltip {
        font-weight: initial;
        font-size: initial;

        > div {
          > p:first-child {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .button-responsible-tech {
    &.affiliation {
      max-width: 48rem;
    }

    ul {
      display: flex;
      flex-direction: column !important;

      .item {
        button {
          align-items: start !important;

          .text {
            .option-responsible-tech {
              max-width: 48rem;
              white-space: normal;

              .name {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
`
