import React from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { useSubcontracting } from '../../../context/Subcontracting/SubcontractingContext'
import SubcontractorExportButtonStyled from './SubcontractorExportButton.styled'

export default function SubcontractorExportButton() {
  const { t } = useTranslation()
  const {
    doesEntryHaveABillBadFunctionMustBeRemovedWhenBackIsRefactored,
    filteredAndSortedData,
  } = useSubcontracting()

  const localeForm = `${t('subcontractorsOverview.SubcontractorsTableHeader.subcontractorsForm')}${t('colon')}`
  const localeCutting = `${t('subcontractorsOverview.SubcontractorsTableHeader.cutting')}${t('colon')}`
  const localeBilling = `${t('subcontractorsOverview.SubcontractorsTableHeader.billing')}${t('colon')}`

  const getSubcontractorBillToReview = (entry) => {
    let result = `${entry.subcontractor_bill.to_review}`
    if (entry.subcontractor_bill.to_review === null) {
      result = doesEntryHaveABillBadFunctionMustBeRemovedWhenBackIsRefactored(entry) ? 'true' : ''
    }

    return result
  }

  const headers = [
    { label: t('subcontractorsOverview.SubcontractorsTableHeader.month'), key: 'month' },
    { label: t('subcontractorsOverview.SubcontractorsTableHeader.subcontractorName'), key: 'subcontractor_name' },
    { label: t('subcontractorsOverview.SubcontractorsTableHeader.purchasePrice'), key: 'purchase_price' },
    { label: t('subcontractorsOverview.SubcontractorsTableHeader.project'), key: 'project' },
    { label: `${localeForm} ${t('subcontractorsOverview.SubcontractorsTableHeader.daysSpent')}`, key: 'form_days_spent' },
    { label: `${localeForm} ${t('subcontractorsOverview.SubcontractorsTableHeader.expenses')}`, key: 'form_expenses' },
    { label: `${localeForm} ${t('subcontractorsOverview.SubcontractorsTableHeader.hapinessIndicator')}`, key: 'form_thi' },
    { label: `${localeCutting} ${t('subcontractorsOverview.SubcontractorsTableHeader.daysSpent')}`, key: 'cutting_days_spent' },
    { label: `${localeCutting} ${t('subcontractorsOverview.SubcontractorsTableHeader.expenses')}`, key: 'cutting_expenses' },
    { label: `${localeBilling} ${t('subcontractorsOverview.SubcontractorsTableHeader.daysSpent')}`, key: 'billing_days_spent' },
    { label: `${localeBilling} ${t('subcontractorsOverview.SubcontractorsTableHeader.expenses')}`, key: 'billing_expenses' },
    { label: `${localeBilling} ${t('subcontractorsOverview.SubcontractorsTableHeader.purchasePrice')}`, key: 'billing_purchase_price' },
    { label: t('comment'), key: 'comment' },
    { label: t('subcontractorsOverview.subcontractorsTableRow.to_review'), key: 'to_review' },
    { label: t('wbs'), key: 'wbs' },
    { label: t('subcontractorsOverview.SubcontractorsTableHeader.missionMaker'), key: 'mission_maker' },

  ]

  const content = filteredAndSortedData().map((project) => ({
    month: project.project_expense_input.month,
    subcontractor_name: project.project_expense_input.name,
    purchase_price: project.project_expense_input.price,
    project: project.project_expense_input.project,
    form_days_spent: project.subcontractor_form_input.quantity_spent,
    form_expenses: project.subcontractor_form_input.misc_expense,
    form_thi: project.subcontractor_form_input.happiness_indicator,
    cutting_days_spent: project.cutting_input.quantity_spent,
    cutting_expenses: project.cutting_input.misc_expense,
    billing_days_spent: project.subcontractor_bill.expense_quantity,
    billing_expenses: project.subcontractor_bill.misc_expense_quantity,
    billing_purchase_price: project.subcontractor_bill.unit_price,
    comment: project.subcontractor_bill.comment,
    to_review: getSubcontractorBillToReview(project),
    wbs: project.project_expense_input.project_wbs,
    mission_maker: project.mission_maker,
  }))

  const filename = `${t('subcontractorsOverview.subcontractorsOverviewTop.title')}.csv`
  return (
    <SubcontractorExportButtonStyled>
      <CSVLink headers={headers} data={content} filename={filename} separator=";">
        <button className="btn" type="button">
          <i className="fa fa-table" />
          <span className="btn-label">{ t('subcontractorsOverview.subcontractorsOverviewTop.download') }</span>
        </button>
      </CSVLink>
    </SubcontractorExportButtonStyled>
  )
}
