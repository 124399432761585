import React, { useReducer, useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components/macro'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import { StyledTimesheetScreen } from './TimesheetScreen.styled'

import { TimesheetParamsProvider, timesheetParamsReducer } from '../../context/Timesheet/TimesheetParamsContext'
import { LocationContextProvider } from '../../context/Timesheet/LocationContext'
import { TimesheetStateContext } from '../../context/Timesheet/TimesheetStateContext'

import { request } from '../../javascripts/HttpService'
import { useSingleHttpRequest } from '../../hooks/httpRequest'
import TimesheetDate from '../../javascripts/models/TimesheetDate'

import TimesheetTop from '../../components/Timesheet/TimesheetTop/TimesheetTop'
import TimesheetTable from '../../components/Timesheet/TimesheetTable/TimesheetTable'
import Loader from '../../components/UI/Loader/Loader'
import GdprFooter from '../../components/Timesheet/GdprFooter/GdprFooter'

const TimesheetScreen = ({
  person,
  timesheetStartDate,
}) => {
  const [fetchTimesheetDataTimer, setFetchTimesheetDataTimer] = useState(null)
  const [countries, setCountries] = useState(null)
  const initialParams = {
    owner: person,
    timesheetDate: new TimesheetDate(
      new Date(timesheetStartDate).getMonth(),
      new Date(timesheetStartDate).getFullYear(),
    ),
  }
  const [timesheetParams, dispatchTimesheetParams] = useReducer(
    timesheetParamsReducer,
    initialParams,
  )
  const [timesheetState, setTimesheetState] = useState(null)
  const {
    isLoading, error, data, sendRequest,
  } = useSingleHttpRequest(true)

  const setDocumentTitleAndUrl = () => {
    const params = document.location.search
    history.pushState(null, null, `/timesheet/${timesheetParams.owner.nickname}/${timesheetParams.timesheetDate.year}/${timesheetParams.timesheetDate.month + 1}${params}`)
    document.title = `${timesheetParams.owner.nickname} ${timesheetParams.timesheetDate.month + 1}/${timesheetParams.timesheetDate.year}`
  }

  const getCountries = () => {
    const url = '/location/locations'

    request(url, { method: 'GET' })
      .then((dataJSON) => {
        setCountries(dataJSON)
      })
      .catch((exception) => {
        throw exception
      })
  }

  useEffect(() => {
    getCountries()
  }, [])

  const fetchTimesheetDataDebounce = (url, ms) => {
    const headers = {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    }

    clearTimeout(fetchTimesheetDataTimer)
    setFetchTimesheetDataTimer(setTimeout(() => {
      sendRequest(url, 'GET', null, headers)
    }, ms))
  }

  useEffect(() => {
    const url = `/timesheet/${timesheetParams.owner.nickname}/${timesheetParams.timesheetDate.year}/${timesheetParams.timesheetDate.month + 1}.json`
    fetchTimesheetDataDebounce(url, 1)
    setDocumentTitleAndUrl()
  }, [timesheetParams])

  useEffect(() => {
    if (error) {
      setTimesheetState()
    } else {
      setTimesheetState(data)
    }
  }, [error, data])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <TimesheetParamsProvider value={[timesheetParams, dispatchTimesheetParams]}>
        <TimesheetStateContext.Provider value={timesheetState}>
          <StyledTimesheetScreen>
            <TimesheetTop loading={isLoading} />
            {
              isLoading
                ? <Loader />
                : (
                  <LocationContextProvider
                    initialLocations={timesheetState.locationPerDay}
                    countries={countries}
                  >
                    <TimesheetTable />
                  </LocationContextProvider>
                )
            }
            <GdprFooter />
          </StyledTimesheetScreen>
        </TimesheetStateContext.Provider>
      </TimesheetParamsProvider>
    </ThemeProvider>
  )
}

export default TimesheetScreen
