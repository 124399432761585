import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledMonthRangePickerSimplified } from './MonthRangePickerSimplified.styled'
import SelectMonthYear from '../../SelectMonthYear/SelectMonthYear'

const MonthRangePickerSimplified = (
  {
    currentMonth,
    currentYear,
    startYear,
    onSubmitMonthYearRange,
  },
) => {
  const { t } = useTranslation()
  const [selectedStartMonthYear, setStartSelectedMonthYear] = useState({
    month: currentMonth, year: currentYear,
  })

  const [selectedEndMonthYear, setEndSelectedMonthYear] = useState(
    currentMonth < 11
      ? { month: (currentMonth + 1) % 12, year: currentYear }
      : { month: 0, year: currentYear + 1 },
  )

  return (
    <StyledMonthRangePickerSimplified>
      <span className="select-prefix capitalize">{t('months.from')}</span>
      <SelectMonthYear
        name="start month picker"
        month={selectedStartMonthYear.month}
        year={selectedStartMonthYear.year}
        startYear={startYear}
        endYear={currentYear + 5}
        onChangeSelectedMonthYear={(month, year) => setStartSelectedMonthYear({ month, year })}
      />
      <span className="select-prefix">{t('months.to')}</span>
      <SelectMonthYear
        name="end month picker"
        month={selectedEndMonthYear.month}
        year={selectedEndMonthYear.year}
        startYear={startYear}
        endYear={currentYear + 5}
        onChangeSelectedMonthYear={(month, year) => setEndSelectedMonthYear({ month, year })}
      />
      <button
        className="submitButton"
        aria-label="month range picker submit"
        type="submit"
        onClick={() => onSubmitMonthYearRange && onSubmitMonthYearRange(
          selectedStartMonthYear.month,
          selectedStartMonthYear.year,
          selectedEndMonthYear.month,
          selectedEndMonthYear.year,
        )}
      >
        Ok
      </button>
    </StyledMonthRangePickerSimplified>
  )
}

export default MonthRangePickerSimplified
