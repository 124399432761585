import styled from 'styled-components'

export default styled.tr`
  input {
    width: 5.5rem;
    height: 1.4rem;
    margin: 0;
    border-radius: 0;
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryBlue};
  }

  .currency-input {
    display: flex;
    justify-content: center;
    .currency {
      background-color: ${({ theme }) => theme.colors.darkerGray};
      width: 1.2rem;
      display: inline-block;
      height: 2.2rem;
      border: 1px solid ${({ theme }) => theme.colors.darkerGray};
      border-radius: 0 0.4rem 0.4rem 0;
      span {
        color: ${({ theme }) => theme.colors.black};
        margin-left: -0.2rem;
      }
    }
  }

  .actions-buttons {
    display: flex;
    justify-content: space-evenly;
   
    button {
      background-color: transparent;
      &.compact {
        border: 1px solid ${({ theme }) => theme.colors.darkestGray};
        border-radius: 50%;
        width: 2.3rem;
        height: 2.3rem;
        text-align: center;
        color: ${({ theme }) => theme.colors.darkestGray};
        &.comment {
          background-color: yellow;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      &.review {
        padding: 0 0.9rem;
        border: 1px solid green;
        color: green;
        border-radius: 1rem;
        cursor: pointer;
        font-size: 1rem;
        &.to-review {
          border: 1px solid red;
          color: red;
        }
      }
    }

    textarea {
      position: absolute;
      top: -150px;
      left: -125px;
      width: 250px;
      height: 150px;
      resize: none;
      z-index: 2;
    }

    div.relative {
      position: relative;
    }
  }
`
