import React, { useRef, useState, useEffect, useMemo } from 'react'
import { Button, Icon, InputDate, Table } from '@produits-internes-oss/design-system-components'
import { useTranslation } from 'react-i18next'
import { useProjectCoordinationContext } from '../../context/ProjectCoordination/ProjectCoordinationContext'
import { monthList, getMonthLastDay } from '../../helpers/datePickerHelper'
import { getClass } from '../../helpers/stringHelper'
import { ProjectConsumptionTimeInputsStyled } from './ProjectConsumptionTimeInputs.styled'
import pictoCopy from '../../../assets/images/copy.svg'

export const ProjectConsumptionTimeInputs = ({ className, dateInitial }) => {
  const { t } = useTranslation()
  const { projectTimeInputs, fetchGetProjectTimeInputs } = useProjectCoordinationContext()

  const [date, setDate] = useState(dateInitial)
  useEffect(() => { fetchGetProjectTimeInputs(date.getFullYear(), date.getMonth() + 1) }, [date])

  const [togglePresales, setTogglePresales] = useState('show')
  const [copyToClipboardSucces, setCopyToClipboardSuccess] = useState(false)

  const days = useMemo(() => Array.from({ length: getMonthLastDay(date).getDate() }, (_, i) => i + 1), [date])
  const columns = useMemo(() => [
    {
      name: t('project.coordination.time_inputs.name.arialabel'),
      title: t('project.coordination.time_inputs.name.text'),
      onTitle: ({ section }) => section === 0 && (
        <div className="presales">
          <span>{t('project.coordination.time_inputs.presales')}</span>
          <button
            className="presales-button"
            type="button"
            aria-label={t(`project.coordination.time_inputs.${togglePresales}.arialabel`)}
            onClick={() => setTogglePresales((previous) => (previous === 'show' ? 'hide' : 'show'))}
          >
            <span className="presales-button-text">{t(`project.coordination.time_inputs.${togglePresales}.text`)}</span>
            <Icon className="presales-button-icon" name={togglePresales} />
          </button>
        </div>
      ),
      onRender: ({ section, item }) => (
        <div className="person">
          <div className="person-title">{section === 0 ? `${item.person['first_name']} ${item.person['last_name']}` : item.activity.role.name}</div>
          <a className="person-subtitle" href={`/timesheet/${item.person.nickname}/${date.getFullYear()}/${date.getMonth() + 1}`} target="_blank" rel="noreferrer">
            <span className="person-subtitle-text">
              {section === 0 ? item.person['nickname'] : `${item.person['first_name']} ${item.person['last_name']} / ${item.person['nickname']}`}
            </span>
            <Icon className="person-subtitle-icon" name="share" />
          </a>
        </div>
      ),
      onFooter: ({ section }) => {
        if (section === 0) return t('project.coordination.time_inputs.subtotal')
        if (section === 1) return null

        return t('project.coordination.time_inputs.total')
      },
    },
    {
      name: t('project.coordination.time_inputs.days.arialabel'),
      title: t('project.coordination.time_inputs.days.text'),
      onRender: ({ item }) => item.totalDays || 0,
      onFooter: ({ section, items }) => section !== 1 && items.reduce((sum, item) => sum + item.totalDays, 0),
    },
    {
      name: t('project.coordination.time_inputs.eighths.arialabel'),
      title: t('project.coordination.time_inputs.eighths.text'),
      onRender: ({ item }) => item.totalHours || 0,
      onFooter: ({ section, items }) => section !== 1 && items.reduce((sum, item) => sum + item.totalHours, 0),
    },
  ].concat(days.map((day) => ({
    name: `${t('project.coordination.time_inputs.day')} ${day}`,
    title: day,
    onRender: ({ item }) => item.hours[day] || '',
    onFooter: ({ section, items }) => section !== 1 && (items.reduce((sum, item) => sum + item.hours[day], 0) || ''),
  }
  ))), [date, days, togglePresales])

  const data = useMemo(() => projectTimeInputs?.reduce((totals, input) => {
    const id = input['person_id']
    const section = input['activity']['presales'] ? 0 : 1

    const base = (id in totals[section]) ? totals[section][id] : {
      person: input['person'],
      activity: input['activity'],
      hours: Object.fromEntries(days.map((day) => [day, 0])),
      totalDays: 0,
      totalHours: 0,
    }

    const day = new Date(input['day']).getDate()

    const result = [...totals]
    result[section][id] = {
      ...base,
      hours: { ...base.hours, [day]: base.hours[day] + input['time_in_hours'] },
      totalDays: base.totalDays + parseFloat(input['time_in_days']) || 0,
      totalHours: base.totalHours + parseFloat(input['time_in_hours']) || 0,
    }

    return result
  }, [{}, {}]), [projectTimeInputs])

  const copyToClipboard = useRef(() => {})

  const copyButtonClick = () => {
    copyToClipboard.current()
    setCopyToClipboardSuccess(true)
    setTimeout(() => {
      setCopyToClipboardSuccess(false)
    }, 5000)
  }

  const copySucceedText = () => (
    <div className="copy-succeed">{t('copySucceed')}</div>
  )
  return (
    <ProjectConsumptionTimeInputsStyled
      className={className}
      skin="light"
      title={t('project.coordination.time_inputs.title')}
      trigger={({ open }) => (
        <button type="button" aria-label={t('project.coordination.time_inputs.trigger.arialabel')} disabled={projectTimeInputs === null} onClick={() => open()}>
          {t('project.coordination.time_inputs.trigger.text')}
        </button>
      )}
    >
      <div className="header">
        <Button
          className="copy"
          skin="dark"
          kind="primary"
          name={t('project.coordination.time_inputs.clipboard.arialabel')}
          text={t('project.coordination.time_inputs.clipboard.text')}
          icon={{ url: pictoCopy, position: 'right' }}
          onClick={() => copyButtonClick()}
        />
        {copyToClipboardSucces && copySucceedText()}
        <InputDate
          className="picker"
          name={t('project.coordination.time_inputs.picker.arialabel')}
          title={t('project.coordination.time_inputs.picker.text')}
          localization={{
            months: [...monthList.map((month) => t(`months.${month}.short`)), ...monthList.map((month) => t(`months.${month}.full`))],
            previous: t('project.coordination.time_inputs.previous'),
            next: t('project.coordination.time_inputs.next'),
          }}
          valueInitial={date}
          precision="month"
          onChange={(value) => setDate(value)}
        />
      </div>
      <Table
        className={getClass('inputs', togglePresales === 'show' ? 'full' : 'compact')}
        columns={columns}
        data={data}
        handlers={{ copyToClipboard: (method) => { copyToClipboard.current = method } }}
      />
    </ProjectConsumptionTimeInputsStyled>
  )
}
