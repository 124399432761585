import styled from 'styled-components'

export const StyledBurgerMenu = styled.div`
  height: 100%;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  -webkit-touch-callout: none;

  > a {
    display: none;
  }
`
