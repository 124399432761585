import styled from 'styled-components'

export const ProjectTurnoverBreakdownTableStyled = styled.div`
overflow-x: auto;

table {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-size: 1.4rem;
  font-family: Outfit, Apercu, sans-serif;
  letter-spacing: normal;
  text-align: left;

  th,
  td {
    box-sizing: border-box;
    height: 6.2rem;
    padding: 0;

    &:first-child {
      position: sticky;
      left: 0;
      z-index: 10;
      font-weight: 600;

      ::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: ${({ theme }) => theme.colors.betterway.white};
      }
    }

    >.cell {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 0.8rem;

      &.positive {
        background-color: ${({ theme }) => theme.colors.betterway.green[20]} !important;
        font-weight: bold;
      }

      &.negative {
        background-color: ${({ theme }) => theme.colors.betterway.orange[20]} !important;
        font-weight: bold;
      }

      &.annual-line-spacing-cell {
        font-weight: bold;
      }

      &.action-cell {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 0.8rem;
      }

      .close-with-manual-change {
        width: auto;
        background-color: ${({ theme }) => theme.colors.betterway.red[100]};
      }

      .change-validation-popup,
      .close-cutting-popup {
        width: auto;

        .window {
          width: auto;
          max-width: 60rem;
          max-height: none;
          font-weight: bold;

          .header {
            justify-content: left;

            .header-close {
              right: 0;
              left: auto;
            }
          }
        }

        .button-validate,
        .open-close-cutting {
          > button {
            justify-content: center;
            border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
            background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
            color: ${({ theme }) => theme.colors.betterway.white};

            &:hover {
              border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
              background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
            }

            &:disabled {
              border-color: ${({ theme }) => theme.colors.betterway.primary[50]};
              background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
              color: ${({ theme }) => theme.colors.betterway.white};
              cursor: not-allowed;
            }
          }
        }

        .button-wrapper {
          display: flex;
          justify-content: flex-end;
          margin-top: 2rem;

          button {
            justify-content: center;
            font-size: 1.6rem;
          }

          .button-cancel {
            width: 14.1rem;
          }

          .button-validate {
            width: 19.5rem;
            margin-left: 1rem;
          }
        }
      }

      .button-comments {
        margin-right: 1.6rem;
      }

      .open-change-validation-button {
        width: max-content;
        background: rgb(0 0 0 / 0%);
        font-family: Outfit, sans-serif;
        text-decoration: underline;
        cursor: pointer;
        text-underline-offset: 3px;

        > img {
          width: 20px;
          margin-left: 5px;
        }

        &.disabled {
          color: grey;
          cursor: not-allowed;

          > img {
            opacity: 40%;
          }
        }
      }

      >.delta {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        width: max-content;
        min-width: 100%;

        > img {
          margin-left: 1rem;
        }
      }

      .separator {
        width: 100%;
        margin: 1rem 0;
        border-bottom: 0.2rem solid;
      }
    }

    &:not(:last-child) {
      >.cell {
        border-right: 1px solid ${({ theme }) => theme.colors.betterway.primary[50]};
      }
    }
  }

  thead {
    tr {
      th {
        position: sticky;
        top: 0;
        z-index: 1;
        width: 20rem;
        min-width: 13rem;
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
        font-weight: bold;
        line-height: 1.44;
        white-space: pre;

        >.cell {
          padding: 0.8rem;
          border-bottom: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[100]};
          background-color: ${({ theme }) => theme.colors.betterway.primary[20]};

          &.total {
            background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
            color: ${({ theme }) => theme.colors.betterway.white};
            font-weight: 600;
          }
        }

        &:first-child {
          z-index: 2;

          >.cell {
            border-radius: 1.5rem 0 0;
          }
        }

        &:last-child {
          >.cell {
            border-radius: 0 1.5rem 0 0;
          }
        }
      }
    }
  }

  tbody {
    tr {
      > td {
        &:not(:first-child):not(:last-child) {
          text-align: right;

          >div.cell {
            align-items: stretch;

            >div {
              display: flex;
              justify-content: end;
              text-align: right;

              >input {
                text-align: right;
              }
            }
          }
        }

        >.cell {
          border-top: 1px solid ${({ theme }) => theme.colors.betterway.primary[20]};

          &.total {
            background-color: ${({ theme }) => theme.colors.betterway.primary[70]} !important;
            color: ${({ theme }) => theme.colors.betterway.white};
            font-weight: 600;
          }
        }
      }

      &:first-child {
        > td {
          >.cell {
            border-top: none;
          }
        }
      }

      &.annual-line-spacing {
        > td {
          >.cell {
            background-color: ${({ theme }) => theme.colors.betterway.secondary[5]};
          }
        }
      }

      &.even {
        > td {
          >.cell {
            background-color: ${({ theme }) => theme.colors.betterway.white};
          }
        }
      }

      &.odd {
        > td {
          >.cell {
            background-color: ${({ theme }) => theme.colors.betterway.primary[5]};
          }
        }
      }

      &.highlight {
        > td {
          >.cell {
            background-color: ${({ theme }) => theme.colors.betterway.secondary[20]};

            &.total {
              color: ${({ theme }) => theme.colors.betterway.primary[100]};
              font-weight: 600;
            }
          }
        }
      }

      .comment-line {
        width: 100%;
        margin: 1.5rem 0;
        border-bottom: 0.2rem solid;
      }
    }
  }

  .comment-textarea {
    width: 100%;
    resize: none;
  }

  .content-textarea {
    display: flex;
    width: 100%;
    height: 15rem;
    margin-bottom: 2rem;

    textarea {
      margin: 0;
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      font-size: 1.4rem;
      font-family: Outfit, Apercu, sans-serif;
      letter-spacing: normal;
      text-align: left;
    }
  }

  .content-textarea-title {
    margin-top: 1rem;
    margin-bottom: 0.2rem;
    font-weight: 400;
  }

  .label-text-area {
    width: 100%;
    height: 100%;
  }

  .title-text-area {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
  }

  .contain-button-validate {
    display: flex;
    justify-content: right;
    width: 100%;

    > button {
      width: 12rem;
      height: 4rem;
      margin-left: 10rem;
      border-radius: 10rem;
      background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
      color: ${({ theme }) => theme.colors.betterway.white};
      font-weight: bold;

      &:disabled {
        background-color: ${({ theme }) => theme.colors.betterway.primary[70]};
      }
    }
  }

  tfoot {
    tr {
      &.hidden {
        display: none;
      }

      &.last {
        td {
          &:first-child {
            >.cell {
              border-radius: 0 0 0 1.5rem;
            }
          }

          &:last-child {
            >.cell {
              border-radius: 0 0 1.5rem;
            }
          }
        }
      }

      td {
        height: 4rem;
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
        font-weight: bold;
        line-height: 1.44;

        &:not(:first-child):not(:last-child) {
          >div.cell {
            align-items: end;
          }
        }

        .offset {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          padding: 0.3rem 0.6rem;
          border: 0.1rem solid;
          border-radius: 0.3rem;
          letter-spacing: 0.81px;
          white-space: pre;

          &.positive-offset {
            border-color: ${({ theme }) => theme.colors.betterway.red[70]};
            background-color: ${({ theme }) => theme.colors.betterway.red[50]};
          }

          &.negative-offset {
            border-color: ${({ theme }) => theme.colors.betterway.green[100]};
            background-color: ${({ theme }) => theme.colors.betterway.green[50]};
          }
        }

        >.cell {
          border-top: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[100]};
          background-color: ${({ theme }) => theme.colors.betterway.primary[20]};

          &.total {
            background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
            color: ${({ theme }) => theme.colors.betterway.white};
            font-weight: 600;
          }
        }
      }
    }
  }
}
`
