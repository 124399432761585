import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSubcontracting } from '../../../context/Subcontracting/SubcontractingContext'
import DateRangePickerSimplified from '../../input/DateRangePickerSimplified/DateRangePickerSimplified'
import SubcontractorExportButton from '../SubcontractorExportButton/SubcontractorExportButton'
import StyledSubcontractorsOverviewTop from './SubcontractorsOverviewTop.styled'

const SubcontractorsOverviewTop = () => {
  const { t } = useTranslation()
  const { fetchEntries } = useSubcontracting()

  const getDateString = (day, month, year) => {
    const dayTwoDigits = `0${day}`
    const monthTwoDigits = `0${month}`
    return `${day < 10 ? dayTwoDigits : day}/${month < 10 ? monthTwoDigits : month}/${year}`
  }

  const getDateURL = (startDate, endDate) => {
    const rangeStart = getDateString(
      startDate.getDate(),
      startDate.getMonth() + 1,
      startDate.getFullYear(),
    )
    const rangeEnd = getDateString(
      endDate.getDate(),
      endDate.getMonth() + 1,
      endDate.getFullYear(),
    )
    fetchEntries(`/project_expenses/subcontractor_inputs?range_start_date=${rangeStart}&range_end_date=${rangeEnd}`)
  }

  return (
    <StyledSubcontractorsOverviewTop>
      <div className="title">
        <h1>{t('subcontractorsOverview.subcontractorsOverviewTop.title')}</h1>
        <p>{t('subcontractorsOverview.subcontractorsOverviewTop.description')}</p>
      </div>
      <DateRangePickerSimplified
        onChangeMonthYearRange={(startDate, endDate) => getDateURL(startDate, endDate)}
      />
      <SubcontractorExportButton />
    </StyledSubcontractorsOverviewTop>
  )
}

export default SubcontractorsOverviewTop
