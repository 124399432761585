import React from 'react'
import { useTranslation } from 'react-i18next'
import { getClass } from '../../../helpers/stringHelper'
import { useSynchronizationContext } from '../../../context/Synchronization/SynchronizationContext'
import { SynchronizationProgressionStyled } from './SynchronizationProgression.styled'

export const SynchronizationProgression = ({ className }) => {
  const { t } = useTranslation()
  const { progression } = useSynchronizationContext()

  return progression
    ? (
      <SynchronizationProgressionStyled className={getClass(className, progression.percentage >= 1 && 'full')} percentage={progression.percentage}>
        {
          progression.total > 0 && (
            <>
              <div className="progression-title">{`${progression.done} ${t('synchronization.people')} / ${progression.total} ${t('synchronization.people')}`}</div>
              <div className="progression-bar" />
            </>
          )
        }
      </SynchronizationProgressionStyled>
    )
    : null
}
