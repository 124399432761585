import React from 'react'
import { StyledNavHeader } from './NavHeader.styled'

const NavHeader = ({ title }) => (
  <StyledNavHeader>
    {title}
  </StyledNavHeader>
)

export default NavHeader
