import styled from 'styled-components'

export const StyledValidationConfirmationModal = styled.div`
  h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
    font-family: ${({ theme }) => theme.fonts.family.bold}
  }

  div {
    display: flex;
    margin-top: 3rem;
    justify-content: flex-end;
    
    a {
      text-decoration: none;
      cursor: pointer;
      padding: 0.6rem 2rem;
      background: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
      border-style: solid;
      border-width: 2px;
      border-color: ${({ theme }) => theme.colors.primary};;
      border-radius: 4.8rem;

      &:hover {
        opacity: 0.85;
      }
    }
  }
`
