import theme from '../../../../../../../style/theme'

export const customStyles = {
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  singleValue: (base) => ({
    ...base,
    paddingLeft: '20px',
  }),
  input: (base) => ({
    ...base,
    'input:focus': {
      boxShadow: 'none',
      margin: 'auto',
      fontFamily: theme.fonts.family.standard,
    },
    paddingLeft: '20px',
    textAlign: 'center',
  }),
  control: (base) => ({
    ...base,
    borderRadius: 50,
    cursor: 'pointer',
  }),
  placeholder: (base) => ({
    ...base,
    paddingLeft: '22px',
    color: theme.colors.skeletonGreyDark,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
}

export const iconStyle = {
  position: 'absolute',
  left: 10,
  fontSize: 15,
  color: theme.colors.primaryGrey,
}
