import styled from 'styled-components'

export default styled.thead`
  position: sticky;
  top: -2rem;
  z-index: 1;
  font-family: ${({ theme }) => theme.fonts.family.medium};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};

  tr {
    th,
    td {
      background-color: ${({ theme }) => theme.colors.primaryBlue};
    }

    &.subcontractor-categories {
      th,
      td {
        padding: 1rem;
        
        &.white-header {
          background-color: ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.primaryBlue};
          border: none;
        }
      }
    }

    &.subcontractor-headers {
      th,
      td {
        padding: 0.5rem 0.2rem;
      }
    }

    &.subcontractor-filters {
      th,
      td {
        padding: 0.5rem;
        background-color: ${({ theme }) => theme.colors.white};
        border-right: 1px solid ${({ theme }) => theme.colors.lightGrey};
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
        color: ${({ theme }) => theme.colors.primaryBlue};

        input {
          width: calc(100% - 0.5rem * 2);
        }

        select {
          width: 100%;
          margin-bottom: 0;
        }
      }

      th:first-child,
      td:first-child {
        border-left: 1px solid ${({ theme }) => theme.colors.lightGrey};
      }
    }
  }
`
