import React from 'react'
import { getClass } from '../../../../helpers/stringHelper'
import SelectEnumStyled from './SelectEnum.styled'

export default function SelectEnum({
  className, name, title, value, disabled, items, onChange,
}) {
  const valueIndex = items.findIndex(((item) => item.name === value))

  return (
    <SelectEnumStyled className={getClass(className, disabled && 'disabled')}>
      {
        title && <div className="title">{title}</div>
      }
      <div className="enum">
        <div className="track">
          {
            items.map((item) => (
              <button
                key={item.name}
                className={item.name === value ? 'selected' : null}
                type="button"
                aria-label={`${name} select enum item ${item.name}`}
                disabled={disabled}
                onClick={() => {
                  if (item.name !== value) {
                    onChange(item)
                  }
                }}
              >
                {item.title}
              </button>
            ))
          }
          {
            valueIndex !== -1 && (
              <div
                className="slider"
                style={{
                  left: `calc((100% / ${items.length}) * ${valueIndex})`,
                  width: `calc((100% / ${items.length})`,
                }}
              />
            )
          }
        </div>
      </div>
    </SelectEnumStyled>
  )
}
