import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  padding: 2.4rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[20]};
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.betterway.white};

  .info {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1.6rem;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.betterway.primary[20]};

    >.update-status-text {
      align-self: center;
      font-size: 1.4rem;
    }

    >.update-status-button {
      width: 3.4rem;
      height: 3.4rem;

      > button {
        width: 100%;
        height: 100%;
        padding: 0;
        border: none;
        background: ${({ theme }) => theme.colors.betterway.secondary[5]};

        >.picto {
          width: 100%;
          height: 100%;
          color: ${({ theme }) => theme.colors.primaryDark};
        }
      }
    }

    .header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .header-status {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: stretch;

        .header-status-icon {
          width: 1.4rem;
          height: 1.4rem;
          margin: 0 0.7rem 0 0;
          border-radius: 50%;

          &.incomplete {
            background-color: ${({ theme }) => theme.colors.betterway.red[100]};
          }

          &.processing {
            background-color: ${({ theme }) => theme.colors.betterway.orange[70]};
          }

          &.processed {
            background-color: ${({ theme }) => theme.colors.betterway.green[50]};
          }
        }

        .header-status-title {
          font-weight: 500;
          font-size: 1.6rem;
        }
      }

      .header-controls {
        width: auto;

        >.header-controls-trigger {
          > button {
            width: 2.4rem;
            height: 2.4rem;
            padding: 0;
            border: none;
          }
        }
      }
    }

    .type {
      flex-shrink: 1;
      overflow: hidden;
      min-width: 0;
      padding-right: 4rem;
      font-weight: 500;
      font-size: 1.6rem;
      text-overflow: ellipsis;
      white-space: pre;
    }

    .value {
      font-weight: 700;
      font-size: 1.6rem;
    }
  }

  .description {
    .description-title {
      margin-bottom: 0.4rem;
      color: ${({ theme }) => theme.colors.betterway.primary[50]};
      font-size: 1.4rem;
    }
  }
`
