import { useTimesheetStateValue } from '../context/Timesheet/TimesheetStateContext'

export const useActivityUtils = () => {
  const {
    daysInMonth,
    holidayAndWeekendDays,
  } = useTimesheetStateValue()

  const numberOfDays = daysInMonth.length

  const inputFillingType = (inputDays, day, previousDay, nextDay) => {
    if (!inputDays) return ''
    if (!inputDays.includes(day)) return ''
    if (inputDays.includes(day) && inputDays.includes(previousDay) && inputDays.includes(nextDay)) return 'fill between'
    if (inputDays.includes(day) && inputDays.includes(previousDay)) return 'fill last'
    if (inputDays.includes(day) && inputDays.includes(nextDay)) return 'fill first'
    return 'fill'
  }

  const isHolidayOrFilled = (inputDays, day, index) => {
    const isHoliday = holidayAndWeekendDays.includes(day) ? 'holiday' : null
    const isFilled = inputFillingType(inputDays, day, daysInMonth[index - 1], daysInMonth[index + 1])
    return [isHoliday, isFilled].join(' ')
  }

  return {
    daysInMonth,
    numberOfDays,
    holidayAndWeekendDays,
    inputFillingType,
    isHolidayOrFilled,
  }
}
