import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import i18nIntervalPlural from 'i18next-intervalplural-postprocessor'
import i18nQueryDetector from './i18nQueryDetector'
import i18nSupportedLocales from './i18nSupportedLocales'

export default i18n
  .use(i18nIntervalPlural)
  .use(initReactI18next)
  .use(i18nQueryDetector)
  .init({
    resources: i18nSupportedLocales,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      fallback: 'fr',
      supported: Object.keys(i18nSupportedLocales),
    },
  })

export const getCurrentLocaleIndex = () => i18n.language

export const getCurrentLocale = () => i18nSupportedLocales[getCurrentLocaleIndex()]
