import styled from 'styled-components'

export default styled.div`
  height: 100%;

  >.loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`
