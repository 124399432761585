import styled from 'styled-components'

export const StyledMonthRangePickerSimplified = styled.div`
display: flex;
flex-flow: row nowrap;
align-items: center;
justify-content: stretch;

  .select-prefix.capitalize {
    text-transform: capitalize;
  }
`
