import React from 'react'
import { StyledBurgerBtn } from './BurgerBtn.styled'

const BurgerMenuBtn = ({ open, setOpen }) => (
  <StyledBurgerBtn
    aria-label="Toggle menu"
    aria-expanded={open}
    open={open}
    onClick={setOpen}
  >
    <div>
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
    </div>
  </StyledBurgerBtn>
)

export default BurgerMenuBtn
