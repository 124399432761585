import styled from 'styled-components'

export const ProjectServiceStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.1rem;
  align-items: flex-end;
  justify-content: space-between;
  width: 60rem;

  .service-content-left {
    display: flex;
    align-items: start;
    width: 100%;

    .disabled-input {
      color: ${({ theme }) => theme.colors.betterway.primary[70]};
    }
  }

  .value.eds-input {
    .title {
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      font-weight: 300;
      font-size: 1.6rem;
    }

    input {
      width: 28.5rem;
      font-size: 1.6rem;
      font-family: 'Outfit', sans-serif;

      &:disabled {
        border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
        background-color: ${({ theme }) => theme.colors.betterway.primary[20]};
        color: ${({ theme }) => theme.colors.betterway.primary[70]};
      }

      &:not(:disabled) {
        border-color: ${({ theme }) => theme.colors.betterway.primary[100]};

        &:hover {
          border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
        }
      }
    }
  }

  .input-group {
    display: flex;
    align-items: center;
    width: 31rem;

    i {
      position: relative;
      top: 1rem;
      right: 10%;
    }
  }

  .create.eds-button {
    position: relative;
    z-index: 0;

    button {
      position: relative;
      border-width: 0.2rem;
      border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
      background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
      color: ${({ theme }) => theme.colors.betterway.white};
      font-weight: 600;
      font-size: 1.6rem;
      cursor: pointer;

      ::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        border-radius: 2.3rem;
        background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
        transition-timing-function: ease-in-out;
        transition-duration: 0.1s;
        transition-property: top, right, left, bottom;
      }

      &:hover {
        border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
        background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
        color: ${({ theme }) => theme.colors.betterway.white};

        ::before {
          background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
        }
      }

      &:focus,
      &:focus-visible {
        border-color: ${({ theme }) => theme.colors.betterway.white};
        color: ${({ theme }) => theme.colors.betterway.white};

        ::before {
          top: -0.4rem;
          right: -0.4rem;
          bottom: -0.4rem;
          left: -0.4rem;
        }
      }

      &:disabled {
        border-color: ${({ theme }) => theme.colors.betterway.primary[50]};
        background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
        cursor: not-allowed;
      }
    }
  }

  .edit.eds-button {
    button {
      border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
      background-color: ${({ theme }) => theme.colors.betterway.secondary[20]};
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      font-weight: 600;
      font-size: 1.6rem;
      cursor: pointer;

      .picto {
        margin-left: 0.8rem;
      }
    }
  }
`
