import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@produits-internes-oss/design-system-components'
import { ProjectModalStaffingForm } from '../ProjectModalStaffingForm/ProjectModalStaffingForm'
import { ProjectStaffingFormHandlerStyled } from './ProjectStaffingFormHandler.styled'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import pictoNavigateCross from '../../../assets/images/iconsNavigateCross.svg'
import { useJQuery } from '../../hooks/useJQuery/useJQuery'

export const ProjectStaffingFormHandler = ({ turnoverTypeSelect, valueInitial }) => {
  const { publish } = useJQuery()
  const [openModalAddStaffing, setOpenModalAddStaffing] = useState(false)
  const { t } = useTranslation()
  const {
    setModalAddStaffing,
    setTurnoverTypeForm,
    setReloadForm,
    formDefaultValue,
    turnoverTypeForm,
    isProjectPresales,
    setExpenseIdToUpdate,
    contextSalesLoading,
    setInlineStaffingFormVisible,
  } = useProjectSalesContext()

  const ModalAddStaffing = useCallback(() => (
    <div className="empty-background-modal staffing-modal">
      <div className="container-modal">
        <div className="header-modal">
          <div className="title">
            { (valueInitial?.id || valueInitial?.expenseId) ? t('project.staffing.update_activity_title') : t('project.staffing.add_activity_title')}
          </div>
          <div
            className="picto-close"
            role="button"
            onClick={() => {
              setOpenModalAddStaffing(false)
              setExpenseIdToUpdate(0)
              publish('/octopod/project/update_kpis')
            }}
            onKeyDown={() => {
              setOpenModalAddStaffing(false)
              setExpenseIdToUpdate(0)
            }}
            tabIndex={0}
          >
            <img
              aria-label="link description mission"
              src={pictoNavigateCross}
            />
          </div>
        </div>
        <div className="content-modal">
          <ProjectModalStaffingForm
            onClose={() => {
              setOpenModalAddStaffing(false)
              setExpenseIdToUpdate(0)
              publish('/octopod/project/update_kpis')
            }}
          />
        </div>
      </div>
    </div>
  ), [turnoverTypeForm])

  return (
    <ProjectStaffingFormHandlerStyled>
      { valueInitial?.id || valueInitial?.expenseId
        ? (
          <div className="edit">
            <Button
              className="btn-edit-staffing"
              skin="dark"
              name={t('project.staffing.edit_activity')}
              disabled={isProjectPresales() || contextSalesLoading}
              icon={{ url: 'edit', position: 'right' }}
              onClick={() => {
                setTurnoverTypeForm(turnoverTypeSelect)
                formDefaultValue(turnoverTypeSelect, valueInitial)
                setModalAddStaffing(true)
                setOpenModalAddStaffing(true)
                setReloadForm(true)
                if (turnoverTypeSelect === 'subcontracting' || turnoverTypeSelect === 'intragroup') {
                  setExpenseIdToUpdate(valueInitial.expenseId)
                }
              }}
            />
          </div>
          ) : (
            <Button
              className="add"
              skin="dark"
              name={t('project.staffing.add_activity')}
              text={t('project.staffing.add_activity')}
              onClick={() => {
                setTurnoverTypeForm(turnoverTypeSelect)
                setReloadForm(true)
                formDefaultValue(turnoverTypeSelect)
                setInlineStaffingFormVisible(true)
              }}
            />
          )}
      {
        openModalAddStaffing && (
          <div>
            <ModalAddStaffing
              title={t('project.modal-data-infos.title.mission-comment')}
              closeModal={setOpenModalAddStaffing}
            />
          </div>
        )
      }
    </ProjectStaffingFormHandlerStyled>
  )
}
