import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { ActivityOverviewForm, ActivityOverviewTable } from '../../components'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import { StateProvider } from '../../state'
import {
  currentMonthFirstDayTimestamp,
  monthList,
  monthLastDayTimestamp,
  yearQuarters,
} from '../../helpers/datePickerHelper'

const ActivityOverviewScreen = () => {
  const { t } = useTranslation()
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const currentMonthLastDay = monthLastDayTimestamp(new Date(currentMonthFirstDayTimestamp()))
  const financialYearStartMonth = monthList.indexOf('september')
  const initialState = {
    range: {
      startDate: currentMonthFirstDayTimestamp(),
      endDate: currentMonthLastDay,
    },
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case 'changeRange':
        return {
          ...state,
          range: action.range,
        }
      default:
        return state
    }
  }

  const preparePresetRange = (year) => {
    const ranges = yearQuarters(year, financialYearStartMonth)
    const title = t('activityOverview.financial_year')

    return { title, ranges }
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <StateProvider initialState={initialState} reducer={reducer}>
        <ActivityOverviewForm
          setData={setData}
          setLoading={setLoading}
          presetRange={preparePresetRange}
        />
        <ActivityOverviewTable loading={loading} data={data} />
      </StateProvider>
    </ThemeProvider>
  )
}

export default ActivityOverviewScreen
