import React, { useState } from 'react'
import { StyledTimesheetTable } from './TimesheetTable.styled'
import ProjectPickerModal from '../TimesheetModals/ProjectPickerModal/ProjectPickerModal'
import TimesheetHeader from './TimesheetHeader/TimesheetHeader'
import TimesheetBody from './TimesheetBody/TimesheetBody'
import TimesheetFooter from './TimesheetFooter/TimesheetFooter'
import Anomalies from './Anomalies/Anomalies'
import BlockingErrors from './BlockingErrors/BlockingErrors'

import { TimeinputsContextProvider } from '../../../context/Timesheet/TimeinputsContext'
import { useTimesheetStateValue } from '../../../context/Timesheet/TimesheetStateContext'
import { ActivityContextProvider } from '../../../context/Timesheet/ActivityContext'
import { ActivityRateProvider } from '../../../context/Timesheet/ActivityRateContext'
import { PeriodStateProvider } from '../../../context/Timesheet/PeriodStateContext'
import { DateProvider } from '../../../context/Timesheet/DateContext'
import { TimesheetErrorsProvider } from '../../../context/Timesheet/TimesheetErrorsContext'

const TimesheetTable = () => {
  const {
    activities, inputsByActivityThenDay, daysInMonth, periods,
  } = useTimesheetStateValue()
  const [openProjectPickerModal, setOpenProjectPickerModal] = useState(false)

  const openProjectPickerModalHandler = () => {
    setOpenProjectPickerModal(true)
    document.body.style.overflow = 'hidden'
  }

  const closeBackdropHandler = () => {
    setOpenProjectPickerModal(false)
    document.body.style.overflow = 'auto'
  }

  return (
    <>
      <ActivityContextProvider initialActivities={activities}>
        <StyledTimesheetTable>
          <TimeinputsContextProvider initialState={inputsByActivityThenDay}>
            <ActivityRateProvider>
              <PeriodStateProvider initialFirstPeriodStatus={periods[0]?.status} initialSecondPeriodStatus={periods[1]?.status}>
                <DateProvider>
                  <TimesheetErrorsProvider>
                    <TimesheetHeader openProjectPickerModal={openProjectPickerModalHandler} />
                    <TimesheetBody />
                    <BlockingErrors nbDays={daysInMonth.length} />
                    <Anomalies nbDays={daysInMonth.length} />
                    <TimesheetFooter nbDays={daysInMonth.length} />
                  </TimesheetErrorsProvider>
                </DateProvider>
              </PeriodStateProvider>
            </ActivityRateProvider>
          </TimeinputsContextProvider>
        </StyledTimesheetTable>
        <ProjectPickerModal
          open={openProjectPickerModal}
          closeModal={() => closeBackdropHandler()}
        />
      </ActivityContextProvider>
    </>
  )
}

export default TimesheetTable
