import React, { useState, useContext, createContext, useEffect } from 'react'
import { useFetch } from '../../fetchOverviewApi'
import { useJQuery } from '../../hooks/useJQuery/useJQuery'

const ProjectQualityContext = createContext()

export const ProjectQualityContextProvider = ({ children, id }) => {
  const { subscribe } = useJQuery()
  const [errors, setErrors] = useState([])
  const [project, setProject] = useState(null)

  const fetchGetProject = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProject(response.project)
    }
  }
  const fetchGetKPIs = async () => {
    await fetchGetProject()
  }

  useEffect(() => {
    subscribe('/octopod/tabchange', () => fetchGetKPIs(), 0)

    fetchGetKPIs()
  }, [])

  return (
    <ProjectQualityContext.Provider value={{
      errors,
      project,
    }}
    >
      {children}
    </ProjectQualityContext.Provider>
  )
}

export const useProjectQualityContext = () => useContext(ProjectQualityContext)
