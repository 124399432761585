import React, { useState } from 'react'
import { Button, InputText, InputTextarea } from '@produits-internes-oss/design-system-components'
import { useTranslation } from 'react-i18next'
import { ProjectContractReferenceStyled } from './ProjectContractReference.styled'
import { useProjectContractContext } from '../../context/ProjectContract/ProjectContractContext'
import pictoModify from '../../../assets/images/iconsModify.svg'

export const ProjectContractReference = () => {
  const [modify, setModify] = useState(false)
  const { t } = useTranslation()
  const {
    project,
    updateReferenceContract,
    fetchUpdateContract,
    defaultReferenceContract,
  } = useProjectContractContext()

  if (project === null) {
    return (
      <ProjectContractReferenceStyled title={{ text: t(`project.contract.contract_timeline.contract.title`), level: 2 }}>
        ...
      </ProjectContractReferenceStyled>
    )
  }

  return (
    <ProjectContractReferenceStyled title={{ text: t(`project.contract.contract_timeline.contract.title`), level: 2 }}>
      <div>
        {!modify && (
          <div className="modify">
            <Button
              className="btn-modify"
              skin="dark"
              name={t(`project.contract.contract_timeline.contract.modify`)}
              text={t(`project.contract.contract_timeline.contract.modify`)}
              onClick={() => {
                if (project.customer_contract_reference) updateReferenceContract('reference', project.customer_contract_reference)
                if (project.customer_contract_comment) updateReferenceContract('comment', project.customer_contract_comment)
                setModify(true)
              }}
              icon={{ url: pictoModify, position: 'right' }}
            />
          </div>
        )}
        { modify
          ? (
            <div className="content-modify">
              <div className="form">
                <InputText
                  className="form-ref"
                  skin="light"
                  name={t(`project.contract.contract_timeline.contract.reference`)}
                  title={t(`project.contract.contract_timeline.contract.reference`)}
                  valueInitial={project.customer_contract_reference ? project.customer_contract_reference : null}
                  required={false}
                  onChange={(value) => updateReferenceContract('reference', value)}
                />
                <div className="text-area">
                  <InputTextarea
                    className="form-comment"
                    skin="light"
                    name={t(`project.contract.contract_timeline.contract.comment`)}
                    title={t(`project.contract.contract_timeline.contract.comment`)}
                    valueInitial={project.customer_contract_comment ? project.customer_contract_comment : null}
                    maxLength={255}
                    required={false}
                    onChange={(value) => updateReferenceContract('comment', value)}
                  />
                  <span>{t(`project.contract.contract_timeline.contract.comment_max`)}</span>
                </div>
              </div>

              <div className="command">
                <Button
                  className="cancel"
                  skin="light"
                  kind="secondary"
                  name={t(`project.contract.contract_timeline.contract.cancel`)}
                  text={t(`project.contract.contract_timeline.contract.cancel`)}
                  onClick={() => {
                    defaultReferenceContract()
                    setModify(false)
                  }}
                />
                <Button
                  className="save"
                  skin="dark"
                  name={t(`project.contract.contract_timeline.contract.save`)}
                  text={project.customer_contract_reference ? t(`project.contract.contract_timeline.contract.save`) : t(`project.contract.contract_timeline.contract.add`)}
                  onClick={() => {
                    fetchUpdateContract()
                    setModify(false)
                  }}
                />
              </div>

            </div>
          )
          : (
            <div className="content">
              <div className="reference">
                <em>
                  {t(`project.contract.contract_timeline.contract.reference`)}
                  {' '}
                  :
                </em>
                <em className="text">{project.customer_contract_reference ? project.customer_contract_reference : '-'}</em>
              </div>
              <div className="comment">
                <em>
                  {t(`project.contract.contract_timeline.contract.comment`)}
                  {' '}
                  :
                </em>
                <em className="text">{project.customer_contract_comment ? project.customer_contract_comment : '-'}</em>
              </div>
            </div>
          )}
      </div>
    </ProjectContractReferenceStyled>
  )
}
