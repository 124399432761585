import styled from 'styled-components'

export const PeriodPickerStyled = styled.div`
position: relative;
width: auto;

.title {
  margin-bottom: 0.3rem;
  color: ${({ theme }) => theme.colors.betterway.primary[70]};
  font-weight: 400;
  font-style: normal;
  font-size: 1.7rem;
}

.buttons-period-picker {
  display: flex;
  align-items: center;
  height: 3.65rem;
  border: 1px solid ${({ theme }) => theme.colors.betterway.primaryBlue};
  border-radius: 2.1rem;

  .navigation-period-button-prev {
    margin-left: 2rem;
    transform: scaleX(-1);
  }

  .navigation-period-button-next {
    margin-right: 2rem;
  }

  .trigger {
    height: 3rem;
  }
}

button {
  display: flex;
  justify-content: center;
  border: none;
  color: ${({ theme }) => theme.colors.betterway.primary[100]};
  text-decoration: underline;
}

.navigation-popup-button {
  background-color: ${({ theme }) => theme.colors.betterway.white};
}

.period-picker-popup {
  position: absolute;
  top: 100%;
  left: 50%;
  display: flex;
  align-items: center;
  height: 19rem;
  padding: 1rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.betterway.white};
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.colors.shadow};
  transform: translateX(-50%);
}

.block {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 18rem;
  margin: 2rem;
  padding: 1rem;
  padding: 1.5rem;
  border: solid 0.1rem ${({ theme }) => theme.colors.betterway.primary[100]};
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.betterway.white};

  .block-title {
    text-transform: capitalize;
  }

  button {
    margin: 1rem 0;
    border: solid 0.1rem ${({ theme }) => theme.colors.betterway.primary[100]};
    border-radius: 2.1rem;
    font-weight: bold;
    text-decoration: none;
  }

  .current-period-button {
    button {
      background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
      color: ${({ theme }) => theme.colors.betterway.white};
    }
  }
}
`
