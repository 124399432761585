import styled from 'styled-components'
import shapeArrow from '../../../../../assets/images/arrow.svg'

export const TooltipStyled = styled.div`
  position: relative;

  >.popup-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.betterway.overlayLight};
    opacity: ${(props) => (props.isOpened ? '100%' : '0%')};
    pointer-events: ${(props) => (props.isOpened ? 'auto' : 'none')};
    user-select: none;
    transition: opacity 0.2s ease-in-out;
  }

  >.popup-tooltip {
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 40rem;
    padding: 2.4rem;
    border-radius: 2rem;
    background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
    color: ${({ theme }) => theme.colors.betterway.white};
    transition: opacity 0.2s ease-in-out;
    transform: translateX(calc(-100% - 2rem)) translateY(calc(-50%));

    &.opened {
      opacity: 100%;
      pointer-events: auto;
      user-select: auto;
    }

    &.closed {
      opacity: 0%;
      pointer-events: none;
      user-select: none;
    }

    ::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 2rem;
      height: 2rem;
      background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
      transform: translateX(100%) translateY(-50%);
      mask-image: url(${shapeArrow});
      mask-repeat: no-repeat;
      mask-position-y: center;
    }

    >.title {
      margin-bottom: 1.6rem;
      font-weight: 600;
      font-size: 1.8rem;
    }
  }
`
