import { Modal } from '@produits-internes-oss/design-system-components'
import styled from 'styled-components'

export const ProjectDataHatModalStyled = styled(Modal)`
&.octo.hard {
  >.popup-overlay {
    z-index: 1000;
  }

  >.popup-tooltip {
    z-index: 1000;

    >.modal {
      >.header {
        button {
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
        }
      }

      >.content {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: stretch;
        overflow-y: visible;
        box-sizing: border-box;
        height: fit-content;
        min-height: 15rem;

        >.text {
          flex: 1;
        }

        >.controls {
          display: flex;
          flex-flow: row nowrap;
          gap: 1.6rem;
          align-items: center;
          justify-content: flex-end;

          >.control.secondary {
            button {
              span {
                color: ${({ theme }) => theme.colors.betterway.primary[100]};
              }
            }

            &:hover {
              button {
                span {
                  color: ${({ theme }) => theme.colors.betterway.white};
                }
              }
            }
          }
        }
      }

      >.footer {
        margin: 0;
      }
    }
  }
}
`
