import styled from 'styled-components'

export const ProjectPaymentScheduleLinesStyled = styled.div`
  &.octo {
    .payment-schedule-line-creation {
      .info-section {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.4rem;
      }

      .percentage-warning {
        display: flex;
        gap: 0.8rem;
        margin-top: 1.6rem;
        padding: 1.6rem;
        border-width: 0.1rem;
        border-style: solid;
        border-color: ${({ theme }) => theme.colors.betterway.red[100]};
        border-radius: 0.3rem;
        background-color: ${({ theme }) => theme.colors.betterway.red[5]};

        img {
          height: 2.4rem;
        }
      }

      p b {
        font-weight: 600;
      }
    }

    .modal-payment-schedule-line {
      .payment-schedule-line-form {
        .form-top-section {
          display: flex;
          gap: 1.6rem;

          .amount,
          .forecast-date,
          .billing-date {
            max-width: 30rem;
          }

          .price-tag {
            position: relative;

            &::after {
              content: '€';
              position: absolute;
              top: 3.6rem;
              right: 1.5rem;
            }
          }

          .percentage {
            width: 22.8rem;

            input {
              font-size: 1.6rem;
              font-family: 'Outfit', sans-serif;

              &:disabled {
                border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
                background-color: ${({ theme }) => theme.colors.betterway.primary[20]};
                color: ${({ theme }) => theme.colors.betterway.primary[70]};
                cursor: not-allowed;
              }
            }
          }
        }

        .form-bottom-section {
          margin-top: 2.4rem;

          .condition {
            width: 61.6rem;
          }

          .condition-limit {
            display: flex;
            margin-top: 1rem;

            img {
              width: 1.67rem;
              height: 1.67rem;
              margin-right: 0.4rem;
              margin-left: 0.8rem;
            }

            p {
              font-weight: 600;
              font-size: 1.2rem;
            }
          }
        }
      }

      .modal {
        min-width: 1224px;

        .content {
          width: auto !important;
        }

        .header {
          .close {
            display: none !important;
          }
        }

        .footer {
          margin-top: -28px !important;
        }
      }
    }

    .payment-schedule-lines-table {
      margin-top: 1.4rem;

      .actions {
        display: flex;
        gap: 2.6rem;
        max-width: 10rem;

        .hard.opened > .popup-tooltip {
          .tooptip-delete-content {
            width: 44.7rem;
            height: auto;

            .header {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .title {
                font-weight: 600;
                font-size: 1.8rem;
              }

              button {
                cursor: pointer;

                img {
                  width: 2.2rem;
                  height: 2.2rem;
                }
              }
            }

            .body {
              margin-top: 1.6rem;
              margin-bottom: 1.6rem;

              p {
                margin-bottom: 2px;
                font-weight: 300;
                font-size: 1.4rem;

                .text-bold {
                  font-weight: 600;
                }
              }
            }

            .footer {
              display: flex;
              justify-content: flex-end;

              .cancel {
                max-width: 11.3rem;
                margin-right: 1.6rem;
              }

              .submit {
                max-width: 24.6rem;
              }
            }
          }
        }
      }

      .btn-modify,
      .btn-destroy {
        button {
          display: flex !important;
          width: auto !important;
          height: auto !important;
          padding: 0;
          border: none !important;
          background-color: transparent !important;
          color: ${({ theme }) => theme.colors.primary} !important;
          font-weight: normal !important;
          font-size: 1.4rem !important;
          text-decoration: underline !important;

          &:hover {
            border: none !important;
            background-color: transparent !important;
          }
        }
      }
    }

    .alerts {
      .payment-date-conformity {
        width: auto;
        height: auto;
        padding: 1.6rem;
        border-width: 0.1rem;
        border-style: solid;
        border-color: ${({ theme }) => theme.colors.betterway.red[100]};
        border-radius: 0.3rem;
        background-color: ${({ theme }) => theme.colors.betterway.red[5]};

        .top-section {
          display: flex;
          justify-content: space-between;

          p {
            color: ${({ theme }) => theme.colors.betterway.primary[100]};

            span {
              font-weight: 600;
            }
          }
        }

        .btn-modify-section {
          display: flex;
          justify-content: flex-end;
          margin-top: 2rem;

          img {
            width: 24rem;
            height: 24rem;
            cursor: pointer;
          }
        }
      }

      .new-line-alert {
        width: auto;
        height: auto;
        margin-top: 2.4rem;
        margin-bottom: 2.4rem;
        padding: 1.6rem;
        border-width: 0.1rem;
        border-style: solid;
        border-color: ${({ theme }) => theme.colors.betterway.green[100]};
        border-radius: 0.3rem;
        background-color: ${({ theme }) => theme.colors.betterway.green[5]};

        p {
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          font-weight: 600;
        }
      }
    }
  }

  .octo.hard.opened > .popup-tooltip {
    .tooltip-delete-context {
      height: auto;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tittle {
          font-size: 1.8rem;
        }

        img {
          width: 2.4rem;
          height: 2.4rem;
        }
      }

      .footer {
        display: flex;
        justify-content: flex-end;

        .cancel {
          max-width: 11.3rem;
          margin-right: 1.6rem;
        }

        .submit {
          max-width: 24.6rem;
        }
      }
    }
  }

  .octo.hard > table {
    table-layout: fixed;

    > thead {
      >tr {
        > th:nth-child(3) {
          width: 33rem;
        }

        > th:last-child {
          width: 25rem;
        }
      }
    }

    > tbody {
      > tr > td:nth-child(3) {
        word-wrap: break-word;
      }

      tr:has(td .cell .flash) {
        animation-name: color-change;
        animation-duration: 5s;
        animation-iteration-count: initial;
      }

      tr {
        >td {
          &[aria-label*='amount'],
          &[aria-label*='percentage'] {
            text-align: right;
          }
        }
      }
    }
  }

  .copy {
    width: fit-content;
    margin-top: 1.4rem;
    margin-bottom: 1rem;

    &.octo.hard.default {
      > button {
        padding: 0;
        border: none;
        background: none;
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
        font-weight: normal;
        font-size: 1.4rem;
        text-decoration: underline;

        &:hover {
          background: none;
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          font-weight: bold;
        }

        &:focus,
        &:focus-visible {
          border: none;
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
        }

        ::before {
          content: none;
        }

        >.icon {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }

  .copy-succeed {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    padding: 1.6rem;
    border: solid 0.2rem ${({ theme }) => theme.colors.betterway.green[100]};
    border-radius: 0.3rem;
    background-color: ${({ theme }) => theme.colors.betterway.green[5]};
  }

  @keyframes color-change {
    from {
      background-color: ${({ theme }) => theme.colors.betterway.green[20]};
    }

    to {
      background-color: none;
    }
  }
`
