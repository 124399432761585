import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSynchronizationContext } from '../../../context/Synchronization/SynchronizationContext'
import { SynchronizationErrorsStyled } from './SynchronizationErrors.styled'

export const SynchronizationErrors = ({ className }) => {
  const { t } = useTranslation()
  const { progression } = useSynchronizationContext()

  const getMessageFromStatus = (id, status, target, type) => {
    const resource = t(`synchronization.errors.type.${type}`, { target })
    switch (status) {
      case 'closed wbs': return `${t('synchronization.errors.status.closed_wbs')} ${resource}`
      case 'missing wbs': return `${t('synchronization.errors.status.missing_wbs')} ${resource}`
      case 'not on authorization list': return `${t('synchronization.errors.status.not_on_authorization_list')} ${resource}`
      case 'unauthorized wbs': return `${t('synchronization.errors.status.unauthorized_wbs')} ${resource}`
      default: return t('synchronization.errors.status.default', { id })
    }
  }

  return progression && progression.errors
    ? (
      <SynchronizationErrorsStyled className={className}>
        <div className="title">
          <span className="warning" />
          <span>{t('synchronization.errors.title', { postProcess: 'interval', count: progression?.errors?.length })}</span>
        </div>
        <div className="list">
          <div className="list-scroll">
            {
                progression.errors.map(({ id, person, resource, status }) => (
                  <div key={`error-${id}`} className="error">
                    <div className="error-person">
                      <div className="error-person-name">{`${person.first_name} ${person.last_name}`}</div>
                      <a className="error-person-link" href={`/timesheet/${person.nickname}`} target="_blank" rel="noreferrer">
                        <span className="error-person-link-nickname">{person.nickname}</span>
                        <span className="error-person-link-icon" />
                      </a>
                    </div>
                    <div className="error-status">{getMessageFromStatus(id, status, resource.id, resource.type)}</div>
                  </div>
                ))
              }
          </div>
        </div>
      </SynchronizationErrorsStyled>
    )
    : null
}
