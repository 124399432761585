import styled from 'styled-components'

export const ProjectStaffingFormHandlerStyled = styled.div`
  .edit {
    display: flex;
    height: 0;

    .btn-edit-staffing {
      display: flex;
      align-items: center;

      button {
        display: flex !important;
        width: auto !important;
        height: auto !important;
        padding: 0;
        border: none !important;
        background-color: transparent !important;
        color: ${({ theme }) => theme.colors.primary} !important;
        font-weight: normal !important;
        font-size: 1.4rem !important;
        text-decoration: underline !important;

        &:hover {
          border: none !important;
          background-color: transparent !important;
        }
      }
    }
  }

  .edit.staffing > button {
    margin: auto;
    cursor: pointer;

    &:hover {
      background-color: unset;
    }
  }

  .empty-background-modal,
  div.staffing-modal {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 3000 !important;
    width: 100vw !important;
    height: 100vh !important;
    background-color: #00000061 !important;

    >div.container-modal {
      position: fixed;
      top: 2%;
      left: 50%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 75%;
      height: auto;
      padding: 3.2rem 4rem;
      border-radius: 20px;
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      box-shadow: 0 4px 8px 0 ${({ theme }) => theme.colors.shadow};
      transform: translateX(-50%);

      >div.header-modal {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 2.4rem;

        .title {
          font-weight: 600;
          font-size: 1.8rem;
        }

        .picto-close {
          cursor: pointer;
        }
      }

      .content-modal {
        width: 100%;
        text-align: left;
      }
    }
  }

`
