import styled from 'styled-components'

export const ReleaseNoteStyled = styled.div`
  div.page-content {
    h2.title {
      margin-bottom: 2rem;
    }

    div.release-note-container {
      margin-top: 2rem;
      margin-bottom: 4rem;

      h3.release-note-subtitle {
        display: inline-flex;
        align-items: baseline;
        margin-bottom: 1rem;

        >div.octo.hard {
          &.application-name,
          &.epic-name {
            margin-left: 1rem;
          }
        }
      }

      div.release-note-text {
        margin-left: 2rem;
        font-size: 1.6rem;
      }
    }
  }
`
