import styled from 'styled-components'

export const StyledSubcontractorsOverviewTable = styled.div`
  
  margin: 0 auto;
  margin-top: 6rem;
  table {
    width: 100%;
    border-collapse: separate;
    text-align: center;
    table-layout: fixed;

    td {
      padding: 0.5rem;
      border-right: 1px solid ${({ theme }) => theme.colors.lightGrey};
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
    }

    td:first-child {
      border-left: 1px solid ${({ theme }) => theme.colors.lightGrey};
    }

    a {
      cursor: pointer
    }
  }
`
