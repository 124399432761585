import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '@produits-internes-oss/design-system-components'
import { useProjectContractContext } from '../../context/ProjectContract/ProjectContractContext'
import { getNumberAsPercentage } from '../../helpers/stringHelper'

export const ProjectStatusChangeHistoricTable = () => {
  const { t } = useTranslation()
  const { project, projectStatusLog } = useProjectContractContext()

  const history = project?.['status_logs']

  const createColumns = useCallback(() => {
    const columns = [
      {
        name: 'status',
        title: t(`projectHistory.status`),
        onRender: ({ item }) => t(`project.status.${item['status']}`) || '',
      },
      {
        name: 'date',
        title: t(`projectHistory.date`),
        onRender: ({ item }) => item['date'] || '',
      },
      {
        name: 'mop',
        title: t(`projectHistory.margin`),
        onRender: ({ item }) => getNumberAsPercentage(item['mop']?.['propale_operating_marge']) || '',
      },
      {
        name: 'person',
        title: t(`projectHistory.modify`),
        onRender: ({ item }) => item['person'] || '',
      },
    ]
    return columns
  }, [])

  const createRows = useCallback(() => {
    if (projectStatusLog === null || history === null) {
      return {}
    }
    const rows = {}

    history.forEach((historic, index) => {
      const date = new Date(historic.date).toLocaleDateString()
      rows[historic.id] = {
        status: historic.status,
        mop: projectStatusLog[index],
        date,
        person: historic.person ? (`${historic.person['first_name']}  ${historic.person['last_name']} / ${historic.person['nickname']}`) : '',
      }
    })

    return rows
  }, [projectStatusLog, history])

  if (project === null || projectStatusLog === null) {
    return <div>...</div>
  }

  return (
    <Table columns={createColumns()} data={createRows()} />
  )
}
