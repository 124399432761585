import React from 'react'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import { ProjectFormContextProvider } from '../../context/ProjectForm/ProjectFormContext'
import { ProjectForm } from '../../components/ProjectForm/ProjectForm'

export const ProjectFormScreen = ({ id, idToDuplicate, person }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <ProjectFormContextProvider id={id} idToDuplicate={idToDuplicate} currentPerson={person}>
      <ProjectForm />
    </ProjectFormContextProvider>
  </ThemeProvider>
)
