import styled from 'styled-components'
import { ProjectFormFragment } from '../ProjectFormFragment/ProjectFormFragment'

export const ProjectFormLeadStyled = styled(ProjectFormFragment)`
  .form-row {
    display: flex;
    flex-direction: row;
    gap: 3.2rem;

    .form-control {
      ul {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .octo.hard.amount {
    width: 28rem;
  }

  p.tip {
    font-size: 1.4rem;
  }

  .hidden-button {
    position: relative;
    display: flex;
    gap: 0.8rem;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    width: auto;
    width: 20rem;
    height: 3.8rem;
    padding: 0 2.4rem;
    border-width: 0.1rem;
    border-style: solid;
    border-radius: 2rem;
    background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
    color: ${({ theme }) => theme.colors.betterway.white};
    font-weight: 600;
    opacity: 0%;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  .container {
    width: auto;
    width: 20rem;
    border-radius: 2rem;
  }

  .container:focus-within .hidden-button {
    opacity: 100%;
    visibility: visible;
    pointer-events: auto;
  }

  .hidden-button:focus {
    width: auto;
    border-width: 0.3rem;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
  }

  .hidden-button:disabled {
    border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
    background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
    color: ${({ theme }) => theme.colors.betterway.white};
    cursor: not-allowed;
  }

`
