import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { StyledActivityInfo } from './ActivityInfo.styled'
import { repositionElement } from '../../../../../../helpers/repositionElementInScreen'
import Button from '../../../../../input/Buttons/Button/Button'
import { useActivityValues } from '../../../../../../context/Timesheet/ActivityContext'

const ActivityInfo = ({ activityState, toggleActivityPopin }) => {
  const { t, i18n } = useTranslation()
  moment.locale(`${i18n.language}`)
  const [openWarningBox, setOpenWarningBox] = useState(false)
  const {
    activity, project, customerName, consumedDays, notDeletable,
  } = activityState
  const { hideActivity } = useActivityValues()

  const consumptionRate = activity.nb_days === '0.0'
    ? `${t('activity.popin.noDaysAllocated')}`
    : `${Math.round((consumedDays / activity.nb_days) * 100)}% ${t('activity.popin.consumed')}`

  return (
    <StyledActivityInfo notDeletable={notDeletable}>
      <h4>{t('activity.popin.myProject')}</h4>
      <div className="activity-title">
        {
          project.kind === 'internal'
            ? <a href={`/projects/internal/${project.id}/?locale=${i18n.language}`} target="_blank" rel="noreferrer">{activity.full_title}</a>
            : <a href={`/projects/billable/${project.id}/?locale=${i18n.language}`} target="_blank" rel="noreferrer">{activity.full_title}</a>
        }
      </div>
      <div className="project-dates">
        <p>
          {`#${project.reference} - ${t('days.From')} ${moment(project.start_date).format('L')} 
            ${t('days.to')} ${moment(project.end_date).format('L')}`}
        </p>
      </div>
      <div className="row-info">
        <div className="info">
          <span className="icon">
            <i className="fa fa-building" />
          </span>
          <p>{customerName || t('notKnown')}</p>
        </div>
        <div className="info">
          <span className="icon">
            <i className="fa fa-user" />
          </span>
          <p>{project.decision_maker || t('notKnown')}</p>
        </div>
      </div>
      <div className="row-info">
        <div className="info">
          <span className="icon">
            <i className="fa fa-briefcase" />
          </span>
          <p>{activity.title || t('notKnown')}</p>
        </div>
        <div className="info">
          <span className="icon">
            <i className="fa fa-hourglass-half" />
          </span>
          <p>{consumptionRate}</p>
        </div>
      </div>
      <div className="info delete-activity">
        <span className="icon">
          <i className="fa fa-trash" />
        </span>
        <Button
          disabled={notDeletable}
          color="blockingError"
          type="text bold"
          click={() => setOpenWarningBox(!openWarningBox)}
        >
          {t('activity.popin.hide')}
        </Button>
      </div>
      {
        openWarningBox
          ? (
            <DeleteWarningBox
              hideBox={() => setOpenWarningBox(!openWarningBox)}
              deleteActivity={() => {
                toggleActivityPopin()
                hideActivity(activity)
              }}
            />
          )
          : null
      }
    </StyledActivityInfo>
  )
}

const DeleteWarningBox = ({ hideBox, deleteActivity }) => {
  const { t } = useTranslation()
  const ref = useRef()

  useEffect(() => {
    repositionElement(ref.current, 'end')
  }, [])

  return (
    <div className="delete-warning" role="contentinfo" ref={ref}>
      <p>{t('activity.popin.deletionWarning')}</p>
      <Button
        type="button"
        color="blockingError"
        click={deleteActivity}
      >
        {t('activity.popin.hideConfirm')}
      </Button>
      <Button
        type="text"
        color="primaryGreyDark"
        click={hideBox}
      >
        {t('activity.popin.hideCancel')}
      </Button>
    </div>
  )
}

export default ActivityInfo
