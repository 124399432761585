import React, {
  useState, useEffect, useContext, createContext, useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useFetch } from '../../fetchOverviewApi'
import { clean } from '../../helpers/stringHelper'
import { Expense } from '../../structures/Expense/Expense'

const ExpensesContext = createContext()

export const ExpensesContextProvider = ({ children, initialProps, canEditAlreadySentExpenses }) => {
  const { t } = useTranslation()

  const options = useRef(null)

  const [date, setDate] = useState({ year: initialProps.year, month: initialProps.month, period: initialProps.period })
  const [person, setPerson] = useState(initialProps.person)
  const [expenses, setExpenses] = useState([])
  const [dateOfLastTeleworkSetupExpense, setDateOfLastTeleworkSetupExpense] = useState(null)
  const [initialType, setInitialType] = useState(null)
  const [totalAmount, setTotalAmount] = useState(0)
  const [isPeriodCurrent, setIsPeriodCurrent] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingValidationForm, setIsLoadingValidationForm] = useState(false)
  const [groupAttendees, setGroupAttendees] = useState([])

  const [expenseSelected, setExpenseSelected] = useState(null)

  const [errors, setErrors] = useState([])

  const hasErrorInReceiptFile = useRef(null)

  const setViewToList = () => setExpenseSelected(null)

  const setViewToCreate = () => setExpenseSelected(new Expense({}))

  const setViewToEdit = (expense) => setExpenseSelected(new Expense(expense))

  const getPersonName = (personObject) => (personObject ? `${personObject['first_name']} ${personObject['last_name'].toUpperCase()} - ${personObject['nickname']}` : '')

  const addToGroupAttendees = (personIdAndNames) => {
    setGroupAttendees((previous) => {
      if (previous.map((personFromPrevious) => personFromPrevious.id).includes(personIdAndNames.id)) {
        return (previous)
      }
      return [...previous, personIdAndNames]
    })
  }

  const removeFromGroupAttendees = (personId) => {
    setGroupAttendees((previous) => {
      if (previous.length === 1) {
        return []
      }

      const indexOfPerson = previous.map((personFromPrevious) => personFromPrevious.id).indexOf(personId)
      previous.splice(indexOfPerson, 1)
      return [...previous]
    })
  }

  const getFormFromExpense = (expense) => {
    const form = new FormData()

    Object.entries({ ...expense, person_id: person.id }).forEach(([key, value]) => {
      if (key === 'receipts') {
        value.forEach(({ id, file }, index) => {
          if (id) {
            form.append(`receipt${index}[id]`, id)
          }

          if (file) {
            form.append(`receipt${index}[file]`, file)
          }
        })
      } else if (value !== null) {
        form.append(key, value)
      }
    })
    return form
  }

  const fetchGetMetadata = async () => {
    const response = await useFetch('GET', '/expenses/metadata')
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      options.current = {
        countries: response.currencies.flatMap((currency) => (currency.countries.map((country) => ({
          name: country.id,
          title: `${currency.code} ${currency.symbol} - ${country.name}`,
          slug: clean(`${currency.code} ${country.name}`),
          currency: currency.symbol,
        })))),
        invoicingKinds: response.invoicing_kinds.map((option) => ({ name: option, title: t(`expenses.categories.invoicing.${option}`) })),
        mealKinds: [{ name: response.meal_kinds[0], title: t('yes') }, { name: response.meal_kinds[1], title: t('no') }],
        projects: response.projects.map((project) => ({
          name: project.id,
          title: project.name,
          slug: clean(project.name),
          isInternal: project.is_internal,
        })),
        reasons: response.reasons.map((option) => ({ name: option, title: t(`expenses.categories.reason.${option}`) })),
        suppliers: {
          hotel: response.suppliers.hotel.map((option) => ({ name: option.id, title: option.name })),
          plane: response.suppliers.plane.map((option) => ({ name: option.id, title: option.name })),
        },
        travelClasses: response.travel_classes.map((travelClass) => ({ name: travelClass, title: t(`expenses.categories.class.${travelClass}`) })),
        types: response.types.map((option) => ({ name: option, title: t(`expenses.categories.type.${option}`) })),
        vat: [{ name: 'yes', title: t('yes') }, { name: 'no', title: t('no') }],
        wbsCategories: response.wbs.map((option) => ({ name: option.id, title: option.name })),
      }
    }
  }

  const fetchGetPersonExpensesForPeriod = async (year, month, period, personID) => {
    const response = await useFetch('GET', `/expenses?person=${personID}&year=${year}&month=${month + 1}&period=${period + 1}`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setDate({ year, month, period })
      setPerson({
        ...response.person,
        label: `${response.person.firstname} ${response.person.lastname.toUpperCase()} - ${response.person.nickname} - ${response.person.lob}`,
      })
      setExpenses(response.expenses.map((expense) => new Expense(expense)))
      setDateOfLastTeleworkSetupExpense(response.date_of_last_telework_setup_expense)
      setTotalAmount(parseFloat(response.total_amount))
      setIsPeriodCurrent(response.is_period_current)
      setInitialType(null)
    }
  }

  const fetchCreateExpense = async (expense) => {
    setIsLoadingValidationForm(true)

    const response = await useFetch('POST', '/expenses', getFormFromExpense(expense), true)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
      hasErrorInReceiptFile.current = true
    } else {
      await fetchGetPersonExpensesForPeriod(date.year, date.month, date.period, person.id)
      setViewToList()
      setGroupAttendees([])
    }

    setIsLoadingValidationForm(false)
  }

  const fetchEditExpense = async (id) => {
    setIsLoading(true)

    const response = await useFetch('GET', `/expenses/${id}/edit`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setViewToEdit(response.expense)
      setInitialType(response.expense.type)
      if (response.expense['people_attendees'] && response.expense['people_attendees'].length > 0) {
        setGroupAttendees((response.expense['people_attendees']).map((personAttendees) => ({
          id: personAttendees.id,
          personName: getPersonName(personAttendees),
        })))
      }
    }

    setIsLoading(false)
  }

  const fetchUpdateExpense = async (expense) => {
    setIsLoadingValidationForm(true)

    const response = await useFetch('PUT', `/expenses/${expense.id}`, getFormFromExpense(expense), true)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
      hasErrorInReceiptFile.current = true
    } else {
      await fetchGetPersonExpensesForPeriod(date.year, date.month, date.period, person.id)
      setViewToList()
      setGroupAttendees([])
    }

    setIsLoadingValidationForm(false)
  }

  const fetchUpdateExpenseStatus = async (expenseId) => {
    const response = await useFetch('PATCH', `/expenses/${expenseId}/toggle_status`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      await fetchGetPersonExpensesForPeriod(date.year, date.month, date.period, person.id)
    }
  }

  const fetchDestroyExpense = async (expenseId) => {
    const response = await useFetch('DELETE', `/expenses/${expenseId}`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      await fetchGetPersonExpensesForPeriod(date.year, date.month, date.period, person.id)
    }
  }

  const prefetch = async () => {
    await fetchGetMetadata()
    await fetchGetPersonExpensesForPeriod(date.year, date.month, date.period, person.id)
    setIsLoading(false)
  }

  useEffect(() => { prefetch() }, [])

  return (
    <ExpensesContext.Provider value={{
      year: date.year,
      month: date.month,
      period: date.period,
      person,
      getPersonName,
      addToGroupAttendees,
      removeFromGroupAttendees,
      groupAttendees,
      setGroupAttendees,
      expenses,
      dateOfLastTeleworkSetupExpense,
      initialType,
      totalAmount,
      isPeriodCurrent,
      expenseSelected,
      options: options.current,
      setViewToList,
      setViewToCreate,
      setViewToEdit,
      errors,
      hasErrorInReceiptFile,
      fetchGetMetadata,
      fetchGetPersonExpensesForPeriod,
      fetchCreateExpense,
      fetchEditExpense,
      fetchUpdateExpense,
      fetchUpdateExpenseStatus,
      fetchDestroyExpense,
      isLoading,
      isLoadingValidationForm,
      canEditAlreadySentExpenses,
    }}
    >
      {children}
    </ExpensesContext.Provider>
  )
}

export const useExpensesContext = () => useContext(ExpensesContext)
