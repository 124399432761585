import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 25rem;
  height: 3.2rem;
  padding: 0 1.6rem;
  border: 1px solid ${({ theme }) => theme.colors.betterway.primary[50]};
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.betterway.white};
  color: ${({ theme }) => theme.colors.betterway.primary[50]};
  transition: border 0.1s ease-in-out, color 0.1s ease-in-out;

  &:hover,
  &:focus-within {
    border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
    color: ${({ theme }) => theme.colors.betterway.primary[100]};
  }

  input {
    margin: 0 1rem 0 0;
    padding: 0;
    border: none;
    color: inherit;
    font-size: 1.4rem;
    font-family: "Apercu", sans-serif;

    &:focus,
    &:focus-visible,
    &:target {
      outline: none;
    }
  }
`
