import React, { useState, useEffect } from 'react'
import { InputNumber } from '@produits-internes-oss/design-system-components'
import { getRoundedToDecimal } from '../../helpers/numberHelper'
import { getClass, parseFloatWithAnySeparator } from '../../helpers/stringHelper'
import { useDebouncer } from '../../hooks/useDebouncer/useDebouncer'
import { useProjectTurnoverBreakdownContext } from '../../context/ProjectTurnoverBreakdownContext/ProjectTurnoverBreakdownContext'
import { ProjectTurnoverBreakdownTotalAmountInputStyled } from './ProjectTurnoverBreakdownTotalAmountInput.styled'
import { ProjectTurnoverBreakdown } from '../../structures/ProjectTurnoverBreakdown/ProjectTurnoverBreakdown'

export const ProjectTurnoverBreakdownTotalAmountInput = ({ year, month }) => {
  const { debounce } = useDebouncer()
  const {
    turnoverBreakdown,
    setTurnoverBreakdown,
    fetchUpdateTurnoverBreakdownMonthAmount,
  } = useProjectTurnoverBreakdownContext()

  const turnoverBreakdownMonth = turnoverBreakdown.values[year][month]

  const total = getRoundedToDecimal(turnoverBreakdown.totals.months[year][month].all.amount, 2)
  const [amount, setAmount] = useState(turnoverBreakdownMonth.amount || total)
  const [isInvalid, setIsInvalid] = useState(false)
  const hasChanged = turnoverBreakdownMonth.amount !== null
  const isValidated = turnoverBreakdownMonth.validation?.date !== null

  useEffect(() => {
    if (!hasChanged) {
      setAmount(total)
    }
  })

  const clean = () => {
    if (amount === '') { setAmount(0) }
  }

  const update = (value) => {
    setAmount(value)
    setIsInvalid(false)

    let valueParsed = parseFloatWithAnySeparator(value)
    if (Number.isNaN(valueParsed)) {
      valueParsed = 0
      setIsInvalid(true)
    }

    const turnoverBreakdownToUpdate = new ProjectTurnoverBreakdown(turnoverBreakdown)
    turnoverBreakdownToUpdate.values[year][month].amount = valueParsed
    setTurnoverBreakdown(turnoverBreakdownToUpdate)
    debounce(() => fetchUpdateTurnoverBreakdownMonthAmount(valueParsed, year, month), 1000)
  }
  return (
    <ProjectTurnoverBreakdownTotalAmountInputStyled>
      <InputNumber
        className={getClass(hasChanged && 'changed', isInvalid && 'error')}
        name={`${year} ${month} total amount`}
        valueInitial={parseFloat(amount).toFixed(2)}
        disabled={isValidated}
        localization={{ invalid: { text: 'only numbers allowed', arialabel: 'only numbers allowed' } }}
        onChange={(value) => update((value || value === 0) ? value : null)}
        onBlur={() => clean()}
      />
    </ProjectTurnoverBreakdownTotalAmountInputStyled>
  )
}
