import React, {
  useCallback,
  useEffect,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  useFetchData,
  buildUrl,
} from '../../fetchOverviewApi'
import { useStateValue } from '../../state'
import StyledActivityOverviewForm from './ActivityOverviewForm.styled'
import DateRangePickerSimplified from '../input/DateRangePickerSimplified/DateRangePickerSimplified'

const ActivityOverviewForm = ({
  setData,
  setLoading,
}) => {
  const { t } = useTranslation()
  const [{ range }, dispatch] = useStateValue()
  const [loading, hasError, data, handleFetch] = useFetchData(true)
  const isMounted = useRef(true)

  const setRange = useCallback((startDate, endDate) => {
    dispatch({
      type: 'changeRange',
      range: { startDate, endDate },
    })
  })

  useEffect(() => {
    if (range.startDate && range.endDate) {
      const url = buildUrl('company', range.startDate, range.endDate)
      handleFetch(isMounted, url)
    }
  }, [range])

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  useEffect(() => {
    if (hasError) {
      setData()
    } else {
      setData(data)
    }
  }, [hasError, data])

  useEffect(() => () => {
    isMounted.current = false
  }, [])

  return (
    <StyledActivityOverviewForm>
      <h1>{t('activityOverview.title')}</h1>
      <DateRangePickerSimplified
        onChangeMonthYearRange={(startDate, endDate) => setRange(startDate, endDate)}
      />
    </StyledActivityOverviewForm>
  )
}

export default ActivityOverviewForm
