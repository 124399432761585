import styled from 'styled-components'

export const ManagePeriodsValidationButtonStyled = styled.div`
  .validate-periods {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 18.1rem;
    height: 4rem;
    margin-top: 2.45rem;
    padding: 0.85rem 2.4rem;
    border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
    color: ${({ theme }) => theme.colors.betterway.white};
    font-size: 1.4rem;
    font-family: ${({ theme }) => theme.fonts.family.bold};

    &:hover {
      background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
    }
  }

  .btn-grey {
    background-color: ${({ theme }) => theme.colors.betterway.primary[50]} !important;
  }
`
