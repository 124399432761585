import React, { useRef, useState } from 'react'
import PopupStyled from './Popup.styled'
import { getClass } from '../../../../helpers/stringHelper'
import { getUserDevice } from '../../../../helpers/deviceHelper'
import pictoClose from '../../../../../assets/images/croix.svg'

export default function Popup({
  className, name, title, trigger, content, withoutParentsScroll,
}) {
  const warp = useRef()
  const [isOpened, setIsOpened] = useState(false)

  const setParentsScroll = (node, value) => {
    const element = node
    if (element !== null) {
      if (element.scrollHeight > element.clientHeight) {
        element.style.overflow = value
      }

      setParentsScroll(element.parentNode, value)
    }
  }

  const open = () => {
    if (!withoutParentsScroll) setParentsScroll(warp.current.parentNode, 'hidden')
    setIsOpened(true)
  }

  const close = () => {
    if (!withoutParentsScroll) setParentsScroll(warp.current.parentNode, null)
    setIsOpened(false)
  }

  const position = getUserDevice() === 'mobile' ? 'bottom' : 'center'
  const devicePopupSize = getUserDevice() === 'mobile' ? '' : 'window-stretch'
  return (
    <PopupStyled className={className}>
      {
        trigger({ open })
      }
      <div ref={warp} className={`${getClass('popup', devicePopupSize, position, isOpened ? 'opened' : 'closed')}`} aria-hidden={!isOpened}>
        <button className="overlay" type="button" aria-label={`${name} popup close overlay`} onClick={() => close()} />
        <div className="window">
          <div className="header">
            <button
              className="header-close"
              style={{ backgroundImage: `url(${pictoClose})` }}
              type="button"
              aria-label="popup close button"
              onClick={() => close()}
            />
            <span className="header-title">{title}</span>
          </div>
          <div className="content">
            {
              content({ close })
            }
          </div>
        </div>
      </div>
    </PopupStyled>
  )
}
