import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectSummaryContext } from '../../context/ProjectSummaryContext/ProjectSummaryContext'
import { ProjectStatusHistoryStyled } from './ProjectStatusHistory.styled'

export const ProjectStatusHistory = () => {
  const { t } = useTranslation()
  const { project } = useProjectSummaryContext()

  if (!project) return <div>...</div>

  const navigateTo = (id, anchor) => {
    const target = document.getElementById(id)
    if (target) {
      target.click()
    }

    return document.getElementById(anchor)?.scrollIntoView({ behavior: 'smooth' })
  }

  const history = project['status_logs'].slice(-4)
  return (
    <ProjectStatusHistoryStyled>
      <div className="history">
        {
          history.map(({ id, status, date, person }) => (
            <div key={id} className="log">
              <div className="gauge" />
              <div className="details">
                <div className="date">{new Date(date).toLocaleDateString('fr')}</div>
                <div className="status">{t(`project.status.${status}`)}</div>
                {
                  person && (
                  <a className="person" href={`https://askbob.octo.tools/people/${person.nickname.toLowerCase()}`} target="_blank" rel="noreferrer">
                    {`${person.nickname} / ${person.first_name} ${person.last_name}`}
                  </a>
                  )
                }
              </div>
            </div>
          ))
        }
      </div>
      <button className="link" type="button" onClick={() => navigateTo('contract_tab', 'collapse-status-change-history')}>
        <span className="link-text">{t('project.summary.history.all')}</span>
        <span className="link-icon" />
      </button>
    </ProjectStatusHistoryStyled>
  )
}
