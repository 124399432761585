import React from 'react'
import { getClass } from '../../../../helpers/stringHelper'
import ButtonStyled from './Button.styled'
import pictoWarning from '../../../../../assets/images/attention.svg'

export default function Button({
  className, name, title, text, picto, error, disabled, onClick,
}) {
  return (
    <ButtonStyled className={getClass(className, 'eds-button', error && 'error')} picto={picto} pictoWarning={pictoWarning}>
      {
        title && <div className="title">{title}</div>
      }
      <button
        type="button"
        aria-label={`${name} button`}
        disabled={disabled}
        onClick={() => {
          if (document.activeElement) {
            document.activeElement.blur()
          }
          onClick()
        }}
      >
        <span className="text">{text}</span>
        {
          picto && <div className="picto" />
        }
      </button>
      {
        error && (
          <div className="description">
            <span className="description-icon" />
            <span className="description-text">{error}</span>
          </div>
        )
      }
    </ButtonStyled>
  )
}
