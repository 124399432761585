/* eslint-disable no-console */

import { Workbox } from 'workbox-window'

export const registerServiceWorkerForPWA = async (scope) => {
  console.log(`Registering PWA on ${process.env.NODE_ENV} environment...`)
  if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'production') {
    throw new Error('Could not register PWA : environment must be "development" or "production".')
  }

  if (!('serviceWorker' in navigator)) {
    throw new Error('Could not register PWA : service workers are not enabled on this navigator.')
  }

  const workbox = new Workbox('packs/pwa.worker.js', { scope })
  const installation = new Promise((resolve) => {
    workbox.addEventListener('installed', (event) => {
      console.log(`PWA successfully ${event.isUpdate ? 'updated' : 'installed'}.`)
      resolve(event)
    })
  })

  try {
    const registration = await workbox.register()
    console.log(`PWA successfully registered on ${registration.scope}.`)
  } catch (error) {
    console.log('Could not register service worker.')
    return Promise.reject(error)
  }

  return installation
}
