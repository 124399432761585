import styled, { css } from 'styled-components'

export const StyledTimesheetHeaderDay = styled.th`
  background-color: ${({ theme, open }) => (open ? theme.colors.lightBlue : theme.colors.primary)} !important;
  color: ${({ theme, holiday }) => (holiday ? theme.colors.skeletonGreyLight : theme.colors.white)} !important;
  z-index: ${({ open }) => (open ? 1001 : 99)};
  cursor: pointer;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightBlue} !important;
  }

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
  `}

  &:nth-last-child(-n+8) > div {
    right: 0;
  }

  &:not(:nth-last-child(-n+8)) > div {
    left: 0;
  }

  .dayNumber {
    display: block;
    font-size: 1.5rem;
  }

  .dayShort {
    display: block;
    font-size: 1.2rem;
  }

  .locationCode {
    display: block;
    position: relative;
    font-size: 1.1rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.secondaryBlue};
  }

  &.current-day {
    .locationCode {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  @media (min-width: ${({ theme }) => theme.sizes.desktopMedium}) {
    .dayNumber {
      font-size: 1.5rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.sizes.desktopLarge}) {
    .dayNumber {
      font-size: 1.6rem;
    }
  }
`
