import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTimesheetHeaderDay } from './TimesheetHeaderDay.styled'

import { dayList } from '../../../../../helpers/datePickerHelper'
import { useLocationValue } from '../../../../../context/Timesheet/LocationContext'
import { useDateContextValue } from '../../../../../context/Timesheet/DateContext'

import LocationPopin from './LocationPopin/LocationPopin'

const TimesheetHeaderDay = (
  {
    dayInWeek,
    dateOfDay,
    fullDate,
    holiday,
    validated,
  },
) => {
  const { t } = useTranslation()
  const { locationPerDay, countries } = useLocationValue()
  const [open, setOpen] = useState(false)
  const {
    isToday,
  } = useDateContextValue()

  const toggleLocationPopin = () => {
    if (validated) return
    setOpen(!open)
    document.body.style.overflow = !open ? 'hidden' : 'auto'
  }

  const closeLocationPopin = () => {
    setOpen(false)
  }

  return (
    <StyledTimesheetHeaderDay
      role={`timesheet-header-day-${dateOfDay}`}
      open={open}
      holiday={holiday}
      className={isToday(fullDate) ? 'current-day' : undefined}
      onClick={toggleLocationPopin}
      disabled={validated}
    >
      <span className="locationCode">{locationPerDay[fullDate].country_code}</span>
      <span className="dayNumber">{dateOfDay}</span>
      <span className="dayShort">{t(`days.${dayList[dayInWeek]}.short`)}</span>
      <LocationPopin
        open={open}
        close={closeLocationPopin}
        toggleLocationPopin={toggleLocationPopin}
        countries={countries}
        dateOfDay={dateOfDay}
        fullDate={fullDate}
      />
    </StyledTimesheetHeaderDay>
  )
}

export default TimesheetHeaderDay
