/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import React, { useCallback, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  InputNumber, InputRadio, InputSearch, InputText, Section, InputDate,
} from '@produits-internes-oss/design-system-components'
import { useDebouncer } from '../../hooks/useDebouncer/useDebouncer'
import { useSearch } from '../../hooks/useSearch/useSearch'
import { useProjectFormContext } from '../../context/ProjectForm/ProjectFormContext'
import { ProjectFormLeadStyled } from './ProjectFormLead.styled'
import { monthList } from '../../helpers/datePickerHelper'
import Loader from '../input/_ExportDS/Loader/Loader'

export const ProjectFormLead = () => {
  const { t } = useTranslation()
  const { debounce } = useDebouncer()
  const { fetchSearch } = useSearch()
  const {
    cache, form, getPersonName, updateForm, currentPerson, fetchCreateOrUpdateProject, isLoading, setIsLoading,
  } = useProjectFormContext()
  const buttonRef = useRef(null)
  const projectNameInput = useRef(null)

  const action = form.id.value ? 'update' : 'create'
  const errors = Object.entries(form).map(([name, { error }]) => error && name).filter(Boolean)
  const requirements = Object.entries(form).map(([name, { required, value }]) => required && (value === null || value === undefined || value?.length === 0) && name).filter(Boolean)
  const isDisabled = isLoading || errors.length > 0 || requirements.length > 0

  useEffect(() => {
    if (projectNameInput.current) {
      const input = projectNameInput.current.querySelector('.name-input .control input')
      input.focus()
    }
  }, [])

  useCallback(() => {
    if (form['business_contact_id'].value === null) {
      updateForm('business_contact_id', currentPerson.id)
    }
  }, [form['business_contact_id']])

  useEffect(() => {
    const container = document.querySelector('.container')

    const handleFocusIn = () => {
      if (buttonRef.current) {
        buttonRef.current.focus()
      }
    }

    if (container) {
      container.addEventListener('focusin', handleFocusIn)
    }

    return () => {
      container.removeEventListener('focusin', handleFocusIn)
    }
  }, [])

  return (
    <ProjectFormLeadStyled status="lead">
      <Section className="form-section">
        <div className="columns threefifth">
          <div className="column">
            <h3>{t('project.form.mission')}</h3>
            <div className="form-group">
              <div ref={projectNameInput}>
                <InputText
                  className="form-control name-input"
                  skin="light"
                  name="name text input"
                  title={t('project.form.name')}
                  valueInitial={form['name'].value}
                  required={form['name'].required}
                  onChange={(value) => updateForm('name', value)}
                />
              </div>
              <div className="form-row">
                <InputNumber
                  className="form-control amount"
                  skin="light"
                  name="amount number input"
                  title={t('project.form.amount')}
                  localization={{ invalid: { text: t('project.form.amount_invalid.text'), arialabel: t('project.form.amount_invalid.text') } }}
                  valueInitial={form['amount'].value}
                  required={form['amount'].required}
                  disabled={form['amount'].disabled}
                  onCheck={() => form['amount'].error && t('project.form.amount_error_negative')}
                  onChange={(value) => updateForm('amount', value)}
                />
                <InputRadio
                  className="form-control radio radio-box"
                  skin="light"
                  name="kind radio"
                  title={t('project.form.kind')}
                  options={Object.fromEntries(cache.kinds.map((kind) => [kind, t(`project.form.kinds.${kind}`)]))}
                  valueInitial={form['kind'].value}
                  required={form['kind'].required}
                  onCheck={() => form['kind'].error && t('project.form.kind_error_acn')}
                  onChange={([value]) => updateForm('kind', value)}
                />
              </div>
              <div className="form-row">
                <InputDate
                  className="form-control"
                  skin="light"
                  precision="day"
                  localization={{
                    months: [...monthList.map((month) => t(`months.${month}.short`)), ...monthList.map((month) => t(`months.${month}.full`))],
                  }}
                  name="start_date date input"
                  title={t('project.form.start_date')}
                  valueInitial={form['start_date'].value}
                  required={form['start_date'].required}
                  onChange={(value) => updateForm('start_date', value)}
                  onCheck={(value) => ((form['end_date'] && form['end_date'].value !== null && value !== null && value > form['end_date'].value)
                    ? t('project.form.start_date_error')
                    : null)}
                />
                <InputDate
                  className="form-control"
                  skin="light"
                  name="end_date date input"
                  title={t('project.form.end_date')}
                  precision="day"
                  localization={{
                    months: [...monthList.map((month) => t(`months.${month}.short`)), ...monthList.map((month) => t(`months.${month}.full`))],
                  }}
                  valueInitial={form['end_date'].value}
                  required={form['end_date'].required}
                  onChange={(value) => updateForm('end_date', value)}
                  onCheck={(value) => ((form['start_date'] && form['start_date'].value !== null && value !== null && value < form['start_date'].value)
                    ? t('project.form.end_date_error')
                    : null)}
                />
              </div>
              <p className="tip">{t('project.form.dates_tip')}</p>
            </div>
          </div>
          <div className="column">
            <h3>{t('project.form.contact')}</h3>
            <div className="form-group">
              <InputSearch
                className="form-control"
                skin="light"
                name="customer search"
                title={t('project.form.customer_id')}
                valueInitial={{ key: form['customer_id'].value, search: form['customer_name'].value }}
                required={form['customer_id'].required}
                onCheck={() => form['customer_id'].error && t('project.form.customer_id_error_affiliated')}
                onSearch={(search) => debounce(() => search.length >= 3 && fetchSearch(search, 'Customer', null, (customer) => customer.name), 500)}
                onChange={({ key }) => updateForm('customer_id', key && parseInt(key, 10))}
              />
              <InputText
                className="form-control"
                skin="light"
                name="customer_contact_email text input"
                title={t('project.form.customer_contact_email')}
                valueInitial={form['customer_contact_email'].value}
                required={form['customer_contact_email'].required}
                onCheck={() => form['customer_contact_email'].error && t('project.form.customer_contact_email_error_syntax')}
                onChange={(value) => updateForm('customer_contact_email', value.trim())}
              />
              <InputSearch
                className="form-control"
                skin="light"
                name="business_contact search"
                title={t('project.form.business_contact_id')}
                valueInitial={{ key: (form['business_contact_id'].value), search: getPersonName(form['business_contact_name'].value || currentPerson) }}
                required={form['business_contact_id'].required}
                onSearch={(search) => debounce(() => search.length >= 3 && fetchSearch(search, 'Person', null, getPersonName), 500)}
                onChange={({ key }) => updateForm('business_contact_id', key && parseInt(key, 10))}
              />
              <div className="container" tabIndex="0">
                {
                  isLoading ? <Loader className="loading" />
                    : (
                      <button
                        className="hidden-button"
                        aria-label={t(`project.form.${action}.arialabel`)}
                        type="button"
                        ref={buttonRef}
                        disabled={isDisabled}
                        onClick={async () => {
                          setIsLoading(true)
                          const id = await fetchCreateOrUpdateProject()
                          if (id) {
                            window.location.href = action === 'create' ? `/projects/billable/${id}#sale_tab` : `/projects/billable/${id}`
                          } else {
                            setIsLoading(false)
                          }
                        }}
                      >
                        {t(`project.form.${action}.text`)}
                      </button>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </Section>
    </ProjectFormLeadStyled>
  )
}
