import styled from 'styled-components'
import { theme } from '@produits-internes-oss/design-system'
import bellActive from '../../../assets/images/bellActive.svg'
import bellInactive from '../../../assets/images/bellInactive.svg'

export const HolidaysStyled = styled.div`
@keyframes breathe-inner {
  0% { transform: scale(1.25); }
  50% { transform: scale(0.9); }
  100% { transform: scale(1.25); }
}

@keyframes breathe-outer {
  0% { transform: scale(1); }
  50% { transform: scale(1.4); }
  100% { transform: scale(1); }
}

&.octo {
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  align-items: stretch;
  justify-content: flex-start;
  max-width: 150rem;
  height: fit-content;
  margin: auto;
  padding-bottom: 10rem;
  color: ${theme.colors.marine[100]};

  > h1 {
    font-weight: 600;
    font-size: 3rem;
  }

  >.controls {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid ${theme.colors.marine[20]};

    >.search {
      max-width: 30rem;
    }

    >.today {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-end;
      box-shadow: none;
      text-align: right;

      >.today-name {
        font-weight: 600;
      }
    }
  }

  > header {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
    align-items: stretch;
    justify-content: stretch;
    padding: 3.2rem;
    border-radius: 2rem;
    background-color: ${theme.colors.marine[100]};
    color: ${theme.colors.white};

    >.title {
      display: flex;
      gap: 1em;
      align-items: center;
      justify-content: flex-start;

      @media (min-width: 880px) {
        flex-flow: row nowrap;
      }

      @media (max-width: 880px) {
        flex-flow: column nowrap;
      }

      > h2 {
        flex: 1;
        font-weight: 400;
        font-size: 3.6rem;
      }

      >.notifications {
        >.popup-trigger {
          cursor: pointer;

          >.bell {
            width: 4rem;
            height: 4rem;
            border-radius: 2rem;
            background-color: ${theme.colors.white};
            background-image: url(${bellInactive});
            background-position: center;
            background-size: 3rem;
            background-repeat: no-repeat;
          }
        }

        &.active {
          >.popup-trigger {
            >.bell {
              position: relative;
              z-index: 1;
              background-image: url(${bellActive});
            }

            ::before {
              content: '';
              position: absolute;
              border-radius: 100%;
              background-color: ${theme.colors.white};
              opacity: 20%;
              animation: breathe-inner 2s infinite;
              inset: -1rem;
            }

            ::after {
              content: '';
              position: absolute;
              border-radius: 100%;
              background-color: ${theme.colors.white};
              opacity: 30%;
              animation: breathe-outer 2s infinite;
              inset: -1rem;
            }
          }

          >.popup-tooltip {
            > p {
              display: inline;

              > * {
                display: inline;
              }

              > strong {
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    >.leaving {
      width: fit-content;
    }

    >.infos {
      display: flex;
      flex-flow: row wrap;
      gap: 0.4em;
      align-items: center;
      justify-content: space-between;

      @media (min-width: 880px) {
        flex-flow: row wrap;
      }

      @media (max-width: 880px) {
        flex-flow: column nowrap;
      }

      >.info {
        display: flex;
        flex-basis: calc(100% / 3 - 0.8em);
        flex-flow: row nowrap;
        gap: 0.6rem;
        align-items: center;
        justify-content: stretch;

        >.info-name {
          font-weight: 600;
        }

        >.info-value {
          display: flex;
          flex-flow: row nowrap;
          gap: 0.8rem;
          align-items: center;
          justify-content: flex-start;
          width: fit-content;
        }

        &:last-child:not(:first-child) {
          flex-grow: 1;
          margin-left: 0.8em;
        }
      }
    }
  }

  >.picker {
    max-width: 30rem;
  }

  >.counters {
    display: flex;
    flex: 1;
    flex-flow: row wrap;
    gap: 2rem;
    align-items: flex-start;
    justify-content: center;
    background-image: url(${({ illustration }) => illustration});
    background-size: contain;
    background-repeat: no-repeat;

    @media (min-width: 880px) {
      padding-bottom: 26rem;
      background-position: top;
    }

    @media (max-width: 880px) {
      padding-bottom: 40%;
      background-position: bottom;
    }
  }

  >.absences {
    display: flex;
    flex-flow: column-reverse nowrap;
    gap: 1em;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 0;

    >.absences-year {
      border: 0.1rem solid ${theme.colors.marine[20]};
      border-radius: 2rem;
      background-color: ${theme.colors.marine[5]};

      >.header {
        padding: 2rem 4rem;
        background-color: transparent;
        color: ${theme.colors.marine[100]};
      }

      >.content {
        display: flex;
        flex-flow: column nowrap;
        gap: 2rem;
        align-items: stretch;
        justify-content: flex-start;
        margin: 0;
        padding: 0 4rem 4rem;

        >.absences-activity {
          padding: 2rem;
          border: 0.1rem solid ${theme.colors.marine[20]};
          border-radius: 2rem;
          background-color: ${theme.colors.marine[5]};

          >.absences-activity-name {
            margin-bottom: 2rem;
            font-weight: 600;
          }

          >.absences-activity-list {
            position: relative;
            display: flex;
            flex-flow: row wrap;
            gap: 2rem;
            align-items: stretch;
            justify-content: flex-start;

            ::before {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 0.1rem;
              background: ${theme.colors.marine[5]};
            }

            >.absence {
              min-width: 30rem;
              padding-left: 3rem;
              border-left: 0.1rem solid ${theme.colors.marine[20]};

              >.absence-interval {
                margin-bottom: 0.8rem;
              }

              >.absence-duration {
                font-weight: 600;
              }

              &:last-child {
                flex-grow: 1;
              }
            }
          }
        }
      }
    }
  }

  >.tips {
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    @media (min-width: 880px) {
      flex-flow: row nowrap;
    }

    @media (max-width: 880px) {
      flex-flow: column nowrap;
    }

    >.tips-input {
      display: flex;
      flex: 1;
      flex-flow: column nowrap;
      gap: 2rem;
      align-items: stretch;
      justify-content: stretch;

      @media (min-width: 880px) {
        padding-right: 4rem;
        border-right: 0.1rem solid ${theme.colors.marine[70]};
      }

      @media (max-width: 880px) {
        padding-bottom: 4rem;
        border-bottom: 0.1rem solid ${theme.colors.marine[70]};
      }

      >.tips-input-cra {
        font-weight: 600;
      }

      >.tips-input-confluence {
        max-width: fit-content;
      }
    }

    >.tips-reducing {
      display: flex;
      flex: 1;
      flex-flow: column nowrap;
      gap: 2rem;
      align-items: stretch;
      justify-content: stretch;

      @media (min-width: 880px) {
        padding-left: 4rem;
      }

      @media (max-width: 880px) {
        padding-top: 4rem;
      }

      >.tips-reducing-header {
        font-weight: 600;
      }

      >.tips-reducing-list {
        display: flex;
        flex-flow: column nowrap;
        gap: 0.5rem;
        align-items: stretch;
        justify-content: stretch;
        padding: 0 20rem 0 2rem;
        color: ${theme.colors.marine[70]};

        > li {
          list-style-type: disc;

          >.tips-reducing-list-item {
            display: flex;
            flex-flow: row nowrap;
            align-items: baseline;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
`
