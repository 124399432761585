import React, { useState } from 'react'
import { InputDate } from '@produits-internes-oss/design-system-components'
import { useTranslation } from 'react-i18next'
import { useProjectCoordinationContext } from '../../context/ProjectCoordination/ProjectCoordinationContext'
import { ProjectConsumptionTimeInputs } from '../ProjectConsumptionTimeInputs/ProjectConsumptionTimeInputs'
import { ProjectActivitiesTimeSpentTable } from '../ProjectActivitiesTimeSpentTable/ProjectActivitiesTimeSpentTable'
import { ProjectActivitiesTimeSpentStyled } from './ProjectActivitiesTimeSpent.styled'

export const ProjectActivitiesTimeSpent = () => {
  const { t } = useTranslation()
  const [timeSpentDate, setTimeSpentDate] = useState(null)

  const {
    fetchGetProjectActivitiesTimeSpent, projectActivitiesTimeSpent,
  } = useProjectCoordinationContext()

  const handleFechProjectActivitiesTimeSpent = async (date) => {
    await fetchGetProjectActivitiesTimeSpent(date)
    setTimeSpentDate(date)
  }

  const hasPresalesActivities = projectActivitiesTimeSpent?.presales_activities?.length > 0
  const months = projectActivitiesTimeSpent?.months || []
  const monthLast = months[months.length - 1]

  return (
    <ProjectActivitiesTimeSpentStyled className="octo">
      <div className="activities-time-spent-header">
        <h2>{t('project.activities.subtitles.octo')}</h2>
        <div className="time-spent-date-picker-container">
          <InputDate
            className="time-spent-date-picker"
            skin="light"
            name={t('project.activities.timeSpent.datePicker.title')}
            title={t('project.activities.timeSpent.datePicker.title')}
            valueInitial={timeSpentDate}
            onCheck={() => {}}
            onChange={(date) => handleFechProjectActivitiesTimeSpent(date)}
          />
          {
            monthLast && <ProjectConsumptionTimeInputs className="time-inputs" dateInitial={new Date(monthLast)} />
          }
        </div>
      </div>
      <ProjectActivitiesTimeSpentTable hasPresalesActivities={hasPresalesActivities} />
    </ProjectActivitiesTimeSpentStyled>
  )
}
