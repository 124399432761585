import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledRestForm } from './RestForm.styled'

import RadioButtons from '../../../../UI/Forms/RadioButtons/RadioButtons'

const RestForm = ({
  restRespected,
  updateRestRespected,
}) => {
  const { t } = useTranslation()
  const restOptions = [
    {
      option: true,
      label: 'yes',
    },
    {
      option: false,
      label: 'no',
    },
  ]

  return (
    <StyledRestForm>
      <h3>{t('periodValidationModal.restRespected.title')}</h3>
      <h6>{t('periodValidationModal.restRespected.question')}</h6>
      <div className="question">
        <p>{t('periodValidationModal.restRespected.label')}</p>
        <RadioButtons
          topic="rest"
          defaultValue={restRespected}
          options={restOptions}
          click={updateRestRespected}
        />
      </div>
      <p className="legend">{t('periodValidationModal.restRespected.legend')}</p>
    </StyledRestForm>
  )
}

export default RestForm
