import React from 'react'

import AutoCompleteSelect from '../AutoCompleteSelect/AutoCompleteSelect'
import { request } from '../../../javascripts/HttpService'

class ProjectPicker extends React.Component {
  componentDidMount() {
    this.requestTimer = null
  }

  render() {
    const { value, onChange } = this.props

    return (
      <AutoCompleteSelect
        value={value}
        loadOptions={this.fetchProjects}
        onChange={onChange}
        getOptionValue={(option) => option.selectedValue}
        noOptionsMessageType="project"
      />
    )
  }

  fetchProjects = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      this.debounceFetchProjects(inputValue, callback)
    }
  };

  debounceFetchProjects = (inputValue, callback) => {
    const url = {
      all: `/projects/search?format=json&search=${inputValue.replace('&', '%26')}`,
      active: `/projects/search/active-internal?format=json&person=${this.props.personId}&search=${inputValue.replace('&', '%26')}`,
    }

    clearTimeout(this.requestTimer)

    this.requestTimer = setTimeout(() => request(url[this.props.scope], { method: 'GET' })
      .then((data) => callback(data)), 1000)

    return this.requestTimer
  };
}

export default ProjectPicker
