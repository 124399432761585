import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledRadioButtons } from './RadioButtons.styled'

const RadioButton = ({
  topic,
  defaultValue,
  click,
  options,
}) => {
  const { t } = useTranslation()

  return (
    <StyledRadioButtons>
      {
        options.map(({ option, label }) => (
          <div key={option} className="form-radio">
            <input
              type="radio"
              value={option}
              id={`${topic}-${option}`}
              name={topic}
              checked={option === defaultValue}
              readOnly
            />
            <label
              htmlFor={`${topic}-${option}`}
              onClick={() => click(option)}
            >
              {t(`${label}`)}
            </label>
          </div>
        ))
      }
    </StyledRadioButtons>
  )
}

export default RadioButton
