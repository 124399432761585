import styled from 'styled-components'

export const ProjectCoordinationExpensesTableStyled = styled.div`
  max-width: 100%;

  >.octo.hard {
    overflow-x: auto;
    padding-bottom: 1.6rem;

    > table {
      position: relative;
      border-collapse: separate;

      > thead {
        > tr {
          > th {
            left: 2rem;

            &.turnover,
            &.index,
            &.remove {
              position: sticky;
              left: 0;
              z-index: 10;
              border-right: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[50]};
            }

            &.turnover:not(:first-child),
            &.index:not(:first-child) {
              left: 14rem;
            }

            &.turnover,
            &.index {
              min-width: 32rem;
            }

            &.remove {
              min-width: 14rem;
            }

            &.consumption {
              position: sticky;
              left: 32rem;
              z-index: 10;
              border-right: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[50]};
            }

            &.label,
            &.comment,
            &.unit,
            &.quantity,
            &.total,
            &.consumption {
              min-width: 20rem;
            }

            &.month {
              min-width: 12rem;
            }
          }
        }
      }

      > tbody {
        > tr {
          > td {
            left: 2rem;
            white-space: pre;

            &.turnover,
            &.index,
            &.remove {
              position: sticky;
              left: 0;
              z-index: 10;
              border-right: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[50]};
              background: inherit;

              >.cell {
                >.tag {
                  display: flex;
                  flex-flow: row nowrap;
                  align-items: center;
                  justify-content: stretch;
                  width: fit-content;
                  padding: 0.8rem;
                  border-radius: 0.3rem;
                  font-weight: 600;

                  &.travel {
                    background-color: ${({ theme }) => theme.colors.betterway.yellow[100]};
                  }

                  &.license {
                    background-color: ${({ theme }) => theme.colors.betterway.cyan[70]};
                  }

                  &.subcontractor {
                    background-color: ${({ theme }) => theme.colors.betterway.purple[70]};
                  }

                  &.misc {
                    background-color: ${({ theme }) => theme.colors.betterway.orange[70]};
                  }
                }

                >.name {
                  margin-top: 0.8rem;
                  font-weight: 600;
                }
              }
            }

            &.unit,
            &.quantity,
            &.total,
            &.month {
              text-align: right;
            }

            &.turnover:not(:first-child),
            &.index:not(:first-child) {
              left: 14rem;
            }

            &.remove {
              >.cell {
                display: flex;
                flex-flow: row nowrap;
                gap: 0.8rem;
                align-items: center;
                justify-content: flex-start;

                >.octo {
                  max-width: fit-content;

                  &.disabled {
                    .checkbox {
                      background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
                      color: ${({ theme }) => theme.colors.betterway.primary[50]};
                    }
                  }
                }
              }
            }

            &.index {
              >.cell {
                font-weight: 600;
              }
            }

            &.consumption {
              position: sticky;
              left: 32rem;
              z-index: 10;
              border-right: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[50]};
              background: inherit;
            }

            &.month {
              >.cell {
                >.octo {
                  >.control {
                    min-width: 5em;
                  }
                }
              }
            }
          }
        }
      }

      > tfoot {
        font-weight: 600;

        > tr {
          > td {
            left: 2rem;
            font-size: 1.4rem;
            text-align: end;
            white-space: pre;

            >.cell {
              >.total {
                text-align: start;
              }
            }

            &.turnover,
            &.index,
            &.remove {
              position: sticky;
              left: 0;
              z-index: 10;
              border-right: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[50]};
            }

            &.turnover:not(:first-child),
            &.index:not(:first-child) {
              left: 14rem;
            }

            &.consumption {
              position: sticky;
              left: 32rem;
              z-index: 10;
              border-right: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[50]};
            }
          }
        }
      }

      .disabled-input-tooltip {
        width: auto;

        .popup-tooltip {
          background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
          color: ${({ theme }) => theme.colors.betterway.white};

          .text-tooltip {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: flex-start;
          }
        }

        .popup-tooltip::after {
          background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
        }

        .popup-overlay {
          opacity: 0%;
        }

        em {
          font-weight: 600;
        }
      }
    }
  }

  >.controls {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.8rem;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    margin: 0.4rem 0;

    >.create {
      >.popup-tooltip {
        >.modal {
          >.content {
            overflow: visible;
            width: 100rem;
            max-width: 90%;
            height: fit-content;
            max-height: 90%;

            > hr {
              margin: 0;
            }

            >.form-requirements {
              font-weight: 600;
              font-size: 1.6rem;
            }

            >.form-row {
              display: flex;
              flex-flow: row nowrap;
              gap: 1.6rem;
              align-items: baseline;
              justify-content: space-between;

              >.form-control {
                flex: 1;

                >div.control {
                  >div.button-group-month {
                    >button.month-button-navigator.arrow-right,
                    >button.month-button-navigator.arrow-left,
                    >button.month-button {
                      font-size: 1.5rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    >.remove {
      >.popup-trigger {
        >.hidden {
          display: none;
        }
      }

      >.popup-tooltip {
        text-align: center;

        >.modal {
          >.content {
            width: 91.2rem;
          }
        }
      }
    }
  }
`
