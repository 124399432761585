import React from 'react'
import { StyledButton } from './Button.styled'

const Button = ({
  color, disabled, type, children, click, ariaLabel,
}) => (
  <StyledButton
    color={color}
    disabled={disabled}
    onClick={click}
    className={type}
    aria-label={ariaLabel}
  >
    {children}
  </StyledButton>
)

export default Button
