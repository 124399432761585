import styled from 'styled-components'

export default styled.table`
  width: 100%;
  border-collapse: separate;

  thead {
    tr {
      th {
        box-sizing: border-box;
        padding: 1.6rem 0.8rem;
        border: 1px solid ${({ theme }) => theme.colors.betterway.primary[100]};
        border-right-color: ${({ theme }) => theme.colors.betterway.transparent};
        border-left-color: ${({ theme }) => theme.colors.betterway.primary[50]};
        background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
        color: ${({ theme }) => theme.colors.betterway.white};
        text-align: left;

        &.center {
          text-align: center;
        }

        &:first-child {
          border-left-color: ${({ theme }) => theme.colors.betterway.primary[100]};
          border-top-left-radius: 1rem;
        }

        &:last-child {
          border-right-color: ${({ theme }) => theme.colors.betterway.primary[100]};
          border-top-right-radius: 1rem;
        }

        .column-content {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;

          .column-content-sort {
            width: 2.2rem;
            height: 2.2rem;
            margin: 0 0 0 1rem;
            border: none;
            border-radius: 0.4rem;
            background: none;
            color: ${({ theme }) => theme.colors.betterway.white};
            font-size: 1.6rem;

            i.fa.fa-sort {
              color: ${({ theme }) => theme.colors.betterway.primary[50]};
            }

            &:hover {
              background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
              color: ${({ theme }) => theme.colors.betterway.white};

              i.fa.fa-sort {
                color: ${({ theme }) => theme.colors.betterway.white};
              }
            }
          }
        }
      }
    }
  }

  tbody {
    tr {
      &.even {
        background-color: ${({ theme }) => theme.colors.betterway.primary[5]};
      }

      &.odd {
        background-color: ${({ theme }) => theme.colors.betterway.white};
      }

      &.blank {
        background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
      }

      td {
        box-sizing: border-box;
        height: 4.8rem;
        padding: 0 0.8rem;
        border-right: 1px solid ${({ theme }) => theme.colors.betterway.transparent};
        border-left: 1px solid ${({ theme }) => theme.colors.betterway.primary[50]};
        color: ${({ theme }) => theme.colors.betterway.primary[100]};

        &.center {
          text-align: center;
        }

        &.grayed {
          color: ${({ theme }) => theme.colors.betterway.primary[70]};
        }

        &:first-child {
          border-left-color: ${({ theme }) => theme.colors.betterway.primary[100]};
        }

        &:last-child {
          border-right-color: ${({ theme }) => theme.colors.betterway.primary[100]};
        }

        a {
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          font-weight: bold;

          &:hover {
            color: ${({ theme }) => theme.colors.betterway.secondary[70]};
            text-decoration: none;
          }
        }

        span.actions {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-around;

          a {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            width: 2.6rem;
            height: 2.6rem;
            border-radius: 0.4rem;
            background-color: transparent;
            color: ${({ theme }) => theme.colors.betterway.primary[70]};
            font-size: 1.6rem;
            text-decoration: none;
            transition: background-color 0.1s ease-in-out;

            &:hover {
              color: ${({ theme }) => theme.colors.betterway.secondary[70]};
            }
          }
        }
      }
    }

    .loader-row {
      td {
        border: none;
      }
    }
  }

  tfoot {
    tr:first-child {
      overflow: clip;

      td {
        padding: 1.2rem 0 1.2rem 5.6rem;
        border: 1px solid ${({ theme }) => theme.colors.betterway.primary[100]};
        border-radius: 0 0 1rem 1rem;
        background-color: ${({ theme }) => theme.colors.betterway.primary[100]};

        span.navigator {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          color: ${({ theme }) => theme.colors.betterway.white};

          button {
            margin: 0 0.8rem;
            background-color: transparent;
            color: inherit;
            font-size: 1.3rem;

            &:disabled {
              color: ${({ theme }) => theme.colors.betterway.primary[70]};
              cursor: default;
            }

            &.arrow {
              width: 2.4rem;
              text-align: center;

              &:first-child {
                margin-left: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }

            &.page {
              &.selected {
                padding: 0.4rem 0.8rem;
                border: 0.5px solid ${({ theme }) => theme.colors.betterway.primary[5]};
                border-radius: 0.4rem;
                cursor: default;
              }
            }
          }
        }
      }
    }
  }
`
