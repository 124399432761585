import styled from 'styled-components'

export const StyledExpenseSummaryTable = styled.div`
  table {
    border-collapse: separate;
    width: 33%;
    margin-bottom: 9px;

    .text-aligned {
      text-align: right;
    }

    .table-border {
      border-left: 1px solid ${({ theme }) => theme.colors.lightGrey};
    }
  }
  thead {
    letter-spacing: 1px;
    tr {
      background-color: ${({ theme }) => theme.colors.primaryBlue};
      color: ${({ theme }) => theme.colors.white};
    }
    th {
      padding: 5px;
      &:first-child {
        border-right: 1px solid ${({ theme }) => theme.colors.lightGrey};
        border-top-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
      }
    }
  }
  tfoot {
    tr {
      background-color: ${({ theme }) => theme.colors.steelBlue};
      color: ${({ theme }) => theme.colors.white};
      font-weight: bold;
      td {
        padding: 8px;
        border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
        border-right: 1px solid ${({ theme }) => theme.colors.lightGrey};
        &:first-child {
          border-bottom-left-radius: 4px;
          border-left: 0;
        }
        &:last-child {
          border-bottom-right-radius: 4px;
          border-right: 0;
        }
      }
    }
  }
`
