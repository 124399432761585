import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import ReactCameraHTML5Photo from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import InputFile from '../InputFile/InputFile'
import CameraStyled from './Camera.styled'
import Popup from '../Popup/Popup'
import Options from '../Options/Options'
import { getUserDevice } from '../../../../helpers/deviceHelper'

export default function Camera({
  className, text, disabled, onChange,
}) {
  const { t } = useTranslation()
  const [cameraError, setCameraError] = useState(false)
  const inputFileRef = useRef(null)
  const [reactCameraOpened, setReactCameraOpened] = useState(false)

  function webCamera() {
    if (reactCameraOpened && !cameraError) {
      return (
        <ReactCameraHTML5Photo
          isSilentMode
          isImageMirror={false}
          isDisplayStartCameraError={false}
          onCameraError={() => { setCameraError(true) }}
          onTakePhoto={async (dataUri) => {
            const fetchDataUri = await fetch(dataUri)
            const fileBlob = await fetchDataUri.blob()
            const file = new File([fileBlob], `receipt-${Date.now()}.jpg`, { type: 'image/jpeg', lastModified: new Date() })

            setReactCameraOpened(false)
            onChange(file)
          }}
        />
      )
    }

    return (
      <div>
        <InputFile name="upload receipt" inputOnly inputRef={inputFileRef} text={text} onChange={(file) => onChange(file)} />
        <Popup
          className="header-controls"
          name="name"
          trigger={({ open }) => (
            <InputFile name="upload receipt" text={text} onChange={null} onClick={() => open()} />
          )}
          content={({ close }) => (
            <Options
              name="expense popup options"
              items={[
                { name: 'uploadReceipt', title: t('expenses.controls.uploadReceipt') },
                { name: 'openCamera', title: t('expenses.controls.openCamera') },
              ]}
              onChange={(option) => {
                if (option.name === 'uploadReceipt') {
                  inputFileRef.current.click()
                } else if (option.name === 'openCamera') {
                  setReactCameraOpened(true)
                }
                close()
              }}
            />
          )}
        />
        { cameraError ? 'Vous avez choisi de ne pas utiliser votre webcam, veuillez utiliser l\'autre option' : '' }
      </div>
    )
  }

  return (
    <CameraStyled className={className}>
      {
        getUserDevice() === 'mobile'
          ? disabled || <InputFile name="upload receipt" text={text} onChange={(file) => onChange(file)} />
          : disabled || webCamera()
      }
    </CameraStyled>
  )
}
