import styled from 'styled-components'

export const ProjectInfoStyled = styled.div`
  display: flex;
  height: 100%;

  .row-infos {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    justify-content: space-around;
    width: 100%;
    padding-left: 2rem;
    border-left: solid 0.1rem ${({ theme }) => theme.colors.betterway.primary[20]};
    font-size: 1.4rem;
    word-wrap: break-word;

    &.first-row {
      margin-bottom: 0;
      padding-left: 0;
      border: none;
    }

    .line-infos {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.2rem;
      align-items: center;
      justify-content: stretch;

      > b {
        font-weight: 600;
      }
    }

    a {
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      font-size: 1.6rem;
      text-decoration: underline;
    }
  }

  .first-row {
    margin-bottom: 3.2rem;
  }

  .title {
    margin-bottom: 0.8rem;
    font-weight: 600;
    font-size: 1.6rem;
  }
`
