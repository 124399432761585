import React from 'react'
import { useTranslation } from 'react-i18next'
import { getClass } from '../../helpers/stringHelper'
import { useProjectFormContext } from '../../context/ProjectForm/ProjectFormContext'
import { ProjectFormFragmentStyled } from './ProjectFormFragment.styled'

export const ProjectFormFragment = ({ children, className, status }) => {
  const { t } = useTranslation()
  const { project } = useProjectFormContext()

  const name = t(`project.status.${status}`)
  return (
    <ProjectFormFragmentStyled
      className={getClass(className, 'form')}
      title={name}
      localization={{
        open: {
          text: t('project.form.dropdown.open.text'),
          arialabel: t('project.form.dropdown.open.arialabel', { status: name.toLowerCase() }),
        },
        close: {
          text: t('project.form.dropdown.close.text'),
          arialabel: t('project.form.dropdown.close.arialabel', { status: name.toLowerCase() }),
        },
      }}
      withStartupOpen={project.status === status}
    >
      {children}
    </ProjectFormFragmentStyled>
  )
}
