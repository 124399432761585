import styled from 'styled-components'

export const StyledRestForm = styled.div`
  margin-bottom: 5rem;
  
  h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  h6 {
    color: inherit;
    font-size: inherit;
    text-transform: none;
    margin-bottom: 2rem;
    font-family: ${({ theme }) => theme.fonts.family.bold};
  }

  .question {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin-right: 1rem;
    }

    h6 {
      margin-bottom: 0;
    }
  }

  .legend {
    font-style: italic;
    font-family: ${({ theme }) => theme.fonts.family.bold};
    font-size: 1.2rem;
  }
`
