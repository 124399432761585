import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useTimesheetStateValue } from '../context/Timesheet/TimesheetStateContext'
import { useActivityValues } from '../context/Timesheet/ActivityContext'

import { request, requestWithCSRFToken } from '../javascripts/HttpService'

export const usePeriodValidationForm = (periodId, open) => {
  const { i18n } = useTranslation()
  const [restRespected, setRestRespected] = useState()
  const [daysInRemote, setNbDaysInRemote] = useState()
  const [daysInCustomerSite, setNbDaysInCustomerSite] = useState()
  const [hasRemoteDaysError, setHasRemoteDaysError] = useState()
  const [hasCustomerSiteDaysError, setHasCustomerSiteDaysError] = useState()
  const [missingLegalInfoMessages, setMissingLegalInfoMessages] = useState()
  const [missingLegalInfo, setMissingLegalInfo] = useState(true)
  const [workingDaysCount, setWorkingDaysCount] = useState()
  const updateRemoteDaysTimer = useRef()
  const updateCustomerOfficeDaysTimer = useRef()

  const { timesheet } = useTimesheetStateValue()
  const {
    activities,
    activityCategories,
    activityCategoryHasInputs,
  } = useActivityValues()

  const checkMissingLegalInfo = () => {
    const url = `/periods/${periodId}/missing_legal_info?locale=${i18n.language}`

    request(url)
      .then((responseData) => {
        setRestRespected(responseData.rest_respected)
        setNbDaysInRemote(responseData.days_in_remote)
        setNbDaysInCustomerSite(responseData.days_in_customer_site)
        setMissingLegalInfo(responseData.missing_legal_info)
        setMissingLegalInfoMessages(responseData.messages)
        setWorkingDaysCount(responseData.working_days_count)
      })
  }

  useEffect(() => {
    if (open) {
      if (daysInRemote) {
        updateNbDaysInRemote(daysInRemote)
      } else {
        checkMissingLegalInfo()
      }
    }
  }, [open])

  const updateRestRespected = (value) => {
    const url = `/periods/${periodId}/rest_respected`
    const body = {
      rest_respected: value,
    }

    requestWithCSRFToken(url, { method: 'POST', body: JSON.stringify(body) })
      .then(() => checkMissingLegalInfo())
  }

  const updateNbDaysInRemote = (value) => {
    const url = `/periods/${periodId}/update_days_in_remote`
    const body = {
      days_in_remote: value,
    }

    requestWithCSRFToken(url, { method: 'POST', body: JSON.stringify(body) })
      .then((responseData) => {
        setHasRemoteDaysError(!responseData.isUpdated)
        checkMissingLegalInfo()
      })
  }

  const updateNbDaysInCustomerSite = (value) => {
    const url = `/periods/${periodId}/update_days_in_customer_site`
    const body = {
      days_in_customer_site: value,
    }

    requestWithCSRFToken(url, { method: 'POST', body: JSON.stringify(body) })
      .then((responseData) => {
        setHasCustomerSiteDaysError(!responseData.isUpdated)
        checkMissingLegalInfo()
      })
  }

  const debounceUpdateNbDaysInRemote = (value) => {
    clearTimeout(updateRemoteDaysTimer.current)
    updateRemoteDaysTimer.current = setTimeout(() => updateNbDaysInRemote(value), 500)
  }

  const debounceUpdateNbDaysInCustomerSite = (value) => {
    clearTimeout(updateCustomerOfficeDaysTimer.current)
    updateCustomerOfficeDaysTimer.current = setTimeout(() => updateNbDaysInCustomerSite(value), 500)
  }

  const validationDisabled = missingLegalInfo || hasRemoteDaysError || hasCustomerSiteDaysError

  return {
    activities,
    activityCategories,
    activityCategoryHasInputs,
    restRespected,
    updateRestRespected,
    daysInRemote,
    debounceUpdateNbDaysInRemote,
    hasRemoteDaysError,
    daysInCustomerSite,
    debounceUpdateNbDaysInCustomerSite,
    hasCustomerSiteDaysError,
    missingLegalInfo,
    missingLegalInfoMessages,
    validationDisabled,
    timesheetId: timesheet.id,
    workingDaysCount,
  }
}
