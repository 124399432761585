import React from 'react'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import { Holidays } from '../../components/Holidays/Holidays'

export const HolidaysScreen = ({ absences, activities, holidays, person, isAdmin, isCurrentPerson }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Holidays absences={absences} activities={activities} holidays={holidays} person={person} isAdmin={isAdmin} isCurrentPerson={isCurrentPerson} />
  </ThemeProvider>
)
