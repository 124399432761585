import styled from 'styled-components'

export const ProjectErrorsStyled = styled.div`
  .error-container {
    display: flex;
    flex-direction: ${(props) => (props.errorsColumn ? `column` : `row`)};
    align-items: center;
    justify-content: flex-start;
    min-height: 5.6rem;
    margin-bottom: 2.4rem;
    padding: 0 1.6rem;
    border-radius: 0.3rem;

    .picto {
      content: '';
      display: inline-block;
      width: ${(props) => (props.errorsColumn ? `6.4rem` : `2rem`)};
      height: ${(props) => (props.errorsColumn ? `6.4rem` : `2rem`)};
      margin: ${(props) => (props.errorsColumn ? `1.6rem` : `0.8rem`)};
      margin-right: ${(props) => (props.errorsColumn ? `0rem` : `0.8rem`)};
      background-image: ${(props) => `url(${props.picto}) `};
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .error-text {
      display: flex;
      flex-direction: ${(props) => (props.errorsColumn ? `column` : `row`)};
      align-items: ${(props) => (props.errorsColumn ? `center` : `''`)};
      margin-bottom: ${(props) => (props.errorsColumn ? `1.6rem` : `0rem`)};
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      font-weight: ${(props) => (props.errorsColumn ? `600` : `normal`)};
      font-size: 1.6rem;

      span {
        font-weight: 600;
      }
    }
  }

  .warning {
    border: 0.1rem solid ${({ theme }) => theme.colors.betterway.orange[100]};
    background-color: ${({ theme }) => theme.colors.betterway.orange[5]};
  }

  .danger {
    border: 0.1rem solid ${({ theme }) => theme.colors.betterway.red[100]};
    background-color: ${({ theme }) => theme.colors.betterway.red[5]};
  }
`
