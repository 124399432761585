import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import ActivityLine from '../ActivityLine/ActivityLine'
import { StyledActivityLines } from './ActivityLines.styled'
import { StyledActivityLine } from '../ActivityLine/ActivityLine.styled'
import { StyledActivityInput } from '../ActivityInput/ActivityInput.styled'
import { useTimesheetStateValue } from '../../../../../context/Timesheet/TimesheetStateContext'
import { useActivityUtils } from '../../../../../hooks/useActivityUtils'
import { useTimeinputsContextValue } from '../../../../../context/Timesheet/TimeinputsContext'
import { useActivityValues } from '../../../../../context/Timesheet/ActivityContext'
import { usePeriodStateValue, periodStatuses } from '../../../../../context/Timesheet/PeriodStateContext'

const ActivityLines = observer(
  ({ activityCategory, cumulatedNbActivities, numberOfActivities }) => {
    const { t } = useTranslation()
    const { owner, inputsByActivityThenDay } = useTimesheetStateValue()

    const {
      activities,
      inboundActivityIds,
    } = useActivityValues()
    const {
      daysInMonth,
      holidayAndWeekendDays,
      isHolidayOrFilled,
    } = useActivityUtils()
    const {
      timeinputs,
      timeInputDisabled,
    } = useTimeinputsContextValue()
    const {
      firstPeriodStatus,
      secondPeriodStatus,
      validatedPeriodDays,
    } = usePeriodStateValue()

    const shouldHideDetails = activityCategory === 'absence' && inputsByActivityThenDay.total_absences
    const activityCategoryCapitalized = activityCategory.toString()[0].toUpperCase()
                                        + activityCategory.toString().slice(1)

    const activityInputsSum = inputsByActivityThenDay.total_absences
    const hasOnePeriodNotYetValidated = firstPeriodStatus === periodStatuses.NOT_YET_VALIDATED
                                        || secondPeriodStatus === periodStatuses.NOT_YET_VALIDATED

    return (
      <>
        <StyledActivityLines>
          <th>{t(`activity.activityCategory.${activityCategory}`)}</th>
          <th aria-label="empty header" />
          {
            daysInMonth.map((day, index) => (
              <td
                key={day}
                data-day={index + 1}
                className={[holidayAndWeekendDays.includes(day) && 'holiday', timeInputDisabled(validatedPeriodDays, day, owner) && 'validated'].join(' ')}
              />
            ))
          }
          <td />
        </StyledActivityLines>
        {
          !shouldHideDetails
          && activities[activityCategory.toString()]
            .filter((activity) => hasOnePeriodNotYetValidated
            || activity.id.toString() in timeinputs)
            .map((activity, index) => (
              <ActivityLine
                key={activity.id}
                inbound={inboundActivityIds.includes(activity.id)}
                activityId={activity.id.toString()}
                activityType={activity.type}
                title={activity.full_title}
                even={index % 2 === 0}
                dataX={cumulatedNbActivities + index + 1}
                numberOfActivities={numberOfActivities}
                validatedPeriodDays={validatedPeriodDays}
                limitedHalfOrFullDayInput={activity.absence && activity.absence_input_type === 'half_or_full_day'}
                limitedFullDayInput={activity.absence && activity.absence_input_type === 'full_day'}
                isAbsence={activity.absence}
                comment={activity.comment}
                nbDays={activity.nb_days}
                consumedDaysQuantity={activity.consumed_days}
                editable={activity.editable}
              />
            ))
        }
        {
          shouldHideDetails
          && (
          <StyledActivityLine even>
            <th>
              <span className="activity-title">{`${activityCategoryCapitalized} ${t('activity.noDetails')}`}</span>
            </th>
            <td />
            {
              daysInMonth.map((day, index) => {
                const className = [isHolidayOrFilled(Object.keys(activityInputsSum), day, index), timeInputDisabled(validatedPeriodDays, day, owner) && 'validated'].join(' ')
                const activityCategoryTotalForDay = activityInputsSum[day]
                return (
                  <StyledActivityInput
                    key={day}
                    data-day={index + 1}
                    className={className.length > 0 ? className : undefined}
                  >
                    {
                      shouldHideDetails
                      && activityCategoryTotalForDay
                      && activityCategoryTotalForDay.time_in_hours > 0
                      && (
                      <div>
                        <span>
                          <span className="activity-hour">
                            {
                              activityCategoryTotalForDay.time_in_hours
                            }
                          </span>
                        </span>
                      </div>
                      )
                    }
                  </StyledActivityInput>
                )
              })
            }
            <td>
              {[Object.values(activityInputsSum).reduce((sum, dayTime) => sum + parseFloat(dayTime.time_in_days, 10), 0), t('days.short')].join(' ')}
            </td>
          </StyledActivityLine>
          )
        }
      </>
    )
  },
)

export default ActivityLines
