import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledExpenseSummaryTable } from './ExpenseSummaryTable.styled'
import ExpenseSummaryRow from './ExpenseSummaryRow/ExpenseSummaryRow'

const ExpenseSummaryTable = ({ expenses }) => {
  const { t } = useTranslation()
  return (
    <StyledExpenseSummaryTable>
      <table>
        <thead>
          <tr>
            <th />
            <th>{t('ExpensesReportTable.amount')}</th>
          </tr>
        </thead>
        <tbody>
          {expenses.expenses_by_kind.map((expense_by_kind, index) => (
            <ExpenseSummaryRow
              key={index}
              kind={expense_by_kind.kind}
              total={expense_by_kind.total}
              currency={expenses.currency_symbol}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td className="table-border">
              {t('ExpensesReportTable.total_amount')}
            </td>
            <td className="text-aligned">
              {expenses.total}
              {' '}
              {expenses.currency_symbol}
            </td>
          </tr>
        </tfoot>
      </table>
    </StyledExpenseSummaryTable>
  )
}

export default ExpenseSummaryTable
