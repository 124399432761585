import React, {
  useState,
  createContext,
  useContext,
} from 'react'

export const rateKind = {
  SUMMARY: 'summary',
  AVERAGE: 'average',
  PRESALES: 'presales',
  PROSPECTION: 'prospection',
  INTERNAL: 'internal',
}

export const ActivityRateContext = createContext()

export const ActivityRateProvider = ({ children }) => {
  const [monthlyActivityRate, setMonthlyActivityRate] = useState(0)
  const [yearlyActivityRate, setYearlyActivityRate] = useState(0)

  return (
    <ActivityRateContext.Provider value={
      {
        monthlyActivityRate,
        setMonthlyActivityRate,
        yearlyActivityRate,
        setYearlyActivityRate,
      }
    }
    >
      {children}
    </ActivityRateContext.Provider>
  )
}

export const useActivityRateValue = () => useContext(ActivityRateContext)
