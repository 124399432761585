import { useRef, useState, useEffect } from 'react'

const LISTENER_FREQUENCY = 20

export const useOnScreenResize = () => {
  const listener = useRef(null)
  const [width, setWidth] = useState(window.innerWidth || 0)
  const [height, setHeight] = useState(window.innerHeight || 0)

  const listenForWindowSize = () => {
    const { innerWidth, innerHeight } = window
    if ((innerWidth && innerWidth !== width.current) || (innerHeight && innerHeight !== height.current)) {
      setWidth(innerWidth)
      setHeight(innerHeight)
    }
  }

  useEffect(() => {
    if (listener.current !== null) {
      clearInterval(listener.current)
    }
    listener.current = setInterval(() => listenForWindowSize(), LISTENER_FREQUENCY)

    return () => clearInterval(listener.current)
  },
  [])

  return {
    width,
    height,
  }
}
