import styled from 'styled-components'

export const AvailabilitiesStyled = styled.div`
h2 {
  margin-bottom: 2rem;
  font-size: 2.4rem;
}

div.table-legend {
  margin-bottom: 2rem;
}

>.octo.hard {
  th {
    padding-right: 1em;
    padding-left: 1em;

    &:focus-visible,
    &:focus-within {
      div > span {
        text-decoration: underline;
      }
    }
  }

  td {
    align-items: left;

    div.cell {
      a {
        &.person-link,
        &.project-link {
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          text-decoration: underline;
          cursor: pointer;

          &:visited {
            color: #681da8;
          }

          &:hover,
          &:focus-visible,
          &:focus-within {
            font-weight: 600;
            text-decoration: underline;
          }
        }
      }

      .highlight-value {
        padding: 0.4rem;
        border-radius: 0.4rem;
        background-color: ${({ theme }) => theme.colors.betterway.orange[50]};
      }
    }

    &.value-centered {
      text-align: center;
    }
  }
}

div.filters {
  display: flex;
  gap: 2.4rem;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 4rem;

  div.octo.hard.filled {
    div.control {
      max-width: max-content;

      button {
        &:focus-visible {
          width: auto;
          border-width: 0.3rem;
          border-style: solid;
          border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
        }
      }
    }
  }
}

.octo.hard.availability-modale.opened {
  >div.popup-tooltip {
    width: 50%;

    >div.modal {
      >div.header {
        >button.close {
          &:focus-visible,
          &:focus-within,
          &:focus {
            border-style: double;
            border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
          }
        }
      }

      >.content {
        width: 100%;
      }
    }
  }

  div.octo {
    >section {
      margin-bottom: 1em;
      padding: 1.5em;
      border: 0.0625em solid #cfd3dd;
      border-radius: 1.25em;
      background-color: #f3f4f7;

      > * {
        border: none;

        &:not(:first-child) {
          margin-top: 1rem;
          padding-top: 0;
        }

        &:first-child {
          align-items: center;
          margin-bottom: 1rem;
          border: none;
        }
      }

      >div {
        display: flex;
      }

      .data-title {
        min-width: fit-content;
        margin-right: 0.5rem;
        font-weight: bold;
      }
    }
  }

  div.modal-mattermost-button.octo.hard.primary.default {
    margin-left: 2rem;

    >button {
      height: fit-content;
    }
  }
}
`
