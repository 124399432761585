import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@produits-internes-oss/design-system-components'
import { StyledTimeRecap } from './TimeRecap.styled'

import ActivityRecap from '../ActivityRecap/ActivityRecap'
import { ErrorsBlock } from '../../../TimesheetTable/TimesheetErrors/TimesheetErrors'
import { CommentArea } from '../../../TimesheetTable/TimesheetFooter/Period/Comment/Comment'

const TimeRecap = ({
  activities,
  activityCategories,
  periodTimeinputs,
  activityCategoryHasInputs,
  totalPeriodInputsForActivity,
  totalInputsForPeriod,
  periodErrorsMessages,
  initialComment,
  onChangeComment,
  updatedAt,
  updatedBy,
}) => {
  const { t } = useTranslation()

  return (
    <StyledTimeRecap hascomment={updatedAt}>
      <h3>{t('periodValidationModal.timesRecap')}</h3>
      <h6>{t('periodValidationModal.verifyTimes')}</h6>
      {
        activityCategories.map((activityCategory) => {
          if (activityCategoryHasInputs(activityCategory, periodTimeinputs)) {
            return (
              <ActivitiesRecap
                key={activityCategory}
                activities={activities}
                activityCategory={activityCategory}
                timeinputs={periodTimeinputs}
                totalPeriodInputsForActivity={totalPeriodInputsForActivity}
              />
            )
          }
          return null
        })
      }
      <ActivityRecap
        className="total"
        activity={{ full_title: t('total') }}
        totalInputs={() => (
          {
            inDays: totalInputsForPeriod.inDays,
            inHours: totalInputsForPeriod.inHours,
          }
        )}
      />
      {
        periodErrorsMessages?.length > 0
        && (
        <div className="period-errors">
          <ErrorsBlock
            type="error"
            errors={periodErrorsMessages}
            label="error"
          />
        </div>
)
      }
      <h6 id="comment-title">{t('comment')}</h6>
      <p className="gdpr-info">
        <Icon name="warning" className="warning" />
        <span className="text">{t('gdprInfoCRAComment')}</span>
      </p>
      <CommentArea
        initialComment={initialComment}
        onChangeComment={onChangeComment}
        updatedAt={updatedAt}
        updatedBy={updatedBy}
      />
    </StyledTimeRecap>
  )
}

export default TimeRecap

const ActivitiesRecap = ({
  activities,
  activityCategory,
  timeinputs,
  totalPeriodInputsForActivity,
}) => {
  const { t } = useTranslation()

  const activitiesOfCategory = activities[activityCategory.toString()]

  return (
    <>
      <h5>{t(`activity.activityCategory.${activityCategory}`)}</h5>
      {
        activitiesOfCategory
          .filter((activity) => activity.id.toString() in timeinputs)
          .map((activity, index) => (
            <ActivityRecap
              key={activity.id}
              activity={activity}
              even={index % 2 === 0}
              totalInputs={() => totalPeriodInputsForActivity(activity.id)}
            />
          ))
      }
    </>
  )
}
