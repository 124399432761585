import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledPeriodSubmit } from './PeriodSubmit.styled'

import Button from '../../../../input/Buttons/Button/Button'
import { ErrorsBlock } from '../../../TimesheetTable/TimesheetErrors/TimesheetErrors'

const PeriodSubmit = ({
  validationDisabled,
  submitPeriod,
  missingLegalInfoMessages,
}) => {
  const { t } = useTranslation()

  return (
    <StyledPeriodSubmit>
      {
        missingLegalInfoMessages?.length > 0
        && (
        <div className="legal period-errors">
          <ErrorsBlock
            type="blockingError"
            errors={missingLegalInfoMessages}
            label="legal"
          />
        </div>
)
      }
      <div className="send-period">
        <Button
          disabled={validationDisabled}
          color="primary"
          type="button bold"
          click={() => submitPeriod()}
        >
          {t('period.validate')}
        </Button>
      </div>
    </StyledPeriodSubmit>
  )
}

export default PeriodSubmit
