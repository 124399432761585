import React from 'react'
import { useTranslation } from 'react-i18next'

const ActivityOverviewTableHeader = () => {
  const { t } = useTranslation()

  return (
    <thead>
      <tr>
        <th className="firstCol" rowSpan="2" />
        <th colSpan="4" />
        <th colSpan="5">{t('activityOverview.days')}</th>
        <th className="no-wrap" colSpan="2">{t('activityOverview.turnover')}</th>
        <th colSpan="2">{t('activityOverview.dailyRate')}</th>
      </tr>
      <tr>
        <th>{t('activityOverview.activityRate')}</th>
        <th>
          <>
            <p>{t('activityOverview.activityRate')}</p>
            <p className="small">{t('activityOverview.withPipe')}</p>
          </>
        </th>
        <th>{t('activityOverview.filling')}</th>
        <th className="border-right">{t('activityOverview.consultants')}</th>
        <th>
          {t('activityOverview.buisness')}
          {' '}
        </th>
        <th>{t('activityOverview.billed')}</th>
        <th>
          <>
            <p>{t('activityOverview.unbilled')}</p>
            <p className="small">
              (
              {t('activityOverview.withPrestaffing')}
              )
            </p>
          </>
        </th>
        <th>{t('activityOverview.prestaffing')}</th>
        <th className="border-right">{t('activityOverview.absence')}</th>
        <th>{t('activityOverview.produced')}</th>
        <th className="border-right">{t('activityOverview.brokenDown')}</th>
        <th>{t('activityOverview.produced')}</th>
        <th>{t('activityOverview.brokenDown')}</th>
      </tr>
    </thead>
  )
}

export default ActivityOverviewTableHeader
