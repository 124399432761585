import styled from 'styled-components/macro'

export const StyledTurnoverRow = styled.tr`
  td {
    text-align: right;
    font-weight: bold;
    line-height: 30px;
    font-size: 13px;
    border-bottom: ${({ theme }) => `0.5px solid ${theme.colors.primaryDark}`};
    border-right:${({ theme }) => `0.5px solid ${theme.colors.primaryDark}`};
    &.firstCol {
      padding-left: 5px;
    }
}
`
