import { useState } from 'react'

import { getDateStringFromTimestamp } from './helpers/datePickerHelper'

const urlSlashCode = (str) => str.replace(/\//gi, '%2F')

export const useFetch = async (method, url, content, contentIsFormData, responseToJson = true) => {
  const errors = []

  try {
    const bodyContent = contentIsFormData ? content : JSON.stringify(content)
    const headers = { 'X-CSRF-TOKEN': document.querySelector('[name=csrf-token]')?.content }

    if (!contentIsFormData) {
      headers['Content-Type'] = 'application/json'
      headers.Accept = 'application/json'
    }

    const response = await fetch(url, {
      headers,
      method,
      body: content ? bodyContent : undefined,
    })

    if (responseToJson) {
      return response.status !== 204 ? await response.json() : {}
    }
    return response.status !== 204 ? await response : {}
  } catch (error) {
    errors.push(error.message)
  }

  return { errors }
}

export const useFetchData = (loadingOnMount) => {
  const [loading, setLoading] = useState(loadingOnMount)
  const [hasError, setError] = useState(false)
  const [data, setData] = useState(null)

  const handleFetch = async (isMounted, url) => {
    setLoading(true)
    setError(false)

    const response = await fetch(url)
    const json = await response.json()

    if (isMounted.current) {
      if (response.ok) {
        setData(json)
      } else {
        setError(true)
      }
      setLoading(false)
    }
  }

  return [loading, hasError, data, handleFetch]
}

export const buildUrl = (entity, startDate, endDate) => {
  const rangeStart = getDateStringFromTimestamp(startDate)
  const rangeEnd = getDateStringFromTimestamp(endDate)

  return `${entity}_activity_overview?start_date=${urlSlashCode(rangeStart)}&end_date=${urlSlashCode(rangeEnd)}`
}

export const buildUrlWithId = (entity, id, range) => {
  const rangeStart = getDateStringFromTimestamp(range.startDate)
  const rangeEnd = getDateStringFromTimestamp(range.endDate)

  return `/${entity}_activity_overview/${id}?start_date=${urlSlashCode(rangeStart)}&end_date=${urlSlashCode(rangeEnd)}`
}
