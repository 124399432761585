import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import chevronBottom from '../../../../assets/images/chevronBlueBottom.svg'
import chevronRight from '../../../../assets/images/chevron.svg'
import hourglass from '../../../../assets/images/hourglass.svg'
import ManagePeriodsSearch from '../ManagePeriodsSearch/ManagePeriodsSearch'
import SendEmailToFilteredPeople from '../SendEmailToFilteredPeople/SendEmailToFilteredPeople'
import ManagePeriodsValidationButton from '../ManagePeriodsValidationButton/ManagePeriodsValidationButton'
import { ManagePeriodsHeaderStyled } from './ManagePeriodsHeader.styled'
import { useManagerPeriodsContext } from '../../../context/ManagePeriods/ManagePeriodsContext'

export default function ManagePeriodsHeader({ personIsAdmin }) {
  const { t } = useTranslation()
  const [accordionVisible, setAccordionVisible] = useState(true)
  const {
    validatePeriodsJobLaunch,
    people,
    activeTab,
  } = useManagerPeriodsContext()

  const toggleAccordion = () => {
    setAccordionVisible(!accordionVisible)
  }

  const jobConfirmationAlert = () => (
    <div className="in-progress-alert">
      <div className="img-circle">
        <img src={hourglass} alt="hourglass" />
      </div>
      <p>{`${t('managePeriods.alertMessage.validationInProgress')}`}</p>
    </div>
  )

  const countPeople = () => (people.length > 0 ? `(${people.length})` : '')

  return (
    <ManagePeriodsHeaderStyled>
      <h1>{`${t('managePeriods.title')}`}</h1>
      { validatePeriodsJobLaunch && jobConfirmationAlert()}
      <div className="header-content">
        <div className="header-content-left">
          <h2>{`${t('managePeriods.search.title')}`}</h2>
          <ManagePeriodsSearch className="search" />
          {
            (activeTab === 'unvalidated') && (
              <>
                <button
                  className="chevron"
                  aria-label="chevron open close"
                  type="button"
                  onClick={() => toggleAccordion()}
                >
                  {`${t('managePeriods.validatePeriods.accordion.title')} ${countPeople()}`}
                  <img src={accordionVisible ? chevronBottom : chevronRight} alt="chevron" />
                </button>
                <ul className={accordionVisible ? 'active-accordion' : 'inactive-according'}>
                  <li>
                    {`${t('managePeriods.validatePeriods.accordion.rest.message')}`}
                    <b>
                      {`${t('managePeriods.validatePeriods.accordion.rest.value')}`}
                    </b>
                  </li>
                  <li>
                    {`${t('managePeriods.validatePeriods.accordion.remote.message')}`}
                    <b>{`${t('managePeriods.validatePeriods.accordion.remote.value')}`}</b>
                  </li>
                </ul>
                { personIsAdmin && <ManagePeriodsValidationButton className="validationButton" /> }
              </>
            )
          }
        </div>
        {
          (activeTab === 'unvalidated' || activeTab === 'emptyDays') && (
            <div className="header-content-right">
              <SendEmailToFilteredPeople personIsAdmin={personIsAdmin} />
            </div>
          )
        }
      </div>
    </ManagePeriodsHeaderStyled>
  )
}
