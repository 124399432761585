import styled from 'styled-components'
import destroyFull from '../../../assets/images/destroyFull.svg'
import destroyEmpty from '../../../assets/images/destroyEmpty.svg'

export const ProjectSalesActivitiesStyled = styled.div`
  .copy {
    width: fit-content;
    margin-bottom: 1.5rem;

    &.octo.hard.default {
      > button {
        padding: 0;
        border: none;
        background: none;
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
        font-weight: normal;
        font-size: 1.4rem;
        text-decoration: underline;

        &:hover {
          background: none;
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          font-weight: bold;
        }

        &:focus,
        &:focus-visible {
          border: none;
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
        }

        ::before {
          content: none;
        }

        >.icon {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }

  .wrapper {
    position: relative;
    overflow: auto;
    border-radius: 2rem;
    white-space: nowrap;
  }

  &.octo {
    margin-bottom: 2.4rem;

    > h2 {
      margin-top: 2.4rem;
      font-weight: 600;
      font-size: 2.4rem;
    }

    >div > div.table {
      margin-top: 1.6rem;

      th {
        vertical-align: top;

        &[aria-label='people'] {
          min-width: 30rem;
        }

        &[aria-label='in_idf'],
        &[aria-label='jobboard'] {
          min-width: 2rem;
        }

        &[aria-label*='nb_days'],
        &[aria-label*='average_daily_rate'] {
          >div.cell {
            min-width: 7rem;
          }
        }

        &[aria-label*='nb_days'] {
          >div.cell {
            min-width: 7rem;
          }
        }

        &[aria-label*='quantity'] {
          display: flex;
          justify-content: right;

          >div.cell {
            width: 10rem;

            .control {
              min-width: 10rem;
            }
          }
        }
      }

      td {
        ul {
          >li {
            >button[aria-label*='staffing_needed_from'] {
              width: fit-content;
              margin: auto;
            }
          }
        }

        &[aria-label*='people'] > .cell {
          width: 27rem;

          .activity-person {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            white-space: normal;

            a {
              text-decoration: underline;
              cursor: pointer;

              &:hover {
                font-weight: 600;
              }
            }

            div.destroy {
              width: auto;

              button {
                gap: 0;
                height: fit-content;
                padding: 0;
                border: unset;
                background-color: unset;

                .icon {
                  border: none;
                  background-image: url(${destroyEmpty});
                  mask-image: url(${destroyEmpty});
                }

                &:hover {
                  color: ${({ theme }) => theme.colors.betterway.primary[100]};

                  .icon {
                    border: none;
                    background-image: url(${destroyFull});
                    mask-image: url(${destroyFull});
                  }
                }

                &:disabled,
                &:disabled:hover {
                  color: ${({ theme }) => theme.colors.primaryGrey};

                  .icon {
                    border: none;
                    background-image: none;
                    mask-image: url(${destroyEmpty});
                  }
                }
              }
            }
          }
        }

        &[aria-label*='in_idf'] > .cell {
          display: flex;
          justify-content: center;
        }

        &[aria-label*='selling_price'],
        &[aria-label*='total'] {
          vertical-align: middle;
        }

        &.align-right {
          >div.cell {
            text-align: right;

            &[aria-label*='nb_days'] {
              >div.cell {
                width: 7rem;
              }
            }

            >span.unit {
              justify-content: flex-end;

              >div.input-with-unit > div.control {
                gap: 0;
              }
            }
          }
        }

        &[aria-label*='average_daily_rate'] {
          >div.cell {
            width: 8rem;

            .control {
              min-width: 8rem;
            }
          }
        }

        &[aria-label*='nb_days'] {
          >div.cell {
            width: 8rem;

            .control {
              min-width: 8rem;
            }
          }
        }

        &[aria-label*='quantity'] {
          height: 100%;

          >div.cell {
            width: 9rem;

            .control {
              min-width: 9rem;
            }
          }
        }
      }

      tfoot {
        >tr {
          >td {
            &[aria-label*='nb_days'],
            &[aria-label*='total'] {
              >div.cell {
                text-align: right;

                >span.unit.price {
                  justify-content: flex-end;
                }
              }
            }

            &[aria-label*='average_daily_rate'] {
              >div.cell {
                min-width: 10rem;
              }
            }

            &[aria-label*='nb_days'] {
              >div.cell {
                min-width: 7rem;
              }
            }

            &[aria-label*='quantity'] {
              width: 7rem;
              min-width: 7rem;

              >div.cell {
                width: 10rem;

                .control {
                  min-width: 10rem;
                }
              }
            }
          }
        }
      }

      img {
        width: 4rem;
        height: 4rem;
        margin: auto;
      }
    }

    > .below-table-button,
    > .below-table-button > div {
      display: flex;
      gap: 0.8rem;
      width: auto;
      margin-top: 1rem;
    }

    .button-control {
      display: flex;
      gap: 1rem;
      width: 100%;
      margin-top: 1rem;

      .delete-activity {
        width: auto;

        button {
          width: auto;

          span {
            position: initial;
            transform: none;
          }
        }
      }
    }

    .cell > div.input-radio-destroy {
      display: flex;
      gap: 1rem;
      align-items: center;

      img {
        width: 2.4rem;
        height: 2.4rem;
        margin: 0;
      }

      .button-delete-activity {
        width: auto;

        &.disabled {
          button {
            cursor: not-allowed;

            .checkbox {
              background-color: ${({ theme }) => theme.colors.betterway.primary[70]} !important;

              .checkbox-icon {
                background-color: ${({ theme }) => theme.colors.betterway.primary[70]} !important;
              }
            }
          }
        }
      }

      .control {
        padding: 0;
      }
    }

    .delete-mode-hide {
      width: 0%;
    }

    .empty-background-modal:not(.staffing-modal) {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 3000;
      width: 100vw;
      height: 100vh;
      background-color: #00000061;

      .container-modal {
        position: fixed;
        top: 40%;
        left: 50%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 96rem;
        height: 15rem;
        padding: 3.2rem 4rem;
        border-radius: 20px;
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
        box-shadow: 0 4px 8px 0 ${({ theme }) => theme.colors.shadow};
        transform: translateX(-50%);

        .header-modal {
          display: flex;
          justify-content: end;
          width: 100%;

          .title {
            display: flex;
            justify-content: center;
            width: 100%;
            font-weight: 600;
            font-size: 1.8rem;
          }

          .picto-close {
            cursor: pointer;
          }
        }

        .content-modal {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .text {
            width: fit-content;
            height: auto;
          }
        }
      }
    }

    >.subcontracting-table {
      thead {
        >tr > th:first-child {
          width: 42rem;
        }

        >tr > th:nth-child(8) {
          width: 5rem;
        }
      }
    }

    >.subcontracting-table,
    .intragroup-table {
      tbody td {
        vertical-align: top;
      }
    }

    .unit {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;

      &.price {
        white-space: pre;

        .control::after {
          content: ' €';
          display: flex;
          flex-flow: row;
          align-items: center;
          justify-content: end;
          margin-bottom: 0.2rem;
          white-space: pre;
        }

        .control {
          min-width: 12rem !important;
        }
      }

      .quantity {
        >.control {
          min-width: 4rem;
        }
      }
    }

    .quantity {
      >.control {
        min-width: 4rem;
      }
    }

    .unit-price,
    .markup {
      >.control {
        min-width: 11rem;
      }
    }

    .purchase-order {
      >.control {
        min-width: 13rem;
      }
    }

    .octo.hard > .description {
      flex-flow: row;
      padding: 0.5em 0 0;
    }

    .total {
      font-weight: 600;
    }

    .expense-status {
      width: 100%;
      margin: auto;
      margin-top: 0.8rem;

      .expense-status-toggle {
        display: inline-block;
        vertical-align: middle;
      }

      span {
        margin-left: 0.8rem;
        font-weight: 600;
        font-size: 1.4rem;
      }
    }

    .toggle-btn {
      position: relative;
      width: 3.6rem;
      height: 2.1rem;
      border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[100]};
      border-radius: 10rem;
      background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
      cursor: pointer;
    }

    .btn-inactive {
      border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[50]};
      background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
    }

    span {
      position: absolute;
      top: 50%;
      left: 0.45rem;
      margin: 0.7rem 0.2rem;
      user-select: none;
      transition: all 0.5s ease;
      transform: translateY(-62%);
    }

    .circle {
      position: absolute;
      top: 50%;
      width: 1.3rem;
      height: 1.3rem;
      border-radius: 10rem;
      transition: all 0.5s ease;
      transform: translateY(-50%);
    }

    .off {
      left: 0.3rem;
      background-color: ${({ theme }) => theme.colors.betterway.white};
    }

    .on {
      left: calc(100% - 1.7rem);
      background-color: ${({ theme }) => theme.colors.betterway.white};
    }

    .total-section {
      margin-top: 2.4rem;

      .activities-total {
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-weight: 600;

        p b {
          margin-left: 1rem;
          font-weight: 600;
          font-size: 2.4rem;
        }
      }
    }

    .copy-succeed {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
      padding: 1.6rem;
      border: solid 0.2rem ${({ theme }) => theme.colors.betterway.green[100]};
      border-radius: 0.3rem;
      background-color: ${({ theme }) => theme.colors.betterway.green[5]};
    }
  }
`
