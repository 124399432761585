import { useEffect, useState } from 'react'

import { clamp } from '../../helpers/numberHelper'

export default (itemCount, itemPerPage) => {
  if (itemPerPage <= 0) {
    throw new RangeError(`pagination number of item per page must be strictly positive (received ${itemPerPage})`)
  }

  const [page, setPage] = useState(0)

  const pageCount = itemCount > 0 ? Math.ceil(itemCount / itemPerPage) : 1
  const pageLast = pageCount - 1
  const pageStart = page * itemPerPage
  const pageEnd = pageStart + itemPerPage - 1
  const pageEndBlanks = page === pageLast ? pageEnd - itemCount + 1 : 0

  const getNavigator = () => {
    let pages = []
    if (pageCount <= 5) {
      pages = [...Array(pageCount)].map((_, pageIndex) => `${pageIndex + 1}`)
    } else if (page === 0 || page === 1) {
      pages = ['1', '2', '...', '...', `${pageLast + 1}`]
    } else if (page === pageLast || page === pageLast - 1) {
      pages = ['1', '...', '...', `${pageLast}`, `${pageLast + 1}`]
    } else {
      pages = ['1', '...', `${page + 1}`, '...', `${pageLast + 1}`]
    }

    return ({
      current: `${page + 1}`,
      pages,
    })
  }

  const navigateToPage = (target) => {
    setPage(clamp(target, 0, pageLast))
  }

  useEffect(() => {
    navigateToPage(page)
  }, [itemCount, itemPerPage])

  return {
    page,
    pageCount,
    pageLast,
    pageStart,
    pageEnd,
    pageEndBlanks,
    getNavigator,
    navigateToPage,
  }
}
