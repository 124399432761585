import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@produits-internes-oss/design-system-components'
import { useProjectSummaryContext } from '../../context/ProjectSummaryContext/ProjectSummaryContext'
import { ProjectAffiliatedStyled } from './ProjectAffiliated.styled'
import pictoMoneyStack from '../../../assets/images/moneyStack.svg'
import pictoFileCase from '../../../assets/images/fileCase.svg'
import pictoExternalLink from '../../../assets/images/navigationExternalLinkBlue.svg'
import { parseFloatWithAnySeparator, getNumberWithUnit } from '../../helpers/stringHelper'
import Link from '../Link/Link'

export const ProjectAffiliated = () => {
  const { t } = useTranslation()
  const { project, fetchProjectAffiliated, projectAffiliated } = useProjectSummaryContext()

  useEffect(() => {
    if (project && project.projects_affiliation_id) {
      fetchProjectAffiliated()
    }
  }, [project])
  if (!project) return <div>...</div>

  if (project.projects_affiliation_id && projectAffiliated === null) return <div>...</div>

  return (
    <ProjectAffiliatedStyled>
      <div className="container-affiliated">
        <div className="infos-affiliated">
          <div className="pictos">
            <img aria-label="file case image" src={pictoFileCase} />
          </div>
          <div className="number-affiliated">{(project.projects_affiliation_id ? projectAffiliated.projects.length : 0)}</div>
          <Link
            className={project.projects_affiliation_id ? 'affiliated-mission' : 'affiliated-mission disabled-link'}
            name="affiliated-mission"
            href={`/projects_affiliations/${project.projects_affiliation_id}`}
          >
            <span>
              {project.projects_affiliation_id ? t(`project.summary.affiliated.title`) : t(`project.summary.affiliated.titleNoneAffiliated`)}
            </span>
            <img aria-label="picto link externe affiliated mission" src={project.projects_affiliation_id ? pictoExternalLink : null} />

          </Link>
        </div>
        <div className="vertical-line" />
        <div className="infos-affiliated">
          <div className="pictos">
            <img aria-label="money stack image" src={pictoMoneyStack} />
          </div>
          <div className="details-section">
            <h2>{project.projects_affiliation_id ? t('project.summary.affiliated.totalAmount') : t('project.summary.affiliated.noTotalAmount') }</h2>
            <Tooltip
              className="affiliated-project-tooltip"
              position="top"
              skin="light"
              trigger={
               ({ open, close }) => (
                 <button
                   type="button"
                   onMouseEnter={() => open()}
                   onMouseLeave={() => close()}
                   className="tooltip-trigger-button"
                   aria-label="tooltip trigger button"
                 />
               )
              }
              content={
                () => (
                  <div>
                    <p>
                      {t('project.summary.affiliated.tooltipContent.first')}
                      <span>
                        {' '}
                        {t('project.summary.affiliated.tooltipContent.second')}
                      </span>
                      {' '}
                      {t('project.summary.affiliated.tooltipContent.third')}
                    </p>
                  </div>
                )
              }
            />
          </div>
          <div className="amount-project">
            {project.projects_affiliation_id ? `${getNumberWithUnit(parseFloatWithAnySeparator(projectAffiliated.total_amount), '€')}` : '- €' }
          </div>
        </div>
      </div>
    </ProjectAffiliatedStyled>
  )
}
