import styled from 'styled-components'

export const ProjectFormStyled = styled.div`
  width: 90%;
  margin: auto;
  font-family: 'Outfit', sans-serif;
  letter-spacing: 0.08rem;

  > a.return {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4rem;
    color: ${({ theme }) => theme.colors.betterway.primary[100]};

    > span.return-icon {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.4rem;
      transform: rotateZ(180deg);
    }

    > span.return-text {
      font-size: 1.4rem;
      text-decoration: underline;
    }
  }

  > h1 {
    font-weight: 600;
    font-size: 3.2rem;
    line-height: normal;
  }

  > label {
    margin-bottom: 2.4rem;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: normal;

    >.asterisk {
      color: ${({ theme }) => theme.colors.betterway.red[100]};
    }
  }

  >.forms {
    display: flex;
    flex-flow: column nowrap;
    gap: 4rem;
    align-items: stretch;
    justify-content: stretch;

    > button,
    > input {
      &:focus-visible {
        width: auto;
        border-width: 0.3rem;
        border-style: solid;
        border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
      }
    }
  }

  >.controls {
    display: flex;
    flex-flow: row nowrap;
    gap: 1.6rem;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;

    >.cancel.default {
      button {
        border-width: 0.2rem;
        border-style: solid;
        border-color: ${({ theme }) => theme.colors.betterway.primary[100]};

        &:hover {
          border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
          background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
        }
      }
    }

    .popup-text {
      padding: 1rem;

      p {
        padding: 1rem;

        em {
          font-weight: 600;
        }
      }
    }

    .duplication-buttons {
      display: flex;
      gap: 1rem;
      justify-content: flex-end;
      width: 100%;
    }

    >.popup-tooltip {
      >.invalid {
        display: flex;
        flex-flow: column nowrap;
        gap: 1.6rem;
        align-items: flex-start;
        justify-content: flex-start;

        >.invalid-category {
          display: flex;
          flex-flow: column nowrap;
          gap: 0.8rem;
          align-items: flex-start;
          justify-content: flex-start;

          > h4 {
            font-weight: 600;
          }

          > ul {
            > li {
              padding-left: 0.8rem;

              ::before {
                content: '- ';
              }
            }
          }
        }
      }
    }

    >.loading {
      >.spin {
        width: 2rem;
        height: 2rem;
        margin: 0;
      }
    }
  }

  >.tip {
    margin-top: 0.8rem;
    padding-bottom: 4.8rem;
    font-size: 1.4rem;
    text-align: center;
  }

  >.form-cta {
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 4rem;
    padding: 1rem 0;
    background-color: ${({ theme }) => theme.colors.betterway.white};
  }

  .octo > button.header {
    &:focus-visible {
      width: 100%;
      border-width: 0.3rem;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
    }
  }

  .octo.hard.form-control {
    .control {
      button,
      textarea,
      input {
        &:focus-visible {
          width: auto;
          border-width: 0.3rem;
          border-style: solid;
          border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
        }
      }
    }
  }

  .content > .form-section > section > .columns > .column > h3 {
    margin-bottom: 1rem !important;
  }
`
