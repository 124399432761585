import styled from 'styled-components'
import pictoInfo from '../../../assets/images/exclamationInfo.svg'

export const ProjectDataHatStyled = styled.div.attrs({ className: 'octo' })`
  color: ${({ theme }) => theme.colors.betterway.white};

  .container {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    color: ${({ theme }) => theme.colors.betterway.white};
    font-weight: 600;
    font-family: 'Outfit', sans-serif;

    .infos-head {
      .project-name {
        width: 100%;
        margin-top: 3.2rem;
        font-size: 3.2rem;
        line-height: normal;
        text-transform: uppercase;
      }

      .project-options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 2.8rem;
        font-size: 1.4rem;

        .modify-project {
          display: flex;
          gap: 1;
          align-items: center;

          .duplicate {
            margin-right: 1.6rem;
          }
        }
      }

      .project-tags {
        display: flex;
        justify-content: flex-end;
        margin: 2rem 0;

        div {
          margin-left: 1.4rem;
        }
      }
    }

    .infos-body {
      .project-infos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 1.2rem;
        font-size: 1.4rem;

        .infos {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: auto;
          font-weight: 500;

          .tag {
            display: flex;
            align-items: center;
            width: max-content;
            height: 1.75rem;
            padding: 0.5rem;
            border: solid 0.125rem ${({ theme }) => theme.colors.betterway.white};
            border-radius: 3px;
            color: ${({ theme }) => theme.colors.betterway.primary[100]};
            font-size: 1.6rem;
          }

          .mission-maker {
            background-color: ${({ theme }) => theme.colors.betterway.yellow[100]};
          }

          .nature {
            margin-left: 1.6rem;
            background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
          }
        }

        hr {
          width: 100%;
          margin: 0 2rem;
          border: none;
          border-top: solid 1px ${({ theme }) => theme.colors.betterway.white};
        }
      }

      .project-infos-value {
        display: flex;
        gap: 32px;
        align-items: center;
        align-items: flex-start;
        width: 100%;
        margin-top: 2.4rem;
        font-size: 1.6rem;

        .infos-value {
          display: flex;
          flex-direction: row;
          gap: 1rem;
          width: 70%;
          height: auto;
          padding: 2.4rem;
          border-radius: 20px;
          background-color: ${({ theme }) => theme.colors.paleBlue4};

          li {
            display: flex;
            margin-right: 1rem;
            list-style: none;
            line-height: 4rem;

            .type-infos {
              margin-right: 0.5rem;
              font-weight: normal;
              font-size: 1.6rem;
            }

            .type-infos-location {
              width: auto;
              margin-right: 0.5rem;
              font-weight: normal;
              font-size: 1.6rem;
            }

            .sector-name {
              display: flex;
              flex-direction: column;
              width: 17.8rem;
              margin-top: 1.1rem;
              line-height: initial;
            }

            .location {
              display: flex;
              flex-direction: column;
              width: 17.8rem;
              margin-top: 1.1rem;
              line-height: initial;
            }
          }
        }

        ul {
          margin: 0;
        }

        .infos-finance {
          display: flex;
          flex-direction: column;
          width: 30%;
          height: 16rem;
          padding: 2.4rem;
          border-radius: 20px;
          background-color: ${({ theme }) => theme.colors.betterway.white};
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          font-weight: normal;
          font-size: 1.8rem;

          li {
            list-style: none;
            line-height: 5rem;

            .infos-finance-value {
              display: inline-flex;
              font-weight: 600;
              font-size: 2rem;

              >.amount-tooltip {
                align-self: center;
                margin-left: 1rem;

                >.popup-trigger {
                  >button {
                    width: 2rem;
                    height: 2rem;
                    background-image: url(${pictoInfo});
                    background-position: center;
                    background-clip: border-box;
                    background-size: contain;
                    background-repeat: no-repeat;
                    cursor: pointer;
                  }
                }

                >.popup-tooltip {
                  font-weight: 200;
                  font-size: 1.4rem;

                  > div {
                    > p {
                      > b {
                        display: inline;
                        font-weight: 600;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .infos-footer {
      display: flex;
      gap: 1rem;
      width: 100%;
      margin-top: 1.2rem;
      color: ${({ theme }) => theme.colors.betterway.white};
      font-weight: lighter;
      font-size: 1.4rem;
      text-decoration: underline;

      .description-mission,
      .commentary-mission {
        width: auto;
        height: auto;
        border: none;

        button {
          width: auto;
          height: auto;
          padding: 0;
          border: none;
          background-color: transparent;
          color: ${({ theme }) => theme.colors.betterway.white};
          font-weight: lighter;
          font-size: 1.4rem;
          text-decoration: underline;

          &:hover {
            border: none;
            background-color: transparent;
          }
        }
      }

      .disabled-link {
        color: ${({ theme }) => theme.colors.primaryGrey};
        pointer-events: none;
      }

      a {
        color: ${({ theme }) => theme.colors.betterway.white};
        font-weight: lighter;
        font-size: 1.4rem;
        text-decoration: underline;

        img {
          margin-left: 0.5rem;
        }
      }
    }
  }

  >.header {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    flex-flow: column nowrap;
    gap: 1.6rem;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    padding: 1.6rem 10vw;
    background-color: ${({ theme }) => theme.colors.betterway.primary[100]};

    >.controls {
      display: flex;
      flex-flow: row nowrap;
      gap: 1.6rem;
      align-items: center;
      justify-content: flex-end;

      > a.return {
        display: flex;
        flex-flow: row wrap;
        gap: 0.2rem;
        gap: 0.8rem;
        align-items: center;
        justify-content: stretch;
        margin-right: auto;
        color: ${({ theme }) => theme.colors.betterway.white};
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    >.name {
      font-size: 3.2rem;

      > b {
        font-weight: 600;
      }

      > span {
        font-weight: normal;
      }
    }

    >.tags {
      display: flex;
      flex-flow: row wrap;
      gap: 1.6rem;
      align-items: center;
      justify-content: flex-end;
    }
  }

  >.details {
    display: flex;
    flex-flow: row nowrap;
    gap: 1.6rem;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    padding: 1.6rem 10vw;
    background-color: ${({ theme }) => theme.colors.paleBlue4};

    > .details-left {
      flex: 1;

      > hr {
        border-color: ${({ theme }) => theme.colors.betterway.primary[50]};
        border-top: 0;
      }

      >.details-summary {
        display: flex;
        flex-flow: row nowrap;
        gap: 1.6rem;
        align-items: center;
        justify-content: stretch;

        >.tag[color='cyan'] {
          border-color: ${({ theme }) => theme.colors.betterway.cyan[100]};
          background-color: ${({ theme }) => theme.colors.betterway.cyan[100]};
        }

        >.tag[color='yellow'] {
          border-color: ${({ theme }) => theme.colors.betterway.yellow[100]};
          background-color: ${({ theme }) => theme.colors.betterway.yellow[100]};
        }

        >.dates {
          display: flex;
          flex-flow: row nowrap;
          gap: 1.6rem;
          align-items: center;
          justify-content: flex-start;

          >.arrow {
            font-weight: 600;
            font-size: 2.4rem;
          }
        }
      }

      >.details-infos {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.6rem;
      }

      >.details-links {
        display: flex;
        flex-flow: row wrap;
        gap: 1.6rem;
        align-items: center;
        justify-content: flex-start;

        a,
        button {
          display: flex;
          flex-flow: row wrap;
          gap: 0.2rem;
          align-items: center;
          justify-content: stretch;
          color: ${({ theme }) => theme.colors.betterway.white};
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    >.details-right {
      display: flex;
      flex-basis: 40rem;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: stretch;

      >.finance {
        display: flex;
        flex-flow: column nowrap;
        gap: 2rem;
        align-items: stretch;
        justify-content: stretch;
        padding: 2.4rem;
        border-radius: 2rem;
        background-color: ${({ theme }) => theme.colors.betterway.white};
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
      }

      >.other {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.6rem;

        >.expense {
          column-span: 2;
        }
      }
    }
  }
`
