import styled from 'styled-components/macro'

export const StyledSkeleton = styled.div`
  width: ${({ width }) => width};
  height: ${({ lineHeight }) => lineHeight};

  div {
    height: ${({ height }) => height};
    border-radius: ${({ circle }) => (circle ? '50%' : '5px')};
    position: relative;
    background: ${({ theme }) => theme.colors.paleGrey};
    animation-name: aniHorizontal;
    animation-duration: 1500ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  @keyframes aniHorizontal {
    0% {
      opacity: .3;
    }

    100% {
      opacity: 1;
    }
  }
`
