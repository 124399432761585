import React from 'react'
import { useTranslation } from 'react-i18next'
import { TooltipProvider } from '../../context/Tooltip/Tooltip'
import { ProjectTurnoverBreakdownContextProvider } from '../../context/ProjectTurnoverBreakdownContext/ProjectTurnoverBreakdownContext'
import { ProjectTurnoverBreakdownStyled } from './ProjectTurnoverBreakdown.styled'
import { ProjectTurnoverBreakdownAlert } from '../ProjectTurnoverBreakdownAlert/ProjectTurnoverBreakdownAlert'
import { ProjectTurnoverBreakdownTable } from '../ProjectTurnoverBreakdownTable/ProjectTurnoverBreakdownTable'
import pictoRevenue from '../../../assets/images/pictoTurnoverBreakdown.svg'

export const ProjectTurnoverBreakdown = ({ id }) => {
  const { t } = useTranslation()
  const openReporting = { text: t('open'), arialabel: 'open the cutting dropdown' }
  const closeReporting = { text: t('close'), arialabel: 'close the cutting dropdown' }

  return (
    <ProjectTurnoverBreakdownContextProvider id={id}>
      <TooltipProvider tooltipsConfiguration={{
        proposal: { position: 'top', hasArrow: true },
        comment: { position: 'bottom', hasArrow: false },
      }}
      >
        <ProjectTurnoverBreakdownStyled
          title={t('turnoverBreakdown.title')}
          localization={{ open: openReporting, close: closeReporting }}
          picto={pictoRevenue}
          withStartupOpen
        >
          <ProjectTurnoverBreakdownAlert className="turnover-breakdown-alert" />
          <ProjectTurnoverBreakdownTable className="turnover-breakdown-table" />
        </ProjectTurnoverBreakdownStyled>
      </TooltipProvider>
    </ProjectTurnoverBreakdownContextProvider>
  )
}
