import styled from 'styled-components'

export const OpportunitiesStyled = styled.div`
h2 {
  margin-bottom: 2rem;
  font-size: 2.4rem;
}

.filters {
  display: flex;
  gap: 2.4rem;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.filter {
  max-width: 40rem;
}

.octo.hard .control > button {
  &:focus-visible {
    width: auto;
    border-width: 0.3rem;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
  }
}

>.octo.hard {
  th {
    padding-right: 1em;
    padding-left: 1em;

    &:focus-visible,
    &:focus-within {
      div > span {
        text-decoration: underline;
      }
    }
  }

  .loader {
    top: 56%;
    justify-self: center;
    width: 40px;
    height: 40px;
    border: 0.3em solid transparent;
    border-top-color: #00a9c5;
    border-radius: 50%;
    animation: 1s loader linear infinite;
  }

  a {
    &.business-contact-link,
    &.project-link,
    &.person-link {
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      text-decoration: underline;
      cursor: pointer;

      &:visited {
        color: #681da8;
      }

      &:hover,
      &:focus-visible,
      &:focus-within {
        font-weight: 600;
        text-decoration: underline;
      }
    }

    &.person-askbob-link {
      cursor: pointer;

      &:hover {
        cursor: pointer;
      }
    }
  }

  td {
    &.value-centered {
      text-align: center;
    }

    div {
      &.status {
        padding: 0.8rem;
        border-radius: 0.3rem;
      }

      &.lead {
        background-color: ${({ theme }) => theme.colors.betterway.orange[100]};
        color: white;
      }

      &.proposal-in-progress {
        background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
      }

      &.proposal-sent {
        background-color: ${({ theme }) => theme.colors.betterway.secondary[70]};
      }

      &.mission-accepted {
        background-color: ${({ theme }) => theme.colors.betterway.green[50]};
      }

      &.mission-signed {
        background-color: ${({ theme }) => theme.colors.betterway.green[50]};
      }

      &.mission-done {
        background-color: ${({ theme }) => theme.colors.betterway.green[100]};
      }
    }
  }
}

.octo.hard.opportunity-modale.opened {
  >div.popup-tooltip {
    width: 50%;

    >div.modal {
      >div.header {
        >button.close {
          &:focus-visible,
          &:focus-within,
          &:focus {
            border-style: double;
            border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
          }
        }
      }

      >.content {
        width: 100%;
      }
    }
  }

  div.octo {
    >section {
      margin-bottom: 1em;
      padding: 1.5em;
      border: 0.0625em solid #cfd3dd;
      border-radius: 1.25em;
      background-color: #f3f4f7;

      > * {
        border: none;

        &:not(:first-child) {
          margin-top: 1rem;
          padding-top: 0;
        }

        &:first-child {
          align-items: center;
          margin-bottom: 1rem;
          border: none;
        }
      }

      >div {
        display: flex;

        img.person-picture {
          overflow: hidden;
          width: 2em;
          height: 2em;
          margin-left: 1rem;
          border-style: solid;
          border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
          border-radius: 50%;
        }
      }

      .data-title {
        min-width: fit-content;
        margin-right: 0.5rem;
        font-weight: bold;
      }

      .inline-project-description {
        display: flex;
      }

      .activity-role {
        min-width: max-content;
        margin-right: 0.5rem;
      }
    }
  }

  div.mattermost-button.octo.hard.primary.default {
    align-items: flex-end;
    margin-top: 2rem;

    >button {
      width: fit-content;
    }
  }
}
`
