import styled, { css } from 'styled-components'

export const StyledActivityLine = styled.tr`

  font-size: 1.2rem;
  ${({ activityError }) => activityError && css`
      background: ${({ theme }) => theme.colors.blockingErrorLight};
  `}
  ${({ activityError }) => !activityError && css`
      background: ${({ theme, even }) => (even ? theme.colors.paleGrey3 : theme.colors.paleGrey4)};
  `}

  th {
    position: relative;
    z-index: ${({ openActivityPopin }) => (openActivityPopin ? 1001 : 0)};
    background-color: ${({ openActivityPopin }) => (openActivityPopin ? 'white' : 'inherit')};
    padding: 0 0.8rem;
    text-align: left;
    border-left: 1px solid ${({ theme }) => theme.colors.lighterGray};
    min-width: 42rem;
    max-width: 42rem;

    ${({ inbound }) => inbound && css`
      animation: highlight 3s ease-out;
    `}

    ${({ clickable }) => clickable && css`
      cursor: pointer;
      :hover {
        background-color: ${({ theme, openActivityPopin }) => (openActivityPopin ? 'white' : theme.colors.paleBlue2)};
      }
    `}

    &:hover {
      z-index: ${({ openActivityPopin }) => (openActivityPopin ? 1001 : 1)};
    }

    &:hover > .activity-tooltip {
      visibility: visible;
      opacity: 1;
    }

    .activity-tooltip {
      font-size: inherit;
      visibility: hidden;
      width: 50%;
      padding: 1.5rem;
      position: absolute;
      right: 0;
      bottom: -2rem;
      opacity: 0;
      transition: 0.5s opacity;
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.primary};
      border-radius: 1rem;
      box-shadow: 0 0.2rem 0.8rem ${({ theme }) => theme.colors.primary};
      text-align: inherit;

      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 30%;
        position: absolute;
      }
    }
    span.activity-title {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .highlighted-title {
    font-family: ${({ theme }) => theme.fonts.family.bold};
    font-size: 1.3rem;
  }

  .full-consumption {
    color: ${({ theme }) => theme.colors.blockingError};
  }
  
  td:first-child {
    background-color: red;
  }

  td:last-child {
    padding: 5px 0;
    text-align: center;
    border-right: solid 1px ${({ theme }) => theme.colors.lighterGray};
    font-family: ${({ theme }) => theme.fonts.family.bold};
  }


  @keyframes highlight {
    0%{
      background-color:rgba(0, 169, 197, 0.3);
    }  
    20%{
      background-color:rgba(0, 169, 197, 0.2);
    }
    50%{
      background-color:rgba(0, 169, 197, 0.1);
    }
    100%{
      background-color: rgba(0, 169, 197, 0);
    }
  }

  @media (min-width: ${({ theme }) => theme.sizes.desktopMedium}) {
    th {
      min-width: 42rem;
      max-width: 42rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.sizes.desktopLarge}) {
    th {
      min-width: 55rem;
      max-width: 55rem;
    }
  }

  &:hover{
    background-color: ${({ theme }) => (theme.colors.paleBlue2)};
  }
  &.focus-line {
    background-color: ${({ theme }) => (theme.colors.paleBlue2)};
  }
`
