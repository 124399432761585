import React from 'react'
import { StyledSkeleton } from './Skeleton.styled'

const Skeleton = ({
  width,
  height,
  circle,
  lineHeight,
}) => (
  <StyledSkeleton
    width={width}
    height={height}
    lineHeight={lineHeight}
    circle={circle}
  >
    <div />
  </StyledSkeleton>
)

export default Skeleton
