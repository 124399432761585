import React from 'react'
import { ThemeProvider } from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Dropdown } from '@produits-internes-oss/design-system-components'
import { ProjectContractScreenStyled } from './ProjectContractScreen.styled'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import pictoContract from '../../../assets/images/pictoContrat.svg'
import pictoHistory from '../../../assets/images/pictoHistory.svg'
import { ProjectContractExplanation } from '../../components/ProjectContractExplanation/ProjectContractExplanation'
import { ProjectContractReference } from '../../components/ProjectContractReference/ProjectContractReference'
import { ProjectStatusChangeHistoricTable } from '../../components/ProjectStatusChangeHistoricTable/ProjectStatusChangeHistoricTable'
import { ProjectContractContextProvider } from '../../context/ProjectContract/ProjectContractContext'
import { ProjectPaymentSchedule } from '../../components/ProjectPaymentSchedule/ProjectPaymentSchedule'
import { ProjectPaymentScheduleLines } from '../../components/ProjectPaymentScheduleLines/ProjectPaymentScheduleLines'

export const ProjectContractScreen = ({ id, personIsAdmin }) => {
  const { t } = useTranslation()
  const openContract = { text: t('open'), arialabel: 'open the contrat et échéancier dropdown' }
  const closeContract = { text: t('close'), arialabel: 'close the contrat et échéancier dropdown' }
  const openHistory = { text: t('open'), arialabel: 'open the status historic dropdown' }
  const closeHistory = { text: t('close'), arialabel: 'close the status dropdown' }

  return (
    <ProjectContractContextProvider id={id}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ProjectContractScreenStyled>
          <Dropdown
            className="contract"
            title={t(`project.contract.contract_timeline.title`)}
            localization={{ open: openContract, close: closeContract }}
            picto={pictoContract}
            withStartupOpen
          >

            <ProjectContractExplanation />
            <ProjectContractReference />
            <ProjectPaymentSchedule personIsAdmin={personIsAdmin} />
            <ProjectPaymentScheduleLines />
          </Dropdown>
          <Dropdown
            className="status-historic"
            title={t(`projectHistory.history`)}
            localization={{ open: openHistory, close: closeHistory }}
            picto={pictoHistory}
            withStartupOpen
          >
            <ProjectStatusChangeHistoricTable />
          </Dropdown>
        </ProjectContractScreenStyled>
      </ThemeProvider>
    </ProjectContractContextProvider>
  )
}
