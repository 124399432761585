import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, InputNumber, Modal } from '@produits-internes-oss/design-system-components'
import { ProjectPricingExportModalStyled } from './ProjectPricingExportModal.styled'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'

export const ProjectPricingExportModal = () => {
  const { t } = useTranslation()

  const [paymentDelay, setPaymentDelay] = useState(0)
  const { project, fetchPatchProjectPricingExport } = useProjectSalesContext()

  useEffect(() => {
    if (project !== null) {
      setPaymentDelay(project.payment_delay)
    }
  }, [project])

  if (!project || !project.can_get_pricing) return null

  return (
    <ProjectPricingExportModalStyled className="pricing-export-modal">
      <Modal
        trigger={({ open }) => (
          <button type="button" className="export-button" aria-label={t('project.revenue.pricingExport.openModal.ariaLabel')} onClick={() => open()}>
            {t('project.revenue.pricingExport.openModal.text')}
            <Icon name="enter" className="download-icon" />
          </button>
        )}
        title={t('project.revenue.pricingExport.modal.title')}
        skin="light"
        onValidate={() => { fetchPatchProjectPricingExport(paymentDelay) }}
        localization={
          {
            validation: {
              text: t('project.revenue.pricingExport.modal.button.validate.text'),
              arialabel: t('project.revenue.pricingExport.modal.button.validate.ariaLabel'),
            },
            cancel: {
              text: t('project.revenue.pricingExport.modal.button.cancel.text'),
              arialabel: t('project.revenue.pricingExport.modal.button.cancel.ariaLabel'),
            },
          }
        }
      >
        <p>{t('project.revenue.pricingExport.modal.numberOfMonths.instructions')}</p>
        <InputNumber
          title={t('project.revenue.pricingExport.modal.numberOfMonths.label')}
          onCheck={(value) => (!Number.isInteger(value) || value < 0 || value > 11 ? t('project.revenue.pricingExport.modal.numberOfMonths.errorNumber') : null)}
          onChange={(value) => { setPaymentDelay(value) }}
          valueInitial={paymentDelay}
          required="required"
          className="input-payment-delay"
          localization={
            {
              invalid: {
                text: t('project.revenue.pricingExport.modal.numberOfMonths.errorFormat.text'),
                arialabel: t('project.revenue.pricingExport.modal.numberOfMonths.errorFormat.ariaLabel'),
              },
            }
          }
        />
      </Modal>
    </ProjectPricingExportModalStyled>
  )
}
