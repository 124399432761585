import React from 'react'
import { ThemeProvider } from 'styled-components/macro'
import { PeopleListingContextProvider } from '../../context/PeopleListing/PeopleListingContext'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import PeopleListingHeader from '../../components/PeopleListing/PeopleListingHeader/PeopleListingHeader'
import PeopleListingTable from '../../components/PeopleListing/PeopleListingTable/PeopleListingTable'
import PeopleListingFooter from '../../components/PeopleListing/PeopleListingFooter/PeopleListingFooter'

export default function PeopleListingScreen({ people, isAdmin }) {
  return (
    <ThemeProvider theme={theme}>
      <PeopleListingContextProvider people={people}>
        <GlobalStyle />
        <PeopleListingHeader />
        <PeopleListingTable isEditable={isAdmin} />
        <PeopleListingFooter />
      </PeopleListingContextProvider>
    </ThemeProvider>
  )
}
