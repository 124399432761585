import styled from 'styled-components'

export const ManagePeriodsTabsStyled = styled.div`
  .tabs-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 4rem;

    ul {
      margin: 0;

      &.tab-group {
        display: flex;

        li {
          list-style: none;

          button {
            &.disabled {
              background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
              color: ${({ theme }) => theme.colors.betterway.white};
              cursor: not-allowed;
            }

            &.active {
              border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[100]};
              border-bottom: 0.1rem solid ${({ theme }) => theme.colors.betterway.white};
              background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
              color: ${({ theme }) => theme.colors.betterway.white};
            }
          }
        }
      }
    }

    .tab {
      padding: 2.4rem;
      border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[20]};
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      background-color: ${({ theme }) => theme.colors.betterway.primary[5]};
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      font-family: ${({ theme }) => theme.fonts.family.standard};
      text-align: start;

      p:first-child {
        font-family: ${({ theme }) => theme.fonts.family.bold};
      }
    }

    .active {
      border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[100]};
      border-bottom: 0.1rem solid ${({ theme }) => theme.colors.betterway.white};
      background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
      color: ${({ theme }) => theme.colors.betterway.white};
    }
  }

  .period-picker {
    align-self: flex-end;
    margin-right: 2rem;
    margin-bottom: 2.7rem;
    margin-left: auto;
  }

`
