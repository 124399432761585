import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@produits-internes-oss/design-system-components'
import { ProjectQualityStyled } from './ProjectQuality.styled'
import { useProjectQualityContext } from '../../context/ProjectQuality/ProjectQualityContext'

export const ProjectQuality = () => {
  const { t } = useTranslation()
  const { project } = useProjectQualityContext()
  const risk = () => (
    <div className="risk-content">
      <header>
        <Icon className="icon-header" name="warning" />
        <h2>{t(`project.quality.risk.title`)}</h2>
      </header>
      <ul>
        <li>
          <div className="risk-label">
            {t(`project.quality.risk.transboundary`)}
          </div>
          <div className="risk-answer">
            {project.transboundary ? t(`project.quality.risk.answer_positive`) : t(`project.quality.risk.answer_negative`)}
          </div>
        </li>
        <li>
          <div className="risk-label">
            {t(`project.quality.risk.operations_domains`)}
          </div>
          <div className="risk-answer">
            {project.operations_domains_names ? project.operations_domains_names : '-' }
          </div>
        </li>
        <li>
          <div className="risk-label">
            {t(`project.quality.risk.risky_operations_countries`)}
          </div>
          <div className="risk-answer">
            {project.risky_operations_countries_names ? project.risky_operations_countries_names : '-' }
          </div>
        </li>
        <li>
          <div className="risk-label">
            {t(`project.quality.risk.imply_export_transfer`)}
          </div>
          <div className="risk-answer">
            {project.imply_export_or_transfer ? t(`project.quality.risk.answer_positive`) : t(`project.quality.risk.answer_negative`)}
          </div>
        </li>
        <li>
          <div className="risk-label">
            {t(`project.quality.risk.commercial_laws`)}
          </div>
          <div className="risk-answer">
            {project.concern_commercial_laws ? t(`project.quality.risk.answer_positive`) : t(`project.quality.risk.answer_negative`)}
          </div>
        </li>
      </ul>
    </div>

  )

  if (project === null) {
    return <div>...</div>
  }

  return (
    <ProjectQualityStyled>
      {risk()}
    </ProjectQualityStyled>
  )
}
