import styled from 'styled-components'
import theme from '../../../../../style/theme'

export const StyledProjectPickerModal = styled.div`
  p {
    font-weight: bold;
  }
  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }
  form {
    & > *:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  .missingProject {
    font-size: 12px;
    margin: 0.5rem;
    font-style: italic;
    color: red;
  }
`

export const customStyles = {
  input: (base) => ({
    ...base,
    'input:focus': {
      boxShadow: 'none',
      margin: 'auto',
      fontFamily: theme.fonts.family.standard,
    },
  }),
  control: (base) => ({
    ...base,
    borderRadius: 50,
    cursor: 'pointer',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
}
