import React from 'react'
import { useTranslation } from 'react-i18next'
import StyledTimesheetHeader from './TimesheetHeader.styled'
import TimesheetHeaderDay from './TimesheetHeaderDay/TimesheetHeaderDay'
import TimesheetHeaderTotal from './TimesheetHeaderTotal/TimesheetHeaderTotal'
import { useTimesheetStateValue } from '../../../../context/Timesheet/TimesheetStateContext'
import { usePeriodStateValue } from '../../../../context/Timesheet/PeriodStateContext'

const TimesheetHeader = ({ openProjectPickerModal }) => {
  const { t } = useTranslation()
  const { daysInMonth, holidayAndWeekendDays } = useTimesheetStateValue()
  const { validatedPeriodDays } = usePeriodStateValue()
  const daysInMonthObject = daysInMonth.map((dateItem) => {
    const date = new Date(dateItem)
    return { dayInWeek: date.getDay(), dateOfDay: date.getDate(), fullDate: dateItem }
  })

  return (
    <StyledTimesheetHeader>
      <tr>
        <th aria-label="empty zone" />
        <th aria-label="empty zone" />
        {
          daysInMonthObject.map(({ dayInWeek, dateOfDay, fullDate }) => (
            <TimesheetHeaderDay
              key={`day-${dateOfDay}`}
              dayInWeek={dayInWeek}
              dateOfDay={dateOfDay}
              fullDate={fullDate}
              holiday={holidayAndWeekendDays.includes(fullDate)}
              validated={validatedPeriodDays.includes(fullDate)}
            />
          ))
        }
        <th>{t('total')}</th>
      </tr>
      <tr>
        <th>
          <div>
            {t('project.projects')}
            <button type="button" onClick={openProjectPickerModal}>
              {t('project.addProject')}
            </button>
          </div>
        </th>
        <th>{t('consumption')}</th>
        <TimesheetHeaderTotal />
      </tr>
    </StyledTimesheetHeader>
  )
}

export default TimesheetHeader
