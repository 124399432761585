/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip, InputDate, Modal, InputSelect, InputText, InputTextarea } from '@produits-internes-oss/design-system-components'
import { getClass, getNumberWithUnit, parseFloatWithAnySeparator } from '../../helpers/stringHelper'
import { useProjectDataHatContext } from '../../context/ProjectDataHatContext/ProjectDataHatContext'
import { StatusStyled } from './Status.styled'

export const Status = ({ status, current, messages, missingFields, disabled }) => {
  const { t } = useTranslation()
  const { project, fetchUpdateStatus } = useProjectDataHatContext()
  const [projectParams, setProjectParams] = useState({})
  const [otherQADirectorPresent, setOtherQADirectorPresent] = useState(project.qa_director === 'other')
  const endDateWarningStatus = ['proposal_in_progress', 'proposal_sent', 'mission_accepted', 'mission_signed']
  const commentWarningStatus = ['proposal_no_go', 'lead_transferred_to_acn', 'proposal_canceled_by_client', 'proposal_lost']
  const commentRequiredModal = commentWarningStatus.includes(status)
  const canceledByClientModal = status === 'proposal_canceled_by_client'

  useEffect(() => {
    if (project) {
      setProjectParams({
        status: project.status.current,
        endDate: project.end_date,
        comment: project.comment,
        qaDirector: project.qa_director,
        otherQADirector: project.other_qa_director,
        amountDifferenceComment: project.amount_difference_comment,
      })
    }
  }, [project])

  const statusClassTag = () => {
    if (status === 'lead') return 'lead'
    if (status === 'proposal_in_progress') return 'proposal-in-progress'
    if (status === 'proposal_sent') return 'proposal-sent'
    if (status === 'mission_accepted') return 'mission-accepted'
    if (status === 'mission_signed') return 'mission-signed'
    if (status === 'mission_done') return 'mission-done'
    if (status === 'proposal_lost') return 'proposal-lost'
    if (status === 'proposal_no_go') return 'proposal-no-go'
    if (status === 'proposal_canceled') return 'proposal-canceled'
    if (status === 'proposal_canceled_by_client') return 'proposa-canceled-by-client'
    if (status === 'lead_transferred_to_acn') return 'lead-transferred-to-acn'
    return ''
  }

  const inProgressStatusMessages = () => (
    <div className="message">
      {
        disabled && (
          <>
            <p className="title-spacing ">
              <span className="tooltip-bold">
                {t('project.status.tooltip.inProgress.firstParagraph.0')}
              </span>
              {t('project.status.tooltip.inProgress.firstParagraph.1')}
            </p>
            {
              (messages.includes('projects.customer_must_be_active')) && (
                <>
                  <p>
                    <span className="tooltip-bold">
                      {t('project.status.tooltip.inProgress.secondParagraph.0')}
                    </span>
                    {t('project.status.tooltip.inProgress.secondParagraph.1')}
                  </p>
                  <p>{t('project.status.tooltip.inProgress.thirdParagraph.0')}</p>
                </>
              )
            }
            <p>
              {t('project.status.tooltip.inProgress.fourthParagraph.0')}
              <span className="tooltip-bold">{t('project.status.tooltip.inProgress.fourthParagraph.1')}</span>
            </p>
            <ul>
              {
                missingFields.map((field) => (
                  <li key={field}>{t(`project.status.tooltip.inProgress.mandatoryFields.${field}`)}</li>
                ))
              }
            </ul>
          </>
        )
      }
    </div>

  )

  const sentStatusMessages = () => (
    <div>
      {
        disabled && (
          <>
            {
              (messages.includes('projects.no_activities')) && (
                <>
                  <p>
                    {t('project.status.tooltip.sent.firstParagraph.0')}
                    <span className="tooltip-bold">
                      {t('project.status.tooltip.sent.firstParagraph.1')}
                    </span>
                    {t('project.status.tooltip.sent.firstParagraph.2')}
                  </p>
                </>
              )
            }
            {
              (messages.includes('projects.payment_schedule_not_filled')) && (
                <>
                  <p>
                    <span className="tooltip-bold">
                      {t('project.status.tooltip.sent.secondParagraph.0')}
                    </span>
                    {t('project.status.tooltip.sent.secondParagraph.1')}
                  </p>
                </>
              )
            }
            {
              missingFields.length > 0 && (
                <>
                  <p>
                    {t('project.status.tooltip.sent.thirdParagraph.0')}
                    <span className="tooltip-bold">{t('project.status.tooltip.sent.thirdParagraph.1')}</span>
                  </p>
                  <ul>
                    {
                      missingFields.map((field) => (
                        <li key={field}>{t(`project.status.tooltip.sent.mandatoryFields.${field}`)}</li>
                      ))
                    }
                  </ul>
                </>
              )
            }
          </>
        )
      }
    </div>
  )

  const acceptedStatusMessages = () => (
    <div>
      {
        (messages.includes('projects.warning_for_auto_start_date')) && (
          <p>
            {t('project.status.tooltip.accepted.fourthParagraph.0')}
            <span className="tooltip-bold">
              {t('project.status.tooltip.accepted.fourthParagraph.1')}
            </span>
            {t('project.status.tooltip.accepted.fourthParagraph.2')}
          </p>
        )
      }
      {
        disabled && (
          <>
            <p>
              <span className="tooltip-bold">
                {t('project.status.tooltip.accepted.firstParagraph.0')}
              </span>
              {t('project.status.tooltip.accepted.firstParagraph.1')}
            </p>
            {
              (messages.includes('projects.payment_schedule_not_filled')) && (
                <p>
                  {t('project.status.tooltip.accepted.secondParagraph.0')}
                  <span className="tooltip-bold">
                    {t('project.status.tooltip.accepted.secondParagraph.1')}
                  </span>
                </p>
              )
            }
            {
              (messages.includes('projects.no_activities')) && (
                <p>
                  {t('project.status.tooltip.accepted.thirdParagraph.0')}
                  <span className="tooltip-bold">
                    {t('project.status.tooltip.accepted.thirdParagraph.1')}
                  </span>
                  {t('project.status.tooltip.accepted.thirdParagraph.2')}
                </p>
              )
            }
            {
              (messages.includes('projects.prevention_plan_status_needed')) && (
                <p>
                  {t('project.status.tooltip.accepted.fifthParagraph.0')}
                </p>
              )
            }
            {
              missingFields.length > 0 && (
                <>
                  <p>
                    {t('project.status.tooltip.accepted.sixtParagraph.0')}
                    <span className="tooltip-bold">{t('project.status.tooltip.accepted.sixtParagraph.1')}</span>
                  </p>
                  <ul>
                    {
                      missingFields.map((field) => (
                        <li key={field}>{t(`project.status.tooltip.accepted.mandatoryFields.${field}`)}</li>
                      ))
                    }
                  </ul>
                </>
              )
            }
          </>
        )
      }
    </div>
  )

  const signedStatusMessages = () => (
    <div>
      {
        (messages.includes('projects.billing_team_mission_signed')) && (
          <p className="tooltip-bold">
            {t('project.status.tooltip.signed.fifthParagraph.0')}
          </p>
        )
      }
      {
        disabled && (
          <>
            <p>
              {t('project.status.tooltip.signed.firstParagraph.0')}
            </p>
            {
              (messages.includes('projects.missing_invoiceable_fees')) && (
                <p>
                  {t('project.status.tooltip.signed.secondParagraph.0')}
                  <span className="tooltip-bold">
                    {t('project.status.tooltip.signed.secondParagraph.1')}
                  </span>
                  {t('project.status.tooltip.signed.secondParagraph.2')}
                </p>
              )
            }
            {
              (messages.includes('projects.payment_schedule_not_filled')) && (
                <p>
                  <span className="tooltip-bold">
                    {t('project.status.tooltip.signed.thirdParagraph.0')}
                  </span>
                  {t('project.status.tooltip.signed.thirdParagraphh.1')}
                </p>
              )
            }
            {
              (messages.includes('projects.no_activities')) && (
                <p>
                  {t('project.status.tooltip.signed.fourthParagraph.0')}
                  <span className="tooltip-bold">
                    {t('project.status.tooltip.signed.fourthParagraph.1')}
                  </span>
                  {t('project.status.tooltip.signed.fourthParagraph.2')}
                </p>
              )
            }
            {
              missingFields.length > 0 && (
                <>
                  <p>
                    {t('project.status.tooltip.signed.sixtParagraph.0')}
                    <span className="tooltip-bold">{t('project.status.tooltip.signed.sixtParagraph.1')}</span>
                  </p>
                  <ul>
                    {
                      missingFields.map((field) => (
                        <li key={field}>{t(`project.status.tooltip.signed.mandatoryFields.${field}`)}</li>
                      ))
                    }
                  </ul>
                </>
              )
            }
          </>
        )
      }
    </div>
  )

  const doneStatusMessages = () => (
    <div>
      {
        (messages.includes('projects.payment_schedule_not_filled')) && (
          <p>
            <span className="tooltip-bold">
              {t('project.status.tooltip.done.firstParagraph.0')}
            </span>
            {t('project.status.tooltip.done.firstParagraph.1')}
          </p>
        )
      }
      {
        (messages.includes('projects.no_activities')) && (
          <p>
            {t('project.status.tooltip.done.secondParagraph.0')}
            <span className="tooltip-bold">
              {t('project.status.tooltip.done.secondParagraph.1')}
            </span>
            {t('project.status.tooltip.done.secondParagraph.2')}
          </p>
        )
      }
      {
        (messages.includes('projects.needs_mission_done')) && (
          <p>
            {t('project.status.tooltip.done.thirdParagraph.0')}
          </p>
        )
      }
      {
        (messages.includes('projects.needs_turnover_breakdown_validation')) && (
          <p>
            {t('project.status.tooltip.done.fourthParagraph.0')}
            <span className="tooltip-bold">
              {t('project.status.tooltip.done.fourthParagraph.1')}
            </span>
          </p>
        )
      }
      {
        (messages.includes('projects.warning_for_auto_end_date')) && (
          <p>
            {t('project.status.tooltip.done.fifthParagraph.0')}
            <span className="tooltip-bold">
              {t('project.status.tooltip.done.fifthParagraph.1')}
            </span>
            {t('project.status.tooltip.done.fifthParagraph.2')}
          </p>
        )
      }
    </div>
  )

  const lostStatusMessages = () => (
    <div>
      {
        (messages.includes('projects.cannot_have_prestaffing')) && (
          <p>
            {t('project.status.tooltip.lostStatus.0')}
          </p>
        )
      }
    </div>
  )

  const tooltipMessagesByStatus = () => {
    if (status === 'proposal_in_progress') return inProgressStatusMessages()
    if (status === 'proposal_sent') return sentStatusMessages()
    if (status === 'mission_accepted') return acceptedStatusMessages()
    if (status === 'mission_signed') return signedStatusMessages()
    if (status === 'mission_done') return doneStatusMessages()
    if (status === 'proposal_lost'
      || status === 'proposal_no_go'
      || status === 'proposal_canceled'
      || status === 'proposal_canceled_by_client'
      || status === 'lead_transferred_to_acn'
    ) return lostStatusMessages()
    return ''
  }

  const validateEmail = (email) => (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  )

  const translateQADirectorOptions = () => {
    const options = project.qa_director_options
    if (Object.keys(options).includes('other')) {
      options['other'] = (t('project.status.modal.inProgress.expensive.qaDirector.other'))
    }
    return options
  }

  const endDateModalMessage = () => (
    <div className="normal-modal">
      {
          (project.expensive_project && status === 'mission_accepted') && (
            <>
              <p className="title">{t('project.status.modal.accepted.expensive.title')}</p>
              <p>
                {t('project.status.modal.accepted.expensive.text.0')}
                <span><a href="http://mme.accenture.com" target="_blank" rel="noreferrer">MME</a></span>
                {t('project.status.modal.accepted.expensive.text.1')}
              </p>
              <p>
                {t('project.status.modal.accepted.expensive.text.2')}
                <span>
                  <a href="https://docs.google.com/presentation/d/18LxKmSNHba-YUKmqIDjqdxTNcE7dlSt_yuVl4vfRcEQ/edit" target="_blank" rel="noreferrer">documentation.</a>
                </span>
              </p>
            </>
          )
        }
      <p className="title">
        {`${t('project.status.modal.inProgress.endDate.title')}${new Date(project.end_date).toLocaleDateString('fr-FR')}`}
      </p>
      <p>{t('project.status.modal.inProgress.endDate.text')}</p>
      <InputDate
        className="input-date"
        skin="light"
        name={t('project.status.modal.inProgress.endDate.inputTitle')}
        title={t('project.status.modal.inProgress.endDate.inputTitle')}
        valueInitial={new Date(project.end_date)}
        onCheck={() => {}}
        onChange={(date) => {
          setProjectParams({ ...projectParams, endDate: date, status })
        }}
      />
      {
          (project.expensive_project && status === 'proposal_in_progress') && (
            <>
              <p className="text-bottom">{t('project.status.modal.inProgress.expensive.text.0')}</p>
              <p>{t('project.status.modal.inProgress.expensive.text.1')}</p>
              <div className="input-qa-director-section">
                <InputSelect
                  className="input-qa-director"
                  skin="light"
                  name="input search example"
                  valueInitial={project.qa_director && (project.qa_director === 'other' ? ['other'] : [project.qa_director.email])}
                  required
                  title={t('project.status.modal.inProgress.expensive.inputTitle')}
                  options={translateQADirectorOptions()}
                  localization={{ validation: { text: 'Sauvegarder', arialabel: 'sauvegarder' } }}
                  multiple={false}
                  onChange={(qaDirectorEmail) => {
                    const qaDirector = qaDirectorEmail[0]
                    setProjectParams({ ...projectParams, qaDirector, status })
                    setOtherQADirectorPresent(qaDirector === 'other')
                  }}
                />
              </div>
              {
                otherQADirectorPresent && (
                  <InputText
                    className="other-qa-director"
                    skin="light"
                    title={t('project.status.modal.inProgress.expensive.qaDirector.text')}
                    name={t('project.status.modal.inProgress.expensive.qaDirector.text')}
                    required
                    valueInitial={project.other_qa_director}
                    onChange={(otherQADirector) => {
                      setProjectParams({ ...projectParams, status, qaDirector: 'Other', otherQADirector })
                    }}
                    onCheck={(email) => (((validateEmail(email) === null) && email.length > 5) ? t('project.status.modal.inProgress.expensive.qaDirector.error') : '')}
                  />
                )
              }
            </>
          )
        }
    </div>
  )

  const amountDifferenceModalMessage = () => (
    <div className="normal-modal">
      <p className="title">
        {t('project.status.modal.done.text.0')}
        <span>
          {' '}
          (
          {`${getNumberWithUnit(parseFloatWithAnySeparator(project.amount), '€')}`}
          )
          {' '}
        </span>
        {t('project.status.modal.done.text.1')}
        <span>
          (
          {`${getNumberWithUnit(parseFloatWithAnySeparator(project.cutted_amount), '€')}`}
          )
          {' '}
        </span>
        {t('project.status.modal.done.text.2')}
        <span>
          (
          {`${getNumberWithUnit(parseFloatWithAnySeparator(project.difference), '€')}`}
          )
        </span>
      </p>
      <InputTextarea
        className="amount-difference"
        skin="light"
        name={t('project.status.modal.done.inputTitle')}
        title={t('project.status.modal.done.inputTitle')}
        required
        onChange={(amountDifferenceComment) => {
          setProjectParams({ ...projectParams, status, amountDifferenceComment })
        }}
      />
    </div>
  )

  const commentRequiredModalMessage = () => (
    <div>
      <p className="comment-title lost-title">
        {t('project.status.modal.lost.titleLost')}
        <span className="tooltip-bold">
          “
          {t(`project.status.${status}`)}
          ”
        </span>
      </p>
      <InputTextarea
        className="lost-status-comment"
        skin="light"
        valueInitial={project.comment}
        name={t('project.status.modal.lost.inputTitle')}
        title={t('project.status.modal.lost.inputTitle')}
        required
        onChange={(comment) => {
          setProjectParams({ ...projectParams, status, comment })
        }}
      />
    </div>
  )

  const canceledByClientWarningModalMessage = () => (
    <div className="comment-modal">
      <p className="comment-title">
        {t('project.status.modal.lost.titleCancelByClient')}
      </p>
      <p className="text-bottom">
        {t('project.status.modal.lost.text.0')}
        <span className="tooltip-bold">
          “
          {t(`project.status.${status}`)}
          ”
        </span>
      </p>
      <p>
        {t('project.status.modal.lost.text.1')}
        <span className="tooltip-bold">{t('project.status.modal.lost.text.2')}</span>
        {t('project.status.modal.lost.text.3')}
      </p>
      <InputTextarea
        className="lost-status-comment"
        skin="light"
        valueInitial={project.comment}
        name={t('project.status.modal.lost.inputTitle')}
        title={t('project.status.modal.lost.inputTitle')}
        required
        onChange={(comment) => {
          setProjectParams({ ...projectParams, status, comment })
        }}
      />
    </div>
  )

  const displayModalMessages = () => {
    if (status === 'mission_done' && project.amount_difference) {
      return amountDifferenceModalMessage()
    } if (endDateWarningStatus.includes(status)) {
      return endDateModalMessage()
    } if (canceledByClientModal) {
      return canceledByClientWarningModalMessage()
    } if (commentWarningStatus.includes(status)) {
      return commentRequiredModalMessage()
    }
    return ''
  }

  const handleOpenModal = (modal) => (
    disabled ? '' : modal.open()
  )

  const statusModal = () => (
    <Modal
      className="status-modal"
      skin="light"
      kind={canceledByClientModal ? 'error' : ''}
      localization={{
        validation: {
          text: (commentWarningStatus.includes(status))
            ? t('project.status.modal.validateStatusLost')
            : t('project.status.modal.validateStatusChange'),
          arialabel: (commentWarningStatus.includes(status)) ? t('project.status.modal.validateStatusLost') : t('project.status.modal.validateStatusChange'),
        },
        cancel: { text: t('project.status.modal.cancel'), arialabel: t('project.status.modal.cancel') },
      }}
      trigger={(modal) => (
        messages.length > 0 ? (
          <Tooltip
            className="status-tooltip"
            position="bottom"
            skin="light"
            content={() => tooltipMessagesByStatus()}
            trigger={(tooltip) => (
              <div
                role="button"
                type="button"
                tabIndex={0}
                onMouseEnter={() => tooltip.open()}
                onMouseLeave={() => tooltip.close()}
                onClick={() => {
                  handleOpenModal(modal)
                  setProjectParams({ ...projectParams, status })
                }}
                onKeyDown={() => {
                  modal.open()
                  setProjectParams({ ...projectParams, status })
                }}
                disabled={disabled}
              >
                <button
                  type="button"
                  className={`status ${statusClassTag()} ${getClass(disabled && 'disabled')}`}
                  aria-label={`${status} status tooltip`}
                >
                  {t(`project.status.${status}`)}
                </button>
              </div>
            )}
          />
        ) : (
          <button
            type="button"
            className={`status ${statusClassTag()}`}
            onClick={() => {
              modal.open()
              setProjectParams({ ...projectParams, status })
            }}
          >
            {t(`project.status.${status}`)}
          </button>
        )
      )}
      onValidate={async () => {
        setOtherQADirectorPresent(false)
        fetchUpdateStatus(projectParams)
      }}
    >
      {displayModalMessages()}
    </Modal>
  )

  const ButtonWithTooltip = () => (
    <Tooltip
      className="status-tooltip"
      position="bottom"
      skin="light"
      trigger={
        ({ open, close }) => (
          <div
            onMouseEnter={() => { open() }}
            onMouseLeave={() => close()}
            aria-label={`${status} status tooltip`}
            className={`status ${statusClassTag()} ${getClass(disabled && 'disabled')}`}
          >
            <button
              type="button"
              disabled={disabled}
              onClick={() => {
                fetchUpdateStatus({ ...projectParams, status })
              }}
            >
              {t(`project.status.${status}`)}
            </button>
          </div>
        )
      }
      content={
        () => (
          <div>
            {tooltipMessagesByStatus()}
          </div>
        )
      }
    />
  )

  const ButtonWithoutTooltip = () => (
    <button
      type="button"
      className={`status ${statusClassTag()}`}
      onClick={() => {
        fetchUpdateStatus({ ...projectParams, status })
      }}
    >
      {t(`project.status.${status}`)}
    </button>
  )

  const displayStatusTooltip = () => {
    const warningModalStatus = [
      'proposal_in_progress',
      'proposal_sent',
      'mission_accepted',
      'mission_signed',
      'proposal_no_go',
      'lead_transferred_to_acn',
      'proposal_canceled_by_client',
      'proposal_lost',
    ]

    if ((warningModalStatus.includes(status)) || (status === 'mission_done' && project.amount_difference)) {
      return statusModal()
    }
    if (messages.length > 0) {
      return <ButtonWithTooltip />
    }
    return <ButtonWithoutTooltip />
  }

  return (
    <StatusStyled className="octo hard" commentRequiredModal={commentRequiredModal}>
      {
        current ? (
          <button type="button" className={`status current ${statusClassTag()}`}>
            {t(`project.status.${status}`)}
          </button>
        ) : (
          displayStatusTooltip()
        )
      }
    </StatusStyled>
  )
}
