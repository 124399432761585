import styled from 'styled-components'

export default styled.div`
  >.separator {
    height: 0.1rem;
    margin: 1.6rem -1.6rem;
    background-color: ${({ theme }) => theme.colors.betterway.primary[20]};
  }

  >.actions {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0;

    > button {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      height: 4rem;
      padding: 1rem 4rem;
      border: none;
      border-radius: 4rem;
      outline: 0.1rem solid ${({ theme }) => theme.colors.betterway.transparent};
      font-weight: bold;
      font-style: normal;
      font-variant: normal;
      font-size: 1.5rem;
      letter-spacing: 0.1rem;
      cursor: pointer;
      transition:
        background-color 0.1s ease-in-out,
        color 0.1s ease-in-out,
        outline-color 0.1s ease-in-out,
        opacity 0.1s ease-in-out;

      &.cancel {
        background-color: ${({ theme }) => theme.colors.betterway.transparent};
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
        outline-color: ${({ theme }) => theme.colors.betterway.primary[100]};

        &:hover,
        &:focus,
        &:focus-visible {
          color: ${({ theme }) => theme.colors.betterway.secondary[100]};
          outline-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
        }
      }

      &.validate {
        background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
        color: ${({ theme }) => theme.colors.white};

        &:hover,
        &:focus,
        &:focus-visible {
          background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
        }
      }
    }
  }
`
