import styled from 'styled-components'

export const SynchronizationStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: center;
  padding: 2.4rem;
  color: ${({ theme }) => theme.colors.betterway.primary[100]};
  font-family: "Outfit", sans-serif;

  >.controls {
    flex: 1;
    max-width: min-content;

    >.header {
      margin-bottom: 7.8rem;
    }

    >.progression {
      >.progression-title {
        margin-bottom: 0.8rem;
      }
    }

    >.cards {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: stretch;

      >.cards-actions {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: space-between;
      }
    }
  }

  >.errors,
  >.rocket {
    flex: 1;
    min-width: fit-content;
    max-width: 50rem;
    margin-top: 5.4rem;
    margin-left: 5.4rem;
  }
`
