import styled from 'styled-components'

export default styled.div`
  align-self: flex-end;
  margin-bottom: 1rem;

  .btn-label {
    margin-left: 0.4rem;
  }
`
