import React from 'react'
import { StyledActivityRecap } from './ActivityRecap.styled'

const ActivityRecap = ({
  activity,
  even,
  totalInputs,
  className,
}) => {
  const { inDays, inHours } = totalInputs()

  return (
    <StyledActivityRecap even={even} className={className}>
      <p>{activity.full_title}</p>
      <span>
        <strong>
          {inDays}
          j
        </strong>
        {' '}
&nbsp; (
        {inHours}
        h)
      </span>
    </StyledActivityRecap>
  )
}

export default ActivityRecap
