import React from 'react'
import { useTranslation } from 'react-i18next'
import { useManagerPeriodsContext } from '../../../context/ManagePeriods/ManagePeriodsContext'
import { SendEmailToFilteredPeopleStyled } from './SendEmailToFilteredPeople.styled'
import Button from '../../input/_ExportDS/Button/Button'
import pictoCheckDark from '../../../../assets/images/CheckDark.svg'

export default function SendEmailToFilteredPeople({ personIsAdmin }) {
  const { t } = useTranslation()
  const {
    fetchPostSendEmailToSelectedPeople,
    peopleFilteredBySearch,
    emailBody,
    setEmailBody,
    emailButtonPictoCheck,
    buttonSendMailText,
    setEmailButtonPictoCheck,
    setButtonSendMailText,
  } = useManagerPeriodsContext()

  return (personIsAdmin
    && (
    <SendEmailToFilteredPeopleStyled>
      <h2>{`${t('managePeriods.email.title')} (${peopleFilteredBySearch.length})`}</h2>
      <div className="text-area-container">
        <textarea
          className="email-text-area"
          aria-label="email body text area"
          onChange={({ target }) => setEmailBody(target.value)}
          autoComplete="off"
          value={emailBody}
        />
      </div>
      <div className="button-container">
        <Button
          className="send-mail"
          name="send mail to the filtered people input"
          onClick={
            async () => {
              await fetchPostSendEmailToSelectedPeople(emailBody)
              setEmailButtonPictoCheck(pictoCheckDark)
              setButtonSendMailText(t('managePeriods.email.button.sent'))
            }
          }
          text={buttonSendMailText}
          picto={emailButtonPictoCheck}
          disabled={!emailBody || !emailBody.trim() || peopleFilteredBySearch.length === 0}
        />
      </div>
    </SendEmailToFilteredPeopleStyled>
    )
  )
}
