import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePeopleListingContext } from '../../../context/PeopleListing/PeopleListingContext'
import PeopleListingFooterStyled from './PeopleListingFooter.styled'

export default function PeopleListingFooter() {
  const { t } = useTranslation()
  const { showOldPeople, toggleShowOldPeople } = usePeopleListingContext()
  return (
    <PeopleListingFooterStyled>
      <div className="description">
        {
          showOldPeople ? t('people.listing.profiles.description.shown') : t('people.listing.profiles.description.hidden')
        }
      </div>
      <button
        type="button"
        aria-label="toggle showing people"
        onClick={() => toggleShowOldPeople()}
      >
        {
          showOldPeople ? t('people.listing.profiles.button.hide') : t('people.listing.profiles.button.show')
        }
      </button>
    </PeopleListingFooterStyled>
  )
}
