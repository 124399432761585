import styled from 'styled-components'
import pictoExclamationInfo from '../../../assets/images/exclamationInfo.svg'

export const ProjectExpensesExplanationStyled = styled.div`
  &.info-no-expense {
    display: flex;
    padding: 1.6rem;
    border: solid 0.2rem ${({ theme }) => theme.colors.betterway.secondary[100]};
    border-radius: 0.3rem;
    background-color: ${({ theme }) => theme.colors.betterway.secondary[5]};

    ::before {
      content: '';
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 0.8rem;
      background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
      mask-image: url(${pictoExclamationInfo});
      mask-size: contain;
      mask-repeat: no-repeat;
    }

    div {
      p {
        &.not-chargeable {
          margin-top: 0.8rem;
        }

        em {
          font-weight: 600;
        }
      }
    }
  }
`
