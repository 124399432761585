import React, { useEffect, useRef, useState } from 'react'
import { getClass } from '../../../../helpers/stringHelper'
import { TooltipStyled } from './TooltipStyled'

export const Tooltip = ({ className, title, trigger, content }) => {
  const tooltip = useRef(null)
  const [isOpened, setIsOpened] = useState(false)

  const open = () => {
    setIsOpened(!isOpened)
  }

  const close = () => {
    setIsOpened(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', ({ target }) => {
      if (tooltip && tooltip.current && !tooltip.current.contains(target)) {
        setIsOpened(false)
      }
    })
  }, [])

  return (
    <TooltipStyled className={className} isOpened={isOpened}>
      {
        trigger({ open })
      }
      <div className="popup-overlay" />
      <div ref={tooltip} className={`${getClass('popup-tooltip', isOpened ? 'opened' : 'closed')}`} aria-hidden={!isOpened}>
        <div className="title">{title}</div>
        <div className="content">{content({ close })}</div>
      </div>
    </TooltipStyled>
  )
}
