import styled from 'styled-components'
import { Section } from '@produits-internes-oss/design-system-components'

export const ProjectQualityStyled = styled(Section)`
  &.octo {
    .risk-content,
    .sensitive-content {
      display: flex;
      flex-direction: column;
      width: 100%;

      header {
        display: flex;
        align-items: start;
        width: 100%;
        margin-top: 2.4rem;

        h2 {
          margin-left: 1.8rem;
          font-weight: 600;
          font-size: 2.4rem;
        }

        .icon-header {
          width: 3.2rem;
          height: 3.2rem;
        }

        .sub-title {
          margin-top: 0.8rem;
        }
      }

      >ul {
        display: flex;
        flex-direction: column;
        gap: 1.3rem;
        width: auto;
        margin-top: 2.4rem;
        margin-bottom: 3.2rem;
        margin-left: 7.2rem;

        >li {
          display: flex;
          gap: 5.6rem;
          width: 100%;

          >.risk-label {
            width: 50%;
          }

          >.risk-answer {
            width: 50%;
            font-weight: 600;
          }
        }
      }
    }

    .sensitive-content {
      .text-header {
        display: flex;
        flex-direction: column;
        margin-left: 1.8rem;

        h2 {
          margin-left: 0;

          >span {
            font-size: 2.4rem;
            text-decoration: underline;
          }
        }

        span {
          font-weight: 600;
          font-size: 1.6rem;
        }
      }

      >ul {
        flex-wrap: wrap;
        height: 27rem;

        >li {
          display: list-item;
          gap: 5.6rem;
          width: 40%;
          margin-right: 0.5rem;
          list-style: disc;

          >.risk-label {
            width: 50%;
          }

          >.risk-answer {
            width: 50%;
            font-weight: 600;
          }
        }
      }

      .more-infos {
        display: flex;
        flex-direction: column;
        margin-left: 7.2rem;

        img {
          width: 1.8rem;
        }

        >span {
          display: flex;
          margin-right: 1rem;
        }

        .link-infos {
          display: flex;
          margin: 0 0.5rem;
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }
`
