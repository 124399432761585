import React from 'react'
import { StyledNavigationButton } from './NavigationButton.styled'
import ButtonChevronLeft from '../../../../../assets/images/ButtonChevronLeft.svg'
import ButtonChevronRight from '../../../../../assets/images/ButtonChevronRight.svg'
import OctopodLogo from '../../../../../assets/images/octopus.svg'
import Chevron from '../../../../../assets/images/chevron.svg'

const NavigationButton = ({
  disabled, type, className, click, role, title, ariaLabel,
}) => {
  const button = {
    prev: ButtonChevronLeft,
    next: ButtonChevronRight,
    current: OctopodLogo,
    chevron: Chevron,
  }
  return (
    <StyledNavigationButton className={className} onClick={click} role={role} disabled={disabled} aria-label={ariaLabel}>
      <img src={button[type]} title={title} alt="fleche navigation" />
    </StyledNavigationButton>
  )
}

export default NavigationButton
