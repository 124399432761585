import React from 'react'

import ManagePeriodsHeader from './ManagePeriodsHeader/ManagePeriodsHeader'
import { ManagePeriodsStyled } from './ManagePeriods.styled'
import ManagePeriodsTabs from './ManagePeriodsTabs/ManagePeriodsTabs'

export default function ManagePeriods({ personIsAdmin }) {
  return (
    <ManagePeriodsStyled>
      <ManagePeriodsHeader personIsAdmin={personIsAdmin} />
      <ManagePeriodsTabs />
    </ManagePeriodsStyled>
  )
}
