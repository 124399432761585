import React, { useState, useContext, createContext, useEffect } from 'react'
import { useFetch } from '../../fetchOverviewApi'
import { useJQuery } from '../../hooks/useJQuery/useJQuery'

const ProjectDataHatContext = createContext()

export const ProjectDataHatContextProvider = ({ children, id, personCanUpdateToSigned }) => {
  const [errors, setErrors] = useState([])
  const [project, setProject] = useState(null)
  const [status, setStatus] = useState(null)
  const [canUpdateToSigned] = useState(personCanUpdateToSigned)

  const { publish, subscribe } = useJQuery()

  const fetchGetProject = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}.json`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProject(response.project)
      setStatus(response.project.status)
    }
  }

  const fetchUpdateMissionDescription = async (missionDescription) => {
    const response = await useFetch('PATCH', `/projects/billable/${id}/update_mission_description`, { mission_description: missionDescription })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProject({ ...project, mission_description: response.project.mission_description })
    }
  }

  const fetchUpdateMissionCommentary = async (comment) => {
    const response = await useFetch('PATCH', `/projects/billable/${id}/update_mission_comment`, { comment })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProject({ ...project, comment: response.project.comment })
    }
  }

  const fetchUpdateStatus = async (projectParams) => {
    // eslint-disable-next-line camelcase
    const billable_project = {
      status: projectParams['status'],
      end_date: projectParams['endDate'],
      qa_director: projectParams['qaDirector'],
      amount_difference_comment: projectParams['amountDifferenceComment'],
      comment: projectParams['comment'],
      other_qa_director: projectParams['otherQADirector'],
    }

    const response = await useFetch('PUT', `/projects/billable/${id}/update_status`, { billable_project })

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setStatus(response.project_status)
      publish('/octopod/project/update_project')
      publish('/octopod/project/update_kpis')
      publish('/octopod/ajax/reset_elements')
      await fetchGetProject()
    }
  }

  useEffect(() => {
    subscribe('/octopod/tabchange', () => {
      fetchGetProject()
    }, 0)

    subscribe('/octopod/project/update_service', () => {
      fetchGetProject()
    }, 0)

    subscribe('/octopod/project/update_project', () => {
      fetchGetProject()
    }, 0)

    fetchGetProject()
  }, [])

  return (
    <ProjectDataHatContext.Provider value={{
      errors,
      project,
      status,
      canUpdateToSigned,
      fetchGetProject,
      fetchUpdateMissionDescription,
      fetchUpdateMissionCommentary,
      fetchUpdateStatus,
    }}
    >
      {children}
    </ProjectDataHatContext.Provider>
  )
}

export const useProjectDataHatContext = () => useContext(ProjectDataHatContext)
