import React from 'react'
import { useTranslation } from 'react-i18next'
import PersonPicker from '../../../input/PersonPicker/PersonPicker'
import TimesheetPicker from '../../../input/TimesheetPicker/TimesheetPicker'
import { StyledTimesheetForm } from './TimesheetForm.styled'

import { useTimesheetParamsValue } from '../../../../context/Timesheet/TimesheetParamsContext'
import AskbobLogo from '../../../UI/Logo/AskbobLogo'

const TimesheetForm = () => {
  const { t } = useTranslation()
  const [timesheetParams, dispatchTimesheetParams] = useTimesheetParamsValue()

  const changePerson = (selectedOption) => {
    const newPerson = {
      id: selectedOption.selectedValue,
      label: selectedOption.label,
      nickname: selectedOption.nickname,
    }

    dispatchTimesheetParams({
      type: 'changeOwner',
      owner: newPerson,
    })
  }

  return (
    <StyledTimesheetForm>
      <p>{t('myTimesheet')}</p>
      <div className="person-picker" role="person-picker" data-person={timesheetParams.owner.label}>
        <PersonPicker value={timesheetParams.owner} onChange={changePerson} />
      </div>
      <AskbobLogo nickname={timesheetParams.owner.nickname} />
      <TimesheetPicker />
    </StyledTimesheetForm>
  )
}

export default TimesheetForm
