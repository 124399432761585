import styled from 'styled-components'

const graybackground = ({ theme }) => theme.colors.betterway.primary[5]
const bluebackground = ({ theme }) => theme.colors.betterway.primary[100]
const bluecolor = ({ theme }) => theme.colors.betterway.primary[100]
const whitecolor = ({ theme }) => theme.colors.white

export const SynchronizationCardStyled = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isButton ? `column` : `row`)};
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.isButton ? `30rem` : ``)};
  height: ${(props) => (props.isButton ? `` : `10rem`)};
  margin-top: 2rem;
  padding: 2.8rem;
  border: solid 0.1rem ${({ theme }) => theme.colors.betterway.primary[50]};
  border-radius: 1rem;
  background-color: graybackground;

  &:not(:last-child) {
    margin-right: 2rem;
  }

  .card-header {
    display: flex;
    align-items: center;
    width: 100%;
    margin: ${(props) => (props.isButton ? `0rem` : `2rem`)};
    font-weight: bold;

    .infos-header {
      display: flex;
      flex-direction: column;
      width: ${(props) => (props.isButton ? `` : `25rem`)};
      font-size: 1.6rem;

      .updater-info {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        font-size: 1.2rem;
        font-family: ${({ theme }) => theme.fonts.family.light};
      }
    }

    img {
      margin-right: ${(props) => (props.isButton ? `1.6rem` : `3.1rem`)};
    }
  }

  .button-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 4rem;
    padding: 0 5rem;
    border: 1px solid ${({ theme }) => theme.colors.betterway.primary[50]};
    border-radius: 2.1rem;
    background-color: ${({ theme }) => theme.colors.betterway.white};
    color: ${({ theme }) => theme.colors.betterway.primary[50]};
    font-weight: 400;
    font-variant: normal;
    font-size: 1.7rem;
    text-decoration: none;

    &.gray {
      border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
      background-color: ${({ theme }) => theme.colors.betterway.primary[5]};
      color: ${({ theme }) => theme.colors.primaryBlue};
    }

    img {
      margin-left: 1.6rem;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryBlue};
      color: ${({ theme }) => theme.colors.white};
    }
  }

  button {
    align-items: center;
    justify-content: center;
    width: auto;
    margin-top: 2rem;
    padding: 0 3.5rem;
    border: 1px solid;
    border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
    background-color: ${(props) => (props.isButtonGray ? graybackground : bluebackground)};
    color: ${(props) => (props.isButtonGray ? bluecolor : whitecolor)};
    font-size: 1.5rem;

    .picto {
      margin-left: 1.6rem;
    }

    &:hover {
      border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
      background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
      color: ${({ theme }) => theme.colors.white};
    }

    &:disabled {
      border-color: ${({ theme }) => theme.colors.betterway.primary[50]};
      background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
      color: ${({ theme }) => theme.colors.betterway.white};
      cursor: not-allowed !important;
    }
  }
`
