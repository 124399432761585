import styled from 'styled-components'

export const ModalListDeleteActivityExpenseStyled = styled.div`
      position: fixed;
      top: 0;
      left: 0;
      z-index: 3000;
      width: 100vw;
      height: 100vh;
      background-color: #00000061;

      .container-modal {
        position: fixed;
        top: 40%;
        left: 50%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 96rem;
        padding: 3.2rem 4rem;
        border-left: 0.5em solid ${({ theme }) => theme.colors.betterway.red[100]};
        border-radius: 0.375em;
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
        box-shadow: 0 4px 8px 0 ${({ theme }) => theme.colors.shadow};
        transform: translateX(-50%);

        .header-modal {
          display: flex;
          flex-direction: row;
          gap: 1em;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 1.6em;

          .title {
            display: flex;
            gap: 1rem;
            align-items: center;

            h2 {
              font-weight: 600;
              font-size: 1.8rem;
            }

            img {
              width: 2.8rem;
            }
          }

          .picto-close {
            display: flex;
            cursor: pointer;
          }
        }

        .content-modal {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: flex-start;
          justify-content: start;
          width: 100%;
          height: 100%;
          margin-bottom: 1.6rem;
          padding-left: 4.5rem;

          .infos-text {
            width: fit-content;
            height: auto;
          }

          ul {
            display: flex;
            flex-direction: column;
            width: fit-content;
            height: auto;
            padding-left: 1.5rem;

            li {
              display: list-item;
              gap: 5.6rem;
              list-style: disc;

              .text {
                display: flex;
                gap: 0.5rem;

                .title {
                  font-weight: 600;
                }
              }
            }
          }
        }

        .footer-modal {
          display: flex;
          justify-content: end;
          width: 100%;

          .cancel {
            width: max-content;
            margin-left: 2rem;
          }

          .delete-activity {
            width: max-content;
            margin-left: 2rem;
          }
        }
      }
`
