import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react'

import {
  useFetchData,
  buildUrlWithId,
} from '../../../fetchOverviewApi'
import { useStateValue } from '../../../state'

import ActivityOverviewRow from '../ActivityOverviewRow/ActivityOverviewRow'
import PersonRow from '../PersonRow/PersonRow'
import SkeletonTableRow from '../SkeletonRow/SkeletonRow'

const LobRow = ({ lobData }) => {
  const [open, setOpen] = useState(false)
  const [{ range }] = useStateValue()
  const [loading, hasError, data, handleFetch] = useFetchData(true)
  const isMounted = useRef(true)

  useEffect(() => {
    if (!open || data) return

    const url = buildUrlWithId('lob', lobData.id, range)
    handleFetch(isMounted, url)
  }, [open, data])

  const renderChildren = useCallback(() => (
    loading ? <SkeletonTableRow rows={3} columns={14} /> : renderRows()
  ), [loading, data])

  const sortBy = (key, order = 'asc') => function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0
    }

    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key]
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    )
  }

  const renderRows = () => {
    if (!hasError) {
      data.people.sort(sortBy('activity_rate', 'desc'))

      return (
        data.people.map((person) => (
          <PersonRow
            key={person.id}
            personData={person}
          />
        ))
      )
    }
  }

  useEffect(() => () => {
    isMounted.current = false
  }, [])

  return (
    <>
      <ActivityOverviewRow
        style="lob"
        open={open}
        data={lobData}
        onClick={() => setOpen(!open)}
        key={lobData.id}
      />
      {open && renderChildren()}
    </>
  )
}

export default LobRow
