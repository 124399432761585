export default {
  colors: {
    betterway: {
      primary: {
        100: '#0E2356', 70: '#566589', 50: '#8691AA', 20: '#CFD3DD', 5: '#F3F4F7',
      },
      secondary: {
        100: '#00A3BE', 70: '#4CBFD1', 50: '#80D1DE', 20: '#CCEDF2', 5: '#F2FAFC',
      },
      cyan: {
        100: '#00A795', 70: '#4CC1B5', 50: '#80D3CA', 20: '#CCEDEA', 5: '#F2FBFA',
      },
      green: {
        100: '#00AD00', 70: '#4CC64C', 50: '#80D680', 20: '#CCEFCC', 5: '#F2FBF2',
      },
      yellow: {
        100: '#FFE300', 70: '#FFEB4C', 50: '#FFF180', 20: '#FFF9CC', 5: '#FFFEF2',
      },
      orange: {
        100: '#FF6200', 70: '#FF914C', 50: '#FFB080', 20: '#FFE0CC', 5: '#FFF7F2',
      },
      red: {
        100: '#EE0023', 70: '#F34C65', 50: '#F68091', 20: '#FCCCD3', 5: '#FEF2F4',
      },
      purple: {
        100: '#9A78F0', 70: '#B8A0F4', 50: '#CCBBF7', 20: '#EBE4FC', 5: '#FAF8FE',
      },
      white: '#FFFFFF',
      overlay: '#2C2C2C80',
      overlayLight: '#63636333',
      transparent: '#FFFFFF00',
      shadow: '#8CDBE891',
    },
    primaryLight: '#D0CEFF66',
    primaryMedium: '#4227A480',
    primary: '#0E2356',
    secondary: '#00AFCB',
    secondaryPale: 'rgb(0, 175, 203, .5)',
    primaryDark: '#14093F',
    primaryGrey: '#8D91B1',
    primaryGreyDark: '#989898',
    primaryGreyLight: '#F3F2F7',
    primaryHover: '#A9A5B6',
    primarySelected: '#613BEA',
    secondaryLight: '#D9D7EB',
    grey: '#44444480',
    white: '#FFFFFF',
    lighterBlue: '#0088CC',
    lightBlue: '#34406E',
    paleBlue: '#C2C5D8',
    paleBlue2: '#E9ECFF',
    paleBlue3: '#4F61D6',
    paleBlue4: '#263967',
    skyBlue: '#005580',
    steelBlue: '#46799E',
    primaryBlue: '#0E2356',
    primaryBlueLight: 'rgba(14, 35, 86, .3)',
    primaryBluePale: 'rgba(14, 35, 86, .15)',
    secondaryBlue: '#00A9C5',
    purple: '#455691',
    lightGrey: '#DDDDDD',
    lighterGray: '#E5E5E5',
    paleGrey: '#E0E0E0',
    paleGrey2: '#E5E5E5',
    paleGrey3: '#F7F7F7',
    paleGrey4: '#FDFDFD',
    darkerGray: '#ccc',
    darkestGray: '#333',
    skeletonGreyLight: '#D3D3D3',
    skeletonGreyDark: '#B3B3B3',
    shadow: 'rgba(0, 0, 0, .2)',
    green: '#57B756',
    darkGreen: '#2E7330',
    yellow: '#FFA800',
    orange: '#E38968',
    darkPurple: '#C244AF',
    darkPink: '#DB5481',
    lightYellow: 'rgba(255, 168, 0, 0.15)',
    blockingError: '#EC5344',
    blockingErrorPale: 'rgb(236, 83, 68, 0.5)',
    blockingErrorLight: '#FFE7E6',
    errorLight: ' rgba(241, 148, 94, 0.12)',
    error: '#F1945E',
    black: '#000000',
  },
  devices: {
    mobile: '@media screen and (max-width: 576px)',
  },
  sizes: {
    navbarHeight: '8.2rem',
    timesheetHeaderHeight: '12.1rem',
    mobile: '576px',
    mobilePortrait: '800px',
    mobileLandscape: '950px',
    desktop: '992px',
    desktopMedium: '1380px',
    desktopLarge: '1580px',
  },
  fonts: {
    size: {
      normal: '1.3rem',
      large: '1.4rem',
      big: '1.6rem',
    },
    family: {
      light: 'Apercu-Light',
      medium: 'Apercu-Medium',
      bold: 'Apercu-Bold',
      standard: 'Apercu',
    },
  },
}
