import React, { createElement } from 'react'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from '../../../style/globalStyle'

export const usePackedComponent = (component, theme) => {
  const wrapper = (props) => (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {
        createElement(component, props)
      }
    </ThemeProvider>
  )

  wrapper.displayName = component.displayName

  return wrapper
}
