import React, {
  createContext,
  useContext,
} from 'react'

export const DateContext = createContext()

export const DateProvider = ({ children }) => {
  const isToday = (fullDate) => {
    const dateTimesheetDay = new Date(fullDate)
    const dateToday = new Date()
    return (
      dateTimesheetDay.getDate() === dateToday.getDate()
      && dateTimesheetDay.getMonth() === dateToday.getMonth()
      && dateTimesheetDay.getFullYear() === dateToday.getFullYear()
    )
  }

  return (
    <DateContext.Provider value={{ isToday }}>
      {children}
    </DateContext.Provider>
  )
}

export const useDateContextValue = () => useContext(DateContext)
