import React from 'react'
import { StyledActivityInfo } from './ActivityInfo.styled'
import Skeleton from '../../../../../UI/Skeleton/Skeleton'

const ActivityInfoSkeleton = () => (
  <StyledActivityInfo>
    <Skeleton width="100px" lineHeight="20px" height="14px" />
    <div className="activity-title">
      <Skeleton width="300px" lineHeight="20px" height="14px" />
    </div>
    <div className="project-dates" style={{ marginBottom: '20px' }}>
      <Skeleton width="200px" lineHeight="20px" height="14px" />
    </div>
    <div className="row-info">
      <div className="info">
        <Skeleton width="150px" lineHeight="20px" height="14px" />
      </div>
      <div className="info">
        <Skeleton width="150px" lineHeight="20px" height="14px" />
      </div>
    </div>
    <div className="row-info">
      <div className="info">
        <Skeleton width="150px" lineHeight="20px" height="14px" />
      </div>
      <div className="info">
        <Skeleton width="150px" lineHeight="20px" height="14px" />
      </div>
    </div>
    <div className="info delete-activity">
      <Skeleton width="150px" lineHeight="20px" height="14px" />
    </div>
  </StyledActivityInfo>
)

export default ActivityInfoSkeleton
