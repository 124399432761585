import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SynchronizationHeaderStyled } from './SynchronizationHeader.styled'
import hourglass from '../../../../assets/images/hourglass.svg'
import { useSynchronizationContext } from '../../../context/Synchronization/SynchronizationContext'
import Close from '../../../../assets/images/Close.svg'

export default function SynchronizationHeader({ className }) {
  const { t } = useTranslation()
  const {
    jobLaunched,
    alertClosed,
    setAlertClosed,
  } = useSynchronizationContext()

  useEffect(() => {
    setAlertClosed(true)
  }, [])

  const jobConfirmationAlert = () => (
    <div className="in-progress-alert">
      <div className="img-circle">
        <img src={hourglass} alt="hourglass" />
      </div>
      <p>{`${t('synchronization.alertMessage.synchroInProgress')}`}</p>
      <div className="close-btn">
        <button
          type="button"
          aria-label="close alert button"
          onClick={() => { setAlertClosed(!alertClosed) }}
        >
          <img src={Close} alt="cross close" />
        </button>
      </div>
    </div>
  )

  return (
    <SynchronizationHeaderStyled className={className}>
      {(jobLaunched && !alertClosed) ? jobConfirmationAlert() : '' }
      <h1>
        {t('synchronization.title')}
      </h1>
      <h2>
        {t('synchronization.underTitle')}
      </h2>
    </SynchronizationHeaderStyled>
  )
}
