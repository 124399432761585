import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Navbar } from '@produits-internes-oss/octopod-navbar'
import { ThemeProvider } from 'styled-components'
import BurgerMenu from './BurgerMenu/BurgerMenu'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import logo from '../../../assets/images/octopod_logo.svg'
import { StateContext } from '../../state'

export const NavbarContainer = ({
  paths,
  personIsAdmin,
  personIsHRAdmin,
  featureEnabled,
  personBillingEnabled,
  personMealExpensesEnabled,
  currentUserTimesheetPath,
  currentUserNickname,
}) => {
  const { t, i18n } = useTranslation()

  const [openMenu, setOpenMenu] = useState(false)

  const navigate = useCallback((href) => { window.location.href = `${href}?locale=${i18n.language}` }, [])

  const navigateInNewTab = useCallback((href) => window.open(href, '_blank').focus(), [])
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <StateContext.Provider value={{ paths, personIsAdmin, personIsHRAdmin, featureEnabled, personBillingEnabled, personMealExpensesEnabled }}>
        <Navbar
          logo={logo}
          localization={{ hub: { arialabel: t('navbar.hub.arialabel'), text: t('navbar.hub.text') } }}
          links={[
            {
              name: t('myTimesheet'),
              text: t('myTimesheet'),
              url: `${currentUserTimesheetPath}`,
            },
            {
              name: t('myExpenses'),
              text: t('myExpenses'),
              url: `/expenses`,
            },
            featureEnabled
              ? {
                name: t('myHolidays'),
                text: t('myHolidays'),
                url: `/people/${currentUserNickname}/holidays`,
              }
              : {
                name: t('myProfile'),
                text: t('myProfile'),
                url: `/people/profile`,
              },
            {
              name: t('individualActivityReport'),
              text: t('individualActivityReport'),
              url: `/person_activity_report`,
            },
          ]}
          actions={{
            left: [
              {
                name: t('navbar.support'),
                text: t('navbar.support'),
                icon: 'comment-fill',
                onClick: () => navigateInNewTab('https://docs.google.com/forms/d/1zM4GkIPITdkVHeg-Lb2PgK0ZI251Yeg6MnMQ0APxO_k/formrestricted'),
              },
            ],
            right: [
              {
                name: t('navbar.menu'),
                icon: 'menu',
                onClick: () => setOpenMenu((previous) => !previous),
              },
            ],
          }}
          onHome={() => navigate('/')}
          onHub={() => setOpenMenu(false)}
        />
        <BurgerMenu open={openMenu} toggleMenu={() => setOpenMenu((previous) => !previous)} closeMenu={() => setOpenMenu(false)} />
      </StateContext.Provider>
    </ThemeProvider>
  )
}
