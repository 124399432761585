import styled from 'styled-components'

export const AlertStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 1.2rem;
  border-width: 0.1rem;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.betterway.green[100]};
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.colors.betterway.green[5]};

  >.alert-content {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;

    >.alert-content-icon {
      width: 3.5rem;
      height: 3.5rem;
      margin-right: 1.6rem;
      background-image: url(${({ icon }) => icon});
      background-size: contain;
      background-repeat: no-repeat;
    }

    >.alert-content-text {
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      font-size: 1.8rem;
      font-family: "Outfit", "Apercu", sans-serif;
      letter-spacing: normal;
    }
  }

  >.alert-button {
    width: initial;
    height: 4rem;

    > button {
      border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      font-size: 1.6rem;
      font-family: "Outfit", "Apercu", sans-serif;
      letter-spacing: normal;

      &:hover {
        background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
        color: ${({ theme }) => theme.colors.betterway.white};
      }
    }
  }
`
