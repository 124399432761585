import React from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeProvider } from 'styled-components/macro'
import { Section } from '@produits-internes-oss/design-system-components'
import { ProjectSummaryScreenStyled } from './ProjectSummaryScreenStyled.styled'
import { ProjectSummaryContextProvider } from '../../context/ProjectSummaryContext/ProjectSummaryContext'
import { ProjectInfo } from '../../components/ProjectInfo/ProjectInfo'
import { ProjectAffiliated } from '../../components/ProjectAffiliated/ProjectAffiliated'
import { ProjectStatusHistory } from '../../components/ProjectStatusHistory/ProjectStatusHistory'
import { ProjectCci } from '../../components/projectCci/ProjectCci'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import { ProjectTurnover } from '../../components/ProjectTurnover/ProjectTurnover'
import { ProjectErrorsSummary } from '../../components/ProjectErrorsSummary/ProjectErrorsSummary'
import { ProjectPublishersInfos } from '../../components/ProjectPublishersInfos/ProjectPublishersInfos'

export default function ProjectSummaryScreen({ id }) {
  const { t } = useTranslation()

  return (
    <ProjectSummaryContextProvider id={id}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ProjectSummaryScreenStyled>
          <ProjectErrorsSummary />
          <div className="summary">
            <div className="row-section-infos">
              <Section title={{ text: t('project.summary.info.title'), level: 2 }}>
                <div className="section-infos">
                  <ProjectInfo />
                </div>
              </Section>
            </div>
            <div className="row-section-turnover">
              <Section title={{ text: t('project.summary.turnover.title'), level: 2 }}>
                <ProjectTurnover />
              </Section>
            </div>
            <Section className="section-cci" title={{ text: t('project.summary.cci.title'), level: 2 }}>
              <ProjectCci />
            </Section>
            <div className="status-history">
              <Section title={{ text: t('project.summary.history.title'), level: 2 }}>
                <ProjectStatusHistory />
              </Section>
            </div>
            <div className="row-section">
              <Section title={{ text: t('project.summary.affiliated.title'), level: 2 }}>
                <div className="section-affiliated">
                  <ProjectAffiliated />
                </div>
              </Section>
              <Section title={{ text: t('project.summary.publisher.title'), level: 2 }}>
                <div className="row-section-publisher-info">
                  <ProjectPublishersInfos />
                </div>
              </Section>
            </div>
          </div>
        </ProjectSummaryScreenStyled>
      </ThemeProvider>
    </ProjectSummaryContextProvider>
  )
}
