import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import Backdrop from '../../../../../UI/Backdrop/Backdrop'
import { StyledInvalidationPopin } from './InvalidationPopin.styled.jsx'
import Button from '../../../../../input/Buttons/Button/Button'

const InvalidationPopin = ({
  open,
  close,
  toggleInvalidationPopin,
  invalidatePeriod,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Backdrop open={open} close={close} />
      <CSSTransition
        in={open}
        mountOnEnter
        unmountOnExit
        timeout={0}
      >
        <StyledInvalidationPopin className="delete-warning" role="delete-warning">
          <p>{t('period.confirmUnvalidation')}</p>
          <Button
            type="button"
            color="blockingError"
            click={() => {
              toggleInvalidationPopin()
              invalidatePeriod()
            }}
          >
            {t('yes')}
          </Button>
          <Button
            type="text"
            color="primaryGreyDark"
            click={() => {
              toggleInvalidationPopin()
            }}
          >
            {t('cancel')}
          </Button>
        </StyledInvalidationPopin>
      </CSSTransition>
    </>
  )
}

export default InvalidationPopin
