import styled, { css } from 'styled-components'

export const StyledChevron = styled.svg`
  width: 0.8rem;
  margin-left: auto;
  margin-right: 2.4rem;
  transition: transform 0.3s linear;
  transform: ${({ unfold }) => (unfold ? 'rotate(90deg)' : '')};
  ${({ backward }) => backward
    && css`
      transform: ${({ unfold }) => (unfold ? 'rotate(-90deg)' : '')};;
  `};

  ${({ type }) => type === 'project'
    && css`
      width: 1.2rem;
      margin: 0;
  `};
`
