import React, { useRef, useState, useEffect, useContext, createContext } from 'react'
import { TooltipStyled } from './Tooltip.styled'
import pictoArrow from '../../../assets/images/tooltipArrow.svg'

const Tooltip = ({ onCallback, hasArrow }) => {
  const parent = useRef(null)
  const target = useRef(null)
  const element = useRef(null)

  const [content, setContent] = useState(null)
  const [position, setPosition] = useState(null)
  const [elementPosition, setElementPosition] = useState('top')

  const restoreTarget = () => {
    if (target.current !== null) {
      target.current.element.style.position = target.current.position
    }
  }

  const clearTarget = () => {
    target.current = null

    parent.current.prepend(element.current)
  }

  const setTarget = (targetElement) => {
    target.current = {
      element: targetElement,
      position: targetElement.style.position,
    }

    if (!target.current.position) {
      target.current.element.style.position = 'relative'
    }

    target.current.element.append(element.current)
  }

  const setTooltip = (targetElement, contentToDisplay, elementPosition) => {
    restoreTarget()

    if (targetElement === null) {
      clearTarget()
      setPosition(null)
      setContent('')
    } else {
      setTarget(targetElement)
      setPosition({ x: 0, y: 0 })
      setContent(contentToDisplay)
    }
    setElementPosition(elementPosition)
  }

  useEffect(() => {
    parent.current = element.current.parentNode
    onCallback({ setTooltip })
  },
  [])

  useEffect(() => {
    function handleClickOutside(event) {
      if (element.current && !element.current.contains(event.target)) {
        setTooltip(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [element])

  return (
    <TooltipStyled ref={element} className={position ? elementPosition : 'hidden'} pictoArrow={pictoArrow}>
    <div className="tooltip-content">{content}</div>
      {hasArrow ? <div className={`tooltip-arrow ${elementPosition}`} /> : null}
    </TooltipStyled>
  )
}

const TooltipContext = createContext()

export const TooltipProvider = ({ children, tooltipsConfiguration }) => {
  const tooltips = useRef({})

  const setTooltipByName = (name, target, position, elementPosition = 'top') => {
    tooltips.current[name](target, position, elementPosition)
  }

  return (
    <TooltipContext.Provider value={{ setTooltipByName }}>
      {
        Object.entries(tooltipsConfiguration).map(([key, { position, hasArrow }]) => (
          <Tooltip
            key={key}
            position={position}
            hasArrow={hasArrow}
            onCallback={({ setTooltip }) => { tooltips.current[key] = setTooltip }}
          />
        ))
      }
      {
        children
      }
    </TooltipContext.Provider>
  )
}

export const useTooltip = () => useContext(TooltipContext)
