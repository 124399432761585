import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectExpensesInfoStyled } from './ProjectExpensesInfo.styled'

export const ProjectExpensesInfo = () => {
  const { t } = useTranslation()
  return (
    <ProjectExpensesInfoStyled className="info">
      <span>{t('project.expenses.info.0')}</span>
      <em>{t('project.expenses.info.1')}</em>
      <span>{t('project.expenses.info.2')}</span>
      <em>{t('project.expenses.info.3')}</em>
      <span>{t('project.expenses.info.4')}</span>
    </ProjectExpensesInfoStyled>
  )
}
