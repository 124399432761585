import styled from 'styled-components'

export const ProjectPaymentScheduleStyled = styled.div`
  &.schedule-container {
    margin-top: 2.4rem;
    margin-bottom: 2rem;
  }

  &.octo {
    .contract-payment-schedule {
      .form-section {
        display: flex;
        gap: 4rem;

        .left-section {
          .price-tag {
            position: relative;

            &::after {
              content: '€';
              position: absolute;
              top: 3.6rem;
              right: 1.5rem;
            }
          }

          .schedule-amount {
            width: 30rem;
          }

          button {
            display: flex;
            flex-flow: row nowrap;
            gap: 0.9rem;
            align-items: center;
            justify-content: stretch;
            width: 100%;
            margin-top: 1.6rem;
            padding: 0.55rem 0;
            cursor: pointer;

            .checkbox {
              position: relative;
              box-sizing: border-box;
              min-width: 2.2rem;
              min-height: 2.2rem;

              .checkbox-icon {
                position: absolute;
                width: auto;
                height: auto;
                inset: 0;
              }

              ::before {
                content: '';
                position: absolute;
                border-width: 0.1rem;
                border-style: solid;
                border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
                border-radius: 0.3rem;
                background-color: ${({ theme }) => theme.colors.betterway.white};
                inset: 0;
              }
            }

            .text {
              color: ${({ theme }) => theme.colors.betterway.primary[100]};
              font-weight: 600;
              font-size: 1.6rem;
            }
          }

          button:disabled {
            cursor: not-allowed;

            .checkbox {
              background-color: ${({ theme }) => theme.colors.betterway.primary[50]};

              &::before {
                border-color: ${({ theme }) => theme.colors.betterway.primary[50]};
                background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
              }
            }
          }

          div.checked {
            button {
              .checkbox {
                background-color: ${({ theme }) => theme.colors.betterway.primary[100]};

                &::before {
                  border-width: 1rem;
                  border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
                  background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
                }
              }
            }

            button:disabled {
              cursor: not-allowed;

              .checkbox {
                background-color: ${({ theme }) => theme.colors.betterway.primary[50]};

                &::before {
                  border-color: ${({ theme }) => theme.colors.betterway.primary[50]};
                }
              }
            }
          }
        }

        .right-section {
          .schedule-comment {
            width: 61.6rem;
          }

          .comment-limit {
            display: flex;
            margin-top: 1rem;

            img {
              width: 1.67rem;
              height: 1.67rem;
              margin-right: 0.4rem;
              margin-left: 0.8rem;
            }

            p {
              font-weight: 600;
              font-size: 1.2rem;
            }
          }
        }
      }

      .cta {
        display: flex;
        justify-content: flex-end;

        .cancel {
          max-width: 11.3rem;
          margin-right: 1.6rem;
        }

        .submit {
          max-width: 24.6rem;
        }
      }
    }

    .payment-schedule-dashboard {
      padding: 1rem 0;

      .dashboard {
        display: flex;
        height: auto;

        .amount,
        .comment {
          border-color: transparent ${({ theme }) => theme.colors.betterway.primary[20]} transparent transparent;
          border-right-width: 0.1rem;
          border-right-style: solid;
        }

        .amount,
        .comment,
        .fees {
          width: 33.33%;
          padding: 0 2.4rem;
          text-align: center;
          word-wrap: break-word;

          p:first-child {
            margin-bottom: 1.8rem;
            font-weight: 600;
            font-size: 1.6rem;
          }
        }

        .amount {
          p:last-child {
            font-weight: 600;
            font-size: 2.4rem;
          }
        }

        .fees {
          .box-container {
            display: flex;
            align-items: center;
            justify-content: space-around;

            .fees-box {
              display: flex;
              align-items: center;
              justify-content: space-around;
              height: 3.6rem;
              padding: 0 1.8rem;
              border-width: 0.1rem;
              border-style: solid;
              border-radius: 0.3rem;

              p {
                margin: 0;
                font-weight: 400;
              }
            }
          }

          .included {
            border-color: ${({ theme }) => theme.colors.betterway.green[100]};
            background-color: ${({ theme }) => theme.colors.betterway.green[5]};
          }

          .not-included {
            border-color: ${({ theme }) => theme.colors.betterway.red[100]};
            background-color: ${({ theme }) => theme.colors.betterway.red[5]};
          }
        }
      }

      .fees-hidden {
        display: none;
      }

      .no-fees {
        width: 50% !important;
      }

      .comment.no-fees {
        border-right: none;
      }
    }

    .modify-btn-section {
      height: 0;

      .btn-modify {
        position: relative;
        top: -7.2rem;
        right: -1.9rem;
        display: flex;
        align-items: flex-end;

        button {
          display: flex !important;
          width: auto !important;
          height: auto !important;
          padding: 0;
          border: none !important;
          background-color: transparent !important;
          color: ${({ theme }) => theme.colors.primary} !important;
          font-weight: normal !important;
          font-size: 1.4rem !important;
          text-decoration: underline !important;

          &:hover {
            border: none !important;
            background-color: transparent !important;
          }
        }
      }
    }
  }
`
