import React, { useContext, createContext } from 'react'
import { useFetch } from '../../fetchOverviewApi'

const ConsecutivePaidLeaveContext = createContext()

export const ConsecutivePaidLeaveContextProvider = ({ children, peopleWithoutMandatoryPaidLeaves }) => {
  const fetchPostSendEmailToSelectedPeople = async (email) => {
    await useFetch(
      'POST',
      '/people/notify_people_without_mandatory_paid_leave_fulfilled',
      {
        people_ids: peopleWithoutMandatoryPaidLeaves.map((person) => person.id),
        email_subject: email.subject,
        email_body: email.body,
      },
      false,
    )
  }

  return (
    <ConsecutivePaidLeaveContext.Provider
      value={
        {
          peopleWithoutMandatoryPaidLeaves,
          fetchPostSendEmailToSelectedPeople,
        }
      }
    >
      {children}
    </ConsecutivePaidLeaveContext.Provider>
  )
}
export const useConsecutivePaidLeaveContext = () => useContext(ConsecutivePaidLeaveContext)
