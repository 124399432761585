import styled from 'styled-components'
import { mediaQueryForMobile } from '../../../style/devices'
import imageOcto from '../../../assets/images/octo.svg'

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  padding: 0 20% 2rem;
  color: ${({ theme }) => theme.colors.betterway.primary[100]};

  .person {
    width: 100%;
    margin: 1.4rem 0;
  }

  .expense-header-img {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 30rem;
    background-image: url(${(props) => props.QRCodeImage});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  ${mediaQueryForMobile} {
    padding: 2rem;

    .expense-header-img {
      width: 100%;
      height: 6rem;
      background-image: url(${imageOcto});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  h1 {
    width: 100%;
    margin-bottom: 0.4rem;
    color: ${({ theme }) => theme.colors.betterway.primary[100]};
    font-size: 2.2rem;
    line-height: 1;
  }

  .person-info {
    width: 100%;
    color: ${({ theme }) => theme.colors.betterway.primary[70]};
    font-size: 1.8rem;
  }

  .date {
    display: flex;
    flex-flow: column nowrap;
    gap: 1.6rem;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    margin: 1.6rem 0 2.4rem;
  }

  .balance {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    padding: 2.4rem;
    border-radius: 1rem;
    background: ${({ theme }) => theme.colors.betterway.primary[100]};

    .total {
      .total-title {
        color: ${({ theme }) => theme.colors.betterway.primary[20]};
        font-size: 1.4rem;
      }

      .total-amount {
        margin-top: 0.8rem;
        color: ${({ theme }) => theme.colors.betterway.white};
        font-weight: bold;
        font-size: 2.4rem;
      }
    }
  }

  .add {
    margin-top: 0.8rem;

    button {
      width: 100%;
      height: auto;
      padding: 0;
      border: none;
      background-color: ${({ theme }) => theme.colors.betterway.transparent};
      color: ${({ theme }) => theme.colors.betterway.secondary[100]};
      font-size: 1.6rem;
    }
  }

  .expenses {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 100%;
    min-height: 10rem;
    margin-top: 2.4rem;

    .expenses-title {
      color: ${({ theme }) => theme.colors.betterway.primary[70]};
      font-weight: 500;
      font-size: 2rem;
    }

    .expenses-item {
      margin-top: 0.8rem;
    }
  }
`
