import styled from 'styled-components'
import { Section } from '@produits-internes-oss/design-system-components'
import pictoExclamation from '../../../assets/images/exclamation.svg'

export const ProjectSalesExpensesFormStyled = styled(Section)`
  > section {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: space-between;

    >.header {
      > strong {
        font-weight: 600;

        ::after {
          content: ' *';
          color: ${({ theme }) => theme.colors.betterway.red[100]};
        }
      }

      >.example {
        margin-top: 0.4rem;

        > em {
          text-decoration: underline;
        }

        > span {
          ::before {
            content: ' ';
          }
        }
      }
    }

    >.form {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: stretch;

      >.form-values {
        display: flex;
        flex: 3;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: stretch;

        >.form-values-row {
          display: flex;
          flex-flow: row nowrap;
          gap: 1.6rem;
          align-items: center;
          justify-content: flex-start;

          >.name,
          >.amount,
          >.type,
          >.quantity,
          >.suncontractor,
          >.month,
          >.kind {
            max-width: calc(30rem - 0.8rem);
          }

          >.kind {
            >.enum {
              height: 4.2rem;
              padding: 0;
              border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[70]};
              border-radius: 2rem;

              >.track {
                button {
                  text-align: center;

                  &:not(.selected) {
                    cursor: pointer;
                  }
                }

                >.slider {
                  box-sizing: border-box;
                  border: 2px solid ${({ theme }) => theme.colors.betterway.white};
                  border-radius: 2rem;
                  background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
                }
              }
            }
          }
        }

        >.last-row {
          margin-top: 2.4rem;
        }

        >.comment {
          max-width: 60rem;
          margin-top: 2.4rem;

          >.area {
            border-radius: 2rem;
          }
        }

        >.max {
          display: flex;
          flex-flow: row nowrap;
          gap: 0.4rem;
          align-items: center;
          justify-content: flex-start;
          margin-top: 1rem;
          margin-left: 1.6rem;
          font-weight: 600;
          font-size: 1.2rem;

          ::before {
            content: '';
            width: 2rem;
            height: 2rem;
            background-color: ${({ theme }) => theme.colors.betterway.red[100]};
            mask-image: url(${pictoExclamation});
            mask-size: contain;
            mask-repeat: no-repeat;
          }
        }

        .title::after {
          content: ' *';
          color: ${({ theme }) => theme.colors.betterway.red[100]};
        }

        input {
          box-shadow: none !important;
        }
      }

      >.form-button {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        >.form-submit {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-end;
          justify-content: flex-end;
          margin-left: 2rem;

          >.eds-button {
            width: auto;
          }
        }

        >.form-cancel {
          width: max-content;
          margin-left: 2rem;

          >.eds-button {
            button {
              border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
              background-color: ${({ theme }) => theme.colors.betterway.white};
              color: ${({ theme }) => theme.colors.betterway.primary[100]};

              &:hover {
                border-color: ${({ theme }) => theme.colors.betterway.white};
                background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
                color: ${({ theme }) => theme.colors.betterway.white};
              }
            }
          }
        }
      }
    }
  }
`
