import {
  useState, useRef, useEffect, useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'

export const useSingleHttpRequest = (loadingOnMount) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(loadingOnMount)
  const [error, setError] = useState()
  const [data, setData] = useState(null)
  const activeHttpRequests = useRef([])

  const sendRequest = useCallback(
    async (url, method = 'GET', body = null, headers = {}) => {
      setIsLoading(true)
      if (activeHttpRequests.current.length > 0) {
        activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort())
      }

      const httpAbortCtrl = new AbortController()
      activeHttpRequests.current.push(httpAbortCtrl)

      try {
        const response = await fetch(url, {
          method,
          body,
          headers,
          signal: httpAbortCtrl.signal,
        })
        const responseData = await response.json()

        if (response.ok) {
          setData(responseData)
          activeHttpRequests.current = activeHttpRequests.current.filter((abortCtrl) => abortCtrl !== httpAbortCtrl)
          if (activeHttpRequests.current.length === 0) setIsLoading(false)
        } else {
          throw new Error(t('error.message'))
        }
      } catch (error) {
        console.log(error)
        activeHttpRequests.current = activeHttpRequests.current.filter((abortCtrl) => abortCtrl !== httpAbortCtrl)
      }
    }, [],
  )

  const clearError = () => setError(null)

  useEffect(() => () => {
    activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort())
  }, [])

  return {
    isLoading,
    error,
    data,
    sendRequest,
    clearError,
    activeHttpRequests,
  }
}

export const useHttpRequestsInSequence = () => {
  const isFetching = useRef(false)
  const pendingHttpRequests = useRef([])
  const activeHttpRequests = useRef([])

  const waitFor = (condition, ms) => {
    const check = (resolve) => {
      if (condition()) resolve()
      else setTimeout(() => check(resolve), ms)
    }

    return new Promise(check)
  }

  const sendRequestWithCSRFTokenInSequence = useCallback(
    async (url, method = 'GET', body = null) => {
      const csrfToken = document.querySelector('[name=csrf-token]')?.content
      const headers = {
        'X-CSRF-TOKEN': csrfToken,
        'Content-Type': 'application/json',
      }

      pendingHttpRequests.current.push(() => sendRequest(url, method, body, headers))
      await waitFor(() => !isFetching.current, 1)

      isFetching.current = true
      const responseData = await pendingHttpRequests.current[0]()
      pendingHttpRequests.current.shift()
      isFetching.current = false

      return responseData
    }, [],
  )

  const sendRequestAfterAllPendingRequests = useCallback(
    async (url, method = 'GET', body = null) => {
      const csrfToken = document.querySelector('[name=csrf-token]')?.content
      const headers = {
        'X-CSRF-TOKEN': csrfToken,
        'Content-Type': 'application/json',
      }

      await waitFor(() => pendingHttpRequests.current.length === 0, 1)
      const responseData = await sendRequest(url, method, body, headers)

      return responseData
    }, [],
  )

  const sendRequestWithCSRFToken = useCallback(
    async (url, method = 'GET', body = null) => {
      const csrfToken = document.querySelector('[name=csrf-token]')?.content
      const headers = {
        'X-CSRF-TOKEN': csrfToken,
        'Content-Type': 'application/json',
      }

      pendingHttpRequests.current.push(() => sendRequest(url, method, body, headers))
      const responseData = await sendRequest(url, method, body, headers)
      pendingHttpRequests.current.shift()

      return responseData
    }, [],
  )

  const sendRequest = async (url, method, body, headers) => {
    const httpAbortCtrl = new AbortController()
    activeHttpRequests.current.push(httpAbortCtrl)

    try {
      const response = await fetch(url, {
        method,
        body,
        headers,
        signal: httpAbortCtrl.signal,
      })
      const responseData = await response.json()
      activeHttpRequests.current = activeHttpRequests.current.filter((abortCtrl) => abortCtrl !== httpAbortCtrl)
      return responseData
    } catch (error) {
      activeHttpRequests.current = activeHttpRequests.current.filter((abortCtrl) => abortCtrl !== httpAbortCtrl)
      console.log(error)
    }
  }

  useEffect(() => () => {
    activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort())
  }, [])

  return {
    sendRequestWithCSRFToken,
    sendRequestWithCSRFTokenInSequence,
    sendRequestAfterAllPendingRequests,
  }
}
