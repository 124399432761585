import styled from 'styled-components'

export const ButtonDeleteActivityModeStyled = styled.div`
      display: flex;
      gap: 1rem;
      width: 100%;
      margin-top: 1rem;

      .delete-activity {
        width: auto;

        button {
          width: auto;

          span {
            position: initial;
            transform: none;
          }
        }
      }

      .cancel {
        width: auto;

        button {
          border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
          background-color: ${({ theme }) => theme.colors.betterway.white};
          color: ${({ theme }) => theme.colors.betterway.primary[100]};

          &:hover {
            border-color: ${({ theme }) => theme.colors.betterway.white};
            background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
            color: ${({ theme }) => theme.colors.betterway.white};
          }

          span {
            position: initial;
            transform: none;
          }
        }
      }
`
