import React from 'react'
import SubcontractorsTableHeader from '../SubcontractorsTableHeader/SubcontractorsTableHeader'
import SubcontractorsTableRow from '../SubcontractorsTableRow/SubcontractorsTableRow'
import { useSubcontracting } from '../../../context/Subcontracting/SubcontractingContext'
import { StyledSubcontractorsOverviewTable } from './SubcontractorsOverviewTable.styled'
import SkeletonTableRow from '../../ActivityOverviewTable/SkeletonRow/SkeletonRow'

const SubcontractorsOverviewTable = () => {
  const {
    isLoading,
    filteredAndSortedData,
  } = useSubcontracting()

  const data = filteredAndSortedData()
  return (
    <StyledSubcontractorsOverviewTable>
      <table>
        <SubcontractorsTableHeader />
        <tbody>
          {
            isLoading
              ? <SkeletonTableRow rows={3} columns={14} />
              : data.map((subcontractorInput) => (
                <SubcontractorsTableRow
                  key={subcontractorInput.subcontractor_index}
                  subcontractorInput={subcontractorInput}
                />
              ))
          }
        </tbody>
      </table>
    </StyledSubcontractorsOverviewTable>
  )
}

export default SubcontractorsOverviewTable
