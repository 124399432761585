import styled from 'styled-components'

export const StyledTimesheetTable = styled.table`
  border-collapse: separate;
  width: 100%;
  margin-top: 2rem;
  tbody::after {
    content: '';
    display: block;
    height: 1rem;
  }
`
