import React from 'react'
import { getCurrentLocaleIndex } from '../../../locale/i18n'

export default function Link({
  children, className, name, href,
}) {
  return (
    <a
      className={className}
      aria-label={`link to ${name}`}
      href={`${href}?locale=${getCurrentLocaleIndex()}`}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  )
}
