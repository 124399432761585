import React from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeProvider } from 'styled-components/macro'
import { Dropdown, Section } from '@produits-internes-oss/design-system-components'
import { ProjectSalesContextProvider } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectSalesScreenStyled } from './ProjectSalesScreen.styled'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import { ProjectRevenueInfo } from '../../components/ProjectRevenueInfo/ProjectRevenueInfo'
import { ProjectService } from '../../components/ProjectService/ProjectService'
import { ProjectServiceHeader } from '../../components/ProjectServiceHeader/ProjectServiceHeader'
import { ProjectExpensesInfo } from '../../components/ProjectExpensesInfo/ProjectExpensesInfo'
import { ProjectExpensesExplanation } from '../../components/ProjectExpensesExplanation/ProjectExpensesExplanation'
import { ProjectSalesExpensesList } from '../../components/ProjectSalesExpensesList/ProjectSalesExpensesList'
import { ProjectSalesExpensesForm } from '../../components/ProjectSalesExpensesForm/ProjectSalesExpensesForm'
import pictoRevenue from '../../../assets/images/pictoRevenue.svg'
import pictoCosts from '../../../assets/images/pictoCosts.svg'
import { ProjectServiceAdjustmentBonus } from '../../components/ProjectServiceAdjustmentBonus/ProjectServiceAdjustmentBonus'
import { ProjectServiceAdjustmentBonusHeader } from '../../components/ProjectServiceAdjustmentBonusHeader/ProjectServiceAdjustmentBonusHeader'
import { ProjectServiceAdjustmentPenalty } from '../../components/ProjectServiceAdjustmentPenalty/ProjectServiceAdjustmentPenalty'
import { ProjectServiceAdjustmentPenaltyHeader } from '../../components/ProjectServiceAdjustmentPenaltyHeader/ProjectServiceAdjustmentPenaltyHeader'
import pictoExpenses from '../../../assets/images/pictoExpenses.svg'
import { ProjectContingencyHeader } from '../../components/ProjectContingencyHeader/ProjectContingencyHeader'
import { ProjectContingency } from '../../components/ProjectContingency/ProjectContingency'
import { ProjectServiceEndOfYearDiscountHeader } from '../../components/ProjectServiceEndOfYearDiscountHeader/ProjectServiceEndOfYearDiscountHeader'
import { ProjectServiceEndOfYearDiscount } from '../../components/ProjectServiceEndOfYearDiscount/ProjectServiceEndOfYearDiscount'
import { ProjectSalesActivities } from '../../components/ProjectSalesActivities/ProjectSalesActivities'
import { SalesHeader } from '../../components/SalesHeader/SalesHeader'
import { SalesTabBlockedError } from '../../components/SalesTabBlockedError/SalesTabBlockedError'
import { ProjectContingencyExplanation } from '../../components/ProjectContingencyExplanation/ProjectContingencyExplanation'
import { ProjectPricingExportModal } from '../../components/ProjectPricingExportModal/ProjectPricingExportModal'
import { ProjectAddPresales } from '../../components/ProjectAddPresales/ProjectAddPresales'

export const ProjectSalesScreen = ({ id }) => {
  const { t } = useTranslation()
  const openRevenue = { text: t('open'), arialabel: 'open the revenue dropdown' }
  const closeRevenue = { text: t('close'), arialabel: 'close the revenue dropdown' }
  const openCosts = { text: t('open'), arialabel: 'open the costs dropdown' }
  const closeCosts = { text: t('close'), arialabel: 'close the costs dropdown' }
  const openExpenses = { text: t('open'), arialabel: 'open the expenses dropdown' }
  const closeExpenses = { text: t('close'), arialabel: 'close the expenses dropdown' }

  return (
    <ProjectSalesContextProvider id={id}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ProjectSalesScreenStyled>
          <SalesHeader />
          <ProjectPricingExportModal />
          <SalesTabBlockedError />
          <Dropdown className="revenue" title={t('project.revenue.title')} localization={{ open: openRevenue, close: closeRevenue }} picto={pictoRevenue}>
            <Section>
              <ProjectRevenueInfo />
            </Section>
            <div className="service-content">
              <div className="content-column">
                <Section title={{ text: t('project.revenue.service.title'), level: 2 }} subtitle={{ text: t('project.revenue.service.subtitle') }}>
                  <ProjectServiceHeader />
                  <ProjectService />
                </Section>
                <Section title={{ text: t('project.revenue.service_adjustment_bonus.title'), level: 2 }}>
                  <ProjectServiceAdjustmentBonusHeader />
                  <ProjectServiceAdjustmentBonus />
                </Section>
              </div>
              <div className="content-column">
                <Section title={{ text: t('project.revenue.service_end_of_year.title'), level: 2 }} subtitle={{ text: t('project.revenue.service_end_of_year.subtitle') }}>
                  <ProjectServiceEndOfYearDiscountHeader />
                  <ProjectServiceEndOfYearDiscount />
                </Section>
                <Section title={{ text: t('project.revenue.service_adjustment_penalty.title'), level: 2 }}>
                  <ProjectServiceAdjustmentPenaltyHeader />
                  <ProjectServiceAdjustmentPenalty />
                </Section>
              </div>
            </div>
            <ProjectSalesExpensesList category="revenue_additional" />
            <ProjectSalesExpensesForm category="revenue_additional" />
          </Dropdown>
          <Dropdown className="costs" title={t('project.costs.title')} localization={{ open: openCosts, close: closeCosts }} picto={pictoCosts} withStartupOpen>
            <div className="row-section-add-presales">
              <Section title={{ text: t('project.presales.title'), level: 2 }}>
                <ProjectAddPresales />
              </Section>
            </div>
            <ProjectSalesActivities />
            <Section title={{ text: t('project.costs.contingency.title'), level: 2 }}>
              <ProjectContingencyExplanation />
              <ProjectContingencyHeader />
              <ProjectContingency />
            </Section>
          </Dropdown>
          <Dropdown className="expenses" title={t('project.expenses.title')} localization={{ open: openExpenses, close: closeExpenses }} picto={pictoExpenses} withStartupOpen>
            <Section>
              <ProjectExpensesInfo />
            </Section>
            <ProjectExpensesExplanation />
            <ProjectSalesExpensesList category="chargeable" />
            <ProjectSalesExpensesForm category="chargeable" />
            <ProjectSalesExpensesList category="nonchargeable" />
            <ProjectSalesExpensesForm category="nonchargeable" />
          </Dropdown>
        </ProjectSalesScreenStyled>
      </ThemeProvider>
    </ProjectSalesContextProvider>
  )
}
