import { createGlobalStyle } from 'styled-components'
import Apercu from '../assets/fonts/Apercu/Apercu.ttf'
import ApercuLight from '../assets/fonts/Apercu/Apercu-Light.ttf'
import ApercuMedium from '../assets/fonts/Apercu/Apercu-Medium.ttf'
import ApercuBold from '../assets/fonts/Apercu/Apercu-Bold.ttf'
import Outfit from '../assets/fonts/Outfit/Outfit.ttf'

export default createGlobalStyle`
  html {
    overflow: hidden;
    height: 100%;
    font-size: 10px;
  }

  body {
    overflow: auto;
    height: 100%;
    color: ${({ theme }) => theme.colors.primaryDark};
    font-family: 'Apercu', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    text-rendering: optimizeLegibility;

    ${({ theme }) => theme.devices.mobile} {
      padding: 0;
      background-color: ${({ theme }) => theme.colors.betterway.primary[5]};
    }
  }

  #root {
    height: 100vh;
  }

  .navbar {
    ${({ theme }) => theme.devices.mobile} {
      display: none;
    }
  }

  #main-wrap {
    overflow-y: auto;
    box-sizing: border-box;
    height: 100%;
    max-height: calc(100vh - 7.3rem);
    margin: 0;
    padding: 2rem 2rem 0;

    ${({ theme }) => theme.devices.mobile} {
      max-height: 100vh;
      margin: 0;
      padding: 0;
    }
  }

  #limited-main-container {
    height: 100%;
    ${({ theme }) => theme.devices.mobile} {
      padding: 0 !important;
    }
  }

  form {
    margin: 0;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  button {
    border: 0;
    font-family: 'Apercu', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }

  .backdrop-enter,
  .backdrop-enter-active,
  .backdrop-enter-done,
  .backdrop-exit,
  .backdrop-exit-active,
  .backdrop-exit-done {
    top: 7.3rem;
  }

  .side-animated-enter,
  .side-animated-enter-active,
  .side-animated-enter-done,
  .side-animated-exit,
  .side-animated-exit-active,
  .side-animated-exit-done {
    top: 7.3rem;
    height: calc(100vh - 2rem - 7.3rem);
  }

  @font-face {
    font-family: 'Apercu';
    src: local('Apercu'), url(${Apercu}) format('truetype');
  }

  @font-face {
    font-weight: 500;
    font-family: 'Apercu';
    src: local('Apercu-Medium'), url(${ApercuMedium}) format('truetype');
  }

  @font-face {
    font-weight: 700;
    font-family: 'Apercu';
    src: local('Apercu-Bold'), url(${ApercuBold}) format('truetype');
  }

  @font-face {
    font-family: 'Apercu-Light';
    src: local('Apercu-Light'), url(${ApercuLight}) format('truetype');
  }

  @font-face {
    font-family: 'Apercu-Medium';
    src: local('Apercu-Medium'), url(${ApercuMedium}) format('truetype');
  }

  @font-face {
    font-family: 'Apercu-Bold';
    src: local('Apercu-Bold'), url(${ApercuBold}) format('truetype');
  }

  @font-face {
    font-weight: 100 900;
    font-family: 'Outfit';
    src: url(${Outfit}) format('truetype');
  }
`
