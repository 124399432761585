import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeProvider } from 'styled-components/macro'
import { Button, Dropdown, InputSelect } from '@produits-internes-oss/design-system-components'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import pictoCopy from '../../../assets/images/copy.svg'
import pictoTHI from '../../../assets/images/thi.svg'
import { monthList } from '../../helpers/datePickerHelper'
import { useFetch } from '../../fetchOverviewApi'
import { useDebouncer } from '../../hooks/useDebouncer/useDebouncer'
import { ProjectSatisfactionScreenStyled } from './ProjectSatisfactionScreen.styled'
import SelectEnum from '../../components/input/_ExportDS/SelectEnum/SelectEnum'

export const ProjectSatisfactionScreen = ({ id }) => {
  const { t } = useTranslation()
  const { debounce } = useDebouncer()

  const table = useRef()
  const [options, setOptions] = useState([])
  const [filters, setFilters] = useState({ year: null, period: 'full' })
  const [report, setReport] = useState(null)

  const getTotalAsString = (total) => (total && total.time ? (total.value / total.time).toFixed(1) : '')

  const copyToClipboard = () => {
    if (table.current) {
      navigator.clipboard.writeText(Array.from(table.current.childNodes)
        .flatMap((child) => Array.from(child.childNodes))
        .map((line) => Array.from(line.childNodes).map((cell) => cell.textContent).join('\t'))
        .join('\n'))
    }
  }

  const load = ({ year, period }) => debounce(async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/happiness_report`)

    const yearMin = parseInt(response['start_date'], 10)
    const yearMax = parseInt(response['end_date'], 10)
    const yearRange = Array.from({ length: yearMax - yearMin + 1 }, (_, key) => key + yearMin)

    setOptions(Object.fromEntries(yearRange.map((value) => [value, value])))
    setFilters({ year: year || parseInt(response['end_date'], 10), period })
    setReport(response)
  }, 500)
  useEffect(() => { load(filters) }, [])

  if (report === null) { return null }

  const months = Object.keys(report['happiness_indicators_by_month']).filter((month) => parseInt(month, 10) === filters.year)
  const monthsOfPeriod = filters.period === 'half' ? months.slice(months.length - 6) : months
  const totals = { value: 0, time: 0 }
  const totalsByMonth = {}
  const totalsByPerson = {}
  const indicatorsByPerson = Object.entries(report['happiness_indicators_by_person']).sort((a, b) => a.name > b.name).map(([name, values]) => {
    const indicators = monthsOfPeriod.map((month) => {
      const indicator = report['happiness_indicators_by_month'][month].find((other) => values.map((value) => value.id).includes(other.id))

      if (indicator) {
        const time = parseFloat(indicator['time_spent'])
        const value = indicator['value'] * time

        if (!(name in totalsByPerson)) { totalsByPerson[name] = { value: 0, time: 0 } }
        totalsByPerson[name].value += value
        totalsByPerson[name].time += time

        if (!(month in totalsByMonth)) { totalsByMonth[month] = { value: 0, time: 0 } }
        totalsByMonth[month].value += value
        totalsByMonth[month].time += time

        totals.value += value
        totals.time += time
      }

      return { value: indicator?.value, month }
    })

    return { name, indicators }
  })

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ProjectSatisfactionScreenStyled>
        <Dropdown
          title={t('project.satisfaction.thi.title')}
          picto={pictoTHI}
          localization={{
            open: { text: t('project.satisfaction.thi.open.text'), arialabel: t('project.satisfaction.thi.open.arialabel') },
            close: { text: t('project.satisfaction.thi.close.text'), arialabel: t('project.satisfaction.thi.close.arialabel') },
          }}
          withStartupOpen
        >
          <Button
            className="copy"
            skin="dark"
            kind="primary"
            name={t('project.satisfaction.thi.clipboard.arialabel')}
            text={t('project.satisfaction.thi.clipboard.text')}
            icon={{ url: pictoCopy, position: 'right' }}
            onClick={() => copyToClipboard()}
          />
          <div className="controls">
            <InputSelect
              className="year"
              skin="light"
              name={t('project.satisfaction.thi.year.arialabel')}
              title={t('project.satisfaction.thi.year.text')}
              options={options}
              valueInitial={filters.year}
              onChange={([value]) => setFilters({ ...filters, year: parseInt(value, 10) })}
            />
            <SelectEnum
              className="period"
              name={t('project.satisfaction.thi.period.arialabel')}
              value={filters.period}
              items={['half', 'full'].map((name) => ({ name, title: t(`project.satisfaction.thi.period.${name}`) }))}
              onChange={({ name }) => setFilters({ ...filters, period: name })}
            />
          </div>
          <table ref={table}>
            <thead>
              <tr>
                <th>{t('project.satisfaction.thi.consultants')}</th>
                {
                  monthsOfPeriod.map((month) => {
                    const date = new Date(month)
                    return <th key={month}>{`${t(`months.${monthList[date.getMonth()]}.full`)} ${date.getFullYear()}`}</th>
                  })
                }
                <th>{t('project.satisfaction.thi.weighted')}</th>
              </tr>
            </thead>
            <tbody>
              {
                indicatorsByPerson.map(({ name, indicators }) => (
                  <tr key={`${name} happiness report`}>
                    <td>{name}</td>
                    {
                      indicators.map(({ value, month }) => <td className="align-right" key={`${name} happiness report for ${month}`}>{value || ''}</td>)
                    }
                    <td className="align-right">{getTotalAsString(totalsByPerson[name])}</td>
                  </tr>
                ))
              }
            </tbody>
            <tfoot>
              <tr>
                <td>{t('project.satisfaction.thi.total')}</td>
                {
                  monthsOfPeriod.map((month) => <td className="align-right" key={`${month} total`}>{getTotalAsString(totalsByMonth[month])}</td>)
                }
                <td className="align-right">{getTotalAsString(totals)}</td>
              </tr>
            </tfoot>
          </table>
        </Dropdown>
      </ProjectSatisfactionScreenStyled>
    </ThemeProvider>
  )
}
