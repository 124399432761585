import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import StyledTimesheetFooter from './TimesheetFooter.styled'

import DoubleProgressCircle from '../../../UI/DoubleProgressCircle/DoubleProgressCircle'
import Period from './Period/Period'
import ActivityRatePopin from './ActivityRatePopin/ActivityRatePopin'
import Chevron from '../../../UI/Chevron/Chevron'
import HappinessIndicatorModal from './HappinessIndicatorModal/HappinessIndicatorModal'

import { useTimesheetParamsValue } from '../../../../context/Timesheet/TimesheetParamsContext'
import { useTimesheetStateValue } from '../../../../context/Timesheet/TimesheetStateContext'
import { useActivityRateValue } from '../../../../context/Timesheet/ActivityRateContext'
import { usePeriodStateValue } from '../../../../context/Timesheet/PeriodStateContext'
import { useTimesheetErrors } from '../../../../context/Timesheet/TimesheetErrorsContext'

import { monthList } from '../../../../helpers/datePickerHelper'
import { request } from '../../../../javascripts/HttpService'

const TimesheetFooter = observer(({ nbDays }) => {
  const { t } = useTranslation()
  const [openActivityRatePopin, setOpenActivityRatePopin] = useState(false)
  const [openHappinessIndicatorModal, setOpenHappinessIndicatorModal] = useState(false)

  const {
    monthlyActivityRate,
    setMonthlyActivityRate,
    yearlyActivityRate,
    setYearlyActivityRate,
  } = useActivityRateValue()
  const {
    firstPeriodFillRate,
    secondPeriodFillRate,
    firstPeriodStatus,
    secondPeriodStatus,
  } = usePeriodStateValue()
  const [timesheetParams] = useTimesheetParamsValue()
  const { owner, periods } = useTimesheetStateValue()
  const { periodErrors, hasTimesheetErrors } = useTimesheetErrors()

  const toggleActivityRatePopin = () => {
    const newOpenActivityRatePopin = !openActivityRatePopin
    document.body.style.overflow = newOpenActivityRatePopin ? 'hidden' : 'auto'
    setOpenActivityRatePopin(newOpenActivityRatePopin)
  }

  const closeActivityRatePopin = () => {
    setOpenActivityRatePopin(false)
    document.body.style.overflow = 'auto'
  }

  const toggleHappinessIndicatorModal = () => {
    const newOpenHappinessIndicatorModal = !openHappinessIndicatorModal
    document.body.style.overflow = newOpenHappinessIndicatorModal ? 'hidden' : 'auto'
    setOpenHappinessIndicatorModal(newOpenHappinessIndicatorModal)
  }

  const closeHappinessIndicatorModal = () => {
    setOpenHappinessIndicatorModal(false)
    document.body.style.overflow = 'auto'
  }

  useEffect(() => {
    request(`/activity_rate/monthly_and_yearly_rate/${owner.id}/${timesheetParams.timesheetDate.year}/${timesheetParams.timesheetDate.month + 1}`)
      .then((responseData) => {
        setYearlyActivityRate(responseData.fiscal_year_rate)
        setMonthlyActivityRate(responseData.monthly_rate)
      })
  }, [])

  return (
    <StyledTimesheetFooter
      openActivityRatePopin={openActivityRatePopin}
      openHappinessIndicatorModal={openHappinessIndicatorModal}
    >
      <tr>
        <th colSpan={2}>
          <ActivityRatePopin
            open={openActivityRatePopin}
            close={closeActivityRatePopin}
            initialYearlyActivityRate={yearlyActivityRate}
            ownerId={owner.id}
          />
          <div
            className="tace"
            onClick={toggleActivityRatePopin}
            onKeyPress={toggleActivityRatePopin}
            role="button"
            tabIndex={0}
            aria-label="rate-block"
          >
            <p>{t('activityRate')}</p>
            <DoubleProgressCircle
              monthlyRate={Math.round(monthlyActivityRate * 100)}
              yearlyRate={Math.round(yearlyActivityRate * 100)}
            />
            <Chevron fill="rgb(255, 255, 255)" unfold={openActivityRatePopin} backward />
          </div>
        </th>
        <Period
          id={periods[0].id}
          periodNumber={1}
          colSpan={15}
          dates={`01 - 15 ${t(`months.${monthList[timesheetParams.timesheetDate.month]}.full`)} ${timesheetParams.timesheetDate.year}`}
          rate={Math.round(firstPeriodFillRate * 100)}
          kind={firstPeriodStatus}
          periodErrors={periodErrors?.firstPeriod}
          hasTimesheetErrors={hasTimesheetErrors}
        />
        <Period
          id={periods[1].id}
          periodNumber={2}
          colSpan={nbDays - 15}
          dates={`16 - ${nbDays} ${t(`months.${monthList[timesheetParams.timesheetDate.month]}.full`)} ${timesheetParams.timesheetDate.year}`}
          rate={Math.round(secondPeriodFillRate * 100)}
          kind={secondPeriodStatus}
          periodErrors={periodErrors?.secondPeriod}
          hasTimesheetErrors={hasTimesheetErrors}
        />
        <th colSpan={1}>
          <>
            <HappinessIndicatorModal
              open={openHappinessIndicatorModal}
              close={closeHappinessIndicatorModal}
            />
            <button
              type="button"
              className="thi"
              onClick={toggleHappinessIndicatorModal}
              aria-label="thi-button"
            >
              <p>{t('happinessIndicator.modalTitle')}</p>
            </button>
          </>
        </th>
      </tr>
    </StyledTimesheetFooter>
  )
})

export default TimesheetFooter
