import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button, InputDate, InputNumber, InputRadio, InputSearch, InputSelect, InputText, InputTextarea, Modal, Table, Tooltip,
} from '@produits-internes-oss/design-system-components'
import { useDebouncer } from '../../hooks/useDebouncer/useDebouncer'
import { useJQuery } from '../../hooks/useJQuery/useJQuery'
import { useSearch } from '../../hooks/useSearch/useSearch'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { useProjectCoordinationContext } from '../../context/ProjectCoordination/ProjectCoordinationContext'
import { monthList } from '../../helpers/datePickerHelper'
import { filter, map } from '../../helpers/objectHelper'
import { getClass, getNumberWithLeadingZero, getNumberWithUnit } from '../../helpers/stringHelper'
import { ProgressBar } from '../ProgressBar/ProgressBar'
import { ProjectCoordinationExpensesTableStyled } from './ProjectCoordinationExpensesTable.styled'
import pictoDestroy from '../../../assets/images/destroy.svg'

const TURNOVER_TYPES_CLASSES = {
  'travel': 'travel',
  'data_driver': 'license',
  'subcontractor_expenses': 'subcontractor',
  'misc': 'misc',
}

export const ProjectCoordinationExpensesTable = ({ category }) => {
  const { t } = useTranslation()
  const { fetchSearch } = useSearch()
  const { debounce } = useDebouncer()
  const { subscribe, publish } = useJQuery()
  const { project, projectSalesExpenses, getSubcontractorName, fetchCreateProjectSalesExpense, fetchUpdateProjectSalesExpense, fetchGetKPIs } = useProjectSalesContext()
  const { fetchUpdateProjectExpenseQuantity, fetchDeleteExpense } = useProjectCoordinationContext()

  useEffect(() => { subscribe('/octopod/turnover_breakdown_months/update_validation', () => fetchGetKPIs()) }, [])

  const types = useMemo(() => filter(
    map(TURNOVER_TYPES_CLASSES, (key) => ((key !== 'subcontractor_expenses' || category === 'nonchargeable')
      ? t(`project.coordination.expenses.types.${key}`)
      : null)), (_, value) => value !== null,
  ), [category])

  const dates = useMemo(() => (project
    ? project['months'].map(({ month, validated }) => ({ month, date: new Date(month), validated })).sort((a, b) => a.date - b.date)
    : []
  ), [project?.['months']])

  const [data, setData] = useState({})
  const [dataToRemove, setDataToRemove] = useState(null)
  useEffect(() => {
    setData(Object.fromEntries(projectSalesExpenses.filter((expense) => expense.category === category).map((expense) => [expense.id, expense])))
  }, [projectSalesExpenses])

  const [form, setForm] = useState({})

  const updateForm = useCallback((key, value) => setForm((previous) => ({ ...previous, category, [key]: value })), [category])

  const updateExpense = useCallback(async (id, key, value) => debounce(async () => {
    setData((previous) => ({ ...previous, [id]: { ...previous[id], [key]: value } }))
    return fetchUpdateProjectSalesExpense({ id, [key]: value })
  }, 1000), [fetchUpdateProjectSalesExpense])

  const [quantities, setQuantities] = useState({})

  const getQuantity = useCallback((item, month) => {
    const quantityInitial = item['project_expense_quantities'].find((quantity) => quantity.month === month)?.quantity
    const quantityInCache = quantities[item['id']]?.[month]

    return parseFloat(Number.isFinite(quantityInCache) ? quantityInCache : quantityInitial) || 0
  }, [quantities])

  const getQuantityTotalForItem = useCallback((item) => dates.reduce((sum, { month }) => sum + getQuantity(item, month), 0), [dates, getQuantity])

  const updateQuantity = useCallback(async (id, month, quantity) => debounce(async () => {
    setQuantities((previous) => ({ ...previous, [id]: { ...previous[id], [month]: quantity } }))
    return fetchUpdateProjectExpenseQuantity({ id, month, quantity })
  }, 1000), [fetchUpdateProjectExpenseQuantity])

  const columns = useMemo(() => [
    dataToRemove !== null && {
      className: 'remove',
      name: t('project.coordination.expenses.remove.arialabel'),
      title: t('project.coordination.expenses.remove.text'),
      onRender: ({ item }) => (
        <>
          <InputRadio
            name={t('project.coordination.expenses.remove.arialabel')}
            options={{ 'remove': '' }}
            multiple
            disabled={Boolean(getQuantityTotalForItem(item))}
            onChange={() => {
              const { id } = item
              setDataToRemove((previous) => ({ ...previous, [id]: !previous[id] }))
            }}
          />
          <img src={pictoDestroy} alt="destroy" />
        </>
      ),
    },
    category !== 'package' && {
      className: 'turnover',
      name: t('project.coordination.expenses.turnover_type.arialabel'),
      title: t('project.coordination.expenses.turnover_type.text'),
      onRender: ({ item }) => (
        <>
          <div className={getClass('tag', TURNOVER_TYPES_CLASSES[item['turnover_type']])}>
            {t(`project.coordination.expenses.types.${item['turnover_type']}`)}
          </div>
          {
            item['subcontractor_name'] && <div className="name">{item['subcontractor_name']}</div>
          }
        </>
      ),
      onFooter: () => <div className="total">{t('project.coordination.expenses.total.text')}</div>,
    },
    {
      className: 'comment',
      name: t('project.coordination.expenses.comment.arialabel'),
      title: t('project.coordination.expenses.comment.text'),
      onRender: ({ item }) => (
        <InputText
          name={t('project.coordination.expenses.comment.arialabel')}
          valueInitial={item['comment']}
          onChange={(value) => updateExpense(item['id'], 'comment', value)}
        />
      ),
    },
    {
      className: 'unit',
      name: t(`project.coordination.expenses.${category !== 'package' ? 'unit_price' : 'sold'}.arialabel`),
      title: t(`project.coordination.expenses.${category !== 'package' ? 'unit_price' : 'sold'}.text`),
      onRender: ({ item }) => (
        <InputNumber
          name={t(`project.coordination.expenses.${category !== 'package' ? 'unit_price' : 'sold'}.arialabel`)}
          localization={{
            invalid: { arialabel: t('project.coordination.expenses.unit_price_error.arialabel'), text: t('project.coordination.expenses.unit_price_error.text') },
          }}
          valueInitial={parseFloat(item['unit_price'])}
          onCheck={(value) => value <= 0 && ''}
          onChange={(value) => { debounce(() => updateExpense(item['id'], 'unit_price', value), 1000) }}
        />
      ),
      onFooter: ({ items }) => getNumberWithUnit(items.reduce((sum, item) => sum + parseFloat(item['unit_price']), 0), '€'),
    },
    category !== 'package' && {
      className: 'quantity',
      name: t('project.coordination.expenses.quantity.arialabel'),
      title: t('project.coordination.expenses.quantity.text'),
      onRender: ({ item }) => (
        <InputNumber
          name={t('project.coordination.expenses.quantity.arialabel')}
          localization={{
            invalid: { arialabel: t('project.coordination.expenses.quantity_error.arialabel'), text: t('project.coordination.expenses.quantity_error.text') },
          }}
          valueInitial={parseFloat(item['quantity'])}
          onCheck={(value) => value <= 0 && ''}
          onChange={(value) => { debounce(() => updateExpense(item['id'], 'quantity', value), 1000) }}
        />
      ),
      onFooter: ({ items }) => getNumberWithUnit(items.reduce((sum, item) => sum + parseFloat(item['quantity']), 0), ''),
    },
    category !== 'package' && {
      className: 'total',
      name: t('project.coordination.expenses.total_price.arialabel'),
      title: t('project.coordination.expenses.total_price.text'),
      onRender: ({ item }) => getNumberWithUnit(parseFloat(item['unit_price']) * parseFloat(item['quantity']), '€'),
      onFooter: ({ items }) => getNumberWithUnit(items.reduce((sum, item) => sum + parseFloat(item['unit_price']) * parseFloat(item['quantity']), 0), '€'),
    },
    {
      className: 'consumption',
      name: t('project.coordination.expenses.consumption.arialabel'),
      title: t('project.coordination.expenses.consumption.text'),
      onRender: ({ item }) => {
        const total = getQuantityTotalForItem(item)
        const progression = total / parseFloat(item['quantity'])

        return <ProgressBar progression={progression || 0} withProgressionIcon />
      },
      onFooter: ({ items }) => {
        const total = items.reduce((sum, item) => sum + getQuantityTotalForItem(item), 0)
        const progression = total / items.reduce((sum, item) => (sum + parseFloat(item['quantity'])), 0)

        return <ProgressBar progression={progression || 0} withProgressionIcon />
      },
    },
  ].concat(dates.length <= 0 ? [] : dates.map(({ month, date, validated }) => (
    {
      className: 'month',
      name: `${t('project.coordination.expenses.month.arialabel')} ${date.getFullYear()}/${date.getMonth() + 1}`,
      title: `${getNumberWithLeadingZero(date.getMonth() + 1, 2)}/${date.getFullYear()}`,
      onRender: ({ item }) => (
        <Tooltip
          className="disabled-input-tooltip"
          position="left"
          skin="light"
          trigger={
              ({ open, close }) => (
                <div
                  onMouseEnter={() => validated && open()}
                  onMouseLeave={() => validated && close()}
                  className="tooltip-trigger-input"
                  aria-label="tooltip trigger input"
                >

                  <InputNumber
                    name={t('project.coordination.expenses.month.arialabel')}
                    localization={{
                      invalid: { arialabel: t('project.coordination.expenses.month_error.arialabel'), text: t('project.coordination.expenses.month_error.text') },
                    }}
                    valueInitial={getQuantity(item, month)}
                    disabled={validated}
                    onCheck={(value) => value < 0 && ''}
                    onChange={(value) => {
                      const dateAsString = `${date.getFullYear()}-${getNumberWithLeadingZero(date.getMonth() + 1, 2)}-${getNumberWithLeadingZero(date.getDate(), 2)}`
                      updateQuantity(item['id'], dateAsString, value)
                      publish('/octopod/ajax/reset_elements')
                    }}
                  />
                </div>
              )
            }
          content={
              () => (
                <div className="text-tooltip">
                  <em>{t('project.coordination.time_inputs.tooltip-disabled.0')}</em>
                  <span>{t('project.coordination.time_inputs.tooltip-disabled.1')}</span>

                </div>
              )
            }
        />
      ),
      onFooter: ({ items }) => getNumberWithUnit(items.reduce((sum, item) => {
        const total = getQuantity(item, month) * parseFloat(item['unit_price'])
        return sum + total
      }, 0), '€'),
    }
  ))).filter(Boolean), [dates, quantities, Boolean(dataToRemove)])

  const isFormValid = form
  && (form['label'] || category !== 'package')
  && (form['turnover_type'])
  && (form['unit_price'])
  && (form['quantity'])
  && (form['subcontractor_id'] || form['turnover_type'] !== 'subcontractor_expenses')
  && (form['month'] || form['turnover_type'] !== 'subcontractor_expenses')
  && (form['comment'])

  return (
    <ProjectCoordinationExpensesTableStyled>
      <Table columns={columns} data={data} />
      <div className="controls">
        {
          dataToRemove === null && (
            <Modal
              className="create"
              title={t('project.coordination.expenses.create.title')}
              localization={{
                cancel: { arialabel: t('project.coordination.expenses.create.cancel.arialabel'), text: t('project.coordination.expenses.create.cancel.text') },
                validation: { arialabel: t('project.coordination.expenses.create.validate.arialabel'), text: t('project.coordination.expenses.create.validate.text') },
              }}
              trigger={({ open }) => (
                <Button
                  kind="primary"
                  skin="dark"
                  name={t(`project.coordination.expenses.${category}_add.arialabel`)}
                  text={t(`project.coordination.expenses.${category}_add.text`)}
                  onClick={() => {
                    setForm({})
                    open()
                  }}
                />
              )}
              disabled={!isFormValid}
              onValidate={() => {
                setDataToRemove(null)
                return fetchCreateProjectSalesExpense(form)
              }}
            >
              <div className="form-requirements">{t('project.coordination.expenses.create.description')}</div>
              <hr />
              <div className="form-row">
                {
                category !== 'package' && (
                <InputSelect
                  className="form-control"
                  name={t('project.coordination.expenses.turnover_type.arialabel')}
                  title={t('project.coordination.expenses.turnover_type.text')}
                  options={types}
                  required
                  onChange={([value]) => updateForm('turnover_type', value)}
                />
                )
              }
                <InputNumber
                  className="form-control"
                  name={t('project.coordination.expenses.unit_price.arialabel')}
                  title={t('project.coordination.expenses.unit_price.text')}
                  localization={{
                    invalid: { arialabel: t('project.coordination.expenses.unit_price_error.arialabel'), text: t('project.coordination.expenses.unit_price_error.text') },
                  }}
                  required
                  onCheck={(value) => (value ? value <= 0 && '' : null)}
                  onChange={(value) => updateForm('unit_price', value)}
                />
                {
                category !== 'package' && (
                  <InputNumber
                    className="form-control"
                    name={t('project.coordination.expenses.quantity.arialabel')}
                    title={t('project.coordination.expenses.quantity.text')}
                    localization={{
                      invalid: { arialabel: t('project.coordination.expenses.quantity_error.arialabel'), text: t('project.coordination.expenses.quantity_error.text') },
                    }}
                    required
                    onCheck={(value) => (value ? value <= 0 && '' : null)}
                    onChange={(value) => updateForm('quantity', value)}
                  />
                )
              }
              </div>
              {
              form['turnover_type'] === 'subcontractor_expenses' && (
              <div className="form-row">
                <InputSearch
                  className="form-control"
                  name={t('project.coordination.expenses.subcontractor.arialabel')}
                  title={t('project.coordination.expenses.subcontractor.text')}
                  required
                  onSearch={(search) => debounce(() => search.length >= 3 && fetchSearch(search, 'Subcontractor', null, getSubcontractorName), 500)}
                  onChange={({ key }) => updateForm('subcontractor_id', key)}
                />
                <InputDate
                  className="form-control"
                  name={t('project.coordination.expenses.month.arialabel')}
                  title={t('project.coordination.expenses.month.text')}
                  localization={{
                    months: [...monthList.map((month) => t(`months.${month}.short`)), ...monthList.map((month) => t(`months.${month}.full`))],
                    previous: t('project.coordination.expenses.month.previous'),
                    next: t('project.coordination.expenses.month.next'),
                  }}
                  precision="month"
                  required
                  onChange={(value) => updateForm('month', value)}
                />
                <div className="form-control" />
              </div>
              )
            }
              <div className="form-row">
                <InputTextarea
                  className="form-control"
                  name={t('project.coordination.expenses.comment.arialabel')}
                  title={t('project.coordination.expenses.comment.text')}
                  required
                  maxLength={255}
                  onChange={(value) => updateForm('comment', value)}
                />
                <div className="form-control" />
              </div>
            </Modal>
          )
        }
        {
          dataToRemove !== null && (
          <Button
            skin="dark"
            name={t(`project.coordination.expenses.remove_confirm.arialabel`)}
            text={t(`project.coordination.expenses.remove_confirm.text`)}
            onClick={() => {
              const ids = Object.entries(dataToRemove).map(([key, value]) => value && key).filter(Boolean)
              setData((previous) => filter(map(previous, (key, value) => ((ids.includes(key)) ? null : value)), (_, value) => Boolean(value)))
              setDataToRemove(null)

              return fetchDeleteExpense(ids)
            }}
          />
          )
        }
        {
          Object.keys(data).length > 0 && (
            <Modal
              className="remove"
              title={t('project.coordination.expenses.remove_title')}
              trigger={({ open }) => (
                <Button
                  className={getClass(dataToRemove !== null && 'hidden')}
                  kind="secondary"
                  skin="light"
                  name={t(`project.coordination.expenses.remove_select.arialabel`)}
                  text={t(`project.coordination.expenses.remove_select.text`)}
                  onClick={() => {
                    setDataToRemove([])
                    open()
                  }}
                />
              )}
            >
              {t('project.coordination.expenses.remove_description')}
            </Modal>
          )
        }
        {
          Object.keys(data).length > 0 && dataToRemove !== null && (
            <Button
              kind="secondary"
              skin="light"
              name={t(`project.coordination.expenses.remove_cancel.arialabel`)}
              text={t(`project.coordination.expenses.remove_cancel.text`)}
              onClick={() => setDataToRemove(null)}
            />
          )
        }
      </div>
    </ProjectCoordinationExpensesTableStyled>
  )
}
